import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentFlashTrading: {
      padding: '40px 0',
    },

    listFilterHead: {
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      listStyle: 'none',
      marginBottom: 20,
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },

    itemFilter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      listStyle: 'none',
      marginRight: 20,
      padding: '5px 13px',
      minWidth: 80,
      background: theme.custom.colors.bgTrading,
      borderRadius: 6,
      cursor: 'pointer',
      height: 34,
      justifyContent: 'center',
      fontSize: 14,
      lineHeight: '140%',
      color: '#EBECF0',
      textTransform: 'uppercase',
      fontFamily: 'Ubuntu-Regular',

      '& .icon-star2:before': {
        color: '#EBECF0',
        fontSize: 24,
      },

      '&:first-child': {
        minWidth: 58,
      },
    },

    activeFilter: {
      background: '#7F63F4',
      color: '#EBECF0',

      '& .icon-favorite .path1:before': {
        fontSize: 20,
      },
      '& .icon-favorite .path2:before': {
        fontSize: 20,
      },
    },

    itemIcon: {
      marginRight: 5,
      height: 24,
    },

    itemName: {},

    contentTabFlashTrading: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill,minmax(213px,200px))',
      gridGap: 20,
      position: 'relative',

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(auto-fill,minmax(100%,100%))',
      },
    },

    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      background: theme.custom.colors.mainBackground,
      opacity: 0.9,
      width: '100%',
      height: '100%',
      zIndex: 1
    }
  };
});

export default useStyles;
