import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    checkBox: {
      width: '100%',
      fontFamily: 'Ubuntu-Regular',
      fontSize: '14px',
      lineHeight: '20px',
    },

    label: {
      fontFamily: 'Ubuntu-Medium',
      fontSize: '14px',
      lineHeight: '20px',
      margin: '20px 0 8px',
    },

    helperText: {
      fontFamily: 'Ubuntu-Regular',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      marginTop: '4px',
    },
    error: {
      color: theme.custom.colors.red1,
    },
  };
});

export default useStyles;
