import {
  AddressBookService,
  IGetAddressParam,
} from 'src/services/AddressBookService';
import {
  ADDRESS_HIDE,
  ADDRESS_SET,
  ADDRESS_TOGGLE,
} from '../constants/address';

export const AddressBookAction = {
  toggleModalAdd: (
    { isWhitelist = false }: { isWhitelist: boolean },
    { isWithdrawal = false }: { isWithdrawal: boolean }
  ) => ({
    type: ADDRESS_TOGGLE,
    isWhitelist,
    isWithdrawal,
  }),
  hideModalAdd: () => ({
    type: ADDRESS_HIDE,
  }),

  set: (params?: IGetAddressParam) => async (dispatch: any) => {
    const addressService = AddressBookService.getInstance();
    const res = await addressService.getAddresses({
      type: 'WITHDRAWAL',
      ...params,
    });
    if (res.data?.items)
      dispatch({
        type: ADDRESS_SET,
        payload: res.data,
      });
  },

  reload:
    (params?: IGetAddressParam) => async (dispatch: any, getState: any) => {
      const paramsOld = getState().address.params;
      params = { ...paramsOld, ...params };
      const addressService = AddressBookService.getInstance();
      const res = await addressService.getAddresses(params);
      if (res.data?.items)
        dispatch({
          type: ADDRESS_SET,
          params,
          payload: res.data,
        });
    },
};
