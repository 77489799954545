import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import DefaultLayout from 'src/components/Layout/DefaultLayout';
import OrderReport from './OrderReport';
import useStyles from './styles';
import TransactionReport from './TransactionReport';

const ReportsPage = (props: any) => {
  const styles = useStyles();
  const [tabComponent, setTabComponent] = useState(0);

  useEffect(() => {
    document.title = 'Reports';
  }, []);

  return (
    <DefaultLayout>
      <div className={styles.reportsPage}>
        <ul className={styles.tabComponent}>
          <li
            className={`${styles.itemTabComponent} ${tabComponent === 0 && styles.activeTab
              }`}
            onClick={() => setTabComponent(0)}
          >
            Orders
          </li>
          <li
            className={`${styles.itemTabComponent} ${tabComponent === 1 && styles.activeTab
              }`}
            onClick={() => setTabComponent(1)}
          >
            Transactions
          </li>
        </ul>
        {tabComponent === 0 ? <OrderReport /> : <TransactionReport />}
      </div>
    </DefaultLayout>
  );
};

export default withRouter(ReportsPage);
