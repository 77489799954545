import { AuthService } from 'src/services/AuthService';
import { KEY } from 'src/commons/constants';
import { LOGIN_SUCCESS, LOGOUT } from '../constants/authentication';

export const AuthenticationAction = {

  loginSuccess: (asset_token: string, refresh_token: string, username: string) =>
    (dispatch: any) => {

      // set token to localStorage
      localStorage.setItem(KEY.ACCESS_TOKEN, asset_token);
      localStorage.setItem(KEY.REFRESH_TOKEN, refresh_token);

      dispatch({
        type: LOGIN_SUCCESS,
        data: { asset_token, refresh_token, username },
      });
    },

  logout: () => async (dispatch: any) => {
    try {
      const authService = AuthService.getInstance();
      await authService.logout();
    } catch (error) { }
    finally {
      localStorage.removeItem(KEY.ACCESS_TOKEN);
      localStorage.removeItem(KEY.REFRESH_TOKEN);
      dispatch({ type: LOGOUT });
    }
  },
};
