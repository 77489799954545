import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { MobileStepper, Button } from '@material-ui/core';
import useStyles from './styles';

const tutorialSteps = [
  {
    imgPath: '/images/company-1.png',
  },
  {
    imgPath: '/images/company-2.png',
  },
  {
    imgPath: '/images/company-3.png',
  },
  {
    imgPath: '/images/company-4.png',
  },
  {
    imgPath: '/images/company-5.png',
  },
];

export default function TextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.mainSLider}>
      <div className={classes.slideImg}>
        <img
          className={classes.img}
          src={tutorialSteps[activeStep].imgPath}
          alt=""
        />
      </div>

      <MobileStepper
        className={classes.styleMobileSLide}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="large"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            className={classes.styleButtonSlide}
          >
            {theme.direction === 'rtl' ? (
              <img src="/icons/icon-left.svg" alt="" />
            ) : (
              <img src="/icons/icon-right.svg" alt="" />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            className={classes.styleButtonSlide}
          >
            {theme.direction === 'rtl' ? (
              <img src="/icons/icon-right.svg" alt="" />
            ) : (
              <img src="/icons/icon-left.svg" alt="" />
            )}
          </Button>
        }
      />
    </div>
  );
}
