import { useState } from "react";
import { Switch, Typography } from "@material-ui/core";

import useStyles from "./styles";

const ComponentHeadTrading = (props: any) => {
  const { onSetActiveNav, activeNav, handleChecked } = props;
  const styles = useStyles();

  const [isFlashTrade, setFlashTrade] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFlashTrade(e.target.checked);
    handleChecked(e.target.checked);
  };

  return (
    <div className={styles.mainHead}>
      <ul className={styles.navHeadTrading}>
        <li
          className={`${styles.itemNav} ${activeNav === 0 && styles.activeItemNav}`}
          onClick={() => onSetActiveNav(0)}
        >
          Default Trading
        </li>
        <li
          className={`${styles.itemNav} ${activeNav === 1 && styles.activeItemNav}`}
          onClick={() => onSetActiveNav(1)}
        >
          Flash Trading
        </li>
      </ul>
      {activeNav === 1 && (
        <div className={styles.display}>
          <span className="icon-info"></span>
          <Typography className={styles.label}>
            Allow 1 click trading
          </Typography>
          <Switch
            checked={isFlashTrade}
            onChange={handleChange}
            classes={{
              root: styles.rootChecked,
              switchBase: styles.switchBase,
              thumb: styles.thumb,
              track: styles.track,
              checked: styles.checked,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ComponentHeadTrading;
