import { LOADING_TOGGLE } from '../constants/loading';

export const LoadingAction = {
  show: () => ({
    type: LOADING_TOGGLE,
    payload: true,
  }),
  hide: () => ({
    type: LOADING_TOGGLE,
    payload: false,
  }),
};
