import { AnyAction } from 'redux';
import * as actionTypes from '../constants/withdrawal';

const initialState = {
  show: false,
  address_id: null,
  onSuccess: () => {},
};

export const withdrawalReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.WITHDRAWAL_TOGGLE: {
      return {
        ...state,
        show: !state.show,
        address_id: action.address_id,
        onSuccess: action.onSuccess,
      };
    }
    case actionTypes.WITHDRAWAL_HIDE: {
      return {
        ...state,
        show: false,
      };
    }

    default:
      return state;
  }
};
