import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    walletPage: {
      paddingTop: 20,

      [theme.breakpoints.up("xl")]: {
        paddingTop: 40,
      },

      [theme.breakpoints.down("sm")]: {
        paddingBottom: 20,
      },
    },

    footerPage: {
      marginTop: 50,
      marginBottom: 40,
      display: "grid",
      gridGap: 24,
      gridTemplateColumns: "repeat(auto-fill,minmax(calc(50% - 12px),calc(50% - 12px)))",
      position: 'relative',
      zIndex: 100,
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: 50,
      },
    },
  };
});

export default useStyles;