import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import _ from 'lodash';

import { displayNumb } from 'src/utils/number';
import { marketService } from 'src/services/MarketService';
import { getPercent } from 'src/utils/number';

import useStyles from './styles';
import { useSelector } from 'react-redux';
import AppBox from 'src/components/Base/AppBox';


const MarketInfo = (props: any) => {
  const classes = useStyles();

  const { allMarkets, handleRedirectTrading } = props;
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const [isLoading, setIsLoading] = useState(isLogged);
  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    if (allMarkets && allMarkets.length > 0) {
      getPriceChange(allMarkets);
    }
  }, [allMarkets]);

  const getPriceChange = async (allMarkets: any) => {
    setIsLoading(true);

    // get all price change
    const prices = await marketService.getPriceChangeMarket(allMarkets);

    const m = allMarkets.map((item: any) => {

      // find item of price change
      let price: any = {};
      if (prices?.data && _.isArray(prices?.data)) {
        price = prices.data.find((x: any) => x.symbol === item);
      }
      const current_price = price?.current_price ?? 0;
      const old_price = price?.old_price ?? 0;

      return {
        logo: `/images/logos/${item.split('/')[0]?.toLowerCase()}.svg`,
        pair: item,
        price: displayNumb(price?.current_price) ?? 0,
        percent: getPercent(old_price, current_price),
      };
    });

    setMarkets(m);
    setIsLoading(false);
  };

  const renderData = (markets: any) => {
    return markets.map((item: any, idx: number) => {
      return (
        <div className={classes.item} key={idx}>
          <div className={classes.frame1}>
            <img src={item.logo} alt="" className={classes.logo}></img>

            <div className={classes.pairDetail}>
              <span className={classes.pairLabel}>{item.pair}</span>
              <span className={classes.priceLabel}>
                {item.percent > 0 ? `+ ${item.percent}%` : `${item.percent}%`}
              </span>
            </div>
          </div>

          <div className={classes.pairPrice}>
            <span className={classes.pairLabel}>
              {displayFormatPrice(item)}
            </span>
          </div>
          <Button
            variant="contained"
            className={classes.tradeBtn}
            onClick={() => handleRedirectTrading(item.pair)}
          >
            Trade
          </Button>
        </div>
      );
    });
  };

  const displayFormatPrice = (item: any) => {
    const anchorCoin = item?.pair?.split('/')[1]?.toUpperCase();
    if (anchorCoin === 'USD' || anchorCoin === 'USDT') {
      return `$${item.price}`;
    }
    return `${item.price}`;
  };

  return (
    <div className={classes.marketInfoContainer}>
      <div className={classes.header}>Market Info</div>
      <AppBox
        loading={isLoading}
        hasData={markets?.length > 0}
        className={classes.pairs}
      >
        {renderData(markets)}
      </AppBox>
    </div>
  );
};

export default withRouter(MarketInfo);
