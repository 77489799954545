import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentFooterLeft: {
      fontFamily: 'Ubuntu-Medium',
       [theme.breakpoints.down("xs")]: {
        marginTop: 40,
      },
    },

    contentFooterRight: {
      marginTop: 46,
      color: theme.custom.colors.boldGray,
      background: theme.custom.colors.cardBackground,
      borderBottom: `4px solid ${theme.custom.colors.mainBackground}`,
      borderRadius: 8,
      height: "calc(100% - 47px - 40px)",
      minHeight: 465,

      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
      },
    },

    tabComponent: {
      borderBottom: `1px solid ${theme.custom.colors.comet4}`,
      padding: 0,
      margin: 0,
      listStyle: "none",
      display: "flex",
      flexWrap: "wrap",
    },

    itemTabComponent: {
      fontFamily: "Ubuntu-Bold",
      fontSize: 18,
      lineHeight: "140%",
      color: "#9BA1B1",
      padding: "10px 0",
      cursor: "pointer",
      borderBottom: "2px solid transparent",
      marginRight: 40,
      marginBottom: -1,

      "&:last-child": {
        marginRight: 0,
      }
    },

    activeTab: {
      borderBottom: "2px solid #7F63F4",
      color: theme.custom.colors.boldGray,
    }
  };
});

export default useStyles;