import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useStyles from './styles';

const Permissons = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.mainPermisson}>
      <Typography variant="h3" className={classes.titleKey}>
        Key permissions
      </Typography>
      <p className={classes.styleText}>
        Key permissions are applied as attributes to each key, combinations of
        permissions yields key access to more resources:
      </p>
      <div className={classes.listItem}>
        <div className={classes.styleLi}>
          <div className={classes.link}>
            <p className={classes.styleKey}>READ DATA</p>
          </div>
          <p>
            Grant key access to ALL of the read-only endpoints/methods for
            example getting transactions.
          </p>
        </div>

        <div className={classes.styleLi}>
          <div className={classes.link}>
            <p className={classes.styleKey}>MODIFY ACCOUNT DATA </p>
          </div>
          <p>
            Grant key access to account data write endpoints for example adding
            new whitelist addresses.
          </p>
        </div>

        <div className={classes.styleLi}>
          <div className={classes.link}>
            <p className={classes.styleKey}>ALLOW TRADING</p>
          </div>
          <p>
            Grant key access to all trading write endpoints for example
            requesting quotes/executing trades
          </p>
        </div>

        <div className={classes.styleLi}>
          <div className={classes.link}>
            <p className={classes.styleKey}>ALLOW WITHDRAWALS</p>
          </div>
          <p>Grant key access to make withdrawals from account.</p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Permissons);
