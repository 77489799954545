import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    formRoot: {},
    logo: {
      textAlign: 'center',
      '& img': {
        height: 48,
        margin: '35px 0',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    formBox: {
      padding: '30px 0',
      background: theme.custom.colors.bgrBox,
      borderRadius: 10,
      width: 611,
      margin: 'auto',
      color: theme.custom.colors.white,

      [theme.breakpoints.between('560', '768')]: {
        width: 545,
      },

      [theme.breakpoints.down('xs')]: {
        width: 335,
      },
    },
    formBox2: {
      padding: '30px 0',
      background: theme.custom.colors.bgrBox,
      borderRadius: 10,
      // width: 611,
      margin: 'auto',
      color: theme.custom.colors.white,
      fontFamily: 'Titillium Web',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 335,
      },
    },
    titleSize: {
      fontSize: 24,
    },
    title: {
      fontSize: 32,
      fontWeight: 'bold',
      paddingBottom: 20,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
    },

    formSubmit: {
      margin: 'auto',
      padding: '0 100px',
      [theme.breakpoints.down('xs')]: {
        padding: '0 10px',
      },
    },
    padding: {
      [theme.breakpoints.only('sm')]: {
        padding: '0 100px',
      },
      [theme.breakpoints.only('xs')]: {
        padding: '0 10px',
      },
    },
    titleColor: {
      color: theme.custom.colors.colorLbl,
      fontFamily: 'Titillium Web',
      textAlign: 'center',
    },
    formControl: {
      marginBottom: 30,
      position: 'relative',
    },
    label: {},
    formInput: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '10px 40px 10px 16px',
      borderRadius: 6,
      border: '1px solid',
      borderColor: theme.custom.colors.colorLbl,
      background: 'none',
      color: theme.custom.colors.white,
      height: 50,
      marginTop: 5,

      '&::placeholder': {
        color: theme.custom.colors.placeholder,
      },
      [theme.breakpoints.only('xs')]: {
        height: 40,
      },
    },
    icon: {
      position: 'absolute',
      top: 40,
      right: 12,
      cursor: 'pointer',
    },
    display: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    divBtnDisplay: {
      justifyContent: 'space-between !important',
    },
    marginTop: {
      marginTop: `0 !important`,
    },
    styleCheck: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    },
    link: {
      fontWeight: 700,
      fontSize: 16,
      color: theme.custom.colors.white,
      fontFamily: 'Titillium Web',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    divBtn: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 16,
    },
    btnSubmit: {},
    bottom: {
      textAlign: 'center',
      fontSize: 16,
      color: theme.custom.colors.white,
      fontFamily: 'Titillium Web',
      paddingBottom: 30,
      fontWeight: 300,
    },
    iconHeader: {
      textAlign: 'center',
      [theme.breakpoints.only('xs')]: {
        '& img': {
          width: 75,
        },
      },
    },
    labelContent: {
      textAlign: 'center',
      color: theme.custom.colors.white,
      paddingBottom: 25,
      fontSize: 32,
      fontFamily: 'Titillium Web',
      fontWeight: 'bold',
      [theme.breakpoints.only('xs')]: {
        fontSize: 24,
        paddingBottom: 20,
      },
    },
    formInputOTP: {
      width: 50,
      height: 50,
      borderRadius: 8,
      border: '1px solid #EBECF0',
      background: 'none',
      fontSize: 22,
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.custom.colors.radioChecked,
      '&:focus': {
        color: theme.custom.colors.radioChecked,
        border: '1px solid #EBECF0',
        textAlign: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        width: 45,
        height: 45,
      },
    },
    btnCancel: {
      background: 'none !important',
      border: '1px solid #EBECF0',
      color: theme.custom.colors.colorLbl,
      '&:hover': {
        border: '1px solid #EBECF0',
        opacity: 0.8,
      },
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: `space-between`,
    },
    position: {
      [theme.breakpoints.only('sm')]: {
        // position: 'absolute',
        // left: '50%',
        // top: '50%',
        // transform: 'translate(-50%,-50%)',
      },
    },
  };
});
export default useStyles;
