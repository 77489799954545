import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentMid: {
      marginTop: 40,
      "& .MuiDataGrid-cell": {
            "&:focus-within": {
                outline:'none'
            }
      },
      
     
      "& .MuiDataGrid-main": {
        paddingBottom:15
      },
   
      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
      },
    },

    customerDataGrid: {
      [theme.breakpoints.down("xs")]: {
        height: '90px !important'

      },
  
      
   
    },

    headCompoent: {
      display: "flex",
      justifyContent: "space-between",

      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
        justifyContent: "flex-start",
      },
    },

    groupSearchFilter: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    groupSearch: {
      background: theme.custom.colors.cardBackground,
      border: `1px solid ${theme.custom.colors.comet3}`,
      borderRadius: 6,
      position: "relative",
      overflow: "hidden",
      marginRight: 19,

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginRight: 0,
        marginBottom: 15,
      },

      "& .icon-search": {
        position: "absolute",
        top: "50%",
        left: 8,
        fontSize: 18,
        marginTop: -9,

        "&:before": {
          color: theme.custom.colors.boldGray,
        }
      }
    },

    inputSearch: {
      background: "transparent",
      width: 300,
      height: 50,
      border: "none",
      outline: "none",
      fontSize: 14,
      lineHeight: "140%",
      color: theme.custom.colors.boldGray,
      padding: "10px 8px",
      paddingLeft: 34,
      borderRadius: 6,

      [theme.breakpoints.only("xl")]: {
        width: 330,
      },

      [theme.breakpoints.only("sm")]: {
        width: 220,
      },

      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },

      "&::placeholder": {
        color: theme.custom.colors.boldGray,
      }
    },

    formControlLabel: {


      "& .MuiTypography-root": {
        fontSize: 14,
        lineHeight: "140%",
        color: theme.custom.colors.boldGray,
        fontFamily: 'Ubuntu-Regular',
      },
      '&:hover': {
        background: 'none',
      },

      '& .MuiIconButton-colorSecondary:hover': {
        background: 'none',
      },

      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: 'transparent',
      },

      '& .MuiCheckbox-root': {
        color: 'transparent',
      },

      '& .MuiIconButton-label': {
        background: theme.custom.colors.bgrCheck,

        '& > span': {
          borderColor: '#606983',
        }
      },

      '& .MuiFormControlLabel-root': {
        margin: 0,
      },

    },

    selectpageSize: {
      height: 50,
      width: 297,
      padding: "10px 16px",
      color: theme.custom.colors.boldGray,
      borderRadius: 6,
      background: theme.custom.colors.cardBackground,
      border: `1px solid ${theme.custom.colors.comet3}`,
      fontSize: 14,
      lineHeight: "140%",
      outline: 0,

      [theme.breakpoints.down("xs")]: {
        marginLeft: "auto",
        width: 158,
        maxWidth: "50%",
      },
    },

    symbol: {
      display: "flex",
      lineHeight: "20px",
    },

    symbolInfo: {
    },

    iconCoin: {
      width: 35,
      height: 35,
      marginRight: 10,
    },

    btnAction: {
      minWidth: 82,
      height: 36,
      borderRadius: 6,
      boxShadow: "none",
      textTransform: 'none',
      fontFamily: "Ubuntu-Bold",
      fontSize: 12,
      color: theme.custom.colors.white1,
      background: theme.custom.colors.lightBlue,
      marginRight: 11,

      "&:hover": {
        background: theme.custom.colors.cornflowerBlue,
      },

      "&:last-child": {
        marginRight: 0,
      },
    },

    btnCopy: {
      fontSize: 22,
      marginLeft: 10,
      cursor: "pointer",

      "&:before": {
        color: theme.custom.colors.titleTrading,
      }
    },

    addressTable: {
      display: "flex",
      alignItems: "center",
    },

    noMatch: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 0,
    },

    noData: {
      height: 307,
    },
  };
});

export default useStyles;