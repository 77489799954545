import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    balanceSnapshot: {
      height: 209,
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      paddingTop: 15,
      paddingBottom: 15,
      display: "flex",
      flexDirection: "column",
      marginTop: 20,
    },

    title: {
      fontFamily: "Ubuntu-Bold",
      fontSize: 18,
      lineHeight: "140%",
      color: theme.custom.colors.titleTrading,
      margin: 0,
      marginBottom: 15,
      padding: ' 0 15px',
    },

    listBalanceSnapshots: {
      minHeight: 120,
      maxHeight: 140,
      padding: ' 0 12px 0 15px',
      marginRight: 3,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",

      "&::-webkit-scrollbar": {
        width: 3,
      },

      "&::-webkit-scrollbar-track": {
        background: "transparent",
        borderRadius: 10,
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#9BA1B1",
        borderRadius: 10,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#9BA1B1",
      },
    },

    itemBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 0px",
      borderBottom: `1px solid ${theme.custom.colors.lineTable1}`,

      "&:first-child": {
        paddingTop: 0,
      },

      "&:last-child": {
        borderBottom: 0,
      }
    },

    label: {
      display: "flex",
      alignItems: "center",
    },

    icon: {
      display: "flex",
      alignItems: "center",
      marginRight: 8,

      "& img": {
        width: 30,
        height: 30,
      },
    },

    name: {
      fontSize: 14,
      lineHeight: "140%",
      color: theme.custom.colors.titleTrading,
      textTransform: "uppercase",
    },

    value: {
      fontFamily: "Ubuntu-Bold",
      fontSize: 14,
      lineHeight: "140%",
      color: theme.custom.colors.titleTrading,
      textTransform: "uppercase",
    },

    btnTrading: {
      padding: 5,
      minWidth: 58,
      height: 34,
      background: theme.custom.colors.bgTrading,
      borderRadius: 6,
      fontSize: 20,
      outline: "none",
      boxShadow: "none",
      textTransform: "inherit",

      "& .icon-trading:before": {
        color: theme.custom.colors.white1,
      },

      "&:hover": {
        background: theme.custom.colors.bgTrading,
      }
    },

    overlay: {
      position: 'absolute',
      height: 209,
      // background: theme.custom.colors.bgrOverLay,
      zIndex: 1,
      opacity: .9,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: 8,
      bottom: 0,
      [theme.breakpoints.between("1800", "1950")]: {
        width: 345,
        height: 209,
      },
      [theme.breakpoints.between("1160", "1441")]: {
        width: 280,
        height: 209,
      },
      [theme.breakpoints.between("1000", "1155")]: {
        width: 275
      },
      [theme.breakpoints.between("990", "1025")]: {
        width: 245
      },
      [theme.breakpoints.down("sm")]: {
        width: 352
      },
      [theme.breakpoints.down("xs")]: {
        width: 355,
        height: '100%'
      }
    },
  };
});

export default useStyles;
