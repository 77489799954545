import React, { useState } from 'react';
import ModalCustom from 'src/components/Base/ModalCustom';
import DepositCash from './DepositCash';
import DepositCrypto from './DepositCrypto';
import useStyles from './styles';

export const Deposit = ({
  currency,
  isOpen,
  handleClose,
}: {
  currency?: any;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const classes = useStyles();
  const [isOpenCrypto, setIsOpenCrypto] = useState(false);
  const [isOpenCash, setIsOpenCash] = useState(false);

  if (!currency)
    return (
      <>
        <ModalCustom
          className={classes.mainModalDeposit}
          isOpen={isOpen}
          handleClose={handleClose}
        >
          <div className="body-container">
            <div className="label">Deposit</div>
            <div className="items-container">
              <div className="items" onClick={() => setIsOpenCrypto(true)}>
                <span className="icon-crypto"></span>
                <div className="title">Crypto</div>
              </div>
              <div className="items" onClick={() => setIsOpenCash(true)}>
                <span className="icon-cash"></span>
                <div className="title">Cash</div>
              </div>
            </div>
          </div>
        </ModalCustom>
        {isOpenCrypto && (
          <DepositCrypto
            isOpen={isOpenCrypto}
            handleClose={() => setIsOpenCrypto(false)}
            currency={currency}
          />
        )}
        {isOpenCash && (
          <DepositCash
            isOpen={isOpenCash}
            handleClose={() => setIsOpenCash(false)}
            currency={currency}
          />
        )}
      </>
    );

  return (process.env.REACT_APP_FIAT_LIST || '')
    .split(',')
    .includes(currency.asset) ? (
    <DepositCash
      isOpen={isOpen}
      handleClose={handleClose}
      currency={currency}
    />
  ) : (
    <DepositCrypto
      isOpen={isOpen}
      handleClose={handleClose}
      currency={currency}
    />
  );
};

export default Deposit;
