import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentCoinsHighlights: {
    },

    swiperSlideItem: {
      width: 414,
      [theme.breakpoints.down("lg")]: {
        width: 330,
      },
      [theme.breakpoints.down("sm")]: {
        width: 196,
      },
    },

    coinItem: {
      width: "100%",
      height: 120,
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      padding: 20,
      paddingRight: 5,
      cursor: "pointer",
      [theme.breakpoints.down("lg")]: {
        height: 100,
      },
      [theme.breakpoints.down("sm")]: {
        height: 81,
      },
    },

    coinItemActive: {
      background: "#7F63F4",

      "& div": {
        color: "#FFFFFF",
      }
    },

    iconCoin: {
      width: 70,
      height: 70,
      marginRight: 15,

      "& img": {
        width: "100%",
        height: "100%",
      },

      [theme.breakpoints.down("lg")]: {
        height: 50,
      },
      [theme.breakpoints.down("sm")]: {
        width: 35,
        marginRight: 10,

        "& img": {
          width: 35,
          height: "100%",
        },
      },
    },

    info: {

    },

    value: {
      fontFamily: "Ubuntu-Bold",
      fontSize: 24,
      lineHeight: "28px",
      color: theme.custom.colors.white3,
      marginBottom: 2,

      [theme.breakpoints.down("lg")]: {
        fontSize: 18,
        lineHeight: "140%",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeight: "18px",
      },
    },

    name: {
      fontSize: 18,
      lineHeight: "140%",
      color: theme.custom.colors.white4,
      fontFamily: "Ubuntu-Regular",

      [theme.breakpoints.down("lg")]: {
        fontSize: 14,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    }
  };
});

export default useStyles;