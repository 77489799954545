import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    modalMain: {
      '& .modal-container': {
        '@media (max-width: 767px)': {
          maxWidth: 454,
          paddingTop: '13px',
        },
        '@media (max-width: 600px)': {
          width: '335px',
          paddingTop: '13px',
          minHeight: '45vh',
        },
        '& .body-modal-container': {
          padding: '0 118px',

          [theme.breakpoints.down('md')]: {
            padding: "0 40px",
          },
          '@media (max-width: 767px)': {
            padding: '0 30px',
          },
          '@media (max-width: 600px)': {
            padding: '0 10px',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },

        },
      },
    },
    chooseBank: {
      fontSize: 18,
      margin: '40px auto 30px',
      color: theme.custom.colors.titleTrading,

      [theme.breakpoints.down('xs')]: {
        margin: '20px auto 15px',
      },
    },
    boxBank: {
      textAlign: 'left',
      maxHeight: 300,
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: '0 10px',
      marginBottom: 30,
    },
    itemBank: {
      fontSize: 14,
      background: theme.custom.colors.bgBluGrey,
      border: '1px solid ' + theme.custom.colors.bgBluGrey,
      borderRadius: 6,
      marginBottom: 20,
      padding: 20,
      cursor: 'pointer',
      color: theme.custom.colors.white1,

      '&.active': {
        border: '1px solid ' + theme.custom.colors.cornflowerBlue,
      },

      '&:last-child': {
        marginBottom: 0,
      },
    },
    titleBank: {
      fontWeight: 700,
    },
    subBank: {
      fontFamily: 'Ubuntu-Medium',
      fontWeight: 400,
      marginTop: 8,
      wordBreak: 'break-word',
    },

    formHeader: {
      marginBottom: 40,
    },
    formTitle: {
      fontWeight: 700,
      fontSize: 20,
      width: '100%',
      textAlign: 'center',
      color: theme.custom.colors.titleTrading,
    },
    formDes: {
      fontSize: 16,
      color: theme.custom.colors.santaGray,
      fontFamily: 'Ubuntu-Light',
      width: '100%',
      textAlign: 'center',
    },
    formItem: {
      marginBottom: 20,
    },
    inputItem: {
      width: '100%',
    },
    btnPrimary: {
      background: theme.custom.colors.btnEnable,
      borderRadius: '6px',
      color: theme.custom.colors.btnTitle,
      textTransform: 'none',
      fontSize: '12px',
      lineHeight: '14px',
      width: '126px',
      height: '36px',

      '&:hover': {
        background: theme.custom.colors.btnHover,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    btnInfo: {
      minWidth: 126,
      height: 36,
      borderRadius: 6,
      boxShadow: 'none',
      textTransform: 'none',
      fontFamily: 'Ubuntu-Bold',
      fontSize: 12,
      color: theme.custom.colors.white1,
      background: theme.custom.colors.lightBlue,
      marginRight: 20,

      '&:hover': {
        background: theme.custom.colors.cornflowerBlue,
      },

      '&:last-child': {
        marginRight: 0,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 90,
        marginRight: 12,
      },
    },
    btnPercents: {
      background: theme.custom.colors.bgBluGrey,
      borderRadius: 4,
      height: 36,
      width: 63,
      padding: 3,
      fontSize: 12,
      color: '#FFFFFF',
      lineHeight: '14px',
      outline: 'none',
      boxShadow: 'none',
      paddingTop: 5,
      textTransform: 'inherit',

      '&:hover': {
        background: theme.custom.colors.bgBluGrey,
        boxShadow: 'none',
      },

      '&.active': {
        background: theme.custom.colors.cornflowerBlue,
      },

      '& + button': {
        marginLeft: 14,
      },

      [theme.breakpoints.down('xs')]: {
        '& + button': {
          marginLeft: 6,
        },

      },
    },
    rowBtn: {
      '& > div + div': {
        marginLeft: 14,
      },
    },
    styleCheck: {
      padding: 0,
      marginRight: 0,

      '& .MuiCheckbox-root': {
        padding: 12,
        marginRight: 0,
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    formControlLabel: {
      fontSize: 14,
      lineHeight: '140%',
      color: theme.custom.colors.boldGray,
      fontFamily: 'Ubuntu-Light',

      '&.MuiCheckbox-colorSecondary': {
        color: theme.custom.colors.cornflowerBlue,
      },
      '& .MuiIconButton-colorSecondary:hover': {
        background: 'none !important',
      },

      [theme.breakpoints.down('xs')]: {
        '& .MuiIconButton-colorSecondary': {
          paddingTop: '0px !important',
        },
      },

      '& .MuiFormControlLabel-label': {
        color: theme.custom.colors.white2,
        fontWeight: 400,
      },
    },

    styleLabel: {
      color: `${theme.custom.colors.red1}!important`,
    },
  };
});

export default useStyles;
