import { Container, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import DefaultLayout from "src/components/Layout/DefaultLayout/LandingPageLayout";
import useStyles from "./styles";


const listBlock = [
  {
    id: 1,
    name: "Exchanges",
    text: "Enhance your liquidity offering with our prime brokerage services. The oneAlpha platform allows you to provide OTC trading to your clients as well as add additional liquidity to your order books to help boost your liquidity depth.",
  },
  {
    id: 2,
    name: "ATM Networks",
    text: "We offer electronic RFQ’s to power your cryptocurrency ATM network. Price quotes can be ‘frozen’ for up to 15 seconds before users are required to accept. Improve capital efficiency with same day settlement (in selected markets) supporting USD, HKD, GBP and EUR.",
  },
  {
    id: 3,
    name: "OTC Desks & Trading Platforms ",
    text: "Power up your wallet offering by providing your users with the ability to exchange tokens within your platform. Our zero fee, guaranteed price feeds provide the perfect solution to drive user engagement and utility on your platform.",

  },
  {
    id: 4,
    name: "Custodians",
    text: "Power up your wallet offering by providing your users with the ability to exchange tokens within your platform. Our zero fee, guaranteed price feeds provide the perfect solution to drive user engagement and utility on your platform.",
  }
]
const UsecasesPage = (props: any) => {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Use cases";
  }, []);

  return (
    <DefaultLayout>
      <section className={classes.mainUse}>
        <Container className={classes.containerUse}>
          <Typography variant="h3" className={classes.titleUse}>
            Use cases
          </Typography>

          <div className={classes.groupBlock}>
            <div className={classes.blockDigital}>
              <Typography variant="h4" className={classes.titleBlock}>
                Digital Banks
              </Typography>
              <p className={classes.textBlock}>
                Our platform helps manage the full lifecycle of the trade and
                settlement process. Easily integrate our API into your stack to
                provide your users with deep liquidity, diverse token range and
                rapid settlement workflows to level up user experience.
              </p>
            </div>

            <div className={classes.blockProcess}>
              <div className={classes.styleBlock}>
                <Typography variant="h4" className={classes.titleBlock}>
                  Payment Processors
                </Typography>
                <p className={classes.textBlock}>
                  Our low minimum order sizes, price freeze and instant trade
                  confirmation features removes the day to day volatility risk
                  ensuring all payments taken in crypto can automatically be de-
                  risked. Our integrated fiat settlement services provides an
                  additional layer of value to your service offering.
                </p>
              </div>

              <div className={classes.styleBlock}>
                <Typography variant="h4" className={classes.titleBlock}>
                  Wallet Providers
                </Typography>
                <p className={classes.textBlock}>
                  Power up your wallet offering by providing your users with the
                  ability to exchange tokens within your platform. Our zero fee,
                  guaranteed price feeds provide the perfect solution to drive
                  user engagement and utility on your platform.
                </p>
              </div>
            </div>
          </div>

          <div className={classes.blockDesc}>
            {listBlock?.map((item) => (
              <div className={classes.boxDesc} key={item.id}>
                <Typography variant="h4" className={classes.titleBlock}>
                  {item.name}
                </Typography>
                <p className={classes.textBlock}>{item.text}</p>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </DefaultLayout>
  );
};

export default withRouter(UsecasesPage);
