import { MODAL_CONFIRM } from '../constants/modal-confirm';

export const ModalConfirmAction = {
  show: ({
    subTitle,
    msgError,
    onOK,
    okClose,

  }: {
    subTitle?: string;
    msgError?: string;
    onOK: Function;
    okClose?: Function;
   
  }) => {
    return {
      type: MODAL_CONFIRM.TOGGLE,
      payload: {
        show: true,
        subTitle,
        msgError,
        onOK,
        okClose,
        
      },
    };
  },
  setError: (msg?: string | boolean) => ({
    type: MODAL_CONFIRM.SET_MESSAGE,
    payload: msg,
  }),
  hide: () => ({
    type: MODAL_CONFIRM.HIDE,
    payload: {
      show: false,
      msgError: '',
      onOK: () => {},
      okClose: null,
    },
  }),

  loading: () => ({
    type: MODAL_CONFIRM.LOADING,
    payload: true
  }),

  hideLoading: () => ({
    type: MODAL_CONFIRM.LOADING,
    payload: false
  }),

};
