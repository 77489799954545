import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: any) => {
  return {
    groupSelect: {
      
    },

    selectCoin: {
      position: 'absolute',
      top: 35,
      width: '100%',
      background: theme.custom.colors.cardBackground,
      borderRadius: 5,
      padding: 0,
      boxShadow: "3px 3px 10px 6px rgba(0, 0, 0, 0.06)",
      
      
      "&__select": {
        height: 100,
        overflow: 'auto',
        display: 'block',
        paddingBottom: 10,
        paddingTop: 10
      },
      
       "&__options": {
        margin: 0,
        padding: 0,
         
      },
        
    },
    highlighted: {
      margin:'0 8px',
      borderRadius: "6px",
      background: theme.custom.colors.cornflowerBlue,
       "& div": {
          color: theme.custom.colors.btnTitle,
         
      }
    },

    optionCoin: {
      display: 'flex',
      padding: '5px 8px',
      margin: '0 8px',
       "&__option": {
          display: 'block',
          width: '100%',
          background: 'white',
          "&.is-selected": {
           background:  theme.custom.colors.cornflowerBlue,
          },
          "&.is-highlighted": {
           background:  theme.custom.colors.cornflowerBlue,
        }
        },
      
      "& img": {
        width: 18,
        height: 18
      },
        
     
      "&:hover": {
        background: theme.custom.colors.cornflowerBlue,
        margin:'0 8px',
        borderRadius: "6px",
        "& div": {
          color: theme.custom.colors.btnTitle,
         
      }
      },
      
    },

    inputGroup: {
      margin: '8px 8px 0'
    },
    
    inputCoin: {
      textIndent: '10px',
      height: 30,
      background: 'none',
      borderRadius: '5px',
      color: theme.custom.colors.textContentCard,
      fontWeight: 500,
      fontSize: 14,
      borderColor: theme.custom.colors.textContentCard,
      border: '1px solid',
      width: '100%',
      "&:focus": {
        borderColor: theme.custom.colors.lightPurple,
        outline: 'none'
      },
      "&::placeholder": {
        color:  theme.custom.colors.textContentCard,
      }
    },
    iconSearch: {
      position: 'absolute',
      top: 10,
      right: 10,
      fontSize: 25,
      '&:before': {
        color: theme.custom.colors.textContentCard,
      },
    },
      selectContainer: {
      width: "91px"
    },
    dropdown: {
      minWidth: "91px",
      width: "auto",

      userSelect: "none",
      position: "relative",
      zIndex: 5,

      fontFamily: 'Ubuntu-Regular',
      fontStyle: "normal",
      fontWeight: "normal"
    },
    dropdownBtn: {
      width: 140,
      height: "35px",
      borderRadius: "6px",
      padding: "8px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      color: theme.custom.colors.titleTrading,
      border: `1px solid ${theme.custom.colors.comet3}`,
      background: theme.custom.colors.bgrButton,
      fontWeight: "bold",
      cursor: "pointer"
    },
    dropdownContent: {
      width: "100%",
      height: "150px",
      overflow: "auto",
      borderRadius: "6px",
      position: "absolute",
      top: "110%",
      left: "0",
      background: theme.custom.colors.cardBackground,
      padding: "10px",
      fontWeight: 500,
      color: theme.custom.colors.white4,
      boxShadow: "3px 3px 10px 6px rgba(0, 0, 0, 0.06)",
    },

    dropdownItem: {
      padding: "5px",
      cursor: "pointer",
      transition: "all 0.2s",
      display: "flex",
      alignItems: "center",
      fontFamily: 'Ubuntu-Regular',
      "&:hover": {
        background: theme.custom.colors.cornflowerBlue,
        borderRadius: "6px",
        "& $coinSymbol": {
        color: theme.custom.colors.btnTitle
      }
      },

      
    },
    selectedItem: {
       fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '130%',
      display: 'flex',
      alignItems: 'center',
      color: theme.custom.colors.titleTrading,
      marginRight: 12,
      "& img": {
        marginRight: 5
      }
    },
    anchor: {
      marginLeft: "5px",
      fontSize: "14px",
      lineHeight: "130%"
    },
    arrowIcon: {
     

      '& .icon-arrow-down:before': {
        color: theme.custom.colors.titleTrading,
        fontSize: 12,
      },

      '& .icon-arrow-up:before': {
        color: theme.custom.colors.titleTrading,
        fontSize: 12,
      },
    },
    coinLogo: {
      width: "15px",
      height: "15px"
    },

    coinSymbol: {
      marginLeft: "10px",
      fontSize: "14px",
      lineHeight: "130%",
      color: theme.custom.colors.white4,
      fontFamily: 'Ubuntu-Regular'
    },

    
  

    
    
  }
});
export default useStyles;