import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      textAlign: 'center',
      alignItems: 'center',

      '& .modal-container': {},

      '& .body-modal-container': {
        padding: '0 118px',
        maxHeight: '75vh',
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: '0 20px',
          minHeight: '30vh !important',
        },
      },
    },
    title: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 20,
      lineHeight: '115%',
      color: theme.custom.colors.titleTrading,
      marginBottom: 20,
    },
    subTitle: {
      fontFamily: 'Ubuntu-Medium',
      fontSize: 14,
      color: theme.custom.colors.titleTrading,
      lineHeight: '20px',
      marginTop: 20,
      marginBottom: 10,
    },
    inputCode: {
      marginBottom: 30,
    },
    textError: {
      fontFamily: 'Ubuntu-Medium',
      fontSize: 12,
      color: '#DE4949',
      lineHeight: '14px',
      textAlign: 'center',
      // marginTop: 10,
      marginBottom: 20,
    },
    btnGroup2: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      gridGap: 10,
      marginTop: 10,
    },
    btnSubmit: {
      fontFamily: 'Ubuntu-Bold',
      background: theme.custom.colors.cornflowerBlue,
      borderRadius: 6,
      minHeight: 36,
      minWidth: 'auto',
      fontSize: 12,
      color: '#FFFFFF',
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',
      marginTop: '2.5rem',
      width: '126px',

      '&:hover': {
        background: theme.custom.colors.cornflowerBlue,
        boxShadow: 'none',
      },

      '&:disabled': {
        background: theme.custom.colors.bgDisable,
        color: '#FFF',
      },
    },
    btnCancel: {
      borderRadius: '6px',
      color: '#FFF',
      backgroundColor: '#606983',
      fontFamily: 'Ubuntu-Bold',
      fontSize: '12px',
      minHeight: 36,
      width: '126px',
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',
      marginTop: '2.5rem',

      '&:hover': {
        background: '#606983',
        boxShadow: 'none',
      },
    },
    customize: {
      display: 'flex !important',
      justifyContent: 'center',
      // padding: '0 100px',
      '& input': {
        width: 36,
        height: 36,
        fontSize: 14,
        background: 'none',
        color: theme.custom.colors.lightPurple,
        borderRadius: 5,
        margin: '0 4px',
        textAlign: 'center',
        border: '1px solid #EBECF0',
        outline: 'none',
        '&:focus': {
          color: theme.custom.colors.lightPurple,
          textAlign: 'center',
          border: '1px solid #B8A7FF !important',
          boxShadow: `0 6px 6px -6px #B8A7FF`,
        },
      },
      [theme.breakpoints.only('xs')]: {
        padding: '0 10px',
      },
    },
    borderColor: {
      '& input': {
        border: '1px solid #B8A7FF',
      },
    },
  };
});

export default useStyles;
