import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useStyles from './styles';

const ComponentCoinsHighlights = (props: any) => {
  const styles = useStyles();
  const { setActiveCoin, activeCoin, balances } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    document.title = 'Wallet';
    getCoins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balances]);

  const listCoins: any = [];

  const getCoins = () => {
    const btc = balances.find((x: any) => x.asset === 'BTC');
    if (btc) {
      btc.icon = '/images/coin-btc.svg';
      listCoins.push(btc);
    }
    const usd = balances.find((x: any) => x.asset === 'USD');
    if (usd) {
      usd.icon = '/images/coin-usd.svg';
      listCoins.push(usd);
    }
    const eth = balances.find((x: any) => x.asset === 'ETH');
    if (eth) {
      eth.icon = '/images/coin-eth.svg';
      listCoins.push(eth);
    }
    const ltc = balances.find((x: any) => x.asset === 'LTC');
    if (ltc) {
      ltc.icon = '/images/coin-ltc.svg';
      listCoins.push(ltc);
    }
    setData(listCoins);
  };
  return (
    <section className={styles.componentCoinsHighlights}>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={20}
        className="mySwiper"
        breakpoints={{
          '1536': {
            spaceBetween: 24,
          },
          '1': {
            spaceBetween: 20,
          },
        }}
      >
        {data?.map((item: any) => {
          return (
            <SwiperSlide key={item.id} className={styles.swiperSlideItem}>
              <div
                className={`${styles.coinItem} ${
                  item.asset === activeCoin.asset && styles.coinItemActive
                }`}
                onClick={() => setActiveCoin(item)}
              >
                <div className={styles.iconCoin}>
                  <img src={item.icon} alt="" />
                </div>
                <div className={styles.info}>
                  <div className={styles.value}>
                    {item.available} {item.asset}
                  </div>
                  <div className={styles.name}>{item.name}</div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default ComponentCoinsHighlights;
