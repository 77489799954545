import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { ZIndex } from '@material-ui/core/styles/zIndex';

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    negative: number;
  }
}

declare module '@material-ui/core/styles' {
  interface Theme {
    zIndex: ZIndex;
  }

  interface ThemeOptions {
    custom?: any;
  }
}

export const createLemonadeTheme = (options: ThemeOptions = {}) => {
  return createTheme(options);
};

const themeOptions = {
  colors: {
    primary: '#FFCC00',
    secondary: '#3A39BB',
    primaryText: '#636363',
    secondaryText: '#363636',
    metamask: '#FF8F44',
    mainBackground: '#040929',
    cornflowerBlue: '#7F63F4',
    mainHeader: '#040929',
    white: '#FFFFFF',
    placeholder: '#9BA1B1',
    btnSignUp: '#7F63F4',
    textdesc: '#F3F3F3',
    bgrBox: '#2C2949',
    textTitle: '#001856',
    bgrColor: '#2C2949',
    textdetail: '#243359',
    bgrLine: '#373C4E',
    bgrDot: '#040929',
    bgrDot2: '#040929',
    colorLbl: '#EBECF0',
    radioChecked: '#B8A7FF',
    checkedBg: '#453b7c',
    colorButton: '#ABABAB',
    colorRead: '#B8A7FF',
    bgRow: '#F2F6F9',
    colorText: '#384464',
    colorDay: '#C7C9D0',
    textTerm: '#384464',
    textDetail: '#7F63F4',
    colorBorder: '#373C4E',
    textError: '#DE4949',
    borderError: '#DE4949',
    bgDisable: '#606983',
    lightPurple: '#7F63F4',
    hoverButton: '#563BCA',
    textCheckBox: '#E8EDFA',
    textBottom: ' #E8EDFA',
    btnDisable: '#6069834d',
    themeCards: '#373C4E',
    gray4: '#EBECF0',
  },
};

const defaultTheme = createLemonadeTheme({
  custom: themeOptions,
});

export { themeOptions };
export default defaultTheme;
