import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultLayout from 'src/components/Layout/DefaultLayout/LandingPageLayout';
import PrivacyComponent from './PrivacyComponent';

const PrivacyPage = (props: any) => {
  const history = useHistory();
  const [isFooter, setFooter] = useState(false);

  useEffect(() => {
    if (history.location.pathname === '/privacy') {
      setFooter(true);
    }
  }, [history.location.pathname]);

  return (
    <DefaultLayout isFooter={isFooter}>
      <PrivacyComponent />
    </DefaultLayout>
  );
};
export default PrivacyPage;
