import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import SecurityCard from './SecurityElement/SecurityCard';
import useStyles from './styles';

const SecurityPage = (props: any) => {
  const accountState = useSelector((state: any) => {
    return state.accountReducer;
  });
  let listSecurityDef = [
    {
      id: 1,
      icon: 'icon-reset-password',
      title: 'Reset Password',
      content: 'Please use the below form to reset your account password',
      btnReset: 'Reset',
    },
    {
      id: 2,
      icon: 'icon-two-factor-authentication',
      title: 'Two factor authentication',
      content: `${
        accountState.has2FADevices
          ? '2fa is active on your account, click below to deactivate (not recommended).'
          : '2fa is not currently active on your account but is highly recommended for your security. Please activate now.'
      }`,
      btnActive: 'Activate',
    },
    {
      id: 3,
      icon: 'icon-whitelist-addresses',
      title: 'Whitelist Addresses',
      content:
        'Whitelisted addresses do not require confirmation for withdrawals 24h after adding.',
      btnViewList: 'View list',
      btnAdd: 'Add',
    },
  ];

  const styles = useStyles();
  const handleActionReset = (id: any) => {};
  const handleActioncActive = (id: any) => {};
  const handleActionAdd = (id: any) => {};
  const handleActionViewList = (id: any) => {};

  useEffect(() => {
    document.title = 'Security';
  }, []);

  return (
    <DefaultLayout>
      <Typography className={styles.title}>Security</Typography>
      <Grid container spacing={2} className={styles.gridContainer}>
        {listSecurityDef.map((item) => (
          <Grid item key={item.id} sm={12} md={4}>
            <SecurityCard
              {...item}
              actionReset={handleActionReset}
              actionActive={handleActioncActive}
              actionAdd={handleActionAdd}
              actionViewList={handleActionViewList}
            />
          </Grid>
        ))}
      </Grid>
    </DefaultLayout>
  );
};

export default withRouter(SecurityPage);
