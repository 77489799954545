import React, { useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router';

import InputField from 'src/components/BaseLandingPage/Common/Input';
import RadioList from 'src/components/BaseLandingPage/Common/Radio';
import CheckboxCommon from 'src/components/BaseLandingPage/Common/CheckBox';
import ButtonCommon from 'src/components/BaseLandingPage/Common/Button';
import MessageInline from 'src/components/BaseLandingPage/Common/MessageInline';
import Link from 'src/components/Base/Link';

import {
  MIN_LENGTH,
  REGEX_PASSWORD,
  REGEX_USERNAME,
} from 'src/utils/validation';
import { PAGE } from 'src/utils/utils';
import { MAX_LENGTH } from 'src/utils/validation';

import useStyles from './styles';

export interface SingUpFromInput {
  fullName: string;
  username: string;
  email: string;
  corpIndi: string;
  type: string;
  password: string;
  confirmPassword: string;
  terms_checked: boolean;
  token: string;
}

const SignUpComponent = (props: any) => {
  const { actionSignUp, msg, isLoading } = props;
  const history = useHistory();

  const style = useStyles();
  const methodsForm = useForm<SingUpFromInput>({
    mode: 'onChange',
    defaultValues: {
      corpIndi: 'Individual',
    },
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isValid },
    setValue,
    getValues,
    register,
    setError,
    clearErrors,
  } = methodsForm;
  const passwordRef = useRef({});
  passwordRef.current = watch('password');

  useEffect(() => {
    register('token', { required: true });
  }, [register]);

  const onChangeCAPTCHA = (value: string | null) => {
    setValue('token', value || '', {
      shouldValidate: true,
    });
  };
  const onSubmit = (data: any) => {
    actionSignUp(data);
    setValue('token', '', {
      shouldValidate: true,
    });
  };
  const toLogin = () => {
    history.push('/login');
  };

  return (
    <div className={style.signUpRoot}>
      <div className={style.logoBox}>
        <Link href="/">
          <img src="/images/logo.png" alt="" className={style.logo} />
        </Link>
      </div>
      <div className={style.signUpBox}>
        <Typography className={style.title}>Sign up</Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={style.formSubmit}>
          <InputField
            {...methodsForm}
            label={'Full name'}
            name={'fullName'}
            className={style.labelInput}
            maxLength={MAX_LENGTH.FULL_NAME}
            validation={{
              required: true,
            }}
          />
          <InputField
            {...methodsForm}
            label={'Username'}
            name={'username'}
            className={style.labelInput}
            maxLength={MAX_LENGTH.USER_NAME}
            validation={{
              required: true,
              pattern: {
                value: REGEX_USERNAME,
                message:
                  'Allowed characters: A-Z, a-z, apostrophe, space, dash',
              },
            }}
          />
          <InputField
            {...methodsForm}
            type="email"
            label={'Email'}
            name={'email'}
            className={style.labelInput}
            validation={{ required: true }}
          />
          <RadioList
            items={[
              {
                label: 'Private account',
                value: 'Individual',
              },
              {
                label: 'Corporate account',
                value: 'Corporate',
              },
            ]}
            control={control}
            name={'corpIndi'}
          />
          <InputField
            {...methodsForm}
            type="password"
            label={'Password'}
            name={'password'}
            className={style.labelInput}
            maxLength={MAX_LENGTH.PASSWORD}
            validation={{
              required: true,
              minLength: {
                value: MIN_LENGTH.PASSWORD,
                message:
                  'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
              },
              pattern: {
                value: REGEX_PASSWORD,
                message:
                  'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
              },
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const password = e.target.value;
              const confirmPassword = getValues('confirmPassword');
              setValue('password', password, {
                shouldValidate: true,
              });

              if (
                confirmPassword?.length >= MIN_LENGTH.PASSWORD &&
                password?.length >= MIN_LENGTH.PASSWORD
              )
                if (password !== confirmPassword) {
                  setError('confirmPassword', {
                    type: 'pattern',
                    message: 'The password confirmation does not match',
                  });
                } else {
                  clearErrors('confirmPassword');
                }
            }}
          />
          <InputField
            {...methodsForm}
            type="password"
            label={'Confirm Password'}
            name={'confirmPassword'}
            className={style.labelInput}
            modeLogin
            maxLength={MAX_LENGTH.PASSWORD}
            validation={{
              required: true,
              validate: {
                match: (value) =>
                  value === passwordRef.current ||
                  'The password confirmation does not match',
              },
            }}
          />
          <CheckboxCommon
            {...methodsForm}
            nameCheck="terms_checked"
            label={
              <>
                <span className={style.styleLbl}>I agree to oneAlpha’s</span>{' '}
                <Link
                  className={style.link}
                  onClick={() => history.push(PAGE.TERM)}
                >
                  Terms & Conditions
                </Link>{' '}
                <span className={style.styleLbl}>and</span>{' '}
                <Link
                  className={style.link}
                  onClick={() => history.push(PAGE.PRIVACY)}
                >
                  Privacy Policy
                </Link>
              </>
            }
          />
          <div className={style.captcha}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA || ''}
              onChange={onChangeCAPTCHA}
            />
          </div>
          {msg && <MessageInline msg={msg} />}
          <div className={style.divBtn}>
            <ButtonCommon
              type="submit"
              disabled={!isDirty || !isValid}
              isLoading={isLoading}
              className={isLoading ? style.active : ''}
            >
              Request account
            </ButtonCommon>
          </div>
          <div className={style.login}>
            <label>
              Already have an account?{' '}
              <Link className={style.link} onClick={toLogin}>
                Log in
              </Link>
            </label>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SignUpComponent;
