import { useState } from 'react';
import { Container, List, Typography } from '@material-ui/core';
import ModalRequest from 'src/routing/LandingPage/HomePage/Mainhome-1/ModalRequest';
import Link from 'src/components/Base/Link';

import { activeMenu } from 'src/utils/utils';

import useStyles from './styles';

const listMenuDef = [
  {
    id: 1,
    name: 'Liquidity',
    section: '#liquidity',
    hardAnchor: true,
  },
  {
    id: 2,
    name: 'Settlement',
    section: '#settlement',
    hardAnchor: true,
  },
  {
    id: 3,
    name: 'Alpha API',
    path: '/login',
  },
  {
    id: 4,
    name: 'How it works',
    path: '/usecase',
  },
];

const listMenuFoot = [
  {
    id: 1,
    name: 'Terms of use',
    path: '/term',
  },
  {
    id: 2,
    name: 'Privacy Policy',
    path: '/privacy',
  },
];

const Footer = (props: any) => {
  const classes = useStyles();
  const [listMenu] = useState(listMenuDef);
  const [listMenuDif] = useState(listMenuFoot);
  const date = new Date().getFullYear();
  const [modalShow, setModalShow] = useState(false);

  return (
    <footer className={classes.mainFooter}>
      <Container className={classes.styleFoot}>
        <div className={classes.footTop}>
          <Link href="/" className={classes.logoFooter}>
            <img src="/images/logo.png" alt="" />
          </Link>

          <div className={classes.detailFoot}>
            <div className={classes.menuFooter}>
              <List className={classes.listMenuFooter}>
                {listMenu?.map((item) => (
                  <li key={item.id}>
                    <Link
                      key={item.id}
                      hardAnchor={item.hardAnchor}
                      href={item.section ? `/${item.section}` : item.path}
                      className={`${classes.itemMenuFooter} ${activeMenu(
                        item,
                        classes.itemMenuFooterActive
                      )}`}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </List>
            </div>

            <div className={classes.styleContact}>
              <Typography
                variant="h6"
                className={classes.titleContact}
                onClick={() => setModalShow(true)}
              >
                Contact us
              </Typography>
              <ModalRequest open={modalShow} setModalShow={setModalShow} />

              <p className={classes.detailContact}>support@onealpha.io</p>
              <p className={classes.detailContact}>+10000000000000</p>
            </div>

            <div className={classes.menuFooter}>
              <List className={classes.listMenuFooter}>
                {listMenuDif?.map((item) => (
                  <li key={item.id}>
                    <Link
                      key={item.id}
                      href={`${item.path}`}
                      className={`${classes.itemMenuFooter} ${activeMenu(
                        item,
                        classes.itemMenuFooterActive
                      )}`}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </List>
            </div>
          </div>
        </div>

        <div className={classes.footBottom}>
          <p className={classes.textBottom}>
            © <span>{date}</span> oneAlpha All Rights Reserved
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
