import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {

    title: {
      fontFamily: 'Ubuntu-bold',
      fontSize: 20,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',

      '& .icon-info': {
        marginLeft: '10px',
        fontSize: 24,
      },
      '& .icon-info:before': {
        color: theme.custom.colors.white5,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    titleItem: {
      fontFamily: 'Ubuntu-bold',
      fontSize: 20,
      lineHeight: '23px',

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    checkBox: {
      width: '100%',
      marginTop: '15px',
      fontFamily: 'Ubuntu-Regular',
      fontSize: '14px',
      lineHeight: '20px',
    },

    label: {
      fontFamily: 'Ubuntu-Medium',
      fontSize: '14px',
      lineHeight: '20px',
      margin: '20px 0 2px',


      '& .MuiIconButton-colorSecondary:hover': {
        background: 'none',
      },

      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: 'transparent',
      },

      '& .MuiCheckbox-root': {
        color: 'transparent',
      },
    },

    inputItem: {
      width: '100%',
      marginTop: '20px',

      '& input': {
        '&::placeholder': {
          color: theme.custom.colors.colorPlace,
        },
      },
      "& .Mui-error": {
        color: `${theme.custom.colors.red1}!important`,
      }
    },

    labelError: {
       color: `${theme.custom.colors.red1}!important`,
    },

    fileUpload: {
      marginTop: '40px',
    },

    '& .MuiFormControl-root': {
      width: '100%',
      marginTop: '30px',
    },

    numberPhone: {
      marginTop: '8px',
    },

    btnClear: {
      display: 'none',
    },

    styleLabel: {
      color: `${theme.custom.colors.red1}!important`
    },

    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '40px',
    },

    btnLink: {
      fontFamily: 'Ubuntu-Regular',
      color: theme.custom.colors.cornflowerBlue,
      textDecoration: 'underline',
      cursor: 'pointer',
      boxSizing: 'unset',
      "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
      width: 'fit-content',

      "&:active": {
        background: "unset",
      },

      [theme.breakpoints.down('xs')]: {
        outline: 'none',
      },

    },
  };
});

export default useStyles;
