import React, { InputHTMLAttributes } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';

import useStyles from './styles';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

interface DateRangePickerProps extends ReactDatePickerProps {}

const CustomInput = React.forwardRef<
  null,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => (
  <div {...props} tabIndex={props.tabIndex || 0} ref={ref}>
    {props.value || props.placeholder}
  </div>
));

const DateRangePicker: React.FC<any> = (props: DateRangePickerProps) => {
  const {
    startDate,
    endDate,
    maxDate,
    className,
    dateFormat = 'yyyy/MM/dd',
    placeholderText = 'Start date - End date',
    popperClassName,
    onChange,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <>
      {<DateRangeIcon className={classes.iconDatePicker} />}
      <ReactDatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        className={classes.datePicker}
        popperClassName={classes.datePickerPopper}
        placeholderText={placeholderText}
        customInput={<CustomInput className={classes.input} />}
        isClearable
        onChange={onChange}
        {...rest}
      />
    </>
  );
};

export default DateRangePicker;
