import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
    return {

        mainModalConfirm: {
            "& .modal-container": {
                maxWidth: 508,
                height: 321,
                [theme.breakpoints.down('xs')]: {
                    width: 335,
                    padding: 20
                },

                "& .body-modal-container": {
                    padding: "0 90px",

                    [theme.breakpoints.down('sm')]: {
                        padding: "0 20px",
                    },
                    [theme.breakpoints.down('xs')]: {
                        padding: "0 5px",
                    },
                },
            },
        },

        mainModal: {},

        titleModal: {
            fontSize: "20px",
            lineHeight: "23px",
            margin: "0 0 40px",
            color: theme.custom.colors.titleTrading,
        },

        contentModal: {

        },

        textModal: {
            fontSize: "16px",
            lineHeight: "140%",
            color: theme.custom.colors.white2,
            fontFamily: "Ubuntu-Regular",
            marginBottom: 0,

            "& span": {
                fontSize: "20px",
                lineHeight: "23px",
                fontWeight: 700,
            },
        },

        styleText: {
            marginBottom: 0,
        },


        textSub: {
            fontSize: "12px",
            lineHeight: "14px",
            color: theme.custom.colors.white2,
            fontFamily: "Ubuntu-Regular",
        },

        groupBtn: {
            marginTop: 45,
            display: 'flex',
            justifyContent:'center'
        },

        btnConfirm: {
            background: theme.custom.colors.btnEnable,
            borderRadius: 6,
            height: 36,
            width: 126,
            fontSize: 14,
            fontWeight: 700,
            color: theme.custom.colors.btnTitle,
            textTransform: "none",
            marginRight: 10,
            boxShadow: "none",

            "&:hover": {
                background: theme.custom.colors.btnHover,
            },
        },

        btnCan: {
            width: "126px",
            height: "36px",
            borderRadius: "6px",
            color: theme.custom.colors.titleTrading,
            textTransform: "none",
            marginRight: 20,
            border: `1px solid ${theme.custom.colors.borderCancel}`,

            "&:hover": {
                background:'none',
                color: theme.custom.colors.btnEnable,
                border: `1px solid ${theme.custom.colors.btnEnable}`,
            },
        },


    };
});

export default useStyles;