import { HTTPBaseService } from "src/request/HTTPBaseService";

export class MarketService extends HTTPBaseService {

    private static classInstance?: MarketService;

    public static getInstance(token: string) {
        if (!this.classInstance) {
            this.classInstance = new MarketService();
        }
        return this.classInstance;
    }

    public async getAllMarkets() {
        return await this.instance.get('/market/markets/', {
            transformRequest: [(data, headers) => {
                delete headers.Authorization;
                return data
            }]
        })
    }

    public async getAllLimit() {
        const buildURL = `/limits/get_all_limits/`;
        return await this.instance.get(buildURL, {
            transformRequest: [(data, headers) => {
                headers['x-requested-with'] = '';
                delete headers.Authorization;
                return data
            }]
        })
    }

    public async getPriceChangeMarket(data: any) {
        const buildURL = `/private/pricing/change/?symbol=${data.join(",")}`;
        return await this.instance.get(buildURL, {
            transformRequest: [(data, headers) => {
                headers['x-requested-with'] = '';
                delete headers.Authorization;
                return data
            }]
        })
    }

    public async priceAmount(data: any) {
        return await this.instance.post('/pricing/price_for_amount/', data);
    }

    public async getPricing(data: any) {
        return await this.instance.get('/pricing/', {
            params: data,
            baseURL: `${process.env.REACT_APP_HEROKU_PROXY}${process.env.REACT_APP_SANDBOX_API}`,
            transformRequest: [(data, headers) => {
                return data
            }]
        });
    }

    public async getMultiplePricing(bodyData: any) {
        return await this.instance.post('/pricing/', JSON.stringify(bodyData));
    }

    public async execBestRate(data: any) {
        return await this.instance.get('/order/exec_best_rate/', data);
    }

    public async getMarketsInfo(data: any) {
        return await this.instance.get('/coins/markets', {
            baseURL: `https://api.coingecko.com/api/v3`,
            params: data,
            transformRequest: [(data, headers) => {
                delete headers.Authorization;
                return data
            }]
        });
    }
}

// export instance of market service
export const marketService = new MarketService();