import { AnyAction } from 'redux';
import { IGetAddressParam } from 'src/services/AddressBookService';
import {
  ADDRESS_ADD,
  ADDRESS_HIDE,
  ADDRESS_SET,
  ADDRESS_SHOW,
  ADDRESS_TOGGLE,
} from '../constants/address';

const params: IGetAddressParam = {
  page_size: 6,
  page: 1,
  type: 'WITHDRAWAL',
};

const initialState = {
  show: false,
  isWhitelist: false,
  isWithdrawal: false,
  assetSelected: '',
  params: params,
  list: {
    items: [],
  },
};
export interface IAddressList {
  items: IAddressItem[];
  page: number;
  page_size: number;
  pages_total: number;
  items_returned: number;
  items_total: number;
}

export interface IAddressItem {
  id: number;
  type: string;
  address_title: string;
  classification: string;
  asset: string;
  address: string;
  memo: string;
  chain_id: number;
  whitelisted: boolean;
  created_at: number;
  updated_at: number;
}

export const addressReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ADDRESS_TOGGLE:
      return {
        ...state,
        show: !state.show,
        isWhitelist: action.isWhitelist,
        isWithdrawal: action.isWithdrawal,
      };
    case ADDRESS_SHOW:
      return {
        ...state,
        show: action.payload.show,
        assetSelected: action.payload.assetSelected,
      };
    case ADDRESS_HIDE:
      return {
        ...state,
        show: false,
      };
    case ADDRESS_ADD:
      return {
        ...state,
        list: {
          ...state.list,
          items: [...state.list.items, action.payload],
        },
      };
    case ADDRESS_SET:
      return {
        ...state,
        list: action.payload,
        params: action.params,
      };

    default:
      return state;
  }
};
