/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import AppInput from 'src/components/Base/AppInput';
import Checkbox from 'src/components/Base/Checkbox';
import ModalCustom from 'src/components/Base/ModalCustom';
import Select from 'src/components/Base/Select';
import {
  AddressBookService,
  ICreateAddressParam,
} from 'src/services/AddressBookService';
import { AddressBookAction } from 'src/store/actions/address';
import { showModalMessage } from 'src/store/actions/message';
import { hideModalMessage } from 'src/store/actions/message';
import { REGEX } from 'src/utils/validation';
import AppButton from 'src/components/Base/AppButton';
import { ModalConfirmAction } from 'src/store/actions/modal-confirm';
import { AssetAction } from 'src/store/actions/asset';
import { ChainAction } from 'src/store/actions/chain';

import useStyles from './styles';

const addressTypeArr = [
  {
    label: 'Coinbase Pro',
    value: 'Exchange-Coinbase Pro',
  },
  {
    label: 'Huobi Global',
    value: 'Exchange-Huobi Global',
  },
  {
    label: 'Kraken',
    value: 'Exchange-Kraken',
  },
  {
    label: 'Bitfinex',
    value: 'Exchange-Bitfinex',
  },
  {
    label: 'Gate.io',
    value: 'Exchange-Gate.io',
  },
  {
    label: 'FTX',
    value: 'Exchange-FTX',
  },
  {
    label: 'Binance',
    value: 'Exchange-Binance',
  },
  {
    label: 'Bithumb',
    value: 'Exchange-Bithumb',
  },
  {
    label: 'Bitstamp',
    value: 'Exchange-Bitstamp',
  },
  {
    label: 'Coinone',
    value: 'Exchange-Coinone',
  },
  {
    label: 'Bittrex',
    value: 'Exchange-Bittrex',
  },
  {
    label: 'Gemini',
    value: 'Exchange-Gemini',
  },
  {
    label: 'bitFlyer',
    value: 'Exchange-bitFlyer',
  },
  {
    label: 'Poloniex',
    value: 'Exchange-Poloniex',
  },
  {
    label: 'Liquid',
    value: 'Exchange-Liquid',
  },
  {
    label: 'OKEx',
    value: 'Exchange-OKEx',
  },

  {
    label: 'Trust Wallet',
    value: 'Trust Wallet',
  },
  {
    label: 'MetaMask',
    value: 'MetaMask',
  },
  {
    label: 'Exodus',
    value: 'Exodus',
  },
  {
    label: 'MyEtherWallet',
    value: 'MyEtherWallet',
  },
  {
    label: 'BitGo',
    value: 'BitGo',
  },
  {
    label: 'SafePal',
    value: 'SafePal',
  },
  {
    label: 'Arkane',
    value: 'Arkane',
  },
  {
    label: 'TokenPocket',
    value: 'TokenPocket',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

export default function AddAddress() {
  const classes = useStyles();
  const has2FA = useSelector(
    (state: any) => state.accountReducer.has2FADevices
  );
  const { show, isWhitelist, isWithdrawal } = useSelector(
    (state: any) => state.address
  );
  const assetSelected = useSelector((state: any) => state.asset.assetSelected);
  const [isOther, setIsOther] = useState(false);
  const [isChain, setIsChain] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const addressBookService = AddressBookService.getInstance();
  const [chainFilter, setChainfilter] = useState([]);
  const [isSubmit, setSubmit] = useState(false);

  const createAssetBody = {
    asset: '',
  };
  const chains = useSelector((state: any) => state.chain.items).map(
    (item: any) => {
      return {
        label: item.name,
        value: item.id,
      };
    }
  );
  const assets = useSelector((state: any) => state.asset.assets).map(
    (v: any) => {
      return {
        label: v,
        value: v,
      };
    }
  );

  const dispatch = useDispatch();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    setError,
    setValue,
    getValues,
    reset,
    register,
  } = useForm<ICreateAddressParam>({
    mode: 'onChange',
    defaultValues: {
      chain: '',
      classification: '',
    },
  });
  useEffect(() => {
    isWhitelist && setValue('whitelist', true);
    setValue('asset', assetSelected);
  }, [show]);

  useEffect(() => {
    dispatch(AssetAction.set());
    dispatch(ChainAction.set());
  }, [dispatch]);

  useEffect(() => {
    isWithdrawal && handleGetCoin(assetSelected);
  }, [isWithdrawal]);

  useEffect(() => {
    register('asset', { required: true });
    register('classification', { required: true });
  }, [register, show]);

  const handleCheckOther = (item: any) => {
    if (item === 'Others') {
      setValue('classification', '');
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  };

  const handleGetCoin = async (item: any) => {
    createAssetBody.asset = item;
    try {
      const res = await addressBookService.createAsseet(createAssetBody);
      if (res.status === 200) {
        const newChains = chains.filter((item: any) =>
          res?.data?.chains.includes(item.value)
        );
        setChainfilter(newChains);
      } else {
        throw new Error();
      }
    } catch (error) {
      dispatch(
        showModalMessage({
          type: 'ERROR',
          msg: 'System error!',
        })
      );
    }
  };

  const handleCheckChain = (item: any) => {
    setIsChain(item === 7 || item === 5);
  };

  const onSubmit = async (data: ICreateAddressParam) => {
    setSubmit(true);
    try {
      const res = await addressBookService.createAddress(data);
      if (res.status === 200) {
        // Exist address
        res.data.address_title === data.address_title &&
          setError('address_title', { message: 'Title already exists' });
        res.data.address === data.withdrawal_address &&
          setError('withdrawal_address', {
            message: 'Address already exists',
          });
      } else if (res.status === 201) {
        // Success
        checkResponse(res);
      } else {
        // Error
        throw new Error();
      }
    } catch (error) {
      dispatch(
        showModalMessage({
          type: 'ERROR',
          msg: 'System error!',
        })
      );
    } finally {
      setSubmit(false);
    }
  };

  const checkResponse = async (res: any) => {
    if (getValues('whitelist')) {
      // Request email
      setValue('id', res.data?.id);
      onSubmitConfirmCode();

      // show modal confirm whitelist
      dispatch(
        showModalMessage({
          btnCancel: {
            label: 'Cancel',
            show: true,
          },
          btnOk: {
            label: 'Whitelist',
            show: true,
          },
          type: 'SUCCESS',
          msg: 'You have successfully added address',
          onOk: () => {
            dispatch(hideModalMessage());
            dispatch(
              ModalConfirmAction.show({
                subTitle: 'Enter confirm code from email to add new address',
                onOK: (code: string, twoFA: string) => {
                  onSubmitConfirmCode(code, twoFA);
                },
              })
            );
          },
        })
      );
    } else {
      addAddressSuccess();
    }
  };

  const onSubmitConfirmCode = async (code?: any, two_fa?: any) => {
    // Set code
    setValue('code', code);
    setValue('two_fa', two_fa);

    try {
      dispatch(ModalConfirmAction.loading());
      const res = await addressBookService.mutateAddressWhitelist(
        getValues('id'),
        getValues()
      );
      if (res?.status === 200) return;
      if (res?.status === 201) {
        addAddressSuccess();
        return;
      }
      throw new Error();
    } catch (error) {
      dispatch(
        ModalConfirmAction.setError(
          has2FA
            ? 'Email or 2FA code is incorrect. Please check and try again.'
            : 'The email code is incorrect. Please check and try again.'
        )
      );
    } finally {
      dispatch(ModalConfirmAction.hideLoading());
    }
  };

  const addAddressSuccess = () => {
    // Reload address book
    dispatch(AddressBookAction.reload());
    // Hide modal confirm code (if exists)
    dispatch(ModalConfirmAction.hide());
    // Hide modal add
    dispatch(
      AddressBookAction.toggleModalAdd(
        {
          isWhitelist: false,
        },
        {
          isWithdrawal: false,
        }
      )
    );
    dispatch(hideModalMessage());
    // Show message success
    dispatch(
      showModalMessage({
        type: 'SUCCESS',
        msg: getValues('whitelist')
          ? 'You have successfully whitelisted address'
          : 'You have successfully added address',
        onOk: () => {
          dispatch(hideModalMessage());
        },
      })
    );
    // Reset form
    reset();
    setIsOther(false);
    setIsChain(false);
    setChainfilter([]);
  };

  const toolTipContent = (
    <div className={classes.tooltipContentContainer}>
      <div className="icon-warning"></div>
      <p className="tooltip_content">
        It is important to confirm if the receiving address requires a MEMO.
        Failure to input the memo correctly will result in lost assets. Other
        providers may reference the Memo as "tag", "digital ID", "label" or
        "note"
      </p>
    </div>
  );

  return (
    <div>
      <ModalCustom
        isOpen={show}
        handleClose={() => {
          setIsOther(false);
          setIsChain(false);
          dispatch(
            AddressBookAction.toggleModalAdd(
              {
                isWhitelist: false,
              },
              {
                isWithdrawal: false,
              }
            )
          );
          setChainfilter([]);
          reset();
        }}
        // className={classes.modalMain}
      >
        <div className={classes.formHeader}>
          <div className={classes.formTitle}>Set withdrawal address</div>
        </div>
        <form
          className={classes.formContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.formItem}>
            <AppInput
              inputProps={{ maxLength: 20 }}
              classNames={classes.inputItem}
              controller={{
                control,
                name: 'address_title',
                rules: REGEX.titleAddress,
              }}
              label={'Title'}
              error={Boolean(errors.address_title)}
              placeholder="Name of address"
              helperText={errors.address_title?.message}
              labelProps={{
                className: errors.address_title ? classes.labelError : '',
              }}
            />
          </div>
          <div className={classes.groupFlex}>
            <div
              className={clsx(
                classes.formItem,
                classes.selectItem,
                classes.assetDefault
              )}
            >
              {isWithdrawal ? (
                <Select
                  controller={{
                    control,
                    name: 'asset',
                    rules: {
                      required: true,
                    },
                  }}
                  defaultValue={assetSelected}
                  options={assets}
                  className="dropdown-box"
                  inputClassName={classes.selectInput}
                  label="Coin"
                  placeholder="Choose asset"
                  onChange={(item: any) => {
                    setValue('asset', item, { shouldValidate: true });
                    setValue('chain', '');
                    setIsChain(false);
                    handleGetCoin(item);
                  }}
                />
              ) : (
                <Select
                  controller={{
                    control,
                    name: 'asset',
                    rules: {
                      required: true,
                    },
                  }}
                  options={assets}
                  className="dropdown-box"
                  inputClassName={classes.selectInput}
                  label="Coin"
                  placeholder="Choose asset"
                  onChange={(item: any) => {
                    setValue('asset', item, { shouldValidate: true });
                    setValue('chain', '');
                    setIsChain(false);
                    handleGetCoin(item);
                  }}
                />
              )}
            </div>

            <div
              className={clsx(
                classes.formItem,
                classes.selectItem,
                classes.chainInput
              )}
            >
              <Select
                controller={{ control, name: 'chain' }}
                options={chainFilter}
                value={getValues('chain')}
                inputClassName={classes.selectInput}
                label="Chain"
                placeholder="Choose chain"
                onChange={(item: any) => {
                  setValue('chain', item, { shouldValidate: true });
                  handleCheckChain(item);
                }}
              />
            </div>
          </div>

          <div className={classes.formItem}>
            <AppInput
              inputProps={{ maxLength: 50 }}
              classNames={classes.inputItem}
              controller={{
                control,
                name: 'withdrawal_address',
                rules: REGEX.withdrawalAddress,
              }}
              label={'Withdrawal address'}
              error={Boolean(errors.withdrawal_address)}
              placeholder="Enter you receiving address"
              helperText={errors.withdrawal_address?.message}
              labelProps={{
                className: errors.withdrawal_address ? classes.labelError : '',
              }}
            />
          </div>

          <div className={classes.groupFlex}>
            <div className={classes.selectAdderss_type}>
              <Select
                controller={{ control, name: 'classification' }}
                defaultValue={getValues('classification')}
                options={addressTypeArr}
                className={classes.address_type}
                inputClassName={classes.selectInput}
                popupClassName={classes.addressTypeSelectPopup}
                label="Address Type"
                placeholder="Address Type"
                onChange={(item: any) => {
                  setValue('classification', item, { shouldValidate: true });
                  handleCheckOther(item);
                }}
              />
            </div>
            {isChain ? (
              <div className={classes.memoInput}>
                <Tooltip
                  title={toolTipContent}
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  placement="top"
                  open={showTooltip}
                  onOpen={() => setShowTooltip(true)}
                  onClose={() => setShowTooltip(false)}
                >
                  <span
                    className="icon-info"
                    onClick={() => setShowTooltip(!showTooltip)}
                  ></span>
                </Tooltip>
                <AppInput
                  inputProps={{ maxLength: 200 }}
                  classNames={classes.inputItem}
                  controller={{
                    control,
                    name: 'memo',
                    rules: {
                      required: true,
                    },
                  }}
                  label={'Memo/Tag'}
                  error={Boolean(errors.memo)}
                  helperText={errors.memo?.message}
                  labelProps={{
                    className: errors.memo ? classes.labelError : '',
                  }}
                />
              </div>
            ) : null}
          </div>

          {isOther ? (
            <div className={clsx(classes.formItem, classes.freeInput)}>
              <AppInput
                inputProps={{ maxLength: 100 }}
                fullWidth
                placeholder="Other Address Type"
                controller={{
                  control,
                  name: 'classification',
                  rules: {
                    required: true,
                  },
                }}
                error={Boolean(errors.classification)}
                helperText={errors.classification?.message}
                labelProps={{
                  className: errors.classification ? classes.labelError : '',
                }}
              />
            </div>
          ) : null}

          {!isWhitelist && (
            <div
              className={clsx(
                classes.formItem,
                classes.formControlLabel,
                classes.marginTop
              )}
            >
              <Checkbox
                id="isCorrect"
                name="isCorrect"
                color="default"
                className={classes.styleCheck}
                defaultChecked={false}
                onChange={(checked: boolean) => setValue('whitelist', checked)}
              />
              <label htmlFor="isCorrect">Add to whitelist</label>
            </div>
          )}
          <Box
            className={`${!isWhitelist ? '' : 'groupBtn'} ${classes.rowBtn}`}
            display="flex"
          >
            <AppButton
              height={'36px'}
              width={'126px'}
              mode="secondary"
              onClick={() => {
                dispatch(
                  AddressBookAction.toggleModalAdd(
                    {
                      isWhitelist: false,
                    },
                    {
                      isWithdrawal: false,
                    }
                  )
                );
                reset();
                setIsOther(false);
                setIsChain(false);
                setChainfilter([]);
              }}
            >
              Cancel
            </AppButton>
            <AppButton
              height={'36px'}
              width={'126px'}
              type="submit"
              isDisable={!isValid || Object.keys(errors).length > 0}
              isLoading={isSubmit}
            >
              Save
            </AppButton>
          </Box>
        </form>
      </ModalCustom>
    </div>
  );
}
