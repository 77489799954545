import { Container, List, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useStyles from './styles';

const listUL = [
  {
    id: 1,
    icon: 'icon-hour',
    title: 'Support',
    desc: '24/7 global support via dedicated channels ensuring any requests are dealt with in real time.',
  },
  {
    id: 2,
    icon: 'icon-shield',
    title: 'Resiliency',
    desc: 'Our architecture has been designed with real-time failover and high availability for true access to 24/7 liquidity.',
  },
  {
    id: 3,
    icon: 'icon-lock',
    title: 'Security',
    desc: 'Strong institutional focussed security is paramount. We achieve this through adopting a non-custodial, walled garden model to keep assets safe within the oneAlpha ecosystem.',
  },
];
const Experience = (props: any) => {
  const classes = useStyles();

  return (
    <section className={classes.mainEx}>
      <Container className={classes.containterEX}>
        <div className={classes.contentEx}>
          <div data-aos="fade-right">
            <div className={classes.leftEx}>
              <img src="/images/img-mask.png" alt="" />
            </div>
            <div className={classes.leftExMobile}>
              <img src="/images/mask-768.png" alt="" />
            </div>
            <div className={classes.leftExXs}>
              <img src="/images/mask-xs.png" alt="" />
            </div>
          </div>

          <div data-aos="fade-left">
            <div className={classes.rightEX}>
              <Typography variant="h3" className={classes.titleEx}>
                oneAlpha Experience
              </Typography>
              <p className={classes.textEx}>
                Our architecture and platform has been designed with high
                resiliency to ensure you have access to liquidity on a 24/7
                basis. With a focus on strong security protocols, you can be
                confident that our platform will be able to meet all your
                business needs.
              </p>

              <List className={classes.listItem}>
                {listUL?.map((item) => (
                  <div className={classes.detail} key={item.id}>
                    <div className={classes.iconEx}>
                      <img src={`/images/${item.icon}.png`} alt="" />
                    </div>
                    <div className={classes.textDetail}>
                      <h4 className={classes.titleDetail}>{item.title}</h4>
                      <p className={classes.descText}>{item.desc}</p>
                    </div>
                  </div>
                ))}
              </List>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default withRouter(Experience);
