import React from 'react';
import { useGridSlotComponentProps } from '@material-ui/data-grid';
import Pagination from 'src/components/Base/Pagination';

const GridDataPagination = () => {
  const {
    state: {
      pagination: { page, rowCount, pageSize },
    },
    apiRef,
  } = useGridSlotComponentProps();

  const pageCount = Math.ceil(rowCount / pageSize);
  return (
    <Pagination
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

export default GridDataPagination;
