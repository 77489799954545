import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    signUpRoot: {
      color: theme.custom.colors.white,
      position: 'relative',
      paddingBottom: 50,
    },
    signUpBox: {
      background: theme.custom.colors.bgrBox,
      borderRadius: 10,
      maxWidth: 611,
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.down('xs')]: {
        // maxWidth: 335,
      },
    },
    logoBox: {
      padding: '35px 0',
      textAlign: 'center',
    },
    logo: {
      width: 116,
      height: 48,
    },
    boxLogo: {
      width: 116,
      marginTop: 50,
      [theme.breakpoints.down('lg')]: {
        marginRight: 93,
      },
      [theme.breakpoints.down('md')]: {
        width: 35,
        marginRight: 40,
      },
    },
    title: {
      fontSize: 32,
      fontFamily: 'Titillium Web',
      fontWeight: 'bold',
      padding: '20px 0 12px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    formSubmit: {
      margin: 'auto',
      padding: '0 100px 40px',
      [theme.breakpoints.down('xs')]: {
        padding: '0 10px 5px',
      },
    },
    formControl: {
      marginBottom: 30,
      position: 'relative',
    },
    formRadio: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 30,
    },
    formInput: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '10px 8px 10px 16px',
      borderRadius: 6,
      border: '1px solid',
      borderColor: theme.custom.colors.colorLbl,
      background: 'none',
      color: theme.custom.colors.white,
      height: 50,
      marginTop: 5,

      '&::placeholder': {
        color: theme.custom.colors.placeholder,
      },
    },
    radioBtn: {
      userSelect: 'none',
      position: 'relative',
      fontSize: 14,
      paddingLeft: 16,
      height: 50,
      border: `1px solid`,
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 5,
      },
    },
    radioBlock: {
      marginRight: 0,
      width: '100%',
    },
    radioGroup: {
      display: 'grid !important',
      gridTemplateRows: 'repeat(1, minmax(0,1fr))',
      gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
      gridGap: '14px',
      marginBottom: 30,
    },
    radioItem: {},
    label: {
      color: theme.custom.colors.white,
      fontWeight: 600,
    },
    radioLabel: {
      fontSize: 14,
      marginLeft: 5,
      fontFamily: 'Titillium Web',
      color: theme.custom.colors.white,
      [theme.breakpoints.down('xs')]: {
        whiteSpace: 'nowrap',
        marginLeft: 0,
      },
    },
    icon: {
      borderRadius: '50%',
      width: 20,
      height: 20,
      border: `1px solid ${theme.custom.colors.radioChecked}`,
      position: 'relative',
      'input:checked ~ &': {
        color: 'red !important',
      },
    },
    checkedIcon: {
      '&:before': {
        display: 'block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        content: '""',
        backgroundColor: theme.custom.colors.radioChecked,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    },
    checked: {
      background: theme.custom.colors.checkedBg,
    },

    radioLabelChecked: {
      fontWeight: 'bold',
    },

    styleCheck: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      marginBottom: 30,
    },
    paddingCheck: {
      padding: 0,
    },
    styleLbl: {
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '21px',
      fontFamily: 'Titillium Web',
      color: theme.custom.colors.textBottom,
    },
    link: {
      fontWeight: 700,
      color: theme.custom.colors.textBottom,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    iconCheckbox: {
      borderRadius: 3,
      width: 25,
      height: 25,
      backgroundColor: 'none',
      border: '1px solid #606983',
    },
    checkBox: {
      background: theme.custom.colors.btnSignUp,

      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 25,
        height: 25,
        content: '""',
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        'input:hover ~ &': {
          opacity: 0.8,
        },
      },
    },
    iconPassWord: {
      position: 'absolute',
      top: 40,
      right: 12,
      cursor: 'pointer',
    },
    iconPassWordVisable: {},
    divBtn: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 16,
    },
    btnSubmit: {
      background: theme.custom.colors.btnSignUp,
      borderRadius: 6,
      height: 50,
      width: 188,
      fontFamily: 'Titillium Web',
      fontSize: 14,
      fontWeight: 700,
      color: theme.custom.colors.white,
      textTransform: 'none',
      marginRight: 10,
      boxShadow: 'none',

      '&:hover': {
        background: theme.custom.colors.btnSignUp,
        opacity: 0.8,
      },
      '&:disabled': {
        background: theme.custom.colors.bgDisable,
        color: theme.custom.colors.white,
        cursor: 'no-drop',
        pointerEvents: 'auto',

        '&:hover': {
          background: theme.custom.colors.bgDisable,
          opacity: 1,
        },
      },
    },
    login: {
      textAlign: 'center',
      fontSize: 16,
      color: theme.custom.colors.white,
      marginTop: 20,
      fontWeight: 300,
      fontFamily: 'Titillium Web',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 20,
      },
      '& a': {
        color: theme.custom.colors.white,
      },
    },
    captcha: {
      width: 'fit-content',
      margin: '16px auto 0',
    },
    msg: {
      color: theme.custom.colors.textError,
      textAlign: 'center',
      marginTop: 16,
    },

    labelInput: {
      fontWeight: 600,
    },

    active: {
      background: `${theme.custom.colors.btnSignUp} !important`,
    }
  };
});

export default useStyles;
