import { ChainService } from "src/services/ChainService";
import { CHAIN_SELECTED, CHAIN_SET } from "../constants/chain";

export const ChainAction = {
  set: () => async (dispatch: any) => {
    const chainService = ChainService.getInstance();
    const res = await chainService.getChain();
    if (res.status === 200 && res.data?.items) {
      dispatch({
        type: CHAIN_SET,
        payload: res.data.items,
      });
    }
  },
  selectChain: (chain_id: number | null) => ({
    type: CHAIN_SELECTED,
    payload: chain_id,
  }),
};
