import { makeStyles } from "@material-ui/core";
import { drawerWidth, drawerWidthXl } from "../../../store/constants/width-page";

const useStyles = makeStyles((theme: any) => {
  return {
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },

    drawerPaper: {
      width: drawerWidth,
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      borderRight: "none",
      [theme.breakpoints.up("xl")]: {
        width: drawerWidthXl,
      },
      [theme.breakpoints.up("md")]: {
        top: "88px",
      },
    },
    ScrollHeight: {
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down('sm')]: {},
    },

    ScrollHeightSmall: {
      paddingLeft: 13,
      paddingRight: 13,

      [theme.breakpoints.down('sm')]: {},
    },


    drawerOpen: {
      opacity:'1',
      width: drawerWidth,
      background: theme.custom.colors.mainBackground,
      borderColor: theme.custom.colors.brightGray,
      marginTop: 60,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowY: 'auto',
      [theme.breakpoints.up("xl")]: {
        width: drawerWidthXl,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    drawerClose: {
      opacity:'0',
      width: 65,
      background: theme.custom.colors.mainBackground,
      borderColor: theme.custom.colors.brightGray,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      [theme.breakpoints.down("sm")]: {
        width: 0,
      },
    },

    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0, 1),
      minHeight: 70,
      [theme.breakpoints.down("sm")]: {
        minHeight: 64,
        display: 'none'
      },
    },

    logoDesktop: {

    }
  };
});

export default useStyles;
