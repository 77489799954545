import React from 'react';
import useStyles from './styles';

const Common: React.FC<any> = (props: any) => {
  const style = useStyles();
  return (
    <div className={style.commonRoot}>
      <div>{props.children}</div>
    </div>
  );
};

export default Common;