import { AnyAction } from 'redux';
import { LOADING_TOGGLE } from '../constants/loading';

const initialState = {
  show: false,
};

export const LoadingReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case LOADING_TOGGLE:
      return {
        ...state,
        show: action.payload,
      };

    default:
      return state;
  }
};
