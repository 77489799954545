import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    cryptoModalContainer: {
      fontSize: '14px',
      lineHeight: '19.6px',

      '& .body-modal-container': {
        minHeight: 300,
        [theme.breakpoints.down('sm')]: {
          padding: '0 !important',
        },
      },

      '& .label': {
        fontSize: '20px',
        lineHeight: '23px',
        color: theme.custom.colors.titleTrading,
      },

      '& .dropdown-box': {
        marginTop: '30px',
        maxWidth: 'unset',
        fontFamily: 'Ubuntu-Medium',

        '& label + .MuiInput-formControl': {
          marginTop: '8px',
        },
      },

      '& .address-container': {
        marginTop: '30px',
        fontFamily: 'Ubuntu-Medium',
        '& .address-box': {
          marginTop: '8px',
          border: `1px solid ${theme.custom.colors.white2}`,
          borderRadius: '6px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          padding: '10px 8px 10px 16px',
          alignItems: 'center',
          height: 50,

          '& .icon-copy2:before': {
            fontSize: '19px',
            cursor: 'pointer',
            color: theme.custom.colors.borderTradingWatch,
          },

          '& .address': {
            fontFamily: 'Ubuntu-Regular',
            width: '90%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },

      '& .warning': {
        marginTop: '40px',
        background: theme.custom.colors.cornflowerBlue,
        color: theme.custom.colors.white1,
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '15px 20px',
        fontFamily: 'Ubuntu-Regular',

        '& .icon-warning': {
          fontSize: '30px',
        },

        '& .content': {
          marginLeft: '10px',
        },

        [theme.breakpoints.down('xs')]: {
          alignItems: 'unset',
          padding: '15px 10px',
        },
      },

      '& .report': {
        marginTop: '30px',
        fontFamily: 'Ubuntu-Regular',
        color: theme.custom.colors.white2,

        '& a': {
          fontFamily: 'Ubuntu-Bold',
          textDecoration: 'none',
          color: theme.custom.colors.lightPurple2,
        },
      },

      '& .modal-container': {
        minHeight: '370px',
      },
    },
    selectPopup: {
      maxHeight: 130,
    },
  };
});

export default useStyles;
