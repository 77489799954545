import React from 'react';
import { ws } from 'src/utils/socket';

import createWidgetConfig from './config';
import { historyProvider } from './historyProvider';
import { DataFeed } from './datafeed';
import { BarStyles, resolutions } from 'src/commons/constants/TradingConstant';
import AppLoading from '../AppLoading';

declare global {
  interface Window {
    TradingView?: any;
  }
}

interface TVChartContainerProps {
  symbol?: any;
  interval?: any;
  containerId?: any;
  libraryPath?: any;
  chartsStorageUrl?: any;
  chartsStorageApiVersion?: any;
  clientId?: any;
  userId?: any;
  fullscreen?: any;
  autosize?: any;
  studiesOverrides?: any;
  themeMode?: any;
  className?: any;
}

interface State {
  chartType: string;
  resolution: string;
  interval: string;
  themeMode: string;
  isLoading: boolean;
}

/**
 * Helper function to mark custom widget buttons as selected
 * @param {jQuery} button
 */
function markAsSelected(button: any, className = 'selected') {
  button
    .parent()
    .parent()
    .parent()
    .find(`.${className}`)
    .removeClass(className);
  button
    .parent()
    .parent()
    .parent()
    .find('.selected-bg')
    .removeClass('selected-bg');
  button.addClass(className);
  button.parent().addClass('selected-bg');
}

export class TVChartContainer extends React.PureComponent<
  TVChartContainerProps,
  State
> {
  private widget: any = null;
  private datafeed: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      chartType: BarStyles.CANDLES,
      resolution: '1m',
      interval: '1m',
      themeMode: props.themeMode?.status ? 'Dark' : 'Light',
      isLoading: false,
    };
    this.datafeed = new DataFeed({
      getBars: this.fetchKLineData,
      fetchResolveSymbol: this.fetchResolveSymbol,
    });
  }

  componentDidMount() {
    const widgetOptions = createWidgetConfig({
      symbol: this.props.symbol,
      interval: this.state.resolution,
      datafeed: this.datafeed,
      theme: localStorage.getItem('themeMode') ?? 'Light',
    });

    this.widget = new window.TradingView.widget(widgetOptions);

    // event onChart Ready
    this.widget.onChartReady(() => {
      this.renderButtons();
      this.setState({ ...this.state, isLoading: true });
    });
  }

  componentWillUnmount() {
    this.unsubscribeKLine();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.themeMode !== this.props.themeMode) {
      const theme = localStorage.getItem('themeMode') ?? 'Light';
      this.setState({
        ...this.state,
        themeMode: theme,
      });

      // when widget isloading done
      if (this.widget && this.state.isLoading) {
        this.widget?.changeTheme(theme);
      }
    }

    // change symbol view candle chart
    if (prevProps.symbol !== this.props.symbol) {
      this.unsubscribeKLine();

      const widgetOptions = createWidgetConfig({
        symbol: this.props.symbol,
        interval: this.state.resolution,
        datafeed: this.datafeed,
        theme: localStorage.getItem('themeMode') ?? 'Light',
      });

      this.widget = new window.TradingView.widget(widgetOptions);

      // event onChart Ready
      this.widget.onChartReady(() => {
        this.renderButtons();
        this.setState({ ...this.state, isLoading: true });
      });
    }
  }

  public fetchKLineData = async (params: any) => {
    const bars: any[] = [];
    try {
      if (!params.firstDataRequest) {
        return { bars, meta: { noData: true } };
      }
      if (params.resolution !== this.state.interval) {
        this.unsubscribeKLine();
      }

      //  get history of symbol to find first init candle chart
      const res = await historyProvider.getBar(
        params.symbolInfo,
        this.state.resolution
      );

      if (!res || !res.data || !res.data.length) {
        return { bars, meta: { noData: true } };
      }

      for (let i = 0; i < res.data.length; i++) {
        const item = res.data[i];
        bars.push({
          time: item.floor_time * 1000,
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
          volume: item.vol,
        });
      }
      bars.sort((l, r) => (l.time > r.time ? 1 : -1));

      if (params.firstDataRequest) {
        this.subscribeKLine();
      }
      return { bars, meta: { noData: false } };
    } catch {
      return { bars, meta: { noData: false } };
    } finally {
      this.setState({
        ...this.state,
        isLoading: true,
      });
    }
  };

  public fetchResolveSymbol = (symbolName: string): Promise<any> => {
    return new Promise((resolve) => {
      resolve({
        name: symbolName,
        description: '',
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        ticker: symbolName,
        minmov: 1,
        volume_precision: 8,
        pricescale: Math.pow(10, 2),
        has_intraday: true,
        data_status: 'streaming',
      });
    });
  };

  public subscribeKLine = async () => {
    const msg = {
      event: 'subscribe',
      symbol: this.props.symbol,
      method: 'price',
    };

    ws.subscribe('price', msg, (data) => {
      this.datafeed.updateKLine({
        time: data.floor * 1000,
        open: data.open,
        high: data.high,
        low: data.low,
        close: data.close,
        volume: data.vol,
      });
    });
  };

  public unsubscribeKLine = () => {
    const params = {
      event: 'unsubscribe',
      symbol: this.props.symbol,
      method: 'price',
    };
    ws.unsubscribe('price', params);
    this.setState({ ...this.state, isLoading: false });
  };

  renderButtons() {
    resolutions.forEach((res: any) => {
      const button = this.widget.createButton();
      if (res.key === this.state.resolution) {
        button.parent().addClass('selected-bg');
        button.addClass('selected');
      }

      button.on('click', () => {
        markAsSelected(button);
        this.setResolution(res.key, res.resolution);
      });
      button.append(`<span>${res.text}</span>`);
    });
  }

  setResolution(value: string, resolution: string) {
    this.setState({ resolution: value }, () => {
      this.setState({
        ...this.state,
        isLoading: true,
      });
      this.widget.setSymbol(this.props.symbol, resolution);
    });
  }

  setFullscreen = async () => {
    this.widget.chart()._chartWidget._chartWidgetCollection.startFullscreen();
  };

  render() {
    const isLoading = this.state.isLoading;

    return (
      <>
        {!isLoading && (
          <div className={this.props.className}>
            <AppLoading />
          </div>
        )}
        <div id="tv_chart_container" />
      </>
    );
  }
}

export default TVChartContainer;
