import { useHistory } from 'react-router';
import CardCommon from 'src/components/BaseLandingPage/Common/Card';

const SuccessComponent = () => {
  const history = useHistory();

  return (
    <CardCommon
      icon="/images/icon-thank-you.png"
      title="Success!"
      content="You have successfully confirmed email"
      contentBtn="OK"
      onClickOk={() => history.push('/')}
    ></CardCommon>
  );
};
export default SuccessComponent;
