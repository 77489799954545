import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';

import HeaderLogo from 'src/components/BaseLandingPage/Common/HeaderLogo';
import ButtonCommon from 'src/components/BaseLandingPage/Common/Button';
import InputField from 'src/components/BaseLandingPage/Common/Input';
// import { useLoading } from 'src/components/BaseLandingPage/Context/Loading';
import MessageInline from 'src/components/BaseLandingPage/Common/MessageInline';
import Link from 'src/components/Base/Link';

import { PAGE } from 'src/utils/utils';
import { landingService } from 'src/services/LandingService';
import { MAX_LENGTH } from 'src/utils/validation';

import useStyles from 'src/components/BaseLandingPage/Common/Form/styles';
import useClasses from './styles';

interface IForm {
  username: string;
  token: string;
}

const ForgotComponent = () => {
  const history = useHistory();
  const style = useStyles();
  const classes = useClasses();
  const methodsForm = useForm<IForm>({
    mode: 'onChange',
  });
  const [isLoading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
    setValue,
  } = methodsForm;

  useEffect(() => {
    register('token', { required: true });
    document.title = 'Forgot Password';
  }, [register]);

  const onChangeCAPTCHA = (value: string | null) => {
    setValue('token', value || '', {
      shouldValidate: true,
    });
  };

  const onSubmit = (data: any) => {
    //  Loading
    setLoading(true);
    setMsg('');

    // Reset captcha

    // Cal API
    landingService
      .postData('/account/reset/', data)
      .then(async (res: any) => {
        if (res.status !== 200) {
          setMsg('Incorrect email or username');
          return;
        }
        // Success
        await res.data;
        history.push(`/confirm/forgot/${getValues('username')}`);
      })
      .then(() => {
        setLoading(false);
      });

    (window as any)?.grecaptcha?.reset();
    setValue('token', '', {
      shouldValidate: true,
    });
  };

  return (
    <>
      <HeaderLogo></HeaderLogo>
      <div className={`${style.formBox} ${style.position}`}>
        <div className={style.iconHeader}>
          <img src="/images/icon-block.png" alt="" />
        </div>
        <Typography className={`${style.labelContent} ${classes.title}`}>
          Forgot Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={style.formSubmit}>
          <InputField
            {...methodsForm}
            name="username"
            label="Email or Username"
            maxLength={MAX_LENGTH.MAIL}
            validation={{
              required: true,
            }}
          />
          <div className={classes.captcha}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA || ''}
              onChange={onChangeCAPTCHA}
            />
          </div>
          {msg && <MessageInline msg={msg} />}
          <div className={classes.display}>
            <ButtonCommon
              type="submit"
              disabled={!isValid}
              className={`${isLoading && getValues('token') === '' ? classes.active : ''
                }`}
              isLoading={isLoading}
            >
              Send
            </ButtonCommon>
          </div>
          <div className={`${style.bottom} ${classes.showMobile}`}>
            <label>
              Remember password?{' '}
              <Link
                className={style.link}
                onClick={() => {
                  history.push(PAGE.LOGIN);
                }}
              >
                Log in
              </Link>
            </label>
          </div>
        </form>
      </div>
    </>
  );
};
export default ForgotComponent;
