import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { ZIndex } from '@material-ui/core/styles/zIndex';

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    negative: number;
  }
}

declare module '@material-ui/core/styles' {
  interface Theme {
    zIndex: ZIndex;
  }
  interface ThemeOptions {
    custom?: any;
  }
}

export const createLemonadeTheme = (options: ThemeOptions = {}) => {
  return createTheme(options);
};

const themeOptions = {
  colors: {
    primary: '#FFCC00',
    secondary: 'rgba(55, 60, 78, 1)',
    primaryText: '#636363',
    metamask: '#FF8F44',
    mainBackground: '#242731',
    cardBackground: '#373C4E',
    purple1: 'rgba(127, 99, 244, 1)',
    purple2: '#563BCA',
    purple3: 'rgba(96, 105, 131, 0.3)',
    grey1: 'rgba(235, 236, 240, 1)',
    grey2: '#D1D1D1',
    grey3: '#E3E3E3',
    blueGray: 'rgba(88, 104, 148, 1)',
    red1: 'rgba(222, 73, 73, 1)',
    red2: '#DE4949',
    white1: '#EBECF0',
    white2: '#EBECF0',
    white3: '#FFFFFF',
    white4: '#FFFFFF',
    white5: '#EBECF0',
    comet: '#606983',
    comet2: '#606983',
    comet3: '#606983',
    comet4: '#606983',
    comet5: '#606983',

    cornflowerBlue: '#7F63F4',
    hoverBtn: '#563BCA',
    brightGray: '#373C4E',
    santaGray: '#9BA1B1',
    santaGray2: '#9BA1B1',
    lightPurple: '#B8A7FF',
    boldGray: '#EBECF0',
    lightBlue: '#586894',
    lightPurple2: '#B8A7FF',
    titleTrading: '#EBECF0',
    bgRowLabel: '#464D62',
    bgBluGrey: '#464D62',
    bgTrading: '#586894',
    lineTable1: '#384464',
    clCanceled: '#FFFFFF',
    borderQRCode: '#EBECF0',
    btnTradingWatch: '#7F63F4',
    borderTradingWatch: '#EBECF0',
    conrGrayblue: '#3E455F',
    green1: '#4CA250',
    secondary1: '#C7C9D0',
    santaGray1: '#EBECF0',
    grey4: '#EBECF0',
    bgPagination: '#373C4E',
    bgBtn: '#6069834d',
    borderBtnPage: '#384464',
    colorText: '#C7C9D0',
    colorLink: '#B8A7FF',
    bgrButton: '#373C4E',
    colorBorder: '#EBECF0',
    bgrBtn: '#6069834d',
    colorWarning: '#9BA1B1',
    btnCancle: '#586894',
    btnCancel: '#586894',
    descColor: '#C3C7D1',
    borderCancel: '#EBECF0',
    bgrOverLay: 'rgba(36, 39, 49, 0.85)',
    statusConfirmed: '#FFFFFF',
    statusCancelled: '#FFFFFF',
    statusFilled: '#FFFFFF',
    bgrSelect: '#373C4E',
    themeCards: '#373C4E',
    colorStep: '#B8A7FF',
    colorPlace: '#9BA1B1',
    bgDisable: '#6069834d',
    btnEnable: '#7F63F4',
    btnHover: '#563BCA',
    btnTitle: '#FFFFFF',
    fieldTitle: '#EBECF0',
    textContentCard: '#EBECF0',
    checkBoxColor: '#606983',
    bgrCheck: '#373C4E',
    colorDes: '#9BA1B1',
    tabActive: '#EBECF0',
    bgrItemDropdown: '#373C4E',
    activeTab: '#EBECF0',
    gray4: '#EBECF0',
    titleConfirmCode: '#9BA1B1',
    btnSell: '#464D62',
    colorStatus: '#FFFFFF',
    btnDisable: '#60698330',
    bgrBtnDisable: '#6069834D',
  },
};

const themeDarkMode = createLemonadeTheme({
  typography: {
    fontFamily: 'Ubuntu-Medium',
  },
  custom: themeOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export { themeOptions };
export default themeDarkMode;
