/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import ReactPinField, { ReactPinFieldProps } from 'react-pin-field';
import useStyles from './styles';

// export interface AnimalTableProps extends ReactPinFieldProps {
//     hasError?: boolean;
//     isDontFocus?: boolean;
export interface IAppOTPInput extends ReactPinFieldProps {
  hasError?: boolean;
  isDontFocus?: boolean;
  className?: string;
}

const LENGTH_CODE = 6;
const AppOTPInput = (props: IAppOTPInput) => {
  const refPIN = useRef<any>(null);
  const classes = useStyles();

  useEffect(() => {
    if (props.hasError) {
      refPIN.current.inputs.forEach((input: any) => {
        input.classList.add(classes.hasError);
      });
    }
  }, [props.hasError]);
  useEffect(() => {
    if (props.isDontFocus) return;
    refPIN.current.inputs[0]?.focus();
  }, []);

  return (
    <div className={classes.container}>
      <ReactPinField
        ref={refPIN}
        type={'number'}
        autoComplete="false"
        length={LENGTH_CODE}
        className={`${classes.pinField} ${props.className}`}
        validate={/^[0-9]$/}
        inputMode="numeric"
        onChange={(otp: any) => {
          refPIN.current.inputs.forEach((input: any) => {
            input.classList.remove(classes.hasError);
            if (input.value !== '') {
              input.classList.add(classes.hasValue);
            } else {
              input.classList.remove(classes.hasValue);
            }
          });
          if (props?.onChange) {
            props.onChange(otp);
          }
        }}
      />
    </div>
  );
};

export default AppOTPInput;
