import useStyles from "./styles";

const AreaSell = (props: any) => {
  const styles = useStyles();
  const { data } = props;

  return (
    <div className={styles.areaBuy}>
      {data.map((item: any, index: number) => {
        return (
          <div key={index} className={styles.itemArea}>
            <div className={styles.label}>{item.label}</div>
            <div className={styles.bgValue}>
              <div className={styles.value}>{item.value}</div>
              <div
                className={styles.bgFix}
                style={{ width: `${item.percent}%` }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AreaSell;
