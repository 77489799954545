/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useStyles from './styles';

const FeaturesContainer = (props: any) => {
  const classes = useStyles();
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const features = [
    {
      icon: 'icon-trading2',
      url: '/trading',
      title: 'Trading',
      content:
        'Access deep liquidity for many markets via traditional or flash trading ',
    },
    {
      icon: 'icon-api-settings',
      url: isLogged ? '/setting/api' : `/`,
      title: 'API Settings',
      content:
        'Control and access your account via our secure REST/Websocket APIs',
    },
    {
      icon: 'icon-security-settings',
      url: isLogged ? '/setting/security' : `/`,
      title: 'Security Settings',
      content:
        'Ensuring your account security is paramount, we highly suggest configuring 2fa/whitelisting and resetting your account password periodically.',
    },
  ];

  return (
    <div className={classes.linkedContainer}>
      {features.map((item, index) => {
        return (
          <div className={classes.linkedItem} key={index}>
            <div className={classes.iconStyle}>
              <div className={`${item.icon} ${classes.iconStyle}`} />
            </div>

            <div className={classes.content}>
              <span className={classes.title}>{item.title}</span>
              <span className={classes.body}>{item.content}</span>
              {isLogged || (!isLogged && item.url === '/trading') ? (
                <Link to={item.url} className={classes.viewmore}>
                  {' '}
                  View more{' '}
                </Link>
              ) : (
                <a
                  href="#"
                  onClick={() => {
                    window.location.href = `/`;
                  }}
                  className={classes.viewmore}
                >
                  {' '}
                  View more{' '}
                </a>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FeaturesContainer;
