import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    cardRoot: {
    },
    logoBox: {
      padding: '35px 0',
      textAlign: 'center',
    },
    logo: {
      width: 116,
      height: 48,
    },
    box: {
      paddingBottom: 15,
      background: theme.custom.colors.bgrBox,
      borderRadius: 10,
      maxWidth: 611,
      margin: 'auto',
      textAlign: 'center',
      color: theme.custom.colors.white,

      '& img': {
        paddingTop: 20,
        [theme.breakpoints.down('xs')]: {
          width: 75,
        },
      },
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.down('md')]: {},
    },
    boxContent: {
      marginTop: 15,
      color: theme.custom.colors.colorLbl,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 10,
      lineHeight: '37px',
      color: theme.custom.colors.white,
      fontFamily: 'Titillium Web',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
    },
    content: {
      whiteSpace: 'break-spaces',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Titillium Web',
    },
    bottomContent: {
      textAlign: 'center',
      fontSize: 16,
      color: theme.custom.colors.white,
      marginBottom: 40,
      fontWeight: 300,
      fontFamily: 'Titillium Web',
    },
    link: {
      fontWeight: 700,
      cursor: 'pointer',
      color: theme.custom.colors.white,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  };
});
export default useStyles;
