import { Button, Typography } from '@material-ui/core';
import ModalCustom from 'src/components/Base/ModalCustom';
import useStyles from './styles';
import { toLogin, toSignUp } from 'src/utils/common';

const ModalSignUp = (props: any) => {
  const classes = useStyles();
  const { isOpen, handleClose } = props;

  return (
    <div>
      <ModalCustom
        isOpen={isOpen}
        isIconClose={true}
        handleClose={handleClose}
        className={classes.mainModal}
      >
        <Typography className={classes.title}>
          To use all functionality please Sign up/Log in
        </Typography>

        <div className={classes.display}>
          <Button className={classes.btnLogin} onClick={() => toLogin()}>
            Log in
          </Button>
          <Button className={classes.btnSignUp} onClick={() => toSignUp()}>
            Sign up
          </Button>
        </div>
      </ModalCustom>
    </div>
  );
};
export default ModalSignUp;
