/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';

import AppOTPInput from 'src/components/Base/AppOTPInput';
import ModalCustom from 'src/components/Base/ModalCustom';
import AppButton from 'src/components/Base/AppButton';

import useStyles from './styles';

export const ModalConfirmCode = ({
  isOpen,
  handleClose,
  onSubmit,
  errorCode,
  setErrorCode,
  subTitle,
  errorMessage,
  isLoading,
}: {
  isOpen: boolean;
  handleClose: (type: number) => void;
  onSubmit: (pinCode: any) => void;
  errorCode: boolean;
  setErrorCode: (status: boolean) => void;
  subTitle: String;
  errorMessage: String;
  isLoading: boolean;
}) => {
  const styles = useStyles();

  const [pinCode, setPinCode] = useState('');
  const [activeSubmit, setActiveSubmit] = useState(false);

  const handleChange = (value: any) => {
    setPinCode(value);
    setErrorCode(false);
    setActiveSubmit(value?.length === 6);
  };

  useEffect(() => {
    setPinCode('');
    setErrorCode(false);

    return () => {
      setPinCode('');
      setErrorCode(false);
      setActiveSubmit(false);
    };
  }, [isOpen]);

  return (
    <ModalCustom
      isOpen={isOpen}
      handleClose={() => handleClose(2)}
      className={styles.main}
    >
      <Typography className={styles.title} style={{ marginBottom: '10px' }}>
        Confirmation
      </Typography>
      <Typography className={styles.subTitle}>{subTitle}</Typography>
      <AppOTPInput onChange={handleChange} hasError={errorCode} />
      {errorCode && (
        <Typography className={styles.textError}>{errorMessage}</Typography>
      )}
      <div className={styles.btnGroup2}>
        <Button className={styles.btnCancel} onClick={() => handleClose(2)}>
          Cancel
        </Button>

        <AppButton
          type="submit"
          onClick={() => onSubmit(pinCode)}
          className={styles.btnSubmit}
          isDisable={!activeSubmit}
          width="126px"
          height="36px"
          isLoading={isLoading}
        >
          Submit
        </AppButton>
      </div>
    </ModalCustom>
  );
};

export default ModalConfirmCode;
