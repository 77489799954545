import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      background: 'white',
      color: theme.custom.colors.colorText,
      [theme.breakpoints.down('md')]: {
        borderTop: `1px solid #373C4E`,
      },
      [theme.breakpoints.down('xs')]: {},
    },
    contentTitle: {
      background: theme.custom.colors.mainBackground,
    },

    mainTerm: {
      backgroundImage: 'url(/images/bgrTerm.png)',
      paddingTop: 51,
      paddingBottom: 67,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top right',
      backgroundOrigin: 'border-box',

      [theme.breakpoints.down('xs')]: {
        padding: '20px 0',
        backgroundImage: 'url(/images/bgr-termMobile.png)',
      },
    },

    containerTerm: {
      padding: '0 310px',

      [theme.breakpoints.down('md')]: {
        padding: '0 30px',
      },
    },

    titleTerm: {},

    styleTitle: {
      fontSize: '48px',
      lineHeight: '55px',
      fontFamily: 'Ubuntu-Bold',
      color: theme.custom.colors.white,

      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
      },
    },

    detailPrivacy: {
      padding: '0 315px',
      fontFamily: 'Ubuntu',
      [theme.breakpoints.down('md')]: {
        paddingRight: 26,
        paddingLeft: 26,
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: 20,
        paddingLeft: 20,
      },
    },
    section1: {
      marginTop: 40,
      fontSize: 16,
      fontFamily: 'Ubuntu',
      [theme.breakpoints.down('md')]: {
        marginTop: 40,
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 15,
      },
    },
    headerText: {
      fontSize: 24,
      fontFamily: 'Ubuntu-Bold',
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    list1: {
      marginTop: 15,
    },
    hidden: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    text1: {
      margin: '30px 0',
      lineHeight: '140%',
      '& span': {
        fontWeight: 'bold',
      },
    },

    groupText: {
      lineHeight: '140%',
      fontFamily: 'Ubuntu',
    },

    headerText2: {
      fontSize: 20,
      fontWeight: 'bold',

      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,

        '& span': {
          display: 'none',
        },
      },
    },
    headerText3: {
      fontSize: 20,
      marginTop: 30,
      fontFamily: 'Ubuntu-Bold',
      fontWeight: 700,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    subTitle: {
      paddingTop: 15,
    },
    subContent: {
      paddingTop: 20,
    },
    table: {
      minWidth: 880,
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
      display: 'block',
      overflowX: 'auto',
      '& th': {
        textAlign: 'start',
        padding: 10,
        fontWeight: 'normal',
        fontSize: 14,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '-10px',
      },
    },
    row: {
      background: theme.custom.colors.bgRow,
      border: '2px #F2F6F9 solid',
      borderRadius: 10,
      padding: 18,
      height: 50,
      '& td': {
        padding: 10,
        fontSize: 14,
      },
      '& td:first-child': {
        borderRadius: `10px 0 0 10px`,
        [theme.breakpoints.up('md')]: {
          width: '33%',
        },
      },
      '& td:last-child': {
        borderRadius: `0 10px 10px 0`,
        [theme.breakpoints.up('md')]: {
          width: '32.5%',
        },
      },
    },
    tblRes: {
      overflowX: 'auto',
      color: theme.custom.colors.bgDisable,
      '&::-webkit-scrollbar': {
        height: 8,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 10,
        background: '#EBECF0',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7F63F4',
        borderRadius: 10,
      },
    },
  };
});
export default useStyles;
