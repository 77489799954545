/* eslint-disable react-hooks/exhaustive-deps */
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { isNumeric } from 'src/utils/number';
import useStyles from './styles';

export interface IAppInputAmount extends NumberFormatProps {
    hasError?: boolean;
    precision?: number,
    container_classes?: string,
    spinner?: boolean | false,
    increase?: any,
    decrease?: any,
    input_classes: string;
}

const AppInputNumber = (props: IAppInputAmount) => {
    const styles = useStyles();
    const {
        spinner,
        increase,
        decrease,
        disabled,
        onChangeAmount,
        onChange,
        placeholder,
        input_classes,
        value,
        precision,
    } = props;

    const withValueCap = (inputObj: any) => {
        const { value, floatValue } = inputObj;
        if (value === '') return true;
        if (!isNumeric(value)) { return false };
        if (floatValue < 0) return false;
        return true;
    };

    const renderSpinner = (has?: boolean) => {
        if (has) {
            return (
                <div className={styles.spinner}>
                    <span className={styles.groupArrow}>
                        <img
                            src="/images/icons/arrow-up-select.svg"
                            alt=""
                            onClick={() => {
                                if (disabled) return;
                                increase()
                            }}
                        />
                        <img
                            src="/images/icons/arrow-down-select.svg"
                            alt=""
                            onClick={() => {
                                if (disabled) return;
                                decrease();
                            }}
                        />
                    </span>
                </div>
            )
        }
        return (<></>);
    }
    return (
        <div className={props.container_classes}>
            <NumberFormat
                // {...props}
                thousandsGroupStyle="thousand"
                prefix=""
                decimalSeparator="."
                displayType="input"
                type="text"
                thousandSeparator={true}
                allowNegative={true}
                decimalScale={Number(precision)}
                className={input_classes}
                value={value}
                placeholder={placeholder}
                isNumericString
                onValueChange={(values) => {
                    if (onChangeAmount) onChangeAmount(values);
                }}
                onChange={(e) => {
                    if (onChange) onChange(e);
                }}
                isAllowed={withValueCap}
            />
            {
                renderSpinner(spinner)
            }

        </div>
    );
};

export default AppInputNumber;