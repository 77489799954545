import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      color: 'white'
    },

    menu: {

    },

    menuPaper: {
      background: theme.custom.colors.bgPagination,
      color: theme.custom.colors.white,
      marginTop: 40
    },

    menuItem: {
      color: theme.custom.colors.white2,

      "&:hover": {
        background: theme.custom.colors.cornflowerBlue,

      }
    },
    linkActive: {
      color: theme.custom.colors.lightPurple,
      fontFamily: "Ubuntu-Bold",
    },

    link: {
      color: theme.custom.colors.white2,
      "&:hover": {
        textDecoration: 'none',
        " & $menuItem": {
          color: 'white'
        }
      }
    }
  }
});
export default useStyles;