import { THOUSAND_SEPARATOR, THOUSAND_DECIMAL } from 'src/commons/constants';

export function nFormatter(num: number, digits: number) {
    const si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function numberWithCommas(x?: number) {
    if (!x || (x && isNaN(x))) return;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, THOUSAND_DECIMAL);
}

export function addSeparatorsNF(nStr: string, inD: string, outD: string, sep: string) {
    // addSeparatorsNF(43211234.56, '.', '.', ',') 43,211,234.56
    // addSeparatorsNF('52093423.003', '.', ',', '.') 52.093.423,003
    // addSeparatorsNF('93432,8', ',', '.', ',') >  93,432.8
    // addSeparatorsNF('584,567890', ',', '.', ',')  584.567890 
    // addSeparatorsNF(-1.23e8, '.', '.', ',') -123,000,000
    nStr += '';
    const dpos = nStr.indexOf(inD);
    let nStrEnd = '';
    if (dpos !== -1) {
        nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
        nStr = nStr.substring(0, dpos);
    }
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(nStr)) {
        nStr = nStr.replace(rgx, '$1' + sep + '$2');
    }
    return nStr + nStrEnd;
}

export function displayNumb(value: string) {
    if (!value || (value && isNaN(Number(value)))) return;

    return addSeparatorsNF(value, THOUSAND_SEPARATOR, THOUSAND_SEPARATOR, THOUSAND_DECIMAL);
}

export function countDecimals(value: number) {
    try {
        if ((value % 1) !== 0) {
            return value.toString().split(".")[1].length;
        }
        return 0;
    } catch (error) {
        return 0;
    }
}

export function getPercent(num1: number, num2: number) {
    let percent;
    if (num2 !== 0) {
        if (num1 !== 0) {
            percent = (num2 - num1) / num1 * 100;
        } else {
            percent = num2 * 100;
        }
    } else {
        percent = - num1 * 100;
    }
    return Math.floor(percent);
}

export function getRandomId() {
    return Math.random()
        .toString(36)
        .slice(-8);
};

export function getAlphanumeric(v: any) {
    let res = '';
    String(v)
        .split('')
        .forEach(i => {
            const charCode = i.toLowerCase().charCodeAt(0);
            if ((charCode >= 48 && charCode <= 57) || (charCode >= 97 && charCode <= 122)) {
                res += i;
            }
        });
    return res;
};

export function getAlpha(v: any) {
    let res = '';
    String(v)
        .split('')
        .forEach(i => {
            const charCode = i.toLowerCase().charCodeAt(0);
            if (charCode >= 97 && charCode <= 122) {
                res += i;
            }
        });
    return res;
};

export function getNumeric(v: any) {
    let res = '';
    String(v)
        .split('')
        .forEach(i => {
            const charCode = i.toLowerCase().charCodeAt(0);
            if (charCode >= 48 && charCode <= 57) {
                res += i;
            }
        });
    return res;
};

export function getAmountData(amount: string, baseCoin: any) {
    let amountResult = baseCoin['min'];
    try {

        // amount is null or empty
        if (!amount || (amount && amount === '') || (isNaN(Number(amount)))) {
            return amountResult;
        }
        let value = Number(amount);

        // amount less then min
        if (value < Number(baseCoin['min'])) {
            return amountResult;
        }

        // amount more than max
        if (value > Number(baseCoin['max'])) {
            return baseCoin.max;
        }

        const decimals = baseCoin['amount_precision'];
        if (Number(value) > 0 && countDecimals(Number(value)) > decimals) {
            value = Number(value.toFixed(decimals));
        }
        return value;
    } catch (error) {
        return amountResult;
    }
}

export function isNumeric(str: string, isFormat?: boolean) {
    try {
        if (typeof str != "string") return false;
        if (str === "") return false;

        // if format thousands
        if (isFormat) {
            let num = parseFloat(str.replace(/,/g, ''));
            return !isNaN(num);
        }
        return !isNaN(Number(str)) && !isNaN(parseFloat(str));
    } catch (error) {
        return false;
    }
}