import { makeStyles } from "@material-ui/core";
import 'react-phone-input-2/lib/style.css';
const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "42px", // paddingTop: 12 + line-height: 18 + paddingBottom: 12
    backgroundColor: "none",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.custom.colors.white2}`,
    "label + &": {
      marginTop: "4px",
      color: theme.custom.colors.white2,
    },
    "& .Mui-error": {
      borderColor: theme.custom.colors.red,
    },
  },
  input: {
    fontSize: 14,
    lineHeight: "18px",
    // currently, i just delay background-color changed when
    // autocomplete on chrome. it should be better.
    // god bless you
    "&:-webkit-autofill": {
      transitionDelay: "9999999s",
      transitionProperty: "background-color, color",
    },
    padding: theme.spacing(1.5, 2),
    "&::placeholder": {
      color: theme.custom.colors.santaGray2,
      opacity: 1,
      fontWeight: 500,
    },
    "&[type=number]": {
      MozAppearance: "textfield",
    },
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
  multiline: {
    padding: 0,
  },
  disabled: { color: `rgba(255, 255, 255, 0.8)` },
  // affect input if has start adorn
  inputAdornedStart: {
    paddingLeft: 0,
  },
  // affect input if has end adorn
  inputAdornedEnd: {
    paddingRight: 0,
  },
  adornedStart: {
    paddingLeft: 0,
  },
  // affect input if has end adorn
  adornedEnd: {
    paddingRight: 0,
  },
  
  error: { borderColor: "#DE4949" },
  formControl: {
    "& label": {
      color: theme.custom.colors.white2,
      fontFamily: "Ubuntu-Medium",
      fontSize: "14px",
      lineHeight: "19px",
    },
    "& .MuiFormLabel-root": {
      "& .Mui-focused": {
        color: theme.custom.colors.lightPurple,
      },
      "& .Mui-error": {
        color: theme.custom.colors.red,
      },
    },

    "& .label-focused": {
      color: theme.custom.colors.lightPurple,
    },
     "& .label-error": {
      color: '#DE4949',
    },

    "& input": {
      fontFamily: "Ubuntu-Regular",
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  helperText: {
    color: theme.custom.colors.white2,
    fontFamily: "Ubuntu-Regular",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    marginTop: "4px",
  },
  BoxInline: {
    flexDirection: "row",
    display: "flex",
  },
  BoxText: {
    display: "flex",
    // alignItems: 'center !important',
    marginTop: "8px",
    "& span": {
      color: "red",
      paddingLeft: 5,
    },
  },

  containerPhone: {

  },

  inputPhone: {
    "&.form-control": {
      paddingLeft: 40,
      height: 42,
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.custom.colors.white2}`,
      color: theme.custom.colors.white2,
      background: theme.custom.colors.bgPagination,
      [theme.breakpoints.down('xs')]: {
       background: 'none'
      },
    },

    "& .selected-flag": {
      "&.open": {
        background: 'none',
      },

      "&:hover": {
        background: 'none'
      },

    },

    // "&:focus": {
    //    border: `1px solid ${theme.custom.colors.lightPurple}`,
    // }
    
    
  },

  focused: {
    "&.form-control": {
       border: `1px solid ${theme.custom.colors.lightPurple} !important`,
     }
  },

  content: {
    position: 'absolute',
    top: 9,
    left: 70
  },

  buttonPhone: {

    "&.flag-dropdown": {
      background: 'none',
      border: 'none',
      "&.open": {
        background: 'none',
        "& .selected-flag": {
          background: 'none',
        }
      }
    },

    "& .selected-flag": {
      background: 'none',
      "&.open": {
        background: 'none',
      },
      "&:hover": {
        background: 'none'
      },

      "& .flag": {
        "& .arrow": {
          borderTop: `4px solid ${theme.custom.colors.white2}`,
        },
        "& .arrow.up": {
          borderBottom: `4px solid ${theme.custom.colors.white2}`,
          borderTop: 'none'
        },
      }
    },



  },

  dropdownPhone: {
    background: `${theme.custom.colors.bgPagination} !important`,
    "& .country": {
      color: theme.custom.colors.white4,
      "&.highlight": {
        background: `none !important`,
      },

      "& span": {
        color: `${theme.custom.colors.white4} !important`,
      },

      "&:hover": {
        background: `${theme.custom.colors.cornflowerBlue} !important`,
        "& span": {
          color: `${theme.custom.colors.btnTitle} !important`,
        }
      }
    }
  },

  groupPhone: {
    position: 'relative'
  },

  styleBOrder: {
    border: '1px solid #DE4949 !important',
  },

 
}));

export default useStyles;
