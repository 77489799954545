import { AnyAction } from 'redux';
import { MODAL_CONFIRM } from '../constants/modal-confirm';

const initialState = {
  show: false,
  msgError: '',
  onOk: null,
  isLoading: false
};

export const confirmReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case MODAL_CONFIRM.TOGGLE:
      return {
        ...state,
        show: !state.show,
        ...action.payload,
      };
    case MODAL_CONFIRM.HIDE:
      return {
        ...state,
        show: false,
        ...action.payload,
      };
    case MODAL_CONFIRM.SET_MESSAGE:
      return {
        ...state,
        msgError: action.payload,
      };
     case MODAL_CONFIRM.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
   

    default:
      return state;
  }
};
