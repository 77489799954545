import { makeStyles } from '@material-ui/core';
import {
  drawerWidth,
  drawerWidthXl,
} from 'src/commons/constants/WidthPage';

const useStyles = makeStyles((theme: any) => {
  return {
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },

    drawerPaper: {
      width: drawerWidth,
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      borderRight: 'none',
      [theme.breakpoints.up('xl')]: {
        width: drawerWidthXl,
      },
      [theme.breakpoints.up('md')]: {
        top: '88px',
      },
    },

    ScrollHeight: {
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down('sm')]: {},
    },

    ScrollHeightSmall: {
      paddingLeft: 13,
      paddingRight: 13,

      [theme.breakpoints.down('sm')]: {},
    },

    drawerOpen: {
      width: '100%',
      background: theme.custom.colors.mainBackground,
      borderColor: theme.custom.colors.brightGray,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowY: 'auto',
      marginTop: 90,
      [theme.breakpoints.up('xl')]: {
        width: drawerWidthXl,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.between('600', '700')]: {
        width: '100%',
        maxHeight: '65vh',
        minHeight: '65vh',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        marginTop: 80,
      },
    },

    drawerClose: {
      width: 65,
      background: theme.custom.colors.mainBackground,
      borderColor: theme.custom.colors.brightGray,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },

    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      minHeight: 70,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },

      [theme.breakpoints.down('xs')]: {},
    },

    logoDesktop: {},
  };
});

export default useStyles;
