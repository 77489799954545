import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Hidden, useMediaQuery } from '@material-ui/core';

import Header from 'src/components/BaseLandingPage/Header';
import HeaderText from 'src/components/BaseLandingPage/HeaderText';
import Content from 'src/components/BaseLandingPage/Content';
import Footer from 'src/components/BaseLandingPage/Footer';
import Sidebar from 'src/components/BaseLandingPage/Sidebar';

import useStyles from './landingStyles';

const DefaultLayout = (props: any) => {
    const { isFooter } = props;
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
    const [leftDrawerOpened, setLeftDrawerOpened] = useState(true);

    useEffect(() => {
        setLeftDrawerOpened(!matchDownMd);
    }, [matchDownMd]);

    const handleLeftDrawerToggle = () => {
        setLeftDrawerOpened(!leftDrawerOpened);
    };

    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            className={`${classes.root} ${!!leftDrawerOpened && classes.overMobile}`}
            style={{ overflow: 'hidden' }}
        >
            {isFooter ? (
                <>
                    <HeaderText
                        drawerOpen={leftDrawerOpened}
                        drawerToggle={handleLeftDrawerToggle}
                        {...props}
                    />
                    <Hidden mdUp>
                        <Sidebar
                            drawerOpen={leftDrawerOpened}
                            drawerToggle={handleLeftDrawerToggle}
                            {...props}
                        />
                    </Hidden>
                </>
            ) : (
                <>
                    <Header
                        drawerOpen={leftDrawerOpened}
                        drawerToggle={handleLeftDrawerToggle}
                        {...props}
                    />
                    <Hidden mdUp>
                        <Sidebar
                            drawerOpen={leftDrawerOpened}
                            drawerToggle={handleLeftDrawerToggle}
                            {...props}
                        />
                    </Hidden>
                </>
            )}

            <main
                className={`${classes.content} ${isFooter ? `${classes.background}` : ''
                    }`}
            >
                <div className={classes.toolbar} />
                <Content>{props.children}</Content>
                {isFooter ? null : <Footer />}
            </main>
        </div>
    );
};

export default DefaultLayout;
