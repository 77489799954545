import { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import DefaultLayout from 'src/components/Layout/DefaultLayout/LandingPageLayout';
import Mainhome1 from './Mainhome-1';
import LiquidityHome from './LiquidityHome';
import Global from './Global';
import useStyles from './styles';
import ApiContent from './ApiContent';
import Experience from './Experience';
import GetStart from './GetStart';
import Request from './Request';
import AOS from 'aos';

const HomePage = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Landing Page';
    AOS.init();
  }, []);

  return (
    <DefaultLayout>
      <Container className={classes.styleContainer}>
        <Mainhome1 />
        <LiquidityHome />
      </Container>
      <Global />
      <ApiContent />
      <Experience />
      <GetStart />
      <Request />
    </DefaultLayout>
  );
};

export default withRouter(HomePage);
