import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
    return {
        cardRoot: {},
        logoBox: {
            textAlign: 'center',
        },
        logo: {
            width: 116,
            height: 48,
        },

        box: {
            background: theme.custom.colors.bgrBox,
            borderRadius: 10,
            width: 611,
            margin: 'auto',
            textAlign: 'center',
            color: theme.custom.colors.white,
            fontFamily: 'Titillium Web',
            paddingBottom: 10,

            '& img': {
                width: 70,
                [theme.breakpoints.down('xs')]: {
                    width: 70,
                },
            },
            [theme.breakpoints.down('xs')]: {
                width: 335,
            },
            [theme.breakpoints.down('md')]: {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
            },

            '& .MuiButton-root': {
                fontSize: 12,
            },
        },

        boxIcon: {},
        boxContent: {
            paddingTop: 35,
            color: theme.custom.colors.colorLbl,
        },
        title: {
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 10,
            lineHeight: '37px',
            fontFamily: 'Titillium Web',
            [theme.breakpoints.down('sm')]: {
                marginBottom: 20,
            },
        },
        content: {
            fontSize: 14,
            fontWeight: 400,
            fontFamily: 'Titillium Web',
        },
        bottomContent: {
            textAlign: 'center',
            fontSize: 16,
            color: theme.custom.colors.white,
            marginTop: 20,
            fontWeight: 300,
            fontFamily: 'Titillium Web',
        },
        link: {
            fontWeight: 700,
            cursor: 'pointer',
            color: theme.custom.colors.white,
            '&:hover': {
                textDecoration: 'none',
            },
        },
    };
});
export default useStyles;
