import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    cryptoModalContainer: {
      fontSize: "14px",
      lineHeight: "19.6px",
      "& .modal-container": {
        height: "80vh",

        [theme.breakpoints.down('sm')]: {
          padding: "0",
        },
      },

      '& .body-modal-container': {
        padding: '0 !important',
      },

      "& .label": {
        fontSize: "20px",
        lineHeight: "23px",
        textAlign: "center",
        fontFamily: "Ubuntu-Bold",
        color: theme.custom.colors.titleTrading,
        padding: '0 118px',

        [theme.breakpoints.down('md')]: {
          padding: "0 60px",
        },
        [theme.breakpoints.down('sm')]: {
          padding: "0 20px",
        },
      },

      "& .desc": {
        marginTop: "15px",
        fontSize: "14px",
        lineHeight: "140%",
        textAlign: "center",
        fontFamily: "Ubuntu-Regular",
        padding: '0 118px',
        [theme.breakpoints.down('md')]: {
          padding: "0 60px",
        },
        [theme.breakpoints.down('sm')]: {
          padding: "0 20px",
        },
      },

      "& .dropdown-box": {
        marginTop: "30px",
        maxWidth: "unset",
        fontFamily: "Ubuntu-Medium",

        "& label + .MuiInput-formControl": {
          marginTop: "8px",
        },
      },
    },
    tabInput: {
      padding: '0 118px',
      [theme.breakpoints.down('md')]: {
        padding: "0 60px",
      },
      [theme.breakpoints.down('sm')]: {
        padding: "0 20px",
      },
    },
    mainTabSelect: {

      "@media (max-width: 1200px)": {
        margin: 0,
      },
    }
  };
});

export default useStyles;