import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';

import { MAX_LENGTH_INPUT } from 'src/commons/constants/AppInputFormat';
import AppButton from 'src/components/Base/AppButton';
import DatePicker from 'src/components/Base/AppDatePicker';
import AppInput from 'src/components/Base/AppInput';
import AppRadioList from 'src/components/Base/AppRadioList';
import Select from 'src/components/Base/Select';

import { trimObject } from 'src/utils/utils';
import { rulesTextField } from 'src/utils/validation';

import useStyles from './styles';
const countryList = require('react-select-country-list');

const genderList = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const radioList = ['Yes', 'No'];

const General = ({
  formData,
  lastErrors,
  setStep,
  saveData,
}: {
  formData: any;
  lastErrors: any;
  setStep: (step: number, state?: any) => void;
  saveData: (data: any, errors?: any) => void;
}) => {
  const classes = useStyles();
  const [required] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: formData?.name,
      alias: formData?.alias,
      dualCitizenship: formData?.dualCitizenship,
      countryOfCitizenship: formData?.countryOfCitizenship,
      placeOfBirth: formData?.placeOfBirth,
      gender: formData?.gender,
      dateOfBirth: formData?.dateOfBirth
        ? moment(formData.dateOfBirth, 'MM/DD/YYYY').toDate()
        : null,
    },
  });

  const options = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    const runSaveData = () => {
      let data = getValues();

      let formatedData: any = data;
      if (data.dateOfBirth)
        formatedData.dateOfBirth = moment(data.dateOfBirth).format(
          'MM/DD/YYYY'
        );
      formatedData = trimObject(formatedData);

      trigger([
        'name',
        'alias',
        'countryOfCitizenship',
        'dualCitizenship',
        'dateOfBirth',
        'gender',
        'placeOfBirth',
      ]).then((isValid) => {
        saveData(
          {
            general: { ...formatedData },
          },
          { general: !isValid }
        );
      });
    };

    return runSaveData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  useEffect(() => {
    lastErrors && trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.stepContainer}>
      <div className={classes.title}>
        <div>General Information</div>
        <span className="icon-info"></span>
      </div>
      <form>
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'name',
            rules: rulesTextField('Name'),
          }}
          required={required}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Name'}
          error={Boolean(errors.name)}
          placeholder="Full Name"
          helperText={errors.name?.message}
          labelProps={{ className: errors.name ? classes.styleLabel : '' }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'alias',
            rules: rulesTextField('Alias'),
          }}
          required={required}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Alias'}
          error={Boolean(errors.alias)}
          placeholder="Alias"
          helperText={errors.alias?.message}
          labelProps={{ className: errors.alias ? classes.styleLabel : '' }}
        />
        <Controller
          control={control}
          name="gender"
          rules={rulesTextField('Gender')}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              options={genderList}
              className={classes.inputItem}
              inputClassName={classes.selectInput}
              inputProps={{ maxLength: MAX_LENGTH_INPUT }}
              popupClassName={classes.classNameItemsContainer}
              status={errors.gender ? 'error' : 'default'}
              helperMsg={errors.gender?.message}
              label="Gender"
              placeholder="Gender"
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="dateOfBirth"
          rules={{
            validate: (value) => {
              return (
                Boolean(value && value < new Date()) ||
                'Please, enter a valid year'
              );
            },
          }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              error={!!errors.dateOfBirth}
              selected={value}
              classNames={classes.inputItem}
              label="Date of birth"
              required={true}
              placeholderText="mm/dd/yyyy"
              clearButtonClassName={classes.btnClear}
              helperText={errors.dateOfBirth?.message}
              maxDate={new Date()}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="placeOfBirth"
          rules={rulesTextField('Place of birth')}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              options={options}
              className={classes.inputItem}
              inputClassName={classes.selectInput}
              popupClassName={classes.classNameItemsContainer}
              status={errors.placeOfBirth ? 'error' : 'default'}
              helperMsg={errors.placeOfBirth?.message}
              label="Place of birth"
              placeholder="Choose Country"
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="countryOfCitizenship"
          rules={rulesTextField('Country of citizenship')}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              options={options}
              className={classes.inputItem}
              inputClassName={classes.selectInput}
              popupClassName={classes.classNameItemsContainer}
              status={errors.countryOfCitizenship ? 'error' : 'default'}
              helperMsg={errors.countryOfCitizenship?.message}
              label="Country of citizenship"
              placeholder="Choose Country"
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="dualCitizenship"
          rules={rulesTextField('Dual citizenship')}
          render={({ field: { onChange, value } }) => (
            <AppRadioList
              className={classes.inputItem}
              label="Dual citizenship"
              value={value}
              radioList={radioList}
              error={Boolean(errors.dualCitizenship)}
              helperText={errors.dualCitizenship?.message}
              handleSelect={onChange}
            />
          )}
        />
        <div className={classes.btnNext}>
          <AppButton type="submit" fullWidth={true} onClick={() => setStep(2)}>
            Next
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default General;
