import { useEffect, useState } from 'react';
import { Container, Hidden, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import DefaultLayout from 'src/components/Layout/DefaultLayout/LandingPageLayout';
import TeamMobile from './teamMobile';
import Link from 'src/components/Base/Link';

import useStyles from './styles';

const TeamPage = (props: any) => {
  const classes = useStyles();

  const [showDescription, setShowDescription] = useState(false);
  const showDescriptionEvent = () => {
    setShowDescription(!showDescription);
  };

  const [showDesc, setShowDesc] = useState(false);
  const showDescEvent = () => {
    setShowDesc(!showDesc);
  };

  const [showDesc1, setShowDesc1] = useState(false);
  const showDescEvent1 = () => {
    setShowDesc1(!showDesc1);
  };

  const [showDesc2, setShowDesc2] = useState(false);
  const showDescEvent2 = () => {
    setShowDesc2(!showDesc2);
  };

  const [showDesc3, setShowDesc3] = useState(false);
  const showDescEvent3 = () => {
    setShowDesc3(!showDesc3);
  };

  const [showDesc4, setShowDesc4] = useState(false);
  const showDescEvent4 = () => {
    setShowDesc4(!showDesc4);
  };

  useEffect(() => {
    document.title = 'Team';
  }, []);

  return (
    <DefaultLayout>
      <section className={classes.mainTeam}>
        <Container className={classes.mainContainer}>
          <Typography variant="h3" className={classes.titleTeam}>
            Our Team
          </Typography>

          <div className={classes.teamContent}>
            <div
              className={classes.groupTeam}
              style={{
                maxHeight: showDesc ? '626px' : '346px',
              }}
            >
              <div className={classes.descTeam}>
                <div className={classes.detailMember}>
                  <img src="/images/team-1.png" alt="" />
                  <div className={classes.nameTeam}>
                    <Typography variant="h5" className={classes.nameMember}>
                      Matt De Souza
                    </Typography>
                    <p className={classes.textOffice}>Co-Founder & CEO</p>
                  </div>
                </div>
                <img
                  src="/images/icon-in.png"
                  alt=""
                  className={classes.styleIn}
                />
              </div>

              <div className={classes.styleDescription}>
                <span className={classes.styleTestDesc}>
                  Matt has spent 15+ years in capital markets across wealth
                  management, forex trading and financial sales. Leveraging his
                  entrepreneurship and business development skills to build a
                  wealth management business managing in excess of USD40m of
                  assets.&nbsp;
                </span>
                {showDesc && (
                  <span>
                    Joining Goldman Sachs as an engineer straight out of
                    university, he quickly rose through the ranks to become VP
                    in just 4 years. He was working across the securities
                    division, responsible for building and maintaining Goldmans
                    global derivatives trading and settlement systems. Mark
                    worked closely with integrating brokers, clearing houses and
                    regulatory reporting during his tenure at the firm. Having
                    always had a passion for cryptocurrencies, he now leads
                    oneAlpha’s technical team with a vision of building better
                    markets and making it more accessible to everyone as it
                    rapidly moves towards global mass adoption.
                  </span>
                )}
              </div>
              <div className={classes.linkDetail}>
                <Link onClick={showDescEvent} className={classes.styleButton}>
                  {showDesc ? 'Read less' : 'Read more'}
                </Link>
              </div>
            </div>

            <div
              className={classes.groupTeam}
              style={{
                maxHeight: showDescription ? '626px' : '346px',
              }}
            >
              <div className={classes.descTeam}>
                <div className={classes.detailMember}>
                  <img src="/images/team-2.png" alt="" />
                  <div className={classes.nameTeam}>
                    <Typography variant="h5" className={classes.nameMember}>
                      Mark Johnson
                    </Typography>
                    <p className={classes.textOffice}>Co-Founder & CTO</p>
                  </div>
                </div>

                <img
                  src="/images/icon-in.png"
                  alt=""
                  className={classes.styleIn}
                />
              </div>

              <div className={classes.styleDescription}>
                <span className={classes.styleTestDesc}>
                  Mark has been very technical since an early age which helped
                  him discover Bitcoin early in 2011 through mining and trading.
                  He quickly came to realise the power that digital currencies
                  can have on the broader economy.&nbsp;
                </span>
                {showDescription && (
                  <span>
                    Joining Goldman Sachs as an engineer straight out of
                    university, he quickly rose through the ranks to become VP
                    in just 4 years. He was working across the securities
                    division, responsible for building and maintaining Goldmans
                    global derivatives trading and settlement systems. Mark
                    worked closely with integrating brokers, clearing houses and
                    regulatory reporting during his tenure at the firm. Having
                    always had a passion for cryptocurrencies, he now leads
                    oneAlpha’s technical team with a vision of building better
                    markets and making it more accessible to everyone as it
                    rapidly moves towards global mass adoption.
                  </span>
                )}
                <br></br>
              </div>

              <div className={classes.linkDetail}>
                <Link
                  onClick={showDescriptionEvent}
                  className={classes.styleButton}
                >
                  {showDescription ? 'Read less' : 'Read more'}
                </Link>
              </div>
            </div>

            <div
              className={classes.groupTeam}
              style={{
                maxHeight: showDesc1 ? '626px' : '346px',
              }}
            >
              <div className={classes.descTeam}>
                <div className={classes.detailMember}>
                  <img src="/images/team-3.png" alt="" />
                  <div className={classes.nameTeam}>
                    <Typography variant="h5" className={classes.nameMember}>
                      Raymond Shuai
                    </Typography>
                    <p className={classes.textOffice}>Finance Director</p>
                  </div>
                </div>

                <img
                  src="/images/icon-in.png"
                  alt=""
                  className={classes.styleIn}
                />
              </div>

              <div className={classes.styleDescription}>
                <span className={classes.styleTestDesc}>
                  Raymond is an entrepreneurial, highly versatile and
                  strategically minded chartered accountant with a can do and
                  will do approach to solving complex financial, tax and
                  regulatory issues for fast growing fintech and digital assets
                  companies, including but not limited to fundraising.&nbsp;
                </span>
                {showDesc1 && (
                  <span>
                    Joining Goldman Sachs as an engineer straight out of
                    university, he quickly rose through the ranks to become VP
                    in just 4 years. He was working across the securities
                    division, responsible for building and maintaining Goldmans
                    global derivatives trading and settlement systems. Mark
                    worked closely with integrating brokers, clearing houses and
                    regulatory reporting during his tenure at the firm. Having
                    always had a passion for cryptocurrencies, he now leads
                    oneAlpha’s technical team with a vision of building better
                    markets and making it more accessible to everyone as it
                    rapidly moves towards global mass adoption.
                  </span>
                )}
                <br></br>
              </div>
              <div className={classes.linkDetail}>
                <Link onClick={showDescEvent1} className={classes.styleButton}>
                  {showDesc1 ? 'Read less' : 'Read more'}
                </Link>
              </div>
            </div>

            <div
              className={classes.groupTeam}
              style={{
                maxHeight: showDesc2 ? '626px' : '346px',
              }}
            >
              <div className={classes.descTeam}>
                <div className={classes.detailMember}>
                  <img src="/images/team-4.png" alt="" />
                  <div className={classes.nameTeam}>
                    <Typography variant="h5" className={classes.nameMember}>
                      George Kiff
                    </Typography>
                    <p className={classes.textOffice}>Tech Lead</p>
                  </div>
                </div>

                <img
                  src="/images/icon-in.png"
                  alt=""
                  className={classes.styleIn}
                />
              </div>

              <div className={classes.styleDescription}>
                <span className={classes.styleTestDesc}>
                  George is very analytical with a strong attention to detail.
                  Having moved to Asia in 2018, he joins oneAlpha with a wealth
                  of knowledge bringing 10+ years experience as an outstanding
                  full-stack developer. His strong engineering principles
                  including test driven development, agile...&nbsp;
                </span>
                {showDesc2 && (
                  <span>
                    Joining Goldman Sachs as an engineer straight out of
                    university, he quickly rose through the ranks to become VP
                    in just 4 years. He was working across the securities
                    division, responsible for building and maintaining Goldmans
                    global derivatives trading and settlement systems. Mark
                    worked closely with integrating brokers, clearing houses and
                    regulatory reporting during his tenure at the firm. ving
                    always had a passion for cryptocurrencies, he now leads
                    oneAlpha’s technical team with a vision of building better
                    markets and making it more accessible to everyone as it
                    rapidly moves towards global mass adoption.
                  </span>
                )}
              </div>
              <div className={classes.linkDetail}>
                <Link onClick={showDescEvent2} className={classes.styleButton}>
                  {showDesc2 ? 'Read less' : 'Read more'}
                </Link>
              </div>
            </div>

            <div
              className={classes.groupTeam}
              style={{
                maxHeight: showDesc4 ? '626px' : '346px',
              }}
            >
              <div className={classes.descTeam}>
                <div className={classes.detailMember}>
                  <img src="/images/team-5.png" alt="" />
                  <div className={classes.nameTeam}>
                    <Typography variant="h5" className={classes.nameMember}>
                      Kelvin Ung
                    </Typography>
                    <p className={classes.textOffice}>Strategic Adviser</p>
                  </div>
                </div>

                <img
                  src="/images/icon-in.png"
                  alt=""
                  className={classes.styleIn}
                />
              </div>

              <div className={classes.styleDescription}>
                <span className={classes.styleTestDesc}>
                  Kelvin has a strong track record of spearheading strategy and
                  operations and building out distribution for equities and
                  next-generation digital asset marketplaces in Asia. He has
                  also acted as a financial regulator in enforcement,
                  representing the SFC at IOSCO.&nbsp;
                </span>
                {showDesc4 && (
                  <span>
                    Joining Goldman Sachs as an engineer straight out of
                    university, he quickly rose through the ranks to become VP
                    in just 4 years. He was working across the securities
                    division, responsible for building and maintaining Goldmans
                    global derivatives trading and settlement systems. Mark
                    worked closely with integrating brokers, clearing houses and
                    regulatory reporting during his tenure at the firm. Having
                    always had a passion for cryptocurrencies, he now leads
                    oneAlpha’s technical team with a vision of building better
                    markets and making it more accessible to everyone as it
                    rapidly moves towards global mass adoption.
                  </span>
                )}
                <br></br>
              </div>
              <div className={classes.linkDetail}>
                <Link onClick={showDescEvent4} className={classes.styleButton}>
                  {showDesc2 ? 'Read less' : 'Read more'}
                </Link>
              </div>
            </div>

            <div
              className={classes.groupTeam}
              style={{
                maxHeight: showDesc3 ? '626px' : '346px',
              }}
            >
              <div className={classes.descTeam}>
                <div className={classes.detailMember}>
                  <img src="/images/team-6.png" alt="" />
                  <div className={classes.nameTeam}>
                    <Typography variant="h5" className={classes.nameMember}>
                      Chris Gale
                    </Typography>
                    <p className={classes.textOffice}>
                      Marketing & Ecosystem Adviser
                    </p>
                  </div>
                </div>

                <img
                  src="/images/icon-in.png"
                  alt=""
                  className={classes.styleIn}
                />
              </div>

              <div className={classes.styleDescription}>
                <span className={classes.styleTestDesc}>
                  Founder of a number of Start ups including Odyssey Mobile
                  which was acquired by Phuneware and IPO’d in2019. Co -Founder
                  of blockchain video disruption platform, Verasity. Adviser to
                  a number of blockchain projects helping to connect and bridge
                  different eco-systems.&nbsp;
                </span>
                {showDesc3 && (
                  <span>
                    Joining Goldman Sachs as an engineer straight out of
                    university, he quickly rose through the ranks to become VP
                    in just 4 years. He was working across the securities
                    division, responsible for building and maintaining Goldmans
                    global derivatives trading and settlement systems. Mark
                    worked closely with integrating brokers, clearing houses and
                    regulatory reporting during his tenure at the firm. Having
                    always had a passion for cryptocurrencies, he now leads
                    oneAlpha’s technical team with a vision of building better
                    markets and making it more accessible to everyone as it
                    rapidly moves towards global mass adoption.
                  </span>
                )}
                <br></br>
              </div>
              <div className={classes.linkDetail}>
                <Link onClick={showDescEvent3} className={classes.styleButton}>
                  {showDesc3 ? 'Read less' : 'Read more'}
                </Link>
              </div>
            </div>
          </div>
          <Hidden smUp>
            <TeamMobile />
          </Hidden>
        </Container>
      </section>
    </DefaultLayout>
  );
};

export default withRouter(TeamPage);
