import { HTTPBaseService } from 'src/request/HTTPBaseService';

export interface TransactionRequestParams {
  transaction_state?:
    | 'PENDING'
    | 'PENDING APPROVAL'
    | 'COMPLETED'
    | 'REJECTED'
    | 'EXPIRED';
  asset: string;
  timestamp_start?: number | null;
  timestamp_end?: number | null;
  page?: number;
  page_size?: number;
  transaction_type: string;
}

export interface ApiKey {
  label: string;
  prefix: string;
  read_only: boolean;
  allow_trading: boolean;
  allow_withdrawals: boolean;
  change_account_data: boolean;
  ip_addresses: any[] | null;
}

export interface NewApiKey {
  label?: string;
  prefix?: string;
  key?: string;
  secret?: string;
}

export interface ConfirmBody {
  code: string;
  two_fa?: string;
}

export interface CreateApiKeyBody extends Omit<ApiKey, 'prefix'> {}

export interface ConfirmCreateApiKeyBody
  extends CreateApiKeyBody,
    ConfirmBody {}

export interface EditApiKeyBody extends ApiKey {}

export interface ConfirmEditApiKeyBody extends EditApiKeyBody, ConfirmBody {}

export interface DeleteApiKeyBody
  extends Omit<
    ApiKey,
    | 'read_only'
    | 'allow_trading'
    | 'allow_withdrawals'
    | 'change_account_data'
    | 'ip_addresses'
  > {}

export interface ConfirmDeleteApiKeyBody
  extends DeleteApiKeyBody,
    ConfirmBody {}

export interface GetApiKeyResponse {
  keys: ApiKey[];
}

export class ApiKeyService extends HTTPBaseService {
  private static classInstance?: ApiKeyService;

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new ApiKeyService();
    }
    return this.classInstance;
  }

  public async get() {
    return await this.instance.get<GetApiKeyResponse>('/private/api/keys/');
  }

  public async create(body: CreateApiKeyBody) {
    return await this.instance.post('/private/api_keys/', body);
  }

  public async confirmCreate(body: ConfirmCreateApiKeyBody) {
    return await this.instance.post<NewApiKey>('/private/api_keys/', body);
  }

  public async edit(body: EditApiKeyBody) {
    return await this.instance.put('/private/api_keys/', body);
  }

  public async confirmEdit(body: ConfirmEditApiKeyBody) {
    return await this.instance.put('/private/api_keys/', body);
  }

  public async delete(body: DeleteApiKeyBody) {
    return await this.instance.delete('/private/api_keys/', { data: body });
  }
  public async confirmDelete(body: ConfirmDeleteApiKeyBody) {
    return await this.instance.delete('/private/api_keys/', { data: body });
  }
}
