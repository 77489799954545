import { MouseEventHandler, ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import HeaderLogo from 'src/components/BaseLandingPage/Common/HeaderLogo';
import ButtonCommon from '../Button';
import Link from 'src/components/Base/Link';

import useStyles from './styles';
import useClasses from 'src/routing/LandingPage/ThankYouPage/ThankYouComponent/styles';

export const CardThankYou = ({
  onClickOk,
  onClickResent,
  icon,
  title,
  content,
  link,
  label,
  contentBtn,
}: {
  onClickOk: Function;
  onClickResent?: MouseEventHandler;
  icon: string;
  title: string;
  content: ReactElement | string;
  link?: string;
  label?: string;
  contentBtn: string;
}) => {
  const style = useStyles();
  const classes = useClasses();

  return (
    <div className={style.box}>
      <img src={icon} alt="email" />
      <div className={style.boxContent}>
        <Typography className={style.title}> {title} </Typography>
        <Typography className={`${style.content} ${classes.padding}`}>
          {content}
        </Typography>
      </div>
      <ButtonCommon onClick={onClickOk}> {contentBtn} </ButtonCommon>

      {/* Send again */}
      {label && (
        <div className={style.bottomContent}>
          <label>
            {label}{' '}
            <Link className={style.link} onClick={onClickResent}>
              {link}
            </Link>
          </label>
        </div>
      )}
    </div>
  );
};

const CardCommon = (props: {
  onClickOk: Function;
  onClickResent?: MouseEventHandler;
  icon: string;
  title: string;
  content: ReactElement | string;
  link?: string;
  label?: string;
  contentBtn: string;
}) => {
  const style = useStyles();

  return (
    <div className={style.cardRoot}>
      <HeaderLogo />
      <CardThankYou {...props} />
    </div>
  );
};
export default CardCommon;
