import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import Checkbox from 'src/components/Base/Checkbox';

import useStyles from './styles';
import useOnClickOutside from 'src/hooks/useOnClickOutside';

type Option = { value: string; label: string };
type Value = string | null;

export interface SelectProps {
  value?: Value;
  canDeselect: boolean;
  options: Option[];
  label: string;
  placeholder: string;
  className: string;
  inputClassName?: string;
  labelClassName?: string;
  popupClassName?: string;
  status: 'error' | 'success' | 'default';
  helperMsg: string;
  onChange?: (value: Value) => void;
  tooltip?: string;
  defaultValue?: Value;
}

const ArrowDownIcon = ({ className }: any) => {
  return <span className={clsx('icon-arrow-down', className)}></span>;
};

const Select: React.FC<any> = (props: SelectProps) => {
  const {
    value: valueProp,
    canDeselect = false,
    options,
    className,
    inputClassName,
    labelClassName,
    label,
    placeholder,
    status = 'default',
    helperMsg,
    onChange = () => {},
    tooltip,
    defaultValue,
    popupClassName,
  } = props;

  const classes = useStyles();
  const [openState, setOpenState] = useState(false);
  const [valueState, setValueState] = useState(defaultValue);
  const [hasFocus, setHasFocus] = useState(false);

  const value = valueProp !== undefined ? valueProp : valueState;

  const index = options.findIndex((item) => item.value === value);
  const [cursor, setCursor] = useState(index);

  const selectContainerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(selectContainerRef, (e) => {
    if (openState) setOpenState(false);
  });

  const isOptionSelected = (option: Option) => {
    return option.value === value;
  };

  const closePopup = () => {
    setOpenState(false);
    setHasFocus(false);
  };

  const selectOption = (option: Option) => {
    let newValue = option.value;
    setValueState(newValue);
    onChange(newValue);
    closePopup();
  };

  const deselectOption = () => {
    setValueState(null);
    onChange(null);
  };

  const keyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (openState && (e.key === 'ArrowUp' || e.key === 'Up') && cursor > 0) {
      e.preventDefault();
      setCursor((prev) => prev - 1);
      setValueState(options[cursor - 1].value);
      onChange(options[cursor - 1].value);
      scrollToOption(cursor - 1, 'top');
    } else if (
      openState &&
      (e.key === 'ArrowDown' || e.key === 'Down') &&
      cursor < options.length - 1
    ) {
      e.preventDefault();
      setCursor((prev) => prev + 1);
      setValueState(options[cursor + 1].value);
      onChange(options[cursor + 1].value);
      scrollToOption(cursor + 1, 'top');
    } else if (openState && e.key === 'Enter') {
      const result = options[cursor];
      selectOption(result);
    }
  };

  const onInputKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (openState && /^[\w]$/.test(e.key)) {
      scrollToOption(
        options.findIndex(
          (option) => option.label[0]?.toLowerCase() === e.key.toLowerCase()
        ),
        'top'
      );
    }
    keyPress(e);
  };

  const scrollToOption = (index: number, position: 'bot' | 'top' = 'bot') => {
    const popoverEl = popoverRef.current;
    const optionHeight = itemRef.current?.clientHeight || 0;

    let distance =
      index * optionHeight -
      (position === 'bot' ? (popoverEl?.clientHeight || 0) - optionHeight : 0);

    distance >= 0 && popoverEl?.scroll(0, distance);
  };

  const focusSelectedOption = () => {
    if (value) {
      scrollToOption(options.findIndex((option) => option.value === value));
    }
  };

  useEffect(() => {
    openState && focusSelectedOption();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openState]);

  const renderValue = () => {
    return !value || value.length === 0 ? (
      <span className={classes.placeholder}>{placeholder}</span>
    ) : (
      <span className={classes.value}>
        {options.find((el) => el.value === value)?.label}
      </span>
    );
  };

  return (
    <div
      className={clsx(className, classes.container, {
        [classes.error]: status === 'error',
        [classes.success]: status === 'success',
      })}
    >
      {tooltip ? (
        <Tooltip
          title={<>{tooltip}</>}
          classes={{ tooltip: classes.customTooltip }}
          placement="top-start"
        >
          <label
            className={clsx(classes.label, labelClassName, {
              [classes.labelFocused]: hasFocus,
            })}
          >
            {label}
          </label>
        </Tooltip>
      ) : (
        <label
          className={clsx(classes.label, labelClassName, {
            [classes.labelFocused]: hasFocus,
          })}
        >
          {label}
        </label>
      )}
      <div
        className={clsx(classes.selectRoot, { [classes.hasLabel]: !!label })}
        ref={selectContainerRef}
      >
        <div
          tabIndex={0}
          className={clsx(classes.inputRoot, inputClassName)}
          onKeyDown={onInputKeyDown}
          onClick={() => {
            setOpenState(!openState);
            setCursor(index);
          }}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        >
          {renderValue()}
          <ArrowDownIcon
            className={clsx(classes.icon, { [classes.iconFlip]: openState })}
          />
        </div>
        {openState && (
          <div
            ref={popoverRef}
            className={clsx(
              classes.popover,
              {
                [classes.multiple]: canDeselect,
              },
              popupClassName
            )}
          >
            {options?.map((option) => (
              <div
                key={option.value}
                onClick={() => selectOption(option)}
                ref={itemRef}
                className={clsx(classes.option, {
                  [classes.optionSelected]: isOptionSelected(option),
                })}
              >
                {canDeselect && (
                  <Checkbox
                    checked={isOptionSelected(option)}
                    onChange={(value: boolean) => {
                      !value ? deselectOption() : selectOption(option);
                    }}
                  />
                )}
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={classes.helper}>{helperMsg}</div>
    </div>
  );
};

export default Select;
