import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
    return {
        mainModalDelete: {
            "& .modal-container": {
                width: 454,
                [theme.breakpoints.down('xs')]: {
                    width: 335
                },
                "& .body-modal-container": {
                    padding: "0 70px",
                    maxHeight: '100%',
                    minHeight: '100%',
                    [theme.breakpoints.down('xs')]: {
                        padding: "0 30px",
                    },
                },
            },
        },

        mainModal: {
        },

        titlePopUp: {
            textAlign: "center",
        },

        styleTitle: {
            fontSize: "20px",
            lineHeight: "23px",
            color: theme.custom.colors.boldGray,
            margin: "20px 0",
        },

        textConfirm: {
            fontSize: "14px",
            lineHeight: "140%",
            marginBottom: 30,
            color: "#9BA1B1",
        },

        boxBtnConfirm: {
            display: 'flex',
            justifyContent: 'center'
        },

        btnCancel: {
            width: "126px",
            height: "36px",
            borderRadius: "6px",
            color: "#EBECF0",
            background: theme.custom.colors.btnCancle,
            textTransform: "none",
            marginRight: 20,

            "&:hover": {
                background: theme.custom.colors.btnCancle,
            },
        },

        delete: {
            width: "126px",
            height: "36px",
            borderRadius: "6px",
            color: "#EBECF0",
            background: theme.custom.colors.cornflowerBlue,
            textTransform: "none",
            "&:hover": {
                background: theme.custom.colors.purple2
            }
        },
    };
});

export default useStyles;