import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    radioClass: {
      width: '100%',
      margin: '15px 0 0',
      fontFamily: 'Ubuntu-Regular',
      fontSize: '14px',
      lineHeight: '20px',

      '& .MuiRadio-root': {
        padding: 0,
        marginRight: '5px',
      },

      '& .MuiTypography-body1': {
        fontFamily: 'Ubuntu-Regular',
        fontSize: '14px',
        lineHeight: '20px',
      },
    },

    label: {
      fontFamily: 'Ubuntu-Medium',
      fontSize: '14px',
      lineHeight: '20px',
      // margin: "20px 0 2px",
    },

    icon: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: theme.custom.colors.grey2,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',

      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        borderColor: theme.custom.colors.cornflowerBlue,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: theme.custom.colors.grey3,
      },
    },
    checkedIcon: {
      borderColor: theme.custom.colors.cornflowerBlue,
      '&:before': {
        display: 'block',
        width: '14px',
        height: '14px',
        background: theme.custom.colors.cornflowerBlue,
        borderRadius: '50%',
        content: '""',
      },
      'input:hover ~ &': {
        // backgroundColor: 'unset',
      },
    },

    helperText: {
      fontFamily: 'Ubuntu-Regular',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      marginTop: '4px',
    },
    error: {
      color: theme.custom.colors.red1,
    },
  };
});

export default useStyles;
