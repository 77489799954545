import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    modal: {
      textAlign: 'center',

      '& .modal-container': {
        paddingBottom: 51,
        maxWidth: 485,

        '@media (max-width: 600px)': {
          maxWidth: '335px',
        },
      },
      "& .body-modal-container": {
        [theme.breakpoints.down('xs')]: {
          padding: "0 20px",
          minHeight: '25vh !important',
        },
      },

      '& button': {
        margin: '40px auto 0',
        minWidth: 126,
      },
    },

    styleModal: {
      fontSize: 20,
      color: theme.custom.colors.titleTrading,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },

    rowBtn: {
      '& button': {
        margin: '40px 20px 0 20px',
      },
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  };
});

export default useStyles;
