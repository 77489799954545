import React from 'react';
import useStyles from './styles';

interface Props {
  value?: any;
  handleSetValue: (e: any) => void;
  height?: string;
  label?: string;
  description?: string;
  className?: string;
  id?: number;
}

const AppUpload: React.FC<Props> = ({
  label,
  description,
  value,
  handleSetValue,
  height,
  id,
  className,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <div className={className}>
      <div className={styles.label}>{label}</div>
      {description && <div className={styles.description}>{description}</div>}
      {!value.name ? (
        <div className={styles.btn}>
          <input
            onChange={handleSetValue}
            id={`upload${id}`}
            type="file"
            className={styles.visuallyHidden}
            {...rest}
          />
          <label className={styles.regular} htmlFor={`upload${id}`}>
            UPLOAD FILE
          </label>
        </div>
      ) : (
        <div className={styles.fileContainer}>
          <div className={styles.itemUploaded}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.239 2.41406C15.0604 2.41406 13.8829 2.88102 13 3.8125L6.59372 10.5703C4.19841 13.098 4.19841 17.1613 6.59372 19.6891C6.66756 19.7669 6.7563 19.829 6.85466 19.8719C6.85622 19.8724 6.85778 19.8729 6.85934 19.8734C9.27266 22.1603 13.0371 22.1348 15.3547 19.6891L19.8609 14.9344C19.9372 14.859 19.9975 14.769 20.0383 14.6698C20.0792 14.5706 20.0996 14.4642 20.0985 14.3569C20.0973 14.2496 20.0746 14.1437 20.0317 14.0454C19.9888 13.9471 19.9266 13.8584 19.8487 13.7846C19.7709 13.7108 19.6789 13.6535 19.5785 13.6159C19.478 13.5784 19.371 13.5614 19.2638 13.5661C19.1566 13.5707 19.0515 13.5968 18.9546 13.6429C18.8578 13.689 18.7712 13.7541 18.7 13.8344L14.1937 18.5891C12.3923 20.4901 9.55764 20.4901 7.75622 18.5891C5.95472 16.688 5.95472 13.5714 7.75622 11.6703L14.1609 4.9125C15.3327 3.67627 17.1469 3.67627 18.3187 4.9125C19.4904 6.14861 19.4904 8.18889 18.3187 9.425L12.489 15.5766C11.9459 16.1492 11.1531 16.1487 10.6109 15.5766C10.0687 15.0044 10.0687 14.0409 10.6109 13.4688L15.3 8.52031C15.3763 8.4449 15.4366 8.35492 15.4774 8.25571C15.5182 8.15651 15.5387 8.05011 15.5375 7.94285C15.5364 7.83559 15.5137 7.72965 15.4708 7.63133C15.4279 7.53302 15.3656 7.44433 15.2878 7.37055C15.2099 7.29677 15.118 7.2394 15.0175 7.20186C14.917 7.16431 14.81 7.14736 14.7029 7.152C14.5957 7.15664 14.4906 7.18279 14.3937 7.22888C14.2968 7.27498 14.2102 7.34008 14.139 7.42031L9.44841 12.3688C8.31222 13.5678 8.31222 15.4776 9.44841 16.6766C9.52424 16.7564 9.61574 16.8197 9.71716 16.8625C10.8697 17.8253 12.5913 17.7927 13.65 16.6766L19.4797 10.525C21.1727 8.73878 21.1971 5.94337 19.6437 4.07188C19.6063 3.97564 19.5506 3.88756 19.4797 3.8125C18.5967 2.88102 17.4177 2.41406 16.239 2.41406Z"
                fill="#EBECF0"
              />
            </svg>
            <span className={styles.fileName}>{value.name}</span>
          </div>
          <div
            className={styles.deleteBtn}
            onClick={() => handleSetValue(null)}
            tabIndex={Math.random()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Space') {
                handleSetValue(null);
              }
            }}
          >
            Delete
          </div>
        </div>
      )}
      {value.msg && <div className={styles.msg}>{value.msg}</div>}
    </div>
  );
};

export default AppUpload;
