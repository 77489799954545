import useStyles from "./styles";

const SelectTabCash = ({
  activeNav,
  onSetActiveNav,
}:{
  activeNav: number;
  onSetActiveNav: (value: number) => void;
}) => {
  const styles = useStyles();

  return (
    <ul className={styles.navHeadTrading}>
      <li
        className={`${styles.itemNav} ${
          activeNav === 0 && styles.activeItemNav
        }`}
        onClick={() => onSetActiveNav(0)}
      >
        Domestic
      </li>
      <li
        className={`${styles.itemNav} ${
          activeNav === 1 && styles.activeItemNav
        }`}
        onClick={() => onSetActiveNav(1)}
      >
        International
      </li>
    </ul>
  );
};

export default SelectTabCash;
