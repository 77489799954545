import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    stepContainer: {
      background: theme.custom.colors.bgPagination,
      width: '680px',
      borderRadius: '8px',
      margin: '17px auto',
      padding: '25px 113px 40px',
      color: theme.custom.colors.titleTrading,

      '& .mt-40': {
        marginTop: '40px',
      },

      '& .MuiFormLabel-root': {
        fontWeight: 500,
      },
      [theme.breakpoints.between('500', '620')]: {
        width: '100%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '25px 0px 40px 0',
        background: 'none',
        width: '100%',
      },
    },

    title: {
      fontFamily: 'Ubuntu-bold',
      fontSize: 20,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',

      '& .icon-info:before': {
        marginLeft: '10px',
        fontSize: 24,
        color: theme.custom.colors.titleTrading,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    groupForm: {},

    inputItem: {
      width: '100%',
      marginTop: '30px',

      '& input': {
        '&::placeholder': {
          color: theme.custom.colors.colorPlace,
        },
      },
    },

    '& .MuiFormControl-root': {
      width: '100%',
      marginTop: '30px',
    },

    numberPhone: {
      marginTop: '8px',
    },

    btnLink: {
      fontFamily: 'Ubuntu-Regular',
      color: theme.custom.colors.cornflowerBlue,
      textDecoration: 'underline',
      marginTop: '30px',
      cursor: 'pointer',
      boxSizing: 'unset',
      "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
      width: 'fit-content',

      "&:active": {
        background: "unset",
      },

      [theme.breakpoints.down('xs')]: {
        outline: 'none',
      },

    },

    btnNext: {
      marginTop: '28px',
      width: 217,
      paddingRight: '10px',

      [theme.breakpoints.down('xs')]: {
        width: 158,
      },
    },

    customTooltip: {
      backgroundColor: theme.custom.colors.cardBackground,
      boxShadow: '0px 4px 25px rgba(127, 99, 244, 0.2)',
      borderRadius: '6px',
      padding: '20px 20px 30px',
      maxWidth: '508px'
    },

    labelTooltip: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: '20px',
      lineHeight: '23px',
      color: theme.custom.colors.titleTrading,
    },

    descTooltip: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: '16px',
      lineHeight: '140%',
      color: theme.custom.colors.descColor,
    },

    styleLabel: {
      color: `${theme.custom.colors.red1}!important`
    },
  };
});

export default useStyles;
