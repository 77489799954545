import { Button, Container, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import { PAGE } from 'src/utils/utils';
import useStyles from './styles';

const GetStart = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const content = [
    {
      id: 0,
      title: 'Register',
      text: 'Register for an account to gain access to our trading platform',
      btn: 'Sign up',
      btnEvent: () => {
        history.push(PAGE.SIGNUP);
      },
      isActive: true,
      style: 'doing',
    },
    {
      id: 1,
      title: 'Onboarding',
      text: 'Complete our easy onboarding process through providing the requested documents.',
      isActive: false,
      style: 'todo',
    },
    {
      id: 2,
      title: 'Start Trading',
      text: 'Fund your account and start trading right away. Our team is on 24-7 standby to assist all your needs.',
      isActive: false,
      style: 'todo',
    },
  ];
  const [active, setActive] = useState(content);
  const handleActive = (id: any) => {
    const result = content.map((item) => {
      if (item.id < id) {
        return { ...item, isActive: item.id === id, style: 'done' };
      } else if (item.id === id) {
        return { ...item, isActive: item.id === id, style: 'doing' };
      } else {
        return { ...item, isActive: item.id === id, style: 'todo' };
      }
    });
    setActive(result);
  };
  const getBorder = (style: any) => {
    let border = '';
    if (style === 'done') {
      border = classes.done;
    } else if (style === 'doing') {
      border = classes.doing;
    } else {
      border = classes.todo;
    }
    return border;
  };
  const getLine = (item: any) => {
    let line = '';
    if (item.isActive) {
      switch (item.id) {
        case 0:
          line = classes.line1;
          break;
        case 1:
          line = classes.line2;
          break;
        case 2:
          line = classes.line3;
          break;
        default:
          break;
      }
    }
    return line;
  };
  const getShadow = (item: any) => {
    let shadow = '';
    if (item.style === 'done') {
      shadow = classes.boxShadow;
    } else if (item.style === 'doing') {
      shadow = classes.boxShadow;
    }
    return shadow;
  };
  useEffect(() => { });
  return (
    <section className={classes.mainGet}>
      <Container>
        <Typography variant="h4" className={classes.titleGet}>
          Getting Started
        </Typography>

        <div className={classes.groupStep}>
          <div className={classes.detailStep}>
            {active.map((item: any) => {
              return (
                <div
                  className={`${classes.detailBox1} ${getShadow(item)}`}
                  key={item.id}
                >
                  <h4 className={classes.titleStep}>{item.title}</h4>
                  <p className={classes.textStep}>{item.text}</p>
                  {item.btn ? (
                    <Button className={classes.btnStep} onClick={item.btnEvent}>
                      {item.btn}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>

          <div className={classes.multiStep}>
            <div className={`${classes.lineUp}`}>
              <div className={`${classes.dot}`}>
                <ul>
                  {active.map((item: any) => (
                    <li
                      key={item.id}
                      onClick={() => handleActive(item.id)}
                      className={getBorder(item.style)}
                    ></li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={classes.line}>
              {active.map((item: any) => {
                return <div className={getLine(item)} key={item.id}></div>;
              })}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default withRouter(GetStart);
