import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainGlobal: {
      background: theme.custom.colors.white,
      fontFamily: 'Titillium Web',
      position: 'relative',
      zIndex: 1,

      [theme.breakpoints.down('xs')]: {
        paddingTop: 30,
      },
    },

    titleGlobal: {
      fontFamily: 'Titillium Web',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '48px',
      color: theme.custom.colors.textTitle,
      paddingTop: 100,

      [theme.breakpoints.down('lg')]: {
        fontSize: '32px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '48px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        textAlign: 'left',
        paddingTop: 60,
        lineHeight: '40px',
      },
    },

    btnBookNow: {
      background: theme.custom.colors.btnSignUp,
      fontFamily: 'Titillium Web',
      color: theme.custom.colors.white,
      fontSize: 14,
      border: '1px solid #7F63F4',
      boxSizing: 'border-box',
      borderRadius: '6px',
      width: '100%',
      height: 40,
      textTransform: 'none',

      '&:hover': {
        background: theme.custom.colors.btnSignUp,
        opacity: 0.8,
      },
    },

    boxText: {
      display: 'flex',
      justifyContent: 'center',

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    textdesc: {
      textAlign: 'center',
      fontSize: '20px',
      color: theme.custom.colors.textTitle,
      lineHeight: '30px',
      fontWeight: 400,
      maxWidth: 680,

      [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        textAlign: 'left',
      },
    },

    boxImg: {
      padding: '80px 0',
      '& img': {
        width: '100%',
      },
    },

    support: {
      borderRadius: 10,
      background: theme.custom.colors.bgrColor,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: 168,
      position: 'absolute',
      maxWidth: 1232,
      bottom: '-10.5%',
      boxShadow: '0px 4px 50px rgba(127, 99, 244, 0.15)',

      [theme.breakpoints.down('lg')]: {
        width: '95.5%',
        height: 116,
        bottom: '-7%',
      },

      [theme.breakpoints.between('1440', '1919')]: {
        padding: '0 40px',
      },

      [theme.breakpoints.up('lg')]: {
        width: 1232,
        maxWidth: 1232,
        height: 168,
        bottom: '-9%',
      },

      [theme.breakpoints.between('500', '619')]: {},

      [theme.breakpoints.down('sm')]: {
        width: '94.5%',
        maxWidth: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    boxTextMobile: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },

    titleMobile: {
      fontFamily: 'Titillium Web',
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: '24px',
      color: theme.custom.colors.textTitle,
      paddingTop: 30,
    },

    iconGlobal: {
      '& img': {
        width: 200,
        height: '100%',
      },

      '& .img-3': {
        marginTop: 14,
      },
      [theme.breakpoints.down('lg')]: {
        ' & img': {
          width: 168,
        },
      },

      [theme.breakpoints.between('1440', '1919')]: {
        ' & img': {
          width: 200,
        },
      },

      [theme.breakpoints.down('sm')]: {
        '& img': {
          width: 113,
        },
      },

      [theme.breakpoints.between('500', '619')]: {
        '& img': {
          width: '80%',
          marginLeft: 10,
        },
      },
    },
  };
});

export default useStyles;
