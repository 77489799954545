import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    tradingEntry: {
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      padding: 15,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
        width: '100%',
      },
    },
    styleLoading: {
      height: 412,
      maxHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.custom.colors.cardBackground,
    },

    title: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 18,
      lineHeight: '140%',
      color: theme.custom.colors.titleTrading,
      margin: 0,
      marginBottom: 15,
    },

    groupBtn: {
      display: 'grid',
      gridGap: 7,
      gridTemplateColumns: 'auto auto',
      marginTop: 10,
    },

    groupPrice: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '10px',
      margin: '20px 0',
    },

    btnTradingChange: {
      width: 32,
      height: 32,
      padding: 0,
      minWidth: 32,
      background: 'transparent',
      boxShadow: 'none',
      fontSize: 32,
      marginTop: 10,
      marginBottom: 10,
      textTransform: 'inherit',
      borderRadius: '50%',

      '&:hover': {
        background: 'transparent',
        boxShadow: 'none',
      },

      '& .icon-trading-change:before': {
        width: 35,
        height: 35,
        color: '#9BA1B1',
      },
    },

    btn: {
      fontFamily: 'Ubuntu-Bold',
      borderRadius: 6,
      fontSize: 18,
      lineHeight: '140%',
      color: '#FFFFFF',
      boxShadow: 'none',
      textTransform: 'inherit',
      background: theme.custom.colors.btnSell,

      '&:hover': {
        background: '#4CA250',
      },
    },

    btnSell: {
      background: theme.custom.colors.btnSell,
      '&:hover': {
        background: theme.custom.colors.red2,
      },
    },

    activeTab: {
      background: '#4CA250',
    },

    activeTabSell: {
      background: theme.custom.colors.red2,
    },

    listPercents: {
      display: 'grid',
      gridColumnGap: 25,
      gridTemplateColumns: 'auto auto auto auto',
      marginBottom: 15,
      marginTop: 15,
    },

    btnPercents: {
      background: theme.custom.colors.bgBluGrey,
      borderRadius: 4,
      height: 25,
      minWidth: 'auto',
      padding: 3,
      fontSize: 12,
      color: '#FFFFFF',
      lineHeight: '14px',
      outline: 'none',
      boxShadow: 'none',
      paddingTop: 5,
      textTransform: 'inherit',

      '&:hover': {
        background: theme.custom.colors.bgBluGrey,
        boxShadow: 'none',
      },
    },

    activePercent: {
      background: theme.custom.colors.cornflowerBlue,

      '&:hover': {
        background: theme.custom.colors.cornflowerBlue,
      },
    },

    btnTrade: {
      fontFamily: 'Ubuntu-Bold',
      background: theme.custom.colors.btnEnable,
      borderRadius: 4,
      minHeight: 40,
      minWidth: 'auto',
      fontSize: 14,
      color: theme.custom.colors.btnTitle,
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',
      '&:hover': {
        background: theme.custom.colors.btnHover,
        boxShadow: 'none',
        cursor: 'pointer',
      },

      [theme.breakpoints.down('xs')]: {
        minHeight: 50,
      },
    },

    btnDisable: {
      color: `${theme.custom.colors.btnTitle} !important`,
      backgroundColor: `${theme.custom.colors.bgDisable} !important`,
      '&:hover': {
        pointerEvents: 'auto',
        cursor: 'no-drop !important',
      },
    },
    dropdown: {
      flex: '40%',
      width: '100px',
    },

    selectOrderType: {
      flex: '40%',
      width: '100px',

      '& .MuiSelect-select.MuiSelect-select': {
        width: '100%',
        '& span': {
          fontSize: '10px',
          marginLeft: '-10px',
          lineHeight: '12px',
        },
      },
      '& .MuiSelect-icon': {
        lineHeight: '12px',
        right: '5px !important',
        '&:before': {
          fontSize: '10px',
        },
      },
    },

    selectInput: {
      height: 44,
      borderColor: '#9BA1B1',
    },

    styleSelect: {
      maxHeight: 300,
      overflowY: 'auto',
    },

    btnPrice: {
      background: 'none',
      border: `1px solid #9BA1B1`,
      textTransform: 'inherit',
      color: '#EBECF0',
      height: 42,
      flex: '60%',
      '&:hover': {
        background: '#7F63F4',
      },
    },

    '& .MuiMenuItem-root': {
      fontSize: '10px !important',
    },
    colorEror: {
      color: '#ea3943',
    },

    inputAmountContainer: {
      width: 'calc(100% - 110px)',
      display: 'flex',
      flexDirection: 'row',
      border: `1.3px solid ${theme.custom.colors.borderTradingWatch}`,
      borderRadius: 8,
      justifyContent: 'center',
      justifyItems: 'center',
    },

    inputAmount: {
      width: '100%',
      border: 'none',
      background: 'transparent',
      outline: 'none',
      height: 42,
      fontSize: 14,
      lineHeight: '140%',
      padding: 5,
      color: theme.custom.colors.titleTrading,

      '&::-webkit-input-placeholder': {
        color: theme.custom.colors.titleTrading,
      },

      '&:-ms-input-placeholder': {
        color: theme.custom.colors.titleTrading,
      },

      '&::placeholder': {
        color: theme.custom.colors.titleTrading,
      },
    },

    borderError: {
      border: '1px solid #ea3943',
    },

    overlay: {
      position: 'absolute',
      width: '100%',
      height: '65%',
      background: `${theme.custom.colors.bgrOverLay}`,
      zIndex: 1,
      color: theme.custom.colors.btnTitle,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
      [theme.breakpoints.between('1800', '1950')]: {
        width: 345,
        height: '100%',
      },
      [theme.breakpoints.between('1160', '1441')]: {
        width: 280,
        height: '100%',
      },
      [theme.breakpoints.between('1000', '1155')]: {
        width: 275,
      },
      [theme.breakpoints.between('990', '1025')]: {
        width: 245,
      },
      [theme.breakpoints.down('sm')]: {
        width: 355,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '100%',
      },
    },

    content: {
      width: 200,
      height: 114,
      textAlign: 'center',
      margin: 'auto',
      marginTop: 167,
    },
    lable: {
      height: '54px',
      textAlign: 'center',
      fontSize: '18px',
      fontFamily: 'Titillium-Web',
      fontWeight: 'bold',
      lineHeight: '27px',
    },

    btnSignUp: {
      background: theme.custom.colors.cornflowerBlue,
      borderRadius: 6,
      height: 40,
      width: 126,
      marginTop: 20,
      fontFamily: 'Titillium-Web',
      fontSize: 12,
      color: theme.custom.colors.btnTitle,
      boxShadow: 'none',
      fontWeight: 'bold',
      textTransform: 'none',

      '&:hover': {
        background: theme.custom.colors.purple1,
      },
    },
  };
});

export default useStyles;
