import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
    return {
        mainModalConfirm: {
            "& .modal-container": {
                width: '335px !important',
                padding: `20px 0`,
                "& .body-modal-container": {
                    padding: "0 20px",
                    maxHeight: '100%',
                    minHeight: '100%',
                },
            },
        },

        title: {
            fontSize: 18,
            color: theme.custom.colors.titleTrading,
            marginBottom: 10,
            fontFamily: 'Ubuntu-Regular',
            fontWeight: 700,
        },

        contentTitle: {
            width: '50%',
            fontSize: 14,
            fontFamily: 'Ubuntu-Regular'
        },

        subTitle: {
            color: theme.custom.colors.colorWarning,
            fontSize: 12,
            marginBottom: 23,
            fontFamily: 'Ubuntu-Regular',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },

        btnCancel: {
            width: "126px",
            height: "36px",
            borderRadius: "6px",
            color: theme.custom.colors.titleTrading,
            textTransform: "none",
            marginRight: 20,
            fontSize: 12,
            fontFamily: 'Ubuntu-Regular',
            fontWeight: 'bold',
            border: `1px solid ${theme.custom.colors.titleTrading}`,
            "&:hover": {
                background: 'none',
                border: `1px solid ${theme.custom.colors.cornflowerBlue}`,
                color: theme.custom.colors.cornflowerBlue
            },
        },

        confirm: {
            width: "126px",
            height: "36px",
            borderRadius: "6px",
            color: theme.custom.colors.white1,
            background: theme.custom.colors.cornflowerBlue,
            marginRight: 15,
            textTransform: "none",
            fontSize: 12,
            fontFamily: 'Ubuntu-Regular',
            fontWeight: 'bold',
            "&:hover": {
                background: theme.custom.colors.hoverBtn,
            },
        },

        display: {
            display: 'flex',
            marginBottom: 8,
            color: theme.custom.colors.titleTrading,
            fontFamily: 'Ubuntu-Regular',

        },

        renderSpan: {
            fontSize: 18,
            fontWeight: 'bold'
        }
    };
});

export default useStyles;