import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import {
  FormControl,
  FormLabel,
  InputBaseProps,
  FormHelperText,
  FormLabelProps,
} from '@material-ui/core';
import useStyles from './styles';
import { useController, UseControllerProps } from 'react-hook-form';
import { convertCountryCodeToLocale } from 'src/utils/validation';

const validator = require('validator');
export interface AppPhoneProps<T> extends InputBaseProps {
  label?: string;
  labelProps?: FormLabelProps;
  controller: UseControllerProps<T>;
  helperText?: string | React.ReactNode;
  defaultCountry?: string;
  classNames?: string;
  textPlaceholder?: string;
  inputClass?: any;
}

const AppPhone = <T extends Record<string, any>>({
  label,
  labelProps,
  controller,
  className,
  classNames,
  fullWidth,
  required,
  helperText,
  error,
  defaultCountry,
  classes,
  inputProps,
  textPlaceholder,
  inputClass,
}: AppPhoneProps<T>) => {
  const {
    field: { name, onChange, value },
  } = useController<T>({
    ...controller,
    rules: {
      ...controller.rules,
      validate: {
        isValid: (val: any) => {
          if (
            val &&
            !validator.isMobilePhone(
              '+'.concat(val),
              convertCountryCodeToLocale(country)
            )
          ) {
            return 'Please enter valid phone number';
          }
        },
      },
    },
  });
  const [country, setCountry] = useState(defaultCountry);

  const styles = useStyles();
  const [classLabel, setClassLabel] = useState(false);
  const [isDisplay, setDisplay] = useState(!!defaultCountry);

  return (
    <FormControl
      fullWidth={fullWidth}
      error={error}
      required={required}
      className={label ? `${styles.formControl} ${classNames}` : className}
    >
      {label && (
        <FormLabel
          {...labelProps}
          className={`
          ${classLabel && 'label-focused'} 
          ${error && 'label-error'} 
        `}
        >
          {label}
        </FormLabel>
      )}

      <div className={styles.groupPhone}>
        <PhoneInput
          value={value}
          country={defaultCountry}
          inputProps={{
            name: { name },
          }}
          onChange={(val, country: any) => {
            onChange(val);
            setCountry(country?.countryCode);
            setClassLabel(false);
          }}
          onFocus={() => {
            setClassLabel(true);
            setDisplay(false);
          }}
          onBlur={() => {
            setClassLabel(false);
          }}
          containerClass={styles.containerPhone}
          inputClass={`${inputClass ? inputClass : styles.inputPhone} ${
            error ? styles.styleBOrder : ''
          } ${classLabel && !error && !inputClass ? styles.focused : ''}`}
          buttonClass={styles.buttonPhone}
          dropdownClass={styles.dropdownPhone}
          placeholder={textPlaceholder}
        />
        <span
          className={`${isDisplay ? styles.content : ''}`}
          onClick={() => {
            setDisplay(false);
          }}
        >
          {isDisplay ? textPlaceholder : ''}
        </span>
      </div>
      {helperText && (
        <FormHelperText className={styles.helperText} component="div">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AppPhone;
