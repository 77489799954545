import { useEffect, useRef, useState } from 'react';
import AppButton from 'src/components/Base/AppButton';
import AddIndividual from './AddIndividual';
import useStyles from './styles';

const Individuals = ({
  formData,
  lastErrors,
  isLoading,
  setStep,
  saveData,
  handleSubmitKYC,
}: {
  formData: any;
  lastErrors: any;
  isLoading: boolean;
  setStep: (step: number) => void;
  saveData: (data: any, errors?: any) => void;
  handleSubmitKYC: (data: any) => void;
}) => {
  const classes = useStyles();
  const [arrIndividual, setArrIndividual] = useState(
    formData.individuals || [
      {
        isValid: false,
        individual1: '',
      },
    ]
  );

  const arrIndividualRef = useRef(arrIndividual);

  const [inValidIndividual, setInValidIndividual] = useState(true);
  const onSubmitForm = () => {
    const data = arrIndividual.map((item: any, index: number) => {
      let obj: any = {};
      obj[`individual${index + 1}`] = item[`individual${index + 1}`];
      return obj;
    });

    handleSubmitKYC({
      individuals: { ...data },
    });
  };

  const addForm = () => {
    const numberContact = arrIndividual?.length + 1;
    let obj: any = {};
    obj[`individual${numberContact}`] = '';

    setArrIndividual([...arrIndividual, obj]);
  };

  useEffect(() => {
    const inValid = arrIndividual.findIndex(
      (item: any) => item.isValid === false
    );

    setInValidIndividual(inValid !== -1);
    arrIndividualRef.current = arrIndividual;
  }, [arrIndividual]);

  useEffect(() => {
    return () => {
      const isValid = arrIndividualRef.current.findIndex(
        (item: any) => item.isValid === false
      )!;

      saveData(
        {
          individuals: arrIndividualRef.current,
        },
        {
          individuals: !isValid,
        }
      );
    };
  }, [saveData]);

  return (
    <div className={classes.stepContainer}>
      <div className={classes.title}>
        <div>Information on individuals</div>
        <span className="icon-info"></span>
      </div>
      {arrIndividual.map((item: any, index: number) => {
        return (
          <AddIndividual
            key={index}
            num={index + 1}
            lastErrors={lastErrors.individuals}
            data={item[`individual${index + 1}`]}
            setAddIndividual={(val: any) => {
              arrIndividual[index] = val;
              setArrIndividual([...arrIndividual]);
            }}
            isDelete={
              arrIndividual?.length === index + 1 && arrIndividual?.length > 1
            }
            handleDelete={() => {
              arrIndividual.pop();
              setArrIndividual([...arrIndividual]);
            }}
          />
        );
      })}
      <div
        className={classes.btnLink}
        onClick={() => {
          if (arrIndividual?.length < 4) {
            addForm();
          }
        }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && arrIndividual?.length < 4) {
            addForm();
          }
        }}
      >
        Add individual
      </div>
      <div className={classes.btnNext}>
        <AppButton
          fullWidth={true}
          isDisable={false}
          onClick={() => setStep(3)}
        >
          Back
        </AppButton>
        <AppButton
          type="submit"
          fullWidth={true}
          isDisable={
            inValidIndividual ||
            lastErrors?.general ||
            lastErrors?.organization ||
            lastErrors?.documents
          }
          onClick={onSubmitForm}
          isLoading={isLoading}
        >
          Submit
        </AppButton>
      </div>
    </div>
  );
};

export default Individuals;
