import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    rootSecurity: {
      flexGrow: 1,
    },
   
    paper: {
      padding: 20,
      color: theme.custom.colors.boldGray,
      borderRadius: 8,
      background: theme.custom.colors.cardBackground,
      [theme.breakpoints.down("xs")]: {
        padding: "20px 10px",
      },
    },
    body: {
      display: "flex",
      fontFamily: "Ubuntu",
      color: theme.custom.colors.boldGray,
    },
    icon: {
      fontSize: 36,
      marginTop: -5,
      marginRight: 5,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    title: {
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: 10,
      whiteSpace:'nowrap'
    },
    content: {
      fontSize: 14,
      height: 60,
      paddingRight: 20,

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: 60,
      },

      [theme.breakpoints.down("md")]: {
         height: 75,
      },

      [theme.breakpoints.down("sm")]: {
        height: 50,
        paddingRight: 10,
        marginBottom: 10
      },
      color: theme.custom.colors.textContentCard,
    },
    btn: {
      display: "flex",
      flexWrap: "nowrap",


    },
    btnContent: {
      background: theme.custom.colors.cornflowerBlue,
      borderRadius: 6,
      height: 36,
      marginTop: 20,
      fontFamily: "Ubuntu-Bold",
      fontSize: 12,
      color: theme.custom.colors.white1,
      boxShadow: "none",
      textTransform: "none",
      width: 126,
      "&:hover": {
        background: theme.custom.colors.btnHover,
      },
      [theme.breakpoints.only("md")]: {
        width: "45%",
      },
    },
    btnViewList: {
      width: 88,
      background: theme.custom.colors.lightBlue,
      "&:hover": {
        background: theme.custom.colors.lightBlue,
      },
    },
    btnAdd: {
      width: 88,
      marginLeft: 20,
      "&:hover": {
        background: theme.custom.colors.btnHover,
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 14,
      },
    },
  };
});

export default useStyles;
