/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import _ from 'lodash';

import assetConfig from 'src/commons/configs/assetsConfig.json';
import { DEFAULT_LOGO } from 'src/commons/constants';
import { displayNumb } from 'src/utils/number';
import { marketService } from 'src/services/MarketService';
import { getPercent } from 'src/utils/number';
import LoadingOverlay from 'src/routing/ReportsPage/LoadingOverlay';
import TableStyle from 'src/styles/TableStyle';

import useStyles from './styles';
import AppBox from 'src/components/Base/AppBox';

const ComponentWatchList = (props: any) => {
  const styles = useStyles();
  const tableStyle = TableStyle();
  const assetLogo = assetConfig as any;
  const theme = useTheme();
  const matchUpXl = useMediaQuery(theme.breakpoints.up('sm'));
  const [loading, setLoading] = useState(false);
  const [markets, setMarkets] = useState([]);
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const { allMarkets, hanldeChangeSymbolTrade } = props;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Asset',
      sortable: false,
      width: matchUpXl ? 213 : 110,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <div className={styles.detailToken}>
            <img
              className={styles.logo}
              src={(params.getValue(params.id, 'logo') as string) || ''}
              alt=""
            />
            <div className={styles.detailPair}>
              <span className={styles.pairLabel}>
                {' '}
                {params.getValue(params.id, 'pair') || ''}
              </span>
              <span className={styles.pairPercent}>
                {params.getValue(params.id, 'percent') || ''}%
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      sortable: false,
      width: matchUpXl ? 213 : 110,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <div className={styles.priceToken}>
          <span className={styles.pairLabel}>
            {params.getValue(params.id, 'priceNumber') || ''}
          </span>
          <span className={styles.pairPercent}>
            {params.getValue(params.id, 'priceUsd') || ''}
          </span>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: ' ',
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Button
            onClick={() =>
              hanldeChangeSymbolTrade(params.getValue(params.id, 'pair'))
            }
            variant="contained"
            className={styles.btnTradingWatch}
          >
            Trade
          </Button>
          <Button
            variant="contained"
            className={`${styles.btnTradingWatch} ${styles.btnIconTrade}`}
          >
            <span className="icon-trading"></span>
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (allMarkets && allMarkets.length > 0) {
      if (isLogged) {
        getPriceChange(allMarkets);
      }
    }
  }, [allMarkets]);

  const getPriceChange = async (allMarkets: any) => {
    setLoading(true);

    // get all price change
    const prices = await marketService.getPriceChangeMarket(allMarkets);

    let m = allMarkets.map((item: any, indx: number) => {
      // find item of price change
      let price: any = {};
      if (prices?.data && _.isArray(prices?.data)) {
        price = prices.data.find((x: any) => x.symbol === item);
      }

      const current_price = price?.current_price ?? 0;
      const old_price = price?.old_price ?? 0;

      return {
        logo: assetLogo[`${item.split('/')[0]}`]?.icon || DEFAULT_LOGO,
        pair: item,
        priceNumber: displayNumb(price?.current_price?.toString()) ?? '0',
        id: indx,
        percent: getPercent(old_price, current_price),
      };
    });

    setMarkets(m);
    setLoading(false);
  };

  return (
    <div className={styles.componentWatchList}>
      <h2 className={styles.titleTable}>Watch List</h2>
      {!isLogged && <div className={styles.overlay}></div>}
      <div
        className={`${tableStyle.customerDataGrid} ${styles.styleGrid}`}
        style={{ height: 426 }}
      >
        <DataGrid
          autoHeight
          loading={loading}
          rows={markets}
          columns={columns}
          pageSize={5}
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={46}
          rowHeight={64}
          hideFooter={markets?.length <= 5 ? true : false}
          components={{
            NoRowsOverlay: () => (
              <div className={tableStyle.rowEmptydata}>
                <AppBox
                  loading={loading}
                  hasData={markets?.length > 0}
                ></AppBox>
              </div>
            ),
            LoadingOverlay: LoadingOverlay,
          }}
        />
      </div>
    </div>
  );
};

export default ComponentWatchList;
