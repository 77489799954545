export const BarStyles = {
    BARS: '0',
    CANDLES: '1',
    HOLLOW_CANDLES: '9',
    HEIKIN_ASHI: '8',
    LINE: '2',
    AREA: '3',
    RENKO: '4',
    LINE_BREAK: '7',
    KAGI: '5',
    POINT_AND_FIGURE: '6'
};

export const IntervalTypes = {
    D: 'D',
    W: 'W'
};

export const RangeTypes = {
    YTD: 'ytd',
    ALL: 'all'
};

export const Themes = {
    LIGHT: 'Light',
    DARK: 'Dark'
};

export const SupportedResolution = ["1m", "5m", "15m", "30m", "1h", "4h", "8h", "1D", '1W']

export const resolutions = [
    { key: "1m", resolution: "1", text: '1m' },
    { key: "5m", resolution: "5", text: "5m" },
    { key: "15m", resolution: "15", text: "15m" },
    { key: "30m", resolution: "30", text: "30m" },
    { key: "1h", resolution: "60", text: "1h" },
    { key: "4h", resolution: "240", text: "4h" },
    { key: "8h", resolution: "480", text: "8h" },
    { key: "1D", resolution: "1D", text: "1D" },
    { key: "1W", resolution: "1W", text: "1W" }
];