import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainConfiruge: {
      display: 'flex',
      gridGap: '22px',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    boxAPI: {
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    box2: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    titleBox: {
      color: theme.custom.colors.titleTrading,
      fontFamily: 'Ubuntu-Regular',
      fontWeight: 700,
      marginTop: 0,
      marginBottom: 10,
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      },
    },

    titleDoc: {
      fontSize: 20,
    },

    textCon: {
      marginTop: 0,
      color: theme.custom.colors.white2,
      fontSize: '16px',
      lineHeight: '18px',
      fontFamily: 'Ubuntu-Regular',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,

      },
    },

    boxText: {
      flex: 1,
      paddingBottom: 20,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 10,
      },
    },
    btnTitle: {
      fontSize: 12,
    },
    linkButton: {
      textDecoration: 'none',
      width: 130,
    },
  };
});

export default useStyles;
