import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    button: {
      width: "82px",
      height: "36px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      textDecoration: "none",
      cursor: 'pointer',
      color: theme?.custom?.colors.white1,
      borderRadius: "6px",
      border: "none",
      marginTop: 10,

      fontFamily: "Ubuntu-Bold",
      fontSize: "12px",
      lineHeight: "14px",
      textAlign: "center",
      textTransform: "none",

      [theme.breakpoints.down("xs")]: {
        marginLeft: "32px",
        padding: '0 5px',
        marginTop: 9,
      },

      [theme.breakpoints.down("lg")]: {
        marginTop: 5
      },

      [theme.breakpoints.only("md")]: {
        marginTop: 12
      },

      

    },
    btnActive: {
      background: theme?.custom?.colors.btnEnable,
      height: "36px",
      padding: 11,

      "&:hover": {
        background: theme.custom.colors.btnHover,
      },
    },
    btnDisable: {
      background: theme?.custom?.colors.bgrBtnDisable,
      cursor: 'unset',
      color: theme.custom.colors.btnTitle,

      "&:hover": {
        background: theme?.custom?.colors.bgrBtnDisable,
      },
    },

    container: {
      width: "998px",
      height: "283px",
      borderRadius: "8px",
      padding: "30px",
      position: "relative",

      backgroundColor: theme?.custom?.colors.cardBackground,
      color: theme?.custom?.colors.white1,

      [theme.breakpoints.between("991", "1100")]: {
        width: "100%",
        height: "305px",
        padding: "15px 20px 20px 20px",
        fontSize: "14px"
      },


      [theme.breakpoints.down("md")]: {
        width: "640px",
        height: "263px",
        paddingTop: "20px",
        paddingLeft: "20px"
      },

      [theme.breakpoints.down("sm")]: {
        height: "263px",
        width: "100%",
        paddingTop: "20px",
        paddingLeft: "20px"
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "320px",
        padding: "15px 20px 20px 20px",
        fontSize: "14px"
      },

      [theme.breakpoints.only("lg")]: {
        paddingTop: "20px",
        paddingLeft: "20px",
        height: "263px",
        width: "797px"
      }
    },
    content: {
      width: "100%",
      position: "absolute",
      zIndex: 2,
      fontStyle: "normal",
      fontFamily: 'Ubuntu-Regular'
    },
    welcomeTitle: {
      height: "53px",
      display: "grid",

    },
    title: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: "20px",
      lineHeight: "23px",
      color: theme.custom.colors.titleTrading,

    },
    note: {
      fontWeight: "normal",
      fontSize: "16px",
      marginTop: "8px",
      color: theme.custom.colors.titleTrading,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        fontSize: "14px",
      },
    },
    timeline: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",

      [theme.breakpoints.down("xs")]: {
        flexDirection: "row",
        marginTop: 8
      },

      [theme.breakpoints.only("sm")]: {
        width: "728px",
      },

      [theme.breakpoints.only("md")]: {
        width: "480px",
        marginTop: "10px",
      },

      [theme.breakpoints.only("lg")]: {
        width: "520px"
      }
    },
    horizontal: {
      display: "flex",
      flexDirection: "row",
      justifyItems: "center",
      alignItems: "center",

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },

      [theme.breakpoints.only("sm")]: {
        width: "480px",
      },

      [theme.breakpoints.only("md")]: {
        width: "100%"
      },

      [theme.breakpoints.only("lg")]: {
        width: "405px"
      },

    },

    dottedHorizontal: {
      width: "245px",
      border: "1px dashed #606983",

      [theme.breakpoints.between("1500", "1670")]: {
        width: 171,
      },
      [theme.breakpoints.between("900", "1024")]: {
        width: 140,
      },
      [theme.breakpoints.down("xs")]: {
        width: "0px",
        height: 56,
        border: "1px dashed #7F63F4",
      },

      [theme.breakpoints.only("sm")]: {
        width: "142px",
      },

      [theme.breakpoints.only("md")]: {
        width: "142px"
      },

      [theme.breakpoints.only("lg")]: {
        width: "145px"
      },

      [theme.breakpoints.between("890", "1100")]: {
        width: "25%",
      },
    },
    dotted1Horizontal: {
      width: "255px",
      height: "0px",
      border: "1px dashed #606983",

      [theme.breakpoints.between("1500", "1670")]: {
        width: 171,
      },

      [theme.breakpoints.down("xs")]: {
        width: "0px",
        height: "48px",
        border: "1px dashed #606983",
      },

      [theme.breakpoints.only("sm")]: {
        width: "143px",
      },

      [theme.breakpoints.only("md")]: {
        width: "143px"
      },

      [theme.breakpoints.only("lg")]: {
        width: "180px"
      },

      [theme.breakpoints.between("890", "1100")]: {
        width: "25%",
      },
    },

    overlay: {
      position: 'absolute',
      height: "100%",
      background: theme.custom.colors.bgrOverLay,
      zIndex: 3,
      opacity: .9,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      width: "100%",
      borderRadius: 8,
      top: 0,
      left: 0,

      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
      }
    },


    timelineStep: {
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      border: `2px solid ${theme.custom.colors.white2}`,

      display: "flex",
      justifyContent: "center",
      justifyItems: "center",
      alignItems: "center",

      fontWeight: "bold",
      lineHeight: "17px",
      color: theme.custom.colors.titleTrading,
      margin: "5px 0px",

      [theme.breakpoints.down("xs")]: {
        margin: "0px",
      },
    },

    btnIcon: {
      width: 25,
      height: 25,
      backgroundImage: "url(/images/1.svg)",
      backgroundRepeat: 'none'
    },

    timelineIcon: {
      width: "25px",
      height: "25px",

      flex: "none",
      order: 0,
      flexGrow: 0,
      margin: "5px 0px",

      [theme.breakpoints.down("xs")]: {
        margin: 0,
      },
    },

    timelineSteps: {
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },

      [theme.breakpoints.only("sm")]: {
        width: "480px",
      },

      [theme.breakpoints.only("md")]: {
        width: "480px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "520px"
      }
    },

    Register: {
      width: "210px",
      height: "93px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "0px",
      color: theme.custom.colors.titleTrading,

      [theme.breakpoints.between("1500", "1670")]: {
        width: 195,
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "73px",
        marginLeft: "10px",
        flexDirection: "row",
      },
      [theme.breakpoints.only("sm")]: {
        width: "145px"
      },

      [theme.breakpoints.only("md")]: {
        width: "145px"
      },

      [theme.breakpoints.only("lg")]: {
        width: "170px"
      },

      [theme.breakpoints.between("890", "1100")]: {
        width: "25%",
      },

    },
    RegisterStep: {
      [theme.breakpoints.down("xs")]: {
        width: 150,
      },
    },
    KYC: {
      width: "220px",
      marginLeft: "60px",

      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",

      [theme.breakpoints.between("1500", "1670")]: {
        marginLeft: "0px",
        width: 194,
      },

      [theme.breakpoints.down("xs")]: {
        flexDirection: "row",
        marginLeft: "10px",
        width: "100%",
        height: "73px",
        marginTop: 10
      },

      [theme.breakpoints.only("sm")]: {
        width: "145px",
        marginLeft: "25px",
      },

      [theme.breakpoints.only("md")]: {
        width: "145px",
        marginLeft: "25px",
      },

      [theme.breakpoints.only("lg")]: {
        width: "145px",
        marginLeft: "0px",
      },

      [theme.breakpoints.between("890", "1100")]: {
        width: "25%",
      },
    },
    Deposit: {
      width: "210px",
      height: "142px",
      marginLeft: "60px",

      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",

      [theme.breakpoints.between("1500", "1670")]: {
        marginLeft: "0px",
        width: 180,
      },

      [theme.breakpoints.down("xs")]: {
        flexDirection: "row",
        marginLeft: "10px",
        width: "100%",
        height: "73px",
      },
      [theme.breakpoints.only("sm")]: {
        width: "145px",
        marginLeft: "23px",
      },

      [theme.breakpoints.only("md")]: {
        width: "145px",
        marginLeft: "23px",
      },

      [theme.breakpoints.only("lg")]: {
        width: "145px"
      },
      [theme.breakpoints.between("890", "1100")]: {
        width: "25%",
      },
    },

    titleStep: {
      height: "18px",
      lineHeight: "18px",

      fontWeight: 500,
      fontSize: "16px",
      color: theme.custom.colors.titleTrading,

      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "14px",
      },

      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
      },

      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
      }
    },
    contentStep: {
      marginTop: "5px",
      marginBottom: "5px",
      color: theme.custom.colors.colorText,
      fontSize: "14px",
      height: 50,

      [theme.breakpoints.down("xs")]: {
       
      },
    
      [theme.breakpoints.down("md")]: {
       marginBottom: 10,
      },

      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      }
    },
    actionStep: {

    },
    groupLine1: {
      width: "100%",
      height: "283px",
      zIndex: 1,
      position: "absolute",
      // left: "308px",
      right: 0,
      top: 0,
      backgroundImage: "url(/images/welcome-extra.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",

      [theme.breakpoints.only("xs")]: {
        display: "none"
      },

      [theme.breakpoints.only("sm")]: {
        height: "263px",
        right: -3,
        backgroundImage: "url(/images/welcome-small.png)",
      },

      [theme.breakpoints.only("md")]: {
        height: "263px",
        left: 0,
        background: "url(/images/welcome-medium.png)",
      },

      [theme.breakpoints.between("991", "1100")]: {
        height: "263px",
        background: "url(/images/welcome-medium.png)",
      },

      [theme.breakpoints.between('1100', '1500')]: {
        height: "263px",
      }
    },
    KYCStep: {
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.down("xs")]: {
        width: 150,
      },

    },
    DepositStep: {
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.down("xs")]: {
        width: 150,
      },

    },
    dottedActive: {
      border: "1px dashed #7F63F4",
    },
    dottedNotActive: {
      border: "1px dashed #606983",
    }
  };
});

export default useStyles;