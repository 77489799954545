import { message } from '../constants/message';
import { AnyAction } from 'redux';

export type StateType = {
  data: string;
  modal: ModalMessageType;
};

export type ModalMessageType = {
  show?: boolean;
  type?: 'ERROR' | 'SUCCESS';
  msg?: string;
  onOk?: any;
  onCancel?: any;
  btnOk?: {
    label: string;
    show: boolean;
  };
  btnCancel?: {
    label: string;
    show: boolean;
  };
};

const initialState: StateType = {
  data: '',
  modal: {
    show: false,
    type: 'ERROR',
    msg: '',
    btnOk: {
      label: 'Ok',
      show: true,
    },
    btnCancel: {
      label: 'Cancel',
      show: false,
    },
  },
};

export const messageReducer = (
  state: StateType = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case message.PUSH_MESSAGE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case message.TOGGLE_MODAL_MESSAGE: {
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
