import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
    return {
        spinner: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginRight: "5px"
        },
        groupArrow: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "5px",
            lineHeight: '15px',
            "& img": {
                width: 10,
                "&:hover": {
                    cursor: 'pointer'
                }
            }
        },
    };
});

export default useStyles;
