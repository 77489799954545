
import * as Tv from "public/charting_library/charting_library.min";

export type SearchSymbolsParams = {
    userInput: string;
    exchange: string;
    symbolType: string;
};

export type GetBarsParams = {
    symbolInfo: Tv.LibrarySymbolInfo;
    resolution: string;
    from: number;
    to: number;
    firstDataRequest: boolean;
};

export type GetMarksParams = {
    symbolInfo: Tv.LibrarySymbolInfo;
    startDate: number;
    endDate: number;
    resolution: string;
};

export type GetTimescaleMarks = GetMarksParams;
export interface Options {
    fetchConfiguration?: () => Promise<Tv.DatafeedConfiguration>;
    fetchSearchSymbols?: (params: SearchSymbolsParams) => Promise<Array<Tv.SearchSymbolResultItem>>;
    fetchResolveSymbol?: (symbolName: string) => Promise<Tv.LibrarySymbolInfo>;
    getBars?: (params: GetBarsParams) => Promise<{ bars: Tv.Bar[]; meta: Tv.HistoryMetadata }>;
    calculateHistoryDepth?: (resolution: string, resolutionBack: Tv.ResolutionBackValues, intervalBack: number) => Tv.HistoryDepth | undefined;
    getMarks?: (params: GetMarksParams) => Promise<Array<Tv.Mark>>;
    getTimescaleMarks?: (params: GetTimescaleMarks) => Promise<Array<Tv.TimescaleMark>>;
    getServerTime?: () => Promise<number>;
}
export interface DataSubscriber {
    symbolInfo: Tv.LibrarySymbolInfo;
    resolution: string;
    lastBarTime: number | null;
    listener: Tv.SubscribeBarsCallback;
}

export interface DataSubscribers {
    [guid: string]: DataSubscriber;
}

export class DataFeed implements Tv.IExternalDatafeed, Tv.IDatafeedChartApi {
    private options: Options;
    private configuration: Partial<Tv.DatafeedConfiguration> = {};
    private subscribers: DataSubscribers = {};
    private requesting = false;

    constructor(options: Options = {}) {
        this.options = options;
    }

    public onReady(callback: Tv.OnReadyCallback): void {
        new Promise((resolve) => {
            resolve(void 0);
        }).then(() => {
            const { fetchConfiguration } = this.options;
            this.configuration = this.defaultConfiguration();
            if (!fetchConfiguration) {
                callback(this.configuration);
                return;
            }
            fetchConfiguration().then((configuration) => {
                this.configuration = Object.assign(this.configuration, configuration);
                callback(this.configuration);
            });
        });
    }

    public searchSymbols(
        userInput: string,
        exchange: string,
        symbolType: string,
        onResult: Tv.SearchSymbolsCallback
    ) {
        const { fetchSearchSymbols } = this.options;
        if (!fetchSearchSymbols) {
            return;
        }
        fetchSearchSymbols({ userInput, exchange, symbolType })
            .then(onResult)
            .catch(() => {
                onResult([]);
            });
    }

    public resolveSymbol(
        symbolName: string,
        onSymbolResolvedCallback: Tv.ResolveCallback,
        onResolveErrorCallback: Tv.ErrorCallback
    ) {
        const { fetchResolveSymbol } = this.options;
        if (!fetchResolveSymbol) {
            return;
        }
        fetchResolveSymbol(symbolName)
            .then(onSymbolResolvedCallback)
            .catch(() => {
                onResolveErrorCallback("Error fetchResolveSymbol");
            });
    }

    public getBars(
        symbolInfo: Tv.LibrarySymbolInfo,
        resolution: string,
        from: number,
        to: number,
        onHistoryCallback: Tv.HistoryCallback,
        onErrorCallback: Tv.ErrorCallback,
        firstDataRequest: boolean
    ) {
        const { getBars } = this.options;
        if (!getBars) {
            return;
        }
        if (this.requesting) {
            return;
        }
        this.requesting = true;
        getBars({ symbolInfo, resolution, from, to, firstDataRequest })
            .then((data) => {
                this.requesting = false;
                onHistoryCallback(data.bars, data.meta);
            })
            .catch((err) => {
                this.requesting = false;
                onErrorCallback("Error getBars");
            });
    }

    public subscribeBars(
        symbolInfo: Tv.LibrarySymbolInfo,
        resolution: string,
        onRealtimeCallback: Tv.SubscribeBarsCallback,
        subscriberUID: string,
        onResetCacheNeededCallback: () => void
    ) {
        if (this.subscribers[subscriberUID]) {
            return;
        }
        this.subscribers[subscriberUID] = {
            lastBarTime: null,
            listener: onRealtimeCallback,
            resolution: resolution,
            symbolInfo: symbolInfo,
        };
    }

    public unsubscribeBars(subscriberUID: string) {
        if (!this.subscribers[subscriberUID]) {
            return;
        }
        delete this.subscribers[subscriberUID];
    }

    public updateKLine(bar: Tv.Bar) {
        for (const listenerGuid in this.subscribers) {
            const subscriptionRecord = this.subscribers[listenerGuid];
            if (
                subscriptionRecord.lastBarTime !== null &&
                bar.time < subscriptionRecord.lastBarTime
            ) {
                continue;
            }
            subscriptionRecord.lastBarTime = bar.time;
            subscriptionRecord.listener(bar);
        }
    }

    public calculateHistoryDepth(
        resolution: string,
        resolutionBack: Tv.ResolutionBackValues,
        intervalBack: number
    ) {
        const { calculateHistoryDepth } = this.options;
        if (!calculateHistoryDepth) {
            return;
        }
        return calculateHistoryDepth(resolution, resolutionBack, intervalBack);
    }

    public getMarks(
        symbolInfo: Tv.LibrarySymbolInfo,
        startDate: number,
        endDate: number,
        onDataCallback: Tv.GetMarksCallback<Tv.Mark>,
        resolution: string
    ) {
        const { getMarks } = this.options;
        if (!getMarks) {
            return;
        }
        getMarks({ symbolInfo, startDate, endDate, resolution }).then(
            onDataCallback
        );
    }

    public getTimescaleMarks(
        symbolInfo: Tv.LibrarySymbolInfo,
        startDate: number,
        endDate: number,
        onDataCallback: Tv.GetMarksCallback<Tv.TimescaleMark>,
        resolution: string
    ) {
        const { getTimescaleMarks } = this.options;
        const { supports_timescale_marks } = this.configuration;
        if (!getTimescaleMarks || !supports_timescale_marks) {
            return;
        }
        getTimescaleMarks({ symbolInfo, startDate, endDate, resolution }).then(
            onDataCallback
        );
    }

    public getServerTime(callback: Tv.ServerTimeCallback) {
        const { getServerTime } = this.options;
        const { supports_time } = this.configuration;
        if (!getServerTime || !supports_time) {
            return;
        }
        getServerTime().then(callback);
    }

    private defaultConfiguration(): Tv.DatafeedConfiguration {
        return {
            supported_resolutions: ["1", "5", "15", "30", "60", "1D", "1W", "1M"],
            supports_marks: false,
            supports_timescale_marks: false,
        };
    }
}