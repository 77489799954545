import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentTradingEntry: {
      height: '100%',
      borderRadius: 8,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        maxWidth: 354,
      },
      [theme.breakpoints.between('600', '680')]: {
        width: 276,
      },

      [theme.breakpoints.down('xs')]: {
        height: 438,
        gridArea: 'right',
        marginTop: 0,
        maxWidth: '100%',
      },
    },

   

    content: {
      width: 200,
      height: 114,
      textAlign: 'center',
      margin: 'auto',
      marginTop: 177,
    },
    lable: {
      textAlign: 'center',
      fontSize: '18px',
      marginBottom: 20,
      fontFamily: 'Titillium-Web'
    },

    btnSignUp: {
      background: theme.custom.colors.cornflowerBlue,
      borderRadius: 6,
      height: 36,
      width: 126,
      fontFamily: 'Titillium-Web',
      fontSize: 12,
      color: theme.custom.colors.btnTitle,
      boxShadow: 'none',
      fontWeight: 'bold',

      '&:hover': {
        background: theme.custom.colors.purple1,
      },
    },
  };
});

export default useStyles;
