import React from "react";
import clsx from "clsx";
import {
  InputBase,
  InputBaseProps,
  FormLabelProps,
  IconButton,
} from "@material-ui/core";
import useStyles from "./styles";
import { UseControllerProps } from "react-hook-form";
import { useToggle } from "react-use";
import { ReactComponent as VisibleDarkIcon } from "src/assets/icons/svg/visibility.svg";
import { ReactComponent as InvisibleDarkIcon } from "src/assets/icons/svg/visibility-off.svg";
import { ReactComponent as VisibleLightIcon } from "src/assets/icons/svg/visibility-light.svg";
import { ReactComponent as InvisibleLightIcon } from "src/assets/icons/svg/visibility-off-light.svg";

import { useSelector } from "react-redux";
import AppInput from '../AppInput'

export interface AppInputProps<T> extends InputBaseProps {
  label?: string;
  labelProps?: FormLabelProps;
  controller: UseControllerProps<T>;
  helperText?: string | React.ReactNode;
}

const AppPassword: React.FC<AppInputProps<any>> = (props) => {
  const [visible, toggle] = useToggle(false);
  const statusThemeMode = useSelector((state: any) => state.themeMode.status);
  return (
    <AppInput
      type={visible ? "text" : "password"}
      endAdornment={
        <IconButton onClick={toggle}>
          {visible ? (
            statusThemeMode ? (
              <VisibleLightIcon />
            ) : (
              <VisibleDarkIcon />
            )
          ) : statusThemeMode ? (
            <InvisibleLightIcon />
          ) : (
            <InvisibleDarkIcon />
          )}
        </IconButton>
      }
      {...props}
    />
  );
};

export const AppStyledInput: React.FC<InputBaseProps> = ({
  classes,
  inputProps,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <InputBase
      inputProps={{ ...inputProps, title: "", "aria-label": "" }}
      classes={{
        ...classes,
        root: clsx(classes?.root, styles.root),
        input: clsx(classes?.input, styles.input),
        multiline: clsx(classes?.multiline, styles.multiline),
        disabled: clsx(classes?.disabled, styles.disabled),
        focused: clsx(classes?.focused, styles.focused, styles.focused),
        error: clsx(classes?.error, styles.error),
        inputAdornedStart: clsx(
          classes?.inputAdornedStart,
          styles.inputAdornedStart
        ),
        inputAdornedEnd: clsx(classes?.inputAdornedEnd, styles.inputAdornedEnd),
        adornedStart: clsx(classes?.adornedStart, styles.adornedStart),
        adornedEnd: clsx(classes?.adornedEnd, styles.adornedEnd),
      }}
      {...rest}
    />
  );
};

export default AppPassword;
