import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import AppButton from 'src/components/Base/AppButton';
import AppInput from 'src/components/Base/AppInput';
import { IPropsBankChild } from './BankInter';

import useStyles from './style';

export interface IFormBankPage2 {
  int_bank_name: string;
  int_routing_number: string;
}

export default function BankInterPage2({ onBack, onSubmit }: IPropsBankChild) {
  const styles = useStyles();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<IFormBankPage2>({
    mode: 'onChange',
    defaultValues: {
      // int_bank_name: 'Intermediary Bank Name', // 2-1
      // int_routing_number: 'Intermediary Routing Number', // 2-2
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'int_bank_name',
            rules: {
              required: true,
            },
          }}
          label="Intermediary Bank Name"
          error={Boolean(errors.int_bank_name)}
          helperText={errors.int_bank_name?.message}
          labelProps={{
            className: errors.int_bank_name ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'int_routing_number',
            rules: {
              required: true,
            },
          }}
          label="Intermediary Routing Number"
          error={Boolean(errors.int_routing_number)}
          helperText={errors.int_routing_number?.message}
          labelProps={{
            className: errors.int_routing_number ? styles.styleLabel : '',
          }}
        />
      </div>

      <Box className={clsx(styles.row, styles.rowBtn)} display="flex">
        <AppButton mode="secondary" height={'36px'} onClick={() => onBack()}>
          Back
        </AppButton>
        <AppButton type="submit" isDisable={!isValid} height={'36px'}>
          Next
        </AppButton>
      </Box>
    </form>
  );
}
