/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AuthenticationAction } from 'src/store/actions/authentication';

import { useQuery } from 'src/utils/common';
import { KEY } from 'src/commons/constants';

const Authorize = () => {
  const query = useQuery();
  const access_token = query.get(KEY.ACCESS_TOKEN) || '';
  const refresh_token = query.get(KEY.REFRESH_TOKEN) || '';
  let uri = query.get(KEY.REDIRECT_URI) || '';
  let username = query.get(KEY.USERNAME) || '';
  const dispatch = useDispatch();

  useEffect(() => {
    // call action login success
    dispatch(
      AuthenticationAction.loginSuccess(access_token, refresh_token, username)
    );

    setTimeout(() => {
      // Router
      if (!uri) {
        // Default to Home
        (window as any).location = '/';
      } else {
        uri = decodeURIComponent(uri);
        (window as any).history.pushState(null, '', uri);
        (window as any).location.reload();
      }
    }, 100);
  }, []);

  return <></>;
};

export default Authorize;
