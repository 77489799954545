import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { Drawer } from '@material-ui/core';
import Link from 'src/components/Base/Link';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';

// project imports
import NavItems from './NavItems';
import useStyles from './styles';

// ===========================|| SIDEBAR DRAWER ||=========================== //

const Sidebar = (props: any) => {
  const { drawerOpen, window } = props;
  const classes = useStyles();
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <>
      <PerfectScrollbar
        component="div"
        className={
          drawerOpen ? classes.ScrollHeight : classes.ScrollHeightSmall
        }
      >
        <div className={classes.toolbar}>
          <Link href="/" className={classes.logoDesktop}>
            <img
              src={`/images/${drawerOpen ? 'logo-big' : 'logo-small'}.png`}
              alt=""
            />
          </Link>
        </div>
        <NavItems {...props} />
      </PerfectScrollbar>
    </>
  );

  return (
    <>
      <Drawer
        container={container}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
