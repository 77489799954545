import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    title: {
      color: theme.custom.colors.white2,
      fontSize: "36px",
      fontWeight: 700,
    },

    groupName: {
      paddingTop: "22px",
      color: theme.custom.colors.white2,
      fontWeight: 700,
    },

    groupContainer: {
      border: "1px dashed #7B61FF;",
      padding: "22px",
      color: theme.custom.colors.white2,
    },

    flex: {
      display: "flex",
      gap: "22px",
    },
  };
});

export default useStyles;
