import { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { kycService } from 'src/services/KycService';
import DefaultLayout from 'src/components/Layout/DefaultLayout';
import Corporate from './Corporate';
import Individual from './Individual';
import KycStatus from './KycStatus';
import { showModalMessage } from 'src/store/actions/message';
import { KYC_STATUS } from 'src/commons/constants';

import useStyles from './styles';
import { useAppSelector } from 'src/hooks';

const coporateSteps = ['General', 'Organization', 'Documents', 'Individuals'];
const individualSteps = ['General', 'Organization', 'Documents'];

function kycReducer(
  oldData: any,
  action: { type: string; username: string; data: any; errors?: any }
) {
  switch (action.type) {
    case 'merge':
      const newData = {
        ...oldData,
        [action.username]: {
          step: oldData[action.username]?.num,
          data: {
            ...oldData[action.username]?.data,
            ...action.data,
          },
          errors: {
            ...oldData[action.username]?.errors,
            ...action.errors,
          },
        },
      };

      localStorage.setItem('kyc_data', JSON.stringify(newData));
      return newData;
    default:
      throw new Error();
  }
}

const KYCPage = (props: any) => {
  const classes = useStyles();

  const [isCorporateAccount, setIsCorporateAccount] = useState('');
  const [activeStep, setActiveStep] = useState(1);
  const [uploadedKyc, setUploadedKyc] = useState(false);
  const [statusKyc, setStatusKyc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState<any>();
  const history = useHistory();

  const isLogged = useAppSelector((state) => state.auth.isLogged);
  const username = useAppSelector((state) => state.auth.username);

  const dispatch = useDispatch();

  const [kycData, kycDispatch] = useReducer(
    kycReducer,
    JSON.parse(localStorage.getItem('kyc_data') || '{}')
  );

  const saveData = useCallback(
    (data: any, errors?: any) => {
      kycDispatch({ type: 'merge', username, data, errors });
    },
    [username]
  );

  const handleSetSteps = (num: number) => {
    window.scrollTo(0, 0);
    setActiveStep(num);
  };

  const handleSubmitKYC = async (state: any) => {
    const oldData = kycData;
    const args = {
      submission: false,
      data: {
        general: oldData[username].data.general,
        organiztion: oldData[username].data.organization,
        ...state,
      },
    };

    try {
      setIsLoading(true);
      const res = await kycService.postKycForm(args);
      if (res.status) {
        localStorage.setItem(
          'kyc_data',
          JSON.stringify({ ...oldData, [username]: {} })
        );
        dispatch(
          showModalMessage({
            type: 'SUCCESS',
            msg: 'Submissions successfully!',
          })
        );
        setUploadedKyc(true);
        setStatusKyc('pending');
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = 'KYC';
    const fetchData = async () => {
      try {
        // get kyc status of account
        const profile = await kycService.getStatusKyc();
        const uploadStatus = await kycService.getUploadStatusKyc();
        const typeAccount = await kycService.getTypeAccount();
        const statusKYC = profile?.data?.data?.kyc_status ?? '';

        if (
          statusKYC === KYC_STATUS.approved ||
          statusKYC === KYC_STATUS.completed ||
          (statusKYC === KYC_STATUS.pending && uploadStatus)
        ) {
          setUploadedKyc(Boolean(uploadStatus.data));
          setStatusKyc(statusKYC);
          return;
        }

        if (typeAccount?.data?.corporate_account) {
          setIsCorporateAccount('corporateAccount');
          setSteps(coporateSteps);
        } else {
          setIsCorporateAccount('individualAccount');
          setSteps(individualSteps);
        }
      } catch (error) {
      } finally {
      }
    };

    // when  account not login
    if (!isLogged) {
      window.location.href = `$/`;
      return;
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isLogged, username]);

  useEffect(() => {
    if (steps && kycData[username]) {
      const arr = steps.map((step: any) => {
        if (kycData[username]?.errors[step.toLocaleLowerCase()]) return false;
        if (kycData[username]?.data[step.toLocaleLowerCase()]) return true;
        return null;
      });

      const firstErrStep = arr.indexOf(false);
      const lastValidStep = arr.indexOf(null);

      setActiveStep(
        firstErrStep > -1
          ? firstErrStep + 1
          : lastValidStep > -1
            ? lastValidStep + 1
            : steps.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  return (
    <DefaultLayout>
      {uploadedKyc ? (
        <>
          <div className={classes.titlePage}>KYC</div>
          <KycStatus status={statusKyc} />
        </>
      ) : (
        <>
          <div className={`${classes.titlePage} ${classes.floatLeft}`}>KYC</div>
          {steps &&
            (isCorporateAccount === 'corporateAccount' ? (
              <Corporate
                steps={steps}
                activeStep={activeStep}
                kycData={kycData[username]}
                isLoading={isLoading}
                handleSetSteps={handleSetSteps}
                handleSubmitKYC={handleSubmitKYC}
                saveData={saveData}
              />
            ) : (
              <Individual
                steps={steps}
                activeStep={activeStep}
                isLoading={isLoading}
                kycData={kycData[username]}
                handleSetSteps={handleSetSteps}
                handleSubmitKYC={handleSubmitKYC}
                saveData={saveData}
              />
            ))}
        </>
      )}
    </DefaultLayout>
  );
};

export default withRouter(KYCPage);

const titleCase = (str: string) => {
  let sentence = str.toLowerCase().split(' ');
  for (let i = 1; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join('');
};

export const handleUploadDocument = async (item: any) => {
  const label = titleCase(item.label);
  const formData = new FormData();
  formData.append(label, item?.value, item?.name);

  return await kycService.postDocumentsKyc(formData);
};
