import { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { landingService } from 'src/services/LandingService';
import SignUpComponent, { SingUpFromInput } from './SignUpComponent/';
import { LoginFromInput } from '../HomePage/LoginPage/LoginComponent';
import useStyles from './styles';

const SignUpPage = () => {
  const styles = useStyles();
  const history = useHistory();
  const [msg, setMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  const handleSignUp = (data: SingUpFromInput) => {
    // Loading
    setLoading(true);
    setMsg('');

    // Call API
    landingService
      .postData('/private/register/', data)
      .then(async (res: any) => {
        if (res.status !== 200) {
          const msg = await res.data;
          if (typeof msg.exception === 'string') {
            const message = msg.exception;
            setMsg(message.charAt(0).toUpperCase() + message.slice(1));
          } else {
            setMsg(JSON.stringify(msg.exception));
          }
          return;
        }
        signUpSuccess(data);
      })
      .catch(() => {
        setMsg('Error sending message, please try again later');
        setLoading(false);
      })
      .then((err: any) => {
        (window as any)?.grecaptcha?.reset();
        setLoading(false);
      });
  };

  const signUpSuccess = async (data: SingUpFromInput) => {
    const resToken: Promise<string> = getToken({
      username: data.username,
      password: data.password,
    });
    const token = await resToken;
    await sendEmail(token);
    history.push(`/confirm/sign-up/${encodeURIComponent(data.email)}`);
  };

  const getToken = async (data: LoginFromInput): Promise<string> => {
    try {
      const res = await landingService.postData('/api/token/', data);
      const token = await res.data;
      return token.access;
    } catch (error) {
      return '';
    }
  };

  const sendEmail = async (token: string) => {
    try {
      await landingService.sendEmailVerify(
        '/private/user/send_verification_email/',
        null,
        {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    document.title = 'Sign up';
  }, []);

  return (
    <div className={styles.root}>
      <Container className={styles.main}>
        <SignUpComponent
          actionSignUp={handleSignUp}
          msg={msg}
          isLoading={isLoading}
        />
      </Container>
    </div>
  );
};

export default withRouter(SignUpPage);
