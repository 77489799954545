import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainModalNewKey: {
      '& .modal-container': {

      },
      [theme.breakpoints.down('md')]: {
        margin: '20px 0'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0'
      },

      [theme.breakpoints.down('xs')]: {
        margin: '20px 0'
      },
      '& .body-modal-container': {

        [theme.breakpoints.down('md')]: {
          maxHeight: '72vh',
        },
        [theme.breakpoints.down('sm')]: {
          maxHeight: '65vh !important',
          minHeight: '63vh !important',
        },
        [theme.breakpoints.down('xs')]: {
          maxHeight: '70vh !important',
          minHeight: '70vh !important',
        },
      },
    },

    mainModal: {},

    titleModal: {
      fontSize: '20px',
      lineHeight: '23px',
      margin: '0 0 30px',
    },

    mainForm: {},

    styleForm: {},

    styleLabel: {
      display: 'block',
      fontWeight: 500,
      fontSize: '14px',
      marginBottom: 7,
      fontFamily: 'Ubuntu-Medium',
      color: theme.custom.colors.white2,
    },

    error: {
      color: '#f44336 !important'
    },

    styleInput: {
      height: 50,
      
    },

    labelError: {
      color: `${theme.custom.colors.red1}!important`,
    },

    rectangle: {
      height: 33,
      width: 1,
      marginRight: 10,
      [theme.breakpoints.down('sm')]: {
        height: 20,
      },
      [theme.breakpoints.down('xs')]: {
        height: 15,
      },
    },

    styleCheck: {
      paddingLeft: 0,
      paddingRight: 5,

      '&:hover': {
        background: 'none',
      },

      '& .MuiIconButton-colorSecondary:hover': {
        background: 'none',
      },

      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: 'transparent',
      },

      '& .MuiCheckbox-root': {
        color: 'transparent',
      },
    },

    styleLbl: {
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '21px',
      color: theme.custom.colors.boldGray,
      marginLeft: 5,
      fontFamily: 'Ubuntu-Medium',

      '& span': {
        fontWeight: 700,
      },
    },

    divBtn: {
      paddingTop: 30,
      width: 217,
    },

    ip: {
      marginRight: 5,
      marginBottom: 5,
    },
  };
});

export default useStyles;
