import { useEffect } from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';

const listRow = [
  {
    id: 1,
    process: 'To register you as a user on our Platform',
    type: 'Account Data',
    legal: 'To perform our contract with you',
  },
  {
    id: 2,
    process: 'To enable you to use our products and services',
    type: 'Account Data, Transaction Data, Support Data, Technical Data [and User Content]',
    legal: 'To perform our contract with you',
  },
  {
    id: 3,
    process: 'To process your payments',
    type: 'Account Data, Transaction Data, Financial Data',
    legal: 'To perform our contract with you',
  },
  {
    id: 4,
    process: 'To collect overdue amounts',
    type: 'Account Data, Transaction Data, Financial Data',
    legal: 'Legitimate interest to recover debts due to us',
  },
];
const PrivacyComponent = (props: any) => {
  const style = useStyles();

  useEffect(() => {
    document.title = 'Privacy';
  }, []);
  return (
    <div>
      <div className={style.main}>
        <div className={style.contentTitle}>
          <div className={style.mainTerm}>
            <div className={style.containerTerm}>
              <div className={style.titleTerm}>
                <Typography variant="h3" className={style.styleTitle}>
                  Privacy Policy
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className={style.detailPrivacy}>
          <section className={style.section1}>
            <p className={style.headerText}>This policy sets out:</p>
            <div className={style.list1}>
              <p>
                1. the information we collect about you when you visit our
                website, use our products or services, or otherwise interact
                with us
              </p>

              <p>
                2. how we use, share, store, and secure the information; and
              </p>

              <p>3. how you may access and control the information.</p>
            </div>
            <div className={style.text1}>
              <p>
                In this policy,<span> “oneAlpha” </span> or <span> “we” </span>{' '}
                refers to Alphanode LTD of RM 1104, Crawford House, 70 Queens
                Road Central, Hong Kong (together with our subsidiaries, our
                holding company, and subsidiaries of our holding company from
                time to time) and
                <span>“Platform”</span> means our website at www.onealpha.io and
                our software, namely onealpha.io/trade. In this policy,{' '}
                <span> “personal information” </span> refers to any data,
                information, or combination of data and information that is
                provided by you to us, or through your use of our products or
                services, that relates to an identifiable individual.
              </p>
            </div>
          </section>
          <section className={style.groupText}>
            <p className={style.headerText2}>
              <span>1.</span> What information we collect about you
            </p>
            <p className={style.subTitle}>
              1.1 We collect the following types of information about you:
            </p>
            <p className={style.subContent}>
              (a) account and profile information that you provide when you
              register for an account or sign up for our products or services,
              for example your name, username or similar identifier, other
              personal description, job title, date of birth and gender,
              physical address (billing or delivery or both), email address,
              telephone number(s) (collectively, “Account Data”);
            </p>
            <p className={style.subContent}>
              (b) information you provide through support channels, for example
              when you report a problem to us or interact with our support team,
              including any contact information, documentation, or screenshots
              (collectively, “Support Data”);
            </p>
            <p className={style.subContent}>
              (c) communication, marketing, and other preferences that you set
              when you set up your account or profile, or when you participate
              in a survey or a questionnaire that we send you (collectively,
              “Preference Data”);
            </p>
            <p className={style.subContent}>
              (d) details of any transactions, purchases, or orders that you've
              made with us (collectively, “Transaction Data”);
            </p>
            <p className={style.subContent}>
              (e) payment information, for example bank account details
              (collectively, “Financial Data”);
            </p>
            <p className={style.subContent}>
              (f) information about your device or connection, for example your
              internet protocol (IP) address, log-in data, browser type and
              version, time-zone setting, browser plug-in types and versions,
              operating system and platform, and other technology on the devices
              you use to access our products or services and information we
              collect through cookies and other data collection technologies
              (please read our Cookies Policy for details) (collectively,
              “Technical Data”); and
            </p>
            <p className={style.subContent}>
              (g) Information about your use of or visit to our Platform, for
              example page response times, download errors, length of visits to
              certain pages, page interaction information (such as scrolling,
              clicks, and mouse-overs), or methods to browse away from the page
              (collectively, “Usage Data”).
            </p>
            <p className={style.subTitle}>
              1.2 We collect the above information when you provide it to us or
              when you use or visit our Platform. We may also receive
              information about you from other sources, including:
            </p>
            <p>
              (a) our business partners and service providers based in Hong
              Kong, UK, USA who provide Fiat Currency and Digital Currency
              custody and or trading services.
            </p>
            <p className={style.subTitle}>
              1.3 We do not collect sensitive data or special category data
              about you. This includes details about your race, ethnic origin,
              politics, religion, trade union membership, genetics, biometrics,
              health, or sexual orientation.
            </p>
          </section>
          <section className={style.hidden}>
            <p className={style.headerText3}>
              2. How we use information we collect
            </p>
            <p className={style.subTitle}>
              We only use your personal information where the law allows us to.
              We use your personal information only where:
            </p>
            <p className={style.subTitle}>
              (a) we need to perform the contract we have entered into (or are
              about to enter into) with you, including to operate our products
              or services, to provide customer support and personalised
              features, and to protect the safety and security of our Platform;
            </p>
            <p className={style.subTitle}>
              (b) it satisfies a legitimate interest which is not overridden by
              your fundamental rights or data protection interests, for example
              for research and development, and in order to protect our legal
              rights and interests
            </p>
            <p className={style.subTitle}>
              (c) you've given us consent to do so for a specific purpose, for
              example we may send you direct marketing materials or publish your
              information as part of our testimonials or customer stories to
              promote our products or services with your permission; or
            </p>
            <p className={style.subTitle}>
              (d) we need to comply with a legal or regulatory obligation.
            </p>
          </section>
          <section>
            <p className={style.headerText3}>Legal Bases Table</p>
            <div className={style.tblRes}>
              <table className={style.table}>
                <tr>
                  <th>Processing purpose</th>
                  <th>Type of data processed</th>
                  <th>Legal basis</th>
                </tr>
                {listRow?.map((item: any) => {
                  return (
                    <tr key={item.id} className={style.row}>
                      <td>{item.process}</td>
                      <td>{item.type}</td>
                      <td>{item.legal}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default PrivacyComponent;
