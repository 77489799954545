import { Backdrop, Fade, Modal } from '@material-ui/core';
import { useEffect } from 'react';
import useStyles from './styles';

export const ModalCustom = ({
  isOpen,
  handleClose,
  children,
  className,
  isIconClose,
}: {
  isOpen: boolean;
  isIconClose?: boolean;
  handleClose: () => void;
  children?: any;
  className?: string;
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (isOpen && window.innerWidth < 900) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    }
    return () => {
      document.body.style.position = 'unset';
    };
  }, [isOpen]);

  return (
    <Modal
      className={`${classes.mainModal} ${className}`}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className="modal-container">
          {isIconClose ? (
            <></>
          ) : (
            <div className={classes.iconClose}>
              <span className="icon-close" onClick={handleClose}></span>
            </div>
          )}
          <div className="body-modal-container">{children}</div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalCustom;
