import React, { useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import {} from 'react-router-dom';
import ModalCustom from 'src/components/Base/ModalCustom';
import { NewApiKey } from 'src/services/ApiKeyService';
import customToast from 'src/components/Base/Toast/Toast';
import useStyles from './styles';

export const ModalNewKey = ({
  isOpen,
  handleClose,
  newKey,
}: {
  isOpen: boolean;
  handleClose: () => void;
  newKey?: NewApiKey;
}) => {
  const classes = useStyles();

  const [keyCopyTooltip, setKeyCopyTooltip] = useState(false);
  const [secretCopyTooltip, setSecretCopyTooltip] = useState(false);

  const copyToClipboard = (type: 'key' | 'secret') => {
    if (type === 'key') {
      newKey?.key && navigator.clipboard.writeText(newKey?.key);
      setKeyCopyTooltip(true);
      customToast.success('Copied!');
      setTimeout(() => {
        setKeyCopyTooltip(false);
      }, 3000);
    } else {
      newKey?.secret && navigator.clipboard.writeText(newKey?.secret);
      setSecretCopyTooltip(true);
      customToast.success('Copied!');
      setTimeout(() => {
        setSecretCopyTooltip(false);
      }, 3000);
    }
  };

  return (
    <>
      <ModalCustom
        className={classes.mainModalNewKey}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <div className={classes.mainModal}>
          <h3 className={classes.titleModal}>New API Key</h3>
        </div>

        <div className={classes.warning}>
          <img src="/images/icon-warn.svg" alt="" />
          Please note that this information will be shown only once.
        </div>

        <div className={classes.boxLabel}>
          <label className={classes.titleLabel}>Label:</label>
          <span>{newKey?.label}</span>
        </div>
        <br></br>
        <div className={`${classes.boxLabel} ${classes.boxLabel2}`}>
          <label className={classes.titleLabel}>Prefix:</label>
          <span>{newKey?.prefix}</span>
        </div>

        <div className={classes.mainForm}>
          <form className={classes.styleForm}>
            <label className={classes.styleLabel}>API Key</label>
            <div className={classes.styleInput}>
              {newKey?.key || 'API Key'}
              <Tooltip
                title="API Key copied"
                placement="top"
                arrow
                open={keyCopyTooltip}
              >
                <Button
                  className={classes.btnICon}
                  onClick={() => copyToClipboard('key')}
                >
                  <span className="icon-copy2"></span>
                </Button>
              </Tooltip>
            </div>
            <label className={classes.lblWaring}>
              Key will not be shown again
            </label>
            <div className={classes.rectangle}></div>

            <label className={classes.styleLabel}>API Secret</label>
            <div className={classes.styleInput}>
              {newKey?.secret || 'API Secret'}
              <Tooltip
                title="API Secret copied"
                placement="top"
                arrow
                open={secretCopyTooltip}
              >
                <Button
                  className={classes.btnICon}
                  onClick={() => copyToClipboard('secret')}
                >
                  <span className="icon-copy2"></span>
                </Button>
              </Tooltip>
            </div>

            <label className={classes.lblWaring}>
              Secret will not be shown again
            </label>
            <div className={classes.divBtn}>
              <Button
                variant="contained"
                className={classes.btnSubmit}
                onClick={handleClose}
              >
                OK
              </Button>
              <br></br>
              <label className={classes.lblWaring}>
                Click OK once you have copied Key and Secret
              </label>
            </div>
          </form>
        </div>
      </ModalCustom>
    </>
  );
};

export default ModalNewKey;
