import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    boxUser: {
      marginLeft: 20,
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Ubuntu-Regular',
       "& .MuiIconButton-root": {
        "&:hover": {
        background: theme.custom.colors.comet2,
      }
      },
       
      [theme.breakpoints.down("md")]: {
        marginLeft: 10,
      },
    },

    iconButton: {
      width: 40,
      height: 40,
      borderRadius: "50%",
      background: theme.custom.colors.comet2,
      color: theme.custom.colors.white2,

      "& .icon-user:before": {
        color: theme.custom.colors.white2,
      },

     
      
    },

    name: {
      marginLeft: 10,
      fontSize: 16,
      color: theme.custom.colors.white2,
      wordBreak:'break-all'
    }
  };
});

export default useStyles;
