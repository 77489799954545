import React, { useState, useRef } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import assetConfig from 'src/commons/configs/assetsConfig.json';
import { DEFAULT_LOGO } from 'src/commons/constants';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import useStyles from './styles';
export interface SelectCoinProps {
  value: any;
  options: [];
  onChange: (value: any) => {};
}
const SelectCoin: React.FC<any> = (props: SelectCoinProps) => {
  const { value, options, onChange } = props;
  const objOptions = options.map((item: string) => ({
    name: item,
    value: item,
  }));
  const selectContainerRef = useRef<HTMLElement>(null);
  useOnClickOutside(selectContainerRef, (e) => {
    if (isActive) setIsActive(!isActive);
  });
  const styles = useStyles();
  const [isActive, setIsActive] = useState(false);
  const objAsset = assetConfig as any;
  const renderLogoCoin = (coin: string[]) => {
    return (
      <>
        <img
          className={styles.coinLogo}
          src={objAsset[`${coin[0]}`]?.icon || DEFAULT_LOGO}
          alt=""
        />
        <span>{`${coin[0]}/${coin[1]}`}</span>
      </>
    );
  };
  return (
    <div
      className={styles.dropdown}
      ref={(selectContainerRef as React.RefObject<HTMLDivElement>) || null}
    >
      <div
        className={styles.dropdownBtn}
        onClick={() => setIsActive(!isActive)}
      >
        <div className={styles.selectedItem}>
          {typeof value === 'string' ? (
            renderLogoCoin(value?.split('/'))
          ) : (
            <>
              {' '}
              <img
                className={styles.coinLogo}
                src={value.tokenLogo1}
                alt=""
              ></img>
              <span className={styles.anchor}>{value.pair}</span>
            </>
          )}
        </div>

        <div className={styles.arrowIcon}>
          {isActive ? (
            <span className="icon-arrow-up"></span>
          ) : (
            <span className="icon-arrow-down"></span>
          )}
        </div>
      </div>

      {isActive && (
        <div className={styles.groupSelect}>
          <SelectSearch
            options={objOptions}
            value={value}
            search
            filterOptions={fuzzySearch}
            className={styles.selectCoin}
            onChange={(e) => {
              onChange(e);
              setIsActive(false);
            }}
            renderOption={(
              optionsProps: any,
              optionData: any,
              optionSnapshot: any
            ) => {
              return (
                <div
                  onClick={() => {
                    onChange(optionsProps.value);
                    setIsActive(false);
                  }}
                  className={`${styles.optionCoin} ${
                    optionSnapshot.highlighted ? styles.highlighted : ''
                  }  ${optionSnapshot.selected ? styles.highlighted : ''}`}
                >
                  <img
                    src={`/images/logos/${optionsProps.value
                      .split('/')[0]
                      ?.toLowerCase()}.svg`}
                    alt=""
                  />
                  <div className={styles.coinSymbol}>{optionsProps.value}</div>
                </div>
              );
            }}
            renderValue={(valueProps: any) => {
              return (
                <div className={styles.inputGroup}>
                  <span className={`icon-search ${styles.iconSearch}`}></span>

                  <input
                    {...valueProps}
                    className={styles.inputCoin}
                    autoFocus={true}
                    placeholder="Search"
                  />
                </div>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};
export default SelectCoin;
