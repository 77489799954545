function joiner(data: any[]) {
  return data.join(',').concat('\n');
}

export function toCsvBlob(data: any[], header: any[] = []) {
  const headerRow = joiner(header.map((el) => el.name));

  const headerKeys = header.map((el) => el.key);
  const dataRows = data.map((el) => joiner(headerKeys.map((key) => el[key])));

  const blob = new Blob([headerRow, dataRows.join('')], {
    type: 'text/csv;charset=utf-8;',
  });

  return blob;
}
