import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    navItems: {},

    itemNav: {
      display: "flex",
      marginBottom: 15,
      fontSize: 16,
      color: theme.custom.colors.santaGray,
      padding: 8,
      borderRadius: 5,
      textDecoration: "none",
      alignItems: "center",
      justifyContent: "flex-start",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
      },
    },

    itemNavActive: {
      background: theme.custom.colors.cornflowerBlue,
      color: "#FFFFFF",
      fontFamily: "Ubuntu-Bold",
    },

    lineW: {
      background: "#C4C4C4",
      width: "100%",
      height: 1,
      marginBottom: 15,
    },

    iconItemNav: {
      fontSize: 24,
      marginRight: 10,

      "&:before": {
        color: theme.custom.colors.comet3,
      },
    },

    iconItemNavActive: {
      "&:before": {
        color: "#FFFFFF",
      },
    },

    listChild: {
      marginBottom: 15,
      padding: 0,
    },

    itemNavChild: {
      fontSize: 14,
      display: "flex",
      color: theme.custom.colors.santaGray,
      padding: 10,
      paddingLeft: 40,
    },

    itemNavChildActive: {
      background: theme.custom.colors.cornflowerBlue,
      color: "#FFFFFF",
      fontFamily: "Ubuntu-Bold",
    },

    iconArrow: {
      marginLeft: "auto",
      fontSize: 16,

      "&:before": {
        color: theme.custom.colors.santaGray2,
      },
    },

    iconArrowActive: {
      "&:before": {
        color: "#FFFFFF",
      },
    },

    styleBtnDeposit: {
      [theme.breakpoints.down("sm")]: {
        height: 100,
      },
    },

    btnDeposit: {
      fontFamily: "Ubuntu-Bold",
      color: "#FFFFFF",
      boxShadow: "none",
      width: "100%",
      minHeight: 40,
      fontSize: 14,
      background: theme.custom.colors.cornflowerBlue,
      textTransform: "inherit",


      "&:hover": {
        background: theme.custom.colors.btnHover,
      },


    }
  };
});

export default useStyles;
