import { HTTPBaseService } from 'src/request/HTTPBaseService';

export interface OrderRequestParams {
  type?: 'BEST_RATE' | 'LIMIT' | 'QUOTE';
  side?: string;
  symbol?: string;
  state?: 'OPEN' | 'CANCELLED' | 'FILLED' | 'EXCEPTION';
  created_start?: number;
  created_end?: number;
  updated_start?: number | null;
  updated_end?: number | null;
  page?: number;
  page_size?: number;
}

export interface OrderResponse {
  items: [any];
  page: number;
  page_size: number;
  pages_total: number;
  items_returned: number;
  items_total: number;
}

export class TradingService extends HTTPBaseService {
  private static classInstance?: TradingService;

  public static getInstance(token: string) {
    if (!this.classInstance) {
      this.classInstance = new TradingService();
    }
    return this.classInstance;
  }

  public async getBar(data: any) {
    const buildURL = `/private/pricing/charts/?symbol=${data.symbol}&period=${data.period}`;
    return await this.instance.get(buildURL, {
      transformRequest: [
        (data, headers) => {
          headers['x-requested-with'] = '';
          delete headers.Authorization;
          return data;
        },
      ],
    });
  }

  //   public async getRecentOrder(data: any) {
  //     return await this.instance.post(`/order/get_orders/`, data);
  //   }

  public async get(params?: OrderRequestParams) {
    return await this.instance.get<OrderResponse>('/orders/', { params });
  }

  public async refreshDataChart(data: any) {
    const buildURL = `/private/pricing/?symbol=${data.symbol}&period=${data.period}`;
    return await this.instance.get(buildURL, {
      transformRequest: [
        (data, headers) => {
          headers['x-requested-with'] = '';
          delete headers.Authorization;
          return data;
        },
      ],
    });
  }

  public async addOrder(data: any) {
    return await this.instance.put(`/orders/`, data);
  }
}

export const tradingService = new TradingService();
