import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MAX_LENGTH_INPUT } from 'src/commons/constants/AppInputFormat';
import AppButton from 'src/components/Base/AppButton';
import AppInput from 'src/components/Base/AppInput';
import Select from 'src/components/Base/Select';
import { trimObject } from 'src/utils/utils';
import { rulesTextField } from 'src/utils/validation';
import useStyles from './styles';

const Documents = ({
  formData,
  lastErrors,
  isLoading,
  setStep,
  saveData,
  handleSubmitKYC,
}: {
  formData: any;
  lastErrors: any;
  isLoading: boolean;
  setStep: (step: number) => void;
  saveData: (data: any, errors?: any) => void;
  handleSubmitKYC: (item: any) => any;
}) => {
  const classes = useStyles();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      occupation: formData?.documents?.occupation,
      nameOfEmployer: formData?.documents?.nameOfEmployer,
      businessOperationAddress: formData?.documents?.businessOperationAddress,
      employerBusinessNature: formData?.documents?.employerBusinessNature,
      website: formData?.documents?.website,
      income: formData?.documents?.income,
    },
  });

  const onSubmitForm = (data: any) => {
    data = trimObject(data);

    handleSubmitKYC({
      documents: data,
    });
  };

  useEffect(() => {
    const runSaveData = () => {
      let data = trimObject(getValues());

      trigger([
        'businessOperationAddress',
        'employerBusinessNature',
        'income',
        'nameOfEmployer',
        'occupation',
        'website',
      ]).then((isValid) => {
        saveData(
          {
            documents: { ...data },
          },
          { documents: !isValid }
        );
      });
    };

    return runSaveData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  useEffect(() => {
    lastErrors?.documents && trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.stepContainer}>
      <div className={classes.title}>
        <div>Business information</div>
        <span className="icon-info"></span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'occupation',
            rules: rulesTextField('Occupation'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Occupation'}
          error={Boolean(errors.occupation)}
          placeholder="Occupation"
          helperText={errors.occupation?.message}
          labelProps={{
            className: errors.occupation ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'nameOfEmployer',
            rules: rulesTextField('Name of Employer'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Name of Employer'}
          error={Boolean(errors.nameOfEmployer)}
          placeholder="Name of Employer"
          helperText={errors.nameOfEmployer?.message}
          labelProps={{
            className: errors.nameOfEmployer ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'businessOperationAddress',
            rules: rulesTextField('Business operation address'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Business operation address'}
          error={Boolean(errors.businessOperationAddress)}
          placeholder="Business operation address"
          helperText={errors.businessOperationAddress?.message}
          labelProps={{
            className: errors.businessOperationAddress
              ? classes.styleLabel
              : '',
          }}
        />
        <Controller
          control={control}
          name="income"
          rules={rulesTextField('Monthly income')}
          render={({ field: { onChange, value } }) => (
            <Select
              tooltip={
                <div className={classes.descTooltip}>
                  Estimated pre-tax income
                </div>
              }
              value={value}
              options={incomeList}
              className={classes.inputItem}
              inputClassName={classes.selectInput}
              label="Monthly income"
              status={errors.income ? 'error' : 'default'}
              helperMsg={errors.income?.message}
              placeholder="Choose your income"
              onChange={onChange}
            />
          )}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'employerBusinessNature',
            rules: rulesTextField('Employer’s business nature'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Employer’s business nature'}
          error={Boolean(errors.employerBusinessNature)}
          placeholder="Employer’s business nature"
          helperText={errors.employerBusinessNature?.message}
          labelProps={{
            className: errors.employerBusinessNature ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'website',
            // rules: {
            //   ...REGEX.website,
            // },
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Website (if any)'}
          error={Boolean(errors.website)}
          placeholder="Website"
          helperText={errors.website?.message}
          labelProps={{
            className: errors.website ? classes.styleLabel : '',
          }}
        />
        <div className={`${classes.btnNext} mt-40`}>
          <AppButton
            fullWidth={true}
            isDisable={false}
            onClick={() => setStep(2)}
          >
            Back
          </AppButton>
          <AppButton
            type="submit"
            fullWidth={true}
            isDisable={
              !isValid || lastErrors?.general || lastErrors?.organization
            }
            onClick={() => null}
            isLoading={isLoading}
          >
            Submit
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default Documents;

const incomeList = [
  {
    label: '0 (USD)',
    value: '0 (USD)',
  },
  {
    label: '0-1000 (USD)',
    value: '0-1000 (USD)',
  },
  {
    label: '1000-3000 (USD)',
    value: '1000-3000 (USD)',
  },
  {
    label: '3000-5000 (USD)',
    value: '3000-5000 (USD)',
  },
  {
    label: '5000-10000 (USD)',
    value: '5000-10000 (USD)',
  },
  {
    label: '10000-50000 (USD)',
    value: '10000-50000 (USD)',
  },
  {
    label: '50000+ (USD)',
    value: '50000+ (USD)',
  },
];
