import React, { useState, useRef } from 'react';
import { MenuList, MenuItem } from '@material-ui/core';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import useOnClickOutside from 'src/hooks/useOnClickOutside';

import useStyles from './styles';
const AutoComplete = (props: any) => {
  const style = useStyles();
  const [valueSearch, setValueSearch] = useState('');
  const { list, openState, handleSelectCoin, handleClose } = props;

  const handleChange = (e: any) => {
    setValueSearch(e.target.value);
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: list,
    getOptionLabel: (option: any) => option,
    open: openState,
    inputValue: valueSearch.trim(),
    onInputChange: handleChange,
  });

  const [isActive, setIsActive] = useState(openState);

  const autoCompletedRef = useRef<HTMLElement>(null);

  useOnClickOutside(autoCompletedRef, (e) => {
    if (isActive) setIsActive(!isActive);
    handleClose(isActive);
  });

  return (
    <div ref={(autoCompletedRef as React.RefObject<HTMLDivElement>) || null}>
      {isActive && (
        <div {...getRootProps()} className={style.search}>
          <span className={`icon-search ${style.iconSearch}`}></span>
          <input className={style.input} {...getInputProps()} />
        </div>
      )}
      {isActive && groupedOptions.length > 0 ? (
        <MenuList className={style.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <MenuItem
              {...getOptionProps({ option, index })}
              onClick={() => handleSelectCoin(option)}
            >
              <div className={style.optionItem}>
                <img
                  src={`/images/logos/${option?.toLowerCase()}.svg`}
                  alt=""
                />
                {option}
              </div>
            </MenuItem>
          ))}
        </MenuList>
      ) : null}
    </div>
  );
};
export default AutoComplete;
