import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';

import { displayNumb } from 'src/utils/number';
import AppInputNumber from 'src/components/Base/AppInputNumber';

import useStyles from './styles';

const FlashTradingCardComponent = (props: any) => {
  const styles = useStyles();
  const { item, favCoins, handleTrade, onChangeAmount, onChangeFav } = props;

  const marketState = useSelector((state: any) => {
    return state.marketReducer;
  });
  const allLimit = marketState?.allLimit;

  const renderFav = (pair: string) => {
    const favourites = favCoins ?? [];
    const isFav = favourites?.find((x: string) => x?.toLowerCase() === pair?.toLowerCase());
    if (isFav) {
      return (
        <span className="icon-favorite">
          <span className="path1"></span>
          <span className="path2"></span>
        </span>
      )
    }
    return <span className="icon-star"></span>
  };

  const showAmount = (coin: string, amount: any) => {
    if (coin === 'USDT' || coin === 'USD' || coin === 'HKD') {
      return `$ ${amount}`;
    }
    if (coin === 'EUR') {
      return `€ ${amount}`;
    }
    if (coin === 'GBP') {
      return `£ ${amount}`;
    }
    return `${coin} ${amount}`;
  };

  const renderFooterLeft = (pairPriceInfo: any) => {
    let symbol = pairPriceInfo['symbol'];
    let buy = pairPriceInfo['buy'];
    let baseCoin = symbol.split("/")[0];
    let quoteCoin = symbol.split("/")[1];
    return (
      <div className={styles.footerCardLeft}>
        <div className={styles.mrB5}>{showAmount(quoteCoin, buy['cost'])}</div>
        <div>{showAmount(baseCoin, buy['amount'])}</div>
      </div>
    );
  }

  return (
    <div className={styles.flashTradingCard}>
      <div className={styles.headCard}>
        <div className={styles.coin}>
          <img src={`/images/logos/${item?.symbol?.split("/")[0]?.toLowerCase()}.svg`} alt="" />
          {item?.symbol?.split("/")[0].toUpperCase()}
        </div>
        <div className={styles.lineHead}></div>
        <div className={styles.coin}>
          <img src={`/images/logos/${item?.symbol?.split("/")[1]?.toLowerCase()}.svg`} alt="" />
          {item?.symbol?.split("/")[1].toUpperCase()}
        </div>
        <Button className={styles.btnFavorite} onClick={() => onChangeFav(item.symbol)}>
          {
            renderFav(item?.symbol)
          }
        </Button>
      </div>
      <div className={styles.bodyCard}>
        <div className={styles.bodyCardBuy} onClick={() => handleTrade(item, 'buy')}>
          <div className={styles.titleBodyCard}>Buy</div>
          <div className={styles.valueBodyCard}>{displayNumb(item?.buy?.price)}</div>
        </div>
        <div className={styles.bodyCardSell} onClick={() => handleTrade(item, 'sell')}>
          <div className={styles.titleBodyCard}>Sell</div>
          <div className={styles.valueBodyCard}>{displayNumb(item?.sell?.price)}</div>
        </div>
        <div className={styles.bodyCardMid}>{(Number(item?.buy?.price) - Number(item?.sell?.price))?.toFixed(2)}</div>
      </div>

      <div className={styles.footerCard}>
        {
          renderFooterLeft(item)
        }
        <AppInputNumber
          container_classes={`${styles.inputAmountContainer} ${(item.hasError && item.hasError !== '') && styles.formInputError}`}
          input_classes={`${styles.footerCardRight} `}
          value={item['amount']}
          // onValueChange={(value) => onChangeAmount(item, value)}
          onChange={(event: any) => onChangeAmount(item, event)}
          precision={allLimit[item?.symbol?.split('/')[0]]?.amount_precision}
        />
      </div>
      <div>
        {item.hasError && item.hasError !== '' && (
          <span className={styles.errorMsg}>{item.hasError}</span>
        )}
      </div>
    </div>
  );
};
const FlashTradingCard = memo(FlashTradingCardComponent);
export default FlashTradingCard;
