import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: 221,
    fontSize: 14,
  },

  dropdown: {
    zIndex: 2,
    marginTop: 2,
    position: 'absolute',
    width: '100%',
  },

  search: {
    marginTop: 5,
    position: 'relative',
    "& .MuiAutocomplete-input": {
      width: '100%',
      background: 'none',
      height: 42,
      border: `1px solid  ${theme.custom.colors.white2}`,
      borderRadius: 4,
      fontSize: 14,
      color: theme.custom.colors.white2,
      textIndent: 10,
      fontFamily: 'Ubuntu-Regular',
      "&::placeholder": {
         color: theme.custom.colors.santaGray,
      },

      "&:focus-visible": {
        border: `1px solid ${theme.custom.colors.lightPurple}`,
        outline:'none'
      }

    
    },

    
  },

  iconSearch: {
    position: 'absolute',
    right: 10,
    fontSize: '25px',
    top: 8,
    '&:before': {
        color: theme.custom.colors.textContentCard,
      },
  },

  inputRoot: {
    
  },
  
  paper: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14,
    boxShadow: 'none',
    width: '100%',
    margin: 0,
    background: theme.custom.colors.cardBackground,
    color: theme.custom.colors.white2,

    borderColor: theme.custom.colors.white2,
    border: '1px solid',
    marginTop: 5
  },


  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 10,
    '& .optionSelected': {
      backgroundColor: theme.custom.colors.cornflowerBlue,
      width: '100%',
      color: '#ffffff'
    },

    '&[data-focus="true"]': {
      backgroundColor: theme.custom.colors.cornflowerBlue,
      color: '#ffffff',
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.custom.colors.cornflowerBlue,
    },
  },

  noOptions: {
    color: theme.custom.colors.white2,
  },

}));

export default useStyles;
