import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAppSelector } from 'src/hooks';
// import LoginPage from 'src/routing/LandingPage/HomePage/LoginPage';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  redirectPath?: string;
}

const PrivateRoute = ({
  component: Component,
  redirectPath = '/login',
  ...rest
}: PrivateRouteProps) => {
  const isLogged = useAppSelector((state) => state.auth.isLogged);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLogged ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={redirectPath} />
        )
      }
    />
  );
};
export default PrivateRoute;
