import { HTTPBaseService } from 'src/request/HTTPBaseService';

export interface AssetResponse {
  assets: [string];
}
export interface MarketResponse {
  markets: [string];
}

export class AssetService extends HTTPBaseService {
  private static classInstance?: AssetService;

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new AssetService();
    }
    return this.classInstance;
  }

  public async getAssets() {
    return await this.instance.get<AssetResponse>('/asset/assets/');
  }

  public async getLimits() {
    return await this.instance.get('/asset/limits/');
  }

}
