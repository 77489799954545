import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Hidden, List, Link as MuiLink } from '@material-ui/core';
import Link from 'src/components/Base/Link';

import useStyles from './styles';
import { activeMenu, handleTrade, PAGE } from 'src/utils/utils';

const listMenuDef = [
  {
    id: 1,
    name: 'Home page',
    url: '/',
  },
  {
    id: 2,
    name: 'Trade',
    url: '/trade',
  },
  {
    id: 3,
    name: 'Liquidity',
    section: 'liquidity',
  },
  {
    id: 4,
    name: 'Settlement',
    section: 'settlement',
  },
  {
    id: 5,
    name: 'API',
    section: 'api',
  },
  {
    id: 6,
    name: 'Use cases',
    url: '/usecase',
  },
  {
    id: 7,
    name: 'Team',
    url: '/team',
  },
];

const NavItems = (props: any) => {
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const { drawerOpen, drawerToggle } = props;
  const classes = useStyles();
  let history = useHistory();
  const [listMenu] = useState(listMenuDef);
  const [currentActiveMenuItem, setCurrentActiveMenuItem] = useState('/');
  const [, SetCurrentActiveMenuItemChild] = useState('/');

  const setCurrentActiveMenu = (pathname: string) => {
    switch (pathname) {
      case '/':
        setCurrentActiveMenuItem('/');
        SetCurrentActiveMenuItemChild('');
        break;
      case '/liquidity':
        setCurrentActiveMenuItem('/liquidity');
        SetCurrentActiveMenuItemChild('');
        break;
      case '/settlement':
        setCurrentActiveMenuItem('/settlement');
        SetCurrentActiveMenuItemChild('');
        break;
      case '/api':
        setCurrentActiveMenuItem('/api');
        SetCurrentActiveMenuItemChild('/api');
        break;
      case '/usecase':
        setCurrentActiveMenuItem('/usecase');
        SetCurrentActiveMenuItemChild('');
        break;
      case '/team':
        setCurrentActiveMenuItem('/team');
        SetCurrentActiveMenuItemChild('');
        break;
      default:
    }
  };

  const handleSignUp = () => {
    history.push(PAGE.SIGNUP);
  };
  const handleLogin = () => {
    history.push(PAGE.LOGIN);
  };

  useEffect(() => {
    setCurrentActiveMenu(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <div className={classes.navItems}>
      <List>
        {listMenu?.map((item) =>
          item.section ? (
            <MuiLink
              key={item.id}
              href={`/#${item.section}`}
              className={`${classes.itemNav} ${activeMenu(
                item,
                classes.itemNavActive
              )}`}
              onClick={drawerToggle}
            >
              <span
                className={`${classes.iconItemNav} ${
                  currentActiveMenuItem === item.section &&
                  classes.iconItemNavActive
                }`}
              ></span>
              {drawerOpen && item.name}
            </MuiLink>
          ) : (
            <Link
              key={item.id}
              href={item.url}
              className={`${classes.itemNav} ${activeMenu(
                item,
                classes.itemNavActive
              )}`}
              onClick={drawerToggle}
            >
              <span
                className={`${classes.iconItemNav} ${
                  currentActiveMenuItem === item.section &&
                  classes.iconItemNavActive
                }`}
              ></span>
              {drawerOpen && item.name}
            </Link>
          )
        )}

        <Hidden smDown>
          <div onClick={drawerToggle} className={`${classes.itemNav}`}>
            <span
              style={{
                transform: drawerOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              className={`icon-hide-menu ${classes.iconItemNav}`}
            ></span>
            {drawerOpen && 'Hide menu'}
          </div>
        </Hidden>

        <Hidden mdUp>
          <div className={`${classes.itemNavButton}`}>
            {isLogged === null ? (
              <></>
            ) : isLogged ? (
              <Button
                variant="contained"
                className={
                  classes.btnSignupMobile + ' ' + classes.btnTradeMobile
                }
                onClick={handleTrade}
              >
                Trade
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  className={classes.btnLoginMobile}
                  onClick={handleLogin}
                >
                  Log in
                </Button>
                <Button
                  variant="contained"
                  className={classes.btnSignupMobile}
                  onClick={handleSignUp}
                >
                  Sign up
                </Button>
              </>
            )}
          </div>
        </Hidden>
      </List>
    </div>
  );
};

export default NavItems;
