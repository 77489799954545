import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: any) => {
  return {
    mainModal: {
      "& .modal-container": {
        width: 307,
        padding: `20px 0`,
        maxHeight: '100%',
        "& .body-modal-container": {
          padding: "0 20px",
          maxHeight: '100%',
          minHeight: '100%',
        },
      },
    },
    title: {
      fontSize: '18px',
      fontFamily: 'Titillium-Web',
      fontWeight: 'bold',
      color: theme.custom.colors.white3,
      textAlign: 'center',
      width: 200,
      margin: 'auto',
      marginBottom: 30
    },
    btnLogin: {
      width: "126px",
      height: "40px",
      borderRadius: "6px",
      color: theme.custom.colors.white3,
      fontFamily: 'Titillium-Web',
      fontWeight: 'bold',
      fontSize: '12px',
      textTransform: "none",
      border: `1px solid ${theme.custom.colors.titleTrading}`,
      "&:hover": {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.custom.colors.colorStep}`,
        color: theme.custom.colors.colorStep,
      },

    },
    btnSignUp: {
      width: "126px",
      height: "40px",
      borderRadius: "6px",
      fontFamily: 'Titillium-Web',
      fontWeight: 'bold',
      fontSize: '12px',
      color: theme.custom.colors.btnTitle,
      background: theme.custom.colors.cornflowerBlue,
      textTransform: "none",
      '&:hover': {
        backgroundColor: theme.custom.colors.btnHover,
      },
    },
    display: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
});
export default useStyles;