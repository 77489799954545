import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentFooterRight: {
      '& .MuiDataGrid-main': {
        paddingBottom: 15,
      },
    },

    tabComponent: {
      borderBottom: `1px solid ${theme.custom.colors.comet4}`,
      padding: 0,
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
    },

    itemTabComponent: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 18,
      lineHeight: '140%',
      color: '#9BA1B1',
      padding: '10px 0',
      cursor: 'pointer',
      borderBottom: '2px solid transparent',
      marginRight: 40,
      marginBottom: -1,

      '&:last-child': {
        marginRight: 0,
      },
    },

    activeTab: {
      borderBottom: '2px solid #7F63F4',
      color: theme.custom.colors.boldGray,
    },

    symbol: {
      display: 'flex',
      lineHeight: '20px',
    },

    symbolInfo: {},

    iconCoin: {
      width: 35,
      height: 35,
      marginRight: 10,
    },

    btnConfirmed: {
      minWidth: 87,
      height: 29,
      borderRadius: 50,
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 12,
      color: '#FFFFFF',
      background: 'rgba(76, 162, 80, 0.25)',
      border: '1px solid #4CA250',

      '&:hover': {
        background: 'rgba(76, 162, 80, 0.25)',
      },

      '&:last-child': {
        marginRight: 0,
      },
    },

    breakSpaces: {
      whiteSpace: 'break-spaces',
      lineHeight: '20px',
    },
    status: {
      padding: '0 10px',
      minWidth: 87,
      height: 29,
      borderRadius: 50,
      boxShadow: 'none',
      textTransform: 'capitalize',
      fontSize: 12,
      lineHeight: '29px',
      textAlign: 'center',
      border: '1px solid',
      borderColor: theme.custom.colors.white2,

      '&:last-child': {
        marginRight: 0,
      },
    },
    statusConfirm: {
      background: 'rgba(76, 162, 80, 0.25)',
      borderColor: theme.custom.colors.green1,
      color: theme.custom.colors.colorStatus,
    },
    statusFilled: {
      background: 'rgba(222, 73, 73, 0.25)',
      border: '1px solid #DE4949',
      color: theme.custom.colors.clCanceled,
    },
    statusOpen: {
      background: 'rgba(222, 73, 73, 0.25)',
      border: '1px solid #DE4949',
      color: theme.custom.colors.clCanceled,
    },
    statusCancel: {
      background: 'rgba(222, 73, 73, 0.25)',
      border: '1px solid #DE4949',
      color: theme.custom.colors.clCanceled,
    },
    capitalized: {
      textTransform: 'capitalize',
    },
    emptyStyle: {
      height: 'calc(100% - 70px)',
      padding: 20,
    },
    styleTab: {
      '& .MuiDataGrid-windowContainer': {
        '& .MuiDataGrid-window': {
          height: '608px',
        },
      },
      [theme.breakpoints.down('xs')]: {},
    },
  };
});

export default useStyles;
