import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },

    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      minHeight: 70,
      [theme.breakpoints.down("sm")]: {
        minHeight: 64,
      },
    },

    content: {
      flexGrow: 1,
      paddingLeft: 20,
      paddingRight: 20,
      background: theme.custom.colors.mainBackground,
      minHeight: "calc(100vh)",
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        paddingLeft: 96,
        paddingRight: 96,
      },
    },

    overMobile: {
      overflow: 'hidden',
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh)",
      },
    }
  };
});

export default useStyles;
