import { Container } from '@material-ui/core';
import Common from 'src/components/BaseLandingPage/Common';
import NewPasswordComponent from './NewPasswordComponent';

const NewPasswordPage = () => {
  return (
    <Common>
      <Container>
        <NewPasswordComponent />
      </Container>
    </Common>
  );
};
export default NewPasswordPage;
