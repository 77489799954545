import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      textAlign: 'center',
      alignItems: 'center',
      // padding: '0px 200px'
      '& .body-modal-container': {
        [theme.breakpoints.down('xs')]: {
          padding: '0 20px',
          minHeight: '21vh !important',
        },
      },
    },
    title: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 20,
      lineHeight: '115%',
      color: theme.custom.colors.titleTrading,
      margin: 0,
      marginBottom: 8,
    },
    subTitle: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: 14,
      color: theme.custom.colors.titleConfirmCode,
      lineHeight: '20px',
      margin: 0,
      marginBottom: 15,
    },
    textError: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: 12,
      color: '#DE4949',
      lineHeight: '14px',
      margin: 0,
      marginBottom: 15,
      textAlign: 'center',
      marginTop: '10px',
    },
    btnGroup2: {
      display: 'flex',
      columnGap: '14px',
      justifyContent: 'center',
      width: '100%',
      marginTop: '-20px',
      alignItems: 'end',
    },
    btnSubmit: {
      fontFamily: 'Ubuntu-Bold',
      background: theme.custom.colors.cornflowerBlue,
      borderRadius: 6,
      minHeight: 36,
      minWidth: 'auto',
      fontSize: 12,
      color: '#FFFFFF',
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',
      marginTop: '2.5rem',
      width: '126px',

      '&:hover': {
        background: theme.custom.colors.cornflowerBlue,
        boxShadow: 'none',
      },
      '&:disabled': {
        background: theme.custom.colors.bgDisable,
        color: '#FFFFFF !important',
      },
    },
    btnCancel: {
      borderRadius: '6px',
      color: '#FFF',
      backgroundColor: '#606983',
      fontFamily: 'Ubuntu-Bold',
      fontSize: '12px',
      minHeight: 36,
      width: '126px',
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',
      marginTop: '2.5rem',

      '&:hover': {
        background: '#606983',
        boxShadow: 'none',
      },
    },
    customize: {
      display: 'flex !important',
      justifyContent: 'center',
      // padding: '0 100px',
      columnGap: '10px',
      '& input': {
        width: 36,
        height: 36,
        fontSize: 14,
        background: 'none',
        color: theme.custom.colors.lightPurple,
        borderRadius: 5,
        textAlign: 'center',
        border: '1px solid #EBECF0',
        outline: 'none',
        '&:focus': {
          color: theme.custom.colors.lightPurple,
          textAlign: 'center',
          border: '1px solid #B8A7FF !important',
          boxShadow: `0 6px 6px -6px #B8A7FF`,
        },
      },
      [theme.breakpoints.only('xs')]: {
        padding: '0 10px',
      },
    },
    borderColor: {
      '& input': {
        border: '1px solid #B8A7FF',
      },
    },
  };
});

export default useStyles;
