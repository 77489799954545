/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Hidden } from '@material-ui/core';

import VolumeChart from 'src/components/Base/VolumeChart';
import { AccountService } from 'src/services/AccountService';
import GroupSelect from 'src/components/GroupSelect';
import Select from 'src/components/Base/Select';
import * as MarketActions from 'src/store/actions/market';
import ModalSignUp from 'src/routing/TradingPage/ComponentTradingEntry/ModalSignUp';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import useStyles from './styles';

import {
  TRADE_DEFAULT_TOKEN_BUY,
  BALANCE_COINS_DEFAULT,
} from 'src/commons/constants/Constants';

import TableStyle from 'src/styles/TableStyle';
const listScales = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
];
const BalanceComponent = (props: any) => {
  const { defaultType } = props;
  const marketState = useSelector((state: any) => {
    return state.marketReducer;
  });
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const allMarket = marketState?.allMarket;
  const styles = useStyles();
  const tableStyle = TableStyle();
  const dispatch = useDispatch();
  const [scale, setScale] = useState('month');
  const [type, setType] = useState(defaultType);
  const [asset, setAsset] = useState(defaultType);
  const [isOpen, setIsOpen] = useState(false);
  const [listCoins, setListCoins] = useState(BALANCE_COINS_DEFAULT);
  const [seriesBuy, setSeriesBuy] = useState<any[]>([]);
  const [seriesSell, setSeriesSell] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<any[]>([]);
  const [assetList, setAssetList] = useState(Array<string>());
  const [noData, setNoData] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const accountService = new AccountService();
  const selectContainerRef = useRef<HTMLElement>(null);
  useOnClickOutside(selectContainerRef, (e) => {
    if (isActive) setIsActive(!isActive);
  });

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'none',
      borderRadius: 8,
    },
    title: { text: '' },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: dateRange,
      labels: {
        style: {
          color: '#C3C7D1',
          fontSize: '16px',
        },
      },
    },

    legend: {
      align: 'left',
      x: 0,
      y: -50,
      verticalAlign: 'top',
      floating: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      className: 'highcharts-color-1',
      labels: {
        style: {
          color: '#C3C7D1',
          fontSize: '16px',
        },
      },
      tickInterval: 0.02 * 100,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
        borderColor: 'none',
        groupPadding: 0.3,
        pointPadding: 0.2,
      },
      series: {
        maxPointWidth: 30,
      },
    },
    series: [
      {
        name: 'Buy',
        data: seriesBuy,
        borderRadiusTopLeft: 10,
        borderRadiusTopRight: 10,
        color: '#7F63F4',
      },
      {
        name: 'Sell',
        data: seriesSell,
        borderRadiusTopLeft: 10,
        borderRadiusTopRight: 10,
        color: '#B8A7FF',
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  };

  useEffect(() => {
    getAllMarket();
  }, []);

  useEffect(() => {
    setType(type);
    getOrdersVolume(asset, scale);
  }, [type, asset, scale]);

  useEffect(() => {
    if (allMarket && allMarket.length > 0) {
      getPair(TRADE_DEFAULT_TOKEN_BUY);
    }
  }, [allMarket]);

  const getAllMarket = async () => {
    const markets = marketState.allMarket;
    if (markets && markets.length === 0) {
      await dispatch(MarketActions.getAllPairs());
    }
  };
  const getPair = async (activeCoin: string) => {
    const assetList: Array<string> = [];
    const withList: Array<string> = [];

    for (let symbol of allMarket) {
      // get coin from pair
      const splitedCoins = symbol.split('/');
      for (let item of splitedCoins) {
        // check coin has exist list coin and push it to list
        const hasToken = assetList.find((x) => x === item);
        if (!hasToken) {
          assetList.push(item);
        }
      }
    }

    // get token active and binding data to With dropwdown control
    const pairs = allMarket.filter((m: any) =>
      m.includes(activeCoin?.toUpperCase())
    );
    for (let symbol of pairs) {
      // get token from pair
      const token = symbol
        .replace(activeCoin?.toUpperCase(), '')
        .replace('/', '');

      const hasToken = withList.find((x) => x === token);
      if (!hasToken) {
        withList.push(token);
      }
    }
    setAssetList(assetList);
  };
  const getOrdersVolume = async (asset: string, scale: string) => {
    const resultData = await accountService.getOrdersVolume(asset, scale);
    if (resultData && resultData.data && resultData.data.items) {
      const data = resultData.data.items;
      if (data) {
        setNoData(data.length === 0 ? true : false);
        switch (scale) {
          case 'month':
            const fomatMonth = data.map((item: any, key: any) => {
              return moment(item.floor_time * 1000).format('MMM');
            });
            setDateRange(fomatMonth);
            break;
          case 'day':
            const fomatDay = data.map((item: any, key: any) => {
              return moment(item.floor_time * 1000).format('L');
            });
            setDateRange(fomatDay);
            break;
          default:
            const fomatDefault = data.map((item: any, key: any) => {
              return moment(item.floor_time * 1000).format('L');
            });
            setDateRange(fomatDefault);
            break;
        }
        switch (type) {
          case 'BTC':
            const returnBtcBuy = data.map((item: any, key: any) => {
              return +item.btc_buy_amount;
            });
            const returnBtcSell = data.map((item: any, key: any) => {
              return +item.btc_sell_amount;
            });
            setSeriesBuy(returnBtcBuy);
            setSeriesSell(returnBtcSell);
            break;
          case 'USD':
            const returnUsdBuy = data.map((item: any, key: any) => {
              return +item.usd_buy_amount;
            });
            const returnUsdSell = data.map((item: any, key: any) => {
              return +item.usd_sell_amount;
            });
            setSeriesBuy(returnUsdBuy);
            setSeriesSell(returnUsdSell);
            break;
          default:
            const returnBuy = data.map((item: any, key: any) => {
              return +item.buy_amount;
            });
            const returnSell = data.map((item: any, key: any) => {
              return +item.sell_amount;
            });
            setSeriesBuy(returnBuy);
            setSeriesSell(returnSell);
            break;
        }
      }
      //convertData(resultData.data.items);
    }
  };
  const handleChangeType = (value: string) => {
    setType(value);
  };
  const handleChangeAssetCoin = (value: string) => {
    setAsset(value);
    addCoin(value);
  };
  const addCoin = async (value: string) => {
    let newList = BALANCE_COINS_DEFAULT;
    if (newList.length > 2) {
      newList.splice(-1);
    }
    if (!newList.includes(value)) {
      newList.push(value);
    }
    setListCoins(newList);
  };
  useEffect(() => {
    if (defaultType && (defaultType !== 'USD' || defaultType !== 'BTC')) {
      addCoin(defaultType);
    }
    setType(defaultType);
  }, [defaultType]);

  return (
    <>
      <div>
        {isLogged && (
          <Hidden smUp>
            <div className={styles.selectGroup}>
              <GroupSelect
                coins={listCoins}
                value={type}
                defaultValueSelect={type}
                option={true}
                onChangeCoin={handleChangeType}
              />
              <Select
                value={scale}
                options={listScales}
                className={styles.listMonth}
                inputClassName={styles.listMonthInput}
                placeholder="Month"
                onChange={(value: any) => setScale(value)}
              />
              <GroupSelect
                coins={assetList}
                value={asset}
                defaultValueSelect={asset}
                option={true}
                onChangeCoin={handleChangeAssetCoin}
              />
            </div>
          </Hidden>
        )}

        <div
          className={styles.mainChart}
          style={isLogged ? {} : { height: '650px' }}
        >
          {isLogged && (
            <>
              <div className={styles.topHeader}>
                <div className={styles.display}>
                  <div className={styles.legend}>
                    <span></span>
                    <p>Buy</p>
                  </div>
                  <div className={styles.legend}>
                    <span className={styles.colorSell}></span>
                    <p>Sell</p>
                  </div>
                </div>

                <Hidden xsDown>
                  <div className={styles.selectGroup}>
                    <GroupSelect
                      coins={listCoins}
                      value={type}
                      defaultValueSelect={type}
                      option={true}
                      onChangeCoin={handleChangeType}
                    />
                    <Select
                      value={scale}
                      options={listScales}
                      className={styles.listMonth}
                      inputClassName={styles.listMonthInput}
                      placeholder="Month"
                      onChange={(value: any) => setScale(value)}
                    />
                    <GroupSelect
                      coins={assetList}
                      value={asset}
                      defaultValueSelect={asset}
                      option={true}
                      onChangeCoin={handleChangeAssetCoin}
                    />
                  </div>
                </Hidden>
              </div>
            </>
          )}
          {noData ? (
            <div
              className={`${tableStyle.rowEmptydata} ${styles.emptyStyle}`}
              style={isLogged ? {} : { paddingTop: '122px' }}
            >
              <span
                className={`icon-no-data2 ${tableStyle.iconEmptydata}`}
              ></span>
              <div className={tableStyle.textEmptydata}>No data</div>
              <div className={tableStyle.messEmptydata}>
                You will be able to see the information on <br /> the balance
                after deposit
              </div>
              {isLogged ? (
                <Button
                  variant="contained"
                  className={`${tableStyle.btnEmptydata}`}
                  //onClick={() => history.push('/deposit')}
                >
                  Deposit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={`${tableStyle.btnEmptydata}`}
                  onClick={() => setIsOpen(true)}
                >
                  Sign up
                </Button>
              )}
            </div>
          ) : (
            <VolumeChart options={options} />
          )}
        </div>
      </div>

      <ModalSignUp
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      ></ModalSignUp>
    </>
  );
};
export default BalanceComponent;
