import { makeStyles } from '@material-ui/core';

const TableStyle = makeStyles((theme: any) => {
  return {
    customerDataGrid: {
      width: '100%',

      '& .MuiDataGrid-root': {
        border: 'none',

        '& strong': {
          fontFamily: 'Ubuntu-Bold',
        },

        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },

        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
        },

        '& .MuiDataGrid-columnsContainer': {
          border: 'none',
          justifyContent: 'center',
        },

        '& .MuiDataGrid-window': {
          // background: theme.custom.colors.cardBackground,
        },

        '& .MuiDataGrid-footerContainer': {
          justifyContent: 'flex-start',
        },

        '& .MuiDataGrid-columnHeader': {
          paddingLeft: 20,
          fontSize: 14,
          lineHeight: '16px',
          color: theme.custom.colors.santaGray2,
          outline: 'none',
          '&:focus, &:focus-visible': {
            outline: 'none',
          },
        },

        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },

        '& .MuiDataGrid-row': {
          background: theme.custom.colors.cardBackground,
          borderBottom: `4px solid ${theme.custom.colors.mainBackground}`,
          borderRadius: 8,
          '&:hover': {
            background: theme.custom.colors.cardBackground,
          },
        },

        '& .MuiDataGrid-cell': {
          color: theme.custom.colors.white2,
          fontSize: 14,
          lineHeight: '20px',
          border: 'none',
          paddingLeft: 20,
          outline: 0,
          '&:focus': {
            outline: 'none',
          },
        },

        '& .MuiTablePagination-root': {
          color: theme.custom.colors.titleTrading,
        },

        '& .MuiIconButton-root': {
          color: theme.custom.colors.titleTrading,

          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },

      '& .MuiDataGrid-overlay': {
        zIndex: 1,
      },
    },

    rowEmptydata: {
      top: 46,
      background: theme.custom.colors.cardBackground,
      width: '100%',
      height: 'calc(100% - 42px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 2,
      borderRadius: 8,
      padding: 20,
    },

    iconEmptydata: {
      fontSize: 70,
      marginBottom: 30,

      '&:before': {
        color: '#7F63F4',
      },
    },

    textEmptydata: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '28px',
      color: theme.custom.colors.titleTrading,
      marginBottom: 30,
    },

    messEmptydata: {
      fontSize: 16,
      lineHeight: '20px',
      color: theme.custom.colors.colorDes,
      marginBottom: 33,
      maxWidth: '100%',
      textAlign: 'center',
    },

    btnEmptydata: {
      fontFamily: 'Ubuntu-Bold',
      background: theme.custom.colors.btnEnable,
      borderRadius: 6,
      minHeight: 36,
      minWidth: 126,
      fontSize: 14,
      color: theme.custom.colors.btnTitle,
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',

      '&:hover': {
        background: theme.custom.colors.btnHover,
        boxShadow: 'none',
      },

      [theme.breakpoints.down('xs')]: {},
    },

    paginationTable: {
      marginTop: 12,
      '& .MuiPaginationItem-root': {
        background: theme.custom.colors.bgPagination,
        border: `1px solid ${theme.custom.colors.lineTable1}`,
        borderRadius: 5,
        width: 40,
        height: 40,
        lineHeight: '40px',
        fontFamily: 'Ubuntu-Bold',
        fontSize: 18,
        color: '#9BA1B1',

        '&.Mui-selected': {
          background: '#7F63F4',
          borderColor: '#7F63F4',
          color: '#FFFFFF',
        },
      },
      '& .MuiPaginationItem-page.Mui-selected:hover': {
        background: '#7F63F4',
      },
    },

    pagination: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 15,
      '& p': {
        color: theme.custom.colors.titleTrading,
        lineHeight: '40px',
      },
    },

    btnPage: {
      width: 40,
      height: 40,
      borderRadius: '6px',
      backgroundColor: theme.custom.colors.bgPagination,
      border: `1px solid ${theme.custom.colors.borderBtnPage}`,
    },

    btnRoot: {
      minWidth: 0,
    },
  };
});

export default TableStyle;
