import { accountService } from 'src/services/AccountService';
import { kycService } from 'src/services/KycService';
import { addBookService } from 'src/services/AddressBookService';
import {
    GET_PROFILE,
    HAS_2FA_DEVICES,
    KYC_SETTINGS,
    WHITE_LIST,
    SET_DEPOSIT_ADDRESS,
    FETCH_BALANCE,
    FETCH_BANK,
    SET_FAVOURITE
} from '../constants/account';

export const getProfile = () => async (dispatch: any) => {
    try {
        const profileData = await accountService.getProfile();
        if (profileData && profileData.data) {
            dispatch({
                type: GET_PROFILE,
                data: profileData.data,
            });
        }
    } catch (error) {
        throw error;
    }
};

export const getBalances = () => async (dispatch: any) => {
    try {
        const res = await accountService.getBalances();
        if (res && res?.data?.balances) {
            dispatch({
                type: FETCH_BALANCE,
                data: res.data.balances,
            });
        }
    } catch (error) {
        throw error;
    }
};

export const getBanks = () => async (dispatch: any) => {
    try {
        const res = await accountService.getBanks();
        if (res && res?.data?.accounts) {
            dispatch({
                type: FETCH_BANK,
                data: res.data.accounts,
            });
        }
    } catch (error) {
        throw error;
    }
};

export const has2FADevices = () => async (dispatch: any) => {
    try {
        const hasDevices = await accountService.has2FADevices();
        if (hasDevices && hasDevices.data) {
            dispatch({
                type: HAS_2FA_DEVICES,
                data: hasDevices?.data,
            });
        }
    } catch (error) {
        throw error;
    }
};

export const getKYCSettings = () => async (dispatch: any) => {
    try {
        const dataSettings = await kycService.getTypeAccount();
        if (dataSettings && dataSettings.data) {
            dispatch({
                type: KYC_SETTINGS,
                data: dataSettings.data,
            });
        }
    } catch (error) {
        throw error;
    }
};

export const getWhiteListAddrees = () => async (dispatch: any) => {
    try {
        const whiteListAdd = await addBookService.getAddresses();
        if (whiteListAdd && whiteListAdd.data) {
            dispatch({
                type: WHITE_LIST,
                data: whiteListAdd,
            });
        }
    } catch (error) {
        throw error;
    }
};

export const setDepositAddresses = (data: any) => {
    return {
        type: SET_DEPOSIT_ADDRESS,
        payload: data
    }
};

export const getPreferences = () => async (dispatch: any) => {
    try {
        const dataResult = await accountService.getPreferences();
        if (dataResult && dataResult?.data?.favourite) {
            dispatch({
                type: SET_FAVOURITE,
                data: dataResult?.data
            });
        }
    } catch (error) {
        throw error;
    }
};

export const setPreferences = (data: any) => async (dispatch: any) => {
    try {
        const dataResult = await accountService.setPreferences(data);
        if (dataResult && dataResult?.data?.favourite) {
            dispatch({
                type: SET_FAVOURITE,
                data: dataResult?.data
            });
        }
    } catch (error) {
        throw error;
    }
};