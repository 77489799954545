/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';

import useStyles from 'src/components/BaseLandingPage/Common/Form/styles';
import HeaderLogo from 'src/components/BaseLandingPage/Common/HeaderLogo';
import ButtonCommon from 'src/components/BaseLandingPage/Common/Button';
import InputField from 'src/components/BaseLandingPage/Common/Input';
import { MAX_LENGTH, MIN_LENGTH, REGEX_PASSWORD } from 'src/utils/validation';
import { PAGE, useQuery } from 'src/utils/utils';
import { landingService } from 'src/services/LandingService';
import ThankYouComponent from '../../ThankYouPage/ThankYouComponent';
import ErrorPage from '../../ErrorPage';
import { Link } from 'react-router-dom';
import useClasses from './styles';

interface IForm {
  password1: string;
  password2: string;
}

const NewPasswordComponent = () => {
  const style = useStyles();
  const query = useQuery();
  const classes = useClasses();
  const [expired, setExpired] = useState<boolean>(false);
  const [success, setSucces] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const methodsForm = useForm<IForm>({
    mode: 'onChange',
  });
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isValid, isDirty },
  } = methodsForm;
  const password = useRef({});
  password.current = watch('password1', '');

  // Init: Check expired code
  useEffect(() => {
    landingService
      .postData('/account/reset_token/', {
        code: query.get('code'),
        reqid: query.get('reqid'),
      })
      .then((res) => {
        if (res.status !== 200) {
          showExpired();
        }
      })
      .catch(showExpired)
      .then(() => {});
  }, []);

  const showExpired = () => {
    setExpired(true);
  };

  const onSubmit = (data: IForm) => {
    setLoading(true);
    landingService
      .postData('/account/reset_token/', {
        code: query.get('code'),
        reqid: query.get('reqid'),
        ...data,
      })
      .then((res: any) => {
        if (res.status === 200) {
          setSucces(true);
        } else {
          showExpired();
        }
      })
      .catch(showExpired)
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {expired ? (
        <ErrorPage
          title={'Error'}
          content={
            'The token link you have provided has expired or is invalid!'
          }
        ></ErrorPage>
      ) : success ? (
        <ThankYouComponent
          title={'Password reset completed successfully!'}
          content={
            <>
              If your account has been activated, you may now{' '}
              <Link to={PAGE.LOGIN} className={style.link}>
                Log in
              </Link>
            </>
          }
        />
      ) : (
        <>
          <HeaderLogo></HeaderLogo>
          <div className={`${style.formBox} ${style.position}`}>
            <div className={style.iconHeader}>
              <img src="/images/icon-open.png" alt="" />
            </div>
            <Typography className={`${style.labelContent} ${classes.title}`}>
              New password
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={style.formSubmit}
            >
              <InputField
                {...methodsForm}
                type="password"
                label={'New Password'}
                name={'password1'}
                maxLength={MAX_LENGTH.PASSWORD}
                validation={{
                  required: true,
                  minLength: {
                    value: 8,
                    message: 'New Password must have at least 8 characters',
                  },
                  pattern: {
                    value: REGEX_PASSWORD,
                    message:
                      'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
                  },
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const password = e.target.value;
                  const confirmPassword = getValues('password2');
                  setValue('password1', password, {
                    shouldValidate: true,
                  });

                  if (
                    confirmPassword?.length >= MIN_LENGTH.PASSWORD &&
                    password?.length >= MIN_LENGTH.PASSWORD
                  )
                    if (password !== confirmPassword) {
                      setError('password2', {
                        type: 'pattern',
                        message: 'The password confirmation does not match',
                      });
                    } else {
                      clearErrors('password2');
                    }
                }}
              />
              <InputField
                {...methodsForm}
                type="password"
                label={'Confirm Password'}
                name={'password2'}
                maxLength={MAX_LENGTH.PASSWORD}
                modeLogin
                validation={{
                  required: true,
                  validate: {
                    match: (value) =>
                      value === password.current ||
                      'The password confirmation does not match',
                  },
                }}
              />
              <div className={classes.display}>
                <ButtonCommon
                  type="submit"
                  disabled={!isValid || !isDirty}
                  isLoading={isLoading}
                >
                  Submit
                </ButtonCommon>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
export default NewPasswordComponent;
