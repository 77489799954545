/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import _, { debounce } from 'lodash';

import * as tradeAction from 'src/store/actions/trade';
import * as marketActions from 'src/store/actions/market';
import { marketService } from 'src/services/MarketService';
import { accountService } from 'src/services/AccountService';
import { countDecimals, isNumeric } from 'src/utils/number';

import usePrevious from 'src/hooks/usePrevious';
import AppInputNumber from 'src/components/Base/AppInputNumber';
import ModalBestRate from 'src/routing/TradingPage/ModalBestRate';
import GroupSelect from 'src/components/GroupSelect/index';
import ModalSignUp from '../ModalSignUp';
import Select from 'src/components/Base/Select';
import { showModalMessage } from 'src/store/actions/message';
import { ModalMessageType } from 'src/store/reducers/message';
import AppLoading from 'src/components/Base/AppLoading';

import {
  PERCENT_TRADE,
  ORDER_TYPES,
  ORDER_TYPE,
} from 'src/commons/constants/Constants';

import useStyles from './styles';
import { useSetState } from 'react-use';
import { validationAmount } from 'src/utils/validation';

interface iTradeEntryInput {
  step: string;
  min: string;
  max: string;
  value: string;
  symbol: string;
  isError: boolean;
  msgErr: string;
}

interface IModalData {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  data: any | undefined;
  loading?: boolean;
}

const TradingEntry = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const TRADE_TYPE = ['buy', 'sell'];
  const { symbol } = props;
  const prevData = usePrevious({ symbol });

  const [activePercent, setActivePercent] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const [baseBalance, setBaseBalance] = useState<any>({});
  const [quoteBalance, setQuoteBalance] = useState<any>({});
  const [balances, setBalances] = useState<any>();
  const [baseInput, setBaseInput] = useSetState<iTradeEntryInput>();
  const [quoteInput, setQuoteInput] = useSetState<iTradeEntryInput>();
  const [orderType, setOrderType] = useState(ORDER_TYPE.BEST_RATE);
  const [tradeData, setTradeData] = useState<any>({});
  const [modalData, setModalData] = useState<IModalData>({
    isOpen: false,
    handleClose: () => { },
    handleSubmit: () => { },
    data: null,
  });

  const [baseInputValid, setBaseInputValid] = useState<any>(null);
  const [quoteInputValid, setQuoteInputValid] = useState<any>(null);
  const [isErrorPrice, setErrorPrice] = useState('');
  const [priceLimit, setPriceLimit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [priceData, setPriceData] = useState<any>(null);

  const marketState = useSelector((state: any) => {
    return state.marketReducer;
  });

  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const allLimit = marketState?.allLimit;

  const setPair = async () => {
    setLoading(true);
    try {
      if (symbol !== '' && symbol !== prevData?.symbol) {
        const baseCoin = symbol.split('/')[0];
        setBaseInput({
          ...baseInput,
          symbol: baseCoin,
        });
        const quoteCoin = symbol.split('/')[1];
        setQuoteInput({
          ...quoteInput,
          symbol: quoteCoin,
        });
        await getAllLimit();
        await refreshBalance(baseCoin, quoteCoin);
        await setBaseInputValid('');
        await setQuoteInputValid('');
        await getPriceData();
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPair();
  }, [symbol]);

  useEffect(() => {
    onResetChangeTab();
  }, [activeTab]);

  const getAllLimit = async () => {
    const allLimit = marketState.allLimit;
    if (_.isEmpty(allLimit)) {
      await dispatch(marketActions.getAllLimit());
    }
  };

  const getBalances = async () => {
    try {
      const resultData = await accountService.getBalances();

      // construct object map of asset to balances
      let bal_map: any = {};
      let balances = [];

      if (resultData && resultData.data && resultData.data.balances) {
        balances = resultData.data.balances.map((bal_ob: any) => {
          const obj = {
            symbol: bal_ob.asset,
            value: bal_ob.free,
          };
          let free = bal_ob['free'];
          let used = bal_ob['used'];
          let total = bal_ob['total'];
          bal_map[bal_ob['asset']] = {
            free: free,
            used: used,
            total: total,
          };

          return obj;
        });
        setBalances(bal_map);
      }
      return balances;
    } catch (error) { }
  };

  const refreshBalance = async (buyCoin: string, payCoin: string) => {
    const balances = await getBalances();
    // set Available value buy coin
    try {
      if (balances && balances.length > 0) {
        let bBalance = balances.find((x: any) => x.symbol === buyCoin);
        await setBaseBalance(bBalance?.value);
        await setBaseInput({
          ...baseInput,
          symbol: bBalance?.symbol,
          value: '',
        });

        // set Available value pay coin
        let pBalance = balances.find((x: any) => x.symbol === payCoin);
        await setQuoteBalance(pBalance?.value);
        await setQuoteInput({
          ...quoteInput,
          symbol: pBalance?.symbol,
          value: '',
        });
      }
    } catch { }
    finally {
      setLoading(false);
    }
  };

  const handleOpenModalBestRate = () => {
    if (_.isEmpty(tradeData)) return;
    if (enableButton()) return;
    setModalData({
      isOpen: true,
      handleClose: hanleCloseModalBestRate,
      handleSubmit: handleSubmitBestRate,
      data: tradeData,
    });
  };

  const hanleCloseModalBestRate = () => {
    if (_.isEmpty(tradeData)) return;
    setModalData({
      isOpen: false,
      handleClose: hanleCloseModalBestRate,
      handleSubmit: handleSubmitBestRate,
      data: tradeData,
    });
  };

  const handleSubmitBestRate = async () => {
    setModalData({
      isOpen: false,
      handleClose: hanleCloseModalBestRate,
      handleSubmit: hanleCloseModalBestRate,
      data: tradeData,
    });
    await handleTrade();
  };

  const handleTrade = async () => {
    let message: ModalMessageType = {
      type: 'ERROR',
      msg: 'Server error',
    };
    try {
      const execData = await tradeAction.addOrder(tradeData, orderType);
      if (execData && execData.data) {
        setActivePercent(0);
        await refreshBalance(baseInput?.symbol, quoteInput?.symbol);
        props.handleRefreshOrder();
      }
    } catch (error: any) {
      const exMsg = error?.response?.data?.exception;
      message.msg = exMsg ?? message.msg;
      dispatch(showModalMessage(message));
    }
  };

  const onResetChangeTab = async () => {
    await resetTradeInfos();
    if (orderType === ORDER_TYPE.LIMIT) {
      await getPriceLimit();
    }
  };

  const resetTradeInfos = async () => {
    await setBaseInput({
      ...baseInput,
      value: '',
    });

    await setQuoteInput({
      ...quoteInput,
      value: '',
    });

    if (activePercent !== 0) {
      await setActivePercent(0);
    }
    await setBaseInputValid('');
    await setQuoteInputValid('');
  };

  const onChangeActivePercent = async (value: any) => {
    let amount;

    // when remove active percent
    if (activePercent === value) {
      setActivePercent(0);
      resetTradeInfos();
      return;
    }
    setActivePercent(value);

    const baseLimit = allLimit[baseInput.symbol];
    const baseBalance = balances[baseInput.symbol];
    const quoteBalance = balances[quoteInput.symbol];
    const decimals = baseLimit['amount_precision'];

    // when active tab is sell
    if (TRADE_TYPE[activeTab] === 'sell') {
      amount = Number((Number(baseBalance['free']) * value) / 100);
      if (countDecimals(Number(amount)) > decimals) {
        amount = Number(amount).toFixed(decimals);
      }
      await onChangeBuyAmount(String(amount));
      return;
    }

    // when active tab is buy
    let hasBalanceQuote = isNumeric(quoteBalance['free'], true);
    if (!hasBalanceQuote || (hasBalanceQuote && Number(quoteBalance['free']) === 0)) {
      resetTradeInfos();
      return;
    }

    // if order type limit
    if (orderType === ORDER_TYPE.LIMIT) {
      let getPriceNum = isNumeric(String(priceLimit), true);
      if (!getPriceNum || (getPriceNum && Number(getPriceNum) === 0)) {
        resetTradeInfos();
      } else {
        let price = parseFloat(String(priceLimit).replace(/,/g, ''));
        const getPercentQuote = (Number(quoteBalance['free']) * value) / 100;
        amount = Number(getPercentQuote / Number(price));
        if (countDecimals(Number(amount)) > decimals) {
          amount = Number(amount).toFixed(decimals);
        }
        await onChangeBuyAmount(String(amount));
      }
      return;
    }

    // get price of pair
    const bodyData = {
      symbol: `${baseInput.symbol}/${quoteInput.symbol}`,
      proceeds: baseLimit.min,
      side: TRADE_TYPE[activeTab],
    };

    try {
      // calculate percent of quote balance and get amount buy coin
      const priceAmount = await marketService.getPricing(bodyData);
      const getPercentQuote = (Number(quoteBalance['free']) * value) / 100;
      amount = Number(getPercentQuote / Number(priceAmount.data['price']));
      if (countDecimals(Number(amount)) > decimals) {
        amount = Number(amount).toFixed(decimals);
        await onChangeBuyAmount(amount);
      }
    } catch (error) {
      await resetTradeInfos();
    }
  };

  const debounceChangeBuyAmount = debounce(async (nextValue) => {
    await setBaseInputValid('');
    await onChangeBuyAmount(nextValue)
  }, 0);

  const onChangeInputBuyAmount = async (e: any) => {
    await debounceChangeBuyAmount(e.target.value);
    if (activePercent !== 0) {
      await setActivePercent(0);
    }
  };

  const onChangeBuyAmount = async (value: string) => {

    // validation number
    if (!isNumeric(value, true)) {
      await setBaseInput({
        ...baseInput,
        value: value,
      });
      await setQuoteInput({
        ...quoteInput,
        value: ''
      });
      setBaseInputValid('');
      return;
    }

    // if don't change value
    if (Number(value) === Number(baseInput.value)) {
      return;
    }

    // order type is limit
    if (orderType === ORDER_TYPE.LIMIT) {

      // validation input
      let val = parseFloat(value.replace(/,/g, ''));
      const baseCoinLimit = allLimit[baseInput.symbol];
      const hasError = validationAmount(String(val), baseCoinLimit);
      if (hasError !== '') {
        await setBaseInput({
          ...baseInput,
          value: value
        });
        await setQuoteInput({
          ...quoteInput,
          value: ''
        });
        await setBaseInputValid(hasError);
        await setQuoteInputValid('');
        return;
      }

      await setBaseInputValid('');
      await setQuoteInputValid('');

      let tradeInfo = tradeData;
      await setBaseInput({
        ...baseInput,
        value: value,
      });

      // check price limit
      if (!priceLimit || !isNumeric(String(priceLimit), true)) {
        await setBaseInput({
          ...baseInput,
          value: value,
        });
        await setQuoteInput({
          ...quoteInput,
          value: '',
        });
        return;
      }

      // calculate quote coin
      const quoteCoinLimit = allLimit[quoteInput.symbol];
      const quoteDecimals = quoteCoinLimit['amount_precision'];
      let price = parseFloat(String(priceLimit).replace(/,/g, ''));
      let amount;
      amount = Number(val * Number(price));
      if (countDecimals(Number(amount)) > quoteDecimals) {
        amount = Number(amount).toFixed(quoteDecimals);
      }
      setQuoteInput({
        ...quoteInput,
        value: String(amount),
      });

      // set trade data
      if (activeTab === 0) {
        // buy
        tradeInfo.proceeds = val;
        tradeInfo.cost = amount;
      } else {
        // sell
        tradeInfo.cost = val;
        tradeInfo.proceeds = amount;
      }
      setTradeData(tradeInfo);
      return;
    }

    await setBaseInput({
      ...baseInput,
      value: value,
    });
    let num = parseFloat(value.replace(/,/g, ''));
    await calculateDataPrice('buy', String(num));
  };

  const debounceChangePayAmount = debounce(async (nextValue) => {
    await setQuoteInputValid('');
    await onChangePayAmount(nextValue)
  }, 0);

  const onChangeInputPayAmount = async (e: any) => {
    await debounceChangePayAmount(e.target.value);

    // await onChangePayAmount(e.target.value);
    if (activePercent !== 0) {
      await setActivePercent(0);
    }
  };

  const onChangePayAmount = async (value: string) => {

    // validation number
    if (!isNumeric(value, true)) {
      await setBaseInput({
        ...baseInput,
        value: '',
      });
      await setQuoteInput({
        ...quoteInput,
        value: value
      });
      await setBaseInputValid('');
      await setQuoteInputValid('');
      return;
    }

    // if don't change value
    if (Number(value) === Number(quoteInput.value)) {
      return;
    }

    // order type is limit
    if (orderType === ORDER_TYPE.LIMIT) {
      const quoteLimit = allLimit[quoteInput.symbol];
      let valChange = parseFloat(value.replace(/,/g, ''));
      const hasError = validationAmount(String(valChange), quoteLimit);
      if (hasError !== '') {
        await setQuoteInput({
          ...quoteInput,
          value: value
        });

        await setBaseInput({
          ...baseInput,
          value: ''
        });
        await setBaseInputValid('');
        await setQuoteInputValid(hasError);
        return;
      }

      await setBaseInputValid('');
      await setQuoteInputValid('');

      let tradeInfo = tradeData;
      await setQuoteInput({
        ...quoteInput,
        value: value,
      });

      // check price limit
      if (!priceLimit || !isNumeric(String(priceLimit), true)) {
        await setBaseInput({
          ...baseInput,
          value: '',
        });
        await setQuoteInput({
          ...quoteInput,
          value: value,
        });
        return;
      }

      // calculate base coin
      const baseCoinLimit = allLimit[baseInput.symbol];
      const baseCoinDecimals = baseCoinLimit['amount_precision'];
      let val = parseFloat(value.replace(/,/g, ''));
      let price = parseFloat(String(priceLimit).replace(/,/g, ''));
      let amount;
      amount = Number(val / Number(price));
      if (countDecimals(Number(amount)) > baseCoinDecimals) {
        amount = Number(amount).toFixed(baseCoinDecimals);
      }

      if (parseFloat(String(amount).replace(/,/g, '')) === 0) {
        await setBaseInput({
          ...baseInput,
          value: '',
        });
        return;
      }

      setBaseInput({
        ...baseInput,
        value: String(amount),
      });

      // set trade data
      if (activeTab === 0) {
        // buy
        tradeInfo.cost = val;
        tradeInfo.proceeds = amount;
      } else {
        // sell
        tradeInfo.proceeds = val;
        tradeInfo.cost = amount;
      }
      setTradeData(tradeInfo);
      return;
    }

    await setQuoteInput({
      ...quoteInput,
      value: value,
    });

    const num = parseFloat(value.replace(/,/g, ''));
    await calculateDataPrice('pay', String(num));
  };

  const getPriceData = async () => {
    setLoading(true);
    try {
      const bodyData = [];
      bodyData.push({
        side: 'sell',
        symbol: props.symbol,
        cost: String('1'),
      });
      bodyData.push({
        side: 'buy',
        symbol: props.symbol,
        cost: String('1'),
      })
      const resultData = await marketService.getMultiplePricing(bodyData);
      if (resultData && resultData?.data) {
        setPriceData(resultData?.data);
        return resultData?.data;
      }
    } catch (error: any) {
      setPriceData(null);
    }
    finally {
      setLoading(false);
    }
  }

  const calculateDataPrice = async (inputChange: string, amount: string) => {
    try {
      let price = priceData;
      if (!_.isArray(price)) {
        price = await getPriceData();
      }
      await setQuoteInputValid('');
      await setBaseInputValid('');

      price = price.find((x: any) => x.side === TRADE_TYPE[activeTab]);
      const baseLimit = allLimit[baseInput.symbol];
      const baseCoinDecimal = baseLimit['amount_precision'];
      const quoteCoinLimit = allLimit[quoteInput.symbol];
      const quoteCoinDecimal = quoteCoinLimit['amount_precision'];

      if (price) {

        // when change input buy
        if (inputChange === 'buy') {
          const hasError = validationAmount(amount, baseLimit);
          if (hasError !== '') {
            setBaseInputValid(hasError);
            setQuoteInput({
              ...quoteInput,
              value: ''
            });
            setTradeData(null);
            return;
          }

          let amountQuote;
          amountQuote = Number(price.price) * Number(amount);
          if (countDecimals(Number(amountQuote)) > quoteCoinDecimal) {
            amountQuote = Number(amountQuote).toFixed(quoteCoinDecimal);
          }
          setQuoteInput({
            ...quoteInput,
            value: String(amountQuote),
          });

          if (TRADE_TYPE[activeTab] === 'buy') {
            price['cost'] = String(amountQuote);
            price['proceeds'] = String(amount);
          } else {
            price['cost'] = String(amount);
            price['proceeds'] = String(amountQuote);
          }
          setTradeData(price);
          return;
        }

        // when change input pay
        if (inputChange === 'pay') {
          const hasError = validationAmount(amount, quoteCoinLimit);
          if (hasError !== '') {
            setQuoteInputValid(hasError);
            setBaseInput({
              ...baseInput,
              value: ''
            });
            setTradeData(null);
            return;
          }

          let amountBase;
          amountBase = Number(price.price_inverted) * Number(amount);
          if (countDecimals(Number(amountBase)) > baseCoinDecimal) {
            amountBase = Number(amountBase).toFixed(baseCoinDecimal);
          }
          setBaseInput({
            ...baseInput,
            value: String(amountBase),
          });
          if (TRADE_TYPE[activeTab] === 'buy') {
            price['cost'] = String(amount);
            price['proceeds'] = String(amountBase);
          } else {
            price['cost'] = String(amountBase);
            price['proceeds'] = String(amount);
          }
          setTradeData(price);
          return;
        }
      }
    } catch (error: any) {
      let exception: any = error?.response?.data?.exception;
      if (_.isObject(error?.response?.data?.exception)) {
        const keys = Object.keys(error?.response?.data?.exception)[0];
        exception = exception[`${keys}`];
      } else if (_.isString(error?.response?.data?.exception)) {
        exception = error?.response?.data?.exception;
      }

      // when change input buy
      if (inputChange === 'buy') {
        const msg = exception ?? "Server error";
        setBaseInputValid(msg);
        setQuoteInput({
          ...quoteInput,
          value: '',
        });
      }

      // when change input pay
      if (inputChange === 'pay') {
        const msg = exception ?? "Server error";
        setQuoteInputValid(msg);
        setBaseInput({
          ...baseInput,
          value: '',
        });
      }
      setTradeData(null);
    }
  }

  const enableButton = () => {
    if (!tradeData) {
      return true;
    }

    if (Object.keys(tradeData).length === 0 && tradeData.constructor === Object) {
      return true;
    }

    if (!isNumeric(quoteInput.value, true) || !isNumeric(baseInput.value, true)) {
      return true;
    }
    return false;
  };

  const spinnerBuyAmount = async (spinner: string) => {
    const baseCoin = baseInput;
    const baseCoinLimit = allLimit[baseCoin.symbol];
    const decimals = baseCoinLimit['amount_precision'];
    let value;

    // when empty
    if (!isNumeric(baseCoin.value, true)) {
      value = 0;
    } else {
      value = parseFloat(baseCoin.value.replace(/,/g, ''));
    }

    // increment or decrement
    if (spinner === 'in') {
      value = value + Number(baseCoinLimit.min);
    } else {
      value = value - Number(baseCoinLimit.min);
    }

    // limit min and max of value
    if (value > baseCoinLimit.max || value < baseCoinLimit.min) return;
    if (countDecimals(Number(value)) > decimals) {
      value = Number(value).toFixed(decimals);
    }
    await onChangeBuyAmount(String(value));

    if (activePercent !== 0) {
      await setActivePercent(0);
    }
  };

  const spinnerPayAmount = async (spinner: string) => {
    const quoteCoin = quoteInput;
    const quoteCoinLimit = allLimit[quoteCoin.symbol];
    const decimals = quoteCoinLimit['amount_precision'];
    let value;

    // when empty
    if (!isNumeric(quoteCoin.value, true)) {
      value = 0;
    } else {
      value = parseFloat(quoteCoin.value.replace(/,/g, ''))
    }

    // increment or decrement
    if (spinner === 'in') {
      value = value + Number(quoteCoinLimit.min);
    } else {
      value = value - Number(quoteCoinLimit.min);
    }

    // limit min and max of value
    if (value > quoteCoinLimit.max || value < quoteCoinLimit.min) return;
    if (countDecimals(Number(value)) > decimals) {
      value = Number(value).toFixed(decimals);
    }
    await onChangePayAmount(String(value));
    if (activePercent !== 0) {
      await setActivePercent(0);
    }
  };

  const onChangePriceLimit = async (event: any) => {
    let value = event.target.value;
    setPriceLimit(value);
    await setQuoteInputValid('');
    await setBaseInputValid('');

    // check price limit is number
    if (!isNumeric(value, true)) {
      await setQuoteInput({
        ...quoteInput,
        value: '',
      });
      return;
    }

    // get value of buy amount
    let amountBuy;
    if (!isNumeric(baseInput.value, true)) {
      amountBuy = 0;
    } else {
      amountBuy = parseFloat(baseInput.value.replace(/,/g, ''));
    }

    // calculate quote coin
    const quoteCoinLimit = allLimit[quoteInput.symbol];
    const quoteDecimals = quoteCoinLimit['amount_precision'];
    let val = parseFloat(value.replace(/,/g, ''));
    if (val === 0) {
      setQuoteInput({
        ...quoteInput,
        value: '',
      });
    } else {
      let amount;
      amount = Number(amountBuy * Number(val));
      if (countDecimals(Number(amount)) > quoteDecimals) {
        amount = Number(amount).toFixed(quoteDecimals);
      }

      if (parseFloat(String(amount).replace(/,/g, '')) === 0) {
        return;
      }

      setQuoteInput({
        ...quoteInput,
        value: String(amount),
      });

      let tradeInfo = tradeData;

      // set trade data
      if (activeTab === 0) {
        // buy
        tradeInfo.proceeds = amountBuy;
        tradeInfo.cost = amount;
      } else {
        // sell
        tradeInfo.cost = amountBuy;
        tradeInfo.proceeds = amount;
      }
      setTradeData(tradeInfo);
    }
  };

  const handleChangeOrder = async (value: any) => {
    const order = ORDER_TYPES.find((x) => x.value === value)?.label;

    // if order is limit
    if (order === ORDER_TYPE.LIMIT) {
      await getPriceLimit();
    } else {
      await getPriceData();
    }
    await setOrderType(order ?? '');
    await resetTradeInfos();
  };

  const getPriceLimit = async () => {
    setLoading(true);
    const baseLimit = allLimit[baseInput.symbol];
    let bodyData;

    // buy
    if (activeTab === 0) {
      bodyData = {
        symbol: `${baseInput.symbol}/${quoteInput.symbol}`,
        side: TRADE_TYPE[activeTab],
        proceeds: baseLimit.min,
      };
    } else {
      bodyData = {
        symbol: `${baseInput.symbol}/${quoteInput.symbol}`,
        side: TRADE_TYPE[activeTab],
        cost: baseLimit.min,
      };
    }

    try {
      const tradeDataPrice = await marketService.getPricing(bodyData);
      if (tradeDataPrice && tradeDataPrice?.data) {
        setTradeData(tradeDataPrice?.data);
        setPriceLimit(tradeDataPrice?.data?.price);
      }
    } catch (error) {
      await resetTradeInfos();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!isLogged && (
        <div className={styles.overlay}>
          <div className={styles.content}>
            <Typography className={styles.lable}>
              To use all functionality please Sign up
            </Typography>
            <Button className={styles.btnSignUp} onClick={() => setOpen(true)}>
              Sign up
            </Button>
          </div>
        </div>
      )}

      {isOpen && (
        <ModalSignUp isOpen={isOpen} handleClose={() => setOpen(false)} />
      )}

      {loading && (
        <div className={styles.styleLoading}>
          <AppLoading />
        </div>
      )}
      {!loading && (
        <div className={styles.tradingEntry}>
          <div className={styles.groupBtn}>
            <Button
              variant="contained"
              className={`${styles.btn} ${activeTab === 0 && styles.activeTab}`}
              onClick={() => setActiveTab(0)}
            >
              Buy
            </Button>
            <Button
              variant="contained"
              className={`${styles.btn} ${styles.btnSell} ${activeTab === 1 && styles.activeTabSell}`}
              onClick={() => setActiveTab(1)}
            >
              Sell
            </Button>
          </div>

          <div className={styles.groupPrice}>
            {orderType === ORDER_TYPE.LIMIT && (
              <AppInputNumber
                spinner={false}
                container_classes={`${styles.inputAmountContainer} ${isErrorPrice !== '' && styles.borderError}`}
                input_classes={`${styles.inputAmount}`}
                value={priceLimit}
                onChange={(values) => {
                  onChangePriceLimit && onChangePriceLimit(values);
                }}
                style={{
                  display: orderType === ORDER_TYPE.LIMIT ? 'block' : 'none',
                }}
              />
            )}

            <Select
              options={ORDER_TYPES}
              className={styles.selectOrderType}
              inputClassName={styles.selectInput}
              placeholder={'Order type'}
              onChange={handleChangeOrder}
              value={ORDER_TYPES.find((x) => x.label === orderType)?.value ?? 0}
            ></Select>
          </div>

          <GroupSelect
            precision={allLimit[baseInput?.symbol]?.amount_precision}
            value={baseInput.value}
            available={baseBalance}
            defaultValueSelect={baseInput.symbol}
            errMsg={baseInputValid}
            placeholder={`${activeTab === 1 ? 'Amount to sell' : 'Amount to buy'}`}
            onChange={(e: any) => onChangeInputBuyAmount(e)}
            disabledSelect={true}
            increase={() => spinnerBuyAmount('in')}
            decrease={() => spinnerBuyAmount('dec')}
            max={allLimit[baseInput?.symbol]?.max}
          />
          {baseInputValid !== '' ? (
            <span className={styles.colorEror}>{baseInputValid}</span>) : (<></>)}

          <GroupSelect
            precision={allLimit[quoteInput?.symbol]?.amount_precision}
            value={quoteInput.value}
            available={quoteBalance}
            defaultValueSelect={quoteInput.symbol}
            placeholder={`${activeTab === 1 ? 'Amount to get' : 'Amount to pay'}`}
            disabledSelect={true}
            onChange={(e: any) => onChangeInputPayAmount(e)}
            increase={() => spinnerPayAmount('in')}
            decrease={() => spinnerPayAmount('dec')}
            max={allLimit[quoteInput?.symbol]?.max}
            errMsg={quoteInputValid}
          />
          {quoteInputValid !== '' ? (
            <span className={styles.colorEror}>{quoteInputValid}</span>) : (<></>)}

          <div className={styles.listPercents}>
            {PERCENT_TRADE.map((item: any, index: number) => {
              return (
                <Button
                  key={index}
                  variant="contained"
                  onClick={() => onChangeActivePercent(item)}
                  className={`${styles.btnPercents} ${item === activePercent && styles.activePercent}`}
                >
                  {item}%
                </Button>
              );
            })}
          </div>
          <Button
            variant="contained"
            className={`${styles.btnTrade}`}
            onClick={handleOpenModalBestRate}
            disabled={enableButton()}
            classes={{ disabled: styles.btnDisable }}
          >
            Trade
          </Button>

          <ModalBestRate
            isOpen={modalData.isOpen}
            data={modalData.data}
            handleClose={modalData.handleClose}
            handleSubmit={modalData.handleSubmit}
            loading={loading}
          />
        </div>
      )}
    </>
  );
};

export default TradingEntry;
