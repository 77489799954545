import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainModalDeposit: {
      "& .body-container": {
        width: "fit-content",
        margin: "auto",

        "& .label": {
          marginTop: "-5px",
          fontSize: "20px",
          lineHeight: "23px",
          color: theme.custom.colors.titleTrading,
          "@media (max-width: 767px)": {
            textAlign: "center",
          },
        },
        "& .title": {
          color: theme.custom.colors.white4,
        },
        "& .items-container": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px -10px 0 -10px",
          "@media (max-width: 767px)": {
            flexDirection: "column",
          },

          "& .items": {
            border: `1px solid ${theme.custom.colors.white2}`,
            padding: "30px 58px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px",
            fontSize: "18px",
            lineHeight: "140%",
            cursor: "pointer",

            "& .icon-cash:before, .icon-crypto:before": {
              fontSize: "100px",
              cursor: "pointer",
            },
          },
        },
      },

      "& .modal-container": {
        [theme.breakpoints.down('sm')]: {
          maxWidth: 454,
          padding: 20
        },
        [theme.breakpoints.down('xs')]: {
          width: 335,
          padding: 20
        },
        "& .body-modal-container": {
          padding: "0 118px",

          [theme.breakpoints.between('900', '1200')]: {
            padding: "0 !important",
          },

          [theme.breakpoints.down('sm')]: {
            padding: "0 !important",
          },
        },
      },
    },
  };
});

export default useStyles;