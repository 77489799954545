import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    mainChart: {
      background: theme.custom.colors.bgrButton,
      borderRadius: 8,
      padding: '24px 10px 55px',
      marginTop: 45,
      '& .MuiInputBase-root': {
        border: '1px solid #606983 !important',
        paddingTop: 3,
        marginTop: 0,
      },

      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        maxWidth: 480,
      },
    },

    topHeader: {
      margin: '20px 10px 20px 10px',

      [theme.breakpoints.between(1440, 1921)]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },

    display: {
      display: 'flex',
    },

    colorSell: {
      background: '#B8A7FF !important',
      marginLeft: 10,
    },

    legend: {
      display: 'flex',
      marginRight: 15,
      '& span': {
        display: 'block',
        width: 20,
        height: 20,
        background: '#7F63F4',
        borderRadius: '2px',
      },
      '& p': {
        margin: 0,
        paddingLeft: 10,
        color: theme.custom.colors.titleTrading,
      },
    },

    selectGroup: {
      display: 'flex',
      marginTop: 20,
      [theme.breakpoints.down('md')]: {},
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        flexWrap: 'wrap',
        '& .MuiSelect-root': {
          background: theme.custom.colors.bgrSelect,
          borderRadius: 6,
        },

        '& .MuiButtonBase-root': {
          background: theme.custom.colors.bgrSelect,
          borderRadius: 6,
        },

        '& .MuiInputBase-root': {
          borderColor: theme.custom.colors.fieldPlaceNomal,
        },
      },
    },

    inputItem: {},

    listMonth: {
      width: 140,
      marginLeft: 20,
      marginRight: 20,
    },

    listMonthInput: {
      height: 44,
      borderColor: '#606983',
    },

    emptyStyle: {
      height: 'calc(100% - 305px) !important',
      [theme.breakpoints.down('xl')]: {
        height: 'auto !important',
      },
      marginBottom: 92,
    },
  };
});
export default useStyles;
