import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Button,
  List,
  Link as MuiLink,
} from '@material-ui/core';
import Link from 'src/components/Base/Link';

import clsx from 'clsx';
import useStyles from './styles';
import { activeMenu, handleTrade, PAGE } from 'src/utils/utils';

const listMenuDef = [
  {
    id: 1,
    name: 'Trade',
    url: '/trade',
  },
  {
    id: 2,
    name: 'Liquidity',
    section: 'liquidity',
  },
  {
    id: 3,
    name: 'Settlement',
    section: 'settlement',
  },
  {
    id: 4,
    name: 'API',
    section: 'api',
  },
  {
    id: 5,
    name: 'Use cases',
    url: '/usecase',
  },
  {
    id: 6,
    name: 'Team',
    url: '/team',
  },
];
const HeaderText = (props: any) => {
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const { drawerOpen, drawerToggle } = props;
  const classes = useStyles();
  const [listMenu] = useState(listMenuDef);
  let history = useHistory();
  const handleSignUp = () => {
    history.push(PAGE.SIGNUP);
  };
  const handleLogin = () => {
    history.push(PAGE.LOGIN);
  };

  return (
    <AppBar
      className={clsx`${classes.appBar} ${
        drawerOpen ? `${classes.borderMenu}` : ''
      }`}
    >
      <Toolbar className={classes.toolbarHeader}>
        <Hidden mdUp>
          <Typography
            variant="h6"
            className={`${classes.titlePage} ${
              drawerOpen ? `${classes.centerLogo}` : ''
            }`}
          >
            <Link href="/" className={classes.logoMobile}>
              <img src="/images/logo.png" alt="" />
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={drawerToggle}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
          >
            <span className={`${classes.menuIcon} icon-toggle-nav`}></span>
          </IconButton>
          {drawerOpen ? (
            <Button
              variant="contained"
              onClick={drawerToggle}
              className={classes.btnToggleMode}
            >
              <span className="icon-close2"></span>
            </Button>
          ) : (
            <></>
          )}
        </Hidden>

        <Hidden smDown>
          <div className={classes.boxLogo}>
            <Link href="/" className={classes.logoTrading}>
              <img src="/images/logo.png" alt="" />
            </Link>
          </div>

          <List className={classes.listMenuTrading}>
            {listMenu?.map((item) =>
              item.section ? (
                <MuiLink
                  key={item.id}
                  href={`/#${item.section}`}
                  className={`${classes.itemMenuTrading} ${activeMenu(
                    item,
                    classes.itemMenuTradingActive
                  )}`}
                >
                  {item.name}
                </MuiLink>
              ) : (
                <Link
                  key={item.id}
                  href={item.url}
                  className={`${classes.itemMenuTrading} ${activeMenu(
                    item,
                    classes.itemMenuTradingActive
                  )}`}
                >
                  {item.name}
                </Link>
              )
            )}
          </List>

          <div className={classes.headerMain}>
            {isLogged === null ? (
              <></>
            ) : isLogged ? (
              <Button
                variant="contained"
                className={classes.btnSignup}
                onClick={handleTrade}
              >
                Trade
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  className={classes.btnLogin}
                  onClick={handleLogin}
                >
                  Log in
                </Button>
                <Button
                  variant="contained"
                  className={classes.btnSignup}
                  onClick={handleSignUp}
                >
                  Sign up
                </Button>
              </>
            )}
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderText;
