import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentCoinInfo: {
      marginTop: 40,
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      padding: 20,

      [theme.breakpoints.down('lg')]: {
        marginTop: 20,
      },
    },

    nameCoin: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 24,
      lineHeight: '28px',
      color: theme.custom.colors.titleTrading,
      marginBottom: 20,

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        lineHeight: '140%x',
      },
    },

    listContent: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    itemInfo: {
      marginRight: 66,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      marginBottom: 16,
      fontFamily: 'Ubuntu-Regular',


      [theme.breakpoints.down('sm')]: {
        marginRight: 30,
      },
    },

    nameItem: {
      fontSize: 14,
      lineHeight: '140%',
      color: theme.custom.colors.titleTrading,
      fontFamily: 'Ubuntu-Regular',
    },

    valueItem: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '18px',
      color: theme.custom.colors.titleTrading,
      display: "flex",
      flexWrap: "wrap",
      fontFamily: 'Ubuntu-Medium',

      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '140%',
      },
    },

    iconCopy: {
      marginLeft: 25,
      fontSize: 18,
      cursor: 'pointer',

      '&:before': {
        color: theme.custom.colors.titleTrading,
      },
    },

    listBtn: {
      display: 'flex',
      flexWrap: 'wrap',
      "& .Mui-disabled": {
        background: theme.custom.colors.bgrBtnDisable,
        color: theme.custom.colors.btnTitle,
        " & .icon-settings:before": {
        color: theme.custom.colors.btnTitle,
        }
      }
    },
    btnDisable: {
      minWidth: 126,
      height: 36,
      borderRadius: 6,
      boxShadow: 'none',
      textTransform: 'none',
      fontFamily: 'Ubuntu-Bold',
      fontSize: 12,
      color: '#FFF',
      background: theme.custom.colors.btnDisable,
      marginRight: 20,
      marginBottom: 10,
      '& .MuiButton-label': {
        color: theme.custom.colors.white1,
      }
    },
    btnInfo: {
      minWidth: 126,
      height: 36,
      borderRadius: 6,
      boxShadow: 'none',
      textTransform: 'none',
      fontFamily: "Ubuntu-Bold",
      fontSize: 14,
      color: theme.custom.colors.white1,
      background: theme.custom.colors.lightBlue,
      marginRight: 20,
      marginBottom: 10,

      '&:hover': {
        background: theme.custom.colors.cornflowerBlue,
      },

      '&:last-child': {
        marginRight: 0,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 90,
        marginRight: 12,
      },
    },
  };
});

export default useStyles;
