/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import ButtonBase from '@material-ui/core/ButtonBase';
// import InputBase from '@material-ui/core/InputBase';
// import { InputAdornment } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
import { default as useClasses } from './styles';
import useStyles from 'src/components/Base/Select/styles';
import clsx from 'clsx';

export default function AppAutoComplete({
  options,
  onChange,
  className,
  label,
  placeholder,
  defaultValue,
}: {
  options: { label: string; value: number | string }[];
  onChange: Function;
  className: string;
  placeholder: string;
  label: string;
  defaultValue: { label: string; value: number | string };
}) {
  const classes = useClasses();
  const styles = useStyles();

  const [open, setOpen] = React.useState(false);
  const [, setValue] = React.useState<{
    label: string;
    value: number | string;
  } | null>(defaultValue);

  return (
    <React.Fragment>
      <label
        className={clsx(
          styles.label,
          open ? styles.labelFocused : styles.label
        )}
      >
        {label}
      </label>
      <Autocomplete
        id="custom-input-demo"
        options={options}
        blurOnSelect
        getOptionLabel={(option) => option.label}
        onChange={(event: any, newValue: any) => {
          if (newValue) {
            setValue(newValue);
            onChange(newValue.value);
          }
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className={classes.search}>
            <span className={`icon-search ${classes.iconSearch}`}></span>
            <input
              type="text"
              className={classes.inputRoot}
              placeholder="Choose address"
              {...params.inputProps}
            />
          </div>
        )}
        noOptionsText={
          <span className={styles.placeholder}>No match found</span>
        }
        classes={{
          paper: classes.paper,
          option: classes.option,
          noOptions: classes.noOptions,
        }}
      />
    </React.Fragment>
  );
}
