import { HTTPBaseService } from 'src/request/HTTPBaseService';

export interface TransactionRequestParams {
  transaction_state?:
    | 'PENDING'
    | 'PENDING APPROVAL'
    | 'COMPLETED'
    | 'REJECTED'
    | 'EXPIRED';
  asset?: string;
  timestamp_start?: number | null;
  timestamp_end?: number | null;
  page?: number;
  page_size?: number;
  transaction_type?: string;
}

export interface TransactionResponse {
  transactions: [any];
  page: number;
  page_size: number;
  pages_total: number;
  returned: number;
  total: number;
  timestamp_start?: number;
  timestamp_end?: number;
}

export class TransactionService extends HTTPBaseService {
  private static classInstance?: TransactionService;

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new TransactionService();
    }
    return this.classInstance;
  }

  public async get(params?: TransactionRequestParams) {
    return await this.instance.post<TransactionResponse>(
      '/account/transactions/',
      params
    );
  }
}
