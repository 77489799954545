import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  common: {},

  container: {
    maxWidth: '453px',
    fontFamily: 'Ubuntu-Medium',

    '& .Mui-focused': {
      color: theme.custom.colors.white2,
    },
  },

  label: {
    color: theme.custom.colors.white2,
    position: 'static',
    transform: 'none',
    fontFamily: 'Ubuntu-Medium',
    fontSize: 14,
    fontWeight: 500,
  },

  labelFocused: {
    '$label&': {
      color: theme.custom.colors.lightPurple,
    },
  },

  helper: {
    fontSize: '12px',
  },

  selectRoot: {
    position: 'relative',
  },

  hasLabel: {
    marginTop: 4,
  },

  inputRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 16px',
    overflow: 'hidden',
    lineHeight: '20px',

    border: '1px solid',
    borderColor: theme.custom.colors.comet3,
    borderRadius: '6px',
    cursor: 'pointer',

    outline: 'none',
    fontSize: '14px',
    color: theme.custom.colors.white2,
    backgroundColor: theme.custom.colors.bgrSelect,

    '&:focus': {
      borderColor: theme.custom.colors.lightPurple,
    },
  },

  placeholder: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'Ubuntu-Regular',
    color: theme.custom.colors.santaGray,
  },

  value: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    [theme.breakpoints.between('600', '900')]: {
      width: '80%',
    },
  },

  icon: {
    top: 'unset',
    right: '14px',
    transition: '.2s',

    '&:before': {
      color: 'inherit',
    },
  },

  iconFlip: { transform: 'rotate(180deg)' },

  popover: {
    zIndex: 3,
    position: 'absolute',
    marginTop: '2px',
    width: '100%',
    maxHeight: '260px',
    overflowY: 'auto',
    border: `1px solid ${theme.custom.colors.white2}`,
    background: theme.custom.colors.cardBackground,
    boxShadow: '0px 4px 16px rgba(51, 51, 51, 0.24)',
    borderRadius: '6px',

    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: 'transparent',
      padding: '4px',
    },
    '&::-webkit-scrollbar-track': {
      borderWidth: '1px',
      borderLeftStyle: 'solid',
      backgroundColor: 'transparent',
      borderColor: theme.custom.colors.white2,
      borderRadius: '0 6px 6px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.custom.colors.white2,
      borderRadius: '34px',
    },
  },
  multiple: {
    '& $option$optionSelected': {
      backgroundColor: 'transparent',
      color: theme.custom.colors.white2,
    },
    '& $option$optionSelected:hover': {
      backgroundColor: theme.custom.colors.cornflowerBlue,
    },
  },

  option: {
    padding: '10px 16px',
    minHeight: 35,
    fontSize: 14,
    color: theme.custom.colors.white2,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.custom.colors.cornflowerBlue,
      color: '#FFFFFF',
    },
  },

  optionSelected: {
    '$option&, $option&:hover': {
      backgroundColor: theme.custom.colors.cornflowerBlue,
      color: '#FFFFFF',
    },
  },

  error: {
    '& $selectRoot': {
      borderColor: theme.custom.colors.red2,
    },
    '& $label, & $helper': {
      color: theme.custom.colors.red2,
    },
  },
  success: {},

  customTooltip: {
    backgroundColor: theme.custom.colors.cardBackground,
    boxShadow: '0px 4px 25px rgba(127, 99, 244, 0.2)',
    borderRadius: '6px',
  },
}));

export default useStyles;
