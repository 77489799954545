import { WITHDRAWAL_HIDE, WITHDRAWAL_TOGGLE } from '../constants/withdrawal';

export const WithdrawalAction = {
  toggle: (address_id?: string | null, onSuccess?: Function) => ({
    type: WITHDRAWAL_TOGGLE,
    address_id,
    onSuccess,
  }),
  show: (address_id?: string) => ({
    type: WITHDRAWAL_TOGGLE,
    address_id,
  }),
  hide: () => ({
    type: WITHDRAWAL_HIDE,
  }),
};
