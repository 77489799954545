import { useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { withRouter, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import InputField from 'src/components/BaseLandingPage/Common/Input';
import AppPhone from 'src/components/Base/AppPhone';
import ModalThanks from '../ModalThanks';
import { PAGE } from 'src/utils/utils';
import { landingService } from 'src/services/LandingService';
import { REGEX_USERNAME, REGEX, MAX_LENGTH } from 'src/utils/validation';
import ButtonCommon from 'src/components/BaseLandingPage/Common/Button';
import useStyles from './styles';

interface IForm {
  name: string;
  email: string;
  term: boolean;
  phone: string;
  company: string;
  message: string;
  token: string;
}

const ModalRequest = ({ open, setModalShow }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const methodsForm = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      message: 'Request a demo',
      name: '',
      email: '',
      term: false,
      phone: '',
      company: '',
      token: '',
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { isDirty, isValid, errors },
  } = methodsForm;

  const [openThanks, setOpenThanks] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    register('token', { required: true });
  }, [register]);

  const handleClose = () => {
    reset();
    setModalShow(false);
    register('token', { required: true });
    setValue('token', '', {
      shouldValidate: true,
    });
    (window as any)?.grecaptcha?.reset();
  };
  const onChangeCAPTCHA = (value: string | null) => {
    setValue('token', value || '', {
      shouldValidate: true,
    });
  };
  const onSubmit = (data: any) => {
    // remove phone field when blank
    if (data.hasOwnProperty('phone') && !data.phone) delete data.phone;

    setLoading(true);
    // Open sent mail
    landingService
      .postData('/contact/post_message/', data)
      .then(async (res: any) => {
        if (res.status === 200) {
          handleClose();
          setOpenThanks(true);
        }
      })
      .then(() => {
        (window as any)?.grecaptcha?.reset();
        setLoading(false);
      });
  };

  return (
    <div>
      <ModalThanks open={[openThanks, setOpenThanks]} />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: '#2C2949',
            boxShadow: 'none',
            maxWidth: '611px',
            borderRadius: '10px',
          },
        }}
        className={classes.styleDialog}
      >
        <MuiDialogTitle disableTypography className={classes.titleDialog}>
          <img src="/images/icon-demo-modal.svg" alt="" />
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.styleClose}
          >
            <img src="/images/icons/icon-close.svg" alt="" />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers className={classes.styleDialogContent}>
          <Typography variant="h4" className={classes.titleForm}>
            Contact Us form
          </Typography>
          <form className={classes.styleForm} onSubmit={handleSubmit(onSubmit)}>
            <InputField
              {...methodsForm}
              maxLength={MAX_LENGTH.USER_NAME}
              name="name"
              label="Name"
              validation={{
                required: true,
                pattern: {
                  value: REGEX_USERNAME,
                  message:
                    'Allowed characters: A-Z, a-z, apostrophe, space, dash',
                },
              }}
            />
            <InputField
              {...methodsForm}
              type="email"
              name="email"
              label="Сompany email"
              validation={{ required: true }}
            />
            <InputField
              {...methodsForm}
              type="text"
              name="company"
              label="Сompany"
              maxLength={MAX_LENGTH.COMPANY}
              validation={{
                required: true,
                maxLength: 65,
              }}
            />

            <AppPhone
              classNames={`${classes.inputItem}`}
              label="Phone Number"
              controller={{
                control,
                name: 'phone',
                rules: REGEX.phoneNumber,
              }}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              labelProps={{
                className: errors.phone ? classes.styleLabel : '',
              }}
              textPlaceholder={getValues('phone') ? '' : '| __-___-__-__'}
              inputClass={`${classes.inputPhoneNum}`}
            />
            <InputField
              {...methodsForm}
              type="text"
              name="message"
              label="Message"
              validation={{ required: true, maxLength: 255 }}
              maxLength={MAX_LENGTH.MESSAGE}
            />
            <div className={classes.checkBox}>
              <Checkbox
                {...methodsForm}
                {...register('term', {
                  required: true,
                })}
                id="term_modal"
                name="term"
                className={classes.styleCheck}
                disableRipple
                color="default"
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
              />
              <div>
                <label
                  className={
                    classes.styleLbl +
                    ' ' +
                    (errors.term ? classes.styleLblError : '')
                  }
                  htmlFor="term_modal"
                >
                  I agree to oneAlpha’s{' '}
                  <span onClick={() => history.push(PAGE.TERM)}>
                    Terms & Conditions
                  </span>{' '}
                  and{' '}
                  <span onClick={() => history.push(PAGE.PRIVACY)}>
                    Privacy Policy
                  </span>
                </label>
              </div>
            </div>

            <div className={classes.captcha}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA || ''}
                onChange={onChangeCAPTCHA}
              />
            </div>

            <div className={classes.divBtn}>
              <ButtonCommon
                className={classes.btnSubmit}
                type="submit"
                disabled={!isDirty || !isValid}
                isLoading={isLoading}
              >
                Submit
              </ButtonCommon>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withRouter(ModalRequest);
