
import { useEffect, useState } from "react";
import { titleCase } from "..";
import useStyles from "./styles";

export const Internaltional = ({
  data,
}:{
  data: any
}) => {
  const classes = useStyles();
  const [internaltionalData, setInternaltionalData] = useState<any>();

  useEffect(() => {
    const fetchDetail = () => {
      const internaltional = Object.entries(data).map(item => {
        return {
          name: titleCase(item[0]),
          value: item[1]
        }
      })
      
      setInternaltionalData(internaltional)
    }

    if (data) {
      fetchDetail();
    }
  }, [data])
  
  return (
    <div className={classes.tabDepositDomestic}>
      {
        internaltionalData && 
        internaltionalData.map((item: any, index: number) => {
          return (
            <div className={classes.itemDomestic} key={index}>
              <div className="item_key">{item.name}</div>
              <div className="item_value">{item.value}</div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Internaltional;
