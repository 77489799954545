import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_TIMEOUT_MIN } from 'src/commons/configs/SystemConfig.json';
import { KEY } from 'src/commons/constants';
import customToast from 'src/components/Base/Toast/Toast';
import configureStore from 'src/store/configureStore';
import { LOGOUT } from 'src/store/constants/authentication';

export abstract class HTTPBaseService {
  protected instance: AxiosInstance;
  protected readonly baseURL: string | undefined;

  public constructor() {
    this.baseURL = `${process.env.REACT_APP_HEROKU_PROXY}${process.env.REACT_APP_SANDBOX_API}`;
    this.instance = axios.create({ baseURL: this.baseURL });
    // axios use request interceptor
    this.initializeRequestInterceptor();

    // axios use response interceptor
    this.initializeResponseInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest);
  };

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use((response) => {
      return response;
    }, this.handleError);
  };

  private handleRequest = (config: AxiosRequestConfig) => {
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ` + localStorage.getItem('access_token'),
    };
    config.timeout = API_TIMEOUT_MIN * 60000;
    return config;
  };

  private handleError = async (error: AxiosError) => {
    // No care: handle exception authentication
    if (error.response?.status === 401) {
      //
      clearInterval((window as any)[KEY.REFRESH_TOKEN]);
      // Clear token
      localStorage.removeItem(KEY.ACCESS_TOKEN);
      localStorage.removeItem(KEY.REFRESH_TOKEN);
      // Push message when logged
      const { store } = configureStore();
      const auth = store.getState().auth;
      auth.isLogged &&
        customToast.error('Your session is expired', {
          toastId: 'unauthenticated',
        });
      // Update state
      store.dispatch({ type: LOGOUT });
    }
    //   handle exception timeout
    else if (error.response?.status === 408 || error.code === 'ECONNABORTED') {
      customToast.error('Request timeout', { toastId: 'timeout' });
    }
    // submission kyc
    else if (
      error.response?.status === 404 &&
      error.response?.config?.url === '/private/user/kyc/submissions/'
    ) {
      return;
    }
    //confirm remove 2FA device
    else if (
      error.response?.status === 200 &&
      error.response?.config?.url === '/private/2fa_devices/' &&
      error.response?.data?.result === false
    ) {
      return;
    }
    //confirm email to active 2FA device
    else if (
      error.response?.status === 400 &&
      error.response?.config?.url === '/private/2fa_devices/'
    ) {
      return;
    }
    //confirm email to change password
    else if (
      error.response?.status === 400 &&
      error.response?.config?.url === '/account/change_password/'
    ) {
      return Promise.reject(error);
    }

    // unknown error
    if (!error.response || error.response.status >= 500) {
      const message = error.response?.data?.detail || 'Something went wrong';
      customToast.error(message, { toastId: message });
    }

    return Promise.reject(error);
  };
}
