import useStyles from "./styles";

const KycStatus = ({
  status,
}:{
  status: any
}) => {
  const classes = useStyles();

  return (
    <div className={classes.kycStatusContainer}>
      {
        status === "pending" ? (
          <div className={classes.status}>
            <div className="icon-sand-timer"/>
            <div>KYC pending</div>
          </div>
        ) : (
          <div className={classes.status}>
            <div className="icon-medal"/>
            <div>KYC completed</div>
          </div>
        )
      }
      <div className={classes.desc}>
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. 
        Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </div>
    </div>
  )
}

export default KycStatus;
