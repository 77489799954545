import { Button, Typography } from '@material-ui/core';
import ModalCustom from 'src/components/Base/ModalCustom';

import { displayNumb } from 'src/utils/number';
import { TRADE_SIDE } from 'src/commons/constants/Constants';
import AppButton from 'src/components/Base/AppButton';
import useStyles from './styles';

export const ModalConfirm = ({
  isOpen,
  handleClose,
  handleOpen,
  data,
  isLoading,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  data: any;
  isLoading?: boolean;
}) => {
  const classes = useStyles();

  const renderSpan = (data: any, isTop?: boolean) => {
    const symbol = data?.symbol ?? '';
    const orderCoin = symbol.split('/')[0];
    const anchorCoin = symbol.split('/')[1];

    if (isTop) {
      return (
        <span>
          {data?.side === TRADE_SIDE.buy
            ? `${displayNumb(data?.amount)} ${orderCoin}`
            : `${displayNumb(data?.proceeds)} ${anchorCoin}`}
        </span>
      );
    }
    return (
      <span>
        {data?.side === TRADE_SIDE.buy
          ? `${displayNumb(data?.cost)} ${anchorCoin}`
          : `${displayNumb(data?.amount)} ${orderCoin}`}
      </span>
    );
  };
  return (
    <>
      <ModalCustom
        isOpen={isOpen}
        isIconClose={true}
        handleClose={handleClose}
        className={classes.mainModalConfirm}
      >
        <div className="title">
          <Typography className={classes.title}>Confirm trade</Typography>
        </div>

        <div className={classes.display}>
          <Typography className={classes.contentTitle}>
            You will receive:
          </Typography>
          <span className={classes.renderSpan}>{renderSpan(data, true)}</span>
        </div>
        <div className={classes.display}>
          <Typography className={classes.contentTitle}>
            For approximately*
          </Typography>
          <span className={classes.renderSpan}>{renderSpan(data)}</span>
        </div>
        <Typography className={classes.subTitle}>
          subject to market movement, indicate pricing
        </Typography>

        <div className={classes.display}>
          <AppButton
            className={classes.confirm}
            onClick={handleOpen}
            isLoading={isLoading}
            width="126px"
            height="36px"
          >
            Confirm
          </AppButton>
          <Button className={classes.btnCancel} onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export default ModalConfirm;
