import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import ThemeProviderCustomer from './themeProviderCustomer';
// import LoadingProvider from './components/BaseLandingPage/Context/LoadingProvider';
import './App.css';
import './scss/styles.scss';

const App = () => {
  const { store } = configureStore();

  return (
    // <LoadingProvider>
    <Provider store={store}>
      <ThemeProviderCustomer />
    </Provider>
    // </LoadingProvider>
  );
};

export default App;
