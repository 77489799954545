import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    regular: {
      borderRadius: '6px',
      color: theme.custom.colors.btnTitle,
      backgroundColor: theme.custom.colors.btnEnable,
      fontFamily: 'Ubuntu-Bold',
      textTransform: 'none',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '0 16px',
      '&:hover': {
        backgroundColor: theme.custom.colors.btnHover,
      },
    },
    primary: {},
    secondary: {
      color: theme.custom.colors.btnTitle,
      backgroundColor: theme.custom.colors.btnCancel,
    },
    disable: {
      borderRadius: '6px',
      color: theme.custom.colors.btnTitle,
      cursor: 'no-drop',
      backgroundColor: theme.custom.colors.bgDisable,
      fontFamily: 'Ubuntu-Bold',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 16px',
    },
    spiner: {
      animation: 'loadingI 2s linear infinite',
      position: 'relative',
      width: '25px',
      height: '25px',
      marginRight: '10px',
    },
    bubble1: {
      animation: 'bounce 2s ease-in-out infinite',
      position: 'absolute',
      top: 0,
      width: '15px',
      height: '15px',
      borderRadius: '100%',
      backgroundColor: theme.custom.colors.white1,
    },
    bubble2: {
      animation: 'bounce 2s ease-in-out infinite',
      animationDelay: '-1s',

      position: 'absolute',
      top: 'auto',
      bottom: 0,
      width: '15px',
      height: '15px',
      borderRadius: '100%',
      backgroundColor: theme.custom.colors.white1,
    },
  };
});

export default useStyles;
