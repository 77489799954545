/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AxiosResponse } from 'axios';

import Checkbox from 'src/components/Base/Checkbox';
import ModalCustom from 'src/components/Base/ModalCustom';
import { AddressBookAction } from 'src/store/actions/address';
import AppAutoComplete from 'src/components/Base/AutoComplete';
import { WithdrawalAction } from 'src/store/actions/withdrawal';
import { AddressBookService } from 'src/services/AddressBookService';
import {
  WithdrawalResponse,
  WithdrawalService,
} from 'src/services/WithdrawalService';
import { hideModalMessage, showModalMessage } from 'src/store/actions/message';
import AppButton from 'src/components/Base/AppButton';
// import { LoadingAction } from 'src/store/actions/loading';
import { getBalances } from 'src/store/actions/account';
import { amountWithdrawal } from 'src/utils/validation';
import { ModalConfirmAction } from 'src/store/actions/modal-confirm';
import { useAppSelector } from 'src/hooks';
import { shortenAddress, shortenTitle } from 'src/utils/func';
import AppInput from 'src/components/Base/AppInput';

import useStyles from './styles';

interface IForm {
  client_transaction_id: string;
  address_id: number;
  amount: string;
  asset: string;
  term: boolean;
  code: string;
  two_fa: string;
}

export default function WithdrawalCrypto() {
  const classes = useStyles();
  const { show, address_id, onSuccess } = useAppSelector(
    (state) => state.withdrawal
  );
  const assetSelected = useAppSelector((state) => state.asset.assetSelected);
  const balances = useSelector((state: any) => state.accountReducer.balances);
  const { isLoading, show: showModal } = useSelector(
    (state: any) => state.modalConfirm
  );
  const has2FA = useSelector(
    (state: any) => state.accountReducer.has2FADevices
  );
  const showModalConfirmCode = useSelector(
    (state: any) => state.modalConfirm.show
  );
  const showModalAddAddress = useSelector((state: any) => state.address.show);

  const [listAddress, setListAddress] = useState<
    { label: string; value: string | number }[]
  >([]);

  const defaultAddress = listAddress.filter(
    (v: any) => v.value === address_id
  )[0];

  let assetBalance = 0;

  balances.forEach((asset: any) => {
    if (asset.asset === assetSelected) {
      assetBalance = asset.free;
    }
  });

  const dispatch = useDispatch();
  const [percents, setPercents] = useState<number | null>(null);
  const {
    control,
    getValues,
    register,
    formState: { isValid, errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      term: false,
    },
  });

  // Register item require
  useEffect(() => {
    register('term', { required: true });
    register('address_id', { required: true });
  }, [register, show]);

  // Init
  useEffect(() => {
    setValue('address_id', address_id);
    if (show) {
      setValue('asset', assetSelected, {});
    } else {
      reset();
      setPercents(null);
    }
  }, [show]);

  // Get list address by asset
  useEffect(() => {
    dispatch(ModalConfirmAction.loading());
    const assetService = AddressBookService.getInstance();
    // Call api get address by asset
    assetService
      .getAddresses({
        asset: assetSelected,
        type: 'WITHDRAWAL',
        page_size: 999,
      })
      .then((res: AxiosResponse<any>) => {
        setListAddress(
          res.data.items.map((address: any) => ({
            label: `${shortenTitle(address.address_title)} - ${shortenAddress(
              address.address
            )}`,
            value: address.id,
          }))
        );
      })
      .catch(() => {
        setListAddress([]);
      })
      .then(() => {
        dispatch(ModalConfirmAction.hideLoading());
      });
  }, [assetSelected, showModalAddAddress]);

  useEffect(() => {
    setValue('code', '');
    setValue('client_transaction_id', '');
  }, [showModalConfirmCode]);

  const onSubmit = async (data: IForm) => {
    // Loading
    dispatch(ModalConfirmAction.loading());
    const withdrawalService = WithdrawalService.getInstance();

    try {
      let res: AxiosResponse<WithdrawalResponse>;
      if (!data.client_transaction_id) {
        // Auto-generation id
        data.client_transaction_id = uuidv4();
        // Call API
        res = await withdrawalService.create(data);
      } else {
        res = await withdrawalService.confirm(data);
      }

      // Checking response
      if (res.data.client_transaction_id === data.client_transaction_id) {
        checkTypeWithdraw(res.data);

        // Update balances
        dispatch(getBalances());
      } else {
        throw new Error();
      }
    } catch (error) {
      if ((error as any).response?.status === 400) {
        dispatch(
          ModalConfirmAction.setError(
            has2FA
              ? 'Email or 2FA code is incorrect. Please check and try again.'
              : 'The email code is incorrect. Please check and try again.'
          )
        );
      } else {
        dispatch(
          showModalMessage({
            type: 'ERROR',
            msg: 'System error!',
          })
        );
      }
    } finally {
      // hide loading
      dispatch(ModalConfirmAction.hideLoading());
    }
  };

  const checkTypeWithdraw = (data: any) => {
    if (data.state === 'PENDING APPROVAL') {
      dispatch(
        ModalConfirmAction.show({
          onOK: (code: string, twoFA: string) => {
            setValue('code', code);
            setValue('two_fa', twoFA);
            setValue('client_transaction_id', data.client_transaction_id);
            onSubmit(getValues());
          },
        })
      );
    } else {
      // Update balances
      dispatch(getBalances());
      // Hide modal
      dispatch(ModalConfirmAction.hide());
      dispatch(WithdrawalAction.toggle());
      // Show success
      dispatch(
        showModalMessage({
          type: 'SUCCESS',
          msg: 'Withdrawal successfully',
          onOk: () => {
            // Hide all message, modal
            dispatch(hideModalMessage());
          },
        })
      );
      // callback
      onSuccess?.();
    }
  };

  const onClickAddAddress = () => {
    dispatch(
      AddressBookAction.toggleModalAdd(
        { isWhitelist: false },
        { isWithdrawal: true }
      )
    );
  };

  return (
    <>
      <ModalCustom
        isOpen={show}
        handleClose={() => {
          dispatch(WithdrawalAction.toggle());
        }}
        className={classes.modalMain}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formHeader}>
            <div className={classes.formTitle}>
              Make a {assetSelected} withdrawal
            </div>
            <div className={classes.formDes}>
              Please check entered address carefully!
            </div>
          </div>
          <div className={classes.formItem}>
            <AppAutoComplete
              defaultValue={defaultAddress}
              options={listAddress}
              className="dropdown-box"
              label="Withdrawal address"
              placeholder="Choose address"
              onChange={(item: any) => {
                setValue('address_id', item, {
                  shouldValidate: true,
                });
              }}
            />
          </div>
          <div className={classes.formItem}>
            <Button
              variant="contained"
              onClick={() => onClickAddAddress()}
              className={`${classes.btnInfo}`}
            >
              Add new address
            </Button>
          </div>
          <div className={classes.formItem}>
            <AppInput
              classNames={classes.inputItem}
              controller={{
                control,
                name: 'amount',
                rules: {
                  required: true,
                  validate: amountWithdrawal(assetBalance),
                },
              }}
              asset={assetSelected}
              inputAmount={true}
              label={'Amount'}
              error={Boolean(errors.amount)}
              placeholder="Amount"
              helperText={errors.amount?.message}
              labelProps={{
                className: errors.amount ? classes.styleLabel : '',
              }}
            />
          </div>
          <div className={classes.formItem}>
            {[25, 50, 75, 100].map((value, index) => {
              return (
                <Button
                  key={index}
                  variant="contained"
                  className={clsx(
                    classes.btnPercents,
                    value === percents ? `active` : ''
                  )}
                  onClick={() => {
                    setPercents(value);
                    const amount = String(
                      value === 100
                        ? assetBalance
                        : (assetBalance / 100) * value
                    );
                    setValue('amount', amount, {
                      shouldValidate: true,
                    });
                  }}
                >
                  {value}%
                </Button>
              );
            })}
          </div>
          <div className={clsx(classes.formItem, classes.formControlLabel)}>
            <Checkbox
              defaultChecked={false}
              label="My withdrawal address and amount is correct"
              onChange={(checked: boolean) => {
                setValue('term', checked, {
                  shouldValidate: true,
                });
              }}
            />
          </div>
          <div className={`${classes.formItem} ${classes.styleSubmit} `}>
            <AppButton
              type="submit"
              width={'217px'}
              isDisable={!isValid}
              isLoading={isLoading && !showModal}
            >
              Submit
            </AppButton>
          </div>
        </form>
      </ModalCustom>
    </>
  );
}
