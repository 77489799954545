import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
       backgroundImage: 'url(/images/bgr.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0% 0%',
            backgroundSize: '100% 100%',
            height: '100%',
            minHeight: '100vh',
            
            [theme.breakpoints.down('sm')]: {
              backgroundImage: "url(/images/bgr_768.png)",
          
            },
            
            [theme.breakpoints.down('xs')]: {
              backgroundImage: "url(/images/bgr_375.png)",
            },
    },
    main: {
      margin: 'auto',
      padding: '0 20px',
    },
  };
});

export default useStyles;
