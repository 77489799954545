import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
    return {
        mainModalConfirm: {
            position: 'absolute',
            zIndex: 1000,
            "& .modal-container": {
                width: 335,
                padding: `20px 0`,
                "& .body-modal-container": {
                    padding: "0 20px",
                    maxHeight: '100%',
                    minHeight: '100%',
                },
            },
        },

        title: {
            fontSize: 18,
            color: theme.custom.colors.titleTrading,
            marginBottom: 8,
            fontWeight: 700,
            fontFamily: 'Ubuntu-Regular'
        },

        border: {
            height: 1,
            background: theme.custom.colors.lineTable1,
            marginBottom: 10
        },

        content: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
            "& p": {
                textTransform: 'uppercase',
                fontSize: 12,
                color: theme.custom.colors.santaGray2,
                marginBottom: 10
            },
            "& span": {
                color: theme.custom.colors.titleTrading,
                fontSize: 14,
                fontWeight: 400,
                fontFamily: 'Ubuntu-Regular'
            },

        },

        buy: {
            color: `${theme.custom.colors.green1} !important`,
            textTransform: 'capitalize',
            fontSize: 14,
            fontWeight: 400
        },

        spanAmt: {
            fontSize: 14,
            fontWeight: 400
        },

        sell: {
            color: `${theme.custom.colors.red2} !important`,
        },

        btnOK: {
            width: "82px",
            height: "36px",
            borderRadius: "6px",
            color: theme.custom.colors.white1,
            fontSize: 12,
            background: theme.custom.colors.cornflowerBlue,
            marginRight: 15,
            marginTop: 10,
            textTransform: "none",
            "&:hover": {
                background: theme.custom.colors.btnHover,
            },
        },

        display: {
            display: 'flex',
            marginBottom: 8
        }
    };
});

export default useStyles;