import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';

import AppCheckList from 'src/components/Base/AppCheckList';
import DatePicker from 'src/components/Base/AppDatePicker';
import AppInput from 'src/components/Base/AppInput';
import AppPhone from 'src/components/Base/AppPhone';
import AppRadioList from 'src/components/Base/AppRadioList';
import AppUpload from 'src/components/Base/AppUpload';

import { handleUploadDocument } from 'src/routing/KYCPage';
import { MAX_FILE_SIZE } from 'src/commons/constants';
import {
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_INPUT,
} from 'src/commons/constants/AppInputFormat';

import {
  REGEX,
  rulesTextField,
  validateDateOfBirth,
} from 'src/utils/validation';

import useStyles from './styles';

const AddIndividual = ({
  num,
  setAddIndividual,
  isDelete,
  lastErrors,
  handleDelete,
  data,
}: {
  num: number;
  data: any;
  lastErrors: any;
  setAddIndividual: any;
  isDelete?: boolean;
  handleDelete?: any;
}) => {
  const classes = useStyles();
  const [inValidStartDate, setInValid] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(
    data?.dateOfBirth ? moment(data?.dateOfBirth, 'MM/DD/YYYY').toDate() : null
  );
  const [isChange, setOnchange] = useState();

  const [documents, setDocuments] = useState(
    data?.documents || [
      {
        label: `ID Document Upload`,
        name: '',
        value: '',
        msg: '',
      },
      {
        label: `Proof of Address`,
        desc: 'Recent utility bill, bank statement. Must be dated within the last 3 months',
        name: '',
        value: '',
        msg: '',
      },
    ]
  );

  const {
    control,
    formState: { errors, isValid },
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      percentOwner: data?.percentOwner,
      fullName: data?.fullName,
      jobTitle: data?.jobTitle,
      citizenship: data?.citizenship,
      address: data?.address,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      idNumber: data?.idNumber,
      idType: data?.idType,
      individualIs: data?.individualIs,
    },
  });

  const handleChangeFile = (index: number, val: any) => {
    const name = val ? val.target.files[0]?.name : '';
    const value = val ? val.target.files[0] : '';
    documents[index] = {
      ...documents[index],
      name,
      value,
    };
    setDocuments([...documents]);

    if (value) {
      // handle file size
      if (val.target.files[0].size > MAX_FILE_SIZE) {
        documents[index] = {
          ...documents[index],
          msg: 'Maximum file size is 10 MB. Please choose another file',
        };
        setDocuments([...documents]);
        return;
      }

      handleUploadDocument({
        ...documents[index],
        label: `${documents[index]?.label} ${num}`,
      })
        .then((res: any) => {})
        .catch((err: any) => {
          if (err?.response?.status === 413) {
            documents[index] = {
              ...documents[index],
              msg: 'Maximum file size is 10 MB. Please choose another file',
            };
          } else if (err?.response?.status === 400) {
            documents[index] = {
              ...documents[index],
              msg: 'This file type is not supported. Please choose another file',
            };
          } else {
            documents[index] = {
              ...documents[index],
              msg: 'The file is corrupt. Please choose another file',
            };
          }
          setDocuments([...documents]);
        });
    }
  };

  const checkUploadFile = () => {
    return documents.findIndex((item: any) => !!item.msg || !item.value) === -1;
  };

  useEffect(() => {
    const uploadValid = checkUploadFile();
    const values = getValues();
    let obj: any = {};
    obj[`individual${num}`] = {
      percentOwner: values?.percentOwner?.trim(),
      fullName: values?.fullName?.trim(),
      jobTitle: values?.jobTitle?.trim(),
      citizenship: values?.citizenship?.trim(),
      address: values?.address?.trim(),
      phoneNumber: values?.phoneNumber?.trim(),
      email: values?.email?.trim(),
      idNumber: values?.idNumber?.trim(),
      idType: values?.idType?.trim(),
      individualIs: values?.individualIs?.trim(),
      dateOfBirth: moment(dateOfBirth).format('MM/DD/YYYY'),
      documents,
    };

    const currentData = {
      isValid: isValid && uploadValid && !inValidStartDate,
      ...obj,
    };

    setAddIndividual(currentData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, documents, dateOfBirth, isChange]);

  useEffect(() => {
    if (lastErrors) {
      setInValid(validateDateOfBirth(dateOfBirth, dateOfBirth));
      trigger();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isDelete ? (
        <div className={classes.titleContainer}>
          <div className={classes.titleItem}>{`Individual ${num}`}</div>
          <div
            className={classes.btnLink}
            onClick={handleDelete}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleDelete();
              }
            }}
            tabIndex={Math.random()}
          >
            Delete
          </div>
        </div>
      ) : (
        <div
          className={`${classes.titleItem} mt-40`}
        >{`Individual ${num}`}</div>
      )}
      <form onChange={(e: any) => setOnchange(e.target.value)}>
        <Controller
          name="individualIs"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AppCheckList
              className={classes.label}
              value={value}
              label="The below individual is"
              error={Boolean(errors.individualIs)}
              helperText={errors.individualIs?.message}
              checkLists={checkLists}
              handleCheck={onChange}
            />
          )}
          rules={rulesTextField('This field')}
        />
        <div className={`${classes.titleItem} mt-40`}>Contact information</div>
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'percentOwner',
            rules: rulesTextField('% owner'),
          }}
          inputPercent={true}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'If beneficial owner, please state % owner'}
          error={Boolean(errors.percentOwner)}
          placeholder="% owner"
          helperText={errors.percentOwner?.message}
          labelProps={{
            className: errors.percentOwner ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'fullName',
            rules: rulesTextField('Full Name'),
          }}
          label={'Full Name'}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          error={Boolean(errors.fullName)}
          placeholder="Full Name"
          helperText={errors.fullName?.message}
          labelProps={{
            className: errors.fullName ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'jobTitle',
            rules: rulesTextField('Job Title'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Job Title'}
          error={Boolean(errors.jobTitle)}
          placeholder="Job Title"
          helperText={errors.jobTitle?.message}
          labelProps={{
            className: errors.jobTitle ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'citizenship',
            rules: rulesTextField('Citizenship'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Citizenship'}
          error={Boolean(errors.citizenship)}
          placeholder="Citizenship"
          helperText={errors.citizenship?.message}
          labelProps={{
            className: errors.citizenship ? classes.styleLabel : '',
          }}
        />
        <DatePicker
          error={!!inValidStartDate}
          selected={dateOfBirth}
          classNames={classes.inputItem}
          label="Date of Birth"
          placeholderText="mm/dd/yyyy"
          clearButtonClassName={classes.btnClear}
          helperText={inValidStartDate}
          maxDate={new Date()}
          onChange={(update: any) => {
            setDateOfBirth(update);
            setInValid(validateDateOfBirth(update, dateOfBirth));
          }}
          labelProps={{
            className: inValidStartDate ? classes.labelError : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'address',
            rules: rulesTextField('Address'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Address'}
          error={Boolean(errors.address)}
          placeholder="Address"
          helperText={errors.address?.message}
          labelProps={{
            className: errors.address ? classes.styleLabel : '',
          }}
        />

        <AppPhone
          classNames={classes.inputItem}
          label="Phone Number"
          defaultCountry="af"
          controller={{
            control,
            name: 'phoneNumber',
            rules: {
              ...REGEX.phoneNumber,
              ...rulesTextField('Phone Number'),
            },
          }}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber?.message}
          labelProps={{
            className: errors.phoneNumber ? classes.styleLabel : '',
          }}
          textPlaceholder={getValues('phoneNumber') ? '' : '| __-___-__-__'}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'email',
            rules: {
              ...REGEX.email,
              ...rulesTextField('Email'),
            },
          }}
          inputProps={{ maxLength: MAX_LENGTH_EMAIL }}
          label={'Email'}
          error={Boolean(errors.email)}
          placeholder="Email"
          helperText={errors.email?.message}
          labelProps={{
            className: errors.email ? classes.styleLabel : '',
          }}
        />
        <div className={`${classes.titleItem} mt-40`}>ID info</div>
        <Controller
          name="idType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AppRadioList
              className={classes.inputItem}
              label="ID type"
              value={value}
              error={Boolean(errors.idType)}
              helperText={errors.idType?.message}
              radioList={radioList}
              handleSelect={onChange}
            />
          )}
          rules={rulesTextField('This field')}
        />

        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'idNumber',
            rules: rulesTextField('ID Number'),
          }}
          inputAddress={true}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'ID Number'}
          error={Boolean(errors.idNumber)}
          placeholder="ID Number"
          helperText={errors.idNumber?.message}
          labelProps={{
            className: errors.idNumber ? classes.styleLabel : '',
          }}
        />
        {documents.map((item: any, index: number) => {
          return (
            <AppUpload
              key={index}
              className={classes.fileUpload}
              label={item.label}
              description={item?.desc}
              value={item}
              handleSetValue={(e: any) => {
                handleChangeFile(index, e);
              }}
            />
          );
        })}
      </form>
    </>
  );
};

export default AddIndividual;

const radioList = ['Passport', 'Government Issued ID', 'Other'];
const checkLists = ['Controller', 'Beneficial', 'Authorized', 'Other'];
