import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import AddressBookComponent from './AddressBookComponent';
const AddressBookPage = (props: any) => {
  useEffect(() => {
    document.title = 'Address book';
  }, []);

  return (
    <DefaultLayout>
      <AddressBookComponent></AddressBookComponent>
    </DefaultLayout>
  );
};

export default withRouter(AddressBookPage);
