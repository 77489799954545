import { Step, StepLabel, Stepper } from '@material-ui/core';
import clsx from 'clsx';
import Documents from './CorporateDocuments';
import General from './CorporateGeneral';
import Individuals from './CorporateIndividuals';
import Organization from './CorporateOrganization';
import useStyles, { QontoConnector } from './styles';

const Corporate = ({
  steps,
  activeStep,
  kycData,
  isLoading,
  handleSetSteps,
  handleSubmitKYC,
  saveData,
}: {
  steps: any;
  activeStep: number;
  isLoading: boolean;
  kycData: any;
  handleSetSteps: (num: number, state?: any) => void;
  handleSubmitKYC: (data: any) => void;
  saveData: (data: any, errors?: any) => void;
}) => {
  const classes = useStyles();

  const dataStorage = kycData?.data || {};
  const errors = kycData?.errors || {};

  return (
    <div className={classes.mainKYC}>
      <div className={classes.kycContainer}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label: string, index: number) => {
            return (
              <Step key={label}>
                <StepLabel
                  className={clsx({
                    [classes.activedItems]: index + 1 <= activeStep,
                    [classes.declineItems]: errors[label.toLowerCase()],
                  })}
                  onClick={() => handleSetSteps(index + 1)}
                  StepIconComponent={() => {
                    if (
                      dataStorage[label.toLowerCase()] &&
                      !errors[label.toLowerCase()]
                    ) {
                      return <div className="icon-checkmark" />;
                    } else {
                      return <>{index + 1}</>;
                    }
                  }}
                >
                  <div
                    className={clsx(classes.styleStepKYC, {
                      [classes.activedLabel]: index + 1 <= activeStep,
                      [classes.declineLabel]: errors[label.toLowerCase()],
                    })}
                  >
                    {label}
                  </div>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {activeStep === 1 && (
        <General
          formData={dataStorage?.general}
          lastErrors={errors?.general}
          setStep={handleSetSteps}
          saveData={saveData}
        />
      )}
      {activeStep === 2 && (
        <Organization
          formData={dataStorage?.organization}
          lastErrors={errors?.organization}
          setStep={handleSetSteps}
          saveData={saveData}
        />
      )}
      {activeStep === 3 && (
        <Documents
          formData={dataStorage?.documents}
          lastErrors={errors?.documents}
          setStep={handleSetSteps}
          saveData={saveData}
        />
      )}
      {activeStep === 4 && (
        <Individuals
          formData={dataStorage}
          lastErrors={errors}
          isLoading={isLoading}
          saveData={saveData}
          setStep={handleSetSteps}
          handleSubmitKYC={handleSubmitKYC}
        />
      )}
    </div>
  );
};

export default Corporate;
