import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    tabDepositDomestic: {
      height: "calc(80vh - 350px)",
      overflow: "auto",
      fontFamily: "Ubuntu-Regular",
      padding: '0 16px 0 30px',
      marginRight: 5,
    },

    itemDomestic: {
      padding: "20px 26px",
      background: theme.custom.colors.mainBackground,
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
      marginBottom: 3,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: 13,
      },

      "& .item_key": {
        width: "40%",
        [theme.breakpoints.down('xs')]: {
          width: "100%",
        },
      },
      "& .item_value": {
        width: "60%",
        [theme.breakpoints.down('xs')]: {
          width: "100%",
        },
      },

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "start",
        padding: "10px",

        "& .item_key": {
          width: "unset",
        },
        "& .item_value": {
          width: "unset",
          marginTop: "10px",
        },
      },
    },

    activeItemNav: {
      color: theme.custom.colors.titleTrading,
      borderBottom: `2px solid ${theme.custom.colors.lightPurple2}`,
      cursor: "inherit",
    },
  };
});

export default useStyles;
