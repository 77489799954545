export const SCORE_LEVEL = {
  EMAIL_VERIFY: 10,
  HAS_2FA: 40,
  KYC: 30,
  HAS_ADD: 10,
  HAS_WHITE_LIST: 20,
};

export const PERCENT_TRADE = [25, 50, 75, 100];
export const TRADE_DEFAULT_TOKEN_BUY = 'BTC';
export const TRADE_DEFAULT_TOKEN_PAY = 'USDT';
export const CHART_DEFAULT_PAIR = 'BTC/USD';
export const BALANCE_COINS_DEFAULT = ['BTC', 'USD'];

export const CHART_PERIOD = {
  hour: '1h',
  day: '1d',
  month: '1M',
  year: '1y',
  default: '8h',
};

export const TRADE_SIDE = {
  buy: 'buy',
  sell: 'sell',
};

export const TRADE_AMOUNT = {
  min: 0.005,
  max: 1000,
};

export const TRANSACTION_ERROR =
  'Transaction failed. Please check blockchain to know more error.';
export const DEFAULT_LIMIT = 10;
export const API_URL_PREFIX = 'admin';
export const ADMIN_URL_PREFIX = 'dashboard';
export const IMAGE_URL_PREFIX = 'image';
export const MAX_BUY_CAMPAIGN = 1000;
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const TIME_STAMP_FORMAT = 'ddd MMM D YYYY HH:mm:SS [GMT] Z';
export const IP_ADDRESS_PATTERN =
  /^((25[0-5]|2[0-4][0-9]|1\d{1,2}|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1\d{1,2}|[0-9])$/;
export const COINS = [
  { name: 'btc', icon: 'coin-btc' },
  { name: 'usd', icon: 'coin-usd' },
  { name: 'ltc', icon: 'coin-ltc' },
];

export const THOUSAND_SEPARATOR = '.';
export const THOUSAND_DECIMAL = ',';

export const KEY = {
  REDIRECT_URI: 'redirect_uri',
  LOGOUT: 'logout',
  USERNAME: 'username',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  IS_LOGGED: 'isLogged',
};

export const ORDER_TYPES = [
  { value: 1, label: 'BEST_RATE' },
  { value: 2, label: 'LIMIT' },
  // { value: 3, label: 'QUOTE' },
];

export const ORDER_TYPE = {
  LIMIT: 'LIMIT',
  BEST_RATE: 'BEST_RATE',
  QUOTE: 'QUOTE',
};

export const ORDER_STATE = {
  pending: 'PENDING',
  confirmed: 'CONFIRMED',
  expired: 'EXPIRED',
  canceled: 'CANCELED',
  confirming: 'CONFIRMING',
};

export const KYC_STATUS = {
  completed: 'completed',
  pending: 'pending',
  approved: 'approved',
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024; //in bytes

export const DEFAULT_LOGO = '/images/logos/default.svg';
