import { makeStyles } from '@material-ui/core';
import { drawerWidth } from '../../../store/constants/width-page';

const useStyles = makeStyles((theme: any) => {
  return {
    appBar: {
      boxShadow: 'none',
      background: theme.custom.colors.mainHeader,
      zIndex: theme.zIndex.drawer + 1,
      borderBottom: `1px solid ${theme.custom.colors.colorBorder}`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      padding: '15px 0px',

      [theme.breakpoints.down('sm')]: {
        borderBottom: '1px solid #373C4E',
      },

      [theme.breakpoints.down('xs')]: {
        padding: 0,
        borderBottom: '1px solid #373C4E',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '15px 97px',
      },
    },
    borderMenu: {
      borderBottom: 'none !important',
    },

    appBarShift: {
      marginLeft: drawerWidth,
      boxShadow: 'none',
      width: '100%',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('xl')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    appBarShiftClose: {
      marginLeft: 65,
      boxShadow: 'none',
      width: '100%',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('xl')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    styleContainerText: {
      padding: '0 97px',
      [theme.breakpoints.down('xs')]: {
        padding: '10px 0',
      },
    },

    toolbarHeader: {
      minHeight: '70px',
      paddingLeft: '0px',
      paddingRight: '0px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 24px',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 60,
      },

      '& .icon-toggle-nav:before': {
        fontSize: 19,
      }
    },

    logoTrading: {
      '& img': {
        height: 48,
      },
    },

    headerMain: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },

    hide: {
      display: 'none',
    },

    logoMobile: {
      display: 'flex',
      '& img': {
        height: 44,
      },
    },

    boxLogo: {
      width: 115,
      marginRight: 95,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        marginRight: 74,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: 40,
      },
    },

    menuButton: {
      marginRight: 0,

      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        right: 13,
        top: 6,
        zIndex: 1,
      },
    },

    menuIcon: {
      color: theme.custom.colors.cornflowerBlue,
    },

    titlePage: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 24,
      flexGrow: 1,
      color: theme.custom.colors.white3,

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-start',
      },

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },

    centerLogo: {
      justifyContent: 'center !important',
    },

    btnLogin: {
      background: 'none',
      borderRadius: 6,
      height: 40,
      width: 120,
      fontFamily: 'Titillium Web',
      fontSize: 12,
      color: theme.custom.colors.white,
      textTransform: 'none',
      marginRight: 15,
      boxShadow: 'none',
      border: '1px solid #FFFFFF',

      '&:hover': {
        border: '1px solid #7F63F4',
        background: 'none',
        color: theme.custom.colors.textDetail,
      },

      [theme.breakpoints.down('lg')]: {
        width: 117,
      },
    },

    rectangle: {
      height: 36,
      width: 1,
      marginRight: 10,
      background: theme.custom.colors.comet,
    },

    btnToggleMode: {
      height: 36,
      width: 36,
      minWidth: 36,
      borderRadius: 6,
      background: 'transparent',
      boxShadow: 'none',
      fontSize: 24,

      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        right: 10,
        top: 16,
      },

      '& .icon-mode:before': {
        color: theme.custom.colors.white2,
      },

      '&:hover': {
        background: 'transparent',
      },
    },

    btnNotification: {
      height: 36,
      width: 36,
      minWidth: 36,
      borderRadius: 6,
      background: 'transparent',
      boxShadow: 'none',
      fontSize: 24,
      marginLeft: 10,

      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        right: 10,
        top: 13,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 32,
      },

      '& .icon-notification:before': {
        color: theme.custom.colors.white2,
      },

      '&:hover': {
        background: 'transparent',
      },
    },

    btnSignup: {
      background: theme.custom.colors.btnSignUp,
      borderRadius: 6,
      height: 40,
      width: 120,
      fontFamily: 'Titillium Web',
      fontSize: 12,
      fontWeight: 700,
      color: theme.custom.colors.white,
      textTransform: 'none',
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        right: 10,
        top: 13,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 32,
      },

      '&:hover': {
        background: theme.custom.colors.hoverButton,
      },
    },

    listMenuTrading: {
      fontFamily: 'Titillium Web',
      fontWeight: 400,
    },

    itemMenuTrading: {
      color: theme.custom.colors.white,
      fontSize: 18,
      lineHeight: '27px',
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: 50,

      '&:hover': {
        color: theme.custom.colors.lightPurple,
        textDecoration: 'none',
      },

      '&:last-child': {
        marginRight: 0,
      },

      [theme.breakpoints.down('md')]: {
        marginRight: 35,
      },

      [theme.breakpoints.down('lg')]: {
        fontSize: 18,
        marginRight: 62,
      },

      [theme.breakpoints.between('991', '1024')]: {
        marginRight: 42,
      },
    },

    itemMenuTradingActive: {
      color: theme.custom.colors.lightPurple,
      fontFamily: 'Titillium Web',
    },

    iconMenuTrading: {
      color: theme.custom.colors.santaGray,
      fontSize: 24,
      marginRight: 10,

      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },

      '&:before': {
        color: theme.custom.colors.santaGray,
      },
    },

    iconMenuTradingActive: {
      '&:before': {
        color: theme.custom.colors.lightPurple,
      },
    },
  };
});

export default useStyles;
