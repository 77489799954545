/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AccountService } from 'src/services/AccountService';
import { getBanks } from 'src/store/actions/account';
// import { LoadingAction } from 'src/store/actions/loading';
import { hideModalMessage, showModalMessage } from 'src/store/actions/message';
import { ModalMessageType } from 'src/store/reducers/message';
import BankInterPage1, { IFormBankPage1 } from './BankInterPage1';
import BankInterPage2, { IFormBankPage2 } from './BankInterPage2';
import BankInterPage3, { IFormBankPage3 } from './BankInterPage3';
import BankInterPage4, { IFormBankPage4 } from './BankInterPage4';

export interface IPropsBankChild {
  onSubmit: any;
  onBack: Function;
  isLoading?: boolean;
}

interface IForm {
  account_name: string;
  account_number: string;
  bank_name: string;
  swift: string;

  int_bank_name: string;
  int_routing_number: string;

  int_bank_address: {
    country: string;
    street_adress: string;
    street_adress_2: string;
    city: string;
    region: string;
    postcode: string;
  };

  iban: string;
  beneficiary_bank_address: {
    country: string;
    street_adress: string;
    street_adress_2: string;
    city: string;
    region: string;
    postcode: string;
  };
}

export default function BankInter({
  type,
  backToWithdrawBank,
  onStep,
}: {
  type: string;
  backToWithdrawBank: Function;
  onStep: Function;
}) {
  const dispatch = useDispatch();
  const assetSelected = useSelector((state: any) => state.asset.assetSelected);
  const [pageInput, setPageInput] = useState(1);
  const [formData, setFormData] = useState<IForm | any>({});
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (formData: IForm) => {
    // Loading
    // dispatch(LoadingAction.show());
    setLoading(true);

    const data = {
      bank_type: type,
      asset: assetSelected,
      bank_data: formData,
    };
    const accountService = AccountService.getInstance('');

    let message: ModalMessageType = {
      type: 'ERROR',
      msg: 'Server error',
    };

    try {
      const res = await accountService.createBank(data);
      if (res?.data?.accounts) {
        message = {
          type: 'SUCCESS',
          msg: 'Add bank account success',
          onOk: () => {
            // Fetch list bank
            dispatch(getBanks());
            // Hide message
            dispatch(hideModalMessage());
            // Back to withdraw
            backToWithdrawBank();
          },
        };
      }
    } catch (err) {
    } finally {
      // Hide message & loading
      // dispatch(LoadingAction.hide());
      setLoading(false);
      dispatch(showModalMessage(message));
    }
  };

  useEffect(() => {
    onStep(pageInput);
  }, [pageInput]);

  return (
    <>
      <Box display={pageInput === 1 ? '' : 'none'}>
        <BankInterPage1
          onBack={backToWithdrawBank}
          onSubmit={(data: IFormBankPage1) => {
            setFormData({ ...formData, ...data });
            setPageInput(pageInput + 1);
          }}
        />
      </Box>
      <Box display={pageInput === 2 ? '' : 'none'}>
        <BankInterPage2
          onBack={() => {
            setPageInput(pageInput - 1);
          }}
          onSubmit={(data: IFormBankPage2) => {
            setFormData({ ...formData, ...data });
            setPageInput(pageInput + 1);
          }}
        />
      </Box>
      <Box display={pageInput === 3 ? '' : 'none'}>
        <BankInterPage3
          onBack={() => {
            setPageInput(pageInput - 1);
          }}
          onSubmit={(data: IFormBankPage3) => {
            setFormData({ ...formData, ...data });
            setPageInput(pageInput + 1);
          }}
        />
      </Box>
      <Box display={pageInput === 4 ? '' : 'none'}>
        <BankInterPage4
          onBack={() => {
            setPageInput(pageInput - 1);
          }}
          onSubmit={(data: IFormBankPage4) => {
            onSubmit({ ...formData, ...data });
          }}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
}
