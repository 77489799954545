import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
    return {
        componentMid: {
            marginTop: 40,
            position: "relative",

            [theme.breakpoints.down("sm")]: {
                marginTop: 20,
            },

            "& .MuiDataGrid-cell": {
                "&:focus-within": {
                    outline:'none'
                }
            },
            "& .MuiDataGrid-columnHeader": {
                "&:focus-within": {
                    outline: 'none'
                }
            }
          
        },

        title: {
            [theme.breakpoints.down("xs")]: {
                display: "flex",
                justifyContent: "space-between",
            },
        },

        titleList: {
            fontSize: "20px",
            lineHeight: "140%",
            fontWeight: 700,
            color: theme.custom.colors.titleTrading,
            marginBottom: 15,
        },

        headPage: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: 30,
        },

        left: {
            display: "flex",
            flexWrap: "wrap",
        },

        right: {

        },

        breakSpaces: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            lineHeight: "20px",
            fontFamily: "Ubuntu-Regular",
        },

        iconCell: {
            marginLeft: 20,
        },

        icon: {
            width: 24,
            height: 24,
        },

        button: {
            maxWidth: 58,
            height: 34,
            borderRadius: 6,
            boxShadow: "none",
            textTransform: 'none',
            fontSize: 12,
            color: theme.custom.colors.btnTitle,
            marginRight: 10,
            background: theme.custom.colors.bgBtn,

            "&:hover": {
                background: theme.custom.colors.bgBtn,
            },

            "&:last-child": {
                marginRight: 0,
            },
        },

        containerAdd: {
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
            
        },

        btnAdd: {
            background: theme.custom.colors.btnEnable,
            borderRadius: "6px",
            color: theme.custom.colors.btnTitle,
            textTransform: "none",
            fontSize: "12px",
            lineHeight: "14px",
            width: "126px",
            height: "36px",
           

            "&:hover": {
                background: theme.custom.colors.btnHover,
            },
            [theme.breakpoints.up("sm")]: {
            },

        },

        btnMobile: {
            background: theme.custom.colors.btnEnable,
            borderRadius: "6px",
            color: theme.custom.colors.btnTitle,
            textTransform: "none",
            fontSize: "12px",
            lineHeight: "14px",
            width: "161px",
            height: "36px",

            "&:hover": {
                background: theme.custom.colors.btnHover,
            },
        },

        btnCopy: {
            fontSize: 22,
            marginLeft: 10,
            cursor: "pointer",

            "&:before": {
                color: theme.custom.colors.titleTrading,
            }
        },

        addressTable: {
            display: "flex",
            alignItems: "center",
        },
        iconBtn: {},

        groupIP: {
            whiteSpace: 'break-spaces',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },

        ipAddress: {
            marginRight: 20
        },

        tooltip: {
            display: 'block',
            width: 100,
            wordSpacing: '100px',
            "& span": {
                 color: 'red',
            }
        }
    };
});

export default useStyles;