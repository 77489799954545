import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    componentWatchList: {
      height: "100%",

      [theme.breakpoints.only("md")]: {
        width: 564,
      },
    },

    titleTable: {
      fontFamily: "Ubuntu-Bold",
      fontSize: 18,
      lineHeight: "140%",
      color: theme.custom.colors.white3,
      margin: 0,
    },

    btnTradingWatch: {
      width: "82px",
      height: "36px",
      border: "none",
      order: 3,
      boxShadow: "none",
      background: theme?.custom?.colors.cornflowerBlue,
      borderRadius: "6px",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "14px",
      textAlign: "center",
      color: theme?.custom?.colors.white1,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.custom.colors.btnHover,
        boxShadow: 'none',
      },

      "& .icon-trading:before": {
        color: theme.custom.colors.white1,
      },

      [theme.breakpoints.between("1152", "1440")]: {
        display: "none",
      },

    },

    btnIconTrade: {
      [theme.breakpoints.between("1152", "1440")]: {
        display: "block !important",
      },

      [theme.breakpoints.up("lg")]: {
        display: "none",
      },

      [theme.breakpoints.between("990", "1151")]: {
        display: "none",
      },

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },

    },

    overlay: {
      position: 'absolute',
      height: 256,
      background: theme.custom.colors.bgrOverLay,
      zIndex: 3,
      opacity: 0.7,
      color: theme.custom.colors.btnTitle,
      display: 'flex',
      alignItems: 'center',
      width: "100.5%",
      borderRadius: 8,
      bottom: 66,

      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
      [theme.breakpoints.between("1160", "1399")]: {
        width: 564,
        bottom: 43,
        height: 380,
      },
      [theme.breakpoints.between("1000", "1155")]: {
        bottom: 43,
        height: 380,
        width: 565
      },
      [theme.breakpoints.between("990", "1025")]: {
        bottom: 42,
        height: 381,
        width: 565
      },
      [theme.breakpoints.down("sm")]: {
        bottom: 114,
        height: 310,
        width: '100%'
      },
      [theme.breakpoints.down('xs')]: {
        height: 323,
        bottom: 101,
      },
    },

    styleGrid: {
      '& .MuiDataGrid-main': {
        paddingBottom: 10,
      },

    },

    detailToken: {
      display: "flex",
      alignItems: "center",
      gridGap: 10,
      fontFamily: "Ubuntu-Regular",
    },

    detailPair: {
      display: "flex",
      flexDirection: "column",
    },

    pairLabel: {
      lineHeight: "20px",
      fontSize: "16px",
      color: "#9BA1B1",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      }
    },

    pairPercent: {
      lineHeight: "20px",
      color: theme.custom.colors.white5,
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      }
    },

    numberPrice: {},

    priceToken: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "Ubuntu-Regular",
    },

    usdNumber: {},
    logo: {
      width: 35,
      height: 35
    },


  };
});

export default useStyles;
