import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      '&.MuiDataGrid-overlay': {
        backgroundColor: theme.custom.colors.mainBackground,
        opacity: 0.9,
      },
    },
    loadingElement: {
      color: theme.custom.colors.purple1,
      width: '50px',
      height: '50px',
    },
  };
});

export default useStyles;
