/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import { marketService } from 'src/services/MarketService';
import { displayNumb } from 'src/utils/number';
import { getPercent } from 'src/utils/number';

import { TVChartContainer } from 'src/components/Base/CandleChart/index';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import SelectCoin from 'src/components/Base/SelectCoin';
import useStyles from './styles';

const ComponentChartTrading = (props: any) => {
  const styles = useStyles();

  const {
    allMarkets,
    favCoin,
    symbol,
    onChangeSymbol,
    handleChangeFav,
    priceChart,
  } = props;
  const themeMode = useSelector((state: any) => state.themeMode);
  const tvRef = useRef<any>(null);

  const [marketsPrice, setMarketsPrice] = useState(Array<any>());
  const [isActive, setIsActive] = useState(false);
  const selectContainerRef = useRef<HTMLElement>(null);
  const [, setOldPrice] = useState<any>(0);

  useOnClickOutside(selectContainerRef, (e) => {
    if (isActive) setIsActive(!isActive);
  });

  useEffect(() => {
    getPriceChange(allMarkets);
  }, [allMarkets]);

  const getPriceChange = async (allMarkets: any) => {
    if (allMarkets && allMarkets.length > 0) {
      // get all price change
      const prices = await marketService.getPriceChangeMarket(allMarkets);

      let m = allMarkets.map((item: any) => {
        // find item of price change
        let price: any = {};
        if (prices?.data && _.isArray(prices?.data)) {
          price = prices.data.find((x: any) => x.symbol === item);
          const activePair = prices.data.find((x: any) => x.symbol === symbol);
          setOldPrice(Number(activePair?.old_price ?? 0));
        }
        const current_price = price?.current_price ?? 0;
        const old_price = price?.old_price ?? 0;

        return {
          pair: item,
          price: displayNumb(price?.current_price) ?? 0,
          percent: getPercent(old_price, current_price),
        };
      });
      setMarketsPrice(m);
    }
  };

  const handleZoom = () => {
    tvRef.current?.setFullscreen();
  };

  const isFavCoin = (symbol: string, favCoins: any) => {
    const favourites = favCoins ?? [];
    const isFav = favourites?.find((x: string) => x === symbol);
    return isFav ? (
      <span className="icon-favorite">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ) : (
      <span className="icon-star"></span>
    );
  };

  const renderPrice = (symbol: string) => {
    const price = marketsPrice.find((c) => c.pair === symbol);
    return (
      <>
        <div className={styles.numberTotal}>{priceChart}</div>
        <div
          className={
            price?.percent < 0
              ? `${styles.numberPercent} ${styles.downColor}`
              : `${styles.numberPercent}`
          }
        >
          {price?.percent}%
        </div>
      </>
    );
  };

  const handleChangeFavoriePair = async (pair: string) => {
    let listFav = favCoin ?? [];
    const isFavCoin = listFav?.find((p: string) => p === pair);

    // remove favourite coin
    if (isFavCoin) {
      listFav = listFav.filter((x: string) => x !== pair);
    } else {
      listFav.push(pair);
    }

    // put favourite
    await handleChangeFav(listFav);
  };

  return (
    <div className={styles.componentChartTrading}>
      <div className={styles.dropChart}>
        <div className={styles.drop1}>
          <Button
            className={styles.btnFavorite}
            onClick={() => handleChangeFavoriePair(symbol)}
          >
            {isFavCoin(symbol, favCoin)}
          </Button>

          <SelectCoin
            options={allMarkets}
            value={symbol}
            onChange={onChangeSymbol}
          />
          {renderPrice(symbol)}
        </div>
        <div className={styles.zoom} onClick={handleZoom}>
          <span className="icon-zoom"></span>
        </div>
      </div>
      {symbol && symbol !== '' && (
        <TVChartContainer
          ref={tvRef}
          symbol={symbol}
          themeMode={themeMode}
          className={styles.loading}
        />
      )}
    </div>
  );
};

export default ComponentChartTrading;
