import { makeStyles } from '@material-ui/core';

const useClasses = makeStyles((theme: any) => {
  return {
    logo: {
     
    },
    textCenter: {
      textAlign: 'center',
      // marginTop: 15
    },
    
    link: {
      color: theme.custom.colors.white,
    }
  }
});

export default useClasses;