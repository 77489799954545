import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './styles';

interface Props {
  onClick?: () => void;
  width?: string;
  mode?: 'primary' | 'secondary';
  height?: string;
  isDisable?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  type?: any;
  fontSize?: any;
  className?: string;
}

const AppButton: React.FC<Props> = ({
  children,
  onClick,
  width,
  mode = 'primary',
  height,
  isDisable,
  isLoading,
  fullWidth,
  type,
  fontSize,
  className,
}) => {
  const styles = useStyles();
  const renderIsLoading = () => {
    return (
      <>
        <div className={styles.spiner}>
          <div className={styles.bubble1}></div>
          <div className={styles.bubble2}></div>
        </div>
      </>
    );
  };

  if (isDisable) {
    return (
      <div>
        <div
          className={styles.disable}
          style={{
            width: width ? width : fullWidth ? '100%' : 'fit-content',
            height: height ? height : '50px',
            fontSize: fontSize ? fontSize : '',
          }}
        >
          {children}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          type={type}
          className={`${clsx(styles.regular, styles[mode])} ${className}`}
          onClick={onClick}
          style={{
            width: width ? width : fullWidth ? '100%' : 'fit-content',
            height: height ? height : '50px',
            fontSize: fontSize ? fontSize : '',
          }}
        >
          {isLoading && renderIsLoading()}
          {children}
        </Button>
      </div>
    );
  }
};

export default AppButton;
