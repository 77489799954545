import React from 'react';
import { Button, Container, Hidden, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useStyles from './styles';
import SliderImg from './SliderImg';
import ModalRequest from '../Mainhome-1/ModalRequest';

const listIcon = [
  {
    id: 1,
    icon: 'company-1',
  },
  {
    id: 2,
    icon: 'company-2',
  },
  {
    id: 3,
    icon: 'company-3',
    class: 'img-3',
  },
  {
    id: 4,
    icon: 'company-4',
  },
  {
    id: 5,
    icon: 'company-5',
  },
];
const GlobalContent = (props: any) => {
  const classes = useStyles();
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section className={classes.mainGlobal} id="settlement">
      <Container>
        <div data-aos="fade-right">
          <Typography variant="h3" className={classes.titleGlobal}>
            Global Settlement Network
          </Typography>
        </div>

        <div className={classes.boxText}>
          <p className={classes.textdesc}>
            Our platform has been designed to provide your business with rapid
            global settlement in multiple currencies, calibrated according to
            your specific needs. We support USD, EUR, GBP, HKD as well as all
            major cryptocurrencies.
          </p>
        </div>

        <div className={classes.boxTextMobile}>
          <p className={classes.textdesc}>
            Our platform has been designed to provide your business with rapid
            global settlement in multiple currencies, calibrated according to
            your specific needs.
            <br></br>
            We support USD, EUR, GBP, HKD as well as all major cryptocurrencies.
          </p>

          <Typography variant="h4" className={classes.titleMobile}>
            Settlement Discovery
          </Typography>

          <p className={classes.textdesc}>
            Our specialised team is ready to better understand your needs. Book
            a settlement discovery walkthrough to see how we can better optimise
            your capital efficiency.
          </p>

          <Button
            className={classes.btnBookNow}
            onClick={() => setModalShow(true)}
          >
            Book now
          </Button>
          <ModalRequest open={modalShow} setModalShow={setModalShow} />
        </div>

        <div className={classes.boxImg}>
          <img src="/images/img-map.png" alt="" />
        </div>

        <div className={classes.support}>
          {listIcon?.map((item) => (
            <div className={classes.iconGlobal} key={item.id}>
              <img
                className={item.class}
                src={`/images/${item.icon}.png`}
                alt=""
              />
            </div>
          ))}
        </div>
      </Container>
      <Hidden smUp>
        <SliderImg />
      </Hidden>
    </section>
  );
};

export default withRouter(GlobalContent);
