import { useEffect, useState } from 'react';
import { Dialog, Typography } from '@material-ui/core';
import useStyles from 'src/components/BaseLandingPage/Common/Form/styles';

import ButtonCommon from 'src/components/BaseLandingPage/Common/Button';
import MessageInline from 'src/components/BaseLandingPage/Common/MessageInline';
import AppOTPInput from 'src/components/Base/AppOTPInput';

import useClasses from './styles';
const LENGTH_CODE = 6;

const VerifyComponent = ({
  handleVerify,
  show: showState,
  msg,
}: {
  handleVerify: Function;
  show: any;
  msg?: string;
}) => {
  const style = useStyles();
  const classes = useClasses();
  const [show, setShow] = showState;
  const [pinCode, setPinCode] = useState('');
  const [, setFocus] = useState(true);

  const handlePinChange = (value: any) => {
    setPinCode(value);
    if (value !== pinCode) {
      setFocus(false);
    } else {
      setFocus(true);
    }
  };

  const toggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (!show) {
      setPinCode('');
    }
  }, [show]);

  return (
    <Dialog
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={show}
      PaperProps={{
        style: {
          backgroundColor: '#2C2949',
          boxShadow: 'none',
          borderRadius: '10px',
          margin: '20px',
        },
      }}
    >
      <div className={classes.main}>
        <div className={`${style.formBox2} ${style.position}`}>
          <div className={style.iconHeader}>
            <img src="/images/icon-key.png" alt="" />
          </div>
          <Typography className={`${style.labelContent} ${classes.titleColor}`}>
            Confirmation
          </Typography>
          <Typography className={`${style.titleColor} ${classes.labelContent}`}>
            Please enter two factor authentication code
          </Typography>
          <AppOTPInput
            onChange={handlePinChange}
            hasError={!!msg}
            className={classes.pinCode}
          />

          {msg && <MessageInline msg={msg} />}
          <div className={classes.divBtn}>
            <ButtonCommon
              type="button"
              className={classes.btnCancel}
              onClick={toggle}
            >
              Cancel
            </ButtonCommon>
            <ButtonCommon
              type="button"
              disabled={pinCode.length !== LENGTH_CODE}
              onClick={() => handleVerify(pinCode)}
            >
              Confirm
            </ButtonCommon>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default VerifyComponent;
