import React, { useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import {
  MAX_LENGTH,
  REGEX_EMAIL,
  REGEX_LATIN,
  REGEX_PASSWORD,
} from 'src/utils/validation';
import useStyles from './style';

export default function InputField({
  label,
  name,
  type,
  register,
  formState: { errors },
  validation,
  maxLength,
  className,
  modeLogin,
  ...rest
}: {
  label: string;
  name: string;
  type?: 'password' | 'text' | 'email';
  register: Function;
  rest?: any;
  formState: any;
  validation?: RegisterOptions;
  maxLength?: number;
  className?: string;
  [rest: string]: any;
}) {
  const style = useStyles();
  const [hasFocus, setHasFocus] = useState(false);
  const [showEye, setShowEye] = useState(true);

  const typeInput =
    type === 'password' ? (showEye ? 'password' : 'input') : 'input';

  validation =
    // EMAIL
    type === 'email'
      ? {
        ...validation,
        pattern: REGEX_EMAIL,
        validate: {
          ...validation?.validate,
          dots: (val: string) => {
            if (val.includes('..')) return 'This is not a valid email format';
          },
        },
      }
      : type === 'password' && !modeLogin
        ? {
          ...validation,
          pattern: REGEX_PASSWORD,
          validate: {
            ...validation?.validate,
            onlyLatin: (val: string) => {
              if (!val.match(REGEX_LATIN))
                return 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.';
            },
          },
        }
        : // DEFAULT
        validation;

  const onToggleEye = (e: React.MouseEvent<HTMLElement>) => {
    setShowEye(!showEye);
  };

  const showMessage = (objectError: any, label: string) => {
    let msg = '';
    if (!objectError) {
      return <></>;
    }

    if (objectError.type === 'required') {
      msg = objectError.message || `${label} requirements`;
    } else if (objectError.type === 'minLength') {
      msg = objectError.message || `Length is not enough`;
    } else if (objectError.type === 'maxLength') {
      msg = objectError.message || `Max length exceeded`;
    } else if (objectError.type === 'validate') {
      msg = objectError.message;
    } else {
      switch (type) {
        case 'email':
          msg = objectError.message || 'Email format invalid';
          break;
        case 'password':
          msg =
            objectError.message ||
            'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.';
          break;
        default:
          msg = objectError.message;
          break;
      }
    }
    return <span className={style.errorMsg}>{msg}</span>;
  };

  maxLength =
    type === 'password'
      ? MAX_LENGTH.PASSWORD
      : type === 'email'
        ? MAX_LENGTH.MAIL
        : maxLength;

  return (
    <div className={style.formControl} id={name}>
      <label
        htmlFor={name}
        className={`${style.label} ${hasFocus && style.labelFocus} ${errors[name] && style.labelError} ${className}`}
      >
        {label}
      </label>
      <input
        type={typeInput}
        placeholder={label}
        className={`${style.formInput} ${hasFocus && style.formInputFocus} ${errors[name] ? style.formInputError : ''}`}
        maxLength={maxLength}
        {...register(name, validation)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        {...rest}
      ></input>
      {/* Eye for Password */}
      {type === 'password' && (
        <div className={style.iconPassWord} onClick={onToggleEye}>
          <img
            src={showEye ? `/images/icons/icon-eye.svg` : `/images/icons/icon-eye-invisible.svg`}
            alt="eye"
          />
        </div>
      )}
      {/* Message validation */}
      {showMessage(errors[name], label)}
    </div>
  );
}
