/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from '@material-ui/core';
import SuccessComponent from './SuccessComponent';
import Common from 'src/components/BaseLandingPage/Common';
import { useLoading } from 'src/components/BaseLandingPage/Context/Loading';
import { useEffect, useState } from 'react';
import ErrorPage from '../ErrorPage';

const VerifyEmailPage = () => {
  const { show, hide } = useLoading();
  const [isDone, setIsDone] = useState<boolean | null>(null);

  useEffect(() => {
    // Show loading
    show();
    // Cal API
    fetch('/private/user/verify_email_address' + window.location.search)
      .then(async (res: Response) => {
        setIsDone(res.status === 200);
      })
      .then(() => {
        // Close loading
        hide();
      });
  }, []);

  return (
    <Common>
      <Container>
        {isDone === null ? (
          <></>
        ) : isDone === true ? (
          <SuccessComponent />
        ) : (
          <ErrorPage />
        )}
      </Container>
    </Common>
  );
};
export default VerifyEmailPage;
