import { AnyAction } from "redux";
import * as actionTypes from "../constants/account";
import { KYC_STATUS } from 'src/commons/constants/Constants';

const initialState = {
    emailVerified: false,
    has2FADevices: false,
    isKYC: false,
    whitelisted: false,
    balances: [],
    banks: [],
    depositAddresses: [],
    whiteList: [],
    KYCSetting: {
        custodian_name: null,
        custodian_account_id: null,
        kyc_uploaded: false,
        corporate_account: false
    },
    preferences: {}
};

export const accountReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actionTypes.SET_DEPOSIT_ADDRESS: {
            return {
                ...state,
                depositAddresses: action.payload,
            }
        }
        case actionTypes.GET_PROFILE:
            const data = action?.data?.data;
            return {
                ...state,
                emailVerified: data['email_verified'],
                has2FADevices: data['2fa_setup'],
                isKYC: data['kyc_status'] === KYC_STATUS.completed || data['kyc_status'] === KYC_STATUS.approved,
                whitelisted: data['whitelisted_addresses']
            };
        case actionTypes.HAS_2FA_DEVICES:
            const has2FA = action?.data?.active ?? false;
            return {
                ...state,
                has2FADevices: has2FA,
            };
        case actionTypes.FETCH_BALANCE:
            return {
                ...state,
                balances: action.data,
            };
        case actionTypes.FETCH_BANK:
            return {
                ...state,
                banks: action.data,
            };
        case actionTypes.KYC_SETTINGS:
            return {
                ...state,
                KYCSetting: action?.data
            };
        case actionTypes.WHITE_LIST:
            const whiteList = action?.data?.data?.items;
            return {
                ...state,
                whiteList: whiteList
            };
        case actionTypes.SET_FAVOURITE:
            return {
                ...state,
                preferences: action.data,
            };
        default:
            return state;
    }
};
