import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';

import ModalRequest from './ModalRequest';
import Canvas from 'src/components/BaseLandingPage/Canvas';
import useStyles from './styles';
import { handleTrade } from 'src/utils/utils';

const MainHome1 = (props: any) => {
  const classes = useStyles();
  const [counter, setCounter] = useState(0);
  const [counterTime, setCounterTime] = useState(0);
  const [counterDay, setCounterDay] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const theme = useTheme();
  const hiddenXS = useMediaQuery(theme.breakpoints.only('xs'));
  const hiddenMD = useMediaQuery(theme.breakpoints.up('md'));
  const hiddenSM = useMediaQuery(theme.breakpoints.only('sm'));
  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (counter < 10) {
        setCounter(counter + 1);
      }
    }, 100);
    return () => clearTimeout(intervalId);
  }, [counter]);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (counterTime < 100) {
        setCounterTime(counterTime + 1);
      }
    }, 5);
    return () => clearTimeout(intervalId);
  }, [counterTime]);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (counterDay < 24) {
        setCounterDay(counterDay + 1);
      }
    }, 50);
    return () => clearTimeout(intervalId);
  }, [counterDay]);

  useEffect(() => {
    document.title = 'Landing Page';
  }, []);

  return (
    <>
      <section className={classes.contentTop}>
        <div className={classes.groupCanvas}>
          <div className={classes.textCenter}>
            <h3 className={classes.titleTop}>Build Better Markets</h3>
            <p className={classes.textDesc}>
              The leading trade & settlement platform for blockchain powered
              companies
            </p>
            <div className={classes.boxButton}>
              <Button
                variant="contained"
                className={classes.btnTrade}
                onClick={handleTrade}
              >
                Trade
              </Button>
              <Button
                variant="contained"
                className={classes.btnRequest}
                onClick={() => setModalShow(true)}
              >
                Request a Demo
              </Button>
              <ModalRequest open={modalShow} setModalShow={setModalShow} />
            </div>
          </div>

          {hiddenMD && (
            <Canvas className={classes.canvas} sphereRad={480} scale={2} />
          )}
        </div>

        {hiddenXS && (
          <Canvas className={classes.canvasXS} sphereRad={280} scale={3.5} />
        )}
        <div className={classes.countNumber}>
          <div className={classes.box1}>
            <div className={classes.styleNumber}> {counter}+ </div>
            <p className={classes.styleText}>Active Exchanges</p>
          </div>

          <div className={`${classes.box1} ${classes.box2}`}>
            <div className={classes.styleNumber}> {counterTime}+ </div>
            <p className={classes.styleText}>Realtime Markets Routed</p>
          </div>

          <div className={`${classes.box1} ${classes.box3}`}>
            <div className={classes.styleNumber}> {counterDay}/7 </div>
            <p className={classes.styleText}>Market Presence</p>
          </div>
        </div>
        {hiddenSM && (
          <Canvas className={classes.canvas} sphereRad={380} scale={2} />
        )}
      </section>
    </>
  );
};

export default withRouter(MainHome1);
