import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainHead: {
      position: 'relative',
    },

    navHeadTrading: {
      listStyle: 'none',
      padding: 0,
      display: 'flex',
      flexWrap: 'wrap',
      borderBottom: `1px solid ${theme.custom.colors.comet4}`,
    },

    itemNav: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 18,
      lineHeight: '24px',
      color: '#9BA1B1',
      marginRight: 40,
      padding: '10px 0',
      borderBottom: '2px solid transparent',
      position: 'relative',
      marginBottom: -1,
      cursor: 'pointer',

      '&:last-child': {
        marginRight: 0,
      },
    },

    activeItemNav: {
      color: theme.custom.colors.tabActive,
      borderBottom: `2px solid ${theme.custom.colors.lightPurple2}`,
      cursor: 'inherit',
    },

    display: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      top: 0,
      right: 0,
      alignItems: 'center',

      '& .icon-info:before': {
        color: theme.custom.colors.white2,
        fontSize: 24,
      },

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    label: {
      color: theme.custom.colors.white2,
      padding: '0 10px',
    },

    rootChecked: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },

    switchBase: {
      padding: 1,
      color: theme.custom.colors.borderQRCode,
      '&$checked': {
        color: theme.custom.colors.borderQRCode,
        transform: 'translateX(16px)',
        '& + $track': {
          backgroundColor: '#7B61FF',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #606983',
      },
    },

    thumb: {
      width: 24,
      height: 24,
    },

    track: {
      borderRadius: 26 / 2,
      backgroundColor: '#606983',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },

    checked: {},
  };
});

export default useStyles;
