import { tradingService } from 'src/services/TradingService';
import { ORDER_TYPE } from 'src/commons/constants/Constants';
import { v4 as uuidv4 } from 'uuid';

export const addOrder = async (tradeData: any, orderType: string) => {
    try {
        let body: any = {
            type: orderType,
            symbol: tradeData.symbol,
            side: tradeData.side,
            client_order_id: uuidv4()
        }

        // build request body
        switch (body.type) {
            case ORDER_TYPE.LIMIT:
                if (tradeData.side === 'buy') {
                    body['price'] = tradeData.price;
                    body['cost'] = tradeData.cost;
                } else {
                    body['price'] = tradeData.price;
                    body['proceeds'] = tradeData.proceeds;
                }
                break;
            case ORDER_TYPE.BEST_RATE:
                if (tradeData.side === 'buy') {
                    body['proceeds'] = tradeData.proceeds;
                } else {
                    body['cost'] = tradeData.cost;
                }
                break;
            case ORDER_TYPE.QUOTE:
                if (tradeData.side === 'buy') {
                    body['price'] = tradeData.price;
                    body['cost'] = tradeData.cost;
                } else {
                    body['cost'] = tradeData.cost;
                }
                break;
        }
        return await tradingService.addOrder(body);
    } catch (error) {
        throw error;
    }
};