import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
    return {
        root: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
        },

        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,

            [theme.breakpoints.down('xs')]: {
                minHeight: 35,
            },
        },

        content: {
            padding: '20px 0',
            flexGrow: 1,
            background: theme.custom.colors.mainBackground,
            minHeight: 'calc(100vh)',
            overflow: 'hidden',
        },

        overMobile: {
            overflowY: 'hidden',
            background: theme.custom.colors.mainBackground,
            [theme.breakpoints.down('sm')]: {
                height: 'calc(100vh)',
            },
        },
        background: {
            background: theme.custom.colors.white,
        },
    };
});

export default useStyles;
