import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    gridContainer: {
      "& .MuiGrid-item": {
        width: '100%'
      }
    },
    title: {
      fontWeight: "bold",
      fontSize: "24px",
      marginTop: 15,
      marginBottom: 30,
      color: theme.custom.colors.boldGray,
    },
  };
});

export default useStyles;
