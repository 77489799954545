import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_INPUT,
} from 'src/commons/constants/AppInputFormat';
import AppInput from 'src/components/Base/AppInput';
import AppPhone from 'src/components/Base/AppPhone';
import { REGEX, rulesTextField } from 'src/utils/validation';
import useStyles from './styles';

const AddPerson = ({
  contactPerson,
  setContactPerson,
  numberContact,
  lastErrors,
  isDelete,
  handleDelete,
}: {
  contactPerson: any;
  setContactPerson: any;
  numberContact?: number;
  isDelete?: boolean;
  lastErrors?: boolean;
  handleDelete?: any;
}) => {
  const classes = useStyles();
  const [isChange, setOnchange] = useState();

  const {
    control,
    formState: { errors, isValid },
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      primaryContactPerson: contactPerson?.primaryContactPerson,
      email: contactPerson?.email,
      phoneNumber: contactPerson?.phoneNumber,
    },
  });

  useEffect(() => {
    const values = getValues();
    let obj: any = {};
    obj[`contactPerson${numberContact}`] = {
      primaryContactPerson: values.primaryContactPerson?.trim(),
      email: values.email?.trim(),
      phoneNumber: values.phoneNumber?.trim(),
    };
    const currentData = {
      isValid,
      ...obj,
    };

    setContactPerson(currentData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isChange]);

  useEffect(() => {
    lastErrors && trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className={classes.groupForm}
      onChange={(e: any) => setOnchange(e.target.value)}
    >
      {isDelete ? (
        <div className={`${classes.labelContainer} mt-40`}>
          <div
            className={classes.title}
          >{`Contact person ${numberContact}`}</div>
          <div
            className={classes.btnLink}
            onClick={handleDelete}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleDelete();
              }
            }}
            tabIndex={Math.random()}
          >
            Delete
          </div>
        </div>
      ) : (
        <div
          className={`${classes.title} mt-40`}
        >{`Contact person ${numberContact}`}</div>
      )}
      <AppInput
        classNames={classes.inputItem}
        controller={{
          control,
          name: 'primaryContactPerson',
          rules: rulesTextField(`Primary Contact Person`),
        }}
        inputProps={{ maxLength: MAX_LENGTH_INPUT }}
        label={`Primary Contact Person`}
        error={Boolean(errors.primaryContactPerson)}
        placeholder="Full name"
        helperText={errors.primaryContactPerson?.message}
        labelProps={{
          className: errors.primaryContactPerson ? classes.styleLabel : '',
        }}
      />
      <AppInput
        classNames={classes.inputItem}
        controller={{
          control,
          name: 'email',
          rules: {
            ...REGEX.email,
            ...rulesTextField('Email'),
          },
        }}
        inputProps={{ maxLength: MAX_LENGTH_EMAIL }}
        label={'Email'}
        error={Boolean(errors.email)}
        placeholder="Email"
        helperText={errors.email?.message}
        labelProps={{
          className: errors.email ? classes.styleLabel : '',
        }}
      />

      <AppPhone
        classNames={classes.inputItem}
        label="Phone Number"
        defaultCountry="af"
        controller={{
          control,
          name: 'phoneNumber',
          rules: {
            ...REGEX.phoneNumber,
            ...rulesTextField('Phone Number'),
          },
        }}
        error={Boolean(errors.phoneNumber)}
        helperText={errors.phoneNumber?.message}
        labelProps={{
          className: errors.phoneNumber ? classes.styleLabel : '',
        }}
        textPlaceholder={getValues('phoneNumber') ? '' : '| __-___-__-__'}
      />
    </form>
  );
};

export default AddPerson;
