import { HTTPBaseService } from "src/request/HTTPBaseService";

export class KYCService extends HTTPBaseService {

    private static classInstance?: KYCService;

    public static getInstance(token: string) {
        if (!this.classInstance) {
            this.classInstance = new KYCService();
        }
        return this.classInstance;
    }

    public async getTypeAccount() {
        return await this.instance.get('/account/get_kyc_settings/');
    }

    public async postDocumentsKyc(data: any) {
        return await this.instance.post('/private/user/kyc/documents/', data);
    }

    public async postKycForm(data: any) {
        return await this.instance.post('/private/user/kyc/submissions/', data);
    }

    public async getUploadStatusKyc() {
        return await this.instance.get('/private/user/kyc/submissions/');
    }

    public async getStatusKyc() {
        return await this.instance.get('/private/user/profile/');
    }
}

// export instance of kyc service
export const kycService = new KYCService();
