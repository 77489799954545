import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentMarketDepth: {
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      padding: 15,
      [theme.breakpoints.between('500', '800')]: {
        gridArea: 'main',
        width: 354,
        height: 'auto',
      },
      [theme.breakpoints.down('sm')]: {
        gridArea: 'main',
        maxWidth: 354,
      },
      [theme.breakpoints.between('600', '680')]: {
        width: 276,
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },

    title: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 18,
      lineHeight: '140%',
      color: theme.custom.colors.titleTrading,
      margin: 0,
      marginBottom: 15,
    },

    blockMid: {
      marginTop: 10,
      marginBottom: 10,
      background: theme.custom.colors.bgRowLabel,
      borderRadius: 5.3828,
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },

    valueSell: {
      fontFamily: 'Ubuntu-Bold',
      color: '#4CA250',
      fontSize: 16,
      lineHeight: '18px',
    },

    valueBuy: {
      fontFamily: 'Ubuntu-Bold',
      color: '#DE4949',
      fontSize: 16,
      lineHeight: '18px',
    },

    iconMid: {
      marginLeft: 10,
      marginRight: 10,
      fontSize: 16,
      lineHeight: '18px',
      color: '#9BA1B1',
    },
  };
});

export default useStyles;
