import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: any) => {
  return {
    logo: {
      background: theme.custom.colors.mainBackground,
      textAlign: 'center',
      padding: '35px 0',
      "& img": {
        height: 48,
      },
      "&:hover": {
        cursor: 'pointer'
      }
    }
  }
});
export default useStyles;