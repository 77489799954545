import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainFooter: {
      fontFamily: 'Titillium Web',
    },

    styleFoot: {
      padding: 0,

      [theme.breakpoints.down('lg')]: {
        padding: '0 24px',
      },

      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },

    logoFooter: {
      '& img': {
        maxWidth: 116,
      },

      [theme.breakpoints.down('sm')]: {
        '& img': {
          maxWidth: 204,
        },
      },
    },

    footTop: {
      borderTop: '1px solid rgba(56, 68, 100, 0.5)',
      padding: '39px 0 16px',
      display: 'flex',
      gridGap: '105px',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: '40px',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '20px 0 16px',
        gridGap: '30px',
      },
    },

    detailFoot: {
      display: 'flex',
      gridGap: '150px',

      [theme.breakpoints.down('sm')]: {
        gridGap: '70px',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gridGap: '0px',
        textAlign: 'center',
      },
    },

    menuFooter: {},

    listMenuFooter: {
      marginTop: 0,
      '& li': {
        paddingBottom: 16,
      },
    },

    itemMenuFooter: {
      fontSize: '18px',
      color: theme.custom.colors.textdesc,
      '&:hover': {
        color: theme.custom.colors.lightPurple,
        textDecoration: 'none',
      },
    },

    itemMenuFooterActive: {
      color: theme.custom.colors.lightPurple,
    },

    styleContact: {
      paddingTop: 8,
    },

    titleContact: {
      fontFamily: 'Titillium Web',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      color: theme.custom.colors.textdesc,
      cursor: 'pointer',
    },

    detailContact: {
      fontSize: '18px',
      lineHeight: '27px',
      color: theme.custom.colors.textdesc,
    },

    footBottom: {
      borderTop: '1px solid rgba(56, 68, 100, 0.5)',
      padding: '13px 0 30px',
      display: 'flex',
      justifyContent: 'center',
    },

    textBottom: {
      fontSize: '18px',
      lineHeight: '27px',
      color: theme.custom.colors.textdesc,
      fontWeight: 400,
    },
  };
});

export default useStyles;
