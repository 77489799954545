import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    label: {
      fontSize: "14px",
      lineHeight: "140%",
      fontFamily: "Ubuntu-Medium",
    },

    description: {
      fontSize: "12px",
      lineHeight: "14px",
      marginTop: "5px",
    },

    regular: {
      // marginTop: "15px",
      width: "fit-content",
      borderRadius: "6px",
      color: theme.custom.colors.boldGray,
      backgroundColor: "unset",
      border: `1px solid ${theme.custom.colors.boldGray}`,
      fontFamily: "Ubuntu-Bold",
      fontSize: "12px",
      lineHeight: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      padding: '12px 10px',
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "unset",
      },
    },

    fileContainer: {
      padding: "17px 0 14px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${theme.custom.colors.comet}`
    },

    itemUploaded: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "85%",
    },

    fileName: {
      marginLeft: "5px",
      fontSize: "14px",
      lineHeight: "140%",
      width: "90%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

    deleteBtn: {
      color: theme.custom.colors.lightPurple,
      textDecoration: "underline",
      cursor: "pointer",
    },

    msg: {
      marginTop: "15px",
      color: theme.custom.colors.red2,
    },

    visuallyHidden: { 
      position: "absolute",
      height: "1px", 
      width: "1px",
      overflow: "hidden",
      clip: "rect(1px, 1px, 1px, 1px)",
      whiteSpace: "nowrap",

      "&:focus + label": {
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)"
      },
      "& + label::before": {
        color: "red"
      },
      "&:focus + label::before": {
        color: "black"
      },
    },

    btn: {
      marginTop: "15px",

      "& [type='file'] + label": {
        cursor: "pointer",
        display: "inline-block",
        position: "relative",
        transition: "all 0.3s",
        verticalAlign: "middle",
        overflow: "hidden",
      },

      '& [type="file"]:focus + label': {
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
        transition: "all 0.8s",
        background: "rgb(255 255 255 / 20%)",
      },
      '& [type="file"]:active:before + label': {
        transition: "0",
        background: "white",
        margin: 0
      },
    }
  };
});

export default useStyles;
