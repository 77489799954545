import { BallTriangle } from '@agney/react-loading';
import useStyles from './styles';

const AppLoading = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.loadingElement}><BallTriangle /></div>
    </div>
  )
}

export default AppLoading;