import { HTTPBaseService } from "src/request/HTTPBaseService";

export class SecurityService extends HTTPBaseService {
  private static classInstance?: SecurityService;

  public static getInstance(token: string) {
    if (!this.classInstance) {
      this.classInstance = new SecurityService();
    }
    return this.classInstance;
  }

  public async creatWithdrawalAddress(data: any) {
    return await this.instance.put("/addresses/", data);
  }

  public async active2FADevices() {
    return await this.instance.post("/private/2fa_devices/", { task: "setup" });
  }

  public async confirmCodeFromEmail(data: any) {
    return await this.instance.post("/private/2fa_devices/", data);
  }

  public async remove2FADevices(request: any) {
    return await this.instance.delete("/private/2fa_devices/", { data: request });
  }

  public async resetPassword(data: any) {
    return await this.instance.put('/account/change_password/', data);
  }

  public async confirmChangePassword(data: any) {
    return await this.instance.post('/account/change_password/', data);
  }

  public async confirmationEmail() {
    return await this.instance.post('/private/user/send_verification_email/');
  }

}

export const securityService = new (SecurityService)();