import { combineReducers } from 'redux';
import { messageReducer } from './message';
import { themeModeReducer } from './theme-mode';
import { customizationReducer } from './customization';
import { accountReducer } from './account';
import { withdrawalReducer } from './withdrawal';
import { marketReducer } from './market';
import { addressReducer } from './address';
import { assetReducer } from './asset';
import { chainReducer } from './chain';
import { LoadingReducer } from './loading';
import { AuthenticationReducer } from './authentication';
import { confirmReducer } from './modal-confirm';
import { tradeReducer } from './trade';
const rootReducer = combineReducers({
  messages: messageReducer,
  themeMode: themeModeReducer,
  customization: customizationReducer,
  accountReducer: accountReducer,
  marketReducer: marketReducer,
  withdrawal: withdrawalReducer,
  address: addressReducer,
  asset: assetReducer,
  chain: chainReducer,
  loading: LoadingReducer,
  auth: AuthenticationReducer,
  modalConfirm: confirmReducer,
  trade: tradeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
