import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
      mainApi:{
        backgroundImage: "url(/images/bgr.svg)",
        backgroundSize: "inherit",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundOrigin: "border-box",
        fontFamily: "Titillium Web",
      },

      mainCon: {
        display: "flex",
        gridGap: 50,
        paddingTop: 330,
        paddingBottom: 180,
         fontSize: "18px",

        [theme.breakpoints.between('lg', 'xl')]: {
          gridGap: 87,
        },

        [theme.breakpoints.down('sm')]: {
          flexDirection: "column-reverse",
          textAlign: "center",
          paddingTop: 80,
          paddingBottom: 100,
          gridGap: 80,
        },

        [theme.breakpoints.down('xs')]: {
          paddingTop: 70,
          paddingBottom: 0,
        },
      },

      contentAPI: {
        overflow: "hidden",
        [theme.breakpoints.down('lg')]: {
          display: "none",
        },
        [theme.breakpoints.up('lg')]: {
          display: "block",
          padding: 0,
        },
        [theme.breakpoints.down('sm')]: {
          display: "block",
        }
      },

      mainApiTab: {
        paddingTop: 170,
        paddingBottom: 80,
        display: "flex",
        gridGap: "50px",
        paddingRight: 24,

        [theme.breakpoints.up('lg')]: {
          display: "none",
         
        },
        [theme.breakpoints.down('lg')]: {
          gridGap: "110px",
        },

        [theme.breakpoints.between("991", "1100")]: { 
          gridGap: "50px",
        },

        [theme.breakpoints.down('sm')]: {
          display: "none",
        }
      },

      imgStyle: {
        "& img":{
          [theme.breakpoints.down('lg')]: {
            width: 653,
          },

          [theme.breakpoints.down('sm')]: {
            maxWidth: 665,
            width: "100%",
          },
          [theme.breakpoints.down('xs')]: {
            display: "none",
          }
        },
      },

      imgStyleMobile: {
        [theme.breakpoints.up('sm')]: {
          display: "none",
        }
      },

      rightAPI:{
          paddingTop: 23,

          [theme.breakpoints.between('lg', 'xl')]: {
            paddingTop: 0,
          },

          [theme.breakpoints.down('lg')]: {
            paddingTop: 15, 
          },

          [theme.breakpoints.down('sm')]: {
           padding: "15px 25px 0",
          },

          [theme.breakpoints.down('xs')]: {
            padding: "15px 0",
            textAlign: "left",
           },

      },

      mainRight: {},
      
      titleAPI: {
        fontWeight: 700, 
        fontSize: "64px", 
        fontFamily: "Titillium Web",
        color: theme.custom.colors.textdesc,

        [theme.breakpoints.down('lg')]: {
          fontSize: "32px", 
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: "64px", 
        },
      },
      
      textAPI: {
        fontSize: "20px",
        color: theme.custom.colors.textdesc,

        [theme.breakpoints.down('lg')]: {
          fontSize: "18px", 
          marginBottom: "23px",
        },

        [theme.breakpoints.up('lg')]: {
          fontSize: "20px",
        },
      },

      btnAPI: {
        background:theme.custom.colors.btnSignUp,
        border: "1px solid #7F63F4",
        boxSizing: "border-box",
        borderRadius: "6px",
        width: 188,
        height: 50,
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "21px",
        textAlign: "center",
        color: theme.custom.colors.white,
        textTransform: "none",
        fontFamily: "Titillium Web",

        "&:hover": {
          background: theme.custom.colors.hoverButton,
          border: "1px solid #563BCA",
          },
        
        [theme.breakpoints.down('lg')]: {
          marginTop: 13,
        },

        [theme.breakpoints.down('xs')]: {
          width: 157,
          height: 40,
        },
      },

      styleTab: {},
  };
});

export default useStyles;