import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import moment from 'moment';
import { Button, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';

import { OrderRequestParams, OrderService } from 'src/services/OrderService';
import { marketService } from 'src/services/MarketService';

import DownloadCSV from '../DownloadCSV';
import ModalCustom from 'src/components/Base/ModalCustom';
import AppButton from 'src/components/Base/AppButton';
import DateRangePicker from 'src/components/Base/DateRangePicker';
import LoadingOverlay from '../LoadingOverlay';
import Select from 'src/components/Base/Select';
import ModalSignUp from 'src/routing/TradingPage/ComponentTradingEntry/ModalSignUp';
import GridDataPagination from 'src/components/Base/GridDataPagination';

import { toCapitalized } from 'src/utils/common';
import { useAppSelector } from 'src/hooks';

import TableStyle from 'src/styles/TableStyle';
import useStyles from '../styles';

const orderService = OrderService.getInstance();

const maxPageSize = 100;
const maxDate = new Date();
const pageSize = 10;

const OrderReport = (props: any) => {
  const styles = useStyles();
  const tableStyle = TableStyle();
  const [rowHeight] = useState(60);
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLogged = useAppSelector((state) => state.auth.isLogged);

  const [dateRange, setDateRange] = useState([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);
  const [startDate, endDate] = dateRange;

  const [currencies, setCurrencies] = useState<any[]>([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [orderType, setOrderType] = useState('');
  const orderTypes = [
    {
      label: 'Sell',
      value: 'sell',
    },
    {
      label: 'Buy',
      value: 'buy',
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);

  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const CSVheader = useMemo(
    () => [
      { key: 'created_at', name: 'Time/date' },
      { key: 'client_order_id', name: 'Order ID' },
      { key: 'symbol', name: 'Symbol' },
      { key: 'filled_amount', name: 'Amount' },
      { key: 'side', name: 'Side' },
      // { key: 'type', name: 'Type' },
      { key: 'price', name: 'Price' },
      { key: 'cost', name: 'Cost' },
      { key: 'proceeds', name: 'Proceeds' },
      { key: 'state', name: 'Status' },
    ],
    []
  );

  const history = useHistory();

  const hideFooter = rowCount <= 0;
  const footerHeight = hideFooter ? 16 : 62;

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Time/date',
      minWidth: matchUpSm ? 230 : 195,
      flex: 0.8,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <span className={styles.breakSpaces}>
          {params.value && moment.unix(params.value as number).toLocaleString()}
        </span>
      ),
    },
    {
      field: 'client_order_id',
      headerName: 'Order ID',
      minWidth: matchUpSm ? 250 : 205,
      flex: 1,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <span className={styles.breakSpaces}>{params.value}</span>
      ),
    },
    {
      field: 'symbol',
      headerName: 'Symbol',
      minWidth: matchUpSm ? 150 : 135,
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'filled_amount',
      headerName: 'Amount',
      minWidth: 110,
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'side',
      headerName: 'Side',
      minWidth: 100,
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <span>{toCapitalized(params.value as string)}</span>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      minWidth: 140,
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      minWidth: 140,
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        const currencies = (
          params.getValue(params.id, 'symbol') as string
        ).split('/');
        return (
          <>
            {params.value &&
              `${params.value} ${
                params.getValue(params.id, 'side') === 'buy'
                  ? currencies[1]
                  : currencies[0]
              }`}
          </>
        );
      },
    },
    {
      field: 'proceeds',
      headerName: 'Proceeds',
      minWidth: 130,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        const currencies = (
          params.getValue(params.id, 'symbol') as string
        ).split('/');
        return (
          <>
            {params.value &&
              `${params.value} ${
                params.getValue(params.id, 'side') === 'sell'
                  ? currencies[1]
                  : currencies[0]
              }`}
          </>
        );
      },
    },
    {
      field: 'state',
      headerName: 'Status',
      minWidth: 120,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <div
          className={clsx(styles.status, {
            [styles.statusConfirmed]: params.value === 'CONFIRMED',
            [styles.statusFilled]: params.value === 'FILLED',
            [styles.statusCancelled]:
              params.value === 'CANCELLED' ||
              params.value === 'EXEPTION' ||
              params.value === 'EXPIRED',
          })}
        >
          {toCapitalized(params.value as string)}
        </div>
      ),
    },
  ];

  const getAssets = async () => {
    try {
      const { data } = await marketService.getAllMarkets();
      setCurrencies(data.markets.map((el: any) => ({ value: el, label: el })));
    } catch (error) {}
  };

  const getOrders = async (params?: OrderRequestParams) => {
    try {
      setLoading(true);
      const { data } = await orderService.get(params);
      setOrders(
        data.items.map((item) => ({ ...item, id: item.client_order_id }))
      );
      setRowCount(data.items_total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getDownloadData = async () => {
    const startDate = moment().subtract(90, 'days').startOf('day').unix();

    const {
      data: { items: firstPage, pages_total },
    } = await orderService.get({
      page_size: maxPageSize,
      created_start: startDate,
    });

    let otherPages = [];
    if (pages_total > 1) {
      const restResponse = await Promise.all(
        Array(pages_total - 1)
          .fill(1)
          .map((el, index) => {
            return orderService.get({
              page: index + 2,
              page_size: maxPageSize,
              created_start: startDate,
            });
          })
      );
      otherPages = restResponse.map((el) => el.data.items).flat();
    }

    return firstPage.concat(otherPages).map((el) => ({
      ...el,
      side: toCapitalized(el.side),
      state: toCapitalized(el.state),
      created_at: moment.unix(el.created_at).toLocaleString(),
    }));
  };

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    getOrders({
      page: currentPage + 1,
      page_size: pageSize,
      side: orderType,
      symbol: selectedCurrencies,
      created_start: startDate && moment(startDate).startOf('day').unix(),
      created_end: endDate && moment(endDate).endOf('day').unix(),
    });
  }, [currentPage, orderType, selectedCurrencies, startDate, endDate]);

  const renderFilters = (
    <>
      <Select
        canDeselect
        value={selectedCurrencies}
        options={isLogged ? currencies : []}
        className={styles.dropdownBoxReport}
        inputClassName={styles.selectInput}
        placeholder="All markets"
        onChange={(value: any) => {
          setSelectedCurrencies(value);
          setCurrentPage(0);
        }}
      />
      <Select
        canDeselect
        value={orderType}
        options={isLogged ? orderTypes : []}
        className={styles.dropdownBoxReport}
        inputClassName={styles.selectInput}
        placeholder="All orders"
        onChange={(value: string) => {
          setOrderType(value);
          setCurrentPage(0);
        }}
      />
      <div className={styles.boxDatePicker}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate}
          placeholderText="Start date - End date"
          dateFormat="yyyy/MM/dd"
          onChange={(update: any) => {
            setDateRange(update);
            setCurrentPage(0);
          }}
        />
      </div>
    </>
  );
  return (
    <section className={styles.root}>
      <div className={styles.headPage}>
        <div className={styles.left}>
          <Hidden xsDown>{renderFilters}</Hidden>
          <Hidden smUp>
            <Button
              variant="contained"
              className={`${styles.btnFilters}`}
              onClick={() => setModalOpen(true)}
            >
              Filters
            </Button>
            <ModalCustom
              isOpen={modalOpen}
              isIconClose
              handleClose={() => setModalOpen(false)}
              className={styles.mobileFilter}
            >
              <div className={styles.modalTitle}>Filters</div>
              <div>{renderFilters}</div>
              <div className={styles.closeBtn}>
                <AppButton fullWidth onClick={() => setModalOpen(false)}>
                  OK
                </AppButton>
              </div>
            </ModalCustom>
          </Hidden>
        </div>
        <div className={styles.right}>
          <div className={`${styles.btnDownload}`}>
            <DownloadCSV
              header={CSVheader}
              fileName={`${moment().format('DD.MM.YYYY')}-orders.csv`}
              getData={getDownloadData}
            />
          </div>
        </div>
      </div>

      <div
        className={tableStyle.customerDataGrid}
        style={{
          height: `${46 + Number(orders.length) * rowHeight + footerHeight}px`,
          maxHeight: `${46 + 10 * rowHeight + footerHeight}px`,
          minHeight: `${
            46 + (orders.length > 0 ? 1 : 6) * rowHeight + footerHeight
          }px`,
        }}
      >
        <DataGrid
          loading={loading}
          rows={orders}
          columns={columns}
          page={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={46}
          rowHeight={rowHeight}
          hideFooter={hideFooter}
          pagination
          paginationMode="server"
          onPageChange={(page) => setCurrentPage(page)}
          components={{
            Pagination: GridDataPagination,
            NoRowsOverlay: () => (
              <div className={tableStyle.rowEmptydata}>
                <span
                  className={`icon-no-data ${tableStyle.iconEmptydata}`}
                ></span>
                <div className={tableStyle.textEmptydata}>No data</div>
                <div className={tableStyle.messEmptydata}>
                  You will be able to see reports after <br /> completing
                  transactions
                </div>
                <Button
                  variant="contained"
                  className={`${tableStyle.btnEmptydata}`}
                  onClick={() => {
                    isLogged ? history.push('/trading') : setSignUpModal(true);
                  }}
                >
                  {isLogged ? 'Start trading' : 'Sign Up'}
                </Button>
              </div>
            ),
            LoadingOverlay: LoadingOverlay,
          }}
        />
      </div>
      <ModalSignUp
        isOpen={signUpModal}
        handleClose={() => setSignUpModal(false)}
      />
    </section>
  );
};

export default OrderReport;
