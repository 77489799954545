import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainPermisson: {
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      marginTop: 20,
      padding: 20,
    },

    titleKey: {
      fontSize: 20,
      fontFamily: 'Ubuntu-Regular',
      lineHeight: '140%',
      fontWeight: 700,
      color: theme.custom.colors.titleTrading,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },

    styleText: {
      color: theme.custom.colors.white2,
      fontSize: '16px',
      lineHeight: '22px',
      fontFamily: 'Ubuntu-Regular',
    },

    listItem: {},

    styleLi: {
      display: 'flex',
      gridGap: '40px',
      fontFamily: 'Ubuntu-Regular',

      '& p': {
        margin: 0,
        color: theme.custom.colors.white4,
        fontSize: 16,
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gridGap: '0px',
        marginBottom: 20,
      },
    },

    link: {
      width: 189,

      '& p': {
        color: theme.custom.colors.colorStep,
        fontSize: 16,
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 190,
      },
    },

    styleKey: {
      color: theme.custom.colors.colorLink,
      fontSize: '16px',
      lineHeight: '22px',

      '&:hover': {
        textDecoration: 'none',
      },
    },
  };
});

export default useStyles;
