import React from 'react';
import { Button, Typography } from '@material-ui/core';
import AppButton from 'src/components/Base/AppButton';
import ModalCustom from 'src/components/Base/ModalCustom';
import useStyles from './styles';

export const ModalDelete = ({
  isOpen,
  handleClose,
  handleDelete,
  isLoading,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  isLoading: boolean;
}) => {
  const classes = useStyles();

  return (
    <>
      <ModalCustom
        isOpen={isOpen}
        handleClose={handleClose}
        className={classes.mainModalDelete}
      >
        <div className={classes.titlePopUp}>
          <img src="/images/img-trash.svg" alt="" />
          <Typography variant="h4" className={classes.styleTitle}>
            Confirm deleting
          </Typography>

          <p className={classes.textConfirm}>
            Are you sure you want to delete this address?
          </p>

          <div className={classes.boxBtnConfirm}>
            <Button onClick={handleClose} className={classes.btnCancel}>
              Cancel
            </Button>
            <AppButton
              className={classes.delete}
              onClick={handleDelete}
              width="126"
              height="36"
              isLoading={isLoading}
            >
              {' '}
              Delete
            </AppButton>
          </div>
        </div>
      </ModalCustom>
    </>
  );
};

export default ModalDelete;
