import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    xsPagination: {
      color: theme.custom.colors.white2,
      flex: 1,

      '& ul > li:not(:empty):not(:first-child):not(:last-child)': {
        flex: 1,
        textAlign: 'center',
      },
    },
  };
});

export default useStyles;
