import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    areaSell: {
      height: 256,
      paddingRight: 45,
    },

    itemArea: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "calc(100%/8)",
      fontFamily: 'Ubuntu-Regular',
    },

    label: {
      fontSize: 14,
      lineHeight: "140%",
      height: 24,
      minWidth: 60,
      borderRadius: 5.3,
      background: theme.custom.colors.bgRowLabel,
      color: theme.custom.colors.titleTrading,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 2,
      overflow: "hidden",
      marginLeft: 15,
    },

    bgValue: {
      height: "100%",
      width: "calc(100% - 36px - 15px)",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      position: "relative",
    },

    value: {
      fontSize: 14,
      lineHeight: "140%",
      color: theme.custom.colors.titleTrading,
      paddingRight: 15,
      position: "relative",
      zIndex: 2,
    },

    bgFix: {
      background: "rgba(76, 162, 80, 0.35)",
      height: "100%",
      position: "absolute",
    },
  };
});

export default useStyles;
