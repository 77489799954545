import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    control: {},
    paddingCheck: {
      padding: 0,
    },
    controller: {},
    iconCheckbox: {
      borderRadius: 3,
      width: 20,
      height: 20,
      background: 'none',
      border: '1px solid #606983',
      outline: 1,

      'input:hover ~ &': {
        borderColor: '#7F63F4',
      },
      'input:focus ~ &': {
        borderColor: '#7F63F4',
      },
    },
    checkBox: {
      background: theme.custom.colors.btnSignUp,
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        content: '""',
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        'input:hover ~ &': {
          opacity: 0.8,
        },
      },
    },
    styleLbl: {
      userSelect: 'none',
      marginLeft: 9,
      lineHeight: '32px',
      fontSize: 14,
      fontFamily: 'Titillium Web',
      color: theme.custom.colors.white,
    },
    // Error
    iconCheckboxError: {
      borderColor: theme.custom.colors.borderError,
    },
    styleLblError: {
      color: theme.custom.colors.textError,
    },
  };
});
export default useStyles;
