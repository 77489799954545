import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: 11,
    display: 'inline-flex',
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',

    '& > input': {
      opacity: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      height: 0,
      width: 0,
    },
  },
  disabled: {},
  label: {
    fontSize: 14,
    fontFamily: 'Ubuntu-Regular',
  },
  icon: {
    width: '20px',
    height: '20px',
    border: '1px solid',
    borderColor: theme.custom.colors.grey2,
    borderRadius: '2px',
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',

    '$container:hover  &': {
      borderColor: theme.custom.colors.cornflowerBlue,
    },

    '$disabled &': {
      background: theme.custom.colors.grey3,
    },
  },
  checkedIcon: {
    borderColor: theme.custom.colors.cornflowerBlue,
    background: theme.custom.colors.cornflowerBlue,
  },
  checkmark: {
    position: 'absolute',
    top: '4px',
    height: '9px',
    width: '6px',
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
  labelDisabled: {
    '$disabled ~ &': {
      color: theme.custom.colors.grey2,
    },
  },
}));

export default useStyles;
