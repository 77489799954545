/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';

import { displayNumb } from 'src/utils/number';
import { accountService } from 'src/services/AccountService';
import useStyles from './styles';
import ModalChart from '../ModalChart';
import AppBox from 'src/components/Base/AppBox';

const BalanceSnapshot = (props: any) => {
  const symbol = props?.symbol;
  const styles = useStyles();
  const [dataBalanceSnapshot, setDataBalance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [defaultAsset, setDefaultAsset] = useState('BTC');
  const isLogged = useSelector((state: any) => state.auth.isLogged);

  useEffect(() => {
    if (isLogged && symbol !== '') {
      getBalances();
    }
  }, [symbol]);

  const orderBalanceSymbol = (symbol: string, dataBalanceSnapshot: any) => {
    if (symbol && symbol !== '') {
      const baseAssetSymbol = symbol.split('/')[0];
      const quoteAssetSymbol = symbol.split('/')[1];
      let balanceData: any = [];
      if (dataBalanceSnapshot && dataBalanceSnapshot.length > 0) {
        const hasBaseAsset = dataBalanceSnapshot.find(
          (x: any) => x.symbol === baseAssetSymbol
        );
        const hasQuoteAsset = dataBalanceSnapshot.find(
          (x: any) => x.symbol === quoteAssetSymbol
        );

        // push base asset
        if (hasBaseAsset) {
          balanceData.push(hasBaseAsset);
        } else {
          balanceData.push({
            asset: baseAssetSymbol,
            currency: baseAssetSymbol,
            icon: `/images/logos/${baseAssetSymbol.toLowerCase()}.svg`,
            name: baseAssetSymbol,
            symbol: baseAssetSymbol,
            value: '0',
          });
        }

        // push quote asset
        if (hasQuoteAsset) {
          balanceData.push(hasQuoteAsset);
        } else {
          balanceData.push({
            asset: quoteAssetSymbol,
            currency: quoteAssetSymbol,
            icon: `/images/logos/${quoteAssetSymbol.toLowerCase()}.svg`,
            name: quoteAssetSymbol,
            symbol: quoteAssetSymbol,
            value: '0',
          });
        }
      }

      // filter other coin difference current symbol
      const otherCoin = dataBalanceSnapshot.filter(
        (x: any) =>
          x.symbol !== baseAssetSymbol && x.symbol !== quoteAssetSymbol
      );
      balanceData = balanceData.concat(otherCoin);
      setDataBalance(balanceData);
    }
  };

  const getBalances = async () => {
    try {
      const resultData = await accountService.getBalances();
      if (resultData && resultData?.data && resultData?.data?.balances) {
        const filterBalances = resultData.data.balances;
        // .filter(
        // (x: any) => x.total >= 0
        // );
        const balances = filterBalances.map((x: any) => {
          const obj = {
            asset: x.asset,
            icon: `/images/logos/${x.asset.toLowerCase()}.svg`,
            symbol: x.asset,
            currency: x.asset,
            name: x.asset,
            value: x.free,
          };
          return obj;
        });

        // set data balance snapshot
        orderBalanceSymbol(props.symbol, balances);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOpenChart = (asset: string) => {
    setDefaultAsset(asset);
    setOpen(true);
  };
  return (
    <>
      {!isLogged && <div className={styles.overlay}></div>}

      <div className={styles.balanceSnapshot}>
        <h2 className={styles.title}>Balance Snapshot</h2>
        <AppBox
          className={styles.listBalanceSnapshots}
          loading={loading}
          hasData={dataBalanceSnapshot?.length > 0}
        >
          {dataBalanceSnapshot?.map((item: any, index: number) => {
            return (
              <div className={styles.itemBox} key={index}>
                <div className={styles.label}>
                  <div className={styles.icon}>
                    <img src={item.icon} alt="" />
                  </div>
                  <div className={styles.name}>{item.name}</div>
                </div>
                <div className={styles.value}>
                  {displayNumb(item.value)} {item.currency}
                </div>
                <Button
                  variant="contained"
                  className={`${styles.btnTrading}`}
                  onClick={() => handleOpenChart(item.asset)}
                >
                  <span className="icon-trading"></span>
                </Button>
              </div>
            );
          })}
        </AppBox>
        <ModalChart
          defaultAsset={defaultAsset}
          isOpen={isOpen}
          handleClose={() => setOpen(false)}
        />
      </div>
    </>
  );
};

export default BalanceSnapshot;
