/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as tradeAction from 'src/store/actions/trade';
import {
  TRADE_DEFAULT_TOKEN_BUY,
  TRADE_SIDE,
  ORDER_TYPE,
  TRADE_AMOUNT,
} from 'src/commons/constants/Constants';
import { getOrderedCoinMarket } from 'src/utils/market';
import { marketService } from 'src/services/MarketService';
import { getAmountData } from 'src/utils/number';
import { validationAmount } from 'src/utils/validation';

import ModalConfirm from './ModalConfirm';
import ModalCompleted from './ModalCompleted';
import FlashTradingCard from 'src/components/FlashTradingCard';
import { showModalMessage } from 'src/store/actions/message';
import { ModalMessageType } from 'src/store/reducers/message';
import AppLoading from 'src/components/Base/AppLoading';
import useStyles from './styles';

const ComponentFlashTrading = (props: any) => {
  const styles = useStyles();
  const { favCoins, allMarkets, handleChangeFav, isFlashTrade } = props;
  const FAVORITE_SYMBOL = '-1';

  const dispatch = useDispatch();
  const [orderCoins, setOrdercoins] = useState(Array<string>());
  const [dataFlashTrading, setDataTrading] = useState(Array<any>());
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [isOpenCompleted, setOpenCompleted] = useState(false);
  const [trade, setTrade] = useState();
  const [activeCoin, setActiveCoin] = useState({
    name: TRADE_DEFAULT_TOKEN_BUY,
  });
  const [loading, setLoading] = useState(false);
  const marketState = useSelector((state: any) => {
    return state.marketReducer;
  });
  const allMarket = marketState?.allMarket;
  const allLimit = marketState?.allLimit;

  useEffect(() => {
    if (allMarket && allMarket.length > 0) {
      const { orderedCoins } = getOrderedCoinMarket(activeCoin.name, allMarket);
      setOrdercoins(orderedCoins);
    }
  }, [allMarket]);

  useEffect(() => {
    if (activeCoin.name === FAVORITE_SYMBOL) {
      getFavoriteCoins();
    } else {
      getPairsCoin(activeCoin.name);
    }
  }, [activeCoin.name]);

  const handleOpen = (item: any) => {
    setOpenConfirm(true);
    setTrade(item);
  };

  const getFavoriteCoins = async () => {
    if (favCoins && favCoins.length > 0) {
      await getListFlashTrading(favCoins);
    } else {
      setDataTrading([]);
    }
  };

  const getPairsCoin = async (coin: string) => {
    if (allMarkets && allMarkets.length > 0) {
      let pairs = allMarkets.filter((p: string) => p?.split('/')[0] === coin);

      // when select trade cash coin
      if (pairs && pairs.length === 0) {
        pairs = allMarkets.filter((p: string) => p?.split('/')[1] === coin);
      }
      await getListFlashTrading(pairs);
    }
  };

  const getListFlashTrading = async (pairs: any) => {
    try {
      const bodyRequset = [];
      for (let item of pairs) {
        const baseCoinLimit = allLimit[item.split('/')[0]];
        if (!baseCoinLimit) continue;

        // create buy request object params
        bodyRequset.push({
          symbol: item,
          amount: baseCoinLimit?.min ?? TRADE_AMOUNT.min,
          side: TRADE_SIDE.buy,
        });

        // create sell request object params
        bodyRequset.push({
          symbol: item,
          amount: baseCoinLimit?.min ?? TRADE_AMOUNT.min,
          side: TRADE_SIDE.sell,
        });
      }

      // get price buy, sell of pairs
      const resultData = await marketService.priceAmount(bodyRequset);
      if (resultData && resultData?.data) {
        mapPriceTradingCard(pairs, resultData.data);
      }
    } catch (error) {
      setDataTrading([]);
    }
  };

  const mapPriceTradingCard = (pairs: any, dataList: any) => {
    const resultData: any = [];
    pairs.map((item: any) => {
      const baseCoinLimit = allLimit[item.split('/')[0]];
      if (baseCoinLimit) {
        const pairData = dataList.filter((x: any) => x.symbol === item);
        const buy = pairData.find((x: any) => x.side === TRADE_SIDE.buy);
        const sell = pairData.find((x: any) => x.side === TRADE_SIDE.sell);
        const obj = {
          symbol: item,
          amount: buy.amount,
          buy: buy,
          sell: sell,
        };
        resultData.push(obj);
      }
    });
    setDataTrading(resultData);
  };

  const handleOpenCompleted = () => {
    setOpenCompleted(false);
    setOpenConfirm(false);
  };

  const handleTrade = async (pairInfo: any, side: string) => {
    setLoading(true);
    try {
      // get trade data
      const tradeData = await getTradeData(pairInfo, side);

      // only trade when get data
      if (tradeData) {
        // case allow 1 click trading
        if (isFlashTrade) {
          setTrade(tradeData);
          await handleConfirmTrade(tradeData);
          return;
        }

        // case not allow 1 click trading
        handleOpen(tradeData);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getTradeData = async (pairInfo: any, side: string) => {
    const baseCoin = allLimit[pairInfo.symbol.split('/')[0]];
    let refreshData = dataFlashTrading;
    let tradeData;

    // validation amount
    let error = validationAmount(pairInfo.amount, baseCoin);
    if (
      !pairInfo.amount ||
      (pairInfo.amount && pairInfo.amount === '') ||
      isNaN(Number(pairInfo.amount))
    ) {
      error = `min amount ${baseCoin['min']}`;
    }
    const indx = refreshData.findIndex(
      (x: any) => x?.symbol === pairInfo.symbol
    );
    if (error !== '') return;

    try {
      let value = pairInfo.amount;
      value = getAmountData(value, baseCoin);

      // get trade data
      const bodyRequest = [
        {
          symbol: pairInfo.symbol,
          amount: value,
          side: side,
        },
      ];
      const result = await marketService.priceAmount(bodyRequest);
      if (result && result?.data) {
        const pairData = result?.data ?? [];
        tradeData = pairData.find((x: any) => x.side === side);
      }
      return tradeData;
    } catch (error) {
      const pairData = refreshData[indx];
      pairData.hasError = error;
      refreshData[indx] = pairData;
      setDataTrading(refreshData);
      return;
    }
  };

  const onChangeAmount = async (item: any, event: any) => {
    let value = event.target.value;
    let refreshData = dataFlashTrading;

    // find index of pair flash trading card
    const indx = refreshData.findIndex((x: any) => x?.symbol === item.symbol);
    const baseCoin = allLimit[item.symbol.split('/')[0]];
    const pairData = refreshData[indx];
    pairData.amount = value;

    //get validation input
    pairData.hasError = validationAmount(value, baseCoin);
    refreshData[indx] = pairData;
    setDataTrading([...refreshData]);
  };

  const handleFavorieFilter = async () => {
    setActiveCoin({ name: FAVORITE_SYMBOL });
  };

  const handleChangeFavoriePair = async (pair: string) => {
    let listFav = favCoins ?? [];
    const isFavCoin = listFav?.find((p: string) => p === pair);

    // remove favourite coin
    if (isFavCoin) {
      listFav = listFav.filter((x: string) => x !== pair);
    } else {
      listFav.push(pair);
    }

    // put favourite
    await handleChangeFav(listFav);

    // refresh if active is favourite
    if (activeCoin.name === FAVORITE_SYMBOL) {
      if (!listFav || (listFav && listFav.length === 0)) {
        setDataTrading([]);
        return;
      }
      await getListFlashTrading(listFav);
    }
  };

  const handleConfirmTrade = async (data: any) => {
    let message: ModalMessageType = {
      type: 'ERROR',
      msg: 'Server error',
    };

    try {
      setLoading(true);
      const execData = await tradeAction.addOrder(data, ORDER_TYPE.BEST_RATE);
      if (execData && execData.data) {
        setOpenCompleted(true);
        setOpenConfirm(false);
      }
    } catch (error: any) {
      setLoading(false);
      let errMsg = error?.response?.data;
      if (errMsg) {
        let refreshData = dataFlashTrading;
        const indx = refreshData.findIndex(
          (x: any) => x?.symbol === data.symbol
        );
        const pairData = refreshData[indx];
        pairData.hasError = errMsg?.exception;
        refreshData[indx] = pairData;
        setDataTrading([...refreshData]);
        return;
      }
      dispatch(showModalMessage(message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.componentFlashTrading}>
      <ul className={styles.listFilterHead}>
        {FAVORITE_SYMBOL === activeCoin.name.toUpperCase() ? (
          <li
            className={`${styles.activeFilter} ${styles.itemFilter} `}
            onClick={handleFavorieFilter}
          >
            <span className="icon-favorite">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </li>
        ) : (
          <li className={styles.itemFilter} onClick={handleFavorieFilter}>
            <span className="icon-star2"></span>
          </li>
        )}

        {orderCoins?.map((item, idx: number) => {
          return (
            <li
              className={`${styles.itemFilter} ${item?.toUpperCase() === activeCoin.name.toUpperCase() &&
                styles.activeFilter}`}
              key={idx}
              onClick={() => setActiveCoin({ name: item })}
            >
              <>
                <img
                  className={styles.itemIcon}
                  src={`/images/logos/${item?.toLowerCase()}.svg`}
                  alt=""
                />
                <span className={styles.itemName}>{item}</span>
              </>
            </li>
          );
        })}
      </ul>

      <div className={styles.contentTabFlashTrading}>
        {loading && !isOpenConfirm && isFlashTrade && (
          <div className={styles.loading}>
            <AppLoading />
          </div>
        )}
        {dataFlashTrading?.map((item, indx) => {
          return (
            <div key={indx}>
              <FlashTradingCard
                item={item}
                favCoins={favCoins}
                handleTrade={handleTrade}
                onChangeAmount={onChangeAmount}
                onChangeFav={handleChangeFavoriePair}
              />
            </div>
          );
        })}
      </div>

      {isOpenConfirm && (
        <ModalConfirm
          isOpen={isOpenConfirm}
          handleClose={() => setOpenConfirm(false)}
          handleOpen={() => handleConfirmTrade(trade)}
          data={trade}
          isLoading={loading}
        />
      )}

      {isOpenCompleted && (
        <ModalCompleted
          isOpen={isOpenCompleted}
          handleClose={() => setOpenCompleted(false)}
          handleComplete={handleOpenCompleted}
          data={trade}
        ></ModalCompleted>
      )}
    </div>
  );
};

export default ComponentFlashTrading;
