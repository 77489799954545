import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    rootSupport: {
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
        width: 335,
      },
    },
    paper: {
      padding: 20,
      color: theme.custom.colors.boldGray,
      borderRadius: 8,
      background: theme.custom.colors.cardBackground,
      [theme.breakpoints.down('xs')]: {
        padding: '20px 10px',
      },
    },
    icon: {
      fontSize: 36,
      marginTop: -5,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    titleSize1: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 10,
    },
    titleSize2: {
      fontSize: 14,
      height: 60,
      color: theme.custom.colors.white2,
      [theme.breakpoints.down('md')]: {
        height: 75,
      },

      [theme.breakpoints.down('sm')]: {
        height: 50,
      },

      [theme.breakpoints.down('xs')]: {
        width: 267,
        height: 60,
      },
    },
    content: {
      display: 'flex',
      fontFamily: 'Ubuntu-Regular',
      color: theme.custom.colors.boldGray,
    },
    btn: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '10px',
      marginTop: 20,

      '& .MuiButton-root': {
        '@media (min-width: 900px) and (max-width: 1100px)': {
          width: '108px !important',
          padding: 0,
        },
      },

      '& .MuiButton-root:first-child': {
        '@media (min-width: 900px) and (max-width: 1100px)': {
          width: '94px !important',
          padding: 0,
        },
      },

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
      '& a': {
        '&:focus-visible': {
          outline: 'none'
        }
      }

    },
    btnContent: {
      fontSize: 12,
    },
    marginRight: {
      marginRight: 20,
      [theme.breakpoints.down('xs')]: {
        marginRight: 14,
      },
    },
    linkButton: {
      textDecoration: 'none',
    },
  };
});

export default useStyles;
