import { AssetService } from 'src/services/AssetService';
import { ASSET_SELECTED, ASSET_SET } from '../constants/asset';

export const AssetAction = {
  set: () => async (dispatch: any) => {
    const assetService = AssetService.getInstance();
    const res = await assetService.getAssets();
    if (res.status === 200 && res.data?.assets)
      dispatch({
        type: ASSET_SET,
        payload: res.data.assets,
      });
  },
  selectAsset: (asset?: string | null) => ({
    type: ASSET_SELECTED,
    payload: asset,
  }),
};
