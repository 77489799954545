/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // Change path
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Scroll to section
  useEffect(() => {
    const idSection = (window as any).location.hash;

    if (idSection) {
      setTimeout(() => {
        try {
          (document as any).querySelector(idSection)?.scrollIntoView();
        } catch {
        } finally {
        }
      }, 200);
    }
  }, []);

  return null;
}
