import { message } from '../constants/message';
import { ModalMessageType } from '../reducers/message';

export const pushMessage = (msg: string) => {
  return {
    type: message.PUSH_MESSAGE,
    payload: msg,
  };
};
export const showModalMessage = ({ ...res }: ModalMessageType) => {
  return {
    type: message.TOGGLE_MODAL_MESSAGE,
    payload: {
      show: true,
      ...res,
    },
  };
};

export const hideModalMessage = () => ({
  type: message.TOGGLE_MODAL_MESSAGE,
  payload: {
    show: false,
    onOk: undefined,
    btnOk: {
      label: 'Ok',
      show: true,
    },
    btnCancel: {
      label: 'Cancel',
      show: false,
    },
  },
});
