import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {

    mainUse: {
      padding: "45px 0 100px",
      fontFamily: "Titillium Web",

      [theme.breakpoints.down('sm')]: {
        paddingBottom: 80,
      },
    },

    containerUse: {
      padding: "0px",

      [theme.breakpoints.down('lg')]: {
        padding: "0px 24px",
      },

      [theme.breakpoints.down('sm')]: {
        padding: "0px 20px",
      },
    },

    titleUse: {
      fontWeight: 700, 
      fontSize: 48, 
      fontFamily: "Titillium Web",
      color: theme.custom.colors.white,
      marginBottom: 50,

      [theme.breakpoints.down('md')]: {
        fontSize: 32, 
      },
    },

    groupBlock:{
      display: "flex",
      gridGap: "50px 50px",

      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        gridGap: "30px",
      },

    },

    blockDigital :{
      backgroundImage: "url(/images/bgr-useCase.png)",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left",
      backgroundOrigin: "border-box",
      height: 568,
      maxWidth: 440,
      padding: "32px 30px 0px",

      [theme.breakpoints.down("md")]: {
        height: 523,
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },

      [theme.breakpoints.down("xs")]: {
        padding: "20px 20px 0px",
      },
    },

    titleBlock:{
      fontFamily: "Ubuntu-Bold",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "28px",
      color: theme.custom.colors.textdesc,

      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },

    textBlock:{
      fontSize: "20px", 
      lineHeight: "30px", 
      color: theme.custom.colors.textdesc,
      fontWeight: 400,

      [theme.breakpoints.down('md')]: {
        fontSize: "18px", 
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: "20px",
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: "18px",
      }
    },

    blockProcess:{
      maxWidth: 720,
      display: "grid",
      gridGap: "50px",
      
      [theme.breakpoints.down('lg')]: {
        gridGap: "25px",
      },

      [theme.breakpoints.down('md')]: {
        gridGap: "22px",
      },

      [theme.breakpoints.down('sm')]: {
        gridGap: "30px",
        maxWidth: "100%",
      },
    },

    styleBlock: {
      background:theme.custom.colors.bgrColor,
      padding: "32px 32px 0",
      boxShadow: "0px 4px 50px rgba(127, 99, 244, 0.15)",
      borderRadius: "10px",
      height: 243,

      [theme.breakpoints.between("1440", "1920")]: { 
        height: 256,
      },

      [theme.breakpoints.down("md")]: {
        height: 235,
      },

      [theme.breakpoints.down("xs")]: {
        padding: "20px 20px 0px",
        height: "auto",
      },
    },

    blockStyle:{},

    blockDesc:{
      display: "grid",
      gridTemplateRows: "repeat(1, minmax(0,1fr))",
      gridTemplateColumns: "repeat(2, minmax(0,1fr))",
      gridGap: "50px 50px",
      marginTop: 50,

      [theme.breakpoints.down("sm")]: {
        gridTemplateRows: "repeat(1, minmax(0,1fr))",
        gridTemplateColumns: "repeat(1, minmax(0,1fr))",
        marginTop: 30,
        gridGap: "30px",
      },

      [theme.breakpoints.down("xs")]: {
       
      },
    },

    boxDesc:{
      background:theme.custom.colors.bgrColor,
      boxShadow: "0px 4px 50px rgba(127, 99, 244, 0.15)",
      borderRadius: "10px",
      padding: "32px 30px 0px",
      height: 256,

      [theme.breakpoints.down("md")]: {
        height: 219,
      },

      [theme.breakpoints.down("xs")]: {
        padding: "20px 10px 0px 20px",
        height: "auto",
      },
    },
  };
});

export default useStyles;