import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    navItems: {
      [theme.breakpoints.down('sm')]: {
        padding: '95px 145px 0',
      },
      [theme.breakpoints.between('550', '619')]: {
        padding: '95px 50px 0',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        width: '100%',
      },
    },

    itemNav: {
      display: 'flex',
      marginBottom: 30,
      fontSize: 24,
      color: theme.custom.colors.white,
      padding: 8,
      borderRadius: 7,
      textDecoration: 'none',
      alignItems: 'center',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      fontFamily: 'Titillium Web',

      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        marginBottom: 15,
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        fontSize: 20,
        marginBottom: 7,
        paddingLeft: 0,
      },
    },

    itemNavActive: {
      background: theme.custom.colors.cornflowerBlue,
      color: '#FFFFFF',
      fontFamily: 'Titillium Web',
      fontSize: 20,

      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },

    lineW: {
      background: '#C4C4C4',
      width: '100%',
      height: 1,
      marginBottom: 15,
    },

    iconItemNav: {
      fontSize: 24,
      marginRight: 10,

      '&:before': {
        color: theme.custom.colors.comet3,
      },
    },

    iconItemNavActive: {
      '&:before': {
        color: '#FFFFFF',
      },
    },

    listChild: {
      marginBottom: 15,
      padding: 0,
    },

    itemNavChild: {
      fontSize: 14,
      display: 'flex',
      color: theme.custom.colors.santaGray,
      padding: 10,
      paddingLeft: 40,
    },

    itemNavChildActive: {
      color: theme.custom.colors.white3,
      fontFamily: 'Ubuntu-Bold',
    },

    iconArrow: {
      marginLeft: 'auto',
      fontSize: 16,

      '&:before': {
        color: theme.custom.colors.santaGray2,
      },
    },

    iconArrowActive: {
      '&:before': {
        color: '#FFFFFF',
      },
    },
    btnLoginMobile: {
      background: 'none',
      borderRadius: 6,
      height: 50,
      width: 209,
      fontFamily: 'Titillium Web',
      fontSize: 14,
      color: theme.custom.colors.white,
      textTransform: 'none',
      marginRight: 15,
      boxShadow: 'none',
      border: '1px solid #FFFFFF',

      '&:hover': {
        border: '1px solid #7F63F4',
        background: 'none',
        color: theme.custom.colors.textDetail,
      },
    },
    itemNavButton: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        height: 160,
        paddingTop: 30,
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        paddingTop: 0,
      },
    },
    btnSignupMobile: {
      background: theme.custom.colors.btnSignUp,
      borderRadius: 6,
      height: 50,
      width: 209,
      fontFamily: 'Titillium Web',
      fontSize: 14,
      fontWeight: 700,
      color: theme.custom.colors.white,
      textTransform: 'none',
      marginRight: 10,
      boxShadow: 'none',

      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },

      '&:hover': {
        background: theme.custom.colors.hoverButton,
      },
    },
    btnTradeMobile: {
      width: '100%',
      marginRight: 0,
    },
  };
});

export default useStyles;
