import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { hideModalMessage } from 'src/store/actions/message';
import { ModalMessageType } from 'src/store/reducers/message';
import AppButton from 'src/components/Base/AppButton';
import ModalCustom from 'src/components/Base/ModalCustom';

import useStyles from './styles';

export default function ModalMessage() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { show, type, msg, onOk, btnCancel, btnOk }: ModalMessageType =
    useSelector((state: any) => state.messages.modal);

  return (
    <ModalCustom
      className={styles.modal}
      isOpen={show || false}
      handleClose={() => {
        dispatch(hideModalMessage());
      }}
      isIconClose={true}
    >
      <Box textAlign="center" className={styles.styleModal}>
        <div
          style={{
            marginBottom: 10,
            fontSize: 20,
          }}
        >
          {type === 'SUCCESS' ? (
            <img src="/images/icons/success2.svg" alt="success" />
          ) : (
            <img src="/images/icons/error2.svg" alt="error" />
          )}
        </div>
        <div>{msg}</div>

        <div className={styles.rowBtn}>
          {btnCancel?.show && (
            <AppButton
              mode="secondary"
              height={'36px'}
              onClick={() => dispatch(hideModalMessage())}
            >
              {btnCancel?.label}
            </AppButton>
          )}
          {btnOk?.show && (
            <AppButton
              height={'36px'}
              onClick={() => (onOk ? onOk() : dispatch(hideModalMessage()))}
            >
              {btnOk?.label}
            </AppButton>
          )}
        </div>
      </Box>
    </ModalCustom>
  );
}
