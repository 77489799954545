import { HTTPBaseService } from "src/request/HTTPBaseService";

export class DepositService extends HTTPBaseService {

    private static classInstance?: DepositService;

    public static getInstance(token: string) {
        if (!this.classInstance) {
            this.classInstance = new DepositService();
        }
        return this.classInstance;
    }

    public async getDepositAddress() {
        return await this.instance.get('/account/deposit_addresses_all/');
    }

    public async getListBanks() {
        return await this.instance.get('/bank_accounts/');
    }
}

export const depositService = new DepositService();