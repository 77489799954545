import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useStyles from './styles';

const borderRadius = require('highcharts-border-radius');
borderRadius(Highcharts);
const VolumeChart = (props: any) => {
  const { options } = props;
  const styles = useStyles();
  return (
    <div className={styles.styleChart}>
      <HighchartsReact
        containerProps={{ style: { maxHeight: '1000px', width: '100%', fontFamily: 'Ubuntu-Regular', } }}
        highcharts={Highcharts}
        options={options}
      ></HighchartsReact>
    </div>
  );
};
export default VolumeChart;
