import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    stepContainer: {
      background: theme.custom.colors.bgPagination,
      width: "680px",
      borderRadius: "8px",
      margin: "17px auto",
      padding: "40px",
      color: theme.custom.colors.titleTrading,

      "& .mt-40": {
        marginTop: "40px",
      },

      [theme.breakpoints.between('500', '620')]: {
        width: '100%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      [theme.breakpoints.down("xs")]: {
        padding: "25px 0px 40px 0",
        background: "none",
        width: "100%",
      },
    },

    title: {
      fontFamily: "Ubuntu-bold",
      fontSize: 20,
      lineHeight: "23px",
      display: "flex",
      alignItems: "center",

      "& .icon-info": {
        marginLeft: "12px",
        fontSize: 24
      },
    },

    inputContainer: {
      marginTop: "20px",
    },

    fileUpload: {
      marginBottom: "40px",
    },

    label: {
      fontFamily: "Ubuntu-Medium",
      fontSize: "14px",
      lineHeight: "20px",
      margin: "20px 0 2px",
    },

    btnNext: {
      margin: "0 -10px",
      display: "flex",
      flexDirection: "row",


      "& >div": {
        width: "50% !important",
        margin: "0 10px"
      }
    }

  };
});

export default useStyles;
