/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import useStyles from './styles';
import Select from 'src/components/Base/Select';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';

import ModalDelete from '../ModalDelete';
import { useDispatch, useSelector } from 'react-redux';
import { AssetAction } from 'src/store/actions/asset';
import { AddressBookAction } from 'src/store/actions/address';
import { WithdrawalAction } from 'src/store/actions/withdrawal';
import { addBookService } from 'src/services/AddressBookService';

import TableStyle from '../../../styles/TableStyle';
import assetConfig from 'src/commons/configs/assetsConfig.json';
import { IGetAddressParam } from 'src/services/AddressBookService';
import { ModalConfirmAction } from 'src/store/actions/modal-confirm';
import { hideModalMessage, showModalMessage } from 'src/store/actions/message';
import LoadingOverlay from 'src/routing/ReportsPage/LoadingOverlay';
import clsx from 'clsx';
import GridDataPagination from 'src/components/Base/GridDataPagination';

const ROW_HIGHT = 60;

const AddressBookComponent = (props: any) => {
  const style = useStyles();
  const dispatch = useDispatch();
  const tableStyle = TableStyle();
  const [filterByChain, setFilterByChain] = useState<string>('all');
  const [loading, setLoading] = useState(false);
  const chains = [
    { id: 'all', name: 'All addresses' },
    ...useSelector((state: any) => state.chain.items),
  ].map((v: any) => ({
    label: v.name,
    value: v.id,
  }));
  const listAddress = useSelector((state: any) => state.address.list);
  const { page_size = 6 }: IGetAddressParam = useSelector(
    (state: any) => state.address.params
  );
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [idAddress, setIdAddress] = useState('');
  const getParamSearch = () => {
    const params: IGetAddressParam = {
      page: currentPage + 1,
      page_size: page_size,
      chain_id: filterByChain !== 'all' ? filterByChain : '',
    };
    return params;
  };

  const handleDelete = (id: any) => {
    setIsOpenDelete(true);
    setIdAddress(id);
  };
  const actionDelete = async () => {
    setLoading(true);
    try {
      await addBookService.deleteAddress(idAddress).then(async (res) => {
        if (res.status === 200 || res.status === 204) {
          setIsOpenDelete(false);
          await dispatch(AddressBookAction.reload(getParamSearch()));
        }
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onWhitelistAddress = async (id: string) => {
    const res = await addBookService.mutateAddressWhitelist(id, {
      whitelist: true,
    });
    if (res.status === 200) {
      showConfirmCode(id);
    }
  };

  const showConfirmCode = async (id: string) => {
    const onOK = async (code: string, twoFA: string) => {
      dispatch(ModalConfirmAction.loading());

      try {
        // Call API
        const res = await addBookService.mutateAddressWhitelist(id, {
          whitelist: true,
          code,
          two_fa: twoFA,
        });

        if (res?.data?.whitelisted) {
          dispatch(
            showModalMessage({
              type: 'SUCCESS',
              msg: 'You have successfully whitelist address',
              onOk: async () => {
                dispatch(hideModalMessage());
                dispatch(ModalConfirmAction.hide());
                // Reload table
                setLoading(true);
                await dispatch(AddressBookAction.reload(getParamSearch()));
                setLoading(false);
              },
            })
          );
        } else {
          throw new Error();
        }
      } catch (error) {
        dispatch(ModalConfirmAction.setError(true));
      } finally {
        // Hide loading
        dispatch(ModalConfirmAction.hideLoading());
      }
    };

    // Show modal confirm code/2FA
    dispatch(
      ModalConfirmAction.show({
        onOK,
      })
    );
  };

  useEffect(() => {
    setLoading(true);

    // Fetch data
    (async () => {
      try {
        await dispatch(AddressBookAction.reload(getParamSearch()));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const columns: GridColDef[] = [
    {
      field: 'asset',
      headerName: 'Asset',
      sortable: false,
      minWidth: matchUpMd ? 300 : 150,

      renderCell: (params: GridValueGetterParams) => {
        const asset = params.row.asset;
        const objAsset = (assetConfig as any)[asset];
        return (
          <div className={style.asset}>
            <img
              className={style.iconCoin}
              src={objAsset?.icon || '/images/logos/default.svg'}
              alt={asset}
            />
            <div className={style.assetInfo}>
              <strong>{asset || ''}</strong>
              <div>{objAsset?.name || ''}</div>
            </div>
          </div>
        );
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      sortable: false,
      minWidth: matchUpMd ? 500 : 380,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <span className={style.breakSpaces}>
          {params.getValue(params.id, 'address') || ''}
        </span>
      ),
    },
    {
      field: 'address_title',
      headerName: 'Title',
      sortable: false,
      minWidth: matchUpMd ? 280 : 150,

      renderCell: (params: GridValueGetterParams) => (
        <span className={style.overflow}>
          {' '}
          {params.getValue(params.id, 'address_title') || ''}{' '}
        </span>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      minWidth: 250,
      renderCell: (params: GridValueGetterParams) => {
        const whitelisted = params.row.whitelisted;
        return (
          <>
            {/* Delete */}
            <Button
              variant="contained"
              className={style.btnAction}
              onClick={() => handleDelete(params.row.id)}
            >
              <img src="/images/icons/delete.svg" alt="" />
            </Button>
            {/* Withdraw */}
            <Button
              variant="contained"
              className={style.btnAction}
              onClick={() => {
                dispatch(AssetAction.selectAsset(params.row.asset));
                dispatch(WithdrawalAction.toggle(params.row.id));
              }}
            >
              <img src="/images/icons/sent.svg" alt="" />
            </Button>
            {/* Whitelist */}
            <Button
              variant="contained"
              className={style.btnAction}
              disabled={whitelisted}
              onClick={() => onWhitelistAddress(params.row.id)}
            >
              <img
                src={
                  whitelisted
                    ? '/images/icons/star2.svg'
                    : '/images/icons/star3.svg'
                }
                alt=""
              />
            </Button>
          </>
        );
      },
    },
  ];

  const onChangeFilter = async (chain_id: string) => {
    setFilterByChain(chain_id);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      // Fetch data
      await dispatch(AddressBookAction.reload(getParamSearch()));
      setLoading(false);
    })();
  }, [filterByChain]);

  return (
    <div className={style.styleAddress}>
      <Typography className={style.title}>Address book</Typography>
      <div className={style.header}>
        <Select
          options={chains}
          value={filterByChain}
          className={style.dropdownBoxAddress}
          status
          onChange={onChangeFilter}
        ></Select>
        <Button
          variant="contained"
          className={style.btnAdd}
          onClick={() => {
            dispatch(
              AddressBookAction.toggleModalAdd(
                {
                  isWhitelist: false,
                },
                {
                  isWithdrawal: false,
                }
              )
            );
          }}
        >
          Add Address
        </Button>
      </div>
      <div
        className={clsx(tableStyle.customerDataGrid, style.grid)}
        style={{
          height: `calc(130px + ${page_size * ROW_HIGHT}px)`,
          maxHeight: `calc(130px + ${10 * ROW_HIGHT}px)`,
          minHeight: `calc(130px + ${page_size * ROW_HIGHT}px)`,
        }}
      >
        <DataGrid
          autoHeight
          loading={loading}
          classes={{
            root: style.dataGrid,
          }}
          rows={listAddress.items}
          columns={columns}
          disableSelectionOnClick
          disableColumnMenu
          // headerHeight={46}
          rowHeight={ROW_HIGHT}
          // Page
          paginationMode="server"
          rowCount={listAddress.items_total}
          page={currentPage}
          pageSize={page_size}
          onPageChange={(page) => setCurrentPage(page)}
          hideFooter={!(listAddress?.items_total > page_size)}
          //
          components={{
            Pagination: GridDataPagination,
            NoRowsOverlay: () => (
              <div className={tableStyle.rowEmptydata}>
                <span
                  className={`icon-no-data3 ${tableStyle.iconEmptydata}`}
                ></span>
                <div className={tableStyle.textEmptydata}>No data</div>
                <div className={tableStyle.messEmptydata}>
                  You will be able to see the list of addresses
                  <br /> after adding an address
                </div>
              </div>
            ),
            LoadingOverlay: LoadingOverlay,
          }}
        />
      </div>
      <ModalDelete
        isOpen={isOpenDelete}
        handleClose={() => setIsOpenDelete(false)}
        handleDelete={actionDelete}
        isLoading={loading}
      />
    </div>
  );
};
export default AddressBookComponent;
