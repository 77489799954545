import { useState } from 'react';

import ModalCustom from 'src/components/Base/ModalCustom';
import Radio from 'src/components/Base/Radio';
import useStyles from 'src/routing/Withdrawal/BankAccount/style';
import BankDomestic from './BankDomestic';
import BankInter from './BankInter';
import BankUSA from './BankUSA';

const typeBank = [
  { type: 'ACH', name: 'USA - ACH' },
  { type: 'INTERNATIONAL', name: 'International' },
  { type: 'DOMESTICHK', name: 'HKG - Domestic' },
];

export default function BankAccount({ show: showState }: { show: any }) {
  const [show, setShow] = showState;
  const styles = useStyles();
  const [typeBankSelected, setTypeBankSelected] = useState('ACH');
  const [titleDetail, setTitleDetail] = useState('');
  const backToWithdrawBank = () => {
    setShow(false);
  };

  const handleStep = (step: any) => {
    switch (step) {
      case 1:
        setTitleDetail('');
        break;
      case 2:
        setTitleDetail('Intermediary Bank Details 1/2');
        break;
      case 3:
        setTitleDetail('Intermediary Bank Details 2/2');
        break;
      default:
        setTitleDetail('Beneficiary Details');
        break;
    }
  };
  return (
    <ModalCustom
      isOpen={show}
      handleClose={() => {
        setShow(!show);
      }}
      className={styles.styleBank}
    >
      <div className={styles.row}>
        <div className={styles.title}>Link New Bank Account</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowTitle}>Account Type</div>
        {typeBank.map((type) => {
          return (
            <div className={styles.radioBlock}>
              <Radio
                name={'accountType'}
                className={styles.styleRadio}
                checked={typeBankSelected === type.type}
                label={type.name}
                onChange={() => {
                  setTypeBankSelected(type.type);
                }}
              />
            </div>
          );
        })}
      </div>
      {/* Switch form input */}

      <div className={styles.titleDetail}>{titleDetail}</div>
      {typeBankSelected === 'ACH' && (
        <BankUSA
          type={typeBankSelected}
          backToWithdrawBank={backToWithdrawBank}
        />
      )}
      {typeBankSelected === 'INTERNATIONAL' && (
        <BankInter
          type={typeBankSelected}
          backToWithdrawBank={backToWithdrawBank}
          onStep={handleStep}
        />
      )}
      {typeBankSelected === 'DOMESTICHK' && (
        <BankDomestic
          type={typeBankSelected}
          backToWithdrawBank={backToWithdrawBank}
        />
      )}
    </ModalCustom>
  );
}
