/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';

import ModalCustom from 'src/components/Base/ModalCustom';
import AppPassword from 'src/components/Base/AppPassword';

import { securityService } from 'src/services/SecurityService';
import { toLogin, validatePassword, removeAccents } from 'src/utils/common';
import { AuthenticationAction } from 'src/store/actions/authentication';
// import Loading from 'src/components/Base/Loading';
import { ModalConfirmAction } from 'src/store/actions/modal-confirm';
import AppButton from 'src/components/Base/AppButton';

import useStyles from './styles';

const MESSAGE_INVALID_PASS = 'Existing Password invalid';
const MESSAGE_NOT_MATCH_PASS =
  'new_password is not present, does not match confirm_password, or is same as current_password';

const ComponentResetPassword = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { isOpenResetPopup, handleClose } = props;

  const accountState = useSelector((state: any) => {
    return state.accountReducer;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState({
    error: false,
    msg: '',
  });
  const [newPassword, setNewPassword] = useState({ error: false, msg: '' });
  const [confirmPassword, setConfirmPassword] = useState({
    error: false,
    msg: '',
  });
  const [errorNotMatch, setErrorNotMatch] = useState(false);
  const [currentPasswordValue, setCurrentPasswordValue] = useState('');
  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordVaue] = useState('');
  const [activeButtonReset, setActiveButtonReset] = useState(false);
  const handleCloseSetNewPasswordPopup = () => {
    handleClose(1);
  };

  //Set new password
  const handleChangeCurrentPassword = (e: any) => {
    e.preventDefault();
    setCurrentPassword({ error: false, msg: '' });
    let data = removeAccents(e?.target.value);
    if (data === '') {
      setCurrentPassword({ error: true, msg: 'Current password requirements' });
    } else if (data?.length > 20) {
      data = data.substr(0, 20);
      setCurrentPassword({ error: true, msg: 'Max length exceeded' });
    }
    setCurrentPasswordValue(data);
  };

  const handleChangeNewPassword = (e: any) => {
    e.preventDefault();
    setErrorNotMatch(false);
    setNewPassword({ error: false, msg: '' });
    let data = removeAccents(e?.target.value);
    if (data === '') {
      setNewPassword({ error: true, msg: 'New password requirements' });
    } else if (data?.length > 20) {
      data = data.substr(0, 20);
      setNewPassword({ error: true, msg: 'Max length exceeded' });
    }
    setNewPasswordValue(data);
  };

  const handleChangeConfirmPassword = (e: any) => {
    e.preventDefault();
    setErrorNotMatch(false);
    setConfirmPassword({ error: false, msg: '' });
    let data = removeAccents(e?.target.value);
    if (data === '') {
      setConfirmPassword({ error: true, msg: 'Confirm password requirements' });
    } else if (data?.length > 20) {
      data = data.substr(0, 20);
      setConfirmPassword({ error: true, msg: 'Max length exceeded' });
    }
    setConfirmPasswordVaue(data);
  };

  const onSubmitSetNewPassword = async () => {
    if (!validatePassword(currentPasswordValue)) {
      setCurrentPassword({
        error: true,
        msg: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
      });
      return;
    }

    if (!validatePassword(newPasswordValue)) {
      setNewPassword({
        error: true,
        msg: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
      });
      return;
    }

    if (confirmPasswordValue !== newPasswordValue) {
      setErrorNotMatch(true);
      return;
    }

    setIsLoading(true);
    try {
      const response = await securityService.resetPassword({
        current_password: currentPasswordValue,
        new_password: newPasswordValue,
        confirm_password: confirmPasswordValue,
      });
      setIsLoading(false);
      if (response?.status === 200) {
        await handleClose(1);
        dispatch(
          ModalConfirmAction.show({
            subTitle:
              'Enter confirmation code from email to reset your password',
            onOK: (code: string, twoFA: string) => {
              onSubmitConfirmCode(code, twoFA);
            },
          })
        );
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response?.data?.exception === MESSAGE_INVALID_PASS) {
        setCurrentPassword({
          error: true,
          msg: 'Current password is incorrect',
        });
        return;
      }

      if (error.response?.data?.exception === MESSAGE_NOT_MATCH_PASS) {
        setNewPassword({
          error: true,
          msg: 'The new password must be different from the current password',
        });
        return;
      }
    }
  };

  const onSubmitConfirmCode = async (code?: any, two_fa?: any) => {
    dispatch(ModalConfirmAction.loading());
    const data = {
      code: code,
      '2FA': two_fa,
    };
    try {
      const response = await securityService.confirmChangePassword(data);
      if (response?.status === 200) {
        await dispatch(AuthenticationAction.logout());
        toLogin();
      }
    } catch (error) {
      dispatch(
        ModalConfirmAction.setError(
          accountState.has2FADevices
            ? 'Email or 2FA code is incorrect. Please check and try again.'
            : 'The email code is incorrect. Please check and try again.'
        )
      );
    } finally {
      dispatch(ModalConfirmAction.hideLoading());
    }
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      current_password: '',
      new_password: '',
      confirm_password: '',
    },
  });

  useEffect(() => {
    setCurrentPassword({ error: false, msg: '' });
    setNewPassword({ error: false, msg: '' });
    setConfirmPassword({ error: false, msg: '' });
    setErrorNotMatch(false);
    setConfirmPasswordVaue('');
    setCurrentPasswordValue('');
    setNewPasswordValue('');
  }, [isOpenResetPopup]);

  useEffect(() => {
    if (
      !currentPassword.error &&
      !newPassword.error &&
      !confirmPassword.error &&
      currentPasswordValue.length >= 8 &&
      newPasswordValue.length >= 8 &&
      confirmPasswordValue.length >= 8
    ) {
      setActiveButtonReset(true);
    } else {
      setActiveButtonReset(false);
    }
  }, [currentPasswordValue, newPasswordValue, confirmPasswordValue]);

  return (
    <>
      {/* {isLoading && <Loading />} */}
      {
        <ModalCustom
          isOpen={isOpenResetPopup}
          handleClose={handleCloseSetNewPasswordPopup}
          className={styles.resetPasswordContainer}
        >
          <div className={styles.title} style={{ marginBottom: '20px' }}>
            Set New Password
          </div>
          <form
            className={styles.form}
            onSubmit={handleSubmit(onSubmitSetNewPassword)}
          >
            <div className={styles.inputContainer}>
              <AppPassword
                controller={{ control, name: 'current_password' }}
                label={'Current password'}
                error={currentPassword.error}
                helperText={currentPassword.msg}
                placeholder="Current password"
                onChange={handleChangeCurrentPassword}
                value={currentPasswordValue}
                labelProps={{
                  className: currentPassword.error ? styles.styleLabel : '',
                }}
              />
            </div>

            <div className={styles.inputContainer}>
              <AppPassword
                controller={{ control, name: 'new_password' }}
                label={'New password'}
                error={newPassword.error}
                placeholder="New password"
                helperText={newPassword.msg}
                onChange={handleChangeNewPassword}
                value={newPasswordValue}
                labelProps={{
                  className: newPassword.error ? styles.styleLabel : '',
                }}
              />
            </div>
            <AppPassword
              controller={{ control, name: 'confirm_password' }}
              label={'Confirm password'}
              error={confirmPassword.error || errorNotMatch}
              placeholder="Confirm password"
              helperText={
                confirmPassword.msg?.length
                  ? confirmPassword.msg
                  : errorNotMatch
                    ? 'The confirm password does not match'
                    : ''
              }
              onChange={handleChangeConfirmPassword}
              value={confirmPasswordValue}
              labelProps={{
                className: confirmPassword.error ? styles.styleLabel : '',
              }}
            />
            <div className={styles.btnGroup}>
              <Button
                className={styles.btnCancel}
                onClick={() => handleClose(1)}
              >
                Cancel
              </Button>

              <AppButton
                type="submit"
                className={styles.btnSubmit}
                isDisable={!activeButtonReset}
                width="126px"
                height="36px"
                isLoading={isLoading}
              >
                Submit
              </AppButton>
            </div>
          </form>
        </ModalCustom>
      }
    </>
  );
};

export default ComponentResetPassword;
