import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      zIndex: 1301,
      backgroundColor: theme.custom.colors.mainBackground,
      opacity: 0.9,
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loadingElement: {
      color: theme.custom.colors.purple1,
      width: '50px',
      height: '50px',
    },
  };
});

export default useStyles;
