import { Container } from '@material-ui/core';
import VerifyComponent from './VerifyComponent';
import Common from 'src/components/BaseLandingPage/Common';

const VerifyPage = () => {
  const handleVerify = (pinCode: any) => { };
  return (
    <Common>
      <Container>
        <VerifyComponent handleVerify={handleVerify} show={true} />
      </Container>
    </Common>
  );
};
export default VerifyPage;
