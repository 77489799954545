import { AnyAction } from 'redux';
import { KEY } from 'src/commons/constants';
import * as actionTypes from '../constants/authentication';

const initialState = {
  isLogged: false,
  username: '',
};

export const AuthenticationReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        [KEY.IS_LOGGED]: true,
        username: action.data.username,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        [KEY.IS_LOGGED]: false,
        username: '',
      };
    }
    default:
      return state;
  }
};
