import { AnyAction } from "redux";
import * as actionTypes from "../constants/market";

const initialState = {
    allMarket: []
};

export const tradeReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {

        case actionTypes.GET_MARKET_PAIRS:
            return {
                ...state,
                allMarket: action?.data?.markets ?? [],
            };
        default:
            return state;
    }
};
