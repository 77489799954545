import ModalCustom from 'src/components/Base/ModalCustom';
import useStyles from './styles';
import BalanceComponent from 'src/routing/WalletPage/ComponentFooterRight/BalanceComponent';

const ModalChart = (props: any) => {
  const classes = useStyles();
  const { isOpen, handleClose, defaultAsset } = props;

  return (
    <div>
      <ModalCustom
        isOpen={isOpen}
        isIconClose={true}
        handleClose={handleClose}
        className={classes.mainModal}
      >
        <div className={classes.mainModalChart}>
          <BalanceComponent defaultType={defaultAsset} />
        </div>
      </ModalCustom>
    </div>
  );
};
export default ModalChart;
