import { Box, Button } from '@material-ui/core';
import { useAppSelector } from 'src/hooks';

import useStyles from './styles';
import ModalSignUp from 'src/routing/TradingPage/ComponentTradingEntry/ModalSignUp';
import { useState } from 'react';
import AppLoading from '../AppLoading';

export default function AppBox({
  loading = false,
  hasData = true,
  children,
  ...props
}: {
  loading?: boolean;
  hasData: boolean;
  children?: any;
  [props: string]: any;
}) {
  const classes = useStyles();
  const isLogged = useAppSelector((state) => state.auth.isLogged);
  const [open, setOpen] = useState(false);

  const renderLoginOrNoData = (
    <div {...props}>
      <div style={{ height: '100%' }}>
        <div className={`${classes.rowEmptydata}`}>
          <span className={`icon-no-data ${classes.iconEmptydata}`}></span>
          <div className={classes.textEmptydata}>No data</div>
          {!isLogged && (
            <>
              <div className={classes.messEmptydata}>
                You will be able to see reports after <br /> completing
                transactions
              </div>
              <Button
                variant="contained"
                className={`${classes.btnEmptydata}`}
                onClick={() => setOpen(true)}
              >
                Sign up
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );

  const renderLoading = (
    <div {...props}>
      <div className={classes.boxLoading}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <AppLoading />
        </Box>
      </div>
    </div>
  );

  return (
    <>
      {!isLogged ? (
        renderLoginOrNoData
      ) : loading ? (
        renderLoading
      ) : !hasData ? (
        renderLoginOrNoData
      ) : (
        <div {...props}>{children}</div>
      )}
      <ModalSignUp isOpen={open} handleClose={() => setOpen(false)} />
    </>
  );
}
