import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      textAlign: 'center',
      alignItems: 'center',
      '& .body-modal-container': {
        [theme.breakpoints.down('xs')]: {
          padding: '0 20px',
          minHeight: '30vh !important',
        },
      },
    },
    title: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '115%',
      color: theme.custom.colors.titleTrading,
      margin: 0,
      marginBottom: 8,
    },
    subTitle: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: 14,
      color: theme.custom.colors.titleTrading,
      lineHeight: '20px',
      margin: '30px 0 5px',
    },
    textError: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: 12,
      color: '#DE4949',
      lineHeight: '14px',
      margin: 0,
      marginBottom: 15,
      textAlign: 'center',
      marginTop: '10px',
    },
    btnGroup2: {
      display: 'flex',
      columnGap: '14px',
      justifyContent: 'center',
      width: '100%',
      marginTop: 30,
      '& > div': {
        width: 126,
      },
    },

    customize: {
      display: 'flex !important',
      justifyContent: 'center',
      // padding: '0 100px',
      columnGap: '10px',
      '& input': {
        width: 36,
        height: 36,
        fontSize: 14,
        background: 'none',
        color: theme.custom.colors.lightPurple,
        borderRadius: 5,
        textAlign: 'center',
        border: '1px solid #EBECF0',
        outline: 'none',
        '&:focus': {
          color: theme.custom.colors.lightPurple,
          textAlign: 'center',
          border: '1px solid #B8A7FF !important',
          boxShadow: `0 6px 6px -6px #B8A7FF`,
        },

        '&[type=number]': {
          MozAppearance: 'textfield',
        },

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
      [theme.breakpoints.only('xs')]: {
        padding: '0 10px',
      },
    },
    borderColor: {
      '& input': {
        border: '1px solid #B8A7FF',
      },
    },
  };
});

export default useStyles;
