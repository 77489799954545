import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    pinField: {
      width: '36px',
      height: '36px',
      margin: '10px',
      background: theme.custom.colors.themeCards,

      border: `1px solid ${theme.custom.colors.gray4}`,
      borderRadius: '6px',
      boxSizing: 'border-box',

      fontFamily: 'Ubuntu',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'center',
      MozAppearance: 'textfield',

      '&:focus': {
        outline: 'none',
        caretColor: theme.custom.colors.lightPurple,
        border: `1px solid ${theme.custom.colors.lightPurple}`,
        boxShadow: '0px 6px 7px rgba(127, 99, 244, 0.25)',
        borderRadius: '6px',
      },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
      },

      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
        MozAppearance: 'textfield',
      },

      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
        MozAppearance: 'textfield',
      },

      [theme.breakpoints.down('xs')]: {
        margin: '6px',
      },
    },

    hasValue: {
      border: `1px solid ${theme.custom.colors.lightPurple}`,
      color: theme.custom.colors.lightPurple,
    },
    hasError: {
      borderColor: 'rgb(220, 53, 69)',
      boxShadow: '0 0 0.25rem rgba(220, 53, 69, 0.5)',
    },
  };
});

export default useStyles;
