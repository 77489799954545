import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    linkedContainer: {
      width: "100%",
      height: "193px",
      marginTop: "24px",
      justifyContent: "center",
      fontFamily: 'Ubuntu-Regular',
      display: "flex",
      gridGap: '24px',
      fontStyle: "normal",
      color: theme.custom.colors.titleTrading,


      [theme.breakpoints.between("991", "1100")]: {
        height: "175px"
      },

      [theme.breakpoints.between("1450", "1536")]: {
        width: "1380px",
        height: "162px",
      },


      [theme.breakpoints.between("1536", "1920")]: {
        width: "100%",
        height: "162px",
        marginBottom: 25,
      },

      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },

      [theme.breakpoints.between('599', '699')]: {
        height: '100% !important',
      },

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        width: "100%",
        height: "526px",
        rowGap: "24px",
        marginTop: "30px",
        padding: '0 20px',
      },

      [theme.breakpoints.only("md")]: {
        width: "100%",
        height: "100%"
      },

    },
    linkedItem: {
      width: "560px",
      height: "193px",
      display: "flex",
      flexDirection: "row",

      borderRadius: "8px",
      paddingTop: "30px",
      paddingLeft: "23px",
      paddingBottom: "30px",

      backgroundColor: theme?.custom?.colors.cardBackground,
      color: theme?.custom?.colors.white1,



      [theme.breakpoints.between('599', '699')]: {
        height: '100% !important',
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingBottom: '20px !important',
      },


      [theme.breakpoints.down("sm")]: {
        width: "260px",
        height: 210,
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: 0
      },

      [theme.breakpoints.down("md")]: {
        width: "357px",
        height: 200,
        paddingTop: "15px",
        paddingLeft: "13px",
      },

      [theme.breakpoints.only("lg")]: {
        width: "446px",
        height: 180,
        paddingTop: "15px",
        paddingLeft: "13px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: "22px",
        paddingLeft: "10px",
        paddingBottom: "21px",
        paddingRight: "20px",

        width: "100%",
        height: "162px"
      },
    },
    iconStyle: {
      marginTop: "-3px",
      fontSize: 35,

      [theme.breakpoints.only("sm")]: {
        display: "none"
      },
    },
    content: {
      display: "flex",
      flexDirection: "column"
    },
    title: {
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "140%",
      color: theme.custom.colors.titleTrading,
    },
    body: {
      marginTop: "6px",
      paddingRight: "20px",
      marginBottom: "20px",

      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "140%",
      color: theme?.custom?.colors.colorText,
      height: 70,

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "60px",
        fontSize: "14px",
        marginBottom: "16px",
        paddingRight: 0,
      },

      [theme.breakpoints.only("sm")]: {
        paddingRight: "0",
        marginBottom: 30,
        fontSize: "14px",
      },

      [theme.breakpoints.only("md")]: {
        marginBottom: 35,
        fontSize: "14px",
      },

      [theme.breakpoints.only("lg")]: {
        marginBottom: "16px",
        fontSize: "14px",
      }
    },
    viewmore: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: theme?.custom?.colors.colorLink,
      textDecoration: "none",

      [theme.breakpoints.down("md")]: {
        marginTop:0
      },

      [theme.breakpoints.down("sm")]: {
        marginTop:20
      },

      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        marginTop:0
      },
      
      [theme.breakpoints.between('600', '699')]: {
        marginTop:40
      },
    }
  };
});

export default useStyles;