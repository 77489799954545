import React, { useEffect, useState } from 'react';
import ModalCustom from 'src/components/Base/ModalCustom';
import Select from 'src/components/Base/Select';
import { depositService } from 'src/services/DepositService';
import Domestic from './Domestic';
import Internaltional from './International';
import SelectTabCash from './SelectTabCash';
import useStyles from './styles';

export const DepositCash = ({
  currency,
  isOpen,
  handleClose,
}: {
  currency?: any;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const classes = useStyles();
  const [item, setItem] = useState<any>();
  const [activeNav, setActiveNav] = useState(0);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await depositService.getListBanks();
      if (res?.status === 200) {
        const data = res.data.items;
        let arr: any[] = [];
        data.map((item: any) => {
          return (
            item?.type === 'DEPOSIT' &&
            arr.push({
              label: item.asset,
              value: {
                domestic: item?.bank_data?.domestic,
                international: item?.bank_data?.international,
              },
            })
          );
        });
        setOptions(arr);
      }
    };
    if (!currency) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalCustom
      isOpen={isOpen}
      handleClose={handleClose}
      className={classes.cryptoModalContainer}
    >
      <div className="label">
        {currency ? `Make a ${currency.asset} deposit` : 'Make cash deposit'}
      </div>
      <div className="desc">
        IMPORTANT: Reference must be included as message to payee on all
        transfers
      </div>
      <div className={classes.tabInput}>
        {!currency && (
          <Select
            value={item}
            options={options}
            className="dropdown-box"
            label="Currency"
            placeholder="Choose currency"
            status
            onChange={(item: any) => setItem(item)}
          />
        )}
      </div>

      <div className={classes.mainTabSelect}>
        <SelectTabCash
          activeNav={activeNav}
          onSetActiveNav={(value: number) => setActiveNav(value)}
        />
        {activeNav === 0 && <Domestic data={item?.domestic} />}
        {activeNav === 1 && <Internaltional data={item?.international} />}
      </div>
    </ModalCustom>
  );
};

export default DepositCash;

export const titleCase = (str: string) => {
  let sentence = str.toLowerCase().split('-');
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(' ');
};
