import { Container } from '@material-ui/core';
import ConfirmComponent from './ConfirmComponent';
import Common from 'src/components/BaseLandingPage/Common';
import { useParams } from 'react-router';

const ConfirmPage = () => {
  const {
    email,
    mode,
  }: {
    email: string;
    mode: ModeScreen;
  } = useParams();

  return (
    <Common>
      <Container>
        <ConfirmComponent email={email} mode={mode} />
      </Container>
    </Common>
  );
};
export default ConfirmPage;
