import { AnyAction } from "redux";
import * as actionTypes from "../constants/market";

const initialState = {
    allMarket: [],
    allLimit: {}
};

export const marketReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {

        case actionTypes.GET_MARKET_PAIRS:
            return {
                ...state,
                allMarket: action?.data ?? [],
            };
        case actionTypes.GET_ADD_LIMITS:
            return {
                ...state,
                allLimit: action?.data ?? {},
            };
        default:
            return state;
    }
};
