import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import clsx from 'clsx';
import React from 'react';
import useStyles from './styles';

interface Props {
  value?: any;
  error?: any;
  helperText?: any;
  radioList: any[];
  handleSelect: (item: any) => void;
  height?: string;
  label?: string;
  className?: string;
}

const AppRadioList: React.FC<Props> = ({
  label,
  value,
  radioList,
  handleSelect,
  height,
  className,
  error,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={className}>
      <div
        className={clsx(classes.label, {
          [classes.error]: error,
        })}
      >
        {label}
      </div>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={(e: any) => handleSelect(e.target.value)}
      >
        {radioList.map((item, index) => {
          return (
            <FormControlLabel
              className={classes.radioClass}
              key={index}
              value={item}
              control={<StyledRadio />}
              label={item}
            />
          );
        })}
      </RadioGroup>
      {helperText && (
        <div
          className={clsx(classes.helperText, {
            [classes.error]: error,
          })}
        >
          {helperText}
        </div>
      )}
    </FormControl>
  );
};

const StyledRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default AppRadioList;
