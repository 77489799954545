import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import AppButton from 'src/components/Base/AppButton';
import AppInput from 'src/components/Base/AppInput';

import { IPropsBankChild } from './BankInter';

import useStyles from './style';

export interface IFormBankPage3 {
  int_bank_address: {
    country: string;
    street_adress: string;
    street_adress_2: string;
    city: string;
    region: string;
    postcode: string;
  };
}

export default function BankInterPage3({ onBack, onSubmit }: IPropsBankChild) {
  const styles = useStyles();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<IFormBankPage3>({
    mode: 'onChange',
    defaultValues: {
      int_bank_address: {
        // 3
        // country: 'Country',
        // street_adress: 'Street Address',
        // street_adress_2: 'Street Address 2 (apt, Suite)',
        // city: 'City',
        // region: 'Region',
        // postcode: 'Postal Code',
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'int_bank_address.country',
            rules: {
              required: true,
            },
          }}
          label="Country"
          error={Boolean(errors.int_bank_address?.country)}
          helperText={errors.int_bank_address?.country?.message}
          labelProps={{
            className: errors.int_bank_address?.country
              ? styles.styleLabel
              : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'int_bank_address.street_adress',
            rules: {
              required: true,
            },
          }}
          label="Street Address"
          error={Boolean(errors.int_bank_address?.street_adress)}
          helperText={errors.int_bank_address?.street_adress?.message}
          labelProps={{
            className: errors.int_bank_address?.street_adress
              ? styles.styleLabel
              : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'int_bank_address.street_adress_2',
            rules: {
              required: true,
            },
          }}
          label="Street Address 2 (apt, Suite)"
          error={Boolean(errors.int_bank_address?.street_adress_2)}
          helperText={errors.int_bank_address?.street_adress_2?.message}
          labelProps={{
            className: errors.int_bank_address?.street_adress_2
              ? styles.styleLabel
              : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'int_bank_address.city',
            rules: {
              required: true,
            },
          }}
          label="City"
          error={Boolean(errors.int_bank_address?.city)}
          helperText={errors.int_bank_address?.city?.message}
          labelProps={{
            className: errors.int_bank_address?.city ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'int_bank_address.region',
            rules: {
              required: true,
            },
          }}
          label="Region"
          error={Boolean(errors.int_bank_address?.region)}
          helperText={errors.int_bank_address?.region?.message}
          labelProps={{
            className: errors.int_bank_address?.region ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          inputPostal={true}
          controller={{
            control,
            name: 'int_bank_address.postcode',
            rules: {
              required: true,
            },
          }}
          label="Postal Code"
          error={Boolean(errors.int_bank_address?.postcode)}
          helperText={errors.int_bank_address?.postcode?.message}
          labelProps={{
            className: errors.int_bank_address?.postcode
              ? styles.styleLabel
              : '',
          }}
        />
      </div>

      <Box className={clsx(styles.row, styles.rowBtn)} display="flex">
        <AppButton mode="secondary" height={'36px'} onClick={() => onBack()}>
          Back
        </AppButton>
        <AppButton type="submit" height={'36px'} isDisable={!isValid}>
          Next
        </AppButton>
      </Box>
    </form>
  );
}
