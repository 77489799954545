import React, { useEffect, useState } from 'react';
import { Grow, Paper, Popper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { displayNumb } from 'src/utils/number';
import AutoComplete from './AutoComplete';
import AppInputNumber from 'src/components/Base/AppInputNumber';

import useStyles from './styles';

const GroupSelect = (props: any) => {
  const styles = useStyles();
  const {
    coins,
    babel,
    value,
    defaultValueSelect,
    placeholder,
    available,
    onChangeAmount,
    onChangeCoin,
    option,
    errMsg,
    disabled,
    disabledSelect,
    precision,
    increase,
    decrease,
    onChange,
  } = props;

  const [open, setOpen] = useState(false);
  const prevOpen = React.useRef(open);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    if (disabledSelect) return; // disabled selected when trading page
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleSelectCoin = (name: string) => {
    if (disabledSelect) return; // disabled selected when trading page
    onChangeCoin(name);
    setOpen(false);
  };

  const renderOptions = (option: any) => {
    if (!disabledSelect) {
      return !option ? (
        open ? (
          <ArrowDropUpIcon className={styles.iconDrop} />
        ) : (
          <ArrowDropDownIcon className={styles.iconDrop} />
        )
      ) : open ? (
        <span className={`icon-arrow-up ${styles.iconSelect}`}></span>
      ) : (
        <span className={`icon-arrow-down ${styles.iconSelect}`}></span>
      );
    }
    return <> </>;
  };

  return (
    <div className={styles.boxGroupSelect}>
      {!option ? (
        <div className={styles.head}>
          <div className={styles.babel}>{babel}</div>
          <div className={styles.value}>Available {displayNumb(available)}</div>
        </div>
      ) : (
        <></>
      )}

      <div className={`${styles.groupSelect} ${option ? `${styles.optionGroup}` : ''}`}
      >
        <div
          ref={anchorRef}
          aria-haspopup="true"
          onClick={handleToggle}
          className={`${styles.selectCoin} ${option ? `${styles.optionCoin}` : ''}`}
        >
          <img
            className={styles.iconShow}
            src={`/images/logos/${defaultValueSelect?.toLowerCase()}.svg`}
            alt=""
          />
          <span className={styles.selectTitle}>{defaultValueSelect}</span>
          {renderOptions(option)}
        </div>
        {!option ? (
          <>
            <AppInputNumber
              spinner={true}
              container_classes={`${styles.inputAmountContainer} ${errMsg && errMsg !== '' && styles.borderError}`}
              input_classes={`${styles.inputAmount}`}
              value={value}
              onValueChange={(values) => {
                if (onChangeAmount) onChangeAmount(values);
              }}
              onChange={(e) => {
                if (onChange) onChange(e);
              }}
              placeholder={placeholder}
              disabled={disabled}
              precision={precision}
              increase={() => increase()}
              decrease={() => decrease()}
            // isAllowed={withValueCap}
            />
          </>
        ) : (
          <></>
        )}

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement={'bottom-start'}
          transition
          disablePortal
          className={`${styles.dropdownSelect} ${option ? `${styles.widthPaper}` : ''
            }`}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper className={styles.paperSelect}>
                <AutoComplete
                  list={coins}
                  openState={open}
                  handleClose={(value: boolean) => {
                    setOpen(!value);
                  }}
                  handleSelectCoin={handleSelectCoin}
                ></AutoComplete>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default GroupSelect;
