import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import useStyles from './styles';
const listDesc = [
  {
    id: 1,
    text: 'Liquidity Aggregation',
    icon: 'icon-set',
    sub: 'Aggregating markets across 10+ leading exchanges, providing the deepest liquidity with a diverse token range to suit your business needs',
  },
  {
    id: 2,
    text: 'Smart Order Routing',
    icon: 'icon-chart',
    sub: 'Proprietary algorithms designed by industry experts to ensure best price executions and the tightest spreads.',
  },
  {
    id: 3,
    text: 'Zero Fee Pricing ',
    icon: 'icon-coin',
    sub: 'Transparent pricing model, all trades are instantly confirmed, traded prices guaranteed ensuring no price slippage or post trade fees.',
  },
  {
    id: 4,
    text: 'Low Minimums',
    icon: 'icon-search',
    sub: 'Our platform accepts trade sizes from as low as $1 up to large block trades to suit all business needs.',
  },
];
const LiquidityHome = (props: any) => {
  const classes = useStyles();

  return (
    <section className={classes.mainLiqui} id="liquidity">
      <h3 className={classes.tittleLiqui}>Liquidity Reimagined</h3>
      <div data-aos="fade-up">
        <div className={classes.groupDesc}>
          {listDesc?.map((item) => (
            <div className={classes.boxDesc1} key={item.id}>
              <div className={classes.icon}>
                <img src={`/images/${item.icon}.svg`} alt="" />
              </div>
              <Typography variant="h4" className={classes.titleBox}>
                {item.text}
              </Typography>
              <p className={classes.textSub}>{item.sub}</p>
            </div>
          ))}
        </div>
      </div>
      <div data-aos="fade-up">
        <div className={classes.styleImg}>
          <img src="/images/lan.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default withRouter(LiquidityHome);
