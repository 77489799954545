import { useState } from 'react';
import BalanceComponent from './BalanceComponent';
import useStyles from './styles';

const ComponentFooterLeft = (props: any) => {
  const styles = useStyles();
  const [tabComponent] = useState(0);

  return (
    <div className={styles.componentFooterLeft}>
      <ul className={styles.tabComponent}>
        <li
          className={`${styles.itemTabComponent} ${
            tabComponent === 0 && styles.activeTab
          }`}
        >
          Volume Chart
        </li>
      </ul>
      <BalanceComponent defaultType={'BTC'} />
    </div>
  );
};

export default ComponentFooterLeft;
