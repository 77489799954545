/* eslint-disable react-hooks/exhaustive-deps */
import { Hidden } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { securityService } from 'src/services/SecurityService';
import useStyles from './styles';


const SecurityContainer = (props: any) => {
  const classes = useStyles();
  const { score, enable2FA, verified, kyc } = props;
  const [counter, setCounter] = useState(0);
  const username: string = useSelector((state: any) => state.auth.username);

  const handleSendEmail = () => {
    securityService
      .confirmationEmail()
      .then((res) => {
        if (res?.status === 200) {
          const timeStamp = new Date().getTime();
          const item = { user: username, timeStamp: timeStamp.toString() }
          localStorage.setItem('timeStamp_confirmEmail', JSON.stringify(item));
          setCounter(60);
        }
      })
      .catch((err) => null);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter > 0 && !verified) {
        setCounter(counter - 1);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [counter, verified]);

  useEffect(() => {
    if (!verified) {
      const timeStampData = localStorage.getItem('timeStamp_confirmEmail');
      if (timeStampData) {
        const dataParse = JSON.parse(timeStampData);
        if (dataParse && dataParse.user === username) {
          const countdown = (new Date().getTime() - Number(dataParse.timeStamp)) / 1000;
          if (countdown < 60) {
            setCounter(Math.floor(60 - countdown));
          }
        }
      }
    }
  }, [verified]);


  const handleTime = (seconds: number) => {
    return seconds < 10 ? '0' + seconds : seconds;
  };


  return (
    <div className={classes.securityContainer}>
      <div className={classes.overView}>
        <div className={classes.percentageView}>
          <label className={classes.title}>
            Increase your account security
          </label>
          <div className={classes.percentage}>
            <div className={classes.progress}>
              <span
                className={classes.progBar}
                style={{ width: `${score}%` }}
              ></span>
            </div>
            <div className={classes.progLabel}>
              <span className={classes.perNum}>{score}%/</span>
              <span className={classes.perTotal}>100%</span>
            </div>
          </div>
        </div>
        <div className={classes.line}></div>
      </div>
      <div className={classes.securityContent}>
        <div className={classes.securityRow}>
          <div className={classes.securityItem}>
            {verified ? (
              <div className={classes.line1}>
                <div className={classes.ellipse3}></div>
                <span className={classes.securityType}>Confirmed Email</span>
              </div>
            ) : (
              <>
                <div className={classes.line1}>
                  <div className={classes.ellipseUnconfirm}></div>
                  <span className={classes.securityType}>
                    Unconfirmed Email
                  </span>
                </div>
                {counter ? (
                  <div className={`${classes.securityAction} ${classes.textSend}`}>
                    {`${handleTime(counter)}s`}
                  </div>
                ) : (
                  <Link
                    to="#"
                    className={`${classes.securityAction} ${classes.textSend}`}
                    onClick={handleSendEmail}
                  >
                    Send confirmation email
                  </Link>
                )}
              </>
            )}
          </div>

          <div className={classes.securityItem}>
            <div className={classes.line1}>
              <div className={classes.ellipse1}></div>
              <span className={classes.securityType}>
                Whitelist <Hidden xsDown>Addresses</Hidden>
              </span>
            </div>
            <Link
              to="/address-book"
              className={`${classes.securityAction} ${classes.textLink}`}
            >
              Set
            </Link>
          </div>
        </div>

        <div className={`${classes.securityRow} ${classes.mgTop42}`}>
          <div className={classes.securityItem}>
            <div className={classes.securityItem}>
              <div className={classes.line1}>
                <div className={classes.ellipse1}></div>
                <span className={classes.securityType}>KYC complete</span>
              </div>

              {kyc ? (
                <></>
              ) : (
                <Link
                  to="/setting/kyc"
                  className={`${classes.securityAction} ${classes.textLink}`}
                >
                  Set
                </Link>
              )}
            </div>
          </div>

          <div className={classes.securityItem}>
            <div className={classes.securityItem}>
              {enable2FA ? (
                <div className={classes.line1}>
                  <div className={classes.ellipse2}></div>
                  <span className={classes.securityType}>Enable 2FA</span>
                </div>
              ) : (
                <div className={classes.line1}>
                  <div className={classes.ellipseDis}></div>
                  <span className={classes.securityType}>Enable 2FA</span>
                </div>
              )}

              {enable2FA ? (
                <span className={`${classes.securityAction} ${classes.enable}`}>
                  Enabled
                </span>
              ) : (
                <Link
                  to="/security"
                  className={`${classes.securityAction} ${classes.textLink}`}
                >
                  Disabled
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityContainer;