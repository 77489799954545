import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    titlePage: {
      fontFamily: 'Ubuntu-bold',
      fontSize: '24px',
      lineHeight: '28px',
      marginTop: '30px',
      color: theme.custom.colors.titleTrading,
    },
    floatLeft: {
      float: 'left',
      position: 'absolute',
      
      [theme.breakpoints.up('sm')]: {
        float: 'left',
      },
      [theme.breakpoints.only('xs')]: {
        float: 'unset',
        position: 'unset',
      },
    },
  };
});

export default useStyles;
