import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    contentLanding: {},
    styleContainer: {
     

      [theme.breakpoints.down('lg')]: {
        backgroundSize: "cover",
        
      },

      [theme.breakpoints.up('md')]: {
        // backgroundImage: "url(/images/landing-bgr.png)",
        backgroundSize: "inherit",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundOrigin: "border-box",
       
      },
      [theme.breakpoints.between('sm','md')]: {
        backgroundSize: "1300px",
        paddingLeft: 24,
        paddingRight: 24,
      },
      [theme.breakpoints.up('lg')]: {
        padding: 0,
      },

      [theme.breakpoints.up('xs')]: {},
    },
    button: {
      color: theme?.custom?.colors.mainBackground,
    }
  };
});

export default useStyles;