import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    title: {
      fontFamily: 'Ubuntu-bold',
      fontSize: 20,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',

      '& .icon-info:before': {
        marginLeft: '12px',
        fontSize: 24,
        color: theme.custom.colors.titleTrading,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    groupForm: {},

    inputItem: {
      width: '100%',
      marginTop: '30px',

      '& input': {
        '&::placeholder': {
          color: theme.custom.colors.colorPlace,
        },
      },
    },

    '& .MuiFormControl-root': {
      width: '100%',
      marginTop: '30px',
    },

    numberPhone: {
      marginTop: '8px',
    },

    btnLink: {
      fontFamily: 'Ubuntu-Regular',
      color: theme.custom.colors.cornflowerBlue,
      textDecoration: 'underline',
      cursor: 'pointer',
      boxSizing: 'unset',
      "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
      width: 'fit-content',

      "&:active": {
        background: "unset",
      },

      [theme.breakpoints.down('xs')]: {
        outline: 'none',
      },
    },

    btnNext: {
      marginTop: '28px',
      width: 217,
      paddingRight: '10px',

      [theme.breakpoints.down('xs')]: {
        width: 158,
      },
    },
    styleLabel: {
      color: `${theme.custom.colors.red1}!important`
    },
  };
});

export default useStyles;
