import { Typography } from '@material-ui/core';
import useStyles from './styles';
import AppButton from 'src/components/Base/AppButton';

function SupportComponent(props: any) {
  const {
    title,
    icon,
    content,
    primaryHref,
    primaryText,
    secondaryHref,
    secondaryText,
  } = props;
  const style = useStyles();
  return (
    <div className={style.rootSupport}>
      <div className={style.paper}>
        <div className={style.content}>
          <span className={`${style.icon} ${icon}`}> </span>
          <div>
            <Typography className={`${style.titleSize1}`}>{title}</Typography>
            <Typography className={`${style.titleSize2}`}>{content}</Typography>
            <div className={style.btn}>
              <a
                target="_blank"
                rel="noreferrer"
                href={primaryHref}
                className={`${style.linkButton} `}
              >
                <AppButton height="36px" width="126px">
                  <span className={style.btnContent}>{primaryText}</span>
                </AppButton>
              </a>
              {secondaryText ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={secondaryHref}
                  className={`${style.linkButton}`}
                >
                  <AppButton mode="secondary" height="36px" width="126px">
                    <span className={style.btnContent}>{secondaryText}</span>
                  </AppButton>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportComponent;
