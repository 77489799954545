import { KEY } from 'src/commons/constants';
import { HTTPBaseService } from 'src/request/HTTPBaseService';

export class AuthService extends HTTPBaseService {
  private static classInstance?: AuthService;

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new AuthService();
    }
    return this.classInstance;
  }

  public async login(data: any) {
    return await this.instance.post('/api/token/', data);
  }

  public async refreshToken() {
    return await this.instance.post('/api/token/refresh/', {
      refresh: localStorage.getItem(KEY.REFRESH_TOKEN),
    });
  }

  public async logout() {
    // return await this.instance.post('/auth/logout/');
  }
}
