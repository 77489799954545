import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainGet: {
      fontFamily: 'Titillium Web',
    },

    titleGet: {
      fontWeight: 700,
      fontSize: '64px',
      fontFamily: 'Titillium Web',
      color: theme.custom.colors.textdesc,
      textAlign: 'center',
      paddingTop: 120,

      [theme.breakpoints.down('md')]: {
        fontSize: '48px',
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '64px',
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        paddingTop: 70,
      },
    },

    groupStep: {
      paddingTop: 40,
    },

    detailStep: {
      display: 'flex',
      justifyContent: 'center',
      gridGap: '100px',

      [theme.breakpoints.down('sm')]: {
        gridGap: '10px',
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gridGap: '32px',
      },
    },

    detailBox1: {
      background: theme.custom.colors.bgrBox,
      borderRadius: '10px',
      width: 280,
      height: 178,
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        height: 195,
      },
      [theme.breakpoints.between('500', '619')]: {
        height: 'auto',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 178,
        boxShadow: '0px 4px 50px rgba(127, 99, 244, 0.35)',
      },
    },

    titleStep: {
      fontSize: '24px',
      lineHeight: '37px',
      color: theme.custom.colors.white,
      margin: '20px 0 10px',
    },

    textStep: {
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.custom.colors.white,
      margin: '10px 0',
      padding: '0 20px',

      [theme.breakpoints.down('xs')]: {
        padding: '0 45px',
      },
    },

    btnStep: {
      background: theme.custom.colors.btnSignUp,
      borderRadius: 6,
      width: 114,
      height: 30,
      fontFamily: 'Titillium Web',
      fontSize: 14,
      fontWeight: 600,
      color: theme.custom.colors.white,
      textTransform: 'none',
      marginRight: 10,
      boxShadow: 'none',

      '&:hover': {
        background: theme.custom.colors.btnSignUp,
        opacity: 0.8,
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: 5,
      },
    },

    multiStep: {
      paddingTop: 40,
      position: 'relative',

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    lineUp: {
      background: theme.custom.colors.bgrLine,
      height: 8,
      borderRadius: 10,
    },

    dot: {
      position: 'relative',
      '& ul': {
        display: 'flex',
        justifyContent: 'space-around',
        margin: 0,
        padding: 0,
      },

      '& li': {
        background: theme.custom.colors.bgrDot,
        border: '5px solid #373C4E',
        boxSizing: 'border-box',
        width: 25,
        height: 25,
        borderRadius: '100%',
        listStyleType: 'none',
        margin: '-10px 0 0',
        zIndex: 10,
        cursor: 'pointer',
      },

      '& li:first-child': {
        marginLeft: 32,

        [theme.breakpoints.down('sm')]: {
          marginLeft: -10,
        },
      },
      '& li:last-child': {
        marginRight: 40,

        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
        },
      },
    },
    line: {
      position: 'relative',
    },
    line1: {
      background: theme.custom.colors.btnSignUp,
      height: 8,
      borderRadius: 10,
      width: '33%',
      marginTop: '-8px',
    },
    line2: {
      background: theme.custom.colors.btnSignUp,
      height: 8,
      borderRadius: 10,
      width: '66%',
      marginTop: '-8px',
    },
    line3: {
      background: theme.custom.colors.btnSignUp,
      height: 8,
      borderRadius: 10,
      width: '100%',
      marginTop: '-8px',
    },
    dotColor: {
      margin: '-8px 0 0',

      '& ul': {
        display: 'flex',
        justifyContent: 'space-around',
        margin: 0,
        padding: 0,
      },

      '& li': {
        background: theme.custom.colors.bgrDot,
        border: '5px solid #7F63F4',
        boxSizing: 'border-box',
        width: 25,
        height: 25,
        borderRadius: '100%',
        listStyleType: 'none',
        margin: '-10px 0 0',
        marginRight: 93,
      },
    },

    boxShadow: {
      boxShadow: '0px 4px 50px rgba(127, 99, 244, 0.35)',
      [theme.breakpoints.down('xs')]: {
        boxShadow: '0px 4px 50px rgba(127, 99, 244, 0.35)',
      },
    },
    todo: {},
    doing: { border: '5px solid #7F63F4 !important' },
    done: { border: '5px solid #7F63F4 !important' },
  };
});

export default useStyles;
