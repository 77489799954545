import { HTTPBaseService } from 'src/request/HTTPBaseService';

export interface OrderRequestParams {
  type?: 'BEST_RATE' | 'LIMIT' | 'QUOTE';
  side?: string;
  symbol?: string;
  state?: 'OPEN' | 'CANCELLED' | 'FILLED' | 'EXCEPTION';
  created_start?: number;
  created_end?: number;
  updated_start?: number | null;
  updated_end?: number | null;
  page?: number;
  page_size?: number;
}

export interface OrderResponse {
  items: [any];
  page: number;
  page_size: number;
  pages_total: number;
  items_returned: number;
  items_total: number;
}

export class OrderService extends HTTPBaseService {
  private static classInstance?: OrderService;

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new OrderService();
    }
    return this.classInstance;
  }

  public async get(params?: OrderRequestParams) {
    return await this.instance.get<OrderResponse>('/orders/', { params });
  }
}
