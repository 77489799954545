import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    priceChart: {
      width: '998px',
      height: '509px',
      borderRadius: '8px',
      marginTop: '36px',
      color: theme?.custom?.colors.white1,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: '30px',
        height: '372px',
        padding: '0 20px',
      },
      [theme.breakpoints.only('sm')]: {
        width: '100%',
      },

      [theme.breakpoints.only('md')]: {
        width: '57%',
        height: '449px',
      },

      [theme.breakpoints.only('lg')]: {
        width: '57%',
        marginTop: '24px',
      },
    },
    header: {
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '28px',
      justifyContent: 'space-between',
      color: theme.custom.colors.titleTrading,
      fontWeight: 700,
      fontFamily: 'Ubuntu-Bold',
      fontSize: 20,

      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },

      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    title: {},
    chartContent: {
      width: '100%',
      height: '464px',
      paddingTop: '20px',
      paddingLeft: '17px',
      paddingRight: '30px',
      paddingBottom: '44.58px',
      marginTop: '10px',

      borderRadius: '8px',
      backgroundColor: theme?.custom?.colors.cardBackground,

      [theme.breakpoints.down('xs')]: {
        marginTop: '30px',
        height: '372px',
      },

      [theme.breakpoints.only('md')]: {
        height: '404px',
      },

      [theme.breakpoints.down('xs')]: {
        height: '321px',
        paddingTop: '15px',
        paddingLeft: '10x',
        paddingRight: '10px',
        paddingBottom: '33px',
      },
    },
    coinInfo: {
      width: '100%',
      height: '50.01px',

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      gridGap: '100px',

      [theme.breakpoints.between('991', '1200')]: {
        gridGap: '30px',
      },

      // [theme.breakpoints.up('lg')]: {
      //   gridGap: '50px',
      // },

      [theme.breakpoints.down('sm')]: {
        gridGap: '50px',
      },

      [theme.breakpoints.between('500', '619')]: {
        gridGap: '30px',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '105px',
        flexDirection: 'column',
        gridGap: '0px',
      },
    },
    chart: {
      width: '100%',
      height: '320px',
      marginTop: '28px',

      [theme.breakpoints.down('xs')]: {
        height: '146px',
      },

      [theme.breakpoints.only('sm')]: {
        height: '303px',
      },

      [theme.breakpoints.only('md')]: {
        height: '293px',
      },

      [theme.breakpoints.only('lg')]: {
        height: '303px',
      },
    },
    coinPair: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',

      width: '107px',
      height: '41px',

      flex: 'none',
      order: 0,
      flexGrow: 0,

      [theme.breakpoints.down('md')]: {},

      [theme.breakpoints.down('xs')]: {
        margin: 0,
        marginRight: 0,
      },
    },
    icon: {
      width: '50px',
      height: '50.01px',
    },
    symbol: {
      width: '47px',
      height: '41px',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px',

      order: 0,
      flexGrow: 0,
    },
    benchMark: {
      width: '106px',
      height: '41px',
      display: 'flex',
      flexDirection: 'column',

      order: 1,
      flexGrow: 0,

      [theme.breakpoints.down('xs')]: {
        marginLeft: '20px',
      },
    },
    price: {
      width: '81px',
      height: '41px',
      display: 'flex',
      flexDirection: 'column',

      order: 2,
      flexGrow: 0,
    },
    change: {
      width: '84px',
      height: '41px',
      display: 'flex',
      flexDirection: 'column',

      order: 3,
      flexGrow: 0,
    },

    marketcap: {
      width: '71px',
      height: '41px',
      display: 'flex',
      flexDirection: 'column',

      order: 4,
      flexGrow: 0,

      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    coinInfoTitle: {
      width: '100%',
      height: '20px',

      fontFamily: 'Ubuntu-Regular',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '140%',
      color: '#9BA1B1',

      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        textTransform: 'uppercase',
      },
    },
    coinInfoValue: {
      width: '100%',
      height: '18px',

      fontFamily: 'Ubuntu-Regular',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '18px',
      color: theme?.custom?.colors.titleTrading,

      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },

    coinInfoLeft: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',

        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '5px',
      },
    },

    coinInfoRight: {
      display: 'flex',
      flexDirection: 'row',
      gridGap: '100px',

      [theme.breakpoints.between('991', '1200')]: {
        gridGap: '30px',
      },

      // [theme.breakpoints.up('lg')]: {
      //   gridGap: '50px',
      // },

      [theme.breakpoints.down('sm')]: {
        gridGap: '34px',
      },

      [theme.breakpoints.between('500', '619')]: {
        gridGap: '18px',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '35px',
        marginTop: 15,
        paddingLeft: '10px',
        justifyContent: 'flex-start',
        columnGap: '7px',
      },
    },
  };
});

export default useStyles;
