import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainPage: {},

    mainPageHead: {
      display: 'grid',
      marginBottom: 40,
      gridGap: 24,
      gridTemplateColumns:
          'calc(1254/1728*100%) calc(220/1728*100%) calc(220/1728*100%) !important',

      [theme.breakpoints.between('3000', '8000')]: {
        gridGap: 24,
        gridTemplateColumns:
          'calc(1400/1728*100%) calc(160/1728*100%) calc(160/1728*100%) !important',
      },
      [theme.breakpoints.between('1440', '2000')]: {
        gridGap: 24,
        gridTemplateColumns:
          'calc(998/1728*100%) calc(336/1728*100%) calc(346/1728*100%) !important',
      },

      [theme.breakpoints.only('lg')]: {
        gridGap: 20,
        gridTemplateColumns:
          'calc(797/1376.5*100%) calc(268/1376.5*100%) calc(275/1376.5*100%) !important',
      },

      [theme.breakpoints.only('md')]: {
        gridGap: 20,
        gridTemplateColumns:
          'calc(529/1112*100%) calc(268/1112*100%) calc(275/1112*100%) !important',
      },

      [theme.breakpoints.only('sm')]: {
        gridGap: 20,
        width: '100%',
        gridTemplateColumns: 'none !important',
        gridTemplateAreas:
          "'header header header header' 'main main right right' !important",
        marginBottom: 30,
      },

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'none !important',
        gridTemplateAreas: "'right' 'header' 'main' ",
        marginBottom: 30,
      },
    },
    boxChart: {
      // gridArea: "chart",
    },
    boxMarket: {
      // gridArea: "market",
    },
    boxTrade: {
      // gridArea: "trade",
    },

    groupTablePage: {
      display: 'grid',
      gridColumnGap: 24,
      position: 'relative',
      paddingBottom: 43,


      [theme.breakpoints.up('xl')]: {
        gridColumnGap: 24,
        gridTemplateColumns: 'calc(1145/1728*100%) calc(561/1728*100%)',
      },

      [theme.breakpoints.only('lg')]: {
        gridGap: 20,
        gridTemplateColumns: 'calc(986/1376.5*100%) calc(373/1376.5*100%)',
      },

      [theme.breakpoints.only('md')]: {
        gridGap: 20,
        gridTemplateColumns: '1fr',
      },

      [theme.breakpoints.only('sm')]: {
        gridTemplateColumns: '1fr',
        gridGap: 30,
      },

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
        gridGap: 30,
      },
    },

    navHeadTrading: {},

    itemNav: {},

    activeItemNav: {},

    overlay: {
      position: 'absolute',
      height: 310,
      background: theme.custom.colors.bgrOverLay,
      zIndex: 3,
      opacity: 0.7,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      width: '100.5%',
      top: 70,
      borderRadius: 8,
      [theme.breakpoints.between('1800', '1950')]: {
        width: '100.1%',
        height: 310,
      },
      [theme.breakpoints.between('1160', '1441')]: {
        width: '100.5%',
        height: 310,
      },
      [theme.breakpoints.between('1000', '1155')]: {
        width: '100%',
        height: 310,
      },
      [theme.breakpoints.between('990', '1025')]: {
        width: '100%',
        height: 310,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 310,
      },
      [theme.breakpoints.down('xs')]: {
        height: 323,
      },
    },
  };
});

export default useStyles;
