import { GET_ADD_LIMITS, GET_MARKET_PAIRS } from '../constants/market';
import { marketService } from 'src/services/MarketService';
import { sortPriorityAlplabet } from 'src/utils/index';

export const getAllPairs = () => async (dispatch: any) => {
  try {
    const pairs = await marketService.getAllMarkets();
    const allLimit = await marketService.getAllLimit();
    if (pairs && pairs.data) {
      let allMarkets = [];

      // filter all coin has limit data
      for (let item of pairs.data.markets) {
        const baseCoin = item.split("/")[0];
        const quoteCoin = item.split("/")[1];
        const hasBaseCoin = allLimit.data[baseCoin];
        const hasQuoteCoin = allLimit.data[quoteCoin];
        if (hasBaseCoin && hasQuoteCoin) {
          allMarkets.push(item);
        }
      }

      // set all market
      let orderByAllMarket = sortPriorityAlplabet('BTC', allMarkets);
      dispatch({
        type: GET_MARKET_PAIRS,
        data: orderByAllMarket,
      });
      dispatch({
        type: GET_ADD_LIMITS,
        data: allLimit.data,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const getAllLimit = () => async (dispatch: any) => {
  try {
    const limits = await marketService.getAllLimit();
    if (limits && limits.data) {
      dispatch({
        type: GET_ADD_LIMITS,
        data: limits.data,
      });
    }
  } catch (error) {
    throw error;
  }
};
