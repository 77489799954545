/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import useStyles from 'src/components/BaseLandingPage/Common/Form/styles';
import HeaderLogo from 'src/components/BaseLandingPage/Common/HeaderLogo';
import ButtonCommon from 'src/components/BaseLandingPage/Common/Button';
import CheckboxCommon from 'src/components/BaseLandingPage/Common/CheckBox';
import MessageInline from 'src/components/BaseLandingPage/Common/MessageInline';
import InputField from 'src/components/BaseLandingPage/Common/Input';
import VerifyComponent from '../../../VerifyPage/VerifyComponent';
import Link from 'src/components/Base/Link';

import { landingService } from 'src/services/LandingService';
import { useHistory } from 'react-router';
import { KEY, PAGE, useQuery } from 'src/utils/utils';
import { AuthenticationAction } from 'src/store/actions/authentication';
import { LoadingAction } from 'src/store/actions/loading';
import { AssetAction } from 'src/store/actions/asset';
import { ChainAction } from 'src/store/actions/chain';
import { AddressBookAction } from 'src/store/actions/address';
import {
  getBalances,
  getBanks,
  has2FADevices,
} from 'src/store/actions/account';
import { getAllPairs } from 'src/store/actions/market';

import { MAX_LENGTH } from 'src/utils/validation';
import useClasses from './styles';

export interface LoginFromInput {
  username: string;
  password: string;
  otp?: string;
  remember?: boolean;
}

const LoginComponent = () => {
  const history = useHistory();
  const query = useQuery();
  const isLogout = query.get(KEY.LOGOUT);
  const [msg, setMsg] = useState('');
  const [msg2FA, setMsg2FA] = useState('');
  const [show2FA, setShow2FA] = useState(false);
  const style = useStyles();
  const classes = useClasses();
  const [isLoading, setLoading] = useState(false);
  const methodsForm = useForm<LoginFromInput>({
    mode: 'onChange',
    defaultValues: {},
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, isValid },
  } = methodsForm;
  const dispatch = useDispatch();

  // Init page
  useEffect(() => {
    if (isLogout === 'true') {
      localStorage.removeItem(KEY.ACCESS_TOKEN);
      localStorage.removeItem(KEY.REFRESH_TOKEN);
    }

    const dtFormLogin = JSON.parse(
      localStorage.getItem(KEY.REMEMBER_LOGIN) || 'false'
    );
    setValue('remember', dtFormLogin.remember);
    if (dtFormLogin.remember) {
      setValue('username', dtFormLogin.username);
    }
    document.title = 'Log in';
  }, []);

  // Login
  const onSubmit = (data: LoginFromInput) => {
    setMsg('');
    const { username, remember } = data;
    localStorage.setItem(
      KEY.REMEMBER_LOGIN,
      JSON.stringify({
        username,
        remember,
      })
    );
    getToken(data);
  };

  const getToken = (data: LoginFromInput) => {
    setLoading(true);

    data.username = data.username.toLowerCase();

    // call API Login
    landingService
      .postData('/api/token/', data)
      .then(async (res: any) => {
        setLoading(true);
        if (res.status !== 200) {
          setMsg('Incorrect username or password');
          return;
        }
        const data = await res.data;
        checkExist2FA(data);
      })
      .catch((err) => {
        setMsg('Incorrect username or password');
        setLoading(false);
      });
  };

  const checkExist2FA = (data: any) => {
    if (data.require_otp) {
      // Open confirm 2FA
      setMsg2FA('');
      setShow2FA(true);
      setLoading(false);
      if (data.incorrect_otp) {
        setMsg2FA('The 2FA code is incorrect. Please check and try again.');
      }
    } else {
      dispatch(
        AuthenticationAction.loginSuccess(
          data.access,
          data.refresh,
          getValues('username').toLowerCase()
        )
      );

      // fetch data
      dispatch(LoadingAction.show());

      Promise.all([
        dispatch(AssetAction.set()),
        dispatch(ChainAction.set()),
        dispatch(AddressBookAction.reload()),
        dispatch(getBalances()),
        dispatch(getBanks()),
        dispatch(has2FADevices()),
        dispatch(getAllPairs()),
        // dispatch(getAllLimit()),
      ]).finally(() => {
        // Hide loading
        dispatch(LoadingAction.hide());
      });

      history.push('/trade');
    }
  };

  const submit2FA = (code2FA: string) => {
    setValue('otp', code2FA);
    getToken(getValues());
  };

  useEffect(() => {
    if (!show2FA) {
      setTimeout(() => {
        setMsg2FA('');
        setValue('otp', '');
      }, 100);
    }
  }, [show2FA]);

  return (
    <>
      <HeaderLogo />
      <div className={`${style.formBox} ${style.position}`}>
        <Typography className={`${style.labelContent} ${classes.logo}`}>
          Log in
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={style.formSubmit}>
          <InputField
            {...methodsForm}
            label={'Email or Username'}
            name={'username'}
            maxLength={MAX_LENGTH.MAIL}
            validation={{ required: true }}
          />
          <InputField
            {...methodsForm}
            label={'Password'}
            name={'password'}
            type="password"
            modeLogin
            validation={{ required: true }}
          />
          <div className={style.display}>
            <div className={style.styleCheck}>
              <CheckboxCommon
                {...methodsForm}
                required={false}
                nameCheck="remember"
                label="Remember me"
              />
            </div>
            <div>
              <Link
                className={style.link}
                onClick={() => {
                  history.push(PAGE.FORGOT);
                }}
              >
                Forgot Password
              </Link>
            </div>
          </div>
          {msg && <MessageInline msg={msg} />}
          <div className={classes.textCenter}>
            <ButtonCommon
              type="submit"
              disabled={!isDirty || !isValid}
              isLoading={isLoading}
            >
              Log in
            </ButtonCommon>
          </div>
          <div className={style.bottom}>
            <label>
              Don’t have an account?{' '}
              <Link
                className={style.link}
                onClick={() => {
                  history.push(PAGE.SIGNUP);
                }}
              >
                Sign up
              </Link>
            </label>
          </div>
        </form>
      </div>
      <VerifyComponent
        handleVerify={submit2FA}
        show={[show2FA, setShow2FA]}
        msg={msg2FA}
      />
    </>
  );
};
export default LoginComponent;
