import { Container } from '@material-ui/core';
import Common from 'src/components/BaseLandingPage/Common';
import LoginComponent from './LoginComponent';
import useStyles from './styles';

const LoginPage = () => {
  const style = useStyles();

  return (
    <Common>
      <Container className={style.main}>
        <LoginComponent />
      </Container>
    </Common>
  );
};
export default LoginPage;
