import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    securityContainer: {
      width: "706px",
      height: "283px",
      borderRadius: "8px",
      fontFamily: "Ubuntu-Regular",
      fontStyle: "normal",
      padding: "30px 30px 34px 30px",


      backgroundColor: theme?.custom?.colors.cardBackground,
      color: theme.custom.colors.titleTrading,

      [theme.breakpoints.down("xs")]: {
        flexDirection: "row",
        width: "100%",
        padding: "20px"
      },

      [theme.breakpoints.only("md")]: {
        width: "452px",
        height: "263px",
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "31px"
      },

      [theme.breakpoints.only("sm")]: {
        display: "none",
      },

      [theme.breakpoints.only("lg")]: {
        width: "563px",
        height: "263px",
      }
    },

    styleSec: {
      fontSize: 14,
      color: '#DE4949',
      margin: '2px 0px 0px 17px',
    },

    overView: {
      width: "646px",
      height: "67px",
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "74px"
      },

      [theme.breakpoints.only("sm")]: {
      },

      [theme.breakpoints.only("md")]: {
        width: "100%",
      },

      [theme.breakpoints.only("lg")]: {
        width: "523px",
      }
    },

    percentageView: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      color: theme.custom.colors.titleTrading,

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        width: "100%"
      },
    },

    title: {
      width: "266px",
      height: "46px",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "23px",

      [theme.breakpoints.down("xs")]: {
        whiteSpace: 'nowrap',
        width: "261px",
        height: "25px",
        fontSize: "18px"
      },


      [theme.breakpoints.only("md")]: {
        fontSize: "16px"
      },
    },
    line: {
      width: "646px",
      height: "1px",
      marginTop: "20px",

      background: "#9BA1B1",
      borderRadius: "1px",

      [theme.breakpoints.down("xs")]: {
        width: "295px",

        backgroundColor: theme?.custom?.colors.grey3,
        borderRadius: "1px",
        flex: "none",
        order: 1,
        flexGrow: 0,
        margin: "10px 0px"
      },

      [theme.breakpoints.only("sm")]: {
      },

      [theme.breakpoints.only("md")]: {
        width: "100%",
      },

      [theme.breakpoints.only("lg")]: {
        width: "523px",
      }
    },
    percentage: {
      display: "flex",
      flexDirection: "column",
      marginTop: "12px",
      [theme.breakpoints.down("xs")]: {
        marginTop: 16,
      }
    },
    progress: {
      width: "266px",
      height: "8px",
      background: theme?.custom?.colors.comet,
      borderRadius: "10px",

      [theme.breakpoints.down("xs")]: {
        width: "197px"
      },
      [theme.breakpoints.only("sm")]: {
      },

      [theme.breakpoints.only("md")]: {
        width: "189px"
      },
    },
    progBar: {
      display: "block",
      height: "100%",
      background: theme?.custom?.colors.cornflowerBlue,
      backgroundSize: "300% 100%",
      borderRadius: "10px"
    },
    progLabel: {
      width: "100%",
      height: "14px",
      marginTop: "10px",
      fontSize: "12px",
      lineHeight: "14px"
    },
    perNum: {
      fontWeight: 500
    },
    perTotal: {
      fontWeight: 400,
      color: '#9BA1B1',
    },

    securityContent: {
      width: "100%",
      marginTop: "26px",

      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      columnGap: "42px"
    },
    securityRow: {
      width: 'calc(100% - 93px)',
      height: "42px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",

      [theme.breakpoints.down("xs")]: {
        width: "auto"
      },
      [theme.breakpoints.only("sm")]: {
      },
      [theme.breakpoints.only("md")]: {
        width: "100%"
      },
    },
    securityItem: {
      width: "189px",
      height: "42px",
    },
    line1: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyItems: "center"
    },
    securityAction: {
      with: "100px",
      height: "16px",
      paddingLeft: "17px",

      fontSize: "14px",
      lineHeight: "16px"

    },
    ellipse1: {
      width: "7px",
      height: "7px",
      borderRadius: "50%",
      background: theme?.custom?.colors.cornflowerBlue,
    },
    ellipse2: {
      width: "7px",
      height: "7px",
      borderRadius: "50%",
      background: theme?.custom?.colors.green1
    },
    ellipse3: {
      width: "7px",
      height: "7px",
      borderRadius: "50%",
      background: theme?.custom?.colors.green1
    },
    securityType: {
      width: "100%",
      height: "25px",
      marginLeft: "10px",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "140%",

      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
    mgTop42: {
      marginTop: "42px",

      [theme.breakpoints.only("lg")]: {
        marginTop: "46px",
      }
    },

    enable: {
      color: theme?.custom?.colors.green1,
      textDecoration: "none",
    },
    low: {
      color: theme?.custom?.colors.red2,
    },
    textLink: {
      textDecoration: "underline",
      color: theme?.custom?.colors.lightPurple2,
    },
    ellipseDis: {
      width: "7px",
      height: "7px",
      borderRadius: "50%",
      background: theme?.custom?.colors.cornflowerBlue,
    },

    ellipseUnconfirm: {
      width: "7px",
      height: "7px",
      borderRadius: "50%",
      background: theme?.custom?.colors.red2,
    },

    textSend: {
      color: theme?.custom?.colors.lightPurple2,
    },
  };
});

export default useStyles;