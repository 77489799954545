/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import canvasApp from './ex';
const Canvas = (props: any) => {
  const { sphereRad, scale } = props;
  useEffect(() => {
    canvasApp(sphereRad, scale);
  }, []);
  return <canvas id="canvasOne" width="600" height="600"></canvas>;
};
export default Canvas;
