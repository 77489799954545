/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'react-toastify';
import ModalCustom from 'src/components/Base/ModalCustom';
import Select from 'src/components/Base/Select';
import { depositService } from 'src/services/DepositService';
import useStyles from './styles';

export const DepositCrypto = ({
  currency,
  isOpen,
  handleClose,
}: {
  currency?: any;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const classes = useStyles();
  const [item, setItem] = useState();
  const [, setCopied] = useCopyToClipboard();
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await depositService.getDepositAddress();
      if (res?.status === 200) {
        const data = res.data.deposit_addresses;
        let arr: any[] = [];
        data.map((item: { asset: any; deposit_address: any }, index: any) => {
          return (
            item.deposit_address &&
            arr.push({
              label: item.asset,
              value: item.deposit_address || index.toString(),
            })
          );
        });
        setOptions(arr);
      }
    };
    if (!currency) {
      fetchData();
    }
  }, []);

  return (
    <ModalCustom
      isOpen={isOpen}
      handleClose={handleClose}
      className={classes.cryptoModalContainer}
    >
      <div className="label">
        Make a {`${currency?.asset || 'crypto'}`} deposit
      </div>
      {!currency && (
        <Select
          value={item}
          options={options}
          className="dropdown-box"
          label="Currency"
          placeholder="Choose currency"
          popupClassName={classes.selectPopup}
          onChange={(item: any) => setItem(item)}
        />
      )}
      {(currency?.address || item) && (
        <>
          <div className="address-container">
            <div className="title">Deposit Address</div>
            <div className="address-box">
              <div className="address">
                {currency ? currency.address : item}
              </div>
              <div
                className="icon-copy2"
                onClick={() => {
                  setCopied(currency ? currency.address : item);
                  toast.success('Copied!');
                }}
              ></div>
            </div>
          </div>
          <div className="warning">
            <div className="icon-warning"></div>
            <div className="content">
              Double check the address you are sending to is exactly as above,
              mistakes cannot be corrected.
            </div>
          </div>
        </>
      )}
      <div className="report">
        Having trouble? <a href="/support">Contact us</a> for assistance.
      </div>
    </ModalCustom>
  );
};

export default DepositCrypto;
