import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import ModalCustom from 'src/components/Base/ModalCustom';
import useStyles from './styles';
import ModalConfirmCode from '../ModalConfirmCode';
import {
  ApiKey,
  ApiKeyService,
  DeleteApiKeyBody,
} from 'src/services/ApiKeyService';
import customToast from 'src/components/Base/Toast/Toast';

const apiKeyService = ApiKeyService.getInstance();

export const ModalDelete = ({
  isOpen,
  currentKey,
  handleClose,
  onSubmitted,
}: {
  isOpen: boolean;
  currentKey?: ApiKey | null;
  handleClose: () => void;
  onSubmitted: () => void;
}) => {
  const classes = useStyles();

  const [submitting, setSubmitting] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const [lastFormInput, setLastFormInput] = useState<DeleteApiKeyBody>();

  const onSubmit = async () => {
    if (!currentKey || submitting) return;
    const body = {
      label: currentKey.label,
      prefix: currentKey.prefix,
    };
    setLastFormInput(body);
    try {
      setSubmitting(true);
      await apiKeyService.delete(body);
      setModalConfirm(true);
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitConfirm = async (code: string, twoFA?: string) => {
    if (!lastFormInput || confirming) return;
    try {
      setConfirming(true);
      await apiKeyService.confirmDelete({
        ...lastFormInput,
        code,
        two_fa: twoFA,
      });
      customToast.success('API Key Deleted Successfully');
      setModalConfirm(false);
      handleClose();
      onSubmitted && onSubmitted();
    } catch {
      setConfirmError(true);
    } finally {
      setConfirming(false);
    }
  };
  return (
    <>
      <ModalCustom
        isOpen={isOpen}
        handleClose={handleClose}
        className={classes.mainModalDelete}
      >
        <div className={classes.titlePopUp}>
          <img src="/images/img-trash.svg" alt="" />
          <Typography variant="h4" className={classes.styleTitle}>
            Confirm deleting
          </Typography>

          <p className={classes.textConfirm}>
            Are you sure you want to delete this object?
          </p>

          <div className={classes.boxBtnConfirm}>
            <Button onClick={handleClose} className={classes.btnCancel}>
              Cancel
            </Button>
            <Button className={classes.delete} onClick={onSubmit}>
              Delete
            </Button>
          </div>
        </div>
      </ModalCustom>
      <ModalConfirmCode
        isOpen={modalConfirm}
        loading={confirming}
        subTitle="Enter confirmation code from email to add new API key."
        errorCode={confirmError}
        setErrorCode={setConfirmError}
        handleClose={() => setModalConfirm(false)}
        onSubmit={onSubmitConfirm}
      />
    </>
  );
};

export default ModalDelete;
