/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ws } from 'src/utils/socket';
import * as MarketActions from 'src/store/actions/market';
import * as AccountActions from 'src/store/actions/account';
import { CHART_DEFAULT_PAIR } from 'src/commons/constants/Constants';

import DefaultLayout from 'src/components/Layout/DefaultLayout';
import ComponentHeadTrading from './ComponentHeadTrading';
import ComponentChartTrading from './ComponentChartTrading';
import ComponentMarketDepth from './ComponentMarketDepth';
import ComponentTradingEntry from './ComponentTradingEntry';
import ComponentRecentOrders from './ComponentRecentOrders';
import ComponentWatchList from './ComponentWatchList';
import ComponentFlashTrading from './ComponentFlashTrading';

import useStyles from './styles';

const TradingPage = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isFlashTrade, setFlashTrade] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [activeNav, setActiveNav] = useState(0);
  const [pair, setPair] = useState<string>('');
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const handleChecked = (value: any) => {
    setFlashTrade(value);
  };

  const [priceChart, setPrice] = useState();

  const marketState = useSelector((state: any) => {
    return state.marketReducer;
  });

  const accountState = useSelector((state: any) => {
    return state.accountReducer;
  });

  useEffect(() => {
    document.title = 'Trading';
    ws.initWebSocket();
    getAllMarket();
    getPreferences();
  }, []);

  useEffect(() => {
    if (marketState.allMarket && marketState.allMarket.length > 0) {
      const allMarket = marketState.allMarket;
      let pairURL = props.match?.params?.pair;

      // default pair
      if (!pairURL || pairURL === '') {
        setPair(CHART_DEFAULT_PAIR);
        pairURL = CHART_DEFAULT_PAIR.replace('/', '_');
        history.push(`/trading/${pairURL}`);
        return;
      }

      // find pair in markert
      pairURL = pairURL.replace('_', '/');
      const findPair = allMarket.find((x: any) => x === pairURL);
      if (pairURL && !findPair) {
        history.push('/error');
      }

      setPair(pairURL);
    }
  }, [marketState.allMarket]);

  const getAllMarket = async () => {
    const markets = marketState.allMarket;
    if (markets && markets.length === 0) {
      await dispatch(MarketActions.getAllPairs());
    }
  };

  const getPreferences = async () => {
    const favourites = accountState?.preferences?.favourite;
    if (!favourites || (favourites && favourites.length === 0)) {
      await dispatch(AccountActions.getPreferences());
    }
  };

  const handleRefreshOrder = () => {
    doRefresh((prev) => prev + 1);
  };

  const handleChangeFav = async (favCoins: any) => {
    let preferences = accountState?.preferences ?? {};
    preferences['favourite'] = favCoins;
    await dispatch(AccountActions.setPreferences(preferences));
  };

  const callBackUpdatePrice = (p: any) => {
    setPrice(p);
  }

  return (
    <DefaultLayout>
      <ComponentHeadTrading
        activeNav={activeNav}
        onSetActiveNav={(value: number) => setActiveNav(value)}
        handleChecked={handleChecked}
      />

      <div className={styles.mainPage}>
        {activeNav === 0 && (
          <>
            <div className={styles.mainPageHead}>
              <ComponentChartTrading
                symbol={pair}
                onChangeSymbol={(pair: string) => {
                  let pairURL = pair.replace('/', '_');
                  history.push(`/trading/${pairURL}`);
                  setPair(pair);
                }}
                allMarkets={marketState.allMarket}
                favCoin={accountState?.preferences?.favourite}
                handleChangeFav={handleChangeFav}
                priceChart={priceChart}
              />
              <ComponentMarketDepth symbol={pair} callBackUpdatePrice={callBackUpdatePrice} />
              <ComponentTradingEntry
                handleRefreshOrder={handleRefreshOrder}
                symbol={pair}
              />
            </div>
            <div className={styles.groupTablePage}>
              {!isLogged && <div className={styles.overlay}></div>}
              <ComponentRecentOrders refresh={refresh} />
              <ComponentWatchList
                allMarkets={accountState?.preferences?.favourite}
                hanldeChangeSymbolTrade={(symbol: string) => setPair(symbol)}
              />
            </div>
          </>
        )}
        {activeNav === 1 && (
          <ComponentFlashTrading
            favCoins={accountState?.preferences?.favourite}
            allMarkets={marketState.allMarket}
            isFlashTrade={isFlashTrade}
            handleChangeFav={handleChangeFav}
          />
        )}
      </div>
    </DefaultLayout>
  );
};

export default withRouter(TradingPage);
