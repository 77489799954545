import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainEx: {
      background: theme.custom.colors.white,
      fontFamily: "Titillium Web",
    },
    containterEX: {
      [theme.breakpoints.up('lg')]: {
        padding: 0,
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },

    contentEx: {
      display: "flex",
      gridGap: 100,
      padding: "100px 0",

      [theme.breakpoints.down('lg')]: {
        gridGap: 25,
      },

      [theme.breakpoints.up('lg')]: {
        gridGap: 100,
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: "column-reverse",
        paddingTop: 80,
        paddingBottom: 100,
        gridGap: 80,
        paddingRight: 20,
        paddingLeft: 20,
      },

      [theme.breakpoints.down('xs')]: {
        padding: "30px 20px",
      },
    },
    leftEx: {
      [theme.breakpoints.down('lg')]: {
        "& img": {
          maxWidth: 428,
          maxHeight: 611,
        },
      },
      [theme.breakpoints.up('lg')]: {
        "& img": {
          maxWidth: 505,
          maxHeight: 637,
        },
      },

      [theme.breakpoints.down('sm')]: {
        display: "none",
      },

    },

    leftExMobile: {
      [theme.breakpoints.up('md')]: {
        display: "none",
      },

      [theme.breakpoints.up('sm')]: {
        "& img": {
          width: "100%",
        },
      },

      [theme.breakpoints.down('xs')]: {
        display: "none",
      },
    },

    leftExXs: {
      [theme.breakpoints.up('sm')]: {
        display: "none",
      },

      [theme.breakpoints.down('xs')]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& img": {
          width: "100%",
        },
      },
    },

    rightEX: {},

    titleEx: {
      fontWeight: 700,
      fontSize: "48px",
      fontFamily: "Titillium Web",
      color: theme.custom.colors.textTitle,

      [theme.breakpoints.down('lg')]: {
        fontSize: "32px",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "48px",
      },
    },

    textEx: {
      fontSize: "20px",
      lineHeight: "30px",
      color: theme.custom.colors.textTitle,

      [theme.breakpoints.down('lg')]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "20px",
      },
    },

    listItem: {
      marginLeft: '-13px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-12px',
      },
    },

    detail: {
      display: "flex",
      gridGap: "30px",

      [theme.breakpoints.down('lg')]: {
        gridGap: "9px",
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: "column",
        gridGap: "0px",
      },
    },

    iconEx: {
      [theme.breakpoints.down('xs')]: {
        marginLeft: "-10px",
      },
    },

    titleDetail: {
      fontWeight: 700,
      fontSize: "24px",
      color: theme.custom.colors.textTitle,
      margin: 0,
      paddingTop: 16,

      [theme.breakpoints.down('lg')]: {
        paddingTop: 12,
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
      },
    },

    descText: {
      fontSize: "18px",
      lineHeight: "27px",
      color: theme.custom.colors.textdetail,
      margin: "10px 0",
    },

    textDetail: {},
  };
});

export default useStyles;