import { IconButton } from '@material-ui/core';
import useStyles from './styles';
import { useSelector } from 'react-redux';

const ProfileSection = (props: any) => {
  const { handleProfile } = props;
  const username: string = useSelector((state: any) => state.auth.username);
  const classes = useStyles();
  return (
    <div className={classes.boxUser} onClick={handleProfile}>
      <IconButton
        className={classes.iconButton}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
      >
        <span className="icon-user"></span>
      </IconButton>
      <span className={classes.name}>{username || ''}</span>
    </div>
  );
};

export default ProfileSection;
