import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DefaultLayout from 'src/components/Layout/DefaultLayout/LandingPageLayout';
import TermsComponent from './TermsComponent';

const TermsPage = (props: any) => {
  const history = useHistory();
  const [isFooter, setFooter] = useState(false);

  useEffect(() => {
    if (history.location.pathname === '/term') {
      setFooter(true);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    document.title = 'Terms & Conditions';
  }, []);

  return (
    <DefaultLayout isFooter={isFooter}>
      <TermsComponent />
    </DefaultLayout>
  );
};
export default TermsPage;
