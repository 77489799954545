import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    styleAddress: {
      paddingBottom: 20,
    },
    title: {
      padding: '30px 0',
      fontSize: 24,
      fontFamily: 'Ubuntu-Bold',
      color: theme.custom.colors.boldGray,
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
    },

    dropdownBoxAddress: {
      marginRight: 20,
      width: 213,
      maxWidth: '100%',

      [theme.breakpoints.down('sm')]: {
        width: 167,
      },

      [theme.breakpoints.down('xs')]: {
        width: 157,
      },

      '& .MuiInput-root': {
        marginTop: 0,
        height: 50,
        background: theme.custom.colors.cardBackground,
        border: `1px solid ${theme.custom.colors.comet3}`,
      },

      '& .MuiSelect-root > span': {
        color: theme.custom.colors.boldGray,
        fontSize: 14,
      },

      '& .icon-arrow-down:before': {
        color: theme.custom.colors.boldGray,
      },
    },

    btnAdd: {
      fontFamily: 'Ubuntu-Bold',
      background: theme.custom.colors.cornflowerBlue,
      borderRadius: 6,
      minHeight: 50,
      minWidth: 213,
      maxWidth: '100%',
      fontSize: 14,
      color: '#FFFFFF',
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',

      '&:hover': {
        background: theme.custom.colors.hoverBtn,
        boxShadow: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: 167,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 157,
      },
    },

    grid: {
      marginBottom: 20,

      "& .MuiDataGrid-main": {
        paddingBottom: 15
      }
    },

    dataGrid: {
      "& .MuiDataGrid-overlay": {
        background: 'rgba(0, 0, 0, 0.5)'
      }
    },

    asset: {
      display: 'flex',
      lineHeight: '20px',
    },

    iconCoin: {
      width: 35,
      height: 35,
      marginRight: 10,
    },

    assetInfo: {},

    breakSpaces: {
      lineHeight: '20px',
      whiteSpace: 'break-spaces',
    },

    overflow: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'

    },

    btnAction: {
      width: 60,
      height: 35,
      borderRadius: '6px',
      backgroundColor: theme.custom.colors.bgBtn,
      marginRight: 10,
      '&:hover': {
        backgroundColor: '#7F63F4',
      },
    },

    paginationTable: {},

    pagination: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 15,
      '& p': {
        color: theme.custom.colors.titleTrading,
        lineHeight: '40px',
      },
    },

    btnPage: {
      width: 40,
      height: 40,
      borderRadius: '6px',
      backgroundColor: theme.custom.colors.bgPagination,
      border: `1px solid ${theme.custom.colors.borderBtnPage}`,
    },

    btnRoot: {
      minWidth: 0,
    },
  };
});
export default useStyles;
