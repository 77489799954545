import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    padding: {
      [theme.breakpoints.down('xs')]: {
        padding: '0 55px',
      },
    },
  };
});
export default useStyles;
