import { HTTPBaseService } from 'src/request/HTTPBaseService';

export interface WithdrawalRequest {
  client_transaction_id: string;
  amount: string;
  address_id?: number;
  withdrawal_address?: string;
  classification?: string;
  asset?: string;
  memo?: string;
  // transfer_type: 'EXTERNAL' | 'PRIMEX';
  chain_id?: number;
  code?: string;
  two_fa?: string;
}

export interface WithdrawalResponse {
  client_transaction_id: string;
  state: 'PENDING' | 'PENDING APPROVAL' | 'COMPLETED' | 'REJECTED' | 'EXPIRED';
  asset: string;
  amount: string;
  withdrawal_address: string;
  classification: string;
  memo: string;
  chain: string;
  transfer_type: 'EXTERNAL' | 'PRIMEX';
  created_at: number;
  updated_at: number;
}

export class WithdrawalService extends HTTPBaseService {
  private static classInstance?: WithdrawalService;

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new WithdrawalService();
    }
    return this.classInstance;
  }

  public async create(params?: WithdrawalRequest) {
    return await this.instance.put<WithdrawalResponse>('/withdrawals/', params);
  }
  public async confirm(params: WithdrawalRequest) {
    return await this.instance.put<WithdrawalResponse>(
      '/withdrawals/' + params.client_transaction_id + '/',
      params
    );
  }
}
