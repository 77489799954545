import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import clsx from 'clsx';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import LoadingOverlay from 'src/routing/ReportsPage/LoadingOverlay';
import ModalSignUp from 'src/routing/TradingPage/ComponentTradingEntry/ModalSignUp';
import GridDataPagination from 'src/components/Base/GridDataPagination';

import assetConfig from 'src/commons/configs/assetsConfig.json';
import { DEFAULT_LOGO } from 'src/commons/constants';
import { capitalizeFirstLetter } from 'src/utils/common';
import { displayNumb } from 'src/utils/number';
import { OrderRequestParams, OrderService } from 'src/services/OrderService';
import {
  TransactionRequestParams,
  TransactionService,
} from 'src/services/TransactionService';

import TableStyle from 'src/styles/TableStyle';
import useStyles from './styles';

const transactionService = TransactionService.getInstance();
const orderService = OrderService.getInstance();

const ComponentFooterLeft = (props: any) => {
  const styles = useStyles();
  const tableStyle = TableStyle();
  const rowHeight = 60;
  const [tabComponent, setTabComponent] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const [transactions, setTransactions] = useState<any[]>([]);
  const [transactionTotal, setTransactionTotal] = useState(0);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [transactionPage, setTransactionsPage] = useState(0);

  const [orders, setOrders] = useState<any[]>([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [orderPage, setOrderPage] = useState(0);

  const history = useHistory();
  const isLogged = useSelector((state: any) => state.auth.isLogged);

  const theme = useTheme();
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const matchOnlyLg = useMediaQuery(theme.breakpoints.only('lg'));
  const matchOnlysm = useMediaQuery(theme.breakpoints.only('sm'));

  const columnsOrder: GridColDef[] = [
    {
      field: 'updated_at',
      headerName: 'Time/date',
      sortable: false,
      minWidth: matchOnlysm ? 230 : 195,
      flex: 0.8,
      renderCell: (params: GridValueGetterParams) => (
        <span className={styles.breakSpaces}>
          {params.value &&
            moment((params.value as number) * 1000).toLocaleString()}
        </span>
      ),
    },
    {
      field: 'client_order_id',
      headerName: 'Order ID',
      sortable: false,
      minWidth: matchOnlysm ? 250 : 205,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <span className={styles.breakSpaces}>
          {params.getValue(params.id, 'client_order_id') || ''}
        </span>
      ),
    },
    {
      field: 'symbol',
      headerName: 'Symbol',
      minWidth: 120,
      flex: 1,
      sortable: false,
    },
    {
      field: 'filled_amount',
      headerName: 'Amount',
      minWidth: 110,
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'side',
      headerName: 'Side',
      minWidth: 100,
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <span className={styles.capitalized}>{params.value}</span>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      minWidth: 140,
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      sortable: false,
      minWidth: 130,
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.value &&
            `${params.value} ${
              (params.getValue(params.id, 'symbol') as string).split('/')[1]
            }`}
        </>
      ),
    },
    {
      field: 'proceeds',
      headerName: 'Proceeds',
      sortable: false,
      minWidth: 130,
      renderCell: (params: GridValueGetterParams) => {
        const currencies = (
          params.getValue(params.id, 'symbol') as string
        ).split('/');
        return (
          <>
            {params.value &&
              `${params.value} ${
                params.getValue(params.id, 'side') === 'sell'
                  ? currencies[1]
                  : currencies[0]
              }`}
          </>
        );
      },
    },
    {
      field: 'state',
      headerName: 'Status',
      sortable: false,
      minWidth: 120,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div
            className={clsx(styles.status, {
              [styles.statusConfirm]: params.value === 'CONFIRMED',
              [styles.statusFilled]: params.value === 'FILLED',
              [styles.statusOpen]: params.value === 'OPEN',
              [styles.statusCancel]:
                params.value === 'CANCELLED' ||
                params.value === 'EXEPTION' ||
                params.value === 'EXPIRED',
            })}
          >
            {(params.value as string)?.toLowerCase()}
          </div>
        );
      },
    },
  ];
  const columnsTransactions: GridColDef[] = [
    {
      field: 'symbol',
      headerName: 'Asset',
      sortable: false,
      width: matchUpXl ? 195 : matchOnlyLg ? 165 : 150,
      renderCell: (params: GridValueGetterParams) => (
        <div className={styles.symbol}>
          <img
            className={styles.iconCoin}
            src={`${params.getValue(params.id, 'logo')}`}
            alt=""
          />
          <div className={styles.symbolInfo}>
            <strong>{params.getValue(params.id, 'symbol') || ''}</strong>
            <div>{params.getValue(params.id, 'name') || ''}</div>
          </div>
        </div>
      ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      width: matchUpXl ? 195 : matchOnlyLg ? 165 : 150,
      renderCell: (params: GridValueGetterParams) => (
        <strong>{params.getValue(params.id, 'amount') || ''}</strong>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      width: matchUpXl ? 165 : matchOnlyLg ? 135 : 135,
      renderCell: (params: GridValueGetterParams) => (
        <>{params.getValue(params.id, 'type') || ''}</>
      ),
    },
    {
      field: 'time',
      headerName: 'Time',
      sortable: false,
      width: matchUpXl ? 255 : 165,
      renderCell: (params: GridValueGetterParams) => (
        <div
          style={{
            width: '100%',
            whiteSpace: 'normal',
            lineHeight: '20px',
          }}
        >
          {params.getValue(params.id, 'time') || ''}
        </div>
      ),
    },
  ];

  const getTransactions = async (params: TransactionRequestParams) => {
    try {
      setTransactionsLoading(true);
      const resultData = await transactionService.get(params);
      if (resultData && resultData.data && resultData.data.transactions) {
        const transactions = resultData.data.transactions.map((x: any) => {
          const asset = x.asset;
          const objAsset = (assetConfig as any)[asset];
          const obj = {
            id: x.transaction_id,
            asset: x.asset,
            symbol: x.asset,
            name: objAsset?.name || x.name,
            logo: objAsset?.icon || DEFAULT_LOGO,
            amount: displayNumb(x.amount),
            type: capitalizeFirstLetter(x.transaction_type),
            time: moment(x.datetime).toLocaleString(),
          };
          return obj;
        });
        setTransactions(transactions);
        setTransactionTotal(resultData.data.total);
        setTransactionsLoading(false);
      }
    } finally {
      setTransactionsLoading(false);
    }
  };

  const getOrders = async (params?: OrderRequestParams) => {
    try {
      setOrdersLoading(true);
      const { data } = await orderService.get(params);
      setOrders(
        data.items.map((item: any) => ({
          ...item,
          id: item.client_order_id,
          amount: displayNumb(item.filled_amount),
          proceeds: displayNumb(item.proceeds),
          cost: displayNumb(item.cost),
        }))
      );
      setOrderTotal(data.items_total);
    } finally {
      setOrdersLoading(false);
    }
  };

  useEffect(() => {
    getTransactions({ page: transactionPage + 1, page_size: 10 });
  }, [transactionPage]);

  useEffect(() => {
    getOrders({ page: orderPage + 1, page_size: 10 });
  }, [orderPage]);

  useEffect(() => {
    if (props.isRefresh) {
      getTransactions({ page: transactionPage + 1, page_size: 10 });
      getOrders({ page: orderPage + 1, page_size: 10 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isRefresh]);

  return (
    <div className={styles.componentFooterRight}>
      <ul className={styles.tabComponent}>
        <li
          className={`${styles.itemTabComponent} ${
            tabComponent === 1 && styles.activeTab
          }`}
          onClick={() => setTabComponent(1)}
        >
          Orders
        </li>
        <li
          className={`${styles.itemTabComponent} ${
            tabComponent === 0 && styles.activeTab
          }`}
          onClick={() => setTabComponent(0)}
        >
          Transactions
        </li>
      </ul>

      <div
        className={`${tableStyle.customerDataGrid} ${styles.styleTab}`}
        style={{
          height: `calc(46px + ${Number(10) * rowHeight}px + 60px)`,
        }}
      >
        {tabComponent === 1 ? (
          <DataGrid
            loading={ordersLoading}
            rows={orders}
            rowCount={orderTotal}
            columns={columnsOrder}
            disableSelectionOnClick
            disableColumnMenu
            headerHeight={46}
            rowHeight={rowHeight}
            hideFooter={true}
            pagination
            paginationMode="server"
            page={orderPage}
            pageSize={10}
            onPageChange={(page) => setOrderPage(page)}
            components={{
              Pagination: GridDataPagination,
              NoRowsOverlay: () => (
                <div
                  className={`${tableStyle.rowEmptydata}`}
                  style={isLogged ? {} : { height: '650px' }}
                >
                  <span
                    className={`icon-no-data ${tableStyle.iconEmptydata}`}
                  ></span>
                  <div className={tableStyle.textEmptydata}>No data</div>
                  <div className={tableStyle.messEmptydata}>
                    You will be able to see reports after <br /> completing
                    transactions
                  </div>
                  {isLogged ? (
                    <Button
                      variant="contained"
                      className={`${tableStyle.btnEmptydata}`}
                      onClick={() => history.push('/trading')}
                    >
                      Start trading
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={`${tableStyle.btnEmptydata}`}
                      onClick={() => setIsOpen(true)}
                    >
                      Sign up
                    </Button>
                  )}
                </div>
              ),
              LoadingOverlay: LoadingOverlay,
            }}
          />
        ) : (
          <DataGrid
            loading={transactionsLoading}
            rows={transactions}
            rowCount={transactionTotal}
            columns={columnsTransactions}
            disableSelectionOnClick
            disableColumnMenu
            headerHeight={46}
            rowHeight={rowHeight}
            hideFooter={true}
            pagination
            paginationMode="server"
            page={transactionPage}
            pageSize={10}
            onPageChange={(page) => setTransactionsPage(page)}
            components={{
              Pagination: GridDataPagination,
              NoRowsOverlay: () => (
                <div
                  className={`${tableStyle.rowEmptydata}`}
                  style={isLogged ? {} : { height: '650px' }}
                >
                  <span
                    className={`icon-no-data ${tableStyle.iconEmptydata}`}
                  ></span>
                  <div className={tableStyle.textEmptydata}>No data</div>
                  <div className={tableStyle.messEmptydata}>
                    You will be able to see reports after <br /> completing
                    transactions
                  </div>
                  {isLogged ? (
                    <Button
                      variant="contained"
                      className={`${tableStyle.btnEmptydata}`}
                      onClick={() => history.push('/trading')}
                    >
                      Start trading
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={`${tableStyle.btnEmptydata}`}
                      onClick={() => setIsOpen(true)}
                    >
                      Sign up
                    </Button>
                  )}
                </div>
              ),
              LoadingOverlay: LoadingOverlay,
            }}
          />
        )}
      </div>

      <ModalSignUp
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      ></ModalSignUp>
    </div>
  );
};

export default ComponentFooterLeft;
