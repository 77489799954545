import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    twoFatorAuthentication: {
      height: "inherit",
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      padding: 15,
      display: "flex",
      flexDirection: "column",
      marginTop: 20,
    },
    title: {
      fontFamily: "Ubuntu-Bold",
      fontSize: 18,
      lineHeight: "115%",
      color: theme.custom.colors.titleTrading,
      margin: 0,
      marginBottom: 8,
    },
    subTitle: {
      fontFamily: "Ubuntu-Regular",
      fontSize: 14,
      color: theme.custom.colors.titleTrading,
      lineHeight: "140%",
      margin: 0,
      marginBottom: 15,
      marginLeft: "34px"
    },
    textError: {
      fontFamily: "Ubuntu-Regular",
      fontSize: 12,
      color: '#DE4949',
      lineHeight: "14px",
      margin: 0,
      marginBottom: 15,
      textAlign: 'left',
      marginTop: '10px',
    },
    iconStyle: {
      fontSize: 35,
      width: "35px",
      height: "35px",
    },
    content: {
      display: 'flex',
      marginLeft: '34px',
      "& svg": {
        height: "262px",
        width: "262px",
        borderRadius: 8,
        border: `16px solid ${theme.custom.colors.borderQRCode}`
      },
      [theme.breakpoints.down("xs")]: {
        display: 'inline-block',
        marginLeft: '0px',
      },

    },

    codeQR: {
      [theme.breakpoints.down("xs")]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    form: {
      maxWidth: '205px',
      marginLeft: '10%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down("xs")]: {
        marginLeft: '0px',
        marginTop: '20px',
        width: '100%',
        maxWidth: '100%'
      },

      // "& input": {
      //     display: 'flex',
      //     flexDirection: 'column',
      //     justifyContent: 'center',
      //     alignItems: 'flex-start',
      //     padding: '10px 8px 10px 16px',
      //     border: `1px solid ${theme.custom.colors.boldGray}`,
      //     borderRadius: 6,
      //     flexGrow: 0,
      //     margin: '4px 0px',
      //     height: '50px',
      //     width: '100%',
      //     background: 'transparent',
      //     '::placeholder': {
      //         color: 'white',
      //     },

      // }
    },
    btnSubmit: {
      fontFamily: "Ubuntu-Bold",
      background: theme.custom.colors.cornflowerBlue,
      borderRadius: 6,
      minHeight: 36,
      minWidth: 'auto',
      fontSize: 12,
      color: "#FFFFFF",
      outline: "none",
      boxShadow: "none",
      textTransform: "inherit",
      marginTop: '1rem',
      width: '70%',

      "&:hover": {
        background: theme.custom.colors.cornflowerBlue,
        boxShadow: "none",
      },

      "&:disabled": {
        background: theme.custom.colors.bgDisable,
        color: "#FFFFFF !important"
      },

      [theme.breakpoints.down("xs")]: {
        width: '100%'
      },
    }

  };
});

export default useStyles;