const validator = require('validator');

export function useQuery() {
  return new URLSearchParams(window.location.search);
}

export const handleTrade = () => {
  (window as any).location.replace('/trade');
};

export const sendMail = (name: string, email: string) => {
  const breakLine = '%0D%0A';
  const mailTo = 'contact@onealpha.io';
  const subject = `Request demo`;
  const body = `Name: ${name}${breakLine}Company email: ${email}`;
  (
    window as any
  ).location = `mailto:${mailTo}?&subject=${subject}&body=${body}`;
};

export const activeMenu = (item: any, className: string) => {
  const pathOrHash =
    (window as any).location.hash || (window as any).location.pathname;
  const menuName = item.url || item.section || item.path;
  const isActive =
    menuName.length > 1
      ? pathOrHash.includes(menuName)
      : pathOrHash === menuName;

  return isActive ? className : '';
};

export const PAGE = {
  SIGNUP: '/sign-up',
  LOGIN: '/login',
  FORGOT: '/forgot',
  ERROR: 'error',
  TERM: '/term',
  PRIVACY: '/privacy',
};

export const KEY = {
  REDIRECT_URI: 'redirect_uri',
  LOGOUT: 'logout',
  USERNAME: 'username',
  REMEMBER_LOGIN: 'remember_login',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
};

export const CONST = {
  TIME_OUT_MAILTO: 2000,
};

export const convertCountryCodeToLocale = (countryCode?: string) => {
  if (!countryCode) return;
  return validator.isMobilePhoneLocales.find(
    (locale: string) => locale.split('-')[1] === countryCode.toUpperCase()
  );
};

export const trimObject = (obj: { [key: string]: any }) => {
  const result: { [key: string]: any } = {};
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      result[key] = obj[key].trim();
    } else {
      result[key] = obj[key];
    }
  }
  return result;
};

declare global {
  enum ModeScreen {
    SIGNUP = 'sign-up',
    FORGOT = 'forgot',
  }
}
