import { Button } from '@material-ui/core';
import useStyles from './styles';
const ButtonCommon = (props: any) => {
  const { children, onClick, type, className, isLoading, ...rest } = props;
  const style = useStyles();
  const renderIsLoading = () => {
    return (
      <>
        <div className={style.spiner}>
          <div className={style.bubble1}></div>
          <div className={style.bubble2}></div>
        </div>
      </>
    );
  };
  return (
    <Button
      onClick={onClick}
      type={type}
      className={`${style.btnCommon} ${className}`}
      {...rest}
    >
      {isLoading && renderIsLoading()}
      {children}
    </Button>
  );
};
export default ButtonCommon;
