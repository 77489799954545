import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    flashTradingCard: {
      display: "flex",
      flexDirection: "column",
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,
      padding: 10,
      cursor: 'pointer'
    },

    headCard: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 15,
    },

    coin: {
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      lineHeight: "140%",
      color: theme.custom.colors.white4,
      textTransform: "uppercase",

      "& img": {
        marginRight: 5,
        height: 20,
      },
    },

    lineHead: {
      marginLeft: 5,
      marginRight: 5,
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      lineHeight: "140%",
      color: theme.custom.colors.white4,
    },

    btnFavorite: {
      marginLeft: "auto",
      width: 20,
      height: 20,
      minWidth: "auto",
      padding: 0,
      fontSize: 20,
    },

    bodyCard: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill,minmax(50%,50%))",
      position: "relative",
      zIndex: 0
    },

    bodyCardBuy: {
      minHeight: 98,
      padding: "5px 11px",
      background: "rgba(76, 162, 80, 0.5)",
      borderRadius: "6px 0px 0px 6px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      "&:hover": {
        cursor: "pointer",
        background: "rgba(76, 162, 80, 0.25)",
      }
    },

    bodyCardSell: {
      minHeight: 98,
      padding: "5px 11px",
      background: "rgba(222, 73, 73, 0.5)",
      borderRadius: "0px 6px 6px 0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      "&:hover": {
        cursor: "pointer",
        background: "rgba(222, 73, 73, 0.25)",
      }
    },

    bodyCardMid: {
      position: "absolute",
      width: 48,
      height: 15,
      top: 0,
      left: "50%",
      marginLeft: -24,
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: theme.custom.colors.cardBackground,
      border: `1px solid ${theme.custom.colors.comet5}`,
      borderRadius: 4,
      fontSize: 12,
      lineHeight: "14px",
      color: theme.custom.colors.white2,
      fontFamily: 'Ubuntu-Regular',
    },

    titleBodyCard: {
      fontSize: 16,
      lineHeight: "140%",
      color: theme.custom.colors.white1,
      marginBottom: 5,
      fontFamily: 'Ubuntu-Regular',
    },

    valueBodyCard: {
      fontSize: 20,
      lineHeight: "23px",
      textAlign: "center",
      color: theme.custom.colors.white3,
      wordBreak: "break-word",
      fontFamily: 'Ubuntu-Regular',
    },

    footerCard: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 9,
    },

    footerCardLeft: {
      fontSize: 12,
      lineHeight: "14px",
      alignItems: "center",
      color: '#9BA1B1',
      wordBreak: "break-word",
      fontFamily: 'Ubuntu-Regular',
    },

    mrB5: {
      marginBottom: 6,
    },

    footerCardRight: {
      width: 89,
      height: 32,
      outline: 'none',

      background: "transparent",
      border: 'none',

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 5,
      fontSize: 16,
      lineHeight: "140%",
      color: theme.custom.colors.borderTradingWatch,
    },

    errorMsg: {
      color: '#DE4949',
      fontSize: 10,
    },

    formInputError: {
      border: `1px solid #DE4949 !important`,
      color: '#DE4949',
    },

    inputAmountContainer: {
      display: "flex",
      flexDirection: "row",
      border: `1.3px solid ${theme.custom.colors.borderTradingWatch}`,
      borderRadius: 8,
      justifyContent: "center",
      justifyItems: "center"
    }
  };
});

export default useStyles;
