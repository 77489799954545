import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {

    titlePageAPI: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "28px",
      color: theme.custom.colors.titleTrading,
      margin: "30px 0",
      [theme.breakpoints.down('sm')]: {
         fontSize: "20px"
      },
    },

    contentAPI:{
    },
  };
});

export default useStyles;