export function shortenAddress(address = '', start = 15, chars = 4) {
  if (address.length > 20) {
    return `${address.substring(0, start)}...${address.substring(
      address.length - chars
    )}`;
  } else {
    return address;
  }
}
export function shortenTitle(title = '', start = 15, chars = 4) {
  if (title.length > 20) {
    return `${title.substring(0, start)}...${title.substring(
      title.length - chars
    )}`;
  } else {
    return title;
  }
}
