import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    homeWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',

      paddingTop: '20px',
      paddingBottom: 40,

      [theme.breakpoints.down('xs')]: {
        paddingTop: '20px',
      },

      [theme.breakpoints.only('sm')]: {
        paddingTop: '20px',
      },

      [theme.breakpoints.only('md')]: {
        paddingTop: '20px',
      },

      [theme.breakpoints.only('lg')]: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
    },
    styleHome: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        margin: '0 -20px',
      },
    },
    welcomeContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      columnGap: '24px',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        rowGap: '24px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 20px',
        marginBottom: 24
      },
    },
    priceContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      columnGap: '24px',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        rowGap: '24px',
      },
    },

    title: {
      fontSize: 18,
      fontFamily: 'Ubuntu-Bold',
      color: theme.custom.colors.titleTrading,
      marginBottom: 20,

      [theme.breakpoints.down('xs')]: {
        padding: '0 20px',
      },
    }
  };
});

export default useStyles;
