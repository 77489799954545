import { Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_INPUT,
} from 'src/commons/constants/AppInputFormat';
import AppButton from 'src/components/Base/AppButton';
import AppInput from 'src/components/Base/AppInput';
import AppPhone from 'src/components/Base/AppPhone';
import { trimObject } from 'src/utils/utils';
import { REGEX, rulesTextField } from 'src/utils/validation';
import AddPerson from './AddPerson';
import useStyles from './styles';

const General = ({
  formData,
  lastErrors,
  setStep,
  saveData,
}: {
  formData: any;
  lastErrors?: any;
  setStep: (step: number, state?: any) => void;
  saveData: (data: any, errors?: any) => void;
}) => {
  const classes = useStyles();
  const [contactArr, setContactArr] = useState<any>(
    formData?.addContactPerson || []
  );

  const contactArrRef = useRef<any>(contactArr);

  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      entityLegalName: formData?.entityLegalName,
      taxIdentificationNumber: formData?.taxIdentificationNumber,
      websiteURL: formData?.websiteURL,
      primaryBusinessAddress: formData?.primaryBusinessAddress,
      primaryContactPerson: formData?.primaryContactPerson,
      email: formData?.email,
      phoneNumber: formData?.phoneNumber,
    },
  });

  const addContact = () => {
    const numberContact = (contactArr?.length || 0) + 2;
    let obj: any = {};
    obj[`contactPerson${numberContact}`] = {};

    setContactArr([...contactArr, obj]);
  };

  useEffect(() => {
    contactArrRef.current = {
      addContactPerson: contactArr,
      inValidContact:
        contactArr.findIndex((item: any) => item.isValid === false) !== -1,
    };
  }, [contactArr]);

  useEffect(() => {
    const runSaveData = () => {
      let data = trimObject(getValues());

      contactArrRef.current.addContactPerson.length &&
        (data.addContactPerson = contactArrRef.current.addContactPerson);
      trigger([
        'entityLegalName',
        'email',
        'phoneNumber',
        'primaryBusinessAddress',
        'primaryContactPerson',
        'taxIdentificationNumber',
      ]).then((isValid) => {
        saveData(
          {
            general: { ...data },
          },
          { general: !isValid || contactArrRef.current.inValidContact }
        );
      });
    };

    return runSaveData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  useEffect(() => {
    lastErrors && trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.stepContainer}>
      <div className={classes.title}>
        <div>General Information</div>
        <Tooltip
          title={
            <>
              <div className={classes.labelTooltip}>Info</div>
              <div className={classes.descTooltip}>
                Thank you for your interest in oneAlpha. As part of our
                onboarding process, we use this form to collect and verify
                information on each potential counterparty. We may request
                additional documentation including, but not limited, to
                financial information in connection with our onboarding process.
                By completing this form, you confirm that all information
                entered is true and accurate.
              </div>
            </>
          }
          classes={{ tooltip: classes.customTooltip }}
        >
          <span className="icon-info"></span>
        </Tooltip>
      </div>
      <form className={classes.groupForm}>
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'entityLegalName',
            rules: rulesTextField('Entity Legal Name'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Entity Legal Name'}
          error={Boolean(errors.entityLegalName)}
          placeholder="Entity Legal Name"
          helperText={errors.entityLegalName?.message}
          labelProps={{
            className: errors.entityLegalName ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'taxIdentificationNumber',
            rules: rulesTextField('Tax Identification Number'),
          }}
          inputNumber={true}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Tax Identification Number'}
          error={Boolean(errors.taxIdentificationNumber)}
          placeholder="Tax Identification Number"
          helperText={errors.taxIdentificationNumber?.message}
          labelProps={{
            className: errors.taxIdentificationNumber ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'websiteURL',
            // rules: {
            //   ...REGEX.website,
            //   ...rulesTextField('Website URL'),
            // },
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Website URL'}
          error={Boolean(errors.websiteURL)}
          placeholder="Website URL"
          helperText={errors.websiteURL?.message}
          labelProps={{
            className: errors.websiteURL ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'primaryBusinessAddress',
            rules: rulesTextField('Primary Business Address'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Primary Business Address'}
          error={Boolean(errors.primaryBusinessAddress)}
          placeholder="Primary Business Address"
          helperText={errors.primaryBusinessAddress?.message}
          labelProps={{
            className: errors.primaryBusinessAddress ? classes.styleLabel : '',
          }}
        />
        <div className={`${classes.title} mt-40`}>Contact person 1</div>
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'primaryContactPerson',
            rules: rulesTextField('Primary Contact Person'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Primary Contact Person'}
          error={Boolean(errors.primaryContactPerson)}
          placeholder="Full name"
          helperText={errors.primaryContactPerson?.message}
          labelProps={{
            className: errors.primaryContactPerson ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'email',
            rules: {
              ...REGEX.email,
              ...rulesTextField('Email'),
            },
          }}
          inputProps={{ maxLength: MAX_LENGTH_EMAIL }}
          label={'Email'}
          error={Boolean(errors.email)}
          placeholder="Email"
          helperText={errors.email?.message}
          labelProps={{
            className: errors.email ? classes.styleLabel : '',
          }}
        />
        <AppPhone
          classNames={classes.inputItem}
          label="Phone Number"
          defaultCountry="af"
          controller={{
            control,
            name: 'phoneNumber',
            rules: {
              ...REGEX.phoneNumber,
              ...rulesTextField('Phone Number'),
            },
          }}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber?.message}
          labelProps={{
            className: errors.phoneNumber ? classes.styleLabel : '',
          }}
          textPlaceholder={formData?.phoneNumber ? '' : '| __-___-__-__'}
        />
        {contactArr.map((contact: any, index: number) => {
          return (
            <AddPerson
              key={contact}
              lastErrors={lastErrors}
              contactPerson={contact[`contactPerson${index + 2}`]}
              setContactPerson={(item: any) => {
                contactArr[index] = {
                  ...item,
                };
                setContactArr([...contactArr]);
              }}
              numberContact={index + 2}
              isDelete={contactArr?.length === index + 1}
              handleDelete={() => {
                contactArr.pop();
                setContactArr([...contactArr]);
              }}
            />
          );
        })}
        <div
          className={classes.btnLink}
          onClick={() => {
            if (contactArr?.length < 3) {
              addContact();
            }
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && contactArr?.length < 3) {
              addContact();
            }
          }}
        >
          Add contact person
        </div>
        <div className={classes.btnNext}>
          <AppButton fullWidth={true} onClick={() => setStep(2)}>
            Next
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default General;
