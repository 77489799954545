import { useLocation } from 'react-router';
import configureStore from 'src/store/configureStore';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function toLogin({
  path,
  logout,
}: {
  path?: string;
  logout?: boolean;
} = {}) {
  // Get path
  path = encodeURIComponent(path || window.location.href);
  // Check login
  const { store } = configureStore();
  const auth = store.getState().auth;
  logout = logout || !auth.isLogged;
  // Redirect
  (window as any).location = '/login';
}

export function toSignUp() {
  (window as any).location = "/";
}

export const validatePassword = (pass: any) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?/#~_+\-=|\\]).{8,}$/g;
  return re.test(String(pass));
};

export const removeAccents = (str: String) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
    .replace(/[^a-zA-Z0-9*.!@$%^&(){}[\]:;<>,.?/#~_+\-=|\\]/g, '');
};

export function capitalizeFirstLetter(str: string) {
  if (!str || (str && str === '')) return '';
  return str[0].toUpperCase() + str.slice(1)?.toLowerCase();
}
export const toCapitalized = (string: string) => {
  if (!string) return '';
  return string
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .map((part) => part[0].toUpperCase().concat(part.slice(1)))
    .join(' ');
};
