import { HTTPBaseService } from 'src/request/HTTPBaseService';

export class AccountService extends HTTPBaseService {
  private static classInstance?: AccountService;

  public static getInstance(token: string) {
    if (!this.classInstance) {
      this.classInstance = new AccountService();
    }
    return this.classInstance;
  }
  public async getBalances() {
    return await this.instance.get('/account/balances/');
  }
  public async getBanks() {
    return await this.instance.get('/private/account/banks/');
  }
  public async getTransactions() {
    return await this.instance.get('/account/transactions/');
  }
  public async getProfile() {
    return await this.instance.get('/private/user/profile');
  }

  public async has2FADevices() {
    return await this.instance.get('/private/2fa_devices');
  }
  public async getBalancesByAsset(data: any) {
    return await this.instance.post(`/account/balances/`, data);
  }

  public async createBank(params?: any) {
    return await this.instance.post<any>('/private/account/banks/', params);
  }

  public async getPreferences() {
    return await this.instance.get<any>('/private/preferences/');
  }

  public async setPreferences(params?: any) {
    return await this.instance.put<any>('/private/preferences/', params);
  }
  public async getOrdersVolume(asset: string, date:string) {
    return await this.instance.get(`/private/orders/volume/?asset=${asset}&scale=${date}`);
  }
}

// export instance of account service
export const accountService = new AccountService();
