import { Dialog } from '@material-ui/core';
import { CardThankYou } from 'src/components/BaseLandingPage/Common/Card';
import useStyles from './styles';

const ModalThanks = ({
  open: openState,
}: {
  open: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}) => {
  const [open, setOpen] = openState;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: '#2C2949',
            boxShadow: 'none',
            maxWidth: '100%',
            width: 400,
            borderRadius: '10px',
          },
        }}
        className={classes.styleDialog}
      >
        <CardThankYou
          icon="/images/icon-thank-you.png"
          title={'Success!'}
          content={'Your request has been sent successfully'}
          contentBtn="OK"
          onClickOk={() => setOpen(false)}
        />
      </Dialog>
    </div>
  );
};

export default ModalThanks;
