/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import AppOTPInput from 'src/components/Base/AppOTPInput';
import ModalCustom from 'src/components/Base/ModalCustom';
import AppButton from 'src/components/Base/AppButton';

import useStyles from './styles';
import { useSelector } from 'react-redux';

export const ModalConfirmCode = ({
  isOpen,
  loading,
  handleClose,
  onSubmit,
  errorCode,
  setErrorCode,
  subTitle,
}: {
  isOpen: boolean;
  loading: boolean;
  handleClose: (type: number) => void;
  onSubmit: (pinCode: string, twoFA?: string) => void;
  errorCode: boolean;
  setErrorCode: (status: boolean) => void;
  subTitle: String;
}) => {
  const styles = useStyles();
  const [pinCode, setPinCode] = useState('');
  const [twoFAcode, setTwoFACode] = useState('');

  const has2FA = useSelector(
    (state: any) => state.accountReducer.has2FADevices
  );

  const handleChangeCode = (value: any) => {
    setPinCode(value);
    setErrorCode(false);
  };

  const handleChangeTwoFA = (value: any) => {
    setTwoFACode(value);
    setErrorCode(false);
  };

  useEffect(() => {
    setPinCode('');
    setErrorCode(false);
  }, [isOpen]);

  return (
    <ModalCustom
      isOpen={isOpen}
      handleClose={() => handleClose(2)}
      className={styles.main}
    >
      <Typography className={styles.title} style={{ marginBottom: '10px' }}>
        Confirmation
      </Typography>
      <Typography className={styles.subTitle}>{subTitle}</Typography>
      <AppOTPInput onChange={handleChangeCode} hasError={errorCode} />
      {has2FA && (
        <>
          <Typography className={styles.subTitle}>
            Enter 2FA confirmation code
          </Typography>
          <AppOTPInput onChange={handleChangeTwoFA} hasError={errorCode} />
        </>
      )}
      {errorCode && (
        <Typography className={styles.textError}>
          {has2FA
            ? 'Email or 2FA code is incorrect. Please check and try again.'
            : 'The email code is incorrect. Please check and try again.'}
        </Typography>
      )}
      <div className={styles.btnGroup2}>
        <AppButton
          fullWidth
          onClick={() => handleClose(2)}
          mode="secondary"
          height={'36px'}
          fontSize={12}
        >
          Cancel
        </AppButton>
        <AppButton
          fullWidth
          isLoading={loading}
          isDisable={
            pinCode.length < 6 || (has2FA && twoFAcode.length < 6) || errorCode
          }
          type="submit"
          onClick={() => onSubmit(pinCode, twoFAcode)}
          height={'36px'}
          fontSize={12}
        >
          Confirm
        </AppButton>
      </div>
    </ModalCustom>
  );
};

export default ModalConfirmCode;
