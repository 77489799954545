import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    btnCommon: {
      background: theme.custom.colors.btnSignUp,
      borderRadius: 6,
      height: 50,
      width: 188,
      fontFamily: 'Titillium Web',
      fontSize: 12,
      fontWeight: 700,
      color: theme.custom.colors.white,
      textTransform: 'none',
      boxShadow: 'none',
      marginTop: 26,
      marginBottom: 20,
      '&:hover': {
        background: theme.custom.colors.btnSignUp,
        opacity: 0.8,
      },
      [theme.breakpoints.down('xs')]: {
        height: 40,
        width: 150,
      },

      '&:disabled': {
        background: theme.custom.colors.btnDisable,
        color: theme.custom.colors.white,
        cursor: 'no-drop',
        pointerEvents: 'auto',

        '&:hover': {
          background: theme.custom.colors.btnDisable,
          opacity: 1,
        },
      },
    },

     spiner: {
      animation: 'loadingI 2s linear infinite',
      position: 'relative',
      width: '25px',
      height: '25px',
      marginRight: '10px',
    },
    bubble1: {
      animation: 'bounce 2s ease-in-out infinite',
      position: 'absolute',
      top: 0,
      width: '15px',
      height: '15px',
      borderRadius: '100%',
      backgroundColor: theme.custom.colors.white,
    },
    bubble2: {
      animation: 'bounce 2s ease-in-out infinite',
      animationDelay: '-1s',

      position: 'absolute',
      top: 'auto',
      bottom: 0,
      width: '15px',
      height: '15px',
      borderRadius: '100%',
      backgroundColor:  theme.custom.colors.white,
    },
  };
});
export default useStyles;
