import { makeStyles } from "@material-ui/core";
import {
  drawerWidth,
  drawerWidthXl,
} from "../../../store/constants/width-page";

const useStyles = makeStyles((theme: any) => {
  return {
    appBar: {
      [theme.breakpoints.up("xl")]: {
        padding: "0 96px",
      },
      boxShadow: "none",
      background: theme.custom.colors.mainBackground,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    appBarShift: {
      marginLeft: drawerWidth,
      boxShadow: "none",
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up("xl")]: {
        width: `calc(100% - ${drawerWidthXl}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    appBarShiftClose: {
      marginLeft: 65,
      boxShadow: "none",
      width: `calc(100% - ${65}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up("xl")]: {
        width: `calc(100% - ${65}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    toolbarHeader: {
      minHeight: 70,
      paddingLeft: 20,
      paddingRight: 20,
      borderBottom: `1px solid ${theme.custom.colors.comet2}`,
      [theme.breakpoints.up("xl")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: 60,
      },
    },

    boxLogoTrading: {
      width: 115,
      marginRight: 165,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        marginRight: 93,
      },
      [theme.breakpoints.down("md")]: {
        width: 35,
        marginRight: 30,
      },
     
    },

    logoTrading: {
      "& img": {
        height: 48,
      },
    },

    headerMain: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
      "& .Mui-disabled": {
        background: '#6069834D',
        color: 'white',
        " & .icon-settings:before": {
        color: 'white',
        }
      }
    },

    hide: {
      display: "none",
    },

    logoMobile: {
      display: "flex",
      "& img": {
        height: 44,
      },
    },

    menuButton: {
      marginRight: 36,

      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        left: 20,
        top: 6,
      },
    },

    menuIcon: {
      color: theme.custom.colors.cornflowerBlue,
    },

    titlePage: {
      fontFamily: "Ubuntu-Bold",
      fontSize: 24,
      flexGrow: 1,
      color: theme.custom.colors.white3,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    btnDeposit: {
      background: theme.custom.colors.btnEnable,
      borderRadius: 6,
      height: 36,
      fontFamily: "Ubuntu-Bold",
      fontSize: 12,
      color: theme.custom.colors.btnTitle,
      marginRight: 20,
      boxShadow: "none",
      textTransform: 'none',

      "&:hover": {
        background: theme.custom.colors.btnHover,
      },
    },

    rectangle: {
      height: 36,
      width: 1,
      marginRight: 10,
      background: theme.custom.colors.comet,
    },

    btnToggleMode: {
      height: 36,
      width: 36,
      minWidth: 36,
      borderRadius: 6,
      background: "transparent",
      boxShadow: "none",
      fontSize: 24,

      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        right: 10,
        top: 13,
      },

      "& .icon-mode:before": {
        color: theme.custom.colors.white2,
      },

      "&:hover": {
        background: "transparent",
      },
    },

    btnNotification: {
      height: 36,
      width: 36,
      minWidth: 36,
      borderRadius: 6,
      background: "transparent",
      boxShadow: "none",
      fontSize: 24,
      marginLeft: 10,

      [theme.breakpoints.down("md")]: {
         marginLeft: 0
      },

      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        right: 10,
        top: 13,
      },

      [theme.breakpoints.down("xs")]: {
        fontSize: 32,
      },

      "& .icon-notification:before": {
        color: theme.custom.colors.white2,
      },

      "&:hover": {
        background: "transparent",
      },
    },

    btnSettings: {
      height: 36,
      width: 36,
      minWidth: 36,
      borderRadius: 6,
      background: "transparent",
      boxShadow: "none",
      fontSize: 24,
      marginLeft: 10,

      [theme.breakpoints.down("md")]: {
        marginLeft: 0
      },

      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        right: 10,
        top: 13,
      },

      [theme.breakpoints.down("xs")]: {
        fontSize: 32,
      },

      "& .icon-settings:before": {
        color: theme.custom.colors.white2,
      },

      "&:hover": {
        background: "transparent",
      },
    },

    listMenuTrading: {
      display: "flex",
      padding: 0,
    },

    ListItemMenu: {
      padding: 0,
      borderRight: `1px solid ${theme.custom.colors.secondary}`,

      "&:first-child": {
        borderLeft: `1px solid ${theme.custom.colors.secondary}`,
      },
    },

    itemMenuTrading: {
      color: theme.custom.colors.white4,
      fontSize: 16,
      display: "inline-flex",
      alignItems: "center",
      padding: "8px 20px",
      lineHeight: "20px",
      fontFamily: "Ubuntu-Regular",
      fontWeight: 400,

      "&:hover": {
        color: theme.custom.colors.lightPurple,
        textDecoration: "none",
      },

      [theme.breakpoints.only("lg")]: {
        padding: "8px 15px",
      },

      [theme.breakpoints.down("md")]: {
        padding: "8px 3px",
      },

      [theme.breakpoints.between("991", "1152")]: {
        padding: "8px 8px !important",
      },

    },

    itemMenuTradingActive: {
      color: theme.custom.colors.lightPurple,
      fontFamily: "Ubuntu-Bold",
    },

    iconMenuTrading: {
      color: theme.custom.colors.santaGray,
      fontSize: 24,
      marginRight: 10,

      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },

      "&:before": {
        color: theme.custom.colors.santaGray,
      },
    },

    iconMenuTradingActive: {
      "&:before": {
        color: theme.custom.colors.lightPurple,
      },
    },

    menuSetting: {

    },

    menuProfile: {}
  };
});

export default useStyles;
