import { Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import ButtonCommon from 'src/components/BaseLandingPage/Common/Button';
import HeaderLogo from 'src/components/BaseLandingPage/Common/HeaderLogo';

import useStyles from './styles';

const NotFoundPage = ({
  title = '404 Not Found',
  content = '',
}: {
  title?: string;
  content?: string;
}) => {
  const styles = useStyles();
  const history = useHistory();

  useEffect(() => {
    document.body.style.background = '#040929';
  }, []);

  return (
    <div className={styles.cardRoot}>
      <HeaderLogo />
      <div className={styles.box}>
        <div className={styles.boxContent}>
          <img src="/images/icons-cancel.png" alt="" />
          <Typography className={styles.title}> {title} </Typography>
          <Typography className={`${styles.content}`}>{content}</Typography>
        </div>
        <ButtonCommon
          onClick={() => {
            history.push('/');
          }}
        >
          OK
        </ButtonCommon>
      </div>
    </div>
  );
};

export default NotFoundPage;
