import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    search: {
      padding: '10px 15px',
      display: 'none'
    },
    iconSearch: {
      position: 'absolute',
      top: '12px',
      right: '20px',
      fontSize: 25,
      '&:before': {
        color: theme.custom.colors.textContentCard,
      },
    },

    input: {
      textIndent: '10px',
      height: 30,
      background: 'none',
      borderRadius: '5px',
      color: theme.custom.colors.textContentCard,
      fontWeight: 500,
      fontSize: 14,
      borderColor: theme.custom.colors.textContentCard,
      border: '1px solid',
      width: '100%',
      "&:focus": {
        borderColor: theme.custom.colors.lightPurple,
        outline: 'none'
      },
      "&::placeholder": {
        color:  theme.custom.colors.textContentCard,
      }
    },

    listbox: {
      maxHeight: 200,
      overflowY: 'auto',
      padding: 0,
      "& .MuiListItem-button": {
        "&:hover": {
          background: theme.custom.colors.cornflowerBlue,
          "& $optionItem": {
            color:  theme.custom.colors.btnTitle
          }
        },
       
      }
      
    },

    optionItem: {
      color: theme.custom.colors.white4,
      display: 'flex',
      textTransform: 'uppercase',
      fontFamily: 'Ubuntu-Bold',

      '& img': {
        height: 25,
        marginRight: 10,
      },
    },
  };
});
export default useStyles;
