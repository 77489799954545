import { useSelector } from 'react-redux';
import WithdrawalBank from './WithdrawalBank';
import WithdrawalCrypto from './WithdrawalCrypto';

export default function Withdrawal() {
  const assetSelected = useSelector((state: any) => state.asset.assetSelected);

  return (process.env.REACT_APP_FIAT_LIST || '')
    .split(',')
    .includes(assetSelected) ? (
    <WithdrawalBank />
  ) : (
    <WithdrawalCrypto />
  );
}
