import React from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import useStyles from './styles';

const Icon = ({
  variant,
}: {
  variant: 'success' | 'error' | 'warning' | 'info';
}) => {
  const classes = useStyles();

  return (
    <span
      className={clsx(classes.icon, `icon-${variant}`, classes[variant])}
    ></span>
  );
};

const customToast = {
  success: (message: string, options?: any) =>
    toast.success(message, {
      icon: <Icon variant="success" />,
      ...options,
    }),
  error: (message: string, options?: any) =>
    toast.error(message, {
      icon: <Icon variant="error" />,
      ...options,
    }),
  warning: (message: string, options?: any) =>
    toast.warning(message, {
      icon: <Icon variant="warning" />,
      ...options,
    }),
  info: (message: string, options?: any) =>
    toast.info(message, {
      icon: <Icon variant="info" />,
      ...options,
    }),
};
export default customToast;
