import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  icon: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  success: {},
  error: {},
  warning: {
    '&:before': {
      color: '#f1c40f',
    },
  },
  info: {
    '&:before': {
      color: '#7F63F4',
    },
  },
}));

export default useStyles;
