import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentChartTrading: {
      position:'relative',
      height: '100%',
      background: theme.custom.colors.cardBackground,
      borderRadius: 8,

      [theme.breakpoints.down('sm')]: {
        gridArea: 'header',
        height: 357,
        maxWidth: 728,
      },

      [theme.breakpoints.between('600', '680')]: {
        maxWidth: 572,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
    },

    drop1: {
      display: 'flex',
      alignItems: 'center',
      gridGap: '10px',
    },
    zoom: {
      '& .icon-zoom:before': {
        fontSize: 20,
        color: theme.custom.colors.titleTrading,
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },

    dropChart: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px  10px',
      gridGap: '10px',

      '& .icon-star:before': {
        fontSize: 25,
      },

      '& .icon-star:hover': {
        cursor: 'pointer',
      },
    },

   

    mainDrop: {
      display: 'flex',
      flexDirection: 'column',
    },

   

   


    coinLogo: {
      width: '15px',
      height: '15px',
    },

    coinSymbol: {
      marginLeft: '10px',
      fontSize: '14px',
      lineHeight: '130%',
    },

    numberTotal: {
      fontSize: '20px',
      lineHeight: '23px',
      color: theme.custom.colors.white4,
      marginLeft: 26,
      display: 'block',

      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },

    numberPercent: {
      fontSize: '14px',
      lineHeight: '140%',
      color: theme.custom.colors.green1,
      display: 'block',

      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },

    downColor: {
      color: `#ea3943`
    },

    btnFavorite: {
      marginLeft: 'auto',
      width: 20,
      height: 20,
      minWidth: 'auto',
      padding: 0,
      fontSize: 20,
    },
      input: {
      textIndent: '10px',
      height: 30,
      background: 'none',
      borderRadius: '5px',
      color: theme.custom.colors.textContentCard,
      fontWeight: 500,
      fontSize: 14,
      borderColor: theme.custom.colors.textContentCard,
      border: '1px solid',
      width: '100%',
      "&:focus": {
        borderColor: theme.custom.colors.lightPurple,
        outline: 'none'
      },
      "&::placeholder": {
        color:  theme.custom.colors.textContentCard,
      }
    },
     
   iconSearch: {
      position: 'absolute',
      top: '12px',
      right: '12px',
      fontSize: 25,
      '&:before': {
        color: theme.custom.colors.textContentCard,
      },
    },
   
    search: {
     marginBottom: 5
    },

    loading: {
      width: '100%',
      height: '100%',
      top: 0,
      zIndex: 100,
      position: 'absolute',
      background:  theme.custom.colors.bgrOverLay,
      opacity: 0.8,
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
      "& div": {
          position: 'absolute',
          top:'37%',
          right: '45%'
      },
      [theme.breakpoints.down('xs')]: {
         height: '100%',
      },
    }

  };
});

export default useStyles;
