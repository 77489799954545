import { useHistory } from 'react-router';
import CardCommon from 'src/components/BaseLandingPage/Common/Card';
import { PAGE } from 'src/utils/utils';

const ConfirmComponent = ({
  email,
  mode,
}: {
  email: string;
  mode: ModeScreen;
}) => {
  const history = useHistory();
  const modeSignUp = mode === 'sign-up';

  const onOk = () => {
    history.push('/');
  };

  const toLogin = () => {
    history.push(PAGE.LOGIN);
  };

  return (
    <CardCommon
      icon="/images/email_open.png"
      title={
        modeSignUp
          ? 'Confirmation email sent'
          : 'Password reset request submitted successfully!'
      }
      content={
        modeSignUp
          ? `Email was sent to ${decodeURIComponent(
            email
          )}\nPlease confirm via email`
          : `Please check your mailbox for a link to reset your password.`
      }
      link="Login"
      label={modeSignUp ? 'Already have an account?' : ''}
      contentBtn="OK"
      onClickOk={onOk}
      onClickResent={toLogin}
    ></CardCommon>
  );
};

export default ConfirmComponent;
