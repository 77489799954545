import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_INPUT,
} from 'src/commons/constants/AppInputFormat';
import AppButton from 'src/components/Base/AppButton';
import AppInput from 'src/components/Base/AppInput';
import AppPhone from 'src/components/Base/AppPhone';
import { trimObject } from 'src/utils/utils';
import { REGEX, rulesTextField } from 'src/utils/validation';
import useStyles from './styles';

const Organization = ({
  formData,
  lastErrors,
  setStep,
  saveData,
}: {
  formData: any;
  lastErrors: any;
  setStep: (step: number, state?: any) => void;
  saveData: (data: any, errors?: any) => void;
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      idNumber: formData?.idNumber,
      residentialAddress: formData?.residentialAddress,
      phoneNumber: formData?.phoneNumber,
      taxId: formData?.taxId,
      correspondenceAddress: formData?.correspondenceAddress,
      emailAddress: formData?.emailAddress,
    },
  });

  useEffect(() => {
    const runSaveData = () => {
      let data = trimObject(getValues());

      trigger([
        'idNumber',
        'residentialAddress',
        'phoneNumber',
        'taxId',
        'correspondenceAddress',
        'emailAddress',
      ]).then((isValid) => {
        saveData(
          {
            organization: { ...data },
          },
          { organization: !isValid }
        );
      });
    };

    return runSaveData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  useEffect(() => {
    lastErrors && trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.stepContainer}>
      <div className={classes.title}>
        <div>Contact information</div>
        <span className="icon-info"></span>
      </div>
      <form>
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'idNumber',
            rules: rulesTextField('Passport/ID number'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          inputAddress={true}
          label={'Passport/ID number'}
          error={Boolean(errors.idNumber)}
          placeholder="Passport/ID number"
          helperText={errors.idNumber?.message}
          labelProps={{ className: errors.idNumber ? classes.styleLabel : '' }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'residentialAddress',
            rules: rulesTextField('Residential address'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Residential address'}
          error={Boolean(errors.residentialAddress)}
          placeholder="Residential address"
          helperText={errors.residentialAddress?.message}
          labelProps={{
            className: errors.residentialAddress ? classes.styleLabel : '',
          }}
        />
        <AppPhone
          classNames={classes.inputItem}
          label="Phone Number"
          defaultCountry="af"
          controller={{
            control,
            name: 'phoneNumber',
            rules: {
              ...REGEX.phoneNumber,
              ...rulesTextField('Phone Number'),
            },
          }}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber?.message}
          labelProps={{
            className: errors.phoneNumber ? classes.styleLabel : '',
          }}
          textPlaceholder={formData?.phoneNumber ? '' : '| __-___-__-__'}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'taxId',
          }}
          inputNumber={true}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Tax ID (if different from Passport/ID)'}
          error={Boolean(errors.taxId)}
          placeholder="Tax ID"
          helperText={errors.taxId?.message}
          labelProps={{ className: errors.taxId ? classes.styleLabel : '' }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'correspondenceAddress',
            rules: rulesTextField('Correspondence address'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Correspondence address'}
          error={Boolean(errors.correspondenceAddress)}
          placeholder="Correspondence address"
          helperText={errors.correspondenceAddress?.message}
          labelProps={{
            className: errors.correspondenceAddress ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'emailAddress',
            rules: {
              ...REGEX.email,
              ...rulesTextField('Email address'),
            },
          }}
          inputProps={{ maxLength: MAX_LENGTH_EMAIL }}
          label={'Email address'}
          error={Boolean(errors.emailAddress)}
          placeholder="Email address"
          helperText={errors.emailAddress?.message}
          labelProps={{
            className: errors.emailAddress ? classes.styleLabel : '',
          }}
        />
        <div className={classes.btnNext}>
          <AppButton
            fullWidth={true}
            isDisable={false}
            onClick={() => setStep(1)}
          >
            Back
          </AppButton>
          <AppButton fullWidth={true} onClick={() => setStep(3)}>
            Next
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default Organization;
