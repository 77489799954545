import { makeStyles } from '@material-ui/core';

const useClasses = makeStyles((theme: any) => {
  return {
    main: {},
    customize: {
      display: 'flex !important',
      justifyContent: 'space-between',
      padding: '0 100px',
      '& input': {
        width: 50,
        height: 50,
        fontSize: 22,
        background: 'none',
        color: theme.custom.colors.radioChecked,
        borderRadius: 8,
        textAlign: 'center',
        border: '1px solid #EBECF0',
        outline: 'none',
        '&:focus': {
          color: theme.custom.colors.radioChecked,
          textAlign: 'center',
          border: '2px solid #B8A7FF !important',
          boxShadow: `0 6px 6px -6px #B8A7FF`,
        },
        [theme.breakpoints.down('xs')]: {
          width: 39,
          height: 41,
        },
      },
      [theme.breakpoints.only('xs')]: {
        padding: '0 7px',
      },
    },
    titleColor: {
      color: `${theme.custom.colors.colorLbl} !important`,
    },
    textCenter: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    display: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    labelContent: {
      textAlign: 'center',
      paddingBottom: 25,
      fontSize: 16,
      [theme.breakpoints.only('xs')]: {
        fontSize: 16,
        paddingBottom: 20,
      },
    },
    borderColor: {
      '& input': {
        border: '2px solid #B8A7FF',
      },
    },
    divBtn: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 108px',
      [theme.breakpoints.only('xs')]: {
        padding: '0 10px',
      },

      '& > button + button': {
        marginLeft: '16px',
      },
    },

    pinCode: {
      background: 'none',
      fontSize: 22,
      width: 50,
      height: 50,
       [theme.breakpoints.only('xs')]: {
        width: 40,
        height: 40,
        fontSize: 20,
      },
    },

    btnCancel: {
      background: 'none',
      border: `1px solid ${theme.custom.colors.white}`,
      color:theme.custom.colors.white,
      "&:hover": {
        background: 'none',
        color:theme.custom.colors.cornflowerBlue,
        borderColor: theme.custom.colors.cornflowerBlue
      }
    }
  };
});
export default useClasses;
