import React, { ChangeEvent } from "react";
import useStyles from "./styles";
import clsx from "clsx";

interface ToggleProps {
  checked: boolean;
  defaultChecked: boolean;
  disabled: boolean;
  size: "normal" | "large";
  name: string;
  className: string;
  label: string;
  onChange: Function;
}

const Toggle: React.FC<any> = (props: ToggleProps) => {
  const {
    checked,
    defaultChecked,
    disabled,
    size = "normal",
    name,
    className,
    label,
    onChange,
  } = props;

  const classes = useStyles();
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange && onChange(value);
  };
  return (
    <label
      className={clsx([
        className,
        classes.container,
        {
          [classes.disabled]: disabled,
          [classes.largeToggle]: size === "large",
        },
      ])}
    >
      <input
        type="checkbox"
        name={name}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <span className={classes.slider}></span>
      <span className={classes.label}>{label}</span>
    </label>
  );
};

export default Toggle;
