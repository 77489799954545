import React, { MouseEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

export interface LinkProps {
  href?: string;
  hardAnchor?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
}

export default function Link({ hardAnchor, children, ...rest }: LinkProps) {
  return (
    <MuiLink
      component={!hardAnchor && rest.href ? RouterLink : 'a'}
      to={rest.href}
      {...rest}
    >
      {children}
    </MuiLink>
  );
}
