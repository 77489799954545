import React, { ChangeEvent } from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

import useStyles from './styles';
import clsx from 'clsx';

interface CheckboxProps {
  checked: boolean;
  defaultChecked: boolean;
  disabled: boolean;
  className: string;
  label: string;
  onChange: Function;
}

const Checkbox: React.FC<any> = (props: CheckboxProps) => {
  const {
    checked,
    defaultChecked,
    disabled,
    className,
    label,
    onChange,
    ...rest
  } = props;

  const classes = useStyles();
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    onChange && onChange(checked);
  };
  return (
    <FormControlLabel
      className={className}
      control={
        <MuiCheckbox
          className={clsx([
            classes.container,
            { [classes.disabled]: disabled },
          ])}
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={handleOnChange}
          disabled={disabled}
          classes={{ disabled: classes.disabled }}
          icon={<span className={classes.icon}></span>}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)}>
              <span className={classes.checkmark}></span>
            </span>
          }
          {...rest}
        />
      }
      label={<span className={classes.label}>{label}</span>}
      classes={{ disabled: classes.labelDisabled }}
    />
  );
};

export default Checkbox;
