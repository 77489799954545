/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {
  CHART_DEFAULT_PAIR,
  CHART_PERIOD,
} from 'src/commons/constants/Constants';
import { tradingService } from 'src/services/TradingService';
import { marketService } from 'src/services/MarketService';
import { numberWithCommas } from 'src/utils/number';

import SelectCoin from 'src/components/Base/SelectCoin';
import useStyles from './styles';

interface ICoinInfo {
  logo: string | undefined;
  symbol: string | undefined;
  price: number | undefined;
  change: number | undefined;
  marketcap: number | undefined;
}

const PriceChart = (props: any) => {
  const classes = useStyles();
  const { allMarkets } = props;

  const [lineChart, setLineChart] = useState(Array<[]>());
  const [serial, setSerial] = useState(CHART_DEFAULT_PAIR.split('/')[0]);
  const [selected, setSelected] = useState({
    tokenLogo1: `/images/logos/${CHART_DEFAULT_PAIR.split(
      '/'
    )[0]?.toLowerCase()}.svg`,
    tokenLogo2: `/images/logos/${CHART_DEFAULT_PAIR.split(
      '/'
    )[1]?.toLowerCase()}.svg`,
    pair: CHART_DEFAULT_PAIR,
  });

  const [coin, setCoinInfo] = useState<ICoinInfo>();

  const options = {
    chart: {
      type: 'line',
      backgroundColor: null,
    },
    credits: {
      enabled: false,
    },
    title: { text: '' },
    legend: {
      enabled: false,
    },
    xAxis: {
      gridLineWidth: 0.5,
      gridLineColor: '#606983',
      tickWidth: 0,
      type: 'datetime',
      labels: {
        style: {
          color: '#9BA1B1',
          fontSize: '10px',
        },
        format: '{value:%b-%Y}',
      },
    },
    yAxis: {
      type: 'logarithmic',
      title: false,
      gridLineWidth: 0.5,
      gridLineColor: '#606983',
      allowDecimals: false,
      labels: {
        style: {
          color: '#9BA1B1',
          fontSize: '18px',
        },
      },
    },
    series: [
      {
        color: '#B8A7FF',
        name: serial,
        data: lineChart,
      },
    ],
  };

  useEffect(() => {
    getBar(selected.pair);
    getMarketsInfo(selected.pair);
  }, [selected]);

  const onChangeChart = async (pair: any) => {
    setSelected({
      tokenLogo1: `/images/logos/${pair.split('/')[0]?.toLowerCase()}.svg`,
      tokenLogo2: `/images/logos/${pair.split('/')[1]?.toLowerCase()}.svg`,
      pair,
    });
    await setSerial(pair.split('/')[0]);
    await getBar(pair);
    await getMarketsInfo(pair);
  };

  const getBar = async (pair: string) => {
    try {
      const resultData = await tradingService.getBar({
        symbol: pair,
        period: CHART_PERIOD.default,
      });
      if (resultData && resultData.data && resultData.data.data) {
        let data = Array<[]>();
        for await (const el of resultData.data.data) {
          let item: any = [];
          item.push(el.floor_time * 1000);
          item.push(el.high);
          data.push(item);
        }
        setLineChart(data);
      }
    } catch (error) {}
  };

  const getMarketsInfo = async (pair: string) => {
    const params = {
      vs_currency: 'usd',
      order: 'market_cap_desc',
      per_page: 100000,
      page: 1,
      sparkline: false,
    };

    const resData = await marketService.getMarketsInfo(params);
    if (resData && resData?.data) {
      const coinSymbol = pair.split('/')[0];
      const info = resData?.data.find(
        (x: any) => x.symbol.toLowerCase() === coinSymbol?.toLowerCase()
      );

      const anchorInfo: ICoinInfo = {
        logo: info?.image,
        symbol: info?.symbol?.toUpperCase(),
        price: info?.current_price,
        change: info?.price_change_percentage_24h,
        marketcap: info?.market_cap,
      };
      setCoinInfo(anchorInfo);
    }
  };

  return (
    <div className={classes.priceChart}>
      <div className={classes.header}>
        <span className={classes.title}>{selected.pair} rate</span>
        <SelectCoin
          options={allMarkets}
          value={selected}
          onChange={onChangeChart}
        />
      </div>

      <div className={classes.chartContent}>
        <div className={classes.coinInfo}>
          <div className={classes.coinInfoLeft}>
            <div className={classes.coinPair}>
              <img
                src={coin?.logo || undefined}
                className={classes.icon}
                alt=""
              ></img>
              <div className={classes.symbol}>
                <span className={classes.coinInfoTitle}>Symbol</span>
                <span className={classes.coinInfoValue}>{coin?.symbol}</span>
              </div>
            </div>
          </div>

          <div className={classes.coinInfoRight}>
            <div className={classes.price}>
              <span className={classes.coinInfoTitle}>Price usd</span>
              <span className={classes.coinInfoValue}>
                ${numberWithCommas(coin?.price)}
              </span>
            </div>

            <div className={classes.change}>
              <span className={classes.coinInfoTitle}>Change (24h)</span>
              <span className={classes.coinInfoValue}>{coin?.change}%</span>
            </div>

            <div className={classes.marketcap}>
              <span className={classes.coinInfoTitle}>Market cap</span>
              <span className={classes.coinInfoValue}>
                ${numberWithCommas(coin?.marketcap)}
              </span>
            </div>
          </div>
        </div>

        <div className={classes.chart}>
          <HighchartsReact
            containerProps={{ style: { height: '98%', width: '100%' } }}
            highcharts={Highcharts}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceChart;
