import Link from 'src/components/Base/Link';

import useStyles from './styles';

const HeaderLogo = (props: any) => {
  const style = useStyles();
  return (
    <>
      <div className={style.logo}>
        <Link href="/">
          <img src="/images/logo.png" alt="" />
        </Link>
      </div>
    </>
  );
};
export default HeaderLogo;
