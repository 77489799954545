import { AnyAction } from "redux";
import * as actionTypes from "../constants/customization";

const initialState = {
  isOpen: [], // for active default menu
  opened: true,
};

// ===========================|| CUSTOMIZATION REDUCER ||=========================== //

export const customizationReducer = (
  state = initialState,
  action: AnyAction
) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    default:
      return state;
  }
};
