import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    rowEmptydata: {
      background: theme.custom.colors.cardBackground,
      width: '100%',
      minHeight: '100%',
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
    },

    boxLoading: {
      background: theme.custom.colors.cardBackground,
      width: '100%',
      minHeight: '100%',
      borderRadius: 8,
    },

    iconEmptydata: {
      fontSize: 70,
      marginBottom: 30,

      '&:before': {
        color: '#7F63F4',
      },
    },

    textEmptydata: {
      fontSize: 24,
      lineHeight: '28px',
      color: theme.custom.colors.titleTrading,
      marginBottom: 30,
    },

    messEmptydata: {
      fontSize: 16,
      lineHeight: '20px',
      color: '#9BA1B1',
      marginBottom: 33,
      maxWidth: '100%',
      textAlign: 'center',
    },

    btnEmptydata: {
      fontFamily: 'Ubuntu-Bold',
      background: theme.custom.colors.btnEnable,
      borderRadius: 6,
      minHeight: 36,
      minWidth: 126,
      fontSize: 14,
      color: theme.custom.colors.btnTitle,
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',

      '&:hover': {
        background: theme.custom.colors.btnHover,
        boxShadow: 'none',
      },

      [theme.breakpoints.down('xs')]: {
        minHeight: 50,
      },
    },
  };
});

export default useStyles;
