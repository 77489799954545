import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import moment from 'moment';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Button, Hidden, useMediaQuery, useTheme } from '@material-ui/core';

import { AssetService } from 'src/services/AssetService';
import {
  TransactionRequestParams,
  TransactionService,
} from 'src/services/TransactionService';

import DownloadCSV from '../DownloadCSV';
import ModalCustom from 'src/components/Base/ModalCustom';
import Select from 'src/components/Base/Select';
import AppButton from 'src/components/Base/AppButton';
import DateRangePicker from 'src/components/Base/DateRangePicker';
import LoadingOverlay from '../LoadingOverlay';
import ModalSignUp from 'src/routing/TradingPage/ComponentTradingEntry/ModalSignUp';
import GridDataPagination from 'src/components/Base/GridDataPagination';

import assetsConfig from 'src/commons/configs/assetsConfig.json';
import { DEFAULT_LOGO } from 'src/commons/constants';
import { toCapitalized } from 'src/utils/common';
import { useAppSelector } from 'src/hooks';

import TableStyle from 'src/styles/TableStyle';
import useStyles from '../styles';

const transactionService = TransactionService.getInstance();
const assetService = AssetService.getInstance();

const maxPageSize = 100;
const maxDate = new Date();
const pageSize = 10;

const TransactionReport = (props: any) => {
  const styles = useStyles();
  const tableStyle = TableStyle();
  const [rowHeight] = useState(60);
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLogged = useAppSelector((state) => state.auth.isLogged);

  const [dateRange, setDateRange] = useState([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);
  const [startDate, endDate] = dateRange;

  const [currencies, setCurrencies] = useState<any[]>([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [transactionType, setTransactionType] = useState('');
  const transactionTypes = [
    {
      label: 'Deposit',
      value: 'DEPOSIT',
    },
    {
      label: 'Withdrawal',
      value: 'WITHDRAWAL',
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);

  const [transactions, setTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const CSVheader = useMemo(
    () => [
      { key: 'datetime', name: 'Time/date' },
      { key: 'transaction_id', name: 'Transaction ID' },
      { key: 'asset', name: 'Asset' },
      { key: 'amount', name: 'Amount' },
      // { key: 'transfer_type', name: 'Transfer type' },
      { key: 'transaction_type', name: 'Type' },
      { key: 'state', name: 'Status' },
    ],
    []
  );

  const history = useHistory();

  const hideFooter = rowCount <= 0;
  const footerHeight = hideFooter ? 16 : 62;

  const columns: GridColDef[] = [
    {
      field: 'datetime',
      headerName: 'Time/date',
      minWidth: matchUpSm ? 230 : 195,
      flex: 0.8,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <span className={styles.breakSpaces}>
          {params.value && moment(params.value as number).toLocaleString()}
        </span>
      ),
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction ID',
      minWidth: matchUpSm ? 250 : 205,
      flex: 1,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <span className={styles.breakSpaces}>{params.value}</span>
      ),
    },
    {
      field: 'asset',
      headerName: 'Asset',
      minWidth: matchUpSm ? 150 : 135,
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridValueGetterParams) =>
        params.value && (
          <div className={styles.symbol}>
            <img
              className={styles.iconCoin}
              src={
                (assetsConfig as any)[params.value as string]?.icon ||
                DEFAULT_LOGO
              }
              alt="asset icon"
            />
            <div className={styles.symbolInfo}>
              <strong>{params.value}</strong>
              <div className={styles.assetName}>
                {(assetsConfig as any)[params.value as string].name}
              </div>
            </div>
          </div>
        ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 140,
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'transaction_type',
      headerName: 'Type',
      minWidth: 120,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <span>{toCapitalized(params.value as string)}</span>
      ),
    },
    {
      field: 'state',
      headerName: 'Status',
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <div
          className={clsx(styles.status, {
            [styles.statusFilled]: params.value === 'COMPLETED',
            [styles.statusFilled]: params.value === 'PENDING APPROVAL',
            [styles.statusCancelled]:
              params.value === 'REJECTED' || params.value === 'EXPIRED',
          })}
        >
          {toCapitalized(params.value as string)}
        </div>
      ),
    },
  ];

  const getAssets = async () => {
    try {
      const { data } = await assetService.getAssets();
      setCurrencies(data.assets.map((el) => ({ value: el, label: el })));
    } catch (error) {}
  };

  const getTransactions = async (params?: TransactionRequestParams) => {
    try {
      setLoading(true);
      const { data } = await transactionService.get(params);
      setTransactions(
        data.transactions.map((item) => ({ ...item, id: item.transaction_id }))
      );
      setRowCount(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getDownloadData = async () => {
    const startDate = moment().subtract(90, 'days').startOf('day').unix();

    const {
      data: { transactions: firstPage, pages_total },
    } = await transactionService.get({
      page_size: maxPageSize,
      timestamp_end: startDate,
    });

    let otherPages = [];
    if (pages_total > 1) {
      const restResponse = await Promise.all(
        Array(pages_total - 1)
          .fill(1)
          .map((el, index) => {
            return transactionService.get({
              page: index + 2,
              page_size: maxPageSize,
              timestamp_end: startDate,
            });
          })
      );
      otherPages = restResponse.map((el) => el.data.transactions).flat();
    }

    return firstPage.concat(otherPages).map((el) => ({
      ...el,
      transaction_type: toCapitalized(el.transaction_type),
      state: toCapitalized(el.state),
      datetime: moment(el.datetime).toLocaleString(),
    }));
  };

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    const params: TransactionRequestParams = {
      page: currentPage + 1,
      page_size: pageSize,
    };
    selectedCurrencies && (params.asset = selectedCurrencies);
    transactionType && (params.transaction_type = transactionType);
    startDate &&
      (params.timestamp_end = moment(startDate).startOf('day').unix());
    endDate && (params.timestamp_start = moment(endDate).endOf('day').unix());

    getTransactions(params);
  }, [currentPage, transactionType, selectedCurrencies, startDate, endDate]);

  const renderFilters = (
    <>
      <Select
        canDeselect
        value={selectedCurrencies}
        options={isLogged ? currencies : []}
        className={styles.dropdownBoxReport}
        inputClassName={styles.selectInput}
        placeholder="All currencies"
        onChange={(value: any) => {
          setSelectedCurrencies(value);
          setCurrentPage(0);
        }}
      />
      <Select
        canDeselect
        value={transactionType}
        options={isLogged ? transactionTypes : []}
        className={styles.dropdownBoxReport}
        inputClassName={styles.selectInput}
        placeholder="All transactions"
        onChange={(value: string) => {
          setTransactionType(value);
          setCurrentPage(0);
        }}
      />
      <div className={styles.boxDatePicker}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate}
          placeholderText="Start date - End date"
          dateFormat="yyyy/MM/dd"
          onChange={(update: any) => {
            setDateRange(update);
            setCurrentPage(0);
          }}
        />
      </div>
    </>
  );

  return (
    <section className={styles.root}>
      <div className={styles.headPage}>
        <div className={styles.left}>
          <Hidden xsDown>{renderFilters}</Hidden>
          <Hidden smUp>
            <Button
              variant="contained"
              className={`${styles.btnFilters}`}
              onClick={() => setModalOpen(true)}
            >
              Filters
            </Button>
            <ModalCustom
              isOpen={modalOpen}
              isIconClose
              handleClose={() => setModalOpen(false)}
              className={styles.mobileFilter}
            >
              <div className={styles.modalTitle}>Filters</div>
              <div>{renderFilters}</div>
              <div className={styles.closeBtn}>
                <AppButton fullWidth onClick={() => setModalOpen(false)}>
                  OK
                </AppButton>
              </div>
            </ModalCustom>
          </Hidden>
        </div>
        <div className={styles.right}>
          <div className={`${styles.btnDownload}`}>
            <DownloadCSV
              header={CSVheader}
              fileName={`${moment().format('DD.MM.YYYY')}-transactions.csv`}
              getData={getDownloadData}
            />
          </div>
        </div>
      </div>

      <div
        className={tableStyle.customerDataGrid}
        style={{
          height: `${
            46 + Number(transactions.length) * rowHeight + footerHeight
          }px`,
          maxHeight: `${46 + 10 * rowHeight + footerHeight}px`,
          minHeight: `${
            46 + (transactions.length > 0 ? 1 : 6) * rowHeight + footerHeight
          }px`,
        }}
      >
        <DataGrid
          loading={loading}
          rows={transactions}
          columns={columns}
          page={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={46}
          rowHeight={rowHeight}
          hideFooter={hideFooter}
          pagination
          paginationMode="server"
          onPageChange={(page) => setCurrentPage(page)}
          components={{
            Pagination: GridDataPagination,
            NoRowsOverlay: () => (
              <div className={tableStyle.rowEmptydata}>
                <span
                  className={`icon-no-data ${tableStyle.iconEmptydata}`}
                ></span>
                <div className={tableStyle.textEmptydata}>No data</div>
                <div className={tableStyle.messEmptydata}>
                  You will be able to see reports after <br /> completing
                  transactions
                </div>
                <Button
                  variant="contained"
                  className={`${tableStyle.btnEmptydata}`}
                  onClick={() => {
                    isLogged ? history.push('/trading') : setSignUpModal(true);
                  }}
                >
                  {isLogged ? 'Start trading' : 'Sign Up'}
                </Button>
              </div>
            ),
            LoadingOverlay: LoadingOverlay,
          }}
        />
      </div>
      <ModalSignUp
        isOpen={signUpModal}
        handleClose={() => setSignUpModal(false)}
      />
    </section>
  );
};

export default TransactionReport;
