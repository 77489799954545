import React from 'react';
import TableStyle from 'src/styles/TableStyle';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Pagination as MuiPagination, PaginationItem } from '@material-ui/lab';
import clsx from 'clsx';
import useStyles from './styles';

interface PaginationProps {
  page: number;
  count: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const Pagination = (props: PaginationProps) => {
  const { page, count, onChange } = props;
  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const tableStyle = TableStyle();
  const classes = useStyles();

  return (
    <MuiPagination
      page={page}
      count={count}
      showFirstButton={!matchDownXs}
      showLastButton={!matchDownXs}
      variant="outlined"
      shape="rounded"
      siblingCount={0}
      boundaryCount={1}
      onChange={onChange}
      renderItem={(props) => {
        if (matchDownXs) {
          if (props.type === 'previous' || props.type === 'next') {
            return <PaginationItem {...props} />;
          }
          if (props.page === page) {
            return <div>{`Page ${page} from ${count}`}</div>;
          }
          return;
        }
        return <PaginationItem {...props} />;
      }}
      className={clsx(tableStyle.paginationTable, {
        [classes.xsPagination]: matchDownXs,
      })}
    />
  );
};
export default Pagination;
