import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import clsx from 'clsx';

import { changeThemeMode } from 'src/store/actions/theme-mode';
import ProfileSection from '../Header/ProfileSection';
import Deposit from 'src/components/Deposit';
import Link from 'src/components/Base/Link';
import MenuCustom from './../MenuCustom';
import { AuthenticationAction } from 'src/store/actions/authentication';
import { toLogin } from 'src/utils/common';
import { LoadingAction } from 'src/store/actions/loading';

import useStyles from './styles';

const listMenuSetting = [
  {
    id: 1,
    label: 'KYC',
    pathname: '/setting/kyc',
  },
  {
    id: 2,
    label: 'API',
    pathname: '/setting/api',
  },
  {
    id: 3,
    label: 'Security',
    pathname: '/setting/security',
  },
  {
    id: 4,
    label: 'Address book',
    pathname: '/setting/address-book',
  },
];
const listMenuDef = [
  {
    id: 1,
    name: 'Home',
    pathname: '/trade',
    icon: 'icon-home',
  },
  {
    id: 2,
    name: 'Trading',
    pathname: '/trading',
    icon: 'icon-trading',
  },
  {
    id: 3,
    name: 'Wallet',
    pathname: '/wallet',
    icon: 'icon-wallet',
  },
  {
    id: 4,
    name: 'Reports',
    pathname: '/reports',
    icon: 'icon-reports',
  },
  {
    id: 5,
    name: 'Support',
    pathname: '/support',
    icon: 'icon-support',
  },
];

const HeaderTrading = (props: any) => {
  const { drawerOpen, drawerToggle } = props;
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [listMenu] = useState(listMenuDef);
  const [currentActiveMenuItem, setCurrentActiveMenuItem] = useState('/');
  const [themeMode, setThemeMode] = useState(
    useSelector((state: any) => state.themeMode.status)
  );
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorProfile, setAnchorProfile] = React.useState<null | HTMLElement>(
    null
  );

  const listMenuProfile = [
    isLogged
      ? {
        id: 3,
        label: 'Log out',
        func: async () => {
          dispatch(LoadingAction.show());
          await dispatch(AuthenticationAction.logout());
          toLogin({
            logout: true,
          });
        },
      }
      : {
        id: 2,
        label: 'Sign in',
        func: () => toLogin(),
      },
  ];

  const open = Boolean(anchorEl);
  const openUser = Boolean(anchorProfile);
  const onSetModePage = () => {
    setThemeMode(!themeMode);
    localStorage.setItem('themeMode', themeMode ? 'dark' : 'light');
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  useEffect(() => {
    dispatch(changeThemeMode(themeMode));
  }, [dispatch, themeMode]);

  useEffect(() => {
    const status = localStorage.getItem('themeMode') || true;
    setThemeMode(status === 'dark' ? false : true);
  }, []);

  const setCurrentActiveMenu = (pathname: string) => {
    switch (pathname) {
      case '/':
        setCurrentActiveMenuItem('/');
        break;
      case '/trading':
        setCurrentActiveMenuItem('/trading');
        break;
      case '/reports':
        setCurrentActiveMenuItem('/reports');
        break;
      case '/wallet':
        setCurrentActiveMenuItem('/wallet');
        break;
      case '/support':
        setCurrentActiveMenuItem('/support');
        break;
      default:
        setCurrentActiveMenuItem(pathname);
    }
  };

  const handleSetting = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorProfile(null);
  };

  useEffect(() => {
    setCurrentActiveMenu(history.location.pathname);
  }, [history.location.pathname]);

  const setActiveMenu = (pathName: string, currentActive: string) => {
    const isTrading = pathName === "/trading" && currentActive.includes(pathName);
    if (isTrading) {
      return true;
    }
    return pathName === currentActive;
  }

  return (
    <AppBar className={clsx(classes.appBar)}>
      <Toolbar className={classes.toolbarHeader}>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={drawerToggle}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
          >
            <span className={`${classes.menuIcon} icon-toggle-nav`}></span>
          </IconButton>

          <Typography variant="h6" className={classes.titlePage}>
            <Link href="/" className={classes.logoMobile}>
              <img src="/images/logo-big.png" alt="" />
            </Link>
          </Typography>

          {drawerOpen ? (
            <Button
              variant="contained"
              onClick={drawerToggle}
              className={classes.btnToggleMode}
            >
              <span className="icon-close2"></span>
            </Button>
          ) : (
            <Button variant="contained" className={classes.btnNotification}>
              <span className="icon-notification"></span>
            </Button>
          )}
        </Hidden>

        <Hidden smDown>
          <div className={classes.boxLogoTrading}>
            <Link href="/" className={classes.logoTrading}>
              <Hidden lgUp>
                <img src="/images/logo-small.png" alt="" />
              </Hidden>
              <Hidden only="md">
                <img src="/images/logo-big.png" alt="" />
              </Hidden>
            </Link>
          </div>

          <List className={classes.listMenuTrading}>
            {listMenu?.map((item) => (
              <ListItem key={item.id} className={classes.ListItemMenu}>
                <Link
                  href={`${item.pathname}`}
                  className={`${classes.itemMenuTrading} ${setActiveMenu(item.pathname, currentActiveMenuItem) && classes.itemMenuTradingActive}`}
                >
                  <span
                    className={`${item.icon} ${classes.iconMenuTrading} ${setActiveMenu(item.pathname, currentActiveMenuItem) && classes.iconMenuTradingActive}`}
                  ></span>
                  {item.name}
                </Link>
              </ListItem>
            ))}
          </List>

          <div className={classes.headerMain}>
            <Button
              variant="contained"
              className={classes.btnDeposit}
              onClick={() => setIsOpenDeposit(true)}
              disabled={!isLogged}
            >
              Deposit
            </Button>
            {isOpenDeposit && (
              <Deposit
                isOpen={isOpenDeposit}
                handleClose={() => setIsOpenDeposit(false)}
              />
            )}
            <div className={classes.rectangle}></div>
            <Button
              variant="contained"
              onClick={() => onSetModePage()}
              className={classes.btnToggleMode}
            >
              <span className="icon-mode"></span>
            </Button>
            <Button variant="contained" className={classes.btnNotification}>
              <span className="icon-notification"></span>
            </Button>
            <Button
              variant="contained"
              className={classes.btnSettings}
              onClick={handleSetting}
              aria-controls="fade-menu"
              disabled={!isLogged}
            >
              <span className="icon-settings"></span>
            </Button>
            <MenuCustom
              id="fade-menu"
              listItem={listMenuSetting}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              className={classes.menuSetting}
            />
            <ProfileSection handleProfile={handleProfile} />

            <MenuCustom
              id="fade-menu-user"
              listItem={listMenuProfile}
              open={openUser}
              anchorEl={anchorProfile}
              onClose={handleClose}
              className={classes.menuProfile}
            />
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderTrading;
