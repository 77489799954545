import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { ZIndex } from '@material-ui/core/styles/zIndex';

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    negative: number;
  }
}

declare module '@material-ui/core/styles' {
  interface Theme {
    zIndex: ZIndex;
  }

  interface ThemeOptions {
    custom?: any;
  }
}

export const createLemonadeTheme = (options: ThemeOptions = {}) => {
  return createTheme(options);
};

const themeOptions = {
  colors: {
    primary: '#FFCC00',
    secondary: '#C7C9D0',
    primaryText: '#636363',
    metamask: '#FF8F44',
    mainBackground: '#F2F6F9',
    cardBackground: '#FFFFFF',
    purple1: 'rgba(127, 99, 244, 1)',
    purple2: '#563BCA',
    purple3: 'rgba(96, 105, 131, 0.3)',
    grey1: 'rgba(56, 68, 100, 1)',
    grey2: '#8C8C8C',
    grey3: '#E3E3E3',
    blueGray: 'rgba(247, 247, 247, 1)',
    red1: 'rgba(222, 73, 73, 1)',
    red2: '#DE4949',
    white1: '#FFFFFF',
    white2: '#606983',
    white3: '#384464',
    white4: '#606983',
    white5: '#384464',
    comet: '#EEEEEE',
    comet2: '#EBECF0',
    comet3: '#9BA1B1',
    comet4: '#C7C9D0',
    comet5: '#373C4E',
    cornflowerBlue: '#7F63F4',
    hoverBtn: '#563BCA',
    brightGray: '#EBECF0',
    santaGray: '#384464',
    santaGray2: '#9BA1B1',
    lightPurple: '#B8A7FF',
    boldGray: '#384464',
    lightBlue: '#838FB0',
    lightPurple2: '#7F63F4',
    titleTrading: '#384464',
    bgRowLabel: '#EBECF0',
    bgBluGrey: '#838FB0',
    bgTrading: '#838FB0',
    lineTable1: '#C7C9D0',
    clCanceled: '#DE4949',
    btnTradingWatch: '#838FB0',
    borderTradingWatch: '#606983',
    borderQRCode: '#EBECF0',
    conrGrayblue: '#3E455F',
    green1: '#4CA250',
    secondary1: '#C7C9D0',
    santaGray1: '#EBECF0',
    grey4: '#EBECF0',
    bgPagination: '#FFFFFF',
    bgBtn: '#838FB0',
    borderBtnPage: '#384464',
    colorText: '#606983',
    colorLink: '#7F63F4',
    bgrButton: '#FFFFFF',
    colorWarning: '#606983',
    btnCancle: '#838FB0',
    btnCancel: '#838FB0',
    descColor: '#606983',
    borderCancel: '#384464',
    colorBorder: '#384464',
    bgrOverLay: 'rgba(36, 39, 49, 0.55)',
    statusConfirmed: '#4CA250',
    statusCancelled: '#DE4949',
    statusFilled: '#7F63F4',
    bgrSelect: '#FFFFFF',
    bgr: '#fff',
    themeCards: '#FFFFFF',
    colorStep: '#7F63F4',
    colorPlace: '#606983',
    bgDisable: 'rgba(96,105,131,0.3)',
    btnEnable: '#7F63F4',
    btnHover: '#563BCA',
    btnTitle: '#FFFFFF',
    fieldTitle: '#606983',
    textContentCard: '#9BA1B1',
    checkBoxColor: '#606983',
    bgrCheck: '#ffffff',
    colorDes: '#606983',
    tabActive: '#7F63F4',

    bgrItemDropdown: '#F2F6F9',
    activeTab: '#7F63F4',
    gray4: '#384464',
    titleConfirmCode: '#384464',
    btnSell: '#C7C9D0',
    colorStatus: '#4CA250',
    btnDisable: '#60698330',
    bgrBtnDisable: '#6069834D',
  },
};

const themeLightMode = createLemonadeTheme({
  typography: {
    fontFamily: 'Ubuntu-Medium',
  },
  custom: themeOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export { themeOptions };
export default themeLightMode;
