/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';

import * as AccountActions from 'src/store/actions/account';
import { SecurityService } from 'src/services/SecurityService';
import { AddressBookAction } from 'src/store/actions/address';

import { showModalMessage } from 'src/store/actions/message';
import ComponentResetPassword from 'src/routing/SecurityPage/ComponentResetPassword';
import ModalConfirmCode from 'src/routing/SecurityPage/SecurityElement/ModalConfirmCode';

// import Loading from 'src/components/Base/Loading';
import useStyles from './styles';

function SecurityCard(props: any): JSX.Element {
  const { title, icon, content, btnReset, btnViewList, btnAdd } = props;
  const dispatch = useDispatch();
  const accountState = useSelector((state: any) => {
    return state.accountReducer;
  });

  const style = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsOpenModalWhiteList] = useState(false);
  const [isOpenResetPopup, setIsOpenResetPopup] = useState(false);
  const [isOpenModal2FA, setIsOpenModal2FA] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const api = SecurityService.getInstance('');
  const history = useHistory();
  const fetchProfileData = async () => {
    await dispatch(AccountActions.has2FADevices());
  };

  useEffect(() => {
    fetchProfileData();
  }, [isOpenModal2FA]);

  const handleClose = (type: any) => {
    if (type === 1) setIsOpenResetPopup(false);
    if (type === 2) setIsOpenModal2FA(false);
    if (type === 3) setIsOpenModalWhiteList(false);
  };

  const handleButtonActive = async () => {
    if (accountState.has2FADevices) {
      setIsOpenModal2FA(true);
      return;
    }

    try {
      setIsLoading(true);
      const res = await api.active2FADevices();
      setIsLoading(false);
      if (res) setIsOpenModal2FA(true);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        showModalMessage({
          type: 'ERROR',
          msg: 'Token is invalid or expired',
        })
      );
    }
  };

  const handleConfirmCode2FA = async (pinCode: any) => {
    try {
      setIsLoading(true);
      const resultData = await api.remove2FADevices({ '2fa-code': pinCode });
      setIsLoading(false);
      if (resultData && resultData?.data?.result) {
        await fetchProfileData();
        setIsOpenModal2FA(false);
        return;
      }
    } catch (error) {
      setIsLoading(false);
    }
    setErrorCode(true);
  };

  const handleConfirmEmail = async (pinCode: any) => {
    try {
      setIsLoading(true);
      const resultData = await api.confirmCodeFromEmail({
        task: 'setup',
        code: pinCode,
      });
      setIsLoading(false);
      if (resultData?.data && resultData?.data !== true) {
        history.push({
          pathname: '/2FA',
          state: { qrCode: resultData?.data },
        });
        return;
      }
    } catch (error) { }
    setIsLoading(false);
    setErrorCode(true);
  };

  const onSubmit = async (pinCode: any) => {
    if (accountState.has2FADevices) {
      handleConfirmCode2FA(pinCode);
    } else {
      handleConfirmEmail(pinCode);
    }
  };

  return (
    <>
      {/* {isLoading && <Loading />} */}
      <div className={style.rootSecurity}>
        <div className={style.paper}>
          <div className={style.body}>
            <span className={`${style.icon} ${icon}`}></span>
            <div>
              <Typography className={`${style.title}`}>{title}</Typography>
              <Typography className={`${style.content}`}>{content}</Typography>
              <div className={style.btn}>
                {!btnViewList ? (
                  btnReset ? (
                    <Button
                      variant="contained"
                      className={`${style.btnContent}`}
                      onClick={() => setIsOpenResetPopup(true)}
                    >
                      {btnReset}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={`${style.btnContent}`}
                      onClick={handleButtonActive}
                    >
                      {accountState.has2FADevices ? 'DeActivate' : 'Activate'}
                    </Button>
                  )
                ) : null}
                {btnViewList ? (
                  <>
                    <Button
                      variant="contained"
                      className={`${style.btnContent} ${style.btnViewList}`}
                      onClick={() => { history.push('/setting/address-book'); }}
                    >
                      {btnViewList}
                    </Button>

                    <Button
                      variant="contained"
                      className={`${style.btnContent} ${style.btnAdd}`}
                      onClick={() => {
                        dispatch(
                          AddressBookAction.toggleModalAdd(
                            {
                              isWhitelist: true,
                            },
                            {
                              isWithdrawal: false,
                            }
                          )
                        );
                      }}
                    >
                      {btnAdd}
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ComponentResetPassword
        isOpenResetPopup={isOpenResetPopup}
        handleClose={() => handleClose(1)}
      />
      <ModalConfirmCode
        isOpen={isOpenModal2FA}
        handleClose={() => handleClose(2)}
        onSubmit={onSubmit}
        errorCode={errorCode}
        setErrorCode={setErrorCode}
        subTitle={
          accountState.has2FADevices
            ? 'Please enter two factor authentication code'
            : 'Enter confirmation code from email'
        }
        errorMessage={
          accountState.has2FADevices
            ? 'The 2FA code is incorrect. Please check and try again.'
            : 'The email code is incorrect. Please check and try again.'
        }
        isLoading={isLoading}
      />
    </>
  );
}

export default withRouter(SecurityCard);
