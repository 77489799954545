import { useState } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import AppButton from 'src/components/Base/AppButton';
import AppInput from 'src/components/Base/AppInput';
import Radio from 'src/components/Base/Radio';
import { AccountService } from 'src/services/AccountService';
import { getBanks } from 'src/store/actions/account';
// import { LoadingAction } from 'src/store/actions/loading';
import { hideModalMessage, showModalMessage } from 'src/store/actions/message';
import { ModalMessageType } from 'src/store/reducers/message';

import useStyles from './style';

interface IForm {
  account_number: string;
  account_name: string;
  routing_number: string;
  check_type: any; //'BUSINESS' | 'PERSONAL';
  account_type: any; //'CHECKING' | 'SAVING';
}

export default function BankUSA({
  type,
  backToWithdrawBank,
}: {
  type: string;
  backToWithdrawBank: Function;
}) {
  const dispatch = useDispatch();
  const assetSelected = useSelector((state: any) => state.asset.assetSelected);
  const checkType: { [key: string]: string } = {
    BUSINESS: 'Business',
    PERSONAL: 'Personal',
  };
  const accountType: { [key: string]: string } = {
    CHECKING: 'Checking',
    SAVING: 'Saving',
  };
  const styles = useStyles();
  const {
    control,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      // account_number: 'account number',
      // account_name: 'Name bank',
      // routing_number: 'routing',
      check_type: 'BUSINESS',
      account_type: 'CHECKING',
    },
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (formData: IForm) => {
    // Loading
    // dispatch(LoadingAction.show());
    setLoading(true);
    const data = {
      bank_type: type,
      asset: assetSelected,
      bank_data: formData,
    };
    const accountService = AccountService.getInstance('');

    let message: ModalMessageType = {
      type: 'ERROR',
      msg: 'Server error',
    };

    try {
      const res = await accountService.createBank(data);
      if (res?.data?.accounts) {
        message = {
          type: 'SUCCESS',
          msg: 'Add bank account success',
          onOk: () => {
            // Fetch list bank
            dispatch(getBanks());
            // Hide message
            dispatch(hideModalMessage());
            // Back to withdraw
            backToWithdrawBank();
          },
        };
      }
    } catch (err) {
    } finally {
      dispatch(showModalMessage(message));
      // dispatch(LoadingAction.hide());
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'account_name',
            rules: {
              required: true,
            },
          }}
          label={'Name of Bank Account'}
          error={Boolean(errors.account_name)}
          placeholder="Name of Bank Account"
          helperText={errors.account_name?.message}
          labelProps={{
            className: errors.account_name ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          inputNumber={true}
          fullWidth={true}
          controller={{
            control,
            name: 'account_number',
            rules: {
              required: true,
            },
          }}
          label={'Account Number'}
          error={Boolean(errors.account_number)}
          placeholder="Account Number"
          helperText={errors.account_number?.message}
          labelProps={{
            className: errors.account_number ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          inputNumber={true}
          fullWidth={true}
          controller={{
            control,
            name: 'routing_number',
            rules: {
              required: true,
            },
          }}
          label={'Routing number'}
          error={Boolean(errors.routing_number)}
          placeholder="Routing number"
          helperText={errors.routing_number?.message}
          labelProps={{
            className: errors.routing_number ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.rowTitle}>Account Type</div>
        {Object.keys(checkType).map((key: string) => {
          return (
            <div className={styles.radioBlock}>
              <Radio
                name={'check_type'}
                className={styles.styleRadio}
                checked={getValues('check_type') === key}
                label={checkType[key]}
                onChange={() =>
                  setValue('check_type', key, {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          );
        })}
      </div>
      <div className={styles.row}>
        <div className={styles.rowTitle}>Title</div>
        {Object.keys(accountType).map((key: string) => {
          return (
            <div className={styles.radioBlock}>
              <Radio
                name={'account_type'}
                className={styles.styleRadio}
                checked={getValues('account_type') === key}
                label={accountType[key]}
                onChange={() =>
                  setValue('account_type', key, {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          );
        })}
      </div>
      <Box className={clsx(styles.row, styles.rowBtn)} display="flex">
        <AppButton
          mode="secondary"
          height={'36px'}
          width={'126px'}
          onClick={() => backToWithdrawBank()}
        >
          Cancel
        </AppButton>
        <AppButton
          type="submit"
          height={'36px'}
          width={'126px'}
          isDisable={!isValid || !isDirty}
          isLoading={isLoading}
        >
          Save
        </AppButton>
      </Box>
    </form>
  );
}
