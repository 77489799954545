import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: any) => {
  return {
    styleChart: {
      fontFamily: 'Ubuntu-Regular',

      '& svg': {
        fontFamily: 'Ubuntu-Regular !important',
        maxWidth: '100%',
        width: '100% !important',
      },

      '& .highcharts-container': {
        maxWidth: '100%',
        width: '100% !important',
      },

    },

  }
});
export default useStyles;