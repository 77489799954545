import { useState } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import AppButton from 'src/components/Base/AppButton';
import AppInput from 'src/components/Base/AppInput';
import { AccountService } from 'src/services/AccountService';
import { getBanks } from 'src/store/actions/account';
// import { LoadingAction } from 'src/store/actions/loading';
import { hideModalMessage, showModalMessage } from 'src/store/actions/message';
import { ModalMessageType } from 'src/store/reducers/message';

import useStyles from './style';

interface IForm {
  account_number: string;
  account_name: string;
  bank_name: string;
  branch_code: string;
  bank_code: string;
  swift_code: string;
}

export default function BankDomestic({
  type,
  backToWithdrawBank,
}: {
  type: string;
  backToWithdrawBank: Function;
}) {
  const dispatch = useDispatch();
  const assetSelected = useSelector((state: any) => state.asset.assetSelected);
  const styles = useStyles();
  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setValue,
  } = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      // account_name: 'Name on Bank Account',
      // bank_name: 'Bank Name',
      // branch_code: 'Branch Code',
      // bank_code: 'Bank Code',
      // account_number: 'Account Number',
      // swift_code: 'SWIFT / BIC',
    },
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (formData: IForm) => {
    // Loading
    // dispatch(LoadingAction.show());
    setLoading(true);

    const data = {
      bank_type: type,
      asset: assetSelected,
      bank_data: formData,
    };
    const accountService = AccountService.getInstance('');

    let message: ModalMessageType = {
      type: 'ERROR',
      msg: 'Server error',
    };

    try {
      const res = await accountService.createBank(data);
      if (res?.data?.accounts) {
        message = {
          type: 'SUCCESS',
          msg: 'Add bank account success',
          onOk: () => {
            // Fetch list bank
            dispatch(getBanks());
            // Hide message
            dispatch(hideModalMessage());
            // Back to withdraw
            backToWithdrawBank();
          },
        };
      }
    } catch (err) {
    } finally {
      // Hide message & loading
      // dispatch(LoadingAction.hide());
      setLoading(false);
      dispatch(showModalMessage(message));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'account_name',
            rules: {
              required: true,
            },
          }}
          label="Name on Bank Account"
          error={Boolean(errors.account_name)}
          helperText={errors.account_name?.message}
          labelProps={{
            className: errors.account_name ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'bank_name',
            rules: {
              required: true,
            },
          }}
          label="Bank Name"
          error={Boolean(errors.bank_name)}
          helperText={errors.bank_name?.message}
          labelProps={{
            className: errors.bank_name ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          inputNumber={true}
          controller={{
            control,
            name: 'branch_code',
            rules: {
              required: true,
            },
          }}
          label="Branch Code"
          error={Boolean(errors.branch_code)}
          helperText={errors.branch_code?.message}
          labelProps={{
            className: errors.branch_code ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          inputNumber={true}
          fullWidth={true}
          controller={{
            control,
            name: 'account_number',
            rules: {
              required: true,
            },
          }}
          label="Account Number"
          error={Boolean(errors.account_number)}
          helperText={errors.account_number?.message}
          labelProps={{
            className: errors.account_number ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'swift_code',
            rules: {
              required: true,
            },
          }}
          onChange={(e) => {
            setValue('swift_code', e.target.value.toUpperCase(), {
              shouldValidate: true,
            });
          }}
          label="SWIFT / BIC"
          error={Boolean(errors.swift_code)}
          helperText={errors.swift_code?.message}
          labelProps={{
            className: errors.swift_code ? styles.styleLabel : '',
          }}
        />
      </div>
      <Box className={clsx(styles.row, styles.rowBtn)} display="flex">
        <AppButton height={'36px'} onClick={() => backToWithdrawBank()}>
          Back
        </AppButton>
        <AppButton
          type="submit"
          height={'36px'}
          isDisable={!isValid || !isDirty}
          isLoading={isLoading}
        >
          Submit
        </AppButton>
      </Box>
    </form>
  );
}
