import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainTeam: {
      padding: '45px 0 100px',
      fontFamily: 'Titillium Web',
    },

    mainContainer: {
      padding: '0px',

      [theme.breakpoints.down('lg')]: {
        padding: '0px 24px',
      },
    },

    titleTeam: {
      fontWeight: 700,
      fontSize: 48,
      fontFamily: 'Titillium Web',
      color: theme.custom.colors.white,

      [theme.breakpoints.between('1440', '1900')]: {
        paddingBottom: 30,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 32,
      },
    },

    teamContent: {
      display: 'grid',
      gridTemplateRows: 'repeat(1, minmax(0,1fr))',
      gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
      gridGap: '50px 50px',
      paddingTop: 40,

      [theme.breakpoints.down('lg')]: {
        gridGap: '25px 25px',
      },

      [theme.breakpoints.down('sm')]: {
        gridTemplateRows: 'repeat(1, minmax(0,1fr))',
        gridTemplateColumns: 'repeat(1, minmax(0,1fr))',
        gridGap: '50px 50px',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    groupTeam: {
      background: theme.custom.colors.bgrColor,
      boxShadow: '0px 4px 50px rgba(127, 99, 244, 0.15)',
      borderRadius: '10px',
      padding: '30px 26px',

      [theme.breakpoints.down('xs')]: {
        padding: '15px',
      },
    },

    descTeam: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    nameTeam: {},

    nameMember: {
      fontWeight: 700,
      fontSize: 32,
      fontFamily: 'Titillium Web',
      color: theme.custom.colors.textdesc,

      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    textOffice: {
      color: theme.custom.colors.textdesc,
      fontWeight: 400,
      fontSize: 20,
      margin: '5px 0',
      fontFamily: 'Titillium Web',

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    styleIn: {
      height: 42,
      marginTop: 2,

      [theme.breakpoints.down('xs')]: {
        width: 36,
        height: 36,
      },
    },

    detailMember: {
      display: 'flex',
      gridGap: 25,
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        '& img': {
          width: 112,
        },
      },

      [theme.breakpoints.down('xs')]: {
        gridGap: 10,

        '& img': {
          width: 65,
        },
      },
    },

    styleDescription: {
      fontSize: '18px',
      lineHeight: '30px',
      color: theme.custom.colors.textdesc,
      marginTop: 25,
      fontWeight: 400,

      [theme.breakpoints.down('lg')]: {
        minHeight: 150,
      },

      [theme.breakpoints.up('lg')]: {
        minHeight: 0,
      },

      [theme.breakpoints.down('sm')]: {
        minHeight: 0,
      },

      [theme.breakpoints.down('xs')]: {},
    },

    styleButton: {
      color: theme.custom.colors.colorRead,
      fontSize: '18px',
      lineHeight: '27px',
      fontWeight: 700,
      textTransform: 'none',
      cursor: 'pointer',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'none',
      },
    },
    styleButton1: {},

    linkDetail: {
      marginTop: 16,
    },

    styleTestDesc: {
      transition: '5s',
    },

    teamContentMobile: {
      display: 'grid',
      gridTemplateRows: 'repeat(1, minmax(0,1fr))',
      gridTemplateColumns: 'repeat(1, minmax(0,1fr))',
      gridGap: '25px',
      paddingTop: 40,
    },
  };
});

export default useStyles;
