import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import Link from 'src/components/Base/Link';

import useStyles from './styles';
interface MenuProps {
  id: string;
  listItem: Array<{ link: string; label: string }>;
  className: string;
  anchorEl: any;
  onClose: any;
  open: any;
}
const MenuCustom: React.FC<any> = (props: MenuProps) => {
  const { listItem, id, anchorEl, open, onClose, className } = props;
  const style = useStyles();
  const history = useHistory();
  const [currentActiveMenuItem, setCurrentActiveMenuItem] = useState('/');
  const setCurrentActiveMenu = (pathname: string) => {
    switch (pathname) {
      case '/setting/kyc':
        setCurrentActiveMenuItem('setting/kyc');
        break;
      case '/setting/api':
        setCurrentActiveMenuItem('/setting/api');
        break;
      case '/setting/security':
        setCurrentActiveMenuItem('/setting/security');
        break;
      case '/setting/address-book':
        setCurrentActiveMenuItem('/setting/address-book');
        break;
      default:
        setCurrentActiveMenuItem(pathname);
    }
  };

  useEffect(() => {
    setCurrentActiveMenu(history.location.pathname);
  }, [history.location.pathname]);
  return (
    <div className={style.main}>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={onClose}
        className={`${className} ${style.menu}`}
        classes={{ paper: style.menuPaper }}
      >
        {listItem?.map((menuItem: any) => {
          const obj = menuItem.func
            ? {
                onClick: menuItem.func,
              }
            : {
                href: `${menuItem.pathname}`,
              };
          return (
            <Link
              {...obj}
              key={menuItem.id}
              className={`${style.link} ${
                currentActiveMenuItem === menuItem.pathname && style.linkActive
              }`}
            >
              <MenuItem
                className={`${style.menuItem} ${
                  currentActiveMenuItem === menuItem.pathname &&
                  style.linkActive
                }`}
              >
                {menuItem.label}
              </MenuItem>
            </Link>
          );
        })}
      </Menu>
    </div>
  );
};
export default MenuCustom;
