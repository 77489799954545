/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { displayNumb } from 'src/utils/number';
import { TradingService } from 'src/services/TradingService';

import AppBox from 'src/components/Base/AppBox';
import { capitalizeFirstLetter } from 'src/utils/common';
import LoadingOverlay from 'src/routing/ReportsPage/LoadingOverlay';
import GridDataPagination from 'src/components/Base/GridDataPagination';
import { OrderRequestParams } from 'src/services/TradingService';

import TableStyle from 'src/styles/TableStyle';
import useStyles from './styles';

const ComponentRecentOrders = (props: any) => {
  const styles = useStyles();
  const tableStyle = TableStyle();
  const tradingService = new TradingService();
  const [dataRows, setRows] = useState<any[]>([]);
  const [orderPage, setOrderPage] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const [loading, setLoading] = useState(isLogged);
  const theme = useTheme();
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const columns: GridColDef[] = [
    {
      field: 'updated_at',
      headerName: 'Time',
      flex: matchUpLg ? 1 : 0,
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <span className={styles.breakSpaces}>
          {params.value &&
            moment((params.value as number) * 1000).toLocaleString()}
        </span>
      ),
    },
    {
      field: 'symbol',
      headerName: 'Symbol',
      width: 140,
      flex: matchUpLg ? 0.45 : 0,
      editable: false,
      sortable: false,
    },
    {
      field: 'side',
      headerName: 'Side',
      sortable: false,
      width: 107,
      flex: matchUpLg ? 0.45 : 0,
      renderCell: (params: GridValueGetterParams) => (
        <>{params.getValue(params.id, 'side') || ''}</>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      sortable: false,
      width: 140,
      flex: matchUpLg ? 0.45 : 0,
      renderCell: (params: GridValueGetterParams) => (
        <>
          {displayNumb(params.getValue(params.id, 'price')?.toString() || '')}
        </>
      ),
    },
    {
      field: 'cost',
      headerName: 'Cost',
      sortable: false,
      width: 145,
      flex: matchUpLg ? 0.45 : 0,
      renderCell: (params: GridValueGetterParams) => {
        const value = displayNumb(
          params.getValue(params.id, 'cost')?.toString() || ''
        );
        let symbol = params.getValue(params.id, 'symbol') as String;
        let side = params.getValue(params.id, 'side') as String;
        if (value) {
          let coin =
            side === 'buy' ? symbol?.split('/')[1] : symbol?.split('/')[0];
          return `${value} ${coin}`;
        }
        return '';
      },
    },
    {
      field: 'proceeds',
      headerName: 'Proceeds',
      sortable: false,
      width: 130,
      flex: matchUpLg ? 0.45 : 0,
      renderCell: (params: GridValueGetterParams) => {
        const value = displayNumb(
          params.getValue(params.id, 'proceeds')?.toString() || ''
        );
        let symbol = params.getValue(params.id, 'symbol') as String;
        let side = params.getValue(params.id, 'side') as String;
        if (value) {
          let coin =
            side === 'buy' ? symbol?.split('/')[0] : symbol?.split('/')[1];
          return `${value} ${coin}`;
        }
        return '';
      },
    },
    {
      field: 'state',
      headerName: 'Status',
      sortable: false,
      width: 130,
      flex: matchUpLg ? 0.45 : 0,
      renderCell: (params: GridValueGetterParams) => {
        <>{params.getValue(params.id, 'state') || ''}</>;
        let state = (params.getValue(params.id, 'state') || '') as string;
        const className =
          state === 'CONFIRMED' ? styles.btnConfirm : styles.btnCanceled;
        state = capitalizeFirstLetter(state) as string;
        return <>{<span className={className}>{state}</span>}</>;
      },
    },
  ];

  useEffect(() => {
    if (isLogged) {
      getOrders({ page: orderPage + 1, page_size: 5 });
    }
  }, [isLogged]);

  useEffect(() => {
    getOrders({ page: orderPage + 1, page_size: 5 });
  }, [orderPage]);

  useEffect(() => {
    if (props.refresh) {
      getOrders({ page: orderPage + 1, page_size: 5 });
    }
  }, [props.refresh]);

  const getOrders = async (params?: OrderRequestParams) => {
    try {
      setLoading(true);
      const resultData = await tradingService.get(params);

      const data = resultData?.data.items.map((item: any) => ({
        ...item,
        id: item.client_order_id,
        amount: displayNumb(item.filled_amount),
        proceeds: displayNumb(item.proceeds),
        cost: displayNumb(item.cost),
      }));

      setRows(data);

      setOrderTotal(resultData?.data.items_total);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.componentRecentOrders}>
      <h2 className={styles.titleTable}>Recent Orders</h2>
      <div
        className={`${tableStyle.customerDataGrid} ${styles.footer} `}
        style={{ minHeight: 400 }}
      >
        <DataGrid
          autoHeight
          loading={loading}
          getRowId={(row) => row.client_order_id}
          rows={dataRows}
          rowCount={orderTotal}
          columns={columns}
          pageSize={5}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={46}
          rowHeight={64}
          scrollbarSize={2}
          page={orderPage}
          onPageChange={(page) => setOrderPage(page)}
          paginationMode="server"
          components={{
            Pagination: GridDataPagination,
            NoRowsOverlay: () => (
              <AppBox
                className={tableStyle.rowEmptydata}
                loading={loading}
                hasData={dataRows?.length > 0}
              ></AppBox>
            ),
            LoadingOverlay: LoadingOverlay,
          }}
        />
      </div>
    </div>
  );
};

export default ComponentRecentOrders;
