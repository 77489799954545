import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "inline-flex",
    position: "relative",
    cursor: "pointer",
    alignItems: "center",

    "& > input": {
      opacity: 0,
      position: "absolute",
      left: 0,
      top: 0,
      height: 0,
      width: 0,
    },
  },

  disabled: {
    pointerEvents: "none",
  },

  slider: {
    width: "36px",
    height: "22px",
    borderRadius: "24px",
    background: theme.custom.colors.santaGray2,
    display: "inline-flex",
    alignItems: "center",

    "$container:hover &": {
      borderColor: theme.custom.colors.cornflowerBlue,
    },

    "&:before": {
      content: "''",
      position: "absolute",
      left: "2px",
      height: "18px",
      width: "18px",
      borderRadius: "50%",
      background: "white",
      transition: ".4s",
    },
    "input:checked ~ &:before": {
      transform: "translateX(14px)",
    },

    "input:checked ~ &": {
      background: theme.custom.colors.cornflowerBlue,
    },

    "$disabled ~ &": {
      background: theme.custom.colors.grey3,
    },
  },

  largeToggle: {
    "& $slider": {
      width: "48px",
      height: "28px",
    },

    "& $slider:before": {
      width: "24px",
      height: "24px",
    },

    "& input:checked ~ $slider:before": {
      transform: "translateX(20px)",
    },
  },

  label: {
    marginLeft: "5px",
  },
}));

export default useStyles;
