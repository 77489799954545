import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      background: theme.custom.colors.bgrColor,
      fontFamily: 'Titillium Web',
    },
    styleDialog: {},

    styleTitleDialog: {},

    styleIcon: {},

    titleDialog: {
      textAlign: 'center',
      paddingTop: 10,
      paddingBottom: 0,
    },

    styleClose: {
      top: '8px',
      right: '8px',
      position: 'absolute',

      '&:hover': {
        background: 'none',
      },
    },

    styleTypo: {},

    styleDialogContent: {
      border: 'none',
    },

    formRequets: {
      background: theme.custom.colors.bgrColor,
      borderRadius: 10,
      width: 611,
      height: 472,
      marginTop: 93,
      fontFamily: 'Titillium Web',
    },

    titleForm: {
      fontWeight: 700,
      fontFamily: 'Titillium Web',
      fontSize: '32px',
      color: theme.custom.colors.textdesc,
      textAlign: 'center',
      padding: 0,

      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
      },
    },

    styleForm: {
      padding: '0 65px 20px',

      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },

    styleLabel: {
      display: 'block',
      fontWeight: 600,
      fontSize: '14px',
      alignItems: 'center',
      color: theme.custom.colors.colorLbl,
      marginBottom: 7,
    },

    styleInput: {
      border: '1px solid #EBECF0',
      borderRadius: '6px',
      width: '96%',
      height: 28,
      background: 'none',
      padding: '10px 8px 10px 16px',
      color: theme.custom.colors.colorLbl,
      outline: 'none',

      '&::placeholder': {
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        color: theme.custom.colors.placeholder,
      },
    },
    rectangle: {
      height: 33,
      width: 1,
      marginRight: 10,
      background: theme.custom.colors.comet,
    },
    rectangle1: {
      height: 10,
      width: 1,
      marginRight: 10,
      background: theme.custom.colors.comet,
    },
    styleCheck: {
      paddingLeft: 0,
      paddingRight: 5,

      '& svg': {
        border: '1px solid #606983',
      },
    },
    styleBox: {},
    icon: {
      borderRadius: 3,
      width: 25,
      height: 25,
      backgroundColor: 'none',
      border: '1px solid #606983',
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
      'input:hover ~ &': {
        borderColor: '#7F63F4',
      },
    },

    checkedIcon: {
      background: theme.custom.colors.btnSignUp,
      border: '1px solid #7F63F4',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 25,
        height: 25,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        opacity: 0.8,
      },
    },
    styleTextCheck: {},
    styleLbl: {
      userSelect: 'none',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '21px',
      color: theme.custom.colors.textdesc,
      marginLeft: 5,
      fontFamily: 'Titillium Web',

      '& span': {
        fontWeight: 700,
        cursor: 'pointer',
      },
    },

    styleLblError: {
      color: theme.custom.colors.textError,
    },

    divBtn: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 16,
    },

    btnSubmit: {
      background: theme.custom.colors.btnSignUp,
      borderRadius: 6,
      height: 50,
      width: 188,
      fontFamily: 'Titillium Web',
      fontSize: 14,
      fontWeight: 700,
      color: theme.custom.colors.white,
      textTransform: 'none',
      boxShadow: 'none',

      '&:hover': {
        background: theme.custom.colors.hoverButton,
      },
      '&:disabled': {
        background: theme.custom.colors.bgDisable,
        color: theme.custom.colors.white,
        cursor: 'no-drop',
        pointerEvents: 'auto',

        '&:hover': {
          background: theme.custom.colors.bgDisable,
          opacity: 1,
        },
      },
    },

    link: {
      cursor: 'pointer',
      color: theme.custom.colors.textdesc,
      '&:hover': {
        textDecoration: 'none',
      },
    },

    captcha: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0',
      [theme.breakpoints.down('xs')]: {
        transform: 'scale(0.85) !important',
      },
    },

    checkBox: {
      display: 'flex',
      alignItems: 'center',
      "& div": {
        marginLeft: 10
      }
    },

    inputItem: {
      width: '100%',
      marginBottom: 20,
      "& label": {
        color: theme.custom.colors.colorLbl,
        marginBottom: 5,
        fontFamily: 'Titillium-Web',
        fontWeight: 'bold',
        
      },

      "& .label-focused": {
         color:theme.custom.colors.colorRead,
      },

      "& .Mui-error": {
        color: '#DE4949 !important',
        fontFamily:'Titillium-Web'
      },

     
    },

    inputPhoneNum: {
      "&.form-control": {
      paddingLeft: 40,
      height: 50,
      width: '100%',
      borderRadius: 6,
      border: `1px solid ${theme.custom.colors.colorLbl}`,
      color: theme.custom.colors.colorLbl,
      background: 'none',
      
      [theme.breakpoints.down('xs')]: {
       background: 'none'
        },
      
      "&:focus": {
        border: `1px solid ${theme.custom.colors.colorRead}`,
      }
     
    },
    },
     
  }
});

export default useStyles;
