import { AnyAction } from 'redux';
import { ASSET_SELECTED, ASSET_SET } from '../constants/asset';

const initialState = {
  assets: [],
  assetSelected: '',
};

export const assetReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ASSET_SET:
      return {
        ...state,
        assets: action.payload,
      };
    case ASSET_SELECTED:
      return {
        ...state,
        assetSelected: action.payload,
      };
    default:
      return state;
  }
};
