import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import useStyles from './styles';
import SupportComponent from './SupportComponent';
import { Grid, Typography } from '@material-ui/core';
const listSupportDef = [
  {
    id: 1,
    icon: 'icon-get-in-touch',
    title: 'Get in touch',
    content:
      'Reach out to us via email or raising a support request below, our team are on standby and will get back to you asap!',
    primaryText: 'Raise Request',
    secondaryText: 'Email',
    primaryHref:
      'https://onealpha1.atlassian.net/servicedesk/customer/portal/3/group/-1',
    secondaryHref: 'mailto:support@onealpha.io',
  },
  {
    id: 2,
    icon: 'icon-faq',
    title: 'FAQ',
    content:
      'Refer to our frequently asked questions in our help center below.',
    primaryText: 'View',
    primaryHref: 'https://onealpha1.atlassian.net/servicedesk/customer/portals',
  },
  {
    id: 3,
    icon: 'icon-documentation',
    title: 'Documentation',
    content:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit ',
    primaryText: 'View',
    primaryHref: 'https://api.onealpha.io/docs',
  },
];
const SupportPage = (props: any) => {
  const styles = useStyles();
  const handleActionChat = (id: any) => {};
  const handleActionView = (id: any) => {};
  const handleActionEmail = (id: any) => {};
  useEffect(() => {
    document.title = 'Support';
  }, []);

  return (
    <DefaultLayout>
      <Typography className={styles.title}>Support</Typography>
      <Grid container spacing={2} className={styles.main}>
        {listSupportDef.map((item) => (
          <Grid item sm={12} md={4} key={item.id} className={styles.item}>
            <SupportComponent
              {...item}
              actionChat={handleActionChat}
              actionView={handleActionView}
              actionEmail={handleActionEmail}
            />
          </Grid>
        ))}
      </Grid>
    </DefaultLayout>
  );
};

export default withRouter(SupportPage);
