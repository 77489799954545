import { Checkbox } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import useStyles from './styles';

const CheckboxCommon = (props: any) => {
  const {
    control,
    nameCheck,
    label,
    onChange,
    formState,
    required = true,
  } = props;
  const { errors } = formState;

  const style = useStyles();
  return (
    <>
      <Controller
        name={nameCheck}
        control={control}
        defaultValue={false}
        rules={{ required: required ? 'This is required' : false }}
        render={({ field }) => (
          <Checkbox
            id={nameCheck}
            className={style.paddingCheck}
            disableRipple
            color="default"
            checkedIcon={
              <span className={clsx(style.iconCheckbox, style.checkBox)} />
            }
            icon={
              <span
                className={clsx(style.iconCheckbox, errors[nameCheck] ? style.iconCheckboxError : '')}
              />
            }
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            onChange={(e: any) =>
              onChange ? onChange : field.onChange(e.target.checked)
            }
            checked={field.value}
          />
        )}
      />
      <label
        className={`${style.styleLbl} ` + (errors[nameCheck] ? style.styleLblError : '')}
        htmlFor={nameCheck}
      >
        {label}
      </label>
    </>
  );
};
export default CheckboxCommon;
