/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Button, Typography } from '@material-ui/core';

import DefaultLayout from 'src/components/Layout/DefaultLayout';
import AppInput from 'src/components/Base/AppInput';
import { SecurityService } from 'src/services/SecurityService';
import useStyles from './styles';

const Component2FAForms = (props: any) => {
  const styles = useStyles();
  const [error, setError] = useState(false);
  const api = SecurityService.getInstance('');
  const history = useHistory();
  const [pinCode, setPinCode] = useState('');
  const onSubmit = async (data: any) => {
    const request = {
      task: 'validate',
      '2fa-code': pinCode,
    };
    try {
      const response = await api.confirmCodeFromEmail(request);
      if (response && response?.data?.result) {
        history.push('/setting/security');
        return;
      }
      setError(true);
    } catch (error) { }
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      test: '',
      select: 1,
      ac: '',
      date: '',
    },
  });

  const handleChange = (e: any) => {
    e.preventDefault();
    setError(false);
    let input = e.target.value;
    let code = input.replace(/[^0-9]/g, '');
    if (code?.length > 6) { code = code.substr(0, 6) }
    setPinCode(code);
  }

  useEffect(() => {
    const prevState = props.location.state?.qrCode;
    if (!prevState || prevState === '') {
      history.push("/")
    }
  }, []);

  return (
    <DefaultLayout>
      <div className={styles.twoFatorAuthentication}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div className={`icon-trading2 ${styles.iconStyle}`} />
          <h2 className={styles.title}>Two factor authentication</h2>
        </div>
        <p className={styles.subTitle}>
          Scan qrcode with device and enter the 2FA code displayed
        </p>
        <div className={styles.content}>
          <div className={styles.codeQR}
            dangerouslySetInnerHTML={{
              __html: `${props?.location?.state?.qrCode}`,
            }}
          />
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <AppInput
              controller={{ control, name: 'test' }}
              label={'2FA code'}
              error={error}
              placeholder="Enter code"
              onChange={handleChange}
              value={pinCode}
            />
            {error && (
              <Typography className={styles.textError}>
                The 2FA code is incorrect. Please check and try again.
              </Typography>
            )}
            <Button type="submit" className={styles.btnSubmit} disabled={!pinCode} style={{ background: !pinCode ? "#606983" : "" }}>
              Submit
            </Button>
          </form>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Component2FAForms;
