import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    kycStatusContainer: {
      marginTop: "30px",
      background: theme.custom.colors.cardBackground,
      padding: "20px 20px 40px",
      borderRadius: "8px",
      width: "797px",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    status: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: theme.custom.colors.boldGray,
      fontSize: "20px",
      lineHeight: "23px",
      fontFamily: "Ubuntu-Bold",

      "& .icon-sand-timer, .icon-medal": {
        fontSize: "30px",
      },

      "& >div:nth-child(2)": {
        marginLeft: "8px",
      },

      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      },
    },

    desc: {
      marginLeft: "38px",
      marginTop: "15px",
      color: theme.custom.colors.descColor,
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Ubuntu-Regular"
    }
  }
})

export default useStyles;