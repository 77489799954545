import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormControlLabel,
  Hidden,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';

import ModalSignUp from 'src/routing/TradingPage/ComponentTradingEntry/ModalSignUp';
import LoadingOverlay from 'src/routing/ReportsPage/LoadingOverlay';
import Checkbox from 'src/components/Base/Checkbox';
import GridDataPagination from 'src/components/Base/GridDataPagination';
import Deposit from 'src/components/Deposit';

import { AssetAction } from 'src/store/actions/asset';
import { WithdrawalAction } from 'src/store/actions/withdrawal';

import assetConfig from 'src/commons/configs/assetsConfig.json';
import { DEFAULT_LOGO } from 'src/commons/constants';

import TableStyle from 'src/styles/TableStyle';
import useStyles from './styles';

const ComponentMid = (props: any) => {
  const { balances, onSearch, currencies, isLoading } = props;
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const tableStyle = TableStyle();
  const [pageTable, setPageTable] = useState(5);
  const [rowHeight, setRowHeight] = useState(64);
  const [isOpen, setIsOpen] = useState(false);
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const [isSearch, setIsSearch] = useState(false);
  const theme = useTheme();
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const matchOnlyLg = useMediaQuery(theme.breakpoints.only('lg'));
  const matchOnlymd = useMediaQuery(theme.breakpoints.only('md'));
  const matchOnlysm = useMediaQuery(theme.breakpoints.only('sm'));
  const matcDonwxs = useMediaQuery(theme.breakpoints.down('xs'));
  const [data, setData] = useState<any[]>([]);
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [page, setPage] = useState(0);
  const [hideZero, setHideZero] = useState(false);

  const handleChangeTablePage = (pageTable: number) => {
    setPageTable(pageTable);
    setPage(0);
  };
  const handleWithdraw = (params: any) => {
    dispatch(AssetAction.selectAsset(params.row.asset));
    dispatch(
      WithdrawalAction.toggle(null, () => {
        window.location.reload();
      })
    );
  };
  const handleDeposit = (params: any) => {
    setIsOpenDeposit(true);
    setSelectedCurrency(params.row);
  };

  const copyToClipboard = (addr: string) => {
    navigator.clipboard.writeText(addr);
    toast.success('Copied!');
  };
  const handleRedirectTrading = (pair: string) => {
    let curency = currencies.filter((x: any) => x.value.split('/')[0] === pair);

    // when select coin is cash (EUR, USD, HKD ..)
    if (curency && curency.length === 0) {
      curency = currencies.filter((x: any) => x.value.split('/')[1] === pair);
    }
    if (curency && curency.length > 0) {
      history.push({ pathname: '/trading', state: { pair: curency[0].value } });
    } else {
      history.push({ pathname: '/trading' });
    }
  };

  useEffect(() => {
    if (matchUpXl) {
      setRowHeight(74);
    } else if (matchOnlyLg) {
      setRowHeight(74);
    } else if (matchOnlymd) {
      setRowHeight(64);
    } else if (matchOnlysm) {
      setRowHeight(64);
    } else if (matcDonwxs) {
      setRowHeight(50);
    }
  }, [matchUpXl, matchOnlyLg, matchOnlymd, matchOnlysm, matcDonwxs]);

  const columns: GridColDef[] = [
    {
      field: 'symbol',
      headerName: 'Asset',
      sortable: false,
      width: matchUpXl ? 250 : matchOnlyLg ? 200 : 180,
      flex: matchUpXl ? 0.45 : matchOnlyLg ? 0.5 : 0,
      renderCell: (params: GridValueGetterParams) => {
        const asset = params.row.asset;
        const objAsset = (assetConfig as any)[asset];
        return (
          <div className={styles.symbol}>
            <img
              className={styles.iconCoin}
              src={objAsset?.icon || DEFAULT_LOGO}
              alt={asset}
            />
            <div className={styles.symbolInfo}>
              <strong>{params.getValue(params.id, 'symbol') || ''}</strong>
              <div>{params.getValue(params.id, 'name') || ''}</div>
            </div>
          </div>
        );
      },
    },
    {
      field: 'total_balance',
      headerName: 'Total balance',
      sortable: false,
      width: matchUpXl ? 200 : matchOnlyLg ? 180 : 150,
      flex: matchUpXl ? 0.45 : matchOnlyLg ? 0.45 : 0,
      renderCell: (params: GridValueGetterParams) => (
        <strong>{params.getValue(params.id, 'total_balance') || ''}</strong>
      ),
    },
    {
      field: 'available',
      headerName: 'Available',
      sortable: false,
      width: matchUpXl ? 200 : matchOnlyLg ? 180 : 150,
      flex: matchUpXl ? 0.45 : matchOnlyLg ? 0.4 : 0,
      renderCell: (params: GridValueGetterParams) => (
        <strong>{params.getValue(params.id, 'available') || ''}</strong>
      ),
    },
    {
      field: 'panding',
      headerName: 'Pending',
      sortable: false,
      width: matchUpXl ? 200 : matchOnlyLg ? 180 : 120,
      flex: matchUpXl ? 0.45 : matchOnlyLg ? 0.4 : 0,
      renderCell: (params: GridValueGetterParams) => (
        <strong>{params.getValue(params.id, 'panding') || ''}</strong>
      ),
    },
    {
      field: 'address',
      headerName: 'Deposit address',
      sortable: false,
      width: matchUpXl ? 220 : matchOnlyLg ? 200 : 150,
      flex: matchUpXl ? 0.5 : matchOnlyLg ? 0.5 : 0,
      renderCell: (params: GridValueGetterParams) => {
        const address = params.getValue(params.id, 'address');
        const addr = address?.toString() || '';
        return (
          <strong className={styles.addressTable}>
            {address ? (
              <span>
                {`${addr.substr(0, 6)}...${addr.substr(
                  addr.length - 4,
                  addr.length
                )}`}
                <span
                  className={`icon-copy2 ${styles.btnCopy}`}
                  onClick={() => copyToClipboard(addr)}
                ></span>
              </span>
            ) : (
              ''
            )}
          </strong>
        );
      },
    },
    {
      field: ' ',
      headerName: 'Action',
      sortable: false,
      width: 300,
      flex: matchUpXl ? 0.7 : matchOnlyLg ? 0.7 : 0,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Button
            variant="contained"
            className={styles.btnAction}
            onClick={() => handleRedirectTrading(params.row.asset)}
          >
            Trading
          </Button>
          <Button
            variant="contained"
            className={styles.btnAction}
            onClick={() => handleWithdraw(params)}
          >
            Withdraw
          </Button>
          <Button
            variant="contained"
            className={styles.btnAction}
            onClick={() => handleDeposit(params)}
          >
            Deposit
          </Button>
        </>
      ),
    },
  ];
  useEffect(() => {
    if (hideZero) {
      const newBalances = balances.filter((x: any) => Number(x.total) > 0);
      setData(newBalances);
    } else {
      setData(balances);
    }
  }, [balances, hideZero]);

  const searchField = (e: string) => {
    onSearch(e);
    setIsSearch(true);
  };
  return (
    <section className={styles.componentMid}>
      <div className={styles.headCompoent}>
        <div className={styles.groupSearchFilter}>
          <div className={styles.groupSearch}>
            <span className="icon-search"></span>
            <input
              placeholder="Search"
              className={styles.inputSearch}
              onChange={(e) => searchField(e.target.value)}
              maxLength={255}
            />
          </div>
          <Hidden xsDown>
            <FormControlLabel
              className={styles.formControlLabel}
              value={hideZero}
              control={<Checkbox onClick={() => setHideZero(!hideZero)} />}
              label="Hide zero balances"
            />
          </Hidden>
        </div>

        <Hidden smUp>
          <FormControlLabel
            className={styles.formControlLabel}
            value={hideZero}
            control={<Checkbox onClick={() => setHideZero(!hideZero)} />}
            label="Hide zero balances"
          />
        </Hidden>
        <select
          className={styles.selectpageSize}
          defaultValue={pageTable}
          onChange={(event) =>
            handleChangeTablePage(Number(event.target.value))
          }
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
        </select>
      </div>

      <div
        className={tableStyle.customerDataGrid}
        style={{
          minHeight: `calc(46px + ${Number(5) * rowHeight}px + 72px)`,
        }}
      >
        <DataGrid
          loading={isLoading}
          autoHeight
          rows={data}
          columns={columns}
          page={page}
          pageSize={pageTable}
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={46}
          rowHeight={rowHeight}
          hideFooter={data.length <= 0}
          onPageChange={(page) => setPage(page)}
          components={{
            Pagination: GridDataPagination,
            NoRowsOverlay: () => (
              <div className={`${tableStyle.rowEmptydata} ${styles.noData}`}>
                {!isSearch && (
                  <>
                    <span
                      className={`icon-no-data ${tableStyle.iconEmptydata}`}
                    ></span>
                    <div className={tableStyle.textEmptydata}>No data</div>
                    <div className={tableStyle.messEmptydata}>
                      You will be able to see reports after <br /> completing
                      transactions
                    </div>

                    {isLogged ? (
                      <Button
                        variant="contained"
                        className={`${tableStyle.btnEmptydata}`}
                        onClick={() => history.push('/trading')}
                      >
                        Start trading
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className={`${tableStyle.btnEmptydata}`}
                        onClick={() => setIsOpen(true)}
                      >
                        Sign up
                      </Button>
                    )}
                  </>
                )}
                {isSearch && (
                  <div
                    className={`${tableStyle.textEmptydata} ${styles.noMatch}`}
                  >
                    <span
                      className={`icon-no-data ${tableStyle.iconEmptydata}`}
                    ></span>
                    <div> No match found</div>
                  </div>
                )}
              </div>
            ),
            LoadingOverlay: LoadingOverlay,
          }}
        />
      </div>
      <ModalSignUp
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      ></ModalSignUp>
      <Deposit
        isOpen={isOpenDeposit}
        handleClose={() => setIsOpenDeposit(false)}
        currency={selectedCurrency}
      />
    </section>
  );
};

export default ComponentMid;
