import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    button: {
      backgroundColor: theme?.custom?.colors.cardBackground,
      color: theme?.custom?.colors.white1,
    },
    marketInfoContainer: {
      width: '706px',
      height: '509px',
      borderRadius: '8px',
      marginTop: '36px',

      color: theme?.custom?.colors.white1,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      [theme.breakpoints.only('sm')]: {
        width: '100%',
        height: '444px',
      },

      [theme.breakpoints.only('md')]: {
        width: '40%',
        height: '444px',
      },

      [theme.breakpoints.only('lg')]: {
        width: '40%',
        marginTop: '24px',
      },
    },
    header: {
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '28px',
      color: theme.custom.colors.titleTrading,
      fontWeight: 700,
      fontFamily: 'Ubuntu-Bold',
      fontSize: 20,

      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },

      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        padding: '0 20px',
      },
    },

    pairs: {
      height: 'calc(100% - 45px)',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '4px',
      marginTop: '10px',
      overflowY: 'auto',

      [theme.breakpoints.down('xs')]: {
        padding: '0 10px 0 20px',
        marginRight: 7,
      },
    },
    item: {
      width: '100%',
      height: '74px',
      background: theme?.custom?.colors.cardBackground,
      borderRadius: '8px',
      padding: '12px 30px',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        padding: '6px 10px',
      },

      [theme.breakpoints.only('sm')]: {
        height: '64px',
        paddingLeft: '20px',
        paddingTop: '10px',
        paddingBottom: '9px',
        paddingRight: '19px',
      },

      [theme.breakpoints.only('md')]: {
        height: '64px',
        paddingLeft: '20px',
        paddingTop: '10px',
        paddingBottom: '9px',
        paddingRight: '19px',
      },

      [theme.breakpoints.only('lg')]: {
        height: '74px',
        paddingLeft: '20.5px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingRight: '21.5px',
      },
    },
    frame1: {
      display: 'flex',
      order: 0,
      alignItems: 'center',
    },

    logo: {
      width: '50px',
      height: '50.01px',

      [theme.breakpoints.only('lg')]: {
        width: '35px',
        height: '35px',
      },

      [theme.breakpoints.down('xs')]: {
        width: '35px',
        height: '35px',
      },
    },
    pairDetail: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',


      position: 'static',
      width: '66px',
      height: '47px',

      order: 1,
      margin: '0px 20px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '0px 10px 0px 5px',
        justifyContent: 'center',
      },
    },
    pairPrice: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      position: 'static',
      width: '80px',
      height: '47px',

      flex: 'none',
      order: 2,
      flexGrow: 0,
    },
    tradeBtn: {
      width: '82px',
      height: '36px',
      border: 'none',
      order: 3,
      fontFamily: 'Ubuntu-Regular',

      background: theme?.custom?.colors.cornflowerBlue,
      borderRadius: '6px',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      textAlign: 'center',
      color: theme?.custom?.colors.white1,
      textTransform: 'none',

      cursor: 'pointer',
      "&:hover": {
        background: theme.custom.colors.btnHover,
      },
    },
    pairLabel: {
      color: theme?.custom?.colors.santaGray2,
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '140%',
      fontFamily: 'Ubuntu-Regular',

      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '14px',
      },
    },
    priceLabel: {
      color: theme?.custom?.colors.titleTrading,
      marginTop: '5px',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '140%',
      width: 'auto',
      minWidth: 100,
      fontFamily: 'Ubuntu-Regular',


      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.only('sm')]: {},

      [theme.breakpoints.only('md')]: {
        fontSize: '14px',
      },

      [theme.breakpoints.only('lg')]: {
        fontSize: '14px',
      },
    },

    styleEmpty: {
      top: 0,
      height: '100%',
      borderRadius: 8,
    },
  };
});

export default useStyles;
