import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    contentTop: {
      marginTop: 20,
      fontFamily: 'Titillium Web',

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },

      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'flex-start',
      },
    },

    titleTop: {
      fontSize: '64px',
      lineHeight: '75px',
      letterSpacing: '0.03em',
      color: theme.custom.colors.white,
      fontWeight: 700,

      [theme.breakpoints.down('lg')]: {
        fontSize: '48px',
      },

      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: '64px',
      },

      [theme.breakpoints.up('md')]: {
        paddingTop: 160,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 40,
        marginBottom: 0,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
      },
    },

    textDesc: {
      fontSize: 20,
      lineHeight: '30px',
      color: theme.custom.colors.textdesc,
      [theme.breakpoints.between('lg', 'xl')]: {},
      [theme.breakpoints.down('lg')]: {
        maxWidth: 510,
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        fontSize: 18,
      },
    },

    boxButton: {
      display: 'flex',
      width: '100%',
      marginTop: 40,
      marginBottom: 30,
      [theme.breakpoints.up('sm')]: {
        gridGap: 24,
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        marginTop: 20,
      },

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        gridGap: '10px',
      },
    },

    btnTrade: {
      background: theme.custom.colors.btnSignUp,
      borderRadius: 6,
      height: 50,
      width: 188,
      fontFamily: 'Titillium Web',
      fontSize: 14,
      fontWeight: 700,
      color: theme.custom.colors.white,
      textTransform: 'none',
      marginRight: 10,
      boxShadow: 'none',

      '&:hover': {
        background: theme.custom.colors.hoverButton,
      },
      [theme.breakpoints.down('xs')]: {
        width: 158,
        height: 40,
      },
    },

    btnRequest: {
      background: 'none',
      borderRadius: 6,
      height: 50,
      width: 188,
      fontFamily: 'Titillium Web',
      fontSize: 14,
      color: theme.custom.colors.white,
      textTransform: 'none',
      marginRight: 15,
      boxShadow: 'none',
      border: '1px solid #FFFFFF',

      '&:hover': {
        border: '1px solid #7F63F4',
        background: 'none',
        color: theme.custom.colors.textDetail,
      },

      [theme.breakpoints.down('xs')]: {
        width: 158,
        height: 40,
      },
    },

    countNumber: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        marginTop: -190,
      },
    },

    textCenter: {
      // textAlign: 'center',
    },

    box1: {
      color: theme.custom.colors.white,
      fontFamily: 'Titillium Web',
      paddingTop: 25,
      marginRight: 260,

      [theme.breakpoints.up('lg')]: {
        marginRight: 260,
      },

      [theme.breakpoints.down('1440')]: {
        marginRight: 152,
        paddingTop: 50,
      },

      [theme.breakpoints.between('1440', '1920')]: {
        marginRight: 240,
        paddingTop: 40,
      },

      [theme.breakpoints.down('sm')]: {
        paddingTop: 30,
        marginRight: 100,
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
        marginRight: 55,
      },
    },
    box2: {},

    box3: { marginRight: 0 },

    styleNumber: {
      fontWeight: 700,
      fontSize: '64px',

      [theme.breakpoints.down('lg')]: {
        fontSize: '48px',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: '64px',
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
      },
    },

    styleText: {
      fontSize: '24px',
      margin: 0,

      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },

    bgrMobile: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    bgrXs: {},

    groupCanvas: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    canvas: {
      position: 'absolute',
      top: '15%',
      right: '15%',
      [theme.breakpoints.between('md', 'lg')]: {
        right: '8%',
      },
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {},
    },

    canvasXS: {
      width: 400,
      height: 400,
    },
  };
});

export default useStyles;
