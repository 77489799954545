import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    styleMainTerm: {
      fontFamily: 'Ubuntu',
    },
    contentTitle: {
      background: theme.custom.colors.mainBackground,
    },

    mainTerm: {
      backgroundImage: 'url(/images/bgrTerm.png)',
      paddingTop: 51,
      paddingBottom: 67,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top right',
      backgroundOrigin: 'border-box',

      [theme.breakpoints.down('sm')]: {
        padding: '30px 0',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '20px 0',
        backgroundImage: 'url(/images/bgr-termMobile.png)',
      },
    },

    containerTerm: {
      padding: '0 305px',

      [theme.breakpoints.down('md')]: {
        padding: '0 30px',
      },
    },

    titleTerm: {},

    styleTitle: {
      fontSize: '48px',
      lineHeight: '55px',
      fontFamily: 'Ubuntu-Bold',
      color: theme.custom.colors.white,

      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
      },
    },

    textDay: {
      fontSize: '18px',
      lineHeight: '140%',
      color: theme.custom.colors.colorDay,

      [theme.breakpoints.down('sm')]: {
        margin: '10px 0',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        margin: 0,
      },
    },

    detailTerm: {
      background: theme.custom.colors.white,
      padding: '60px 0',

      [theme.breakpoints.down('sm')]: {
        padding: '40px 0',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '20px 0',
      },
    },

    titleDetail: {
      fontSize: '32px',
      lineHeight: '37px',
      fontFamily: 'Ubuntu-Bold',
      fontWeight: 700,
      color: theme.custom.colors.textTerm,

      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },

    boxDetail: {},

    titleBox: {
      fontSize: '24px',
      lineHeight: '28px',
      fontFamily: 'Ubuntu-Bold',
      fontWeight: 700,
      color: theme.custom.colors.textTerm,
      margin: '40px 0 0',

      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        margin: '20px 0 0',
      },
    },

    textDetailTerm: {
      fontSize: '16px',
      lineHeight: '140%',
      fontFamily: 'Ubuntu',
      color: theme.custom.colors.textTerm,
      fontWeight: 400,
    },

    styleText: {
      marginBottom: 0,
    },

    styleDetailList: {
      paddingLeft: 27,
      fontFamily: 'Ubuntu',
      color: theme.custom.colors.textTerm,
      fontSize: '16px',
      lineHeight: '150%',
    },

    styleListItem: {
      '& b': {
        fontWeight: 700,
      },
    },

    styleRisk: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    styleLink: {
      color: theme.custom.colors.textDetail,
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },

    titleChild: {
      marginTop: 60,
    },

    textRisk: {
      fontSize: '16px',
      lineHeight: '145%',
      color: theme.custom.colors.textTerm,
    },
  };
});
export default useStyles;
