import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Hidden } from '@material-ui/core';

// project imports
import { customization } from '../../../store/actions/customization';
import Sidebar from '../../Base/Sidebar';
import Content from '../../Base/Content';
import HeaderTrading from '../../Base/HeaderTrading';

import useStyles from './styles';

const DefaultLayout = (props: any) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
  const [leftDrawerOpened, setLeftDrawerOpened] = useState(true);

  useEffect(() => {
    setLeftDrawerOpened(!matchDownMd);
  }, [matchDownMd]);

  const handleLeftDrawerToggle = () => {
    setLeftDrawerOpened(!leftDrawerOpened);
  };

  useEffect(() => {
    dispatch(customization(leftDrawerOpened));
  }, [dispatch, leftDrawerOpened]);

  const classes = useStyles();

  return (
    <div
      className={`${classes.root} ${!!leftDrawerOpened && classes.overMobile}`}
      style={{ overflow: 'hidden' }}
    >
      <HeaderTrading
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
        {...props}
      />
      <Hidden mdUp>
        <Sidebar
          drawerOpen={leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
          {...props}
        />
      </Hidden>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Content>{props.children}</Content>
      </main>
    </div>
  );
};

export default DefaultLayout;
