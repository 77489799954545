import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 30,
      },
    },
    item: {

    },
    title: {
      fontWeight: 'bold',
      fontSize: '24px',
      marginTop: 15,
      marginBottom: 30,
      color: theme.custom.colors.boldGray,
    },
  };
});

export default useStyles;