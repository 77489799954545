import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainModal: {
      overflow: 'auto',
      display: "flex",
      position: "fixed",
      "& .modal-container": {
        background: theme.custom.colors.cardBackground,
        color: theme.custom.colors.white2,
        padding: "22px 0 55px",
        width: 690,
        height: 'fit-content',
        margin: "auto",
        fontFamily: "Ubuntu-Bold",
        borderRadius: "6px",
        border: 'none',
        outline: '0',
        maxHeight: '85vh',
        overflow: 'hidden',

        [theme.breakpoints.down('md')]: {
          maxWidth: 550,
          padding: 20

        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: 454,
          padding: 20

        },

        [theme.breakpoints.down('xs')]: {
          maxWidth: 335,
          padding: 20,
        },

        "&:focus, &:focus-visible": {
          outline: 'none'
        },

        "& .body-modal-container": {
          padding: "0 118px",
          maxHeight: '75vh',
          overflowY: 'auto',

          [theme.breakpoints.down('md')]: {
            padding: "0 60px",
            maxHeight: '70vh',
          },
          [theme.breakpoints.down('sm')]: {
            padding: "0 20px",
          },
          [theme.breakpoints.down('xs')]: {
            padding: "0 20px",
            maxHeight: '82vh',
            // minHeight: '82vh',
          },
        },


        "& .icon-close": {
          textAlign: "right",
          fontSize: "30px",
          cursor: "pointer",
          marginRight: "23px",

          [theme.breakpoints.down('md')]: {
            marginRight: 0,
          },
          [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            cursor: "none",
          },
        },
        "& .icon-close::before": {
          color: theme.custom.colors.white2,
        },
      },
    },

    iconClose: {
      display: 'flex',
      justifyContent: 'flex-end',
    }
  };
});

export default useStyles;