export function getOrderedCoins(allMarket: any) {
    const coins: Array<string> = [];

    // get all coin Ordered
    for (let symbol of allMarket) {
        const splitedCoins = symbol.split("/");
        for (let item of splitedCoins) {

            // check exist in coins
            const exCoin = coins.find((x) => x === item);
            if (!exCoin) {
                coins.push(item);
            }
        }
    }
    return coins;
}

export function getAnchorCoins(orderCoin: string, pairs: any) {
    const coins: Array<string> = [];

    // get all anchor coin with order coin
    for (let symbol of pairs) {
        const token = symbol
            .replace(orderCoin?.toUpperCase(), "")
            .replace("/", "");

        // check exist in coins
        const exCoin = coins.find((x) => x === token);
        if (!exCoin) {
            coins.push(token);
        }
    }
    return coins;
}

export function getOrderedCoinMarket(orderCoin: string, allMarket: any) {

    // get list ordered coin
    const orderedCoins = getOrderedCoins(allMarket);

    // get list anchor coin with active coin
    const pairs = allMarket.filter((m: any) => m.includes(orderCoin?.toUpperCase()));
    const anchorCoins = getAnchorCoins(orderCoin, pairs);

    return {
        orderedCoins,
        anchorCoins,
    }
}