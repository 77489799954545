import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import AppButton from '../AppButton';
import AppOTPInput from 'src/components/Base/AppOTPInput';
import ModalCustom from 'src/components/Base/ModalCustom';
import { ModalConfirmAction } from 'src/store/actions/modal-confirm';

import useStyles from './styles';
const LENGTH_CODE = 6;

export const ModalConfirmCode = () => {
  const styles = useStyles();

  const dispatch = useDispatch();
  const [pinCode, setPinCode] = useState('');
  const [twoFAcode, setTwoFACode] = useState('');
  const {
    show,
    isLoading,
    subTitle,
    msgError,
    onOK,
    onClose = () => dispatch(ModalConfirmAction.hide()),
  } = useSelector((state: any) => state.modalConfirm);

  const has2FA = useSelector(
    (state: any) => state.accountReducer.has2FADevices
  );

  const onChangeCode = (value: any) => {
    setPinCode(value);
  };

  const onChange2FA = (value: any) => {
    setTwoFACode(value);
  };

  useEffect(() => {
    setPinCode('');
    setTwoFACode('');
  }, [show]);

  return (
    <ModalCustom
      isOpen={show}
      handleClose={() => dispatch(ModalConfirmAction.hide())}
      className={styles.main}
    >
      <Typography className={styles.title}>Confirmation</Typography>
      <Typography className={styles.subTitle}>
        {subTitle || 'Enter confirmation code from email'}
      </Typography>
      <AppOTPInput
        onChange={onChangeCode}
        hasError={msgError && msgError !== '' ? true : false}
        isDontFocus={false}
      />
      {has2FA && (
        <>
          <Typography className={styles.subTitle}>
            Enter 2FA confirmation code
          </Typography>
          <AppOTPInput
            onChange={onChange2FA}
            hasError={msgError && msgError !== '' ? true : false}
            isDontFocus={true}
          />
        </>
      )}
      {msgError && (
        <Typography className={styles.textError}>
          {typeof msgError === 'boolean'
            ? has2FA
              ? 'Email or 2FA code is incorrect. Please check and try again.'
              : 'The email code is incorrect. Please check and try again.'
            : msgError}
        </Typography>
      )}
      <div className={styles.btnGroup2}>
        <AppButton
          height={'36px'}
          width={'126px'}
          mode="secondary"
          onClick={() => onClose()}
        >
          Cancel
        </AppButton>
        <AppButton
          type="submit"
          height={'36px'}
          width={'126px'}
          isDisable={
            !(
              pinCode.length === LENGTH_CODE &&
              (has2FA ? twoFAcode.length === LENGTH_CODE : true)
            )
          }
          onClick={() => {
            onOK(pinCode, twoFAcode);
          }}
          isLoading={isLoading}
        >
          Confirm
        </AppButton>
      </div>
    </ModalCustom>
  );
};

export default ModalConfirmCode;
