import { makeStyles } from '@material-ui/core';

const useClasses = makeStyles((theme: any) => {
  return {
    title: {
      fontSize:'24px !important'
    },
    display: {
      textAlign: 'center',
    },
    showMobile: {},
    captcha: {
      width: 'fit-content',
      margin: '16px auto 0',
    },
    active: {
      background: `${theme.custom.colors.btnSignUp} !important`,
    }
  };
});

export default useClasses;
