import { useLoading, BallTriangle } from '@agney/react-loading';
import { GridOverlay } from '@material-ui/data-grid';
import useStyles from './styles';

export default function LoadingOverlay() {
  const classes = useStyles();
  const { indicatorEl } = useLoading({
    loading: true,
    indicator: <BallTriangle />,
  });
  return (
    <GridOverlay className={classes.container}>
      <div className={classes.loadingElement}>{indicatorEl}</div>
    </GridOverlay>
  );
}
