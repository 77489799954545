import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  input: {
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      borderColor: theme.custom.colors.lightPurple,
    },
  },
  iconDatePicker: {
    position: 'absolute',
    zIndex: 1,
    fontSize: 18,
    color: theme.custom.colors.white2,
    top: '50%',
    marginTop: -11,
    left: 10,
  },

  datePicker: {
    width: '100%',
    margin: 0,
    color: theme.custom.colors.white2,
    borderRadius: 6,
    background: theme.custom.colors.cardBackground,
    border: `1px solid ${theme.custom.colors.comet3}`,
    paddingLeft: 34,
    height: 50,
    outline: 'none',
    fontSize: 14,
    fontFamily: 'inherit',

    '&:focus-visible': {
      borderColor: theme.custom.colors.lightPurple,
    },

    '&::placeholder': {
      color: theme.custom.colors.white2,
    },
  },

  datePickerPopper: {
    zIndex: 3,
  },
}));

export default useStyles;
