import { EventEmitter } from "events";

class TvWebSocket {
    private url = process.env.REACT_APP_WS_URL || 'wss://api2.onealpha.io/ws/';
    private ws: WebSocket | null = null;
    private success: any = {};
    private failure: any = {};
    private timer: NodeJS.Timeout | null = null;
    private evt = new EventEmitter();

    public initWebSocket() {
        this.ws = new WebSocket(this.url);
        this.ws.onopen = this.onopen.bind(this);
        this.ws.onclose = this.onclose.bind(this);
        this.ws.onerror = this.onerror.bind(this);
        this.ws.onmessage = this.onmessage.bind(this);
    }

    private onopen() {
        if (!this.ws) {
            return;
        }

        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;

            for (const key in this.success) {
                if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                    this.ws?.send(this.success[key]);
                }

            }
        }

        for (const key in this.failure) {
            if (this.success[key]) {
                continue;
            }

            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                this.ws?.send(this.failure[key]);
                this.success[key] = this.failure[key];
            }
        }
        this.failure = {};
    }

    private onclose() {
        this.ws = null;
        if (!this.timer) {
            this.onReconnection();
        }
    }

    private onerror(event: Event) {
        console.log(" >> Websocket Error...", event);
    }

    private onmessage(event: MessageEvent<string>) {
        if (!event.data) {
            return;
        }

        const data = JSON.parse(event.data);
        if (data && data.ping) {
            this.ws?.send(
                JSON.stringify({
                    pong: Date.now(),
                })
            );
            return;
        }
        this.onBroadcast(data);
    }


    private onBroadcast(data: any) {
        if (!data || (data && !data?.response?.message)) {
            return;
        }
        const messageResponse = data?.response?.message;
        this.evt.emit(messageResponse?.method, messageResponse?.message);
    }

    public subscribe(
        name: string,
        params: any,
        callback: (...args: any[]) => void
    ) {
        let ee: EventEmitter;
        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
            ee = this.failurePush(name, params, callback);
        } else {
            ee = this.successPush(name, params, callback);
        }
        return {
            remove: () => {
                this.unsubscribe(name, params);
                ee.removeAllListeners(name);
            },
        };
    }

    public unsubscribe(name: string, params?: any) {
        if (this.failure[name]) {
            delete this.failure[name];
        }
        if (!this.success[name]) {
            return;
        }
        if (!this.ws) {
            delete this.success[name];
            return;
        }

        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
            this.failurePush(name, params, () => { });
        } else {
            this.ws?.send(JSON.stringify(params));
        }

        this.evt.removeAllListeners(name);
        delete this.success[name];
    }

    private successPush(
        name: string,
        params: any,
        callback: (...args: any[]) => void
    ) {
        this.success[name] = JSON.stringify(params);
        this.ws?.send(params);
        return this.evt.on(name, callback);
    }

    private failurePush(
        name: string,
        params: any,
        callback: (...args: any[]) => void
    ) {
        this.failure[name] = JSON.stringify(params);
        return this.evt.on(name, callback);
    }

    private onReconnection() {
        if (!this.url) {
            return;
        }
        this.initWebSocket();
        this.timer = setInterval(() => {
            this.initWebSocket();
        }, 3000);
    }
}

export const ws = new TvWebSocket();