import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => {
  return {
    mainModal: {
      '& .modal-container': {
        padding: `20px 0`,
        maxHeight: '100%',
        '& .body-modal-container': {
          padding: '0 20px',
          maxHeight: '100%',
          minHeight: '100%',
        },
      },
    },

    mainModalChart: {},
  };
});
export default useStyles;
