/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import useStyles from './styles';
import { Typography } from '@material-ui/core';

const TermsComponent = (props: any) => {
  const classes = useStyles();

  const onClickToAPIDocument = () => {
    (window as any).location = process.env.REACT_APP_API_DOCUMENT;
  };

  return (
    <section className={classes.styleMainTerm}>
      <div className={classes.contentTitle}>
        <div className={classes.mainTerm}>
          <div className={classes.containerTerm}>
            <div className={classes.titleTerm}>
              <Typography variant="h3" className={classes.styleTitle}>
                Terms and conditions
              </Typography>
              <p className={classes.textDay}>Last updated: 25 March, 2021</p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.detailTerm}>
        <div className={classes.containerTerm}>
          <Typography variant="h3" className={classes.titleDetail}>
            Interpretation and Definitions
          </Typography>

          <div className={classes.boxDetail}>
            <Typography variant="h4" className={classes.titleBox}>
              Interpretation
            </Typography>

            <p className={classes.textDetailTerm}>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions.
              <br></br>
              The following definitions shall have the same meaning regardless
              of whether they appear in singular or in plural.
            </p>

            <Typography variant="h4" className={classes.titleBox}>
              Definitions
            </Typography>

            <p className={`${classes.textDetailTerm} ${classes.styleText}`}>
              For the purposes of these Terms and Conditions:
            </p>
            <ul className={classes.styleDetailList}>
              <li className={classes.styleListItem}>
                <b>Affiliate</b> means an entity that controls, is controlled by
                or is under common control with a party, where "control" means
                ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other
                managing authority.
              </li>
              <li className={classes.styleListItem}>
                <b>Company</b> (referred to as either "the Company", "We", "Us"
                or "Our" in this Agreement) refers to oneAlpha.
              </li>
              <li className={classes.styleListItem}>
                <b>oneAlpha</b> means Alpha Node Limited, a company incorporated
                under the laws of Hong Kong with registration number 2696273
                whose registered address is Room 1104 Crawford House, 70 Queen’s
                Road Central, Hong Kong.
              </li>
              <li className={classes.styleListItem}>
                <b>Country</b> refers to: Hong Kong
              </li>
              <li className={classes.styleListItem}>
                <b>Service</b> refers to the Website, the trading platform (GUI)
                and use of the API
              </li>
              <li className={classes.styleListItem}>
                <b> GUI</b> refers to the trading platform accessed via
                <a
                  className={classes.styleLink}
                  href="https://onealpha.io/trade"
                  target="_blank"
                >
                  {' '}
                  www.onealpha.io/trade{' '}
                </a>
              </li>
              <li className={classes.styleListItem}>
                <b>API</b> refers to the oneAlpha API - documentation at &nbsp;
                <a
                  className={classes.styleLink}
                  onClick={onClickToAPIDocument}
                  target="_blank"
                >
                  https://api.onealpha.io/docs
                </a>
              </li>
              <li className={classes.styleListItem}>
                <b>Order</b> means an order or instruction submitted to us.
              </li>
              <li className={classes.styleListItem}>
                <b>Terms and Conditions</b> (also referred as "Terms") mean
                these Terms and Conditions that form the entire agreement
                between You and the Company regarding the use of the Service.
              </li>
              <li className={classes.styleListItem}>
                <b>Third-party Social Media Service</b> means any services or
                content (including data, information, products or services)
                provided by a third-party that may be displayed, included or
                made available by the Service.
              </li>
              <li className={classes.styleListItem}>
                <b>Website</b> refers to oneAlpha, accessible from &nbsp;
                <a href="https://onealpha.io/" target="_blank" className={classes.styleLink}>
                  www.onealpha.io
                </a>
              </li>
              <li className={classes.styleListItem}>
                <b>You</b> means the individual accessing or using the Service,
                or the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
            </ul>
            <div className={classes.styleRisk}>
              <Typography
                variant="h3"
                className={`${classes.titleDetail} ${classes.titleChild}`}
              >
                Risk Warning
              </Typography>
              <p className={classes.textRisk}>
                oneAlpha’s (“oneAlpha“) Digital Currency trading service carries
                a high level of risk and our trading service may not be suitable
                for everyone. It is your responsibility to assess for yourself
                that it is suitable for you based on your circumstances,
                financial resources and objectives. If you are in any doubt you
                should seek independent advice.<br></br>
                Please be aware of the following Risk Warnings.<br></br>
                (a) digital currencies are not backed by any central authority
                and are largely unregulated globally<br></br>
                (b) digital currencies are extremely volatile with profits or
                losses being incurred rapidly. It is your responsibility to
                monitor your account at all time.<br></br>
                (c) currency exchange fluctuations may impact your profits and
                losses;<br></br>
                (d) the price of our quoted instruments are determined by
                fluctuations across the digital currency market which fall
                outside of our control<br></br>
                (e) historical performance is no indication as to future
                performance.<br></br>
                (f) your rights to money held in our segregated client bank
                account or on one of exchange or custodian partners, where
                applicable, may be affected by the insolvency of the relevant
                bank / digital asset exchange / custodian;<br></br>
                (g) Digital Currency Fork events are outside of our control and
                may result in a material change in the value of your deliverable
                digital currency.<br></br>
                We are unable to confirm all risks or relevant considerations.
                By submitting an application to open a oneAlpha trading account,
                you confirm that you have read, accept and understand all terms
                of the Risk Warning and all documents supplied to you as part of
                the application. Further, you confirm that you understand and
                agree that our trading relationship will be governed by these
                documents. Please do not apply to open an account with us or
                commence trading if you are uncertain as to the risks involved
                or unsure on how our trading services operate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TermsComponent;
