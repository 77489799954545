import { FormGroup } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Checkbox from '../Checkbox';
import useStyles from './styles';

interface Props {
  value?: any;
  error?: any;
  helperText?: any;
  checkLists: any[];
  height?: string;
  label?: string;
  className?: string;
  inputRef?: any;
  handleCheck: (item: any) => void;
}

const AppCheckList: React.FC<Props> = ({
  label,
  checkLists,
  handleCheck,
  className,
  value,
  inputRef,
  error,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <FormGroup className={className}>
      <div
        className={clsx(classes.label, {
          [classes.error]: error,
        })}
      >
        {label}
      </div>
      {checkLists.map((item: any, index: any) => {
        return (
          <Checkbox
            inputRef={inputRef}
            key={index}
            className={classes.checkBox}
            checked={item === value}
            label={item}
            onChange={() => handleCheck(item)}
          />
        );
      })}
      {helperText && (
        <div
          className={clsx(classes.helperText, {
            [classes.error]: error,
          })}
        >
          {helperText}
        </div>
      )}
    </FormGroup>
  );
};

export default AppCheckList;
