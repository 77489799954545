import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MAX_LENGTH_INPUT } from 'src/commons/constants/AppInputFormat';
import AppButton from 'src/components/Base/AppButton';
import AppCheckList from 'src/components/Base/AppCheckList';
import AppInput from 'src/components/Base/AppInput';
import { trimObject } from 'src/utils/utils';
import { rulesTextField } from 'src/utils/validation';
import useStyles from './styles';

const Organization = ({
  formData,
  lastErrors,
  setStep,
  saveData,
}: {
  formData: any;
  lastErrors?: any;
  setStep: (step: number, state?: any) => void;
  saveData: (data: any, errors?: any) => void;
}) => {
  const classes = useStyles();

  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      sourceOfFunds: formData?.sourceOfFunds,
      natureOfBusiness: formData?.natureOfBusiness,
      intendedUseOfAccount: formData?.intendedUseOfAccount,
      anticipatedTypesOfAssets: formData?.anticipatedTypesOfAssets,
      anticipatedMonthlyVolume: formData?.anticipatedMonthlyVolume,
      anticipatedMonthlyTransactions: formData?.anticipatedMonthlyTransactions,
      anticipatedTradingPatterns: formData?.anticipatedTradingPatterns,
      typeOfEnterprise: formData?.typeOfEnterprise,
    },
  });

  useEffect(() => {
    const runSaveData = () => {
      trigger([
        'anticipatedMonthlyTransactions',
        'anticipatedMonthlyVolume',
        'anticipatedTradingPatterns',
        'anticipatedTypesOfAssets',
        'intendedUseOfAccount',
        'natureOfBusiness',
        'sourceOfFunds',
        'typeOfEnterprise',
      ]).then((isValid) => {
        let data = trimObject(getValues());

        saveData(
          {
            organization: { ...data },
          },
          { organization: !isValid }
        );
      });
    };

    return runSaveData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  useEffect(() => {
    lastErrors && trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.stepContainer}>
      <div className={classes.title}>Organization and Domicile</div>
      <form>
        <Controller
          name="typeOfEnterprise"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <AppCheckList
              className={classes.label}
              value={value}
              label="Please select type of enterprise"
              error={Boolean(errors.typeOfEnterprise)}
              helperText={errors.typeOfEnterprise?.message}
              checkLists={checkLists}
              handleCheck={onChange}
              inputRef={ref}
            />
          )}
          rules={rulesTextField('Source of Funds')}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'sourceOfFunds',
            rules: rulesTextField('Source of Funds'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Source of Funds'}
          error={Boolean(errors.sourceOfFunds)}
          placeholder="Source of Funds"
          helperText={errors.sourceOfFunds?.message}
          labelProps={{
            className: errors.sourceOfFunds ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'natureOfBusiness',
            rules: rulesTextField('Nature of business'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Nature of business'}
          error={Boolean(errors.natureOfBusiness)}
          placeholder="Nature of business"
          helperText={errors.natureOfBusiness?.message}
          labelProps={{
            className: errors.natureOfBusiness ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'intendedUseOfAccount',
            rules: rulesTextField('Intended use of Account'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Intended use of Account'}
          error={Boolean(errors.intendedUseOfAccount)}
          placeholder="Intended use of Account"
          helperText={errors.intendedUseOfAccount?.message}
          labelProps={{
            className: errors.intendedUseOfAccount ? classes.styleLabel : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'anticipatedTypesOfAssets',
            rules: rulesTextField('Anticipated Types of Assets'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Anticipated Types of Assets'}
          error={Boolean(errors.anticipatedTypesOfAssets)}
          placeholder="Anticipated Types of Assets"
          helperText={errors.anticipatedTypesOfAssets?.message}
          labelProps={{
            className: errors.anticipatedTypesOfAssets
              ? classes.styleLabel
              : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'anticipatedMonthlyVolume',
            rules: rulesTextField('Anticipated Monthly Volume'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Anticipated Monthly Volume'}
          error={Boolean(errors.anticipatedMonthlyVolume)}
          placeholder="Anticipated Monthly Volume"
          helperText={errors.anticipatedMonthlyVolume?.message}
          labelProps={{
            className: errors.anticipatedMonthlyVolume
              ? classes.styleLabel
              : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'anticipatedMonthlyTransactions',
            rules: rulesTextField('Anticipated Monthly Transactions'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Anticipated Monthly Transactions'}
          error={Boolean(errors.anticipatedMonthlyTransactions)}
          placeholder="Anticipated Monthly Transactions"
          helperText={errors.anticipatedMonthlyTransactions?.message}
          labelProps={{
            className: errors.anticipatedMonthlyTransactions
              ? classes.styleLabel
              : '',
          }}
        />
        <AppInput
          classNames={classes.inputItem}
          controller={{
            control,
            name: 'anticipatedTradingPatterns',
            rules: rulesTextField('Anticipated Trading Patterns'),
          }}
          inputProps={{ maxLength: MAX_LENGTH_INPUT }}
          label={'Anticipated Trading Patterns'}
          error={Boolean(errors.anticipatedTradingPatterns)}
          placeholder="i.e. Deposit USD, Buy BTC"
          helperText={errors.anticipatedTradingPatterns?.message}
          labelProps={{
            className: errors.anticipatedTradingPatterns
              ? classes.styleLabel
              : '',
          }}
        />
        <div className={classes.btnNext}>
          <AppButton fullWidth={true} onClick={() => setStep(1)}>
            Back
          </AppButton>
          <AppButton fullWidth={true} onClick={() => setStep(3)}>
            Next
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default Organization;

const checkLists = [
  'Limited Liability Company',
  'Corporation',
  'Partnership',
  'Other',
];
