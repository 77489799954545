import { Button, Container, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import useStyles from './styles';

const APIContent = (props: any) => {
  const classes = useStyles();

  const onClickToAPIDocument = () => {
    (window as any).location = process.env.REACT_APP_API_DOCUMENT;
  };

  return (
    <section className={classes.mainApi} id="api">
      <Container className={classes.contentAPI}>
        <div className={classes.mainCon}>
          <div data-aos="fade-right">
            <div className={classes.imgStyle}>
              <img src="/images/SettingsAPI.png" alt="" />
            </div>

            <div className={classes.imgStyleMobile}>
              <img src="/images/img-APIxs.png" alt="" />
            </div>
          </div>
          <div data-aos="fade-left">
            <div className={classes.rightAPI}>
              <Typography variant="h3" className={classes.titleAPI}>
                API or UI Trading
              </Typography>
              <p className={classes.textAPI}>
                Automate all your trade & settlement needs with a single API.
                Effortlessly integrate via our standardised REST or Websockets
                framework to level up your trading experience.
              </p>
              <p className={classes.textAPI}>
                You can also access all our trade and settlement services via
                our feature rich web based GUI. We offer a classic trading mode
                or a ‘1 click’ flash trading feature for rapid trade execution.
              </p>

              <Button
                variant="contained"
                className={classes.btnAPI}
                onClick={onClickToAPIDocument}
              >
                API documentation
              </Button>
            </div>
          </div>
        </div>
      </Container>

      <div className={classes.mainApiTab}>
        <div data-aos="fade-right">
          <div className={classes.styleTab}>
            <img src="/images/img-apiTab.png" alt="" />
          </div>
        </div>
        <div data-aos="fade-left">
          <div className={classes.rightAPI}>
            <Typography variant="h3" className={classes.titleAPI}>
              API or UI Trading
            </Typography>
            <p className={classes.textAPI}>
              Automate all your trade & settlement needs with a single API.
              Effortlessly integrate via our standardised REST or Websockets
              framework to level up your trading experience.
            </p>
            <p className={classes.textAPI}>
              You can also access all our trade and settlement services via our
              feature rich web based GUI. We offer a classic trading mode or a
              ‘1 click’ flash trading feature for rapid trade execution.
            </p>

            <Button
              variant="contained"
              className={classes.btnAPI}
              onClick={onClickToAPIDocument}
            >
              API documentation
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(APIContent);
