import { themeMode } from "../constants/theme-mode";
import { AnyAction } from "redux";

const initialState = {
  status: true,
};

export const themeModeReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case themeMode.CHANGE_THEME_MODE: {
      return {
        ...state,
        status: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
