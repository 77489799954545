import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Checkbox from '../../components/Base/Checkbox';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import clsx from 'clsx';
import useStyles from './styles';
import Radio from '../../components/Base/Radio';
import Toggle from '../../components/Base/Toggle';
import Select from '../../components/Base/Select';
import NewSelect from '../../components/Base/Select';
import AppButton from '../../components/Base/AppButton';
import AppInput from '../../components/Base/AppInput';
import AppPassword from '../../components/Base/AppPassword';
import AppPhone from '../../components/Base/AppPhone';
import Loading from 'src/components/Base/Loading';
import { useForm } from 'react-hook-form';
import AppRadioList from 'src/components/Base/AppRadioList';
import customToast from 'src/components/Base/Toast/Toast';

const options = Array.from(Array(10).keys()).map((el) => ({
  value: el,
  label: `Option ` + el,
}));

const ExamplePage = (props: any) => {
  const classes = useStyles();
  const [fullWidth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [label] = useState<boolean>(true);
  const [required] = useState<boolean>(false);
  useEffect(() => {
    document.title = 'Home';
  }, []);

  const {
    control,
    // setError,
    // clearErrors,
    // setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      test: '',
      select: 1,
      ac: '',
      date: '',
    },
  });

  const loadingPage = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };
  const [idType, setIdType] = useState('');

  const radioList = ['Yes', 'No'];

  return (
    <>
      {!isLoading && (
        <DefaultLayout>
          <div className={classes.title}>Components Example</div>
          <div className={classes.groupName}>Checkbox</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <Checkbox label="Normal" />
            <Checkbox defaultChecked={true} label="Default checked" />
            <Checkbox disabled label="Disabled" />
          </div>

          <div className={classes.groupName}>Radio</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <Radio label="Normal" name="radio1" />
            <Radio defaultChecked={true} label="Normal" name="radio1" />
            <Radio disabled label="Disabled" />
          </div>

          <div className={classes.groupName}>Toggle</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <Toggle label="Normal" />
            <Toggle label="Large" size="large" />
          </div>
          <AppRadioList
            label="ID type"
            value={idType}
            radioList={radioList}
            handleSelect={(val) => {
              setIdType(val);
            }}
          />
          <div className={classes.groupName}>Select</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <Select
              label="Normal"
              placeholder="Placeholder"
              options={options}
            />
            <Select
              label="Normal"
              placeholder="Placeholder"
              options={options}
              status="error"
              helperMsg="Error message"
            />
            <NewSelect
              label="Normal"
              placeholder="Placeholder"
              options={options}
            />
            <NewSelect
              label="Normal"
              placeholder="Placeholder"
              options={options}
              status="error"
              helperMsg="Error message"
            />
            <NewSelect
              label="Normal"
              placeholder="Placeholder"
              options={options}
            />
          </div>
          <div className={classes.groupName}>Button</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <AppButton
              isLoading={isLoadingButton}
              onClick={() => setIsLoadingButton(!isLoadingButton)}
              fullWidth={fullWidth}
            >
              Example button
            </AppButton>
            <AppButton
              fullWidth={fullWidth}
              isDisable={true}
              onClick={() => alert(123)}
            >
              Disable
            </AppButton>
          </div>
          <div className={classes.groupName}>Input</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <AppInput
              fullWidth={fullWidth}
              controller={{ control, name: 'test' }}
              required={required}
              label={label ? 'Name of your account' : ''}
              error={Boolean(errors.test)}
              placeholder="Example"
              helperText={errors.test?.message}
            />
            <AppInput
              fullWidth={fullWidth}
              controller={{ control, name: 'test' }}
              required={required}
              label={label ? 'Error' : ''}
              error={Boolean(true)}
              placeholder="Example"
              helperText={`Error`}
            />
            <AppPassword
              tabIndex={2}
              controller={{
                control,
                name: 'password',
              }}
              helperText={`message`}
              error={false}
              fullWidth={false}
              label="Enter Password"
              required
            />
          </div>
          <div className={classes.groupName}>Phone</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <AppPhone
              fullWidth={fullWidth}
              controller={{ control, name: 'test' }}
              required={required}
              label={label ? 'Phone number' : ''}
              error={Boolean(errors.test)}
              helperText={'Phone'}
              //onChange={(e) => onChangeInput(e)}
              defaultCountry={`vn`}
            />
          </div>
          <div className={classes.groupName}>Loading</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <AppButton
              isLoading={isLoading}
              onClick={() => loadingPage()}
              fullWidth={fullWidth}
            >
              Loading Page
            </AppButton>
          </div>
          <div className={classes.groupName}>Toast</div>
          <div className={clsx(classes.groupContainer, classes.flex)}>
            <AppButton
              onClick={() => {
                customToast.success('success');
              }}
            >
              Success
            </AppButton>
            <AppButton
              onClick={() => {
                customToast.error('error');
              }}
            >
              Error
            </AppButton>
            <AppButton
              onClick={() => {
                customToast.warning('warning');
              }}
            >
              Warning
            </AppButton>
            <AppButton
              onClick={() => {
                customToast.info('info');
              }}
            >
              Info
            </AppButton>
          </div>
        </DefaultLayout>
      )}

      {isLoading && <Loading />}
    </>
  );
};

export default withRouter(ExamplePage);
