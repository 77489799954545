import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from './routing/NotFoundPage';
import ErrorBoundary from './components/Base/ErrorBoundary';
import HomePage from './routing/HomePage';
import TradingPage from './routing/TradingPage';
import WalletPage from './routing/WalletPage';
import ReportsPage from './routing/ReportsPage';
import KYCPage from './routing/KYCPage';
import APIPage from './routing/APIPage';
import SecurityPage from './routing/SecurityPage';
import AddressBookPage from './routing/AddressBookPage';
import SupportPage from './routing/SupportPage';
import ExamplePage from './routing/ExamplePage';
import Authorize from './routing/Authorize';
import Component2FAForms from './routing/SecurityPage/Component2FAForms';
import AddAddress from './routing/AddressBookPage/AddAddress';
import Withdrawal from './routing/Withdrawal';
import ModalMessage from './components/ModalMessage';
import LoadingComponent from './components/Loading';
import ModalConfirmCode from './components/Base/ModalConfirmCode';
import { useAppSelector } from './hooks';
import PrivateRoute from './components/Base/PrivateRoute';

import LandingPage from './routing/LandingPage/HomePage';
import TeamPage from './routing/LandingPage/TeamPage';
import UsecasesPage from './routing/LandingPage/UsecasesPage';
import SignUpPage from './routing/LandingPage/SignUpPage';
import LoginPage from './routing/LandingPage/HomePage/LoginPage';
import VerifyPage from './routing/LandingPage/VerifyPage';
import ConfirmPage from './routing/LandingPage/ConfirmPage';
import VerifyEmailPage from './routing/LandingPage/VerifyEmailPage';
import ForgotPasswordPage from './routing/LandingPage//ForgotPasswordPage';
import NewPasswordPage from './routing/LandingPage//NewPasswordPage';
import PrivacyPage from './routing/LandingPage//PrivacyPage';
import TermsPage from './routing/LandingPage//TermsPage';
import ScrollToTop from './components/BaseLandingPage/ScrollToTop';
import { ThemeProvider } from '@material-ui/core/styles';
import defaultTheme from 'src/themes/DefaultTheme/DefaultTheme';

/**
 * Main App routes.
 */
const Routes = () => {
  const isLogged = useAppSelector((state) => state.auth.isLogged);

  return (
    <>
      <Switch>
        <Route exact path="/trade" component={HomePage} />
        <Route path={'/authorize'} component={Authorize} />
        <Route path={'/trading/:pair?'} component={TradingPage} />
        <Route path={'/wallet'} component={WalletPage} />
        <Route path={'/reports'} component={ReportsPage} />
        <Route path={'/2FA'} component={Component2FAForms} />
        <Route path={'/support'} component={SupportPage} />
        <Route path={'/example'} component={ExamplePage} />
        <Route path="/404" component={NotFoundPage} />
        <PrivateRoute path={'/setting/api'} component={APIPage} />
        <PrivateRoute path={'/setting/kyc'} component={KYCPage} />
        <PrivateRoute
          path={'/setting/address-book'}
          component={AddressBookPage}
        />
        <PrivateRoute path={'/setting/security'} component={SecurityPage} />
        <ThemeProvider theme={defaultTheme}>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/sign-up" component={SignUpPage} />
            <Route path="/forgot" component={ForgotPasswordPage} />
            <Route path="/reset" component={NewPasswordPage} />
            <Route path="/confirm/:mode/:email" component={ConfirmPage} />
            <Route path="/verify-email" component={VerifyEmailPage} />
            <Route path="/privacy" component={PrivacyPage} />
            <Route path="/verify/:mode" component={VerifyPage} />
            <Route path={'/team'} component={TeamPage} />
            <Route path={'/usecase'} component={UsecasesPage} />
            <Route path={'/term'} component={TermsPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </ThemeProvider>

        {/* Other... */}
        <Route component={NotFoundPage} />
      </Switch>

      {/* Components */}
      <LoadingComponent />
      <ModalMessage />
      {isLogged && (
        <>
          <AddAddress />
          <Withdrawal />
          <ModalConfirmCode />
        </>
      )}
    </>
  );
};

const routing = function createRouting() {
  return (
    <Router>
      <ScrollToTop />
      <ErrorBoundary>{Routes()}</ErrorBoundary>
    </Router>
  );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
