import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '42px', // paddingTop: 12 + line-height: 18 + paddingBottom: 12
    backgroundColor: 'none',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.custom.colors.white2}`,
    color: theme.custom.colors.white2,

    'label + &': {
      marginTop: '4px',
    },
    '&.Mui-error': {
      borderColor: theme.custom.colors.red,
    },
  },
  modeBack: {},
  input: {
    fontSize: 14,
    lineHeight: '18px',
    // currently, i just delay background-color changed when
    // autocomplete on chrome. it should be better.
    // god bless you
    '&:-webkit-autofill': {
      transitionDelay: '9999999s',
      transitionProperty: 'background-color, color',
    },
    padding: theme.spacing(1.5, 2),
    '&::placeholder': {
      color: theme.custom.colors.santaGray2,
      opacity: 1,
      fontWeight: 500,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  multiline: {
    padding: 0,
  },
  disableBox: {
    opacity: 0.7,
  },
  disabled: { color: `rgba(255, 255, 255, 0.8)` },
  // affect input if has start adorn
  inputAdornedStart: {
    paddingLeft: 0,
  },
  // affect input if has end adorn
  inputAdornedEnd: {
    paddingRight: 0,
  },
  adornedStart: {
    paddingLeft: 0,
  },
  // affect input if has end adorn
  adornedEnd: {
    paddingRight: 0,
  },
  focused: { borderColor: theme.custom.colors.lightPurple },
  error: { borderColor: theme.custom.colors.red2 },
  formControl: {
    '& label': {
      color: theme.custom.colors.white2,
      fontFamily: 'Ubuntu-Medium',
      fontSize: '14px',
      lineHeight: '19px',
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: theme.custom.colors.lightPurple,
      },
      '&&&.Mui-error': {
        color: theme.custom.colors.red,
      },
    },
    '& input': {
      fontFamily: 'Ubuntu-Regular',

      '&::placeholder': {
        color: theme.custom.colors.santaGray,
      },
    },
  },
  helperText: {
    color: theme.custom.colors.white2,
    fontFamily: 'Ubuntu-Regular',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    marginTop: '4px',
  },
  BoxInline: {
    flexDirection: 'row',
    display: 'flex',
  },
  BoxText: {
    display: 'flex',
    // alignItems: 'center !important',
    marginTop: '8px',
    '& span': {
      color: 'red',
      paddingLeft: 5,
    },
  },
  muiPhoneNumber: {
    '& .MuiInputLabel-animated': {
      color: theme.custom.colors.white2,
      fontFamily: 'Ubuntu-Regular',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '19px',
      position: 'unset',
    },
    '& .MuiInputBase-root': {
      marginTop: '4px',
      color: theme.custom.colors.white2,
      height: '42px',
      backgroundColor: 'none',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.custom.colors.santaGray}`,
    },
    '& .MuiInputBase-input': {
      borderLeft: `1px solid ${theme.custom.colors.white2}`,
      paddingLeft: '7px',
    },
    '& .MuiPhoneNumber-flagButton': {
      marginLeft: '10px',
    },
  },
}));

export default useStyles;
