import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    modalMain: {
      '& .modal-container': {
        padding: '22px 0 55px',
        '@media (max-width: 767px)': {
          padding: '22px 20px 15px',
        },
        '@media (max-width: 600px)': {
          maxWidth: '335px',
        },
        '& .body-modal-container': {
          padding: '0 118px',
          '@media (max-width: 1200px)': {
            padding: '10px 60px',
          },
          '@media (max-width: 767px)': {
            padding: '0 30px 15px',
          },
          '@media (max-width: 600px)': {
            padding: '0 10px',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },

        },
      },
    },
    formHeader: {
      marginBottom: 40,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
    },
    formTitle: {
      fontWeight: 700,
      fontSize: 20,
      width: '100%',
      textAlign: 'center',
      color: theme.custom.colors.titleTrading,

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    formDes: {
      fontSize: 16,
      fontFamily: 'Ubuntu-Light',
      width: '100%',
      textAlign: 'center',
      marginTop: 10,
      color: theme.custom.colors.colorDes,

      [theme.breakpoints.down('xs')]: {
        marginBottom: 30,
        fontSize: 14,
      },
    },
    formItem: {
      marginBottom: 20,
    },

    styleSubmit: {
      marginBottom: 35,
      [theme.breakpoints.down('xs')]: {
        '& > div > div': {
          width: '100% !important',
        },

        '& button': {
          width: '100% !important',
        },
      },
    },

    inputItem: {
      width: '100%',

      '& .Mui-error': {
        color: '#DE4949 !important',
      },
    },
    btnPrimary: {
      background: theme.custom.colors.btnEnable,
      borderRadius: '6px',
      color: theme.custom.colors.btnTitle,
      textTransform: 'none',
      fontSize: '12px',
      lineHeight: '14px',
      width: '126px',
      height: '36px',

      '&:hover': {
        background: theme.custom.colors.btnHover,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    btnInfo: {
      minWidth: 126,
      height: 36,
      borderRadius: 6,
      boxShadow: 'none',
      textTransform: 'none',
      fontFamily: 'Ubuntu-Bold',
      fontSize: 12,
      color: theme.custom.colors.white1,
      background: theme.custom.colors.lightBlue,
      marginRight: 20,

      '&:hover': {
        background: theme.custom.colors.cornflowerBlue,
      },

      '&:last-child': {
        marginRight: 0,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 90,
        marginRight: 12,
      },
    },
    btnPercents: {
      background: theme.custom.colors.bgBluGrey,
      borderRadius: 4,
      height: 36,
      minWidth: 63,
      padding: 3,
      fontSize: 12,
      color: '#FFFFFF',
      lineHeight: '14px',
      outline: 'none',
      boxShadow: 'none',
      paddingTop: 5,
      textTransform: 'inherit',

      '&:hover': {
        background: theme.custom.colors.bgBluGrey,
        boxShadow: 'none',
      },

      '&.active': {
        background: theme.custom.colors.cornflowerBlue,
      },

      '& + button': {
        marginLeft: 14,
      },

      [theme.breakpoints.down('xs')]: {
        '& + button': {
          marginLeft: 7,
        },

      },

    },
    styleCheck: {
      padding: 0,
      marginRight: 0,

      '& .MuiCheckbox-root': {
        padding: 12,
        marginRight: 0,
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    formControlLabel: {
      fontSize: 14,
      lineHeight: '140%',
      color: theme.custom.colors.boldGray,
      fontFamily: 'Ubuntu-Light',

      '&.MuiCheckbox-colorSecondary': {
        color: theme.custom.colors.cornflowerBlue,
      },

      '& .MuiIconButton-colorSecondary:hover': {
        background: 'none !important',
      },

      [theme.breakpoints.down('xs')]: {
        '& .MuiIconButton-colorSecondary': {
          paddingTop: '0px !important',
        },
      },

      '& .MuiFormControlLabel-label': {
        color: theme.custom.colors.white2,
        fontWeight: 400,
      },
    },

    styleLabel: {
      color: `${theme.custom.colors.red1}!important`,
    },
  };
});

export default useStyles;
