import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import configureStore from './store/configureStore';
import createRoutes from './routes';
import themeDarkMode from './themes/DefaultTheme/ThemeDarkMode';
import themeLightMode from './themes/DefaultTheme/ThemeLightMode';
import { ToastContainer } from 'react-toastify';
import * as AccountActions from 'src/store/actions/account';
import * as MarketActions from 'src/store/actions/market';

import 'react-toastify/dist/ReactToastify.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import 'aos/dist/aos.css';

import { AssetAction } from './store/actions/asset';
import { ChainAction } from './store/actions/chain';
import { AddressBookAction } from './store/actions/address';
import { getBalances, getBanks } from './store/actions/account';
import { LoadingAction } from './store/actions/loading';
import { AuthService } from './services/AuthService';
import { KEY } from './commons/constants';
import { WithdrawalAction } from './store/actions/withdrawal';
import { ModalConfirmAction } from './store/actions/modal-confirm';

const ThemeProviderCustomer: React.FC<any> = (props: any) => {
  const { persistor } = configureStore();
  const statusThemeMode = useSelector((state: any) => state.themeMode.status);
  const [themeMode, setThemeMode] = useState(statusThemeMode);
  const dispatch = useDispatch();

  // Hide modal when change hash (router)
  window.addEventListener(
    'hashchange',
    () => {
      dispatch(AddressBookAction.hideModalAdd());
      dispatch(WithdrawalAction.hide());
      dispatch(ModalConfirmAction.hide());
    },
    false
  );

  useEffect(() => {
    setThemeMode(statusThemeMode);
  }, [statusThemeMode]);

  useEffect(() => {
    const status = localStorage.getItem('themeMode') || true;
    setThemeMode(status === 'dark' ? false : true);
  }, []);

  const checkStatusMode = () => {
    const status = localStorage.getItem('themeMode') || true;
    setThemeMode(status === 'dark' ? false : true);
  };

  useEffect(() => {
    window.addEventListener('storage', checkStatusMode);
    // Loading
    dispatch(LoadingAction.show());

    // Refresh token
    (window as any)[KEY.REFRESH_TOKEN] = setInterval(async () => {
      const authService = AuthService.getInstance();
      const res = await authService.refreshToken();
      localStorage.setItem(KEY.ACCESS_TOKEN, res.data.access);
    }, process.env.REACT_APP_TIME_REFRESH as any); // 30s

    // Fetch data
    (async () => {
      Promise.all([
        dispatch(AssetAction.set()),
        dispatch(ChainAction.set()),
        dispatch(AddressBookAction.reload()),
        dispatch(getBalances()),
        dispatch(getBanks()),
        dispatch(AccountActions.has2FADevices()),
        dispatch(MarketActions.getAllPairs()),
        // dispatch(MarketActions.getAllLimit()),
      ]).finally(() => {
        // Hide loading
        dispatch(LoadingAction.hide());
      });
    })();

    return () => {
      window.removeEventListener('storage', checkStatusMode);
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={themeMode ? themeLightMode : themeDarkMode}>
      <PersistGate loading={null} persistor={persistor}>
        {createRoutes()}
      </PersistGate>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        theme={themeMode ? 'light' : 'dark'}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </ThemeProvider>
  );
};

export default ThemeProviderCustomer;
