import React, { useEffect, useRef, useState } from 'react';
import { MAX_FILE_SIZE } from 'src/commons/constants';
import AppButton from 'src/components/Base/AppButton';
import AppUpload from 'src/components/Base/AppUpload';
import { handleUploadDocument } from 'src/routing/KYCPage';
import useStyles from './styles';

const Documents = ({
  formData,
  lastErrors,
  setStep,
  saveData,
}: {
  formData: any;
  lastErrors?: any;
  setStep: (step: number, data?: any) => void;
  saveData: (data: any, errors?: any) => void;
}) => {
  const classes = useStyles();
  const initialData = [
    {
      label: 'Article of Association',
      name: '',
      value: '',
      msg: '',
    },
    {
      label: 'Certificate of Incorporation',
      name: '',
      value: '',
      msg: '',
    },
    {
      label: 'Business Registration',
      name: '',
      value: '',
      msg: '',
    },
    {
      label: 'Proof of Address for the entity',
      name: '',
      value: '',
      msg: '',
    },
    {
      label: 'Ownership Structure',
      name: '',
      value: '',
      msg: '',
    },
    {
      label: 'Registry of Shareholders',
      name: '',
      value: '',
      msg: '',
    },
    {
      label: 'Registry of Directors',
      name: '',
      value: '',
      msg: '',
    },
    {
      label: 'AML/KYC Policy',
      name: '',
      value: '',
      msg: '',
    },
  ];
  const [data, setData] = useState<any>(formData || initialData);
  const dataRef = useRef(data);

  const handleSetData = (val: any, _label: string, _index: number) => {
    const name = val ? val.target.files[0].name : '';
    const value = val ? val.target.files[0] : '';

    data[_index] = {
      label: _label,
      name,
      value,
    };

    setData([...data]);

    if (value) {
      // handle file size
      if (val.target.files[0].size > MAX_FILE_SIZE) {
        data[_index] = {
          ...data[_index],
          msg: 'Maximum file size is 10 MB. Please choose another file',
        };
        setData([...data]);
        return;
      }

      handleUploadDocument(data[_index])
        .then((res: any) => {})
        .catch((err: any) => {
          if (err?.response?.status === 413) {
            data[_index] = {
              ...data[_index],
              msg: 'Maximum file size is 10 MB. Please choose another file',
            };
          } else if (err?.response?.status === 400) {
            data[_index] = {
              ...data[_index],
              msg: 'This file type is not supported. Please choose another file',
            };
          } else {
            data[_index] = {
              ...data[_index],
              msg: 'The file is corrupt. Please choose another file',
            };
          }
          setData([...data]);
        });
    }
  };

  const checkUpload = (data: any) => {
    return data.findIndex((item: any) => !!item.msg || !item.value) === -1;
  };

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    return () => {
      const checkUploaded = checkUpload(dataRef.current);
      saveData(
        { documents: dataRef.current },
        {
          documents: !checkUploaded,
        }
      );
    };
  }, [saveData]);

  return (
    <div className={classes.stepContainer}>
      <div className={classes.title}>Documents</div>
      <div className={classes.inputContainer}>
        {data.map((item: any, index: number) => {
          return (
            <AppUpload
              key={index}
              id={index}
              className={classes.fileUpload}
              label={item.label}
              value={item}
              handleSetValue={(e) => {
                handleSetData(e, item.label, index);
              }}
            />
          );
        })}
      </div>
      <div className={classes.btnNext}>
        <AppButton
          fullWidth={true}
          isDisable={false}
          onClick={() => setStep(2)}
        >
          Back
        </AppButton>
        <AppButton
          fullWidth={true}
          // isDisable={disableBtn}
          onClick={() => setStep(4)}
        >
          Next
        </AppButton>
      </div>
    </div>
  );
};

export default Documents;
