import { Hidden } from '@material-ui/core';
import TradingEntry from './TradingEntry';
import BalanceSnapshot from './BalanceSnapshot';
import useStyles from './styles';

const ComponentTradingEntry = (props: any) => {
  const styles = useStyles();
  return (
    <div className={styles.componentTradingEntry}>
      <TradingEntry
        handleRefreshOrder={props.handleRefreshOrder}
        symbol={props?.symbol}
      />
      <Hidden xsDown>
        <BalanceSnapshot symbol={props?.symbol} />
      </Hidden>
    </div>
  );
};

export default ComponentTradingEntry;
