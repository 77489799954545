/* eslint-disable no-self-assign */
import { HTTPBaseService } from "src/request/HTTPBaseService";

export class LandingService extends HTTPBaseService {

    private static classInstance?: LandingService;
    public static getInstance(token: string) {
        if (!this.classInstance) {
            this.classInstance = new LandingService();
        }
        return this.classInstance;
    }

    public async postData(url: string = '', bodyData: object | null = {}, headers?: HeadersInit) {
        const body = bodyData ? JSON.stringify(bodyData) : null;
        return await this.instance.post(url, body, {
            transformRequest: [(data: any, headers: any) => {
                headers['x-requested-with'] = '';
                delete headers.Authorization;
                return data
            }]
        })
    }

    public async sendEmailVerify(url: string = '', bodyData: object | null = {}, headerData?: any) {
        const body = bodyData ? JSON.stringify(bodyData) : null;
        return await this.instance.post(url, body, {
            transformRequest: [(data: any, headers: any) => {
                headers['Authorization'] = headerData['Authorization'];
                return data
            }]
        });
    }
}

// export instance of kyc service
export const landingService = new LandingService();
