import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    componentRecentOrders: {
      height: '100%',
      '& .MuiDataGrid-main': {
        paddingBottom: 10,
      },
    },

    footer: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiDataGrid-root': {
          '& .MuiDataGrid-footerContainer': {
            display: 'flex',
          },
          '& .MuiDataGrid-windowContainer': {
            minHeight: '60px !important',
          },
        },
      },
    },

    titleTable: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 18,
      lineHeight: '140%',
      color: theme.custom.colors.white3,
      margin: 0,
    },

    btnCanceled: {
      background: 'rgba(222, 73, 73, 0.25)',
      border: '1px solid #DE4949',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 50,
      height: 29,
      minWidth: 87,
      fontSize: 12,
      color: theme.custom.colors.clCanceled,
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',
    },

    btnConfirm: {
      background: 'rgba(76, 162, 80, 0.25)',
      border: ' 1px solid #4CA250',
      borderRadius: 50,
      height: 29,
      minWidth: 87,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '10px',
      fontSize: 12,
      color: theme.custom.colors.colorStatus,
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'capitalize',
      fontWeight: 400,
    },
    breakSpaces: {
      whiteSpace: 'break-spaces',
      lineHeight: '20px',
    },
  };
});

export default useStyles;
