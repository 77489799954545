import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    reportsPage: {
      padding: '20px 0',
      '& .body-modal-container': {
        maxHeight: '100% !important',
        minHeight: '100% !important',
      },
    },

    tabComponent: {
      borderBottom: `1px solid ${theme.custom.colors.comet4}`,
      padding: 0,
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
    },

    itemTabComponent: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 18,
      lineHeight: '140%',
      color: '#9BA1B1',
      padding: '10px 0',
      cursor: 'pointer',
      borderBottom: '2px solid transparent',
      marginRight: 40,
      marginBottom: -1,

      '&:last-child': {
        marginRight: 0,
      },
    },

    activeTab: {
      borderBottom: `2px solid ${theme.custom.colors.cornflowerBlue}`,
      color: theme.custom.colors.activeTab,
    },

    // For child tabs

    root: {
      marginTop: 40,

      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
      },
    },

    headPage: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 30,
    },

    left: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    right: {},

    boxDatePicker: {
      marginRight: 20,
      width: 220,
      maxWidth: '100%',
      position: 'relative',
      fontFamily: 'Ubuntu-Regular',

      [theme.breakpoints.down('sm')]: {
        width: 167,
      },

      '$mobileFilter &': {
        width: '100%',
        marginTop: 20,
      },

      '& .react-datepicker__close-icon::after': {
        backgroundColor: theme.custom.colors.cornflowerBlue,
      },
      '& .react-datepicker__day--in-range': {
        backgroundColor: theme.custom.colors.cornflowerBlue,
      },

      '& .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected':
        {
          backgroundColor: '#B8A7FF',
        },
    },

    dropdownBoxReport: {
      marginRight: 20,
      width: 213,
      maxWidth: '100%',

      [theme.breakpoints.down('sm')]: {
        width: 167,
      },

      '$mobileFilter &': {
        width: '100%',
        marginTop: 20,
      },
    },

    selectInput: {
      '& > span': {
        color: theme.custom.colors.white2,
        fontSize: 14,
      },
    },

    btnDownload: {
      minHeight: 50,
      minWidth: 213,

      [theme.breakpoints.between('601', '768')]: {
        marginTop: 10,
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: 167,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 157,
      },
    },

    btnFilters: {
      fontFamily: 'Ubuntu-Bold',
      background: '#586894',
      borderRadius: 6,
      minHeight: 50,
      minWidth: 213,
      maxWidth: '100%',
      fontSize: 14,
      color: '#FFFFFF',
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'inherit',

      '&:hover': {
        background: '#586894',
        boxShadow: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: 167,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 157,
      },
    },

    time: {
      display: 'flex',
      lineHeight: '20px',
    },

    breakSpaces: {
      whiteSpace: 'break-spaces',
      lineHeight: '20px',
    },

    symbol: {
      display: 'flex',
      lineHeight: '20px',
      alignItems: 'center',
    },

    assetName: { fontSize: 12 },

    symbolInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    iconCoin: {
      width: 35,
      height: 35,
      marginRight: 10,
    },

    status: {
      padding: '0 10px',
      minWidth: 87,
      height: 29,
      borderRadius: 50,
      boxShadow: 'none',
      fontSize: 12,
      lineHeight: '29px',
      textAlign: 'center',
      border: '1px solid',
      borderColor: theme.custom.colors.white2,

      '&:last-child': {
        marginRight: 0,
      },
    },

    statusConfirmed: {
      background: 'rgba(76, 162, 80, 0.25)',
      borderColor: '#4CA250',
      color: theme.custom.colors.statusConfirmed,
    },
    statusFilled: {
      background: 'rgba(127, 99, 244, 0.25)',
      borderColor: theme.custom.colors.cornflowerBlue,
      color: theme.custom.colors.statusConfirmed,
    },
    statusCancelled: {
      background: 'rgba(222, 73, 73, 0.25)',
      borderColor: '#DE4949',
      color: theme.custom.colors.statusCancelled,
    },

    capitalized: {
      textTransform: 'capitalize',
    },

    mobileFilter: {
      '& .modal-container': {
        paddingBottom: 30,
        overflow: 'visible',

        '& .body-modal-container': {
          maxHeight: '100% !important',
          minHeight: '100% !important',
          overflow: 'visible',
        },
      },
    },
    closeBtn: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
      '& > div': {
        width: 126,
      },
    },

    modalTitle: {
      marginTop: 30,
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center',
    },
  };
});

export default useStyles;
