import { HTTPBaseService } from "src/request/HTTPBaseService";
export class ChainService extends HTTPBaseService {
  private static classInstance?: ChainService;

  public static getInstance(token?: string) {
    if (!this.classInstance) {
      this.classInstance = new ChainService();
    }
    return this.classInstance;
  }

  public async getChain() {
    return await this.instance.get("/chains/");
  }
}
export const chainService = new ChainService();
