import { Container } from '@material-ui/core';
import Common from 'src/components/BaseLandingPage/Common';
import useStyles from './styles';
import ForgotComponent from './ForgotComponent';
const ForgotPasswordPage = () => {
  const style = useStyles();

  return (
    <Common>
      <Container className={style.main}>
        <ForgotComponent />
      </Container>
    </Common>
  );
};
export default ForgotPasswordPage;
