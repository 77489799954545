import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainModalNewKey: {
        [theme.breakpoints.down('md')]: {
          margin:'20px 0'
        },
         [theme.breakpoints.down('sm')]: {
          margin:'20px 0'
        },
      
         [theme.breakpoints.down('xs')]: {
          margin:'20px 0'
        },
    },

    mainModal: {
      fontFamily: 'Ubuntu-Regular',
    },

    titleModal: {
      fontSize: '20px',
      lineHeight: '23px',
      margin: '0 0 30px',
    },

    mainForm: { position: 'relative' },

    styleForm: {
      position: 'relative',
    },

    styleLabel: {
      display: 'block',
      fontWeight: 500,
      fontSize: '14px',
      marginBottom: 7,
      fontFamily: 'Ubuntu-Medium',
      color: theme.custom.colors.boldGray,
    },

    styleInput: {
      position: 'relative',
      border: `1px solid ${theme.custom.colors.colorBorder}`,
      borderRadius: '6px',
      height: 50,
      padding: '13px 45px 13px 16px',
      color: theme.custom.colors.boldGray,
      fontSize: 14,
      lineHeight: '24px',
      fontFamily: 'Ubuntu-Regular',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    rectangle: {
      height: 33,
      width: 1,
      marginRight: 10,
    },

    lblWaring: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: '12px',
      lineHeight: '140%',
      color: theme.custom.colors.colorWarning,
    },

    btnICon: {
      position: 'absolute',
      right: 0,
      top: 8,

      '& img': {
        color: theme.custom.colors.boldGray,
      },
      '&:hover': {
        background: 'transparent',
      },

      '& .icon-copy2:before': {
        color: theme.custom.colors.titleTrading,
        fontSize: '19px',
      },
      '& .MuiTouchRipple-root': {
        display:'none'
      }
    },

    warning: {
      fontSize: '14px',
      lineHeight: '140%',
      fontFamily: 'Ubuntu-Regular',
      color: '#fff',
      background: '#7F63F4',
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      height: 70,
      padding: '0 20px',

      '& img': {
        marginRight: 8,
      },
    },

    titleLabel: {
      marginRight: 10,
      fontSize: 18,
    },

    boxLabel: {
      color: theme.custom.colors.white3,
      marginTop: 30,

      '& span': {
        fontFamily: 'Ubuntu-Regular',
        wordBreak: 'break-all'
      },
    },

    boxLabel2: {
      marginBottom: 30,
      marginTop: 0,
    },

    divBtn: {
      paddingTop: 16,
    },

    btnSubmit: {
      background: theme.custom.colors.btnEnable,
      borderRadius: 6,
      height: 50,
      width: 217,
      fontSize: 14,
      fontWeight: 700,
      color: theme.custom.colors.btnTitle,
      textTransform: 'none',
      marginRight: 10,
      boxShadow: 'none',

      '&:hover': {
        background: theme.custom.colors.btnHover,
      },
    },
  };
});

export default useStyles;
