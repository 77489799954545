import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    styleBank: {
      '& .modal-container': {
        '@media (max-width: 767px)': {
          maxWidth: '545px',
          paddingTop: '13px',

        },
        '@media (max-width: 600px)': {
          width: '335px',
          paddingTop: '13px',
        },
        '& .body-modal-container': {
          padding: '0 118px',
          marginRight: 3,
          [theme.breakpoints.down('md')]: {
            padding: "0 60px",
            maxHeight: '70vh',
          },
          [theme.breakpoints.down('sm')]: {
            padding: "0 20px",
          },
          '@media (max-width: 767px)': {
            padding: '0 30px',
          },
          [theme.breakpoints.down('xs')]: {
            padding: "0",
            maxHeight: '70vh',
            minHeight: '70vh',
          },

        },
      },
    },

    title: {
      fontWeight: 700,
      fontSize: 20,
      color: theme.custom.colors.titleTrading,
    },

    titleDetail: {
      fontWeight: 700,
      fontSize: 20,
      color: theme.custom.colors.titleTrading,
      marginBottom: 20
    },
    styleRadio: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: 14,
    },
    row: {
      marginBottom: 30,
    },
    rowTitle: {
      fontWeight: 500,
      fontSize: 14,
      marginBottom: 17,
    },
    rowBody: {
      display: 'flex',
    },
    radioBlock: {
      marginBottom: 14,
    },
    rowContent: {
      maxHeight: '65vh',
      overflowY: 'auto',
      padding: '0 118px',
      [theme.breakpoints.down('md')]: {
        padding: "0 60px",
      },
      [theme.breakpoints.down('sm')]: {
        padding: "0 20px",
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: '61vh',
        padding: "0 10px",
      },
    },
    rowBtn: {
      '& > div + div': {
        marginLeft: 14,
      },

      [theme.breakpoints.down('xs')]: {
        '& > div + button': {
          minWidth: 136,
        },
        margin: 0,
      },
    },

    styleLabel: {
      color: `${theme.custom.colors.red1}!important`,
    },

  };
});

export default useStyles;
