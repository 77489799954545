import { useEffect, useState } from 'react';
import { Checkbox, Container, Typography } from '@material-ui/core';
import { withRouter, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';

import { PAGE } from 'src/utils/utils';
import { landingService } from 'src/services/LandingService';
import { REGEX_USERNAME, REGEX } from 'src/utils/validation';

import InputField from 'src/components/BaseLandingPage/Common/Input';
import AppPhone from 'src/components/Base/AppPhone';
import ButtonCommon from 'src/components/BaseLandingPage/Common/Button';
import { MAX_LENGTH } from 'src/utils/validation';
import ModalThanks from '../Mainhome-1/ModalThanks';

import useStyles from './styles';

interface IForm {
  name: string;
  email: string;
  term: boolean;
  phone: string;
  company: string;
  message: string;
  token: string;
}

const Request = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const methodsForm = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      message: 'Request a demo',
    },
  });
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
    setValue,
    getValues,
    reset,
  } = methodsForm;

  const [isLoading, setLoading] = useState(false);
  const onSubmit = (data: any) => {
    // remove phone field when blank
    if (data.hasOwnProperty('phone') && !data.phone) delete data.phone;

    // Loading
    setLoading(true);
    landingService
      .postData('/contact/post_message/', data)
      .then((res) => {
        reset();
        setValue('term', false);
        setOpen(true);
        setLoading(false);
      })
      .catch((err: any) => {
        (window as any)?.grecaptcha?.reset();
        setLoading(false);
      });
  };

  const onChangeCAPTCHA = (value: string | null) => {
    setValue('token', value || '', {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    register('token', { required: true });
  }, [register]);

  return (
    <section className={classes.mainRequest}>
      <ModalThanks open={[open, setOpen]} />
      <Container className={classes.conRequest}>
        <div className={classes.detailRequest}>
          <div className={classes.leftRequest}>
            <img
              src="/images/icon-phone.png"
              alt=""
              className={classes.stylePhone}
            />
            <img
              src="/images/mobile-xs.png"
              alt=""
              className={classes.phoneMobile}
            />
          </div>

          <div className={classes.formRequets}>
            <Typography variant="h4" className={classes.titleForm}>
              Contact Us form
            </Typography>

            <form
              className={classes.styleForm}
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputField
                {...methodsForm}
                name="name"
                label="Name"
                maxLength={MAX_LENGTH.USER_NAME}
                validation={{
                  required: true,
                  pattern: {
                    value: REGEX_USERNAME,
                    message:
                      'Allowed characters: A-Z, a-z, apostrophe, space, dash',
                  },
                }}
              />
              <InputField
                {...methodsForm}
                type="email"
                name="email"
                label="Сompany email"
                validation={{ required: true }}
              />
              <InputField
                {...methodsForm}
                type="text"
                name="company"
                label="Сompany"
                validation={{
                  required: true,
                  maxLength: 65,
                }}
                maxLength={MAX_LENGTH.COMPANY}
              />

              <AppPhone
                classNames={classes.inputItem}
                label="Phone Number"
                controller={{
                  control,
                  name: 'phone',
                  rules: REGEX.phoneNumber,
                }}
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message}
                labelProps={{
                  className: errors.phone ? classes.styleLabel : '',
                }}
                textPlaceholder={getValues('phone') ? '' : '| __-___-__-__'}
                inputClass={`${classes.inputPhoneNum}`}
              />
              <InputField
                {...methodsForm}
                type="text"
                name="message"
                label="Message"
                validation={{ required: true }}
                maxLength={MAX_LENGTH.MESSAGE}
              />

              <div className={classes.checkStyle}>
                <Checkbox
                  {...methodsForm}
                  {...register('term', {
                    required: true,
                  })}
                  id="term"
                  name="term"
                  className={classes.styleCheck}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  inputProps={{ 'aria-label': 'decorative checkbox' }}
                  {...props}
                />
                <label
                  className={
                    classes.styleLbl +
                    ' ' +
                    (errors.term ? classes.styleLblError : '')
                  }
                  htmlFor="term"
                >
                  I agree to oneAlpha’s{' '}
                  <span onClick={() => history.push(PAGE.TERM)}>
                    Terms & Conditions
                  </span>{' '}
                  and{' '}
                  <span onClick={() => history.push(PAGE.PRIVACY)}>
                    Privacy Policy
                  </span>
                </label>
              </div>
              <div className={classes.captcha}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA || ''}
                  onChange={onChangeCAPTCHA}
                />
              </div>

              <div className={classes.divBtn}>
                <ButtonCommon
                  className={classes.btnSubmit}
                  type="submit"
                  disabled={!isDirty || !isValid}
                  isLoading={isLoading}
                >
                  Submit
                </ButtonCommon>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default withRouter(Request);
