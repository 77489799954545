import { useMemo, useState } from 'react';
import {
  Button,
  Hidden,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import useStyles from './styles';
import TableStyle from '../../../styles/TableStyle';
import ModalDelete from '../ModalDelete';
import { ApiKey } from 'src/services/ApiKeyService';

const KeyList = (props: any) => {
  const styles = useStyles();
  const tableStyle = TableStyle();
  const [pageSize] = useState(6);
  const [rowHeight] = useState(70);
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentKey, setCurrentKey] = useState<ApiKey | null>();

  const columns: GridColDef[] = useMemo(() => {
    const renderIconCell = (params: GridValueGetterParams) => {
      return params.value ? (
        <img className={styles.icon} src="/images/icons/icon-ok.svg" alt="" />
      ) : (
        <img
          className={styles.icon}
          src="/images/icons/icon-cancel.svg"
          alt=""
        />
      );
    };

    return [
      {
        field: 'label',
        headerName: 'Label',
        minWidth: matchUpMd ? 30 : 150,
        flex: 0.13,
        sortable: false,
        renderCell: (params: GridValueGetterParams) => (
          <Tooltip title={params.row.label}>
            <span className={styles.breakSpaces}>
              {params.getValue(params.id, 'label')}
            </span>
          </Tooltip>
        ),
      },
      {
        field: 'prefix',
        headerName: 'Prefix',
        minWidth: matchUpMd ? 30 : 120,
        flex: 0.13,
        sortable: false,
        renderCell: (params: GridValueGetterParams) => (
          <span className={styles.breakSpaces}>{params.value}</span>
        ),
      },
      {
        field: 'read_only',
        headerName: 'Read data',
        minWidth: 165,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: renderIconCell,
      },
      {
        field: 'change_account_data',
        headerName: 'Modify account data',
        minWidth: 165,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: renderIconCell,
      },
      {
        field: 'allow_trading',
        headerName: 'Allow trading',
        minWidth: 165,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: renderIconCell,
      },
      {
        field: 'allow_withdrawals',
        headerName: 'Allow withdrawals',
        minWidth: 165,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: renderIconCell,
      },
      {
        field: 'ip_addresses',
        headerName: 'IP addresses',
        minWidth: matchUpMd ? 50 : 180,
        flex: 0.13,
        sortable: false,
        renderCell: (params: GridValueGetterParams) => (
          <>
            <Tooltip
              title={`${params.value?.toString().split(',').join(' ')}`}
              classes={{
                tooltip: styles.tooltip,
              }}
            >
              <span className={styles.groupIP}>
                {params.value
                  ?.toString()
                  .split(',')
                  .map((el) => (
                    <span className={styles.ipAddress} key={el}>{el}</span>
                  ))}
              </span>
            </Tooltip>
          </>
        ),
      },
      {
        field: ' ',
        headerName: '',
        minWidth: matchUpXl ? 70 : 160,
        flex: 0.13,
        sortable: false,
        renderHeader: () => (
          <Hidden xsDown>
            <div className={styles.containerAdd}>
              <Button
                className={styles.btnAdd}
                onClick={() => props.setModalAddKey(true)}
              >
                Add
              </Button>
            </div>
          </Hidden>
        ),
        renderCell: (params: GridValueGetterParams) => (
          <>
            <div className={styles.containerAdd}>
              <Button
                variant="contained"
                className={styles.button}
                onClick={() => props.setModalAddKey(true, params.row)}
              >
                <img
                  className={styles.iconBtn}
                  src="/images/icons-pen.svg"
                  alt=""
                />
              </Button>

              <Button
                variant="contained"
                className={styles.button}
                onClick={() => {
                  setCurrentKey(params.row as ApiKey);
                  setIsOpenDelete(true);
                }}
              >
                <img
                  className={styles.iconBtn}
                  src="/images/icons-trash.svg"
                  alt=""
                />
              </Button>
            </div>
          </>
        ),
      },
    ];
  }, [matchUpMd, matchUpXl, styles, props]);

  return (
    <>
      {props.keyList?.length > 0 && (
        <section className={styles.componentMid}>
          <div className={styles.title}>
            <Typography variant="h3" className={styles.titleList}>
              API keys list
            </Typography>
            <Hidden smUp>
              <Button
                className={styles.btnMobile}
                onClick={() => props.setModalAddKey(true)}
              >
                Add
              </Button>
            </Hidden>
          </div>

          <div
            className={tableStyle.customerDataGrid}
            style={{
              height: `${60 + Number(props.keyList.length) * rowHeight + 16}px`,
              maxHeight: `${60 + pageSize * rowHeight + 16}px`,
            }}
          >
            <DataGrid
              rows={props.keyList}
              columns={columns}
              pageSize={pageSize}
              disableSelectionOnClick
              disableColumnMenu
              headerHeight={60}
              rowHeight={rowHeight}
              hideFooter
            />
          </div>
          <ModalDelete
            currentKey={currentKey}
            isOpen={isOpenDelete}
            handleClose={() => setIsOpenDelete(false)}
            onSubmitted={props.onSubmitted}
          />
        </section>
      )}
    </>
  );
};

export default KeyList;
