import { makeStyles, StepConnector, withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    kycContainer: {
      fontFamily: 'Ubuntu-bold',
      display: 'flex',
      justifyContent: 'center',

      '& .MuiPaper-root': {
        background: 'none',
        padding: '20px 0',

        '& .MuiStep-horizontal': {
          width: '100px',
          padding: 0,
        },
      },

      '& .MuiSvgIcon-root': {
        width: '40px',
        height: '40px',
      },

      '& .MuiStepLabel-iconContainer': {
        width: '40px',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #9BA1B1',
        borderRadius: '50%',
        color: '#9BA1B1',
        fontSize: '20px',
        lineHeight: '23px',
        cursor: 'pointer',

        [theme.breakpoints.down('xs')]: {
          cursor: 'unset',
        },
      },

      '& .MuiStepLabel-label': {
        color: theme.custom.colors.white2,
        fontFamily: 'Ubuntu-regular',
      },
      '& .icon-checkmark:before': {
        color: 'inherit',
      },
    },

    activedItems: {
      '& .MuiStepLabel-label .MuiStepLabel-active': {
        color: theme.custom.colors.cornflowerBlue,
      },

      '& .MuiStepLabel-iconContainer': {
        border: 'none',
        background: theme.custom.colors.cornflowerBlue,
        color: 'white',
      },

      '& .icon-checkmark': {
        fontWeight: 'bold',
      },
    },

    styleStepKYC: {
      color: theme.custom.colors.white2,
      fontFamily: 'Ubuntu-regular',
      fontWeight: 500,
    },

    activedLabel: {
      color: theme.custom.colors.colorStep,
      fontWeight: 500,
    },

    declineLabel: {
      color: theme.custom.colors.red2,
      fontWeight: 500,
    },

    declineItems: {
      '& .MuiStepLabel-iconContainer': {
        background: theme.custom.colors.red2,
      },
    },
    styleLine: {},
    declineLine: {
      '& $line': {
        borderColor: '#9BA1B1',
      },

    },

  };
});

export default useStyles;

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 20,
    // left: "calc(-50% + 20px)",
    // right: "calc(50% + 20px)",
  },
  active: {
    '& $line': {
      borderColor: '#9BA1B1',
    },

  },
  completed: {
    '& $line': {
      borderColor: '#7F63F4',
    },
  },
  line: {
    border: '2px dashed #9BA1B1',
    borderBottom: 'none',
  },
  declineLine: {
    '& $line': {
      borderColor: '#9BA1B1',
    },

  },
})(StepConnector);
