import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
    return {
        mainModalDelete: {
            "& .modal-container": {
                width: 454,

                "& .body-modal-container": {
                    padding: "0 70px",
                    maxHeight: '100%',
                    minHeight: '100%',


                    [theme.breakpoints.down('sm')]: {
                        padding: "0 30px",
                    },


                    [theme.breakpoints.down('md')]: {
                        paddingBottom: 30,
                    },
                },

                [theme.breakpoints.down('sm')]: {
                    width: 375
                },

                [theme.breakpoints.down('xs')]: {
                    width: 340
                },
            },
        },

        mainModal: {
        },

        titlePopUp: {
            textAlign: "center",
        },

        styleTitle: {
            fontSize: "20px",
            lineHeight: "23px",
            color: theme.custom.colors.boldGray,
            margin: "20px 0",
        },

        textConfirm: {
            fontSize: "14px",
            lineHeight: "140%",
            marginBottom: 30,
            color: "#9BA1B1",
        },

        boxBtnConfirm: {
            display: 'flex',
            justifyContent: 'center'
        },

        btnCancel: {
            width: "126px",
            height: "36px",
            borderRadius: "6px",
            color: "#EBECF0",
            background: theme.custom.colors.btnCancle,
            textTransform: "none",
            marginRight: 20,
            fontSize: 12,
            fontFamily: 'Ubuntu-Regular',
            fontWeight: 'bold',
            "&:hover": {
                background: theme.custom.colors.btnEnable,
            },
        },

        delete: {
            background: theme.custom.colors.btnEnable,
            borderRadius: 6,
            height: 36,
            width: 126,
            fontSize: 12,
            fontWeight: 700,
            color: theme.custom.colors.btnTitle,
            textTransform: "none",
            marginRight: 10,
            boxShadow: "none",

            "&:hover": {
                background: theme.custom.colors.btnHover,
            },
        },
    };
});

export default useStyles;