import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import Checkbox from 'src/components/Base/Checkbox';
import ModalCustom from 'src/components/Base/ModalCustom';
import AppButton from 'src/components/Base/AppButton';
import {
  WithdrawalResponse,
  WithdrawalService,
} from 'src/services/WithdrawalService';
import { WithdrawalAction } from 'src/store/actions/withdrawal';
import BankAccount from '../BankAccount';

import useStyles from './styles';
import { hideModalMessage, showModalMessage } from 'src/store/actions/message';
// import { LoadingAction } from 'src/store/actions/loading';
import { getBalances } from 'src/store/actions/account';
import { amountWithdrawal } from 'src/utils/validation';
import { AxiosResponse } from 'axios';
import { ModalConfirmAction } from 'src/store/actions/modal-confirm';
import { useAppSelector } from 'src/hooks';
import AppInput from 'src/components/Base/AppInput';

interface IForm {
  client_transaction_id: string;
  withdrawal_address: string;
  account_number?: string;
  routing_number?: string;
  amount: string;
  asset: string;
  term: boolean;
  code: string;
  two_fa: string;
}

export default function WithdrawalBank() {
  const classes = useStyles();
  const { show, onSuccess } = useAppSelector((state) => state.withdrawal);
  const assetSelected = useSelector((state: any) => state.asset.assetSelected);
  const balances = useSelector((state: any) => state.accountReducer.balances);
  const banks = useSelector((state: any) => state.accountReducer.banks);
  const has2FA = useSelector(
    (state: any) => state.accountReducer.has2FADevices
  );
  const showModalConfirmCode = useSelector(
    (state: any) => state.modalConfirm.show
  );
  const isLoading = useSelector((state: any) => state.modalConfirm.isLoading);
  const dispatch = useDispatch();
  const [bankSelected, setBankSelected] = useState<any>(null);
  const [flgAddBank, setFlgAddBank] = useState<boolean>(false);
  const [percents, setPercents] = useState<number | null>(null);
  let assetBalance = 0;
  balances.forEach((asset: any) => {
    if (asset.asset === assetSelected) {
      assetBalance = asset.free;
    }
  });

  const {
    control,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    getValues,
    register,
  } = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      withdrawal_address: '',
      term: false,
    },
  });

  useEffect(() => {
    setValue('code', '');
    setValue('client_transaction_id', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalConfirmCode]);

  useEffect(() => {
    register('term', { required: true });
  }, [register, show]);

  useEffect(() => {
    if (show) {
      setValue('asset', assetSelected, {
        shouldValidate: true,
      });
    } else {
      reset();
      setPercents(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  // Reset term when change bank
  useEffect(() => {
    if (!bankSelected) {
      setValue('term', false, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankSelected]);

  const onSubmitWithdrawal = async (data: IForm) => {
    // Loading
    dispatch(ModalConfirmAction.loading());
    const withdrawalService = WithdrawalService.getInstance();

    try {
      let res: AxiosResponse<WithdrawalResponse>;
      if (!data.client_transaction_id) {
        // Auto-generation id
        data.client_transaction_id = uuidv4();
        // Call API
        res = await withdrawalService.create(data);
      } else {
        res = await withdrawalService.confirm(data);
      }

      // Checking response
      if (res.data.client_transaction_id === data.client_transaction_id) {
        checkTypeWithdraw(res.data);
        // Update balances
        dispatch(getBalances());
      }
    } catch (error) {
      dispatch(
        showModalMessage({
          type: 'ERROR',
          msg: 'System error!',
        })
      );
    } finally {
      // hide loading
      dispatch(ModalConfirmAction.hideLoading());
    }
  };

  const checkTypeWithdraw = (data: any) => {
    if (data.state === 'PENDING APPROVAL') {
      dispatch(
        ModalConfirmAction.show({
          msgError: getValues('code')
            ? has2FA
              ? 'Email or 2FA code is incorrect. Please check and try again.'
              : 'The email code is incorrect. Please check and try again.'
            : '',
          onOK: (code: string, twoFA: string) => {
            setValue('code', code);
            setValue('two_fa', twoFA);
            setValue('client_transaction_id', data.client_transaction_id);
            onSubmitWithdrawal(getValues());
            dispatch(ModalConfirmAction.loading());
          },
        })
      );
    } else {
      // Update balances
      dispatch(getBalances());
      // Hide modal
      dispatch(ModalConfirmAction.hide());
      dispatch(WithdrawalAction.toggle());
      // Show success
      dispatch(
        showModalMessage({
          type: 'SUCCESS',
          msg: 'Withdrawal successfully',
          onOk: () => {
            // Hide all message, modal
            dispatch(hideModalMessage());
          },
        })
      );
      // callback
      onSuccess?.();
      dispatch(ModalConfirmAction.hideLoading());
    }
  };

  const toggleAddBank = () => {
    setFlgAddBank(!flgAddBank);
  };

  return (
    <>
      <BankAccount show={[flgAddBank, setFlgAddBank]} />
      <ModalCustom
        isOpen={show}
        handleClose={() => {
          dispatch(WithdrawalAction.toggle());
          setBankSelected(null);
        }}
        className={classes.modalMain}
      >
        <div className={classes.formHeader}>
          <div className={classes.formTitle}>
            Make a {assetSelected} withdrawal
          </div>
        </div>
        {!bankSelected ? (
          <>
            <Box justifyContent="center" display="flex">
              <AppButton onClick={toggleAddBank} width={'216px'}>
                Add bank details
              </AppButton>
            </Box>
            {banks && banks.length > 0 && (
              <Box textAlign="center">
                <div className={classes.chooseBank}>Choose Bank</div>
                <div className={classes.boxBank}>
                  {banks.map((bank: any) => {
                    return (
                      <div
                        key={bank}
                        className={
                          classes.itemBank +
                          (bankSelected?.title === bank.title ? ' active' : '')
                        }
                        onClick={() => {
                          setBankSelected(bank);
                          setValue('withdrawal_address', bank.title);
                          setValue('withdrawal_address', bank.title);
                          setValue(
                            'account_number',
                            bank.bank_data.account_number
                          );
                          setValue(
                            'routing_number',
                            bank.bank_data.routing_number ||
                              bank.bank_data.int_routing_number
                          );
                        }}
                      >
                        <div className={classes.titleBank}>{bank.title}</div>
                        <div className={classes.subBank}>
                          <b>Account Number:</b> {bank.bank_data.account_number}
                        </div>
                        <div className={classes.subBank}>
                          <b>Routing number:</b>{' '}
                          {bank.bank_data.routing_number ||
                            bank.bank_data.int_routing_number}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Box>
            )}
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmitWithdrawal)}>
            <div className={classes.formItem}>
              <div className={classes.itemBank}>
                <div className={classes.titleBank}>
                  {getValues('withdrawal_address')}
                </div>
                <div className={classes.subBank}>
                  <b>Account Number:</b> {getValues('account_number')}
                </div>
                <div className={classes.subBank}>
                  <b>Routing number:</b> {getValues('routing_number')}
                </div>
              </div>
            </div>
            <div className={classes.formItem}>
              <AppInput
                classNames={classes.inputItem}
                controller={{
                  control,
                  name: 'amount',
                  rules: {
                    required: true,
                    validate: amountWithdrawal(assetBalance),
                  },
                }}
                asset={assetSelected}
                inputAmount={true}
                label={'Amount'}
                error={Boolean(errors.amount)}
                placeholder="Amount"
                helperText={errors.amount?.message}
                labelProps={{
                  className: errors.amount ? classes.styleLabel : '',
                }}
              />
            </div>
            <div className={classes.formItem}>
              {[25, 50, 75, 100].map((value, index) => {
                return (
                  <Button
                    key={index}
                    variant="contained"
                    className={clsx(
                      classes.btnPercents,
                      value === percents ? `active` : ''
                    )}
                    onClick={() => {
                      setPercents(value);
                      const amount =
                        value === 100
                          ? assetBalance
                          : (assetBalance / 100) * value;
                      setValue('amount', String(amount), {
                        shouldValidate: true,
                      });
                    }}
                  >
                    {value}%
                  </Button>
                );
              })}
            </div>
            <div className={clsx(classes.formItem, classes.formControlLabel)}>
              <Checkbox
                defaultChecked={false}
                label="My withdrawal address and amount is correct"
                onChange={(checked: boolean) => {
                  setValue('term', checked, {
                    shouldValidate: true,
                  });
                }}
              />
            </div>
            <Box
              className={clsx(classes.formItem, classes.rowBtn)}
              display="flex"
            >
              <AppButton
                mode="secondary"
                height={'36px'}
                onClick={() => {
                  setBankSelected(null);
                }}
              >
                Back
              </AppButton>
              <AppButton
                type="submit"
                height={'36px'}
                isDisable={!isValid}
                isLoading={isLoading && !showModalConfirmCode}
              >
                Submit
              </AppButton>
            </Box>
          </form>
        )}
      </ModalCustom>
    </>
  );
}
