/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import usePrevious from 'src/hooks/usePrevious';
import { displayNumb } from 'src/utils/number';
import AreaBuy from './AreaBuy';
import AreaSell from './AreaSell';
import useStyles from './styles';
import { ws } from 'src/utils/socket';

export interface IOrderBook {
  value: any;
  label: any;
  percent: any;
}

const ComponentMarketDepth = (props: any) => {
  const styles = useStyles();
  const { symbol, callBackUpdatePrice } = props;
  const prevData = usePrevious({ symbol });

  const [dataAreaBuy, setBid] = useState(Array<IOrderBook>());
  const [dataAreaSell, setAsk] = useState(Array<IOrderBook>());

  useEffect(() => {
    if (prevData?.symbol !== symbol) {
      setBid([]);
      setAsk([]);
      unSubscribe(prevData?.symbol); // unsub old
    }
    subscribeMarketDepth(symbol);

    return () => {
      unSubscribe(symbol);
    };
  }, [symbol]);

  const unSubscribe = (oldSymbol: string) => {
    if (oldSymbol && oldSymbol !== '') {
      const msg = {
        event: 'unsubscribe',
        symbol: oldSymbol,
        method: 'pricing',
      };
      ws.unsubscribe('pricing', msg);
    }
  };

  const callbackResultData = (data: any) => {
    let bid: Array<IOrderBook> = [];
    let ask: Array<IOrderBook> = [];

    const maxKey = data[data.length - 1];
    const maxLabel = maxKey ? Object.keys(maxKey)[0] : 0;

    // mapping data from websocket
    for (let item of data) {
      const label = Object.keys(item);
      const value = item[`${label}`];
      let itemPecent = maxLabel === 0 ? 0 : Math.round(Number(label) / Number(maxLabel) * 100);
      bid.push({
        value: displayNumb(parseFloat(value[0]).toFixed(2)),
        label: Number(label[0]),
        percent: itemPecent,
      });
      ask.push({
        value: displayNumb(parseFloat(value[1]).toFixed(2)),
        label: Number(label[0]),
        percent: itemPecent,
      });

      if (ask && ask.length > 0) {
        callBackUpdatePrice(ask[0].value);
      }
    }

    // set state data
    setBid(bid.reverse());
    setAsk(ask);
  };

  const subscribeMarketDepth = (newSymbol: string) => {
    if (newSymbol && newSymbol !== '') {
      const msg = {
        event: 'subscribe',
        symbol: newSymbol,
        method: 'pricing',
      };
      ws.subscribe('pricing', msg, (data) => {
        callbackResultData(data);
      });
    }
  };

  return (
    <div className={styles.componentMarketDepth}>
      <h2 className={styles.title}>Market Depth</h2>
      <AreaBuy data={dataAreaBuy} />
      <div className={styles.blockMid}>
        <span className={styles.valueSell}>
          {dataAreaSell.length > 0 ? dataAreaSell[0].value : ''}{' '}
          {symbol.split('/')[1]}
        </span>
        <span className={styles.iconMid}>{'<->'}</span>
        <span className={styles.valueBuy}>
          {dataAreaBuy.length > 0 ? dataAreaBuy[dataAreaBuy.length - 1].value : ''}{' '}
          {symbol.split('/')[1]}
        </span>
      </div>
      <AreaSell data={dataAreaSell} />
    </div>
  );
};

export default ComponentMarketDepth;
