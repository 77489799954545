import { ReactElement } from 'react';
import CardCommon from 'src/components/BaseLandingPage/Common/Card';
import { useHistory } from 'react-router';
import { PAGE } from 'src/utils/utils';

const ThankYouComponent = ({
  title = 'Thank you!',
  content = 'Our representative will contact you within 2 hours',
}: {
  title?: string;
  content?: ReactElement | string;
}) => {
  const history = useHistory();
  const handleSubmit = () => {
    history.push(PAGE.LOGIN);
  };

  return (
    <CardCommon
      icon="/images/icon-thank-you.png"
      title={title}
      content={content}
      contentBtn="OK"
      onClickOk={handleSubmit}
    ></CardCommon>
  );
};
export default ThankYouComponent;
