import {
  FormControl,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  InputBaseProps,
} from '@material-ui/core';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import useStyles from './styles';

export interface DatePickerProps extends InputBaseProps {
  label?: string;
  labelProps?: FormLabelProps;
  helperText?: string | React.ReactNode;
  classNames?: string;
  selected?: any;
  clearButtonClassName?: string;
  onChange: (data: any) => void;
  placeholderText?: string;
  maxDate?: any;
}

const DatePicker = ({
  label,
  labelProps,
  selected,
  placeholderText,
  clearButtonClassName,
  className,
  classNames,
  fullWidth,
  required,
  helperText,
  error,
  onChange,
  maxDate,
  ...rest
}: DatePickerProps) => {
  const classes = useStyles();
  const [classLabel, setClassLabel] = useState(false);
  const [requiredField, setRequiredField] = useState('');

  return (
    <FormControl
      fullWidth={fullWidth}
      error={error}
      className={label ? `${classes.formControl} ${classNames}` : className}
    >
      {label && (
        <FormLabel
          {...labelProps}
          className={`
          ${required && requiredField && 'label-error'} 
          ${classLabel && 'label-focused'}
        `}
        >
          {label}
        </FormLabel>
      )}
      <ReactDatePicker
        className={`
          ${classes.datePicker} 
          ${(error || (required && requiredField)) && classes.error}
          ${classLabel && 'input-focused'} 
        `}
        selected={selected}
        placeholderText={placeholderText || 'mm/dd/yyyy'}
        clearButtonClassName={clearButtonClassName}
        onChange={(val) => {
          onChange(val);
          if (!val) {
            setRequiredField(`${label} requirements`);
          } else {
            setRequiredField('');
          }
        }}
        isClearable={true}
        maxDate={maxDate}
        onCalendarClose={() => setClassLabel(false)}
        onCalendarOpen={() => setClassLabel(true)}
      />
      {helperText ? (
        <FormHelperText className={classes.helperText} component="div">
          {helperText}
        </FormHelperText>
      ) : required && requiredField ? (
        <FormHelperText className={classes.helperText} component="div">
          {requiredField}
        </FormHelperText>
      ) : (
        <></>
      )}
    </FormControl>
  );
};

export default DatePicker;
