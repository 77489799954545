import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import ModalCustom from 'src/components/Base/ModalCustom';
import Checkbox from 'src/components/Base/Checkbox';
import AppInput from 'src/components/Base/AppInput';
import { Controller, useForm } from 'react-hook-form';
import AppButton from 'src/components/Base/AppButton';
import { IP_ADDRESS_PATTERN } from 'src/commons/constants';
import { MAX_LENGTH_INPUT } from 'src/commons/constants/AppInputFormat';
import {
  ApiKey,
  ApiKeyService,
  CreateApiKeyBody,
  NewApiKey,
} from 'src/services/ApiKeyService';
import ModalNewKey from '../ModalNewKey';
import ModalConfirmCode from 'src/routing/APIPage/ModalConfirmCode';
import customToast from 'src/components/Base/Toast/Toast';

import useStyles from './styles';

const permissionList = [
  {
    value: 'read_only',
    label: 'Read data',
  },
  {
    value: 'change_account_data',
    label: 'Modify account data',
  },
  {
    value: 'allow_trading',
    label: 'Allow trade',
  },
  {
    value: 'allow_withdrawals',
    label: 'Allow withdrawals',
  },
];

const apiKeyService = ApiKeyService.getInstance();

export const ModalAddKey = ({
  isOpen,
  currentKey,
  keyList,
  handleClose,
  onSubmitted,
}: {
  isOpen: boolean;
  currentKey?: ApiKey | null;
  keyList?: ApiKey[];
  handleClose: () => void;
  onSubmitted: () => void;
}) => {
  const classes = useStyles();

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      label: '',
      permissions: [] as string[],
    },
  });
  const ipForm = useForm({
    mode: 'onChange',
    defaultValues: { ip_address: '' },
  });
  const [ipAddresses, setIpAddresses] = useState<string[]>([]);

  const [modalNewKey, setModalNewKey] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const [newKey, setNewKey] = useState<NewApiKey>();

  const [lastFormInput, setLastFormInput] = useState<CreateApiKeyBody>();

  const addIpAddress = async (ip: string) => {
    if (!ip) return;
    const result = await ipForm.trigger('ip_address');
    if (result) {
      !ipAddresses.includes(ip) && setIpAddresses([...ipAddresses, ip]);
      ipForm.setValue('ip_address', '');
    }
  };

  const deleteIpAddress = (item: string) => {
    setIpAddresses(ipAddresses.filter((el) => el !== item));
  };

  const handleOnChangeCheckbox = (value: string, callback: Function) => {
    const { permissions: oldValues = [] } = getValues();
    let newValues = oldValues?.includes(value)
      ? oldValues.filter((el: string) => el !== value)
      : [...oldValues, value];
    callback(newValues);
  };

  const isLabelDuplicate = (input: string) => {
    return keyList?.some((key) => key !== currentKey && key.label === input);
  };

  const onSubmit = async ({
    label,
    permissions,
  }: {
    label: string;
    permissions: string[];
  }) => {
    if (submitting) return;
    if (isLabelDuplicate(label.trim())) {
      setError('label', { type: 'duplicate' });
      return;
    }
    const body = {
      label: label.trim(),
      read_only: permissions.includes('read_only'),
      allow_trading: permissions.includes('allow_trading'),
      allow_withdrawals: permissions.includes('allow_withdrawals'),
      change_account_data: permissions.includes('change_account_data'),
      ip_addresses: ipAddresses,
    };
    setLastFormInput(body);
    try {
      setSubmitting(true);
      if (currentKey) {
        await apiKeyService.edit({ ...body, prefix: currentKey.prefix });
      } else {
        await apiKeyService.create(body);
      }
      setModalConfirm(true);
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitConfirm = async (code: string, twoFA?: string) => {
    if (!lastFormInput || confirming) return;
    try {
      setConfirming(true);
      if (currentKey) {
        await apiKeyService.confirmEdit({
          ...lastFormInput,
          prefix: currentKey.prefix,
          code,
          two_fa: twoFA,
        });
      } else {
        const { data } = await apiKeyService.confirmCreate({
          ...lastFormInput,
          code,
          two_fa: twoFA,
        });
        setNewKey(data);
        setModalNewKey(true);
      }
      customToast.success(
        currentKey
          ? 'API Key Edited Successfully'
          : 'New API Key Generated Successfully'
      );
      setModalConfirm(false);
      handleClose();
      onSubmitted && onSubmitted();
    } catch {
      setConfirmError(true);
    } finally {
      setConfirming(false);
    }
  };

  const resetForms = () => {
    reset();
    ipForm.reset();
    setIpAddresses([]);
  };

  useEffect(() => {
    const applyCurrentKey = async (currentKey: ApiKey) => {
      setValue('label', currentKey.label);
      setValue(
        'permissions',
        (Object.keys(currentKey) as Array<keyof ApiKey>).filter(
          (el) => currentKey[el] === true
        )
      );
      setIpAddresses(currentKey.ip_addresses || []);
      trigger();
    };

    if (isOpen) {
      resetForms();
      isOpen && currentKey && applyCurrentKey(currentKey);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, currentKey]);

  return (
    <>
      <ModalCustom
        className={classes.mainModalNewKey}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <div className={classes.mainModal}>
          <h3 className={classes.titleModal}>
            {currentKey ? 'Edit API Key' : 'Generate New API Key'}
          </h3>
        </div>

        <div className={classes.mainForm}>
          <form
            className={classes.styleForm}
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          >
            {/* <input {...register('test', { required: true })} /> */}
            <AppInput
              fullWidth
              controller={{
                control,
                name: 'label',
                rules: { required: true },
              }}
              label="Label"
              placeholder="Label"
              error={!!formState.errors.label}
              helperText={
                formState.errors.label?.type === 'duplicate'
                  ? 'This label name already exists'
                  : ''
              }
              labelProps={{
                className: `${classes.styleLabel} ${
                  formState.errors.label ? classes.labelError : ''
                }`,
              }}
              className={classes.styleInput}
              inputProps={{ maxLength: MAX_LENGTH_INPUT }}
            />
            <div className={classes.rectangle}></div>
            <label className={classes.styleLabel}>Title</label>
            <Controller
              name="permissions"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <>
                  {permissionList.map((item) => (
                    <div key={item.value}>
                      <Checkbox
                        className={classes.styleCheck}
                        label={
                          <span className={classes.styleLbl}>{item.label}</span>
                        }
                        onChange={() =>
                          handleOnChangeCheckbox(item.value, onChange)
                        }
                        onBlur={onBlur}
                        checked={value?.indexOf(item.value) > -1}
                        inputRef={ref}
                        value={item.value}
                      />
                    </div>
                  ))}
                </>
              )}
            ></Controller>
            <div className={classes.rectangle}></div>
            <AppInput
              fullWidth
              label="Restrict to IP address (optional)"
              labelProps={{
                className: `${classes.styleLabel} ${
                  ipForm.formState.errors.ip_address ? classes.error : ''
                }`,
              }}
              subLabel={
                <div>
                  {ipAddresses.map((item) => (
                    <Chip
                      className={classes.ip}
                      key={item}
                      label={item}
                      clickable
                      onDelete={() => deleteIpAddress(item)}
                    />
                  ))}
                </div>
              }
              controller={{
                control: ipForm.control,
                name: 'ip_address',
                rules: {
                  maxLength: 15,
                  pattern: IP_ADDRESS_PATTERN,
                  onBlur: (e) => addIpAddress(e.target.value),
                },
              }}
              inputProps={{ maxLength: 15 }}
              helperText={
                ipForm.formState.errors.ip_address ? 'Invalid IP address' : ''
              }
              error={!!ipForm.formState.errors.ip_address}
              placeholder="IP address"
              className={classes.styleInput}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addIpAddress((event.target as HTMLInputElement).value);
                }
              }}
            />
            <div className={classes.divBtn}>
              <AppButton
                type="submit"
                fullWidth
                isDisable={
                  !formState.isValid ||
                  ipForm.formState.errors.ip_address?.type !== undefined
                }
                isLoading={submitting}
              >
                Submit
              </AppButton>
            </div>
          </form>
        </div>
      </ModalCustom>
      <ModalConfirmCode
        isOpen={modalConfirm}
        loading={confirming}
        subTitle="Enter confirmation code from email to add new API key."
        errorCode={confirmError}
        setErrorCode={setConfirmError}
        handleClose={() => setModalConfirm(false)}
        onSubmit={onSubmitConfirm}
      />
      <ModalNewKey
        newKey={newKey}
        isOpen={modalNewKey}
        handleClose={() => setModalNewKey(false)}
      />
    </>
  );
};

export default ModalAddKey;
