import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Hidden, List } from '@material-ui/core';

import Deposit from 'src/components/Deposit';
import Link from 'src/components/Base/Link';

import { changeThemeMode } from 'src/store/actions/theme-mode';
import { LoadingAction } from 'src/store/actions/loading';
import { AuthenticationAction } from 'src/store/actions/authentication';
import { toLogin } from 'src/utils/common';

import useStyles from './styles';

const listMenuDef = [
  {
    id: 1,
    name: 'Home',
    pathname: '/',
    icon: 'icon-home',
  },
  {
    id: 2,
    name: 'Trading',
    pathname: '/trading',
    icon: 'icon-trading',
  },
  {
    id: 3,
    name: 'Wallet',
    pathname: '/wallet',
    icon: 'icon-wallet',
  },
  {
    id: 4,
    name: 'Reports',
    pathname: '/reports',
    icon: 'icon-reports',
  },
  {
    id: 5,
    name: 'Settings',
    pathname: '/settings',
    icon: 'icon-settings',
    children: [
      {
        id: 1,
        name: 'KYC',
        pathname: 'setting/kyc',
      },
      {
        id: 2,
        name: 'API',
        pathname: '/api',
      },
      {
        id: 1,
        name: 'Security',
        pathname: '/setting/security',
      },
      {
        id: 3,
        name: 'Address book',
        pathname: '/setting/address-book',
      },
    ],
  },
  {
    id: 6,
    name: 'Support',
    pathname: '/support',
    icon: 'icon-support',
  },
];

const NavItems = (props: any) => {
  const { drawerOpen, drawerToggle } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const [showChild, setShowChild] = useState(false);
  const [listMenu] = useState(listMenuDef);
  const [currentActiveMenuItem, setCurrentActiveMenuItem] = useState('/');
  const [currentActiveMenuItemChild, SetCurrentActiveMenuItemChild] =
    useState('/');
  const [themeMode, setThemeMode] = useState(
    useSelector((state: any) => state.themeMode.status)
  );
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);
  const username = useSelector((state: any) => state.auth.username);
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const onSetModePage = () => {
    setThemeMode(!themeMode);
    localStorage.setItem('themeMode', themeMode ? 'dark' : 'light');
  };

  useEffect(() => {
    dispatch(changeThemeMode(themeMode));
  }, [dispatch, themeMode]);

  useEffect(() => {
    const status = localStorage.getItem('themeMode') || true;
    setThemeMode(status === 'dark' ? false : true);
  }, []);

  const setCurrentActiveMenu = (pathname: string) => {
    switch (pathname) {
      case '/':
        setCurrentActiveMenuItem('/');
        SetCurrentActiveMenuItemChild('');
        break;
      case '/trading':
        setCurrentActiveMenuItem('/trading');
        SetCurrentActiveMenuItemChild('');
        break;
      case '/reports':
        setCurrentActiveMenuItem('/reports');
        SetCurrentActiveMenuItemChild('');
        break;
      case '/wallet':
        setCurrentActiveMenuItem('/wallet');
        SetCurrentActiveMenuItemChild('');
        break;
      case '/setting/kyc':
        setCurrentActiveMenuItem('/settings');
        SetCurrentActiveMenuItemChild('setting/kyc');
        break;
      case '/setting/api':
        setCurrentActiveMenuItem('/settings');
        SetCurrentActiveMenuItemChild('/setting/api');
        break;
      case '/setting/security':
        setCurrentActiveMenuItem('/settings');
        SetCurrentActiveMenuItemChild('/setting/security');
        break;
      case '/setting/address-book':
        setCurrentActiveMenuItem('/settings');
        SetCurrentActiveMenuItemChild('/setting/address-book');
        break;
      case '/support':
        setCurrentActiveMenuItem('/support');
        SetCurrentActiveMenuItemChild('');
        break;
      default:
    }
  };

  useEffect(() => {
    setCurrentActiveMenu(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <div className={classes.navItems}>
      <List>
        {listMenu?.map((item) =>
          !!item.children ? (
            <div key={item.id}>
              <div
                onClick={() => setShowChild(!showChild)}
                className={`${classes.itemNav} ${currentActiveMenuItem === item.pathname &&
                  classes.itemNavActive
                  }`}
              >
                <span
                  className={`${item.icon} ${classes.iconItemNav} ${currentActiveMenuItem === item.pathname &&
                    classes.iconItemNavActive
                    }`}
                ></span>
                {drawerOpen && item.name}
                {drawerOpen && (
                  <span
                    style={{
                      transform: showChild ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    className={`icon-arrow-down ${classes.iconArrow} ${currentActiveMenuItem === item.pathname &&
                      classes.iconArrowActive
                      }`}
                  ></span>
                )}
              </div>
              {drawerOpen && showChild && (
                <ul className={classes.listChild}>
                  {item.children?.map((child, index) => {
                    return (
                      <Link
                        key={index}
                        href={`${child.pathname}`}
                        className={`${classes.itemNavChild} ${currentActiveMenuItemChild === child.pathname &&
                          classes.itemNavChildActive
                          }`}
                      >
                        {child.name}
                      </Link>
                    );
                  })}
                </ul>
              )}
            </div>
          ) : (
            <Link
              key={item.id}
              href={`${item.pathname}`}
              className={`${classes.itemNav} ${currentActiveMenuItem === item.pathname && classes.itemNavActive
                }`}
            >
              <span
                className={`${item.icon} ${classes.iconItemNav} ${currentActiveMenuItem === item.pathname &&
                  classes.iconItemNavActive
                  }`}
              ></span>
              {drawerOpen && item.name}
            </Link>
          )
        )}

        <Hidden smDown>
          <div onClick={drawerToggle} className={`${classes.itemNav}`}>
            <span
              style={{
                transform: drawerOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              className={`icon-hide-menu ${classes.iconItemNav}`}
            ></span>
            {drawerOpen && 'Hide menu'}
          </div>
        </Hidden>

        <Hidden mdUp>
          <div
            className={`${classes.itemNav}`}
            onClick={async () => {
              dispatch(LoadingAction.show());
              await dispatch(AuthenticationAction.logout());
              toLogin({ logout: true });
            }}
          >
            <span
              style={{
                transform: drawerOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              className={`icon-log-out ${classes.iconItemNav}`}
            ></span>
            {isLogged ? 'Log out ' : 'Sign in'}
          </div>
          <div className={classes.lineW}></div>
          <div className={`${classes.itemNav}`}>
            <span
              style={{
                transform: drawerOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              className={`icon-user ${classes.iconItemNav}`}
            ></span>
            {isLogged ? username : 'Profile'}
          </div>
          <div className={`${classes.itemNav}`}>
            <span
              style={{
                transform: drawerOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              className={`icon-notification ${classes.iconItemNav}`}
            ></span>
            Notifications
          </div>
          <div onClick={() => onSetModePage()} className={`${classes.itemNav}`}>
            <span
              style={{
                transform: drawerOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              className={`icon-mode ${classes.iconItemNav}`}
            ></span>
            {themeMode ? 'Light' : 'Dark'} theme
          </div>
          <div className={classes.styleBtnDeposit}>
            <Button
              variant="contained"
              className={classes.btnDeposit}
              onClick={() => setIsOpenDeposit(true)}
            >
              Deposit
            </Button>
          </div>

          {isOpenDeposit && (
            <Deposit
              isOpen={isOpenDeposit}
              handleClose={() => setIsOpenDeposit(false)}
            />
          )}
        </Hidden>
      </List>
    </div>
  );
};

export default NavItems;
