import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainSLider: {
      flexGrow: 1,
      boxShadow: "0px 4px 50px rgba(127, 99, 244, 0.15)",
      borderRadius: "10px",
      borderBottomLeftRadius:0,
      borderBottomRightRadius:0,
      background:theme.custom.colors.bgrColor,
      position: "relative",
      height: 76,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },

    slideImg :{
      textAlign: "center",
      maxWidth: 176,
    },

    img: {
      maxHeight: 52,
      maxWidth: 176,
      overflow: 'hidden',
      marginTop: 10,
    },

    styleMobileSLide:{
      background: "transparent",
      position: "absolute",
      top: 15,
      width: "100%",
      color: theme.custom.colors.colorButton,

      "& > div > div":{
        display: "none",
      },

      "& > div > div > div":{
      }

    },

    styleButtonSlide: {
      color: theme.custom.colors.colorButton,

      "&:disabled":{
        color: theme.custom.colors.colorButton,
        opacity: "0.3",
      },

      "& svg": {
        height: 20,
      },
    },
  };
});

export default useStyles;