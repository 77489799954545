import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    boxGroupSelect: {
      marginBottom: 15
    },

    head: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 5,
      alignItems: "center",
    },

    babel: {
      fontFamily: "Ubuntu-Bold",
      fontSize: 14,
      lineHeight: "140%",
      color: theme.custom.colors.titleTrading,
    },

    value: {
      fontSize: 12,
      lineHeight: "14px",
      color: "#9BA1B1",
    },

    groupSelect: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid #9BA1B1",
      borderRadius: 4,
      "& .MuiButton-root": {
        "&:hover": {
          background: 'none'
        }
      },
    },

    selectCoin: {
      overflow: "hidden",
      height: 42,
      border: 0,
      boxShadow: "none",
      background: "transparent",
      outline: "none",
      width: 80,
      fontFamily: "Ubuntu-Bold",
      fontSize: 16,
      // lineHeight: 18,
      color: theme.custom.colors.titleTrading,
      textTransform: "uppercase",
      padding: "10px 5px",
      justifyContent: "flex-start",
      display: "flex",
      alignItems: "center"
    },

    optionCoin: {
      width: 140,

      [theme.breakpoints.between(768, 1024)]: {
        width: 128,
      }
    },

    optionGroup: {
      borderRadius: 6,
      borderColor: '#606983'
    },
    iconSelect: {
      marginLeft: 35,

      "&:before": {
        color: theme.custom.colors.titleTrading
      }

    },

    widthPaper: {
      width: '142px !important'
    },

    dropdownSelect: {
      width: 240,
      marginTop: 2,
      zIndex: 5,
      [theme.breakpoints.between("1800", "1950")]: {
        width: 315
      },
      [theme.breakpoints.between("1160", "1441")]: {
        width: 250
      },
      [theme.breakpoints.between("1000", "1155")]: {
        width: 245
      },
      [theme.breakpoints.between("990", "1025")]: {
        width: 212
      },
      [theme.breakpoints.down("sm")]: {
        width: 322
      },
      [theme.breakpoints.down("xs")]: {
        width: 325
      }
    },

    paperSelect: {
      background: theme.custom.colors.cardBackground,
    },

    iconShow: {
      height: 25,
      marginRight: 5,
    },

    iconDrop: {
      position: "absolute",
      right: 0,
    },

    inputAmount: {
      width: "calc(100% - 15px)",
      border: "none",
      borderLeft: "1px solid #9BA1B1",
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      outline: "none",
      boxShadow: "none",
      height: 42,
      fontSize: 14,
      lineHeight: "140%",
      padding: "10px 10px 10px 15px",
      background: "transparent",
      color: theme.custom.colors.titleTrading,

      "&:disabled": {
        WebkitTextFillColor: theme.custom.colors.titleTrading,
        WebkitOpacity: "1",
        background: "transparent",
      },

      "&::-webkit-input-placeholder": {
        color: theme.custom.colors.titleTrading,
      },

      "&::-ms-input-placeholder": {
        color: theme.custom.colors.titleTrading,
      },

      "&::placeholder": {
        color: theme.custom.colors.titleTrading,
      },

    },

    groupArrow: {
      lineHeight: '15px',
      marginTop: '-5px',
      "& img": {
        width: 10,
        "&:hover": {
          cursor: 'pointer'
        }
      }
    },
    borderError: {
      border: '1px solid #ea3943'
    },

    selectTitle: {
      color: theme.custom.colors.white4,
      fontFamily: 'Ubuntu-Medium'
    },

    inputAmountContainer: {
      width: "calc(100% - 80px)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      justifyItems: "center"
    },

  };
});

export default useStyles;
