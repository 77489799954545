import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      backgroundImage:'url(/images/bgr-login.svg)',
      backgroundSize: 'cover',
      height: '100vh',
      [theme.breakpoints.down("sm")]: {
        backgroundImage:'url(/images/bgr-login-768.svg)',
      },
      [theme.breakpoints.down("xs")]: {
        backgroundImage:'url(/images/bgr-login-375.svg)',
      },
    },
    main: {
    },
  };
});

export default useStyles;