import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    formControl: {
      marginBottom: 25,
      position: 'relative',
      fontFamily: 'Titillium Web',
    },

    formInput: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '10px 45px 10px 16px',
      borderRadius: 6,
      border: '1px solid',
      borderColor: theme.custom.colors.colorLbl,
      background: 'none',
      color: theme.custom.colors.white,
      height: 50,
      marginTop: 4,
      fontSize: '14px',
      outline: 'none',

      '&::placeholder': {
        color: theme.custom.colors.placeholder,
        fontFamily: 'Titillium Web'
      },
      // '&:-webkit-autofill': {
      //   '-webkit-box-shadow':
      //     '0 0 0 1000px ' + theme.custom.colors.bgrBox + ' inset !important',
      //   '-webkit-text-fill-color': 'white !important',
      // },
    },
    label: {
      fontSize: 14,
      color: theme.custom.colors.colorLbl,
      fontWeight: 'bold'
    },
    iconPassWord: {
      position: 'absolute',
      top: 43,
      right: 12,
      cursor: 'pointer',
    },
    iconPassWordVisable: {},
    // Focus
    formInputFocus: {
      borderColor: theme.custom.colors.colorRead,
    },
    // Error
    formInputError: {
      borderColor: theme.custom.colors.borderError,
      '&::placeholder': {
        // color: theme.custom.colors.borderError,
      },
    },
    labelFocus: {
      color: theme.custom.colors.colorRead,
    },
    labelError: {
      color: theme.custom.colors.textError,
    },
    errorMsg: {
      color: theme.custom.colors.textError,
      fontSize: 12,
      marginTop: 4,
      textAlign: 'center',
    },
  };
});

export default useStyles;
