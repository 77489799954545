import { Button, Typography } from '@material-ui/core';

import { displayNumb } from 'src/utils/number';
import { TRADE_SIDE } from 'src/commons/constants/Constants';
import ModalCustom from 'src/components/Base/ModalCustom';
import useStyles from './styles';

export const ModalCompleted = ({
  isOpen,
  handleClose,
  handleComplete,
  data,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleComplete: () => void;
  data: any;
}) => {
  const classes = useStyles();

  const renderSpan = (data: any, isTop?: boolean) => {
    const symbol = data?.symbol ?? '';
    const orderCoin = symbol.split('/')[0];
    const anchorCoin = symbol.split('/')[1];

    if (isTop) {
      return (
        <span>
          {data?.side === TRADE_SIDE.buy
            ? `${displayNumb(data?.amount)} ${orderCoin}`
            : `${displayNumb(data?.proceeds)} ${anchorCoin}`}
        </span>
      );
    }
    return (
      <span>
        {data?.side === TRADE_SIDE.buy
          ? `${displayNumb(data?.cost)} ${anchorCoin}`
          : `${displayNumb(data?.amount)} ${orderCoin}`}
      </span>
    );
  };

  return (
    <>
      <ModalCustom
        isOpen={isOpen}
        isIconClose={true}
        handleClose={handleClose}
        className={classes.mainModalConfirm}
      >
        <div className="title">
          <Typography className={classes.title}>Trade completed</Typography>
        </div>
        <div className={classes.content}>
          <div>
            <Typography>Side:</Typography>
            <span
              className={`${classes.buy} ${
                data?.side === TRADE_SIDE.sell ? `${classes.sell}` : ''
              }`}
            >
              {data.side}
            </span>
          </div>
          <div>
            <Typography>Symbol:</Typography>
            <span>{data.symbol}</span>
          </div>
          <div>
            <Typography>Final Price:</Typography>
            <span>{displayNumb(data?.price)}</span>
          </div>
        </div>
        <div className={classes.border}></div>
        <div className={classes.content}>
          <div>
            <Typography>AMT:</Typography>
            <span>{renderSpan(data, true)}</span>
          </div>
          <div>
            <Typography>Final Price:</Typography>
            <span>{displayNumb(data?.price)}</span>
          </div>
          <div>
            <Typography>Status:</Typography>
            <span>Confirmed</span>
          </div>
        </div>

        <div className={classes.display}>
          <Button className={classes.btnOK} onClick={handleComplete}>
            OK
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export default ModalCompleted;
