/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import DefaultLayout from 'src/components/Layout/DefaultLayout';
import ComponentCoinsHighlights from './ComponentCoinsHighlights';
import ComponentCoinInfo from './ComponentCoinInfo';
import ComponentMid from './ComponentMid';
import ComponentFooterLeft from './ComponentFooterLeft';
import ComponentFooterRight from './ComponentFooterRight';
import { AccountService } from 'src/services/AccountService';
import { marketService } from 'src/services/MarketService';
import { DepositService } from 'src/services/DepositService';
import assetConfig from 'src/commons/configs/assetsConfig.json';
import { displayNumb } from 'src/utils/number';

import useStyles from './styles';
import { DEFAULT_LOGO } from 'src/commons/constants';

const WalletPage = (props: any) => {
  const accountService = new AccountService();
  const depositService = new DepositService();
  const styles = useStyles();
  const [balancesLoading, setBalancesLoading] = useState(false);
  const [balances, setBalances] = useState([]);
  const [midBalances, setMidBalances] = useState([]);
  const [activeCoin, setActiveCoin] = useState({});
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    document.title = 'Wallet';
    getBalances();
    getAssets();
  }, []);

  const getAssets = async () => {
    try {
      const { data } = await marketService.getAllMarkets();
      setCurrencies(
        data.markets.map((el: any) => ({
          value: el,
          label: el,
          pair: el.split('/')[0],
        }))
      );
    } catch (error) { }
  };

  const getBalances = async () => {
    try {
      setBalancesLoading(true);

      const {
        data: { deposit_addresses: depositAddresses },
      } = await depositService.getDepositAddress();

      if (!depositAddresses || !depositAddresses.length) return;

      const resultData = await accountService.getBalances();
      if (resultData && resultData.data && resultData.data.balances) {
        const balances = resultData.data.balances.map((x: any, key: number) => {
          let addr: string = '';
          const deposit_address: any = depositAddresses.find(
            (m: any) => m.asset === x.asset
          );
          if (deposit_address) {
            addr = deposit_address.deposit_address;
          }
          const asset = x.asset;
          const objAsset = (assetConfig as any)[asset];
          const obj = {
            id: key,
            asset: x.asset,
            logo: objAsset?.icon || DEFAULT_LOGO,
            symbol: x.asset,
            name: objAsset?.name || x.name,
            total: x.total,
            total_balance: displayNumb(x.total),
            available: displayNumb(x.free),
            locked: displayNumb(x.used),
            panding: displayNumb(x.pending_deposit),
            address: addr,
          };
          if (obj.asset === 'BTC') {
            setActiveCoin(obj);
          }
          return obj;
        });
        setBalances(balances);
        setMidBalances(balances);
      }
    } finally {
      setBalancesLoading(false);
    }
  };

  const onSearch = (key: string) => {
    if (key.length > 0) {
      const newBalances = balances.filter((x: any) =>
        x.asset.toLowerCase().includes(key.trim().toLowerCase())
      );
      setMidBalances(newBalances);
    } else {
      setMidBalances(balances);
    }
  };

  const handleRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  return (
    <>
      <DefaultLayout>
        <div className={styles.walletPage}>
          <ComponentCoinsHighlights
            balances={balances}
            setActiveCoin={(value: any) => setActiveCoin(value)}
            activeCoin={activeCoin}
          />

          <ComponentCoinInfo
            activeCoin={activeCoin}
            currencies={currencies}
            onRefresh={handleRefresh}
          />

          <ComponentMid
            balances={midBalances}
            currencies={currencies}
            onSearch={onSearch}
            isLoading={balancesLoading}
          />

          <section className={styles.footerPage}>
            <ComponentFooterLeft isRefresh={refresh} />
            <ComponentFooterRight />
          </section>
        </div>
      </DefaultLayout>
    </>
  );
};

export default withRouter(WalletPage);
