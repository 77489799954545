import { TradingService } from 'src/services/TradingService';

class HistoryProvider {
    private tradingService: TradingService = new TradingService();

    public async getBar(symbolInfo: any, period: string) {
        const data = {
            symbol: symbolInfo.name,
            period: period
        }
        const resultData = await this.tradingService.getBar(data);
        return resultData.data;
    }

    public async refreshDataChart(symbolInfo: any, period: string) {
        const data = {
            symbol: symbolInfo.name,
            period: period
        }
        const resultData = await this.tradingService.refreshDataChart(data);
        return resultData.data;
    }
}

export const historyProvider = new HistoryProvider();