import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "inline-flex",
    position: "relative",
    cursor: "pointer",
    alignItems: "center",

    "& > input": {
      opacity: 0,
      position: "absolute",
      left: 0,
      top: 0,
      height: 0,
      width: 0,
    },
  },
  disabled: {
    pointerEvents: "none",
  },
  checkmark: {
    width: "20px",
    height: "20px",
    border: "1px solid",
    borderColor: theme.custom.colors.grey2,
    borderRadius: "50%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",

    "$container:hover &": {
      borderColor: theme.custom.colors.cornflowerBlue,
    },

    "&:after": {
      content: "''",
      position: "absolute",
      display: "none",
      height: "14px",
      width: "14px",
      borderRadius: "50%",
      background: theme.custom.colors.cornflowerBlue,
    },
    "input:checked ~ &:after": {
      display: "block",
    },

    "input:checked ~ &": {
      borderColor: theme.custom.colors.cornflowerBlue,
    },

    "$disabled ~ &": {
      background: theme.custom.colors.grey3,
    },
  },
  label: {
    marginLeft: "5px",
  },
}));

export default useStyles;
