import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import clsx from 'clsx';
import { ChangeHandler, Controller } from 'react-hook-form';

import useStyles from 'src/routing/LandingPage/SignUpPage/SignUpComponent/styles';

export default function RadioList({
  items,
  name,
  value,
  onChange,
  control,
}: {
  name: string;
  items: object[];
  value?: string;
  onChange?: ChangeHandler;
  control: any;
}) {
  const style = useStyles();
  return (
    <Controller
      render={({ field }) => (
        <RadioGroup aria-label="gender" className={style.radioGroup} {...field}>
          {items?.map((item: any) => (
            <div
              key={item.value}
              className={`${style.radioBtn} ${item.value === field.value ? style.checked : null}`}
            >
              <FormControlLabel
                classes={{
                  root: style.radioBlock,
                }}
                control={
                  <Radio
                    className={style.radioItem}
                    disableRipple
                    color="default"
                    checkedIcon={
                      <span className={clsx(style.icon, style.checkedIcon)} />
                    }
                    icon={<span className={style.icon} />}
                  />
                }
                value={item.value}
                label={
                  <span
                    className={`${style.radioLabel} ${item.value === field.value
                      ? style.radioLabelChecked
                      : null
                      } `}
                  >
                    {item.label}
                  </span>
                }
              />
            </div>
          ))}
        </RadioGroup>
      )}
      name={name}
      control={control}
    />
  );
}
