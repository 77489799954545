import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import Deposit from 'src/components/Deposit';
import useStyles from './styles';

const WelcomeContainer = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const authState = useSelector((state: any) => state.auth);
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);

  const { kyc, balances } = props;
  const getClassKYC = (isLogged: boolean, kyc: boolean) => {
    let classname = `${classes.button} ${classes.btnDisable}`;
    if (isLogged && !kyc) {
      classname = `${classes.button} ${classes.btnActive} `;
    }
    return classname;
  };

  const getClassDeposit = (isLogged: boolean, kyc: boolean) => {
    let classname = `${classes.button} ${classes.btnDisable}`;
    if (isLogged && kyc) {
      classname = `${classes.button} ${classes.btnActive} `;
    }
    return classname;
  };

  const handleSetKYC = () => {
    // when account is not logged or account logged and kyc done before
    if (!authState?.isLogged || (authState?.isLogged && kyc)) {
      return;
    }

    history.push(`setting/kyc`);
  };

  const handleDeposit = () => {
    // when account is not logged or account logged and kyc not yet
    if (
      !authState?.isLogged ||
      (authState?.isLogged && !kyc) ||
      !isDeposit(balances)
    ) {
      return;
    }
    setIsOpenDeposit(true);
  };

  const isDeposit = (balances: any) => {
    if (!balances || (balances && balances.length === 0)) {
      return false;
    }
    const hasDeposit = balances.find((x: any) => Number(x.total) > 0);
    return hasDeposit ? true : false;
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.welcomeTitle}>
          <span className={classes.title}>Welcome to oneAlpha!</span>
          <span className={classes.note}>
            Just a few more steps and you’re good to go!
          </span>
        </div>

        <div className={classes.timeline}>
          <div className={classes.horizontal}>
            {authState?.isLogged ? (
              <img
                src="/images/active_step.png"
                className={classes.timelineIcon}
                alt=""
              ></img>
            ) : (
              <div className={classes.timelineStep}>1</div>
            )}
            <span
              className={
                authState?.isLogged
                  ? `${classes.dottedHorizontal} ${classes.dottedActive}`
                  : classes.dottedHorizontal
              }
            ></span>
            {kyc ? (
              <img
                src="/images/active_step.png"
                className={classes.timelineIcon}
                alt=""
              ></img>
            ) : (
              <div className={classes.timelineStep}>2</div>
            )}
            <span
              className={
                kyc
                  ? `${classes.dotted1Horizontal} ${classes.dottedActive}`
                  : classes.dotted1Horizontal
              }
            ></span>
            {kyc && isDeposit(balances) ? (
              <img
                src="/images/active_step.png"
                className={classes.timelineIcon}
                alt=""
              ></img>
            ) : (
              <div className={classes.timelineStep}>3</div>
            )}
          </div>

          <div className={classes.timelineSteps}>
            <div className={classes.Register}>
              <div className={classes.RegisterStep}>
                <div className={classes.titleStep}>Register account</div>
                <div className={classes.contentStep}>
                  Choose a username & password, register your OA Account
                </div>
              </div>

              {authState?.isLogged ? (
                <></>
              ) : (
                <Button
                  onClick={() => {
                    history.push('/');
                  }}
                  className={`${classes.btnActive} ${classes.button}`}
                >
                  Sign up
                </Button>
              )}
            </div>

            <div className={classes.KYC}>
              <div className={classes.KYCStep}>
                <div className={classes.titleStep}>KYC</div>
                <div className={classes.contentStep}>
                  Please complete KYC for full-featured OA acc
                </div>
              </div>
              {kyc ? (
                <></>
              ) : (
                <Button
                  className={getClassKYC(authState?.isLogged, kyc)}
                  onClick={handleSetKYC}
                >
                  Complete KYC
                </Button>
              )}
            </div>

            <div className={classes.Deposit}>
              <div className={classes.DepositStep}>
                <div className={classes.titleStep}>Deposit funds</div>
                <div className={classes.contentStep}>
                  Fund your OA account by making your first deposit below
                </div>
              </div>

              {kyc && isDeposit(balances) ? (
                <></>
              ) : (
                <Button
                  className={getClassDeposit(authState?.isLogged, kyc)}
                  onClick={handleDeposit}
                >
                  Deposit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.groupLine1}></div>
      {isOpenDeposit && (
        <Deposit
          isOpen={isOpenDeposit}
          handleClose={() => setIsOpenDeposit(false)}
        />
      )}
    </div>
  );
};

export default WelcomeContainer;
