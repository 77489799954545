import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    title: {
      fontSize: '24px !important'
    },
    display: {
      display: 'flex',
      justifyContent: 'center',
    },
    showMobile: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    link: {
      fontWeight: 700,

      '&:visited': {
        color: 'white',
      },
    },
  };
});

export default useStyles;
