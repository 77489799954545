import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    navHeadTrading: {
      listStyle: "none",
      display: "flex",
      flexWrap: "wrap",
      borderBottom: `1px solid ${theme.custom.colors.comet4}`,
      margin: "30px",
      padding: 0,
    },

    itemNav: {
      fontFamily: "Ubuntu-Bold",
      fontSize: "14px",
      lineHeight: "130%",
      color: "#9BA1B1",
      paddingBottom: "7px",
      borderBottom: "2px solid transparent",
      position: "relative",
      marginBottom: -1,
      cursor: "pointer",
      width: "50%",
      textAlign: "center",

      "&:last-child": {
        marginRight: 0,
      },
    },

    activeItemNav: {
      color: theme.custom.colors.lightPurple2,
      borderBottom: `2px solid ${theme.custom.colors.lightPurple2}`,
      cursor: "inherit",
    },
  };
});

export default useStyles;
