/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';

import DefaultLayout from '../../components/Layout/DefaultLayout';
import ConfigureAPI from '../APIPage/ComponentConfigure';
import Permissons from './Permissons';
import KeyList from './KeyList';
import ModalAddKey from './ModalAddKey';

import { ApiKey, ApiKeyService } from 'src/services/ApiKeyService';

import useStyles from './styles';

const apiKeyService = ApiKeyService.getInstance();

const APIPage = (props: any) => {
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [currentKey, setCurrentKey] = useState<ApiKey | null>();
  const [keyList, setKeyList] = useState<ApiKey[]>([]);
  const history = useHistory();

  const setModalAddKey = (open: boolean, key?: ApiKey) => {
    setCurrentKey(key);
    setIsOpen(open);
  };

  const isLogged = useSelector((state: any) => state.auth.isLogged);

  const getAPIKeys = async () => {
    try {
      const { data } = await apiKeyService.get();
      setKeyList(
        data.keys.map((el) => ({
          ...el,
          id: el.prefix,
        }))
      );
    } catch (error) { }
  };

  useEffect(() => {
    document.title = 'API';
    if (!isLogged) {
      history.push('/');
    }
    getAPIKeys();
  }, []);

  return (
    <DefaultLayout>
      <Typography variant="h4" className={styles.titlePageAPI}>
        API
      </Typography>

      <div className={styles.contentAPI}>
        <ConfigureAPI setModalAddKey={setModalAddKey} />
        <Permissons />
        <KeyList
          setModalAddKey={setModalAddKey}
          keyList={keyList}
          onSubmitted={getAPIKeys}
        />
      </div>

      <ModalAddKey
        isOpen={isOpen}
        currentKey={currentKey}
        keyList={keyList}
        handleClose={() => setIsOpen(false)}
        onSubmitted={getAPIKeys}
      />
    </DefaultLayout>
  );
};

export default withRouter(APIPage);
