import { Button } from '@material-ui/core';

import { displayNumb } from 'src/utils/number';
import { TRADE_SIDE } from 'src/commons/constants';
import ModalCustom from 'src/components/Base/ModalCustom';
import AppButton from 'src/components/Base/AppButton';
import useStyles from './styles';

export const ModalBestRate = ({
  isOpen,
  data,
  handleClose,
  handleSubmit,
  loading,
}: {
  isOpen: boolean;
  handleClose: () => void;
  data: any;
  handleSubmit: () => void;
  loading?: boolean;
}) => {
  const classes = useStyles();

  const renderSpan = (data: any, isTop?: boolean) => {
    const symbol = data?.symbol ?? '';
    const orderCoin = symbol.split('/')[0];
    const anchorCoin = symbol.split('/')[1];

    if (isTop) {
      return (
        <span>
          {data?.side === TRADE_SIDE.buy
            ? `${displayNumb(data?.proceeds)} ${orderCoin}`
            : `${displayNumb(data?.proceeds)} ${anchorCoin}`}
        </span>
      );
    }
    return (
      <span>
        {data?.side === TRADE_SIDE.buy
          ? `${displayNumb(data?.cost)} ${anchorCoin}`
          : `${displayNumb(data?.cost)} ${orderCoin}`}
      </span>
    );
  };
  return (
    <>
      <ModalCustom
        className={classes.mainModalConfirm}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <div className={classes.mainModal}>
          <h3 className={classes.titleModal}>Execute at best available rate</h3>
        </div>
        <div className={classes.contentModal}>
          <p className={classes.textModal}>
            You will receive: &nbsp;
            <span> {renderSpan(data, true)} </span>
          </p>

          <p className={`${classes.textModal} ${classes.styleText}`}>
            For approximately*&nbsp;&nbsp;&nbsp;
            <span>{renderSpan(data)}</span>
          </p>
          <span className={classes.textSub}>
            subject to market movement, indicate pricing
          </span>
        </div>

        <div className={classes.groupBtn}>
          <AppButton
            className={classes.btnConfirm}
            onClick={handleSubmit}
            isLoading={loading}
            width="126px"
            height="36px"
          >
            Confirm
          </AppButton>
          <Button className={classes.btnCan} onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export default ModalBestRate;
