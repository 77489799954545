import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import AppButton from 'src/components/Base/AppButton';
import AppInput from 'src/components/Base/AppInput';
import { IPropsBankChild } from './BankInter';

import useStyles from './style';

export interface IFormBankPage1 {
  account_name: string;
  account_number: string;
  bank_name: string;
  swift: string;
}

export default function BankInterPage1({ onSubmit, onBack }: IPropsBankChild) {
  const styles = useStyles();
  const {
    control,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm<IFormBankPage1>({
    mode: 'onChange',
    defaultValues: {
      // account_name: 'Name on Bank Account', // 1-1
      // account_number: 'Account Number', // 1-2
      // bank_name: 'Name of Bank', // 1-3
      // swift: 'SWIFT Code', // 1-4
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'account_name',
            rules: {
              required: true,
            },
          }}
          label="Name on Bank Account"
          error={Boolean(errors.account_name)}
          helperText={errors.account_name?.message}
          labelProps={{
            className: errors.account_name ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          inputNumber={true}
          fullWidth={true}
          controller={{
            control,
            name: 'account_number',
            rules: {
              required: true,
            },
          }}
          label={'Account Number'}
          error={Boolean(errors.account_number)}
          helperText={errors.account_number?.message}
          labelProps={{
            className: errors.account_number ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'bank_name',
            rules: {
              required: true,
            },
          }}
          label="Name of Bank"
          error={Boolean(errors.bank_name)}
          helperText={errors.bank_name?.message}
          labelProps={{
            className: errors.bank_name ? styles.styleLabel : '',
          }}
        />
      </div>
      <div className={styles.row}>
        <AppInput
          fullWidth={true}
          controller={{
            control,
            name: 'swift',
            rules: {
              required: true,
            },
          }}
          onChange={(e) => {
            setValue('swift', e.target.value.toUpperCase(), {
              shouldValidate: true,
            });
          }}
          label="SWIFT Code"
          error={Boolean(errors.swift)}
          helperText={errors.swift?.message}
          labelProps={{
            className: errors.swift ? styles.styleLabel : '',
          }}
        />
      </div>
      <Box className={clsx(styles.row, styles.rowBtn)} display="flex">
        <AppButton mode="secondary" height={'36px'} onClick={() => onBack?.()}>
          Back
        </AppButton>
        <AppButton type="submit" isDisable={!isValid} height={'36px'}>
          Next
        </AppButton>
      </Box>
    </form>
  );
}
