import { AnyAction } from "redux";
import { CHAIN_SELECTED, CHAIN_SET } from "../constants/chain";

const initialState = {
  items: [],
  id: null,
};

export const chainReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CHAIN_SET:
      return {
        ...state,
        items: action.payload,
      };
    case CHAIN_SELECTED:
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
};
