import { useState } from 'react';
import { Button } from '@material-ui/core';
import { AssetAction } from 'src/store/actions/asset';
import { WithdrawalAction } from 'src/store/actions/withdrawal';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import Deposit from 'src/components/Deposit';
import { getBalances } from 'src/store/actions/account';

const ComponentCoinInfo = (props: any) => {
  const { activeCoin, currencies, onRefresh } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state: any) => state.auth);
  const styles = useStyles();
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);

  const copyToClipboard = (addr: string) => {
    navigator.clipboard.writeText(addr);
    toast.success('Copied!');
  };

  const handleWithdraw = (params: any) => {
    dispatch(AssetAction.selectAsset(params.asset));
    dispatch(getBalances());
    dispatch(
      WithdrawalAction.toggle(null, () => {
        // window.location.reload();
        onRefresh();
      })
    );
  };
  const handleRedirectTrading = (pair: string) => {
    const curency = currencies.find((x: any) => x.pair === pair);
    if (curency) {
      history.push({ pathname: '/trading', state: { pair: curency.value } });
    } else {
      history.push({ pathname: '/trading' });
    }
  };

  return (
    <section className={styles.componentCoinInfo}>
      <div className={styles.nameCoin}>{activeCoin?.name}</div>
      <div className={styles.listContent}>
        <div className={styles.itemInfo}>
          <div className={styles.nameItem}>Total balance</div>
          <div className={styles.valueItem}>{activeCoin?.total_balance}</div>
        </div>
        <div className={styles.itemInfo}>
          <div className={styles.nameItem}>Available</div>
          <div className={styles.valueItem}>{activeCoin?.available}</div>
        </div>
        <div className={styles.itemInfo}>
          <div className={styles.nameItem}>Locked</div>
          <div className={styles.valueItem}>{activeCoin?.locked}</div>
        </div>
        <div className={styles.itemInfo}>
          <div className={styles.nameItem}>Deposit address</div>
          <div className={styles.valueItem}>
            {activeCoin?.address &&
              `${activeCoin?.address.substr(
                0,
                6
              )}...${activeCoin.address.substr(
                activeCoin?.address.length - 4,
                activeCoin?.address.length
              )}`}
            {activeCoin?.address && (
              <span
                className={`icon-copy ${styles.iconCopy}`}
                onClick={() => copyToClipboard(activeCoin.address)}
              ></span>
            )}
          </div>
        </div>
      </div>
      <Deposit
        isOpen={isOpenDeposit}
        handleClose={() => setIsOpenDeposit(false)}
        currency={activeCoin}
      />
      <div className={styles.listBtn}>
        {isLogged && (
          <>
            <Button
              variant="contained"
              className={`${styles.btnInfo}`}
              onClick={() => handleRedirectTrading(activeCoin?.asset)}
            >
              Trade
            </Button>

            <Button
              variant="contained"
              className={`${styles.btnInfo}`}
              onClick={() => handleWithdraw(activeCoin)}
            >
              Withdraw
            </Button>
            <Button
              variant="contained"
              className={`${styles.btnInfo}`}
              onClick={() => setIsOpenDeposit(true)}
            >
              Deposit
            </Button>
          </>
        )}
        {!isLogged && (
          <>
            <Button
              disabled
              variant="contained"
              className={`${styles.btnDisable}`}
            >
              Trade
            </Button>
            <Button
              disabled
              variant="contained"
              className={`${styles.btnDisable}`}
            >
              Withdraw
            </Button>
            <Button
              disabled
              variant="contained"
              className={`${styles.btnDisable}`}
            >
              Deposit
            </Button>
          </>
        )}
      </div>
    </section>
  );
};

export default ComponentCoinInfo;
