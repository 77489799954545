import React from 'react';
import { withRouter } from 'react-router-dom';

import AppButton from 'src/components/Base/AppButton';

import useStyles from './styles';

const ConfigureAPI = (props: any) => {
  const styles = useStyles();

  return (
    <div className={styles.mainConfiruge}>
      <div className={`${styles.boxAPI} ${styles.box2}`}>
        <h3 className={styles.titleBox}>Configure API Keys</h3>
        <div className={styles.boxText}>
          <p className={styles.textCon}>
            Keys are only displayed once, securely take note of your API Key and
            Secret
          </p>
        </div>
        <AppButton
          height="36px"
          width="130px"
          onClick={() => props.setModalAddKey(true)}
        >
          <span className={styles.btnTitle}>Add Key</span>
        </AppButton>
      </div>

      <div className={styles.boxAPI}>
        <h3 className={`${styles.titleBox} ${styles.titleDoc}`}>
          API documentation
        </h3>
        <div className={styles.boxText}>
          <p className={styles.textCon}>
            Please refer to below API documentation on how to access your
            account programatically<br></br>
          </p>
        </div>

        <a
          href="https://api.onealpha.io/docs"
          target="_blank"
          rel="noreferrer"
          className={styles.linkButton}
        >
          <AppButton height="36px" width="130px">
            <span className={styles.btnTitle}>View API docs</span>
          </AppButton>
        </a>
      </div>
    </div>
  );
};

export default withRouter(ConfigureAPI);
