import BigNumber from 'bignumber.js';
import _ from 'lodash';
import { API_URL_PREFIX } from 'src/commons/constants';

export function formatPrecisionAmount(amount: any, precision: number = 18): string {
  const rawValue = new BigNumber(`${amount}`).toFixed(precision);
  return amount && parseFloat(amount) !== Infinity
    ? new BigNumber(rawValue).toFormat()
    : '0';
}

export const checkIsLoginRoute = (pathname: string) => {
  return pathname.indexOf(`/login`) !== -1;
};

export const apiRoute = (url = '') => {
  const truncateUrl = _.trim(url, '/');
  const resUrl = `/${API_URL_PREFIX}/${truncateUrl}`;
  return resUrl;
};
export const checkValueAmount = /^\d*\.?\d*$/;

export const sortPriorityAlplabet = (input: string, data: any) => {
  var first = [];
  var others = [];
  for (var i = 0; i < data.length; i++) {
    if (data[i].indexOf(input) === 0) {
      first.push(data[i]);
    } else {
      others.push(data[i]);
    }
  }
  first.sort();
  others.sort();
  return (first.concat(others));
}