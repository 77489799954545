import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainLiqui: {
      paddingTop: 100,
      fontFamily: "Titillium Web",
      overflow: "hidden",

      [theme.breakpoints.down('sm')]: {
        paddingTop: 30,
      },
    },

    tittleLiqui: {
      fontWeight: 700,
      fontSize: "64px",
      lineHeight: "97px",
      color: theme.custom.colors.textdesc,
      textAlign: "center",


      [theme.breakpoints.down('lg')]: {
        fontSize: "48px",
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: "64px",
      },

      [theme.breakpoints.between('1440', '1920')]: {
        fontSize: "64px",
      },


      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: "32px",
        textAlign: "left",
      },
    },

    groupDesc: {
      gridGap: "50px",

      [theme.breakpoints.up('md')]: {
        fontSize: "48px",
        display: "grid",
        gridTemplateRows: "repeat(1, minmax(0,1fr))",
        gridTemplateColumns: "repeat(4, minmax(0,1fr))",
        gridGap: "50px",
      },

      [theme.breakpoints.between('md', 'lg')]: {
        gridGap: "40px",
      },

      [theme.breakpoints.between('991', '1100')]: {
        gridGap: "10px",
      },

      [theme.breakpoints.down('sm')]: {
        display: "grid",
        gridTemplateRows: "repeat(1, minmax(0,1fr))",
        gridTemplateColumns: "repeat(2, minmax(0,1fr))",
        gridGap: "20px 20px",
      },
      [theme.breakpoints.down('xs')]: {
        display: "grid",
        gridTemplateRows: "repeat(1, minmax(0,1fr))",
        gridTemplateColumns: "repeat(1, minmax(0,1fr))",
        gridGap: "20px 20px",
      }
    },

    boxDesc1: {
      background: theme.custom.colors.bgrBox,
      boxShadow: "0px 4px 50px rgba(127, 99, 244, 0.15)",
      borderRadius: "10px",
      textAlign: "center",
      width: 280,
      height: 349,

      [theme.breakpoints.down('md')]: {
        width: 245,
      },

      [theme.breakpoints.between('md', 'lg')]: {
        maxWidth: 245,
      },

      [theme.breakpoints.between('1440', '1919')]: {
        width: 290,
      },
      [theme.breakpoints.between('991', '1100')]: {
        maxWidth: '100%',
        padding: 10,
        height: 'auto',
      },

      [theme.breakpoints.down('sm')]: {
        width: "100%",
        height: "317px",
      },

      [theme.breakpoints.down('xs')]: {
        textAlign: "left",
        background: "none",
        boxShadow: "none",
        height: "100%",
      },

    },
    icon: {
      paddingTop: "30px",
      paddingBottom: "6px",

      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
      },
    },
    titleBox: {
      color: theme.custom.colors.textdesc,
      fontWeight: 700,
      fontSize: "24px",
      fontFamily: "Titillium Web",

      [theme.breakpoints.down('xs')]: {
        fontSize: "18px",
      },
    },


    textSub: {
      color: theme.custom.colors.textdesc,
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "27px",
      textAlign: "center",
      padding: "0 15px",

      [theme.breakpoints.down('lg')]: {
        fontSize: "16px",
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: "18px",
      },

      [theme.breakpoints.between('1440', '1920')]: {
        fontSize: "18px",
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: "18px",
      },

      [theme.breakpoints.down('xs')]: {
        textAlign: "left",
        padding: 0,
        fontSize: "18px",
      },
    },

    styleImg: {
      paddingTop: 80,

      "& img": {
        width: "100%",
      },
    },
  };
});

export default useStyles;