import { useEffect, useRef, useState } from 'react';
import AppButton from 'src/components/Base/AppButton';
import { useAppSelector } from 'src/hooks';
import { toCsvBlob } from 'src/utils/csv';

const DownloadCSV = ({
  header,
  fileName,
  getData,
}: {
  header: any[];
  fileName: string;
  getData: () => any[] | Promise<any[]>;
}) => {
  const [downloading, setDownloading] = useState(false);
  const isLogged = useAppSelector((state) => state.auth.isLogged);

  const [downloadUrl, setDownloadUrl] = useState('');
  const downloadBtn = useRef<HTMLAnchorElement>(null);

  const onClick = async () => {
    if (downloading) return;
    try {
      setDownloading(true);
      const data = await getData();
      const blob = toCsvBlob(data, header);
      const url = URL.createObjectURL(blob);
      setDownloadUrl(url);
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    downloadUrl && downloadBtn.current && downloadBtn.current.click();
    URL.revokeObjectURL(downloadUrl);
  }, [downloadUrl]);

  return (
    <>
      <AppButton fullWidth onClick={onClick} isLoading={downloading} isDisable={!isLogged}>
        Download CSV
      </AppButton>

      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a
        ref={downloadBtn}
        href={downloadUrl}
        download={fileName}
        style={{ visibility: 'hidden' }}
      ></a>
    </>
  );
};
export default DownloadCSV;
