const disabled_features = [
    'use_localstorage_for_settings',
    'header_settings',
    // 'header_widget',
    'header_fullscreen_button',
    'header_symbol_search',
    'header_saveload',
    'header_chart_type',
    'header_resolutions',
    'header_screenshot',
    'header_indicators',
    'header_compare',
    'header_undo_redo',

    'left_toolbar',
    'compare_symbol',
    'control_bar',
    'dont_show_boolean_study_arguments',
    'symbol_search_hot_key',
    'show_hide_button_in_legend',
    'source_selection_markers',
    'pane_context_menu',
    'create_volume_indicator_by_default',
    'timeframes_toolbar',
    'snapshot_trading_drawings',
    'pane_context_menu'
];

const enabled_features = ['hide_left_toolbar_by_default'];

const overrides = {
    volumePaneSize: 'medium',

    // 'paneProperties.background': '#1E2024',
    // 'paneProperties.vertGridProperties.color': 'rgba(255,255,255,0.03)',
    // 'paneProperties.horzGridProperties.color': 'rgba(255,255,255,0.03)',

    // 'symbolWatermarkProqperties.color': 'rgba(0, 0, 0, 0)',
    // 'scalesProperties.lineColor': '#243c5e',
    // 'scalesProperties.textColor': '#6D6E76',

    // Candles styles
    // 'mainSeriesProperties.style': 1,
    // 'mainSeriesProperties.candleStyle.upColor': '#008ed4',
    // 'mainSeriesProperties.candleStyle.downColor': '#e05140',
    // 'mainSeriesProperties.candleStyle.drawWick': true,
    // 'mainSeriesProperties.candleStyle.drawBorder': true,
    // 'mainSeriesProperties.candleStyle.borderColor': '#FF0000',
    // 'mainSeriesProperties.candleStyle.borderUpColor': '#008ed4',
    // 'mainSeriesProperties.candleStyle.borderDownColor': '#e05140',
    // 'mainSeriesProperties.candleStyle.wickUpColor': '#3d6496',
    // 'mainSeriesProperties.candleStyle.wickDownColor': '#3d6496',
    // 'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,

    'paneProperties.legendProperties.showStudyArguments': false,
    'paneProperties.legendProperties.showStudyTitles': false,
    'paneProperties.legendProperties.showStudyValues': false,
    'paneProperties.legendProperties.showSeriesTitle': false,
    'paneProperties.legendProperties.showSeriesOHLC': false,

    // Line styles
    'mainSeriesProperties.lineStyle.linewidth': 5,

    // Area styles
    // 'mainSeriesProperties.areaStyle.color1': 'rgb(35 59 92 / 0%)',
    // 'mainSeriesProperties.areaStyle.color2': 'rgb(35 59 92 / 0%)',
    // 'mainSeriesProperties.areaStyle.linecolor': '#979EC1',
    // 'mainSeriesProperties.areaStyle.linewidth': 2,
    'timeScale.rightOffset': 1
};

const studies_overrides = {
    // 'volume.volume.color.0': '#676767',
    // 'volume.volume.color.1': '#676767',
    // 'volume.volume.transparency': 90
};

const getLanguageFromURL = () => {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const baseConfig = {
    debug: false,
    container_id: 'tv_chart_container',
    library_path: '/charting_library/',
    locale: getLanguageFromURL() || 'en',
    allow_symbol_change: false,
    loading_screen: { backgroundColor: 'transparent' },
    custom_css_url: '/static/tradingview.css?v=3',
    overrides,
    studies_overrides,
    disabled_features,
    enabled_features,
    autosize: true,
    full_screen: false
};

export default function createWidgetConfig(options: any) {
    return Object.assign({}, baseConfig, options);
}
