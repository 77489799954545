import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    stepContainer: {
      background: theme.custom.colors.bgPagination,
      width: '680px',
      borderRadius: '8px',
      margin: '17px auto',
      padding: '25px 113px 40px',
      color: theme.custom.colors.titleTrading,

      '& .mt-40': {
        marginTop: '40px',
      },
      '& .MuiFormLabel-root': {
        fontWeight: 500,
      },
      [theme.breakpoints.between('500', '620')]: {
        width: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '25px 0px 40px 0',
        background: 'none',
        width: '100%',
      },
    },
    inputItem: {
      width: '100%',
      marginTop: '30px',
      maxWidth: 'unset',

      '& input': {
        '&::placeholder': {
          color: theme.custom.colors.colorPlace,
        },
      },
    },

    selectInput: {
      borderColor: theme.custom.colors.white2,
    },

    title: {
      fontFamily: 'Ubuntu-bold',
      fontSize: 20,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',

      '& .icon-info:before': {
        marginLeft: '12px',
        fontSize: 24,
        color: theme.custom.colors.titleTrading,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    inputContainer: {
      marginTop: '20px',
    },

    label: {
      fontFamily: 'Ubuntu-Medium',
      fontSize: '14px',
      lineHeight: '20px',
      margin: '20px 0 2px',
    },

    btnNext: {
      margin: '0 -10px',
      display: 'flex',
      flexDirection: 'row',

      '& >div': {
        width: '50% !important',
        margin: '0 10px',
      },
    },

    styleLabel: {
      color: `${theme.custom.colors.red1}!important`,
    },

    descTooltip: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: '16px',
      lineHeight: '140%',
      color: theme.custom.colors.descColor,
    },
  };
});

export default useStyles;
