import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "42px", // paddingTop: 12 + line-height: 18 + paddingBottom: 12
    backgroundColor: "none",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.custom.colors.white2}`,
    "label + &": {
      marginTop: "4px",
      color: theme.custom.colors.white2,
    },
  },

  input: {
    fontSize: 14,
    lineHeight: "18px",
    // currently, i just delay background-color changed when
    // autocomplete on chrome. it should be better.
    // god bless you
    "&:-webkit-autofill": {
      transitionDelay: "9999999s",
      transitionProperty: "background-color, color",
    },
    padding: theme.spacing(1.5, 2),
    "&::placeholder": {
      color: theme.custom.colors.santaGray2,
      opacity: 1,
      fontWeight: 500,
    },
    "&[type=number]": {
      MozAppearance: "textfield",
    },
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },

  datePicker: {
    width: "100%",
    marginTop: "4px",
    color: theme.custom.colors.boldGray,
    borderRadius: 6,
    background: "none",
    border: `1px solid ${theme.custom.colors.white2}`,
    outline: "none",
    fontSize: 14,
    padding: "12px 16px",
    "-webkit-appearance": "unset",

    "&::placeholder": {
      color: theme.custom.colors.santaGray,
    },

  },
   formControl: {
    '& label': {
      color: theme.custom.colors.white2,
      fontFamily: 'Ubuntu-Medium',
      fontSize: '14px',
      lineHeight: '19px',
     },
     
      "& .label-error": {
      color: `${theme.custom.colors.red2 } !important`,
    },
     "& .label-focused": {
      color: theme.custom.colors.lightPurple,
    },

    "& .input-focused": {
      borderColor: theme.custom.colors.lightPurple,
    },
    '& input': {
      fontFamily: 'Ubuntu-Regular',
    },
  },
 
  multiline: {
    padding: 0,
  },
  disabled: { color: `rgba(255, 255, 255, 0.8)` },
  // affect input if has start adorn
  inputAdornedStart: {
    paddingLeft: 0,
  },
  // affect input if has end adorn
  inputAdornedEnd: {
    paddingRight: 0,
  },
  adornedStart: {
    paddingLeft: 0,
  },
  // affect input if has end adorn
  adornedEnd: {
    paddingRight: 0,
  },
  focused: { borderColor: theme.custom.colors.lightPurple },
  
  error: { borderColor: `${theme.custom.colors.red2} !important` },
  
  helperText: {
    color: theme.custom.colors.red2,
    fontFamily: "Ubuntu-Regular",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    marginTop: "4px",
  },
  
  BoxInline: {
    flexDirection: "row",
    display: "flex",
  },
  BoxText: {
    display: "flex",
    // alignItems: 'center !important',
    marginTop: "8px",
    "& span": {
      color: "red",
      paddingLeft: 5,
    },
  },
}));

export default useStyles;
