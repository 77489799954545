import { HTTPBaseService } from 'src/request/HTTPBaseService';

export interface ICreateAddressParam {
  id?: string;
  address_title: string;
  classification?: string;
  asset: string;
  withdrawal_address: string;
  memo?: string;
  chain?: number | string;
  whitelist?: boolean;
  code?: string;
  two_fa?: string;
}
export interface IGetAddressParam {
  page?: number;
  page_size?: number;
  asset?: string;
  chain_id?: string;
  memo?: string;
  type?: 'DEPOSIT' | 'WITHDRAWAL';
}

export interface IGetAddressResponse {
  items: IGetAddressItemResponse[];
  page: number;
  page_size: number;
  pages_total: number;
  items_returned: number;
  items_total: number;
}

export interface IGetAddressItemResponse {
  id: number;
  type: 'DEPOSIT' | 'WITHDRAWAL';
  address_title: string;
  classification: string;
  asset: string;
  address: string;
  memo: string;
  chain_id: number;
  whitelisted: boolean;
  created_at: number;
  updated_at: number;
}

export interface IWhitelistAddressRequest {
  whitelist: boolean;
  code: string;
  two_fa: string;
}

export class AddressBookService extends HTTPBaseService {
  private static classInstance?: AddressBookService;

  public static getInstance(token?: string) {
    if (!this.classInstance) {
      this.classInstance = new AddressBookService();
    }
    return this.classInstance;
  }

  public async getAddresses(params?: IGetAddressParam) {
    return await this.instance.get('/addresses', {
      params,
    });
  }

  public async createAddress(data: ICreateAddressParam) {
    return await this.instance.put('/addresses/', data);
  }

  public async mutateAddressWhitelist(
    address_id: string | any,
    data: IWhitelistAddressRequest | any
  ) {
    return await this.instance.put<IGetAddressItemResponse | any>(
      `/addresses/${address_id}/`,
      data
    );
  }

  public async deleteAddress(id: string) {
    return await this.instance.delete(`/addresses/${id}/`);
  }

  public async createAsseet(data: any) {
    return await this.instance.post('/asset/limits/', data);
  }
}

// export instance of address service
export const addBookService = new AddressBookService();
