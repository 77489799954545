/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Hidden, Typography } from '@material-ui/core';

import { KYC_STATUS, SCORE_LEVEL } from 'src/commons/constants/Constants';
import * as AccountActions from 'src/store/actions/account';
import DefaultLayout from 'src/components/Layout/DefaultLayout';
import WelcomeContainer from './WelcomeContainer';
import AccountSecurity from './SecurityContainer';
import FeaturesContainer from './FeaturesContainer';
import MarketInfo from './MarketInfo';
import PriceChart from './PriceChart';

import useStyles from './styles';

const HomePage = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // use states
  const accountState = useSelector((state: any) => {
    return state.accountReducer;
  });

  const marketState = useSelector((state: any) => {
    return state.marketReducer;
  });

  useEffect(() => {
    document.title = 'Home';
    getProfileData();
  }, []);

  const getProfileData = async () => {
    // get kyc settings
    const isKYC = accountState.isKYC;
    if (!isKYC || (isKYC && isKYC !== KYC_STATUS.completed)) {
      await dispatch(AccountActions.getProfile());
    }
  };

  const handleRedirectTrading = (pair: string) => {
    history.push({ pathname: '/trading', state: { pair: pair } });
  };

  const calculateSureScore = (state: any) => {
    let score = 0;
    if (state['whitelisted']) {
      score += SCORE_LEVEL.HAS_WHITE_LIST;
    }

    if (state['isKYC']) {
      score += SCORE_LEVEL.KYC;
    }

    if (state['has2FADevices']) {
      score += SCORE_LEVEL.HAS_2FA;
    }

    if (state['emailVerified']) {
      score += SCORE_LEVEL.EMAIL_VERIFY;
    }
    return score;
  };

  return (
    <DefaultLayout>
      <div className={classes.styleHome}>
        <div className={classes.homeWrapper}>
          <Hidden mdUp>
            <Typography className={classes.title}>Home</Typography>
          </Hidden>
          <div className={classes.welcomeContainer}>
            <WelcomeContainer
              kyc={accountState.isKYC}
              balances={accountState.balances}
            />
            <AccountSecurity
              score={calculateSureScore(accountState)}
              verified={accountState.emailVerified}
              kyc={accountState.isKYC}
              enable2FA={accountState.has2FADevices}
            />
          </div>
          <FeaturesContainer></FeaturesContainer>
          <div className={classes.priceContainer}>
            <MarketInfo
              allMarkets={marketState.allMarket}
              handleRedirectTrading={handleRedirectTrading}
            />
            <PriceChart allMarkets={marketState.allMarket}></PriceChart>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default withRouter(HomePage);
